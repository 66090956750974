/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as yup from 'yup';
import {
  Bank,
  ControllingPersons,
  Groups,
  NomineeType,
  nonIndividualQuestionsFatca,
  ubo,
  Applicant,
  RMType,
} from '../redux-store/types/api-types';
import {
  accountNumberRegex,
  ifscRegex,
  stringRegex,
  addressRegex,
  phoneRegExp,
  nonIndividualPanRegex,
  emailRegex,
  individualPanRegex,
  numberRegex,
  amountRegex,
  alphaNumeric,
  indianPin,
  arnCodeRegex,
} from './regex';
import {
  isMinor,
  currencyConversion,
  maxAge,
  getCountryCodes,
  checkForCorrespondenceAddress,
  futureAge,
  isOnboardingTypeMinor,
  rmRoleTypeService,
} from './utilityFunctions';
import { InternalOptions } from 'yup/lib/types';
import { Values } from '../components/investors/contactDetails';
import { Distributor, SubDistributor } from '../redux-store/actions';
import { Values as ContributorValues } from '../components/investors/personalDetails';
import { Values as DocumentValues } from '../components/investors/documentDetails';
import { Values as NonIndividualDocValues } from '../components/NonIndividualInvestor/DocumentDetails';
import {
  getSubQuestionsIndex,
  Values as NonIndividualFatcaValues,
} from '../components/NonIndividualInvestor/fatca';
import {
  addressTypesMasters,
  applicantStatusMasters,
  APPLICATION_TYPE,
  BankAccountTypeMaster,
  ContactRelations,
  CpUboCodesMaster,
  genderMasters,
  grossAnnualMasters,
  investorTypeMasters,
  maritalMasters,
  nationalitiesMasters,
  NomineeRelations,
  NRIBankAccountTypeMaster,
  occupationDetailsMasters,
  PEPsMasters,
  relatedRelations,
  Relations,
  RoleTypes,
} from './constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { Values as NonIndividualContactValues } from '../components/NonIndividualInvestor/ContactDetails';
import { Values as individualFatcaValues } from '../components/investors/fatca';
import { mdmsLocationList } from '../redux-store/types/mdms';

export const personalDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .nullable()
        //.matches(stringRegex, 'Special characters and numbers not allowed')
        .required('Full Name is required'),
      firstName: yup.string().nullable().required('First Name is required'),
      lastName: yup.string().nullable().required('Last Name is required'),
      gender: yup
        .string()
        .nullable()
        .oneOf(genderMasters, 'Invalid value for Gender')
        .required('Please select gender'),
      fatherOrSpouseName: yup
        .string()
        .nullable()
        //.matches(stringRegex, 'Special characters and numbers not allowed')
        .required('Father/Spouse Name is required'),
      status: yup
        .string()
        .nullable()
        .oneOf(Object.keys(applicantStatusMasters), 'Invalid value for Status')
        .required('Status is required'),
      occupationType: yup
        .string()
        .nullable()
        .test('occupationType', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { createError, path } = context;
          if (!fieldValue) {
            return createError({
              message: 'Occupation Type is required',
              path,
            });
          }
          if (fieldValue && !occupationDetailsMasters.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for Occupation Type',
              path,
            });
          }
          return true;
        }),
      maritalStatus: yup
        .string()
        .nullable()
        .test('maritalStatus', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };
          if (!fieldValue) {
            return createError({
              message: 'Marital Status Type is required',
              path,
            });
          }
          return true;
        }),
      occupationDetails: yup
        .string()
        .nullable()
        .matches(stringRegex, 'Special characters and numbers not allowed')
        .when('occupationType', {
          is: (occupationType: string | null) => occupationType === 'Others',
          then: yup.string().nullable().required('Please Specify Occupation Type'),
        }),
      jointApplicantRelation: yup
        .string()
        .nullable()
        .test('jointApplicationRelation', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path } = context;
          const { index } = options as InternalOptions<ContributorValues> & {
            index: number;
          };

          if (index !== 0) {
            if (!fieldValue) {
              return createError({
                message: 'Relationship with first applicant is required',
                path,
              });
            }
            if (fieldValue && !Relations.includes(fieldValue)) {
              return createError({
                message: 'Invalid value for Relationship with first applicant',
                path,
              });
            }
          }
          return true;
        }),
      panNo: yup
        .string()
        .nullable()
        .matches(individualPanRegex, 'Only individual PANs are allowed')
        .required('Pan is required'),
      relationShipWithFirstApplicant: yup
        .string()
        .nullable()
        .when('jointApplicantRelation', {
          is: (jointApplicantRelation: string | null) => jointApplicantRelation === 'Others',
          then: yup.string().nullable().required('Please Specify Relationship'),
        }),
      minorPan: yup
        .string()
        .nullable()
        .uppercase()
        .length(10, 'PAN must be exactly 10 characters')
        .test('pan', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue) {
            return true;
          }
          if (!individualPanRegex.test(fieldValue)) {
            return createError({
              message: 'Only individual PANs are allowed',
              path,
            });
          }
          if (fieldValue === parent.panNo) {
            return createError({
              message: 'Minor PAN should not be same as Guardian PAN',
              path,
            });
          }
          return true;
        }),
      minorDOB: yup
        .string()
        .nullable()
        .test('minorDOB', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (isOnboardingTypeMinor(parent.onboardingType)) {
            if (!fieldValue) {
              return createError({
                message: 'Date of Birth is required',
                path,
              });
            }
            if (futureAge(fieldValue || '')) {
              return createError({
                message: 'Future date cannot be entered',
                path,
              });
            }
            if (!isMinor(fieldValue)) {
              return createError({
                message: 'Age should be less than 18',
                path,
              });
            }
          }
          return true;
        }),
      minorFirstName: yup
        .string()
        .nullable()
        .test('minorFirstName', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
            return createError({
              message: 'Minor First Name is required',
              path,
            });
          }
          return true;
        }),
      minorLastName: yup
        .string()
        .nullable()
        .test('minorLastName', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
            return createError({
              message: 'Minor Last Name is required',
              path,
            });
          }
          return true;
        }),
      guardianRelationship: yup
        .string()
        .nullable()
        .test('guardianRelationship', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
            return createError({
              message: 'Relationship with guardian is required',
              path,
            });
          }
          return true;
        }),
      guardianRelationshipTypeOther: yup
        .string()
        .nullable()
        .test('guardianRelationshipTypeOther', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (
            !fieldValue &&
            isOnboardingTypeMinor(parent.onboardingType) &&
            parent.guardianRelationship === 'OTHERS'
          ) {
            return createError({
              message: 'Please specify Relationship with guardian is required',
              path,
            });
          }
          return true;
        }),
      // minorName: yup
      //   .string()
      //   .nullable()
      //   .test('minorName', (value, context) => {
      //     const fieldValue = value === undefined ? '' : value;
      //     const { path, createError, parent } = context;
      //     if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
      //       return createError({
      //         message: 'Minor Name is required',
      //         path,
      //       });
      //     }
      //     return true;
      //   }),
      minorFatherName: yup
        .string()
        .nullable()
        .test('minorFatherName', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
            return createError({
              message: 'Minor Father Name is required',
              path,
            });
          }
          return true;
        }),
      minorGender: yup
        .string()
        .nullable()
        .test('minorGender', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError, parent } = context;
          if (!fieldValue && isOnboardingTypeMinor(parent.onboardingType)) {
            return createError({
              message: 'Minor Gender is required',
              path,
            });
          }
          return true;
        }),

      minorEmail: yup.string().nullable().matches(emailRegex, 'invalid Email ID'),
      minorMobile: yup
        .string()
        .nullable()
        .test('minorMobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.minorCountryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (
            value &&
            parent.minorCountryNameAndCode === 'India: +91' &&
            !phoneRegExp.test(value as any)
          ) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (value && !isValidPhoneNumber(value as any, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          return true;
        }),
    })
  ),
});

const validateOverseasAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? true : fieldValue !== '';
};

const validatePermanentAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  applicationType: string
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  const { permanentAddressSameAsCorresponding = false } =
    checkForCorrespondenceAddress(nationality, status) ||
    APPLICATION_TYPE.NON_INDIVIDUAL === applicationType
      ? currentApplicant.address.correspondence || {}
      : currentApplicant.address.overseas || {};
  return permanentAddressSameAsCorresponding ? true : fieldValue !== '';
};

const validatePincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>,
  addressType: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  if (addressType === 'correspondence' && !checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (addressType === 'overseas' && checkForCorrespondenceAddress(nationality, status)) {
    return true;
  }
  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode should be 6 digit code',
      path,
    });
  }
  if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }

  return true;
};

const validateCorrespondenceAddressFields = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = !value ? '' : value;
  const { options } = context;
  const { context: optionsContext, index } = options as InternalOptions<Values> & {
    index: number;
  };
  const { applicants = [] } = optionsContext || {};
  const currentApplicant = applicants[index] || {};
  const { nationality, status = '' } = currentApplicant.address;
  return checkForCorrespondenceAddress(nationality, status) ? fieldValue !== '' : true;
};

export const contactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      phoneNumberoffice: yup.string().nullable(),
      phoneNumberResidence: yup.string().nullable(),
      email: yup
        .string()
        .nullable()
        .matches(emailRegex, 'invalid Email ID')
        .required('Email is required'),
      mobile: yup
        .string()
        .nullable()
        .test('mobile', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === parent.countryNameAndCode) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Mobile is required',
              path,
            });
          }
          if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }
          if (!isValidPhoneNumber(value, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      emailPersonRelationType: yup.string().nullable().required('RelationShip is required'),
      emailPersonRelation: yup
        .string()
        .nullable()
        .when('emailPersonRelationType', {
          is: (emailPersonRelationType: string | null) => emailPersonRelationType === 'Others',
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters, multiple spaces and numbers not allowed')
            .required('Please Enter Relationship'),
        }),
      phonePersonRelationType: yup
        .string()
        .nullable()
        .test('phonePersonRelationType', (value, context) => {
          const { createError, path, parent, options } = context;
          const { context: optionsContext, index } = options as InternalOptions<Values> & {
            index: number;
          };
          const { applicants = [] } = optionsContext || {};
          if (!value) {
            return createError({
              message: 'RelationShip is required',
              path,
            });
          }
          if (
            parent.mobile &&
            value &&
            ContactRelations[value] === ContactRelations.self &&
            applicants.filter(
              (applicant) =>
                applicant.mobile === parent.mobile &&
                ContactRelations[applicant.phonePersonRelationType] === ContactRelations.self
            ).length !== 1
          ) {
            return createError({
              message: `RelationShip can't be choosen as ${ContactRelations[value]} for joint holders who has same mobile number`,
              path,
            });
          }

          return true;
        }),
      phonePersonRelation: yup
        .string()
        .nullable()
        .when('phonePersonRelationType', {
          is: (phonePersonRelationType: string | null) => phonePersonRelationType === 'Others',
          then: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters, multiple spaces and numbers not allowed')
            .required('Please Enter Relationship'),
        }),
      countryNameAndCode: yup
        .string()
        .nullable()
        .test('countryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              //   const { options } = context;
              //   const { context: optionsContext, index } = options as InternalOptions<Values> & {
              //     index: number;
              //   };
              //   const { applicants = [] } = optionsContext || {};
              //   const currentApplicant = applicants[index] || {};
              //   const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              //   if (!fetchedFromKRA) {
              //     return validateCorrespondenceAddressFields(value, context);
              //   }
              //   return true;
              // }
              validateCorrespondenceAddressFields(value, context)
            ),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) =>
              validatePincodeField(value, context, 'correspondence')
            ),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validateCorrespondenceAddressFields(value, context)
            ),
          // country: yup
          // .string()
          // .nullable()
          // .test('country', 'Country is required', (value, context) =>
          // validateCorrespondenceAddressFields(value, context)
          // ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [], applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;

              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (
                value &&
                checkForCorrespondenceAddress(nationality, status) &&
                !countryDropdown.includes(value)
              ) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        overseas: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validatePincodeField(value, context, 'overseas')),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validateOverseasAddressFields(value, context)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              if (!value && checkForCorrespondenceAddress(nationality, status)) {
                return true;
              }
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (value.toUpperCase() === 'INDIA') {
                return createError({
                  message: "Country can't be choosen as INDIA",
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validateOverseasAddressFields(value, context)
          //   ),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL);
              }
              return true;
            }),
          address3: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            ),
          pincode: yup
            .string()
            .trim()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status = '' } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                return validatePincodeField(value, context, 'permanent');
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { countryDropdown = [] } = optionsContext || {};
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { nationality, status } = currentApplicant.address;
              const { permanentAddressSameAsCorresponding = false } = checkForCorrespondenceAddress(
                nationality,
                status
              )
                ? currentApplicant.address.correspondence || {}
                : currentApplicant.address.overseas || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                // if (
                //   applicantStatusMasters[status || ''] === applicantStatusMasters.NRI &&
                //   value.toUpperCase() === 'INDIA'
                // ) {
                //   return createError({
                //     message:
                //       "If the Status is Individual (NRI) then Country can't be choosen as INDIA",
                //     path,
                //   });
                // }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // country: yup
          //   .string()
          //   .nullable()
          //   .test('country', 'Country is required', (value, context) =>
          //     validatePermanentAddressFields(value, context)
          //   ),
        }),
      }),
    })
  ),
});

export const KYCDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      politicallyExposedPersonStatus: yup
        .string()
        .oneOf(PEPsMasters, 'Invalid value for PEP Status')
        .required('PEP status is required'),
      netWorthDate: yup.string().nullable().required('Net worth as on Date is required'),
      grossAnnualIncome: yup.string().nullable().required('Gross Annual Income is required'),
      grossAnnualIncomeDate: yup
        .string()
        .nullable()
        .required('Gross Annual Income as on Date is required'),
      netWorth: yup.number().nullable().required('Networth is required'),
      ckycNo: yup.number().test('ckycNo', (value, context) => {
        const {
          options: { context: optionsContext },
          createError,
          path,
        } = context;
        const { applicants } = optionsContext || {};
        if (!value) {
          return true;
        }
        if (value?.toString().length !== 14) {
          return createError({
            message: 'Invalid CKYC No',
            path,
          });
        }
        if (
          applicants.filter(
            (applicant: Applicant) => applicant.ckycNo?.toString() === value.toString()
          ).length !== 1
        ) {
          return createError({
            message:
              'There is already same CKYC Number for an applicant associated with this application',
            path,
          });
        }
        return true;
      }),
    })
  ),
});

const createSchemaForNonIndianResident = (validationMessage: string) => {
  return {
    is: (value: string) => value.toString() === 'true',
    then: yup.string().required(validationMessage),
  };
};

export const FATCAValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      typeOfAddressProvidedAtKRA: yup
        .string()
        .oneOf(addressTypesMasters, 'Invalid value for Type of Address Provided At KRA')
        .required('Please select type of address provided'),
      placeOfBirth: yup
        .string()
        .required('Please enter place of birth')
        .matches(stringRegex, 'Special characters and numbers not allowed'),
      // countryOfBirth: yup
      //   .string()
      //   .required('Please enter country of birth')
      //   .matches(stringRegex, 'Please enter country of birth'),
      countryOfBirth: yup
        .string()
        .nullable()
        .test('countryOfBirth', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'country of birth is required',
              path,
            });
          }
          if (!countryDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of birth',
              path,
            });
          }
          return true;
        }),
      // countryOfNationality: yup
      //   .string()
      //   .required('Please enter country of nationality')
      //   .matches(stringRegex, 'Please enter country of nationality'),
      countryOfNationality: yup
        .string()
        .nullable()
        .test('countryOfNationality', (value, context) => {
          const fieldValue = value === null ? '' : value;
          const { options, createError, path, parent } = context;
          const { context: optionsContext } = options as InternalOptions<individualFatcaValues>;
          const { countryDropdown = [] } = optionsContext || {};
          if (!fieldValue) {
            return createError({
              message: 'country of nationality is required',
              path,
            });
          }
          if (!countryDropdown.includes(fieldValue)) {
            return createError({
              message: 'Invalid value for country of nationality',
              path,
            });
          }
          return true;
        }),
      taxCountryName: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter tax country name')
        ),
      taxID: yup
        .string()
        .max(20, 'Invalid Tax ID')
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter Tax ID')
        ),
      idType: yup
        .string()
        .when(
          'taxResidentOfAnyCountryOtherThanIndia',
          createSchemaForNonIndianResident('Please enter ID Type')
        ),
      // nameOfEntity: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter name of entity')
      //   ),
      // dateOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter date of incorporation')
      //   ),
      // cityOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter city of incorporation')
      //   ),
      // countryOfIncorporation: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter country of incorporation')
      //   ),
      // entityExcemptionCode: yup
      //   .string()
      //   .when(
      //     'taxResidentOfAnyCountryOtherThanIndia',
      //     buildValidationSchema('Please enter entity exemption code')
      //   ),
    })
  ),
});

// export const riskProfileScheme = yup.object().shape({
//   riskprofiles: yup.array().of(
//     yup.object().shape({
//       answer: yup.string().nullable().required('Field is required'),
//       otherValue: yup
//         .string()
//         .nullable()
//         .when('answer', {
//           is: (answer: string) => answer === 'others',
//           then: yup.string().nullable().required('Field is required'),
//         }),
//     })
//   ),
// });

export const investmentPreferenceSchema = yup.object().shape({
  investmentpreferences: yup.array().of(
    yup.object().shape({
      // answer: yup.string().nullable().required('Field is required'),
      value: yup
        .string()
        .nullable()
        .when('answer', {
          is: (answer: string) => answer === 'yes',
          then: yup.string().nullable().required('Field is required'),
        }),
    })
  ),
  assetallocationguidelines: yup.array().of(
    yup.object().shape({
      // answer: yup.string().nullable().required('Field is required'),
      value: yup
        .string()
        .nullable()
        .when('answer', {
          is: (answer: string) => answer === 'yes',
          then: yup.string().nullable().required('Field is required'),
        }),
    })
  ),
});

export const adminSupportValidation = yup.object().shape({
  supportType: yup.string().required('Support Type is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  countryCode: yup
    .string()
    .nullable()
    .test('countryCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      return true;
    }),
});

const activeEntriesValidationForRelatedPerson = (validationMessage: string) => {
  return {
    is: (value: boolean) => value,
    then: yup.string().nullable().required(validationMessage),
  };
};

export const relatedPersonSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      relatedpersons: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .nullable()
            .matches(stringRegex, 'Special characters not allowed')
            .when('isActive', activeEntriesValidationForRelatedPerson('Full Name is required')),
          relationshipType: yup
            .string()
            .nullable()
            .when('isActive', {
              is: (isActive: boolean) => isActive,
              then: yup
                .string()
                .nullable()
                .oneOf(relatedRelations, 'Invalid value for Nominee Relationship')
                .required('Please select relationship'),
            }),
          dateOfBirth: yup
            .string()
            .nullable()
            .test('dateOfBirth', (value, context) => {
              const fieldValue = value === undefined ? '' : value;
              const { path, createError, parent } = context;
              if (!fieldValue && parent.isActive) {
                return createError({
                  message: 'Date of Birth is required',
                  path,
                });
              }
              if (futureAge(fieldValue || '') && parent.isActive) {
                return createError({
                  message: 'Invalid Date',
                  path,
                });
              }
              if (maxAge(fieldValue || '') && parent.isActive) {
                return createError({
                  message: 'Age should be less than 125',
                  path,
                });
              }
              return true;
            }),
          relationshipTypeOther: yup
            .string()
            .nullable()
            .when(['relationshipType', 'isActive'], {
              is: (relationshipType: string | null, isActive: boolean) =>
                relationshipType === 'OTHERS' && isActive,
              then: yup
                .string()
                .nullable()
                .matches(stringRegex, 'Special characters not allowed')
                .required('Please Specify Relationship'),
            }),
          panNo: yup
            .string()
            .nullable()
            .matches(individualPanRegex, 'Only individual PANs are allowed')
            .test('panNo', (value, context) => {
              const { createError, path, parent } = context;
              if (!value && parent.dateOfBirth && !isMinor(parent.dateOfBirth) && parent.isActive) {
                return createError({
                  message: 'PAN is required',
                  path,
                });
              }
              return true;
            }),
        })
      ),
    })
  ),
});

export const nomineeDetailsSchema = (applicants?: Partial<Applicant>[]) =>
  yup.object().shape({
    nominees: yup.array().of(
      yup.object().shape({
        nomineeName: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(stringRegex, 'Special characters and numbers not allowed')
              .required('Nominee Name is required'),
          }),
        nomineeRelationship: yup.string().when(['Relationship', 'isActive'], {
          is: (Relationship: string | null, isActive: boolean) =>
            isActive && Relationship === 'OTHERS',
          then: yup
            .string()
            .matches(stringRegex, 'Special characters and numbers not allowed')
            .required('Please Specify Relationship'),
        }),
        Relationship: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .oneOf(NomineeRelations, 'Invalid value for Nominee Relationship')
              .required('Please select relationship'),
          }),

        dateOfBirth: yup
          .string()
          .nullable()
          .test('dateOfBirth', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.isActive) {
              return createError({
                message: 'Date of Birth is required',
                path,
              });
            }
            if (futureAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Invalid Date',
                path,
              });
            }
            if (maxAge(fieldValue || '') && parent.isActive) {
              return createError({
                message: 'Age should be less than 125',
                path,
              });
            }
            return true;
          }),
        nomineePercentage: yup.number().when('isActive', {
          is: (isActive: boolean) => isActive,
          then: yup
            .number()
            .required('Nominee % is required')
            .typeError('Nominee % should be a number')
            .test(
              'nomineePercentage',
              'Total Nominee % should be equal to 100%',
              (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                } = context;
                if (value === 0) {
                  return createError({
                    message: 'Nominee % should be greater then 0',
                    path,
                  });
                }
                const { nominees } = optionsContext || {};
                const total = nominees
                  .filter((_nominee: NomineeType) => _nominee.isActive)
                  .map((nominee: NomineeType) => Number(nominee.nomineePercentage))
                  .reduce((a: number, b: number) => a + b, 0);
                return nominees.length && total === 100;
              }
            ),
        }),
        guardianName: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup.string().nullable().required('Guardian Name is required'),
          }),
        nomineePan: yup
          .string()
          .nullable()
          .when('isActive', {
            is: (isActive: boolean) => isActive,
            then: yup
              .string()
              .nullable()
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineePan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const { nominees = [] } = optionsContext || {};
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                // if (!value && !isMinor(parent.dateOfBirth || '')) {
                //   return createError({
                //     message: 'Nominee PAN is required',
                //     path,
                //   });
                // }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as nominee PAN',
                    path,
                  });
                }
                if (
                  value &&
                  nominees.filter((nominee: NomineeType) => nominee.nomineePan === value).length !==
                    1
                ) {
                  return createError({
                    message:
                      'There is already same pan for a nominee associated with this application',
                    path,
                  });
                }
                if (value && parent.nomineeGuardianPan && value === parent.nomineeGuardianPan) {
                  return createError({
                    message: 'Nominee Pan should not be same as guardian PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
        nomineeGuardianPan: yup
          .string()
          .nullable()
          .when(['dateOfBirth', 'isActive'], {
            is: (dateOfBirth: string | null, isActive: boolean) =>
              isActive && isMinor(dateOfBirth || ''),
            then: yup
              .string()
              .nullable()
              .required('Guardian PAN is required')
              .matches(individualPanRegex, 'Only individual PANs are allowed')
              .test('nomineeGuardianPan', (value, context) => {
                const {
                  options: { context: optionsContext },
                  createError,
                  path,
                  parent,
                } = context;
                const applicantsPan = applicants?.map((applicant) => applicant.panNo) || [];
                // if (!value && isMinor(parent.dateOfBirth || '')) {
                //   return createError({
                //     message: 'Guardian Pan is required',
                //     path,
                //   });
                // }
                if (value && applicantsPan.length && applicantsPan?.includes(value)) {
                  return createError({
                    message: 'Applicant PAN not allowed to enter as Guardian PAN',
                    path,
                  });
                }
                if (value && parent.nomineePan && value === parent.nomineePan) {
                  return createError({
                    message: 'Guardian Pan should not be same as Nominee PAN',
                    path,
                  });
                }
                return true;
              }),
          }),
      })
    ),
  });

export const bankDetailsSchema = (applicants: Partial<Applicant>[] | undefined) =>
  yup.object().shape({
    banks: yup.array().of(
      yup.object().shape({
        ifscCode: yup
          .string()
          .nullable()
          .required('IFSC Code is required')
          .matches(ifscRegex, 'Invalid IFSC Code'),
        bankAccountNumber: yup
          .string()
          .nullable()
          .required('Bank Account Number is required')
          .matches(accountNumberRegex, 'Please enter valid Account Number')
          .test('bankAccountNumber', 'Account number already used', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            return banks.filter((bank: Bank) => bank.bankAccountNumber === value).length === 1;
          }),
        bankAccountType: yup
          .string()
          .nullable()
          // .required('Bank Account Type is required')
          .test('bankAccountType', 'Bank Account Type is required', (value, context) => {
            const {
              options: { context: optionsContext },
              createError,
              path,
              parent,
            } = context;
            const fieldValue = value === null ? '' : value;
            const { applicationType, status, banks } = optionsContext || {};
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              !['huf'].includes(applicants ? applicants[0]?.investorType || '' : '') &&
              !fieldValue
            ) {
              return true;
            }
            if (
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL &&
              fieldValue &&
              ![BankAccountTypeMaster.Current, BankAccountTypeMaster.Savings].includes(fieldValue)
            ) {
              return false;
            } else if (
              fieldValue &&
              applicantStatusMasters[status] !== applicantStatusMasters.NRI &&
              !Object.values(BankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              !Object.values(NRIBankAccountTypeMaster).includes(fieldValue)
            ) {
              return false;
            }
            if (
              fieldValue &&
              applicantStatusMasters[status] === applicantStatusMasters.NRI &&
              banks.filter(
                (bank: Bank) =>
                  Object.values(NRIBankAccountTypeMaster).includes(bank.bankAccountType) &&
                  Object.values(NRIBankAccountTypeMaster).includes(fieldValue) &&
                  bank.bankAccountType != fieldValue
              ).length === 1
            ) {
              return createError({
                message: 'Both Bank Account Type should be same',
                path,
              });
            }

            return !!fieldValue;
          }),
        bankName: yup.string().nullable().required('Bank Name is required'),
        bankBranch: yup.string().nullable().required('Bank Branch is required'),
        defaultBankAccount: yup
          .bool()
          .test('defaultBankAccount', 'Please check the default bank account', (value, context) => {
            const {
              options: { context: optionsContext },
            } = context;
            const { banks } = optionsContext || {};
            const isDefaultBankSelected = banks.some((bank: Bank) => bank.defaultBankAccount);
            return isDefaultBankSelected;
          }),
      })
    ),
  });

export const investmentPaymentDetailsSchema = (
  minCommitmentAmount: number,
  maxCommitmentAmount: number,
  minContributionPercentage: number
) =>
  yup.object().shape({
    schemeId: yup.string().required('Fund Scheme is required'),
    planId: yup.string().required('Class Plan is required'),
    commitmentAmount: yup
      .number()
      .nullable()
      .min(
        minCommitmentAmount,
        `Commitment Amount should be greater than or equal to ${currencyConversion(
          minCommitmentAmount
        )}`
      )
      .max(
        maxCommitmentAmount,
        `Commitment Amount should be between ${
          currencyConversion(minCommitmentAmount) || '1 Cr'
        } and ${currencyConversion(maxCommitmentAmount) || '2 Cr'}`
      )
      .required('Commitment Amount is required'),
    setupFeePercentage: yup
      .number()
      .nullable()
      .required('Setup Fee Percentage is required')
      .lessThan(101, 'Setup Fee Percentage should be less then 100%'),
    contributionChequeAmount: yup
      .number()
      .nullable()
      .required('Contribution Cheque Amount is required')
      .max(
        yup.ref('commitmentAmount'),
        'Contribution Amount should not be greater than commitment amount'
      )
      .test(
        'contributionChequeAmount',
        `${
          Number(minContributionPercentage) === 100
            ? `Contribution Amount should be equal to commitment amount`
            : `Contribution Amount should be greater than or equal to ${minContributionPercentage}% of commitment amount`
        }`,
        (value, context) => {
          const { parent } = context;
          const { commitmentAmount } = parent;
          return (
            (100 * Number(value)) / Number(commitmentAmount) >= Number(minContributionPercentage)
          );
        }
      ),
    setupFeeTDS: yup
      .number()
      .nullable()
      .min(0, `TDS should be greater than 0`)
      .max(yup.ref('totalSetupFee'), 'TDS should not be greater than Total Setup Fee'),
  });

export const documentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (
                        documentType === 'bankAccountProof' &&
                        !fieldValue &&
                        ((!isOnboardingTypeMinor(applicants[0].onboardingType) &&
                          banks &&
                          banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                            .length !== 0) ||
                          isOnboardingTypeMinor(applicants[0].onboardingType)) &&
                        Number(indexes[0]) === 0
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                      if (documentType === 'bankAccountProof' && Number(indexes[0]) !== 0) {
                        return true;
                      }
                      if (
                        !fieldValue &&
                        required === 'true' &&
                        documentType !== 'bankAccountProof'
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { applicants = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (
                        !value &&
                        ((!isOnboardingTypeMinor(applicants[0].onboardingType) &&
                          banks &&
                          banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                            .length !== 0) ||
                          isOnboardingTypeMinor(applicants[0].onboardingType)) &&
                        Number(indexes[0]) === 0 &&
                        documentType === 'bankAccountProof'
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                      if (!value && required === 'true' && documentType !== 'bankAccountProof') {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  nominees: yup.array().of(
    yup.object().shape({
      nomineedocuments: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [] } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, DocumentValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<DocumentValues> & { index: number };
                    const { nominees = [], banks } = optionsContext || ({} as DocumentValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName, documentType } =
                      nominees[indexes[0]].nomineedocuments[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value && required === 'true') {
                      return createError({
                        message: `Please upload ${documentName}`,
                        path,
                      });
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
  // applicants: yup.array().of(
  //   yup.object().shape({
  //     documents: yup.object().shape({
  //       // identityProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select identity proof'),
  //       //   documentId: yup.string().required('Please upload Identity Proof'),
  //       // }),
  //       // addressProof: yup.object().shape({
  //       //   documentName: yup.string().required('Please select Address Proof'),
  //       //   documentId: yup.string().required('Please upload Address Proof'),
  //       // }),
  //       bankAccountProof: yup.object().shape({
  //         documentName: yup
  //           .string()
  //           .test('documentName', 'Please select Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),

  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload Bank Account Proof', (value, context) =>
  //             DocumentRequiredForPennyDrop(value, context)
  //           ),
  //       }),
  //       poaNotarized: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload POA Notarized Document', (value, context) =>
  //             POADocumentValidation(value, context, 'poaNotarized')
  //           ),
  //       }),
  //       investorsConsent: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', "Please upload Investor's Consent Document", (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //       fatca: yup.object().shape({
  //         documentId: yup
  //           .string()
  //           .test('documentId', 'Please upload FATCA Document', (value, context) =>
  //             POADocumentValidation(value, context)
  //           ),
  //       }),
  //     }),
  //   })
  // ),
});

export const userManagementRmSchema = (locationMaster: mdmsLocationList[]) =>
  yup.object().shape({
    name: yup.string().nullable().required('RM Name is required'),
    email: yup
      .string()
      .nullable()
      .matches(emailRegex, 'Invalid email id')
      .required('Email is required'),
    phone: yup
      .string()
      .nullable()
      .min(8, 'Invalid Phone number')
      .max(16, 'Invalid Phone number')
      .matches(phoneRegExp, 'Invalid Phone number')
      .required('Phone Number is required'),
    iwappRmId: yup.string().nullable().required('Iwapp Rm Id is required'),
    roleType: yup
      .string()
      .nullable()
      .required('Role Type is required')
      .oneOf(Object.keys(RoleTypes), 'Invalid Role Type'),
    amcRmIds: yup
      .array()
      .nullable()
      .when('roleType', {
        is: (roleType: string) => rmRoleTypeService(roleType || ''),
        then: yup.array().nullable().min(1, "Select AMC's RM").required("Select AMC's RM"),
      }),
    location: yup
      .string()
      .nullable()
      .required('Location is required')
      .oneOf(
        locationMaster.map((loc) => loc.key),
        'Invalid location'
      ),
    //   phone: yup
    //   .string()
    //   .nullable()
    //   .test('phone', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === parent.countryNameAndCode) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value) {
    //       return createError({
    //         message: 'Phone is required',
    //         path,
    //       });
    //     }
    //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }
    //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Phone number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
    // countryNameAndCode: yup
    //   .string()
    //   .nullable()
    //   .test('countryNameAndCode', (value, context) => {
    //     const { createError, path, parent } = context;
    //     const codesList = getCountryCodes()
    //       .map((codes) => {
    //         if (codes.label === value) {
    //           return codes.countryCode;
    //         }
    //       })
    //       .filter((ele) => ele)
    //       .toString();
    //     if (!value) {
    //       return createError({
    //         message: 'Country Code is required',
    //         path,
    //       });
    //     }
    //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
    //       return createError({
    //         message: 'Invalid Mobile number',
    //         path,
    //       });
    //     }

    //     return true;
    //   }),
  });

export const DistributorSchema = yup.object().shape({
  name: yup.string().nullable().required('Distributor Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().required('Email is required'),
    }),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Contact number')
    .max(16, 'Invalid Contact number')
    .matches(phoneRegExp, 'Invalid Contact number')
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('Contact Number is required'),
    }),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value && parent.type=== 'individual') {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value&&parent.type=== 'individual') {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  arnCode: yup
    .string()
    .nullable()
    .matches(arnCodeRegex, 'Invalid ARN Code Ex:- ARN-12345')
    .required('ARN Code is required'),
  rmId: yup
    .string()
    .nullable()
    .when('type', {
      is: (value: string) => value === 'individual',
      then: yup.string().nullable().required('RM Name is required'),
    }),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const TrusteeAndFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  dateOfBirth: yup
    .string()
    .nullable()
    .test(
      'dateOfBirth',
      'Age should be greater than 18',
      (dateOfBirth) => !isMinor(dateOfBirth || '')
    ),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // .required('Date of Birth is required'),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const aifApproverSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const addFundManagerSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid mobile number')
    .max(16, 'Invalid mobile number')
    .matches(phoneRegExp, 'Invalid mobile number'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  leegalityName: yup.string().nullable().required('Leegality Name is required'),
  // pincode: yup.string().required('Please Enter Pincode'),
  // buildingNo: yup.string().required('Please Enter Building Number'),
  // streetName: yup.string().required('Please Enter Street Name'),
  // city: yup.string().required('Please Enter City'),
  // state: yup.string().required('Please Enter State'),
  // country: yup.string().required('Please Enter Country'),
});

export const AuthorisedSignatoryAndPOASchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Mobile number')
    .max(16, 'Invalid Mobile number')
    .matches(phoneRegExp, 'Invalid Mobile number')
    .required('Mobile Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // distributorId: yup.string().required('Please Enter Distridutor Id'),
  // amcId: yup.string().required('Please Enter AmcId'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

export const DistributorRmSchema = yup.object().shape({
  name: yup.string().nullable().required("Distributor's RM name is required"),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .min(8, 'Invalid Contact number')
    .max(16, 'Invalid Contact number')
    .matches(phoneRegExp, 'Invalid Contact number')
    .required('Contact Number is required'),
  //   phone: yup
  //   .string()
  //   .nullable()
  //   .test('phone', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === parent.countryNameAndCode) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Phone is required',
  //         path,
  //       });
  //     }
  //     if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }
  //     if (!isValidPhoneNumber(value, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Phone number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  // countryNameAndCode: yup
  //   .string()
  //   .nullable()
  //   .test('countryNameAndCode', (value, context) => {
  //     const { createError, path, parent } = context;
  //     const codesList = getCountryCodes()
  //       .map((codes) => {
  //         if (codes.label === value) {
  //           return codes.countryCode;
  //         }
  //       })
  //       .filter((ele) => ele)
  //       .toString();
  //     if (!value) {
  //       return createError({
  //         message: 'Country Code is required',
  //         path,
  //       });
  //     }
  //     if (parent.phone && !isValidPhoneNumber(parent.phone, codesList as CountryCode)) {
  //       return createError({
  //         message: 'Invalid Mobile number',
  //         path,
  //       });
  //     }

  //     return true;
  //   }),
  //distributors_RM_Code: yup.string().required('Please Enter Distributor RM code'),
  rmId: yup.string().nullable().required("AMC's RM name is required"),
  // panNumber: yup
  //   .string()
  //   .uppercase()
  //   .length(10, 'PAN must be exactly 10 characters')
  //   .matches(individualPanRegex, 'Only individual PANs are allowed currently')
  //   .required('PAN is required'),
  // pincode: yup.string().nullable().required('Please Enter Pincode'),
  // buildingNo: yup.string().nullable().required('Please Enter Building Number'),
  // streetName: yup.string().nullable().required('Please Enter Street Name'),
  // city: yup.string().nullable().required('Please Enter City'),
  // state: yup.string().nullable().required('Please Enter State'),
  // country: yup.string().nullable().required('Please Enter Country'),
});

// name,
// email,
// countryCode,
// phone,
// panNumber,
// distributors_RM_Code,
// rmId,
// distributorId,
// pincode,
// buildingNo,
// streetName,
// city,
// state,
// country,

// pan: yup
// .string()
// .nullable()
// .uppercase()
// .length(10, 'PAN must be exactly 10 characters')
// .matches(individualPanRegex, 'Only individual PANs are allowed')
// .test('pan', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesPanNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'PAN is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
//     return createError({
//       message:
//         'There is already same pan number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),

// mobile: yup
// .string()
// .nullable()
// .min(8, 'Invalid Mobile Number')
// .max(16, 'Invalid Mobile Number')
// .matches(phoneRegExp, 'Invalid Mobile Number')
// .test('mobile', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesMobileNumbers = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Mobile Number is required',
//       path,
//     });
//   }
//   if (
//     authorisedSignatoriesMobileNumbers &&
//     authorisedSignatoriesMobileNumbers.includes(value)
//   ) {
//     return createError({
//       message:
//         'There is already same mobile number for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
// email: yup
// .string()
// .nullable()
// .matches(emailRegex, 'invalid Email ID')
// .test('email', (value, context) => {
//   const { path, createError, options } = context;
//   const { context: optionsContext } = options as InternalOptions<Groups[]>;
//   const authorisedSignatoriesEmailID = optionsContext
//     ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
//     .flat();
//   if (!value) {
//     return createError({
//       message: 'Email ID is required',
//       path,
//     });
//   }
//   if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
//     return createError({
//       message:
//         'There is already same Email ID for an Authorised Signatory associated with this application',
//       path,
//     });
//   }

//   return true;
// }),
export const amcAdminSchema = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'Invalid email id')
    .required('Email is required'),
  phone: yup
    .string()
    .nullable()
    .required('Mobile Number is required')
    .min(8, 'Invalid Mobile Number')
    .max(16, 'Invalid Mobile Number')
    .matches(phoneRegExp, 'Invalid Mobile Number'),
});

export const NonIndividualContributorValidationSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      name: yup.string().nullable().required('Name Of Entity is required'),
      cityOfIncorporation: yup
        .string()
        .nullable()
        .required('Place of Registration/Incorporation is required'),
      dateOfBirth: yup
        .string()
        .nullable()
        .required('Date of Registration/Incorporation is required'),
      ckycNo: yup
        .string()
        .nullable()
        .test('ckycNo', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const {
            options: { context: optionsContext },
            createError,
            path,
          } = context;
          const { applicants } = optionsContext || {};
          if (!fieldValue) {
            return true;
          } else if (fieldValue.length !== 14) {
            return createError({
              message: 'Invalid CKYC No./CKYC Acknowledge No./KIN No',
              path,
            });
          } else if (!numberRegex.test(fieldValue)) {
            return createError({
              message: 'CKYC No./CKYC Acknowledge No./KIN No allows only digits',
              path,
            });
          } else if (
            applicants.filter((applicant: Applicant) => applicant.ckycNo === fieldValue).length > 1
          ) {
            return createError({
              message:
                'There is already same CKYC Number for an applicant associated with this application',
              path,
            });
          }
          return true;
        }),
      panNo: yup
        .string()
        .nullable()
        .uppercase()
        .length(10, 'PAN must be exactly 10 characters')
        .matches(nonIndividualPanRegex, 'invalid PAN')
        .required('PAN is required'),
      investorType: yup
        .string()
        .nullable()
        .oneOf(Object.keys(investorTypeMasters), 'Invalid value for Investor Type')
        .required('Please Select Investor Type'),
      netWorth: yup
        .string()
        .nullable()
        .test('netWorth', (value, context) => {
          const fieldValue = value === undefined ? '' : value;
          const { path, createError } = context;
          if (!fieldValue) {
            return createError({
              message: 'Net Worth is required',
              path,
            });
          }
          if (!amountRegex.test(fieldValue)) {
            return createError({
              message: 'Invalid amount',
              path,
            });
          }
          return true;
        }),
      grossAnnualIncome: yup
        .string()
        .nullable()
        .oneOf(grossAnnualMasters, 'Invalid value for Gross Annual Income')
        .required('Gross Annual Income is required'),
    })
  ),
});

const validateNonIndividualPincodeField = (
  value: string | null | undefined,
  context: yup.TestContext<Record<string, Values>>
) => {
  const fieldValue = value === undefined ? '' : value;
  const { options, path, createError, parent } = context;
  const { context: optionsContext, index } =
    options as InternalOptions<NonIndividualContactValues> & {
      index: number;
    };
  const { applicants = [] } = optionsContext || {};
  if (!fieldValue) {
    return createError({
      message: 'Pincode is required',
      path,
    });
  }
  if (parent.country?.toLowerCase() === 'india' && !indianPin.test(fieldValue)) {
    return createError({
      message: 'Pincode code should be 6 digit code',
      path,
    });
  }
  if (parent.country?.toLowerCase() !== 'india' && !alphaNumeric.test(fieldValue)) {
    return createError({
      message: 'Invalid Pincode',
      path,
    });
  }
  return true;
};

export const nonIndividualContactDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      contactperson: yup.object().shape({
        address1: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
          )
          .required('Address line 1 is required'),
        address2: yup
          .string()
          .nullable()
          .matches(
            addressRegex,
            "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
          )
          .required('Address line 2 is required'),

        landLineNumber: yup
          .string()
          .nullable()
          .test('landLineNumber', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.stdCode) {
              return createError({
                message: 'Please enter Landline number',
                path,
              });
            } else if (parent.landLineNumber && !numberRegex.test(parent.landLineNumber)) {
              return createError({
                message: 'Please enter valid Landline Number',
                path,
              });
            }
            return true;
          }),
        stdCode: yup
          .string()
          .nullable()
          .test('stdCode', (value, context) => {
            const fieldValue = value === undefined ? '' : value;
            const { path, createError, parent } = context;
            if (!fieldValue && parent.landLineNumber) {
              return createError({
                message: 'Please enter STD Code',
                path,
              });
            } else if (parent.stdCode && !numberRegex.test(parent.stdCode)) {
              return createError({
                message: 'Please enter valid STD Code',
                path,
              });
            }
            return true;
          }),

        city: yup.string().nullable().required('City is required'),
        state: yup.string().nullable().required('State is required'),
        country: yup
          .string()
          .nullable()
          .test('country', (value, context) => {
            const { options, createError, path } = context;
            const { context: optionsContext } =
              options as InternalOptions<NonIndividualContactValues>;
            const { countryDropdown = [] } = optionsContext || {};
            if (!value) {
              return createError({
                message: 'Country is required',
                path,
              });
            }
            if (!countryDropdown.includes(value)) {
              return createError({
                message: 'Invalid value for Country',
                path,
              });
            }
            return true;
          }),
        pincode: yup
          .string()
          .nullable()
          .test('pincode', (value, context) => validateNonIndividualPincodeField(value, context)),
        name: yup.string().nullable().required('Name is required'),
        email: yup
          .string()
          .nullable()
          .matches(emailRegex, 'invalid Email ID')
          .required('Email is required'),
        mobile: yup
          .string()
          .nullable()
          .test('mobile', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === parent.countryNameAndCode) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Mobile is required',
                path,
              });
            }
            if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }
            if (!isValidPhoneNumber(value, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
        countryNameAndCode: yup
          .string()
          .nullable()
          .test('countryNameAndCode', (value, context) => {
            const { createError, path, parent } = context;
            const codesList = getCountryCodes()
              .map((codes) => {
                if (codes.label === value) {
                  return codes.countryCode;
                }
              })
              .filter((ele) => ele)
              .toString();
            if (!value) {
              return createError({
                message: 'Country Code is required',
                path,
              });
            }
            if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
              return createError({
                message: 'Invalid Mobile number',
                path,
              });
            }

            return true;
          }),
      }),
      address: yup.object().shape({
        correspondence: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .required('Address line 1 is required'),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.correspondence || {};
              if (!fetchedFromKRA && !value) {
                return false;
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => validateNonIndividualPincodeField(value, context)),
          city: yup.string().nullable().required('City is required'),
          state: yup.string().nullable().required('State is required'),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext } =
                options as InternalOptions<NonIndividualContactValues>;
              const { countryDropdown = [] } = optionsContext || {};
              if (!value) {
                return createError({
                  message: 'Country is required',
                  path,
                });
              }
              if (!countryDropdown.includes(value)) {
                return createError({
                  message: 'Invalid value for Country',
                  path,
                });
              }
              return true;
            }),
        }),
        permanent: yup.object().shape({
          address1: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
            )
            .test('address1', 'Address line 1 is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          address2: yup
            .string()
            .nullable()
            .matches(
              addressRegex,
              "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
            )
            // .test('address2', 'Address2 is required', (value, context) =>
            //   validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            // ),
            .test('address2', 'Address line 2 is required', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { fetchedFromKRA = null } = currentApplicant.address.permanent || {};
              if (!fetchedFromKRA) {
                return validatePermanentAddressFields(
                  value,
                  context,
                  APPLICATION_TYPE.NON_INDIVIDUAL
                );
              }
              return true;
            }),
          pincode: yup
            .string()
            .nullable()
            .test('pincode', (value, context) => {
              const { options } = context;
              const { context: optionsContext, index } = options as InternalOptions<Values> & {
                index: number;
              };
              const { applicants = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                return validateNonIndividualPincodeField(value, context);
              }
              return true;
            }),
          city: yup
            .string()
            .nullable()
            .test('city', 'City is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          state: yup
            .string()
            .nullable()
            .test('state', 'State is required', (value, context) =>
              validatePermanentAddressFields(value, context, APPLICATION_TYPE.NON_INDIVIDUAL)
            ),
          country: yup
            .string()
            .nullable()
            .test('country', (value, context) => {
              const { options, createError, path } = context;
              const { context: optionsContext, index } =
                options as InternalOptions<NonIndividualContactValues> & {
                  index: number;
                };
              const { applicants = [], countryDropdown = [] } = optionsContext || {};
              const currentApplicant = applicants[index] || {};
              const { permanentAddressSameAsCorresponding = false } =
                currentApplicant.address.correspondence || {};
              if (!permanentAddressSameAsCorresponding) {
                if (!value) {
                  return createError({
                    message: 'Country is required',
                    path,
                  });
                }
                if (!countryDropdown.includes(value)) {
                  return createError({
                    message: 'Invalid value for Country',
                    path,
                  });
                }
              }
              return true;
            }),
          // .test('country', 'Country is required', (value, context) =>
          //   validatePermanentAddressFields(value, context)
          // ),
        }),
      }),
    })
  ),
});

export const nonIndividualDocumentDetailsSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      documents: yup.array().of(
        yup.object().shape({
          documentsList: yup.array().of(
            yup.object().shape({
              documentName: yup
                .string()
                .nullable()
                .test(
                  'documentName',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const {
                      required,
                      documentName,
                      documentType,
                      options: optionsDropdown,
                    } = applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    const fieldValue = optionsDropdown && value && optionsDropdown.includes(value);
                    if (optionsDropdown) {
                      if (
                        documentType === 'bankAccountProof' &&
                        !fieldValue &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                      if (!fieldValue && required === 'true') {
                        return createError({
                          message: `Please Select ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
              documentId: yup
                .string()
                .nullable()
                .test(
                  'documentId',
                  (
                    value: string | undefined | null,
                    context: yup.TestContext<Record<string, NonIndividualDocValues>>
                  ) => {
                    const { options, createError, path } = context;
                    const { context: optionsContext, parent } =
                      options as InternalOptions<NonIndividualDocValues> & { index: number };
                    const { applicants = [], banks } =
                      optionsContext || ({} as NonIndividualDocValues);
                    const { uniqueKey = '' } = parent || {};
                    const indexes = uniqueKey.split('-');
                    const { required, documentName } =
                      applicants[indexes[0]].documents[indexes[1]].documentsList[indexes[2]];
                    if (required === 'false') {
                      return true;
                    }
                    if (!value) {
                      if (
                        !value &&
                        banks &&
                        banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
                          .length !== 0
                      ) {
                        return createError({
                          message: `Please upload ${documentName}`,
                          path,
                        });
                      }
                    }
                    return true;
                  }
                ),
            })
          ),
        })
      ),
    })
  ),
});

export const nonIndividualAuthorisedSignatories = yup.object().shape({
  name: yup.string().nullable().required('Name is required'),
  pan: yup
    .string()
    .nullable()
    .uppercase()
    .length(10, 'PAN must be exactly 10 characters')
    .matches(individualPanRegex, 'Only individual PANs are allowed')
    .test('pan', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesPanNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.pan))
        .flat();
      if (!value) {
        return createError({
          message: 'PAN is required',
          path,
        });
      }
      if (authorisedSignatoriesPanNumbers && authorisedSignatoriesPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same pan for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  mobile: yup
    .string()
    .nullable()
    .test('mobile', (value, context) => {
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesMobileNumbers = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.mobile))
        .flat();
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === parent.countryNameAndCode) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Mobile Number is required',
          path,
        });
      }
      if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (!isValidPhoneNumber(value, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }
      if (
        authorisedSignatoriesMobileNumbers &&
        authorisedSignatoriesMobileNumbers.includes(value)
      ) {
        return createError({
          message:
            'There is already same mobile number for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),

  countryNameAndCode: yup
    .string()
    .nullable()
    .test('countryNameAndCode', (value, context) => {
      const { createError, path, parent } = context;
      const codesList = getCountryCodes()
        .map((codes) => {
          if (codes.label === value) {
            return codes.countryCode;
          }
        })
        .filter((ele) => ele)
        .toString();
      if (!value) {
        return createError({
          message: 'Country Code is required',
          path,
        });
      }
      if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
        return createError({
          message: 'Invalid Mobile number',
          path,
        });
      }

      return true;
    }),
  email: yup
    .string()
    .nullable()
    .matches(emailRegex, 'invalid Email ID')
    .test('email', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<Groups[]>;
      const authorisedSignatoriesEmailID = optionsContext
        ?.map((groups) => groups.groupsignatories.map((signatory) => signatory.email))
        .flat();
      if (!value) {
        return createError({
          message: 'Email ID is required',
          path,
        });
      }
      if (authorisedSignatoriesEmailID && authorisedSignatoriesEmailID.includes(value)) {
        return createError({
          message:
            'There is already same Email ID for an Authorised Signatory associated with this application',
          path,
        });
      }

      return true;
    }),
  designation: yup.string().nullable().required('Designation is required'),
});

const fieldValidation = (
  value: string | undefined | null | number,
  context: yup.TestContext<Record<string, ubo>>,
  mes: string
) => {
  const fieldValue = value === undefined ? '' : value;
  const { path, createError, parent } = context;
  if (!fieldValue && !parent.isFetchData) {
    return createError({
      message: `${mes} is required`,
      path,
    });
  }
  return true;
};

export const nonIndividualUbo = yup.object().shape({
  panNumber: yup
    .string()
    .nullable()
    .uppercase()
    .test('panNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;

      const uboPanNumbers = optionsContext?.map((ubo) => ubo.panNumber);
      if (!value) {
        return createError({
          message: 'Taxpayer ID Number/PAN/Equivalent ID Number is required',
          path,
        });
      }
      if (uboPanNumbers && uboPanNumbers.includes(value)) {
        return createError({
          message:
            'There is already same Taxpayer ID Number/PAN/Equivalent ID Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
          path,
        });
      }

      return true;
    }),

  dob: yup
    .string()
    .nullable()
    .test('dob', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        isMinor(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (
        parent.panNumber &&
        individualPanRegex.test(parent.panNumber) &&
        maxAge(fieldValue || '')
      ) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  name: yup
    .string()
    .nullable()
    .test('name', (value, context) => {
      return fieldValidation(value, context, 'Name');
    }),
  identificationType: yup
    .string()
    .nullable()
    .test('identificationType', (value, context) => {
      return fieldValidation(value, context, 'Identification Type');
    }),
  percentageOfBeneficialInterest: yup
    .number()
    .nullable()
    .test('percentageOfBeneficialInterest', (value, context) => {
      const fieldValue = value;
      const { path, createError, parent } = context;
      if (fieldValue === null || (fieldValue === undefined && !parent.isFetchData)) {
        return createError({
          message: `Percentage Of Beneficial Interest is required`,
          path,
        });
      }
      if (fieldValue === 0 && !parent.isFetchData) {
        return createError({
          message: `Percentage Of Beneficial Interest should be greater 0`,
          path,
        });
      }
      if (fieldValue) {
        if (fieldValue < 0 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest should be greater 0`,
            path,
          });
        }
        if (fieldValue > 100 && !parent.isFetchData) {
          return createError({
            message: `Percentage Of Beneficial Interest must not exceed 100%`,
            path,
          });
        }
      }
      return true;
    }),
  countryOfTaxResidency: yup
    .string()
    .nullable()
    .test('countryOfTaxResidency', (value, context) => {
      return fieldValidation(value, context, 'Country Of Tax Residency');
    }),
  cpUboCode: yup
    .string()
    .nullable()
    .oneOf(Object.keys(CpUboCodesMaster), 'Invalid value for CP/UBO Code')
    .test('cpUboCode', (value, context) => {
      return fieldValidation(value, context, 'Cp/UboCode');
    }),
  placeAndCountryOfBirth: yup
    .string()
    .nullable()
    .test('placeAndCountryOfBirth', (value, context) => {
      return fieldValidation(value, context, 'Place And Country Of Birth');
    }),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .test('occupation', (value, context) => {
      return fieldValidation(value, context, 'Occupation');
    }),
  gender: yup
    .string()
    .nullable()
    .test('gender', (value, context) => {
      return fieldValidation(value, context, 'Gender');
    }),
  nationality: yup
    .string()
    .nullable()
    // .oneOf(nationalitiesMasters, 'Invalid value for Nationality')
    .test('nationality', (value, context) => {
      return fieldValidation(value, context, 'Nationality');
    }),
  fatherName: yup
    .string()
    .nullable()
    .test('fatherName', (value, context) => {
      return fieldValidation(value, context, 'FatherName');
    }),
  ckycNumber: yup
    .string()
    .nullable()
    .length(14, 'Invalid CKYC Number')
    .test('ckycNumber', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, options, parent } = context;
      const { context: optionsContext } = options as InternalOptions<ubo[]>;
      if (!fieldValue && !parent.isFetchData) {
        return true;
      } else if (!numberRegex.test(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message: 'CKYC Number allows only digits',
          path,
        });
      }
      const uboCkycNumbers = optionsContext?.map((ubo) => Number(ubo.ckycNumber).toString());
      if (uboCkycNumbers && uboCkycNumbers.includes(fieldValue as string) && !parent.isFetchData) {
        return createError({
          message:
            'There is already same CKYC Number for an Ultimate Benificiary Ownership (UBO) associated with this application',
          path,
        });
      }
      return true;
    }),
  address2: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address line 1 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address2', (value, context) => {
      return fieldValidation(value, context, 'Address Line 1');
    }),
  address3: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address line 2 format [special characters are not allowed except -/',&()#:.]"
    )
    .test('address3', (value, context) => {
      return fieldValidation(value, context, 'Address Line 2');
    }),
  city: yup
    .string()
    .nullable()
    .test('city', (value, context) => {
      return fieldValidation(value, context, 'City');
    }),
  state: yup
    .string()
    .nullable()
    .test('state', (value, context) => {
      return fieldValidation(value, context, 'State');
    }),
  country: yup
    .string()
    .nullable()
    .test('country', (value, context) => {
      return fieldValidation(value, context, 'Country');
    }),
  pincode: yup
    .string()
    .nullable()
    .test('pincode', (value, context) => {
      const fieldValue = value === undefined ? '' : value;
      const { path, createError, parent } = context;
      if (!fieldValue && !parent.isFetchData) {
        return createError({
          message: 'Pincode is required',
          path,
        });
      } else if (
        parent.country?.toLowerCase() === 'india' &&
        !indianPin.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Pincode length should be 6 digits',
          path,
        });
      } else if (
        parent.country?.toLowerCase() !== 'india' &&
        !alphaNumeric.test(fieldValue as string) &&
        !parent.isFetchData
      ) {
        return createError({
          message: 'Invalid Pincode',
          path,
        });
      }
      return true;
    }),
});

export const nonIndividualControllingPersons = yup.object().shape({
  name: yup.string().nullable().required('Name of controlling person is required'),
  correspondenceAddress: yup
    .string()
    .nullable()
    .matches(
      addressRegex,
      "invalid address format [special characters are not allowed except -/',&()#:.]"
    )
    .required('Correspondence address is required'),
  countryOfResidense: yup
    .string()
    .nullable()
    .required('Country of residence for tax purposes is required'),
  issuingCountry: yup.string().nullable().required('Issuing country is required'),
  controllingPersonType: yup.string().nullable().required('Controlling Person Type is required'),
  indentificationType: yup.string().nullable().required('Indentification Type is required'),
  indentificationNumber: yup
    .string()
    .nullable()
    .test('indentificationNumber', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const indentificationNumberArr = optionsContext?.map(
        (person) => person.indentificationNumber
      );
      if (!value) {
        return createError({
          message: 'Indentification Number is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (indentificationNumberArr && indentificationNumberArr.includes(value)) {
        return createError({
          message:
            'There is already same Indentification Number for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
  occupationType: yup.string().nullable().required('Occupation Type is required'),
  occupation: yup
    .string()
    .nullable()
    .oneOf(occupationDetailsMasters, 'Invalid value for Occupation')
    .required('Occupation is required'),
  dateOfBirth: yup
    .string()
    .nullable()
    .test('dateOfBirth', (value, context) => {
      const { path, createError } = context;
      if (!value) {
        return createError({
          message: 'Date of Birth is required',
          path,
        });
      }
      if (isMinor(value)) {
        return createError({
          message: 'Age should be greater than 18',
          path,
        });
      }
      if (maxAge(value)) {
        return createError({
          message: 'Age should be less than 125',
          path,
        });
      }
      return true;
    }),
  countryOfBirth: yup.string().nullable().required('Country Of Birth is required'),
  nationality: yup.string().nullable().required('Nationality is required'),
  tin: yup
    .string()
    .nullable()
    .test('tin', (value, context) => {
      const { path, createError, options } = context;
      const { context: optionsContext } = options as InternalOptions<ControllingPersons[]>;
      const controllingPersons = optionsContext?.map((person) => person.tin);
      if (!value) {
        return createError({
          message: 'TIN is required',
          path,
        });
      }
      if (value && !alphaNumeric.test(value)) {
        return createError({
          message: 'Special Characters are Not allowed',
          path,
        });
      }
      if (controllingPersons && controllingPersons.includes(value)) {
        return createError({
          message:
            'There is already same Tin for a Controlling Persons associated with this application',
          path,
        });
      }

      return true;
    }),
});

type errorObj = { fieldPath: string; fieldName: string };

const subQuestionsValidation = (
  fatca: nonIndividualQuestionsFatca,
  path: string
): errorObj | errorObj[] | undefined => {
  if (
    !fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.isMandatory === 'true' &&
    fatca.question_type !== 'Controlling_person_details'
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName:
        fatca.question_type === 'single_choice_radio'
          ? 'Field is required'
          : fatca.question_text
          ? fatca.question_text + ' is required'
          : (fatca.placeholder || 'Field') + ' is required',
    };
  }
  if (
    fatca.answer &&
    fatca.isVisible &&
    fatca.backend_key &&
    fatca.validation === 'alphaNumeric' &&
    !alphaNumeric.test(fatca.answer)
  ) {
    const fieldPath =
      path + '.' + getSubQuestionsIndex(fatca.id.split('Q')[1].split('.'), '', true);
    return {
      fieldPath: fieldPath,
      fieldName: 'Special Characters are Not allowed',
    };
  }
  if (fatca.question_type === 'group' && fatca.isVisible && fatca.sub_questions?.length) {
    return fatca.sub_questions
      ?.map((qun) => subQuestionsValidation(qun, path) as errorObj)
      .filter((ele: errorObj) => ele)
      .flat();
  }
};

export const nonIndividualFatcaSchema = yup.object().shape({
  applicants: yup.array().of(
    yup.object().shape({
      fatcadetail: yup.array().of(
        yup.object().shape({
          answer: yup
            .string()
            .nullable()
            .test('answer', (value, context) => {
              const { path, createError, parent } = context;
              if (
                !value &&
                parent.isVisible &&
                parent.isMandatory === 'true' &&
                parent.backend_key &&
                parent.question_type !== 'single_choice_radio'
              ) {
                return createError({
                  message:
                    parent.question_type === 'open_text_field'
                      ? (parent.placeholder || 'Field') + ' is required'
                      : parent.question_text
                      ? parent.question_text + ' is required'
                      : (parent.placeholder || 'Field') + ' is required',
                  path,
                });
              }
              if (
                value &&
                parent.isVisible &&
                parent.backend_key &&
                parent.validation === 'alphaNumeric' &&
                !alphaNumeric.test(value)
              ) {
                return createError({
                  message: 'Special Characters are Not allowed',
                  path,
                });
              }
              if (parent.question_type === 'group' && parent.isVisible && parent.sub_questions) {
                //subQuestionsValidation(value, context)
                const res: errorObj[] = [];
                const val = parent.sub_questions
                  ?.map((qun: nonIndividualQuestionsFatca) => {
                    const r = subQuestionsValidation(qun, path?.split('.')[0]) as errorObj;
                    return r;
                    // return (r as errorObj)
                    //   ? new yup.ValidationError(r.fieldName, '', `${r.fieldPath}.answer`)
                    //   : (r as unknown as errorObj[])?.map(
                    //       (ele: errorObj) =>
                    //         new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`)
                    //     );
                  })
                  .filter((ele: errorObj) => ele)
                  .flat();
                // .toString();
                if (val.length) {
                  const error = val.map((ele: errorObj) => {
                    return new yup.ValidationError(ele.fieldName, '', `${ele.fieldPath}.answer`);
                  });
                  if (error.length) {
                    return new yup.ValidationError(error);
                  }

                  // return createError({
                  //   message: 'Field is Required1',
                  //   path: `${val}.answer`,
                  // });
                }
                return true;
              }
              return true;
            }),
        })
      ),
    })
  ),
});

export const addPlanValidation = yup.object().shape({
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const addFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
  planCode: yup.string().nullable().required('Plan Code is required'),
  planDescription: yup.string().nullable().required('Plan Description is required'),
  schemeNature: yup.string().nullable().required('Scheme Nature is required'),
  // registrationNumber: yup.string().nullable().required('Registration Number is required'),
  fundManagerId: yup.string().nullable().required('Investment Manager Name is required'),
  trusteeId: yup.string().nullable().required('Trustee Name is required'),
  schemeCategory: yup.string().nullable().required('Scheme Category is required'),
  minCommitmentAmount: yup
    .number()
    .nullable()
    .moreThan(0, `Minimum Commitment Amount should be greater than 0`)
    .required('Minimum Commitment Amount is required'),
  maxCommitmentAmount: yup
    .number()
    .nullable()
    .min(
      yup.ref('minCommitmentAmount'),
      'Maximum Commitment Amount should be greater than or equal to Minimum Commitment Amount'
    )
    .required('Maximum Commitment Amount is required'),
});

export const editFundValidation = yup.object().shape({
  schemeCode: yup.string().nullable().required('Scheme Code is required'),
  schemeName: yup.string().nullable().required('Scheme Name is required'),
});

export const DeclareTermsAndConditionsScheme = yup.object().shape({
  isDeclareTermsAndConditions: yup.boolean().test('occupationType', (value, context) => {
    const fieldValue = value === null ? '' : value;
    const { createError, path } = context;
    if (!fieldValue) {
      return createError({
        message: 'Terms And Conditions is Required',
        path,
      });
    }
    return true;
  }),
});

export const distributorsValidationSchema = (isSupportRM: boolean, rmsList: RMType[]) =>
  yup.object().shape({
    rm_id: yup
      .string()
      .nullable()
      .test('rm_id', (value, context) => {
        const { createError, path } = context;
        if (isSupportRM && !value && !!rmsList.length) {
          return createError({
            message: 'RM is Required',
            path,
          });
        }
        return true;
      }),
  });

//<---------Investor Report Schema -------->

export const validationSchemaTransactionStatement = yup.object({
  fromDate: yup.string().nullable().required('From Date is required'),
  toDate: yup.string().nullable().required('To Date is required'),
});

export const validationSchemaClientStatement = yup.object({
  reportOptions: yup.array().min(1, 'Select atleast one option'),
  date: yup.string().nullable().required('Date is required'),
});

export const validationSchemaPortfolioSummary = yup.object({
  date: yup.string().nullable().required('Date is required'),
});

export const validationSchemaCgStatement = yup.object({
  fromDate: yup
    .string()
    .nullable()
    .when('duration', {
      is: (duration: string) => duration === 'custom',
      then: yup.string().nullable().required('From Date is required'),
    }),
  toDate: yup
    .string()
    .nullable()
    .when('duration', {
      is: (duration: string) => duration === 'custom',
      then: yup.string().nullable().required('To Date is required'),
    }),
});


import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Menu,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    Typography,
    Modal,
    RadioGroup,
    FormControlLabel,
    Radio,
    CircularProgress
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AccountType, BSECodeResponse, ClientMandateResponse, FrequencyType, GetAccountType, GetAssetClassesType, GetCategoryAverage, GetFrequencyType, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, MandateResponse, PortfolioType, RecommendationsType, SchemeList, getPortfolioType } from '../../../redux-store/types/api-types';
import { RM_create_transaction, getFrequency, getRmDetails, get_account_type, get_assest_classes, get_bse_code, get_category_average, get_client_mandateIds, get_recommendation_validation, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav, post_recommended_schemes } from '../../../redux-store/actions/userManagement';
import { Bubble } from './investor-transaction-helper';
import FormControl from '@mui/material/FormControl';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import { DeleteIcon } from '../../customSVGs';
import { ErrorType, HoldingSIPLumpsumSelectedData, HoldingSchemeData, selectedFrequencyValidationData } from './types';
import { toUpper } from 'lodash';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';

export default function SIP_Lumpsum({
    location
}: {
    location: Location<{ investorId: string; investorName: string; holdingName: HoldingSchemeData[] }>
}): JSX.Element {

    const { investorId, investorName, holdingName } = location?.state || { investorId: '', investorName: '' };

    //selected holding SIP/lumpsum schemes
    const newHoldingNameData: HoldingSIPLumpsumSelectedData[] = holdingName.map((ele: any) => {
        return { ...ele, instalmentAmt: null, investmentAmt: null, noOfInstalments: null, sipDay: "", frequencySelected: "" }
    })

    //error state
    const errorStates: ErrorType = {
        errorInvestmentAmount: false,
        errorInvestementAmountMsg: "",
        errorFrequency: false,
        errorFrequencyMsg: "",
        errorInstalmentAmount: false,
        errorInstalmentAmountMsg: "",
        errorNoOfInstalment: false,
        errorNoOfInstalmentMsg: "",
        errorSIPDay: false,
        errorSIPDayMsg: "",
        errorFirstOrderToday: false,
        errorMessageFirstOrderToday: "",
        errorMandate: false,
        errorMessageMandate: ""
    }
    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Holdings');
    const [frequencyData, setFrequencyData] = useState<any | null>(null);
    const [holdingNameData, setHoldingNameData] = useState<HoldingSIPLumpsumSelectedData[]>(newHoldingNameData);
    const [accountTypeData, setAccountTypeData] = useState<any | null>(null);
    const [rmId, setRmId] = useState<number | null>();
    const [Recommend, setRecommended] = useState<boolean>(false);
    const [error, setError] = useState<{ [key: string]: ErrorType }>({});
    const [validations, SetValidations] = useState<{ [key: string]: any }>();
    const [selectedFrequencyValidation, setSelectedFrequencyValidation] = useState<{ [key: string]: selectedFrequencyValidationData }>();
    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<string>('recommend');
    const [placeOrderResponse, setPlaceOrderResponse] = useState<any>();
    const [mandateId, setMandateId] = useState<ClientMandateResponse | null>()
    const handleRadioChange = (event: any, value: any) => {
        setRadioValue(event.target.value);
    };
    const frequencyMapping: any = {
        "DAILY": "1",
        "Weekly": "2",
        "MONTHLY": "3",
        "QUARTERLY": "4",
        "ANNUALLY": "6",
    }
    //function to handle change of menu - Explore/recommendation
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "400",
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: "24",
        p: "4",
        padding: "2rem 6rem",
    }

    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Holdings
        </Typography>,
    ];

    //function to handle change of asset class tabs
    const handleChange = (event: any, value: any) => {
        // setValue(value);

    };
    // const handleChangeSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setCheckedSwitch(event.target.checked);
    //     setFrequency("Frequency")
    //     { switchValue === 'SIP' ? setSwitchValue('Lumpsum') : setSwitchValue("SIP") }
    // };

    const open = Boolean(anchorEl);

    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }
    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };

    const isDateDisabled = (date: Date, schemeID: any): boolean => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (date < currentDate) {
            return false;
        }

        if (validations) {

            const foundValue: any = validations[schemeID]?.find((val: any) => val.SIPFREQUENCY == holdingNameData.find((value) => value.schemeID == schemeID)?.frequencySelected)
            const datesArray: any = foundValue?.SIPDATES.split(',');

            if (datesArray.includes(date.getDate().toString())) {
                return true;
            } else {
                return false;
            }
        }

        return true;

    };

    //Frequency
    // useEffect(() => {
    //     setFrequencyData(null);
    //     (async function () {
    //         try {

    //             const response = (await dispatch(
    //                 getFrequency()

    //             )) as unknown as GetFrequencyType;

    //             console.log("response-->", response);
    //             setFrequencyData(response);


    //         } catch (e) {

    //             console.log((e as Error).message);
    //         }
    //     })();
    // }, [dispatch]);

    //To get Validations for all sche,es
    useEffect(() => {
        const SIPValidationData: any = {};
        const ValidationForSelectedFrequency: any = {};
        const errorInitialData: any = {}
        holdingNameData?.map(async (item: HoldingSIPLumpsumSelectedData) => {
            const obj = await getSIPValidationData(item.SCh);
            SIPValidationData[item.schemeID] = obj.validations[1]
            ValidationForSelectedFrequency[item.schemeID] = {
                Productcode_RTA: item.SCh,
                SIPFREQUENCY: null,
                SIPDATES: "",
                SIPMINIMUMINSTALLMENTAMOUNT: "",
                SIPMAXIMUMINSTALLMENTAMOUNT: "",
                SIPMINIMUMINSTALLMENTNUMBERS: "",
                SIPMAXIMUMINSTALLMENTNUMBERS: "",
                SCHEMEISIN: "",
                SIPMINIMUMGAP: "",
                IPMAXIMUMGAP: "",
                SIPMULTIPLIERAMOUNT: ""
            }
            errorInitialData[item.schemeID] = errorStates;
        })
        SetValidations(SIPValidationData);
        setSelectedFrequencyValidation(ValidationForSelectedFrequency);
        setError(errorInitialData);
    }, [holdingName])

    // console.log("validations value", validations)
    // console.log("holdingNameData value", holdingNameData)

    //To get Validations of one schemes
    const getSIPValidationData = async (sch: string) => {
        const isComponentActive = true;
        try {
            const response = (await dispatch(
                get_recommendation_validation({ sch, TrType: "SIP" })
            )) as any;
            if (!isComponentActive) {
                return Promise.resolve();
            }
            return response;
        } catch (e) {
            console.log((e as Error).message);
        }
        return Promise.resolve();
    }

    //RMids
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    const setValidationForSelectedFrequency = (schemeId: string, selectedFrequency: string) => {
        const currentSchemeValidations = validations ? validations[schemeId] : null;
        const currentSchemeFrequencyValidation = currentSchemeValidations.filter((item: any) => item.SIPFREQUENCY == selectedFrequency)[0];
        setSelectedFrequencyValidation({ ...selectedFrequencyValidation, [schemeId]: currentSchemeFrequencyValidation });
    }


    const LumpsumValidation = () => {
        const formData = holdingNameData;
        let valid = true;
        const errorTempData: any = {};
        formData?.map((item: any) => {
            errorTempData[item.schemeID] = error[item.schemeID];
        })
        formData?.map((item: HoldingSIPLumpsumSelectedData) => {
            if (item.switchMode === true) {
                if (item.investmentAmt === null || item.investmentAmt === 0) {
                    errorTempData[item.schemeID].errorInvestmentAmount = true;
                    errorTempData[item.schemeID].errorInvestementAmountMsg = "Investment Amount can not be empty";
                    valid = false;
                }
                else if (item.investmentAmt && (item.investmentAmt < Number(item.MinimumPurchaseAmount) || item.investmentAmt > Number(item.MaximumPurchaseAmount))) {
                    errorTempData[item.schemeID].errorInvestmentAmount = true;
                    errorTempData[item.schemeID].errorInvestementAmountMsg = `Please enter amount greater than ${item.MinimumPurchaseAmount} and less than ${item.MaximumPurchaseAmount}`;
                    valid = false;
                }
                else {
                    errorTempData[item.schemeID].errorInvestmentAmount = false;
                }
            }

        })
        setError(errorTempData);
        return valid;
    }

    const SIPValidation = () => {
        const formData = holdingNameData;
        let valid = true;
        const errorTempData: any = {};
        formData?.map((item: any) => {
            errorTempData[item.schemeID] = error[item.schemeID];
        })
        formData?.map((item: HoldingSIPLumpsumSelectedData) => {
            const currentSIPValidation: selectedFrequencyValidationData | null = selectedFrequencyValidation ? selectedFrequencyValidation[item.schemeID] : null;
            console.log("no of instalments", item.noOfInstalments)
            if (item.switchMode === false) {
                if (item.frequencySelected === null || item.frequencySelected === "") {
                    errorTempData[item.schemeID].errorFrequency = true;
                    errorTempData[item.schemeID].errorFrequencyMsg = "Frequency can not be empty";
                    valid = false;
                }
                else {
                    errorTempData[item.schemeID].errorFrequency = false;
                }
                if (item.instalmentAmt === null || item.instalmentAmt === 0) {
                    errorTempData[item.schemeID].errorInstalmentAmount = true;
                    errorTempData[item.schemeID].errorInstalmentAmountMsg = "Instalment Amount can not be empty";
                    valid = false;
                }
                else if (item.instalmentAmt && (item.instalmentAmt < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT) || item.instalmentAmt > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT))) {
                    errorTempData[item.schemeID].errorInstalmentAmount = true;
                    errorTempData[item.schemeID].errorInstalmentAmountMsg = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT}`;
                    valid = false;
                }
                else {
                    errorTempData[item.schemeID].errorInstalmentAmount = false;
                }
                if (item.frequencySelected != 'DAILY') {
                    if (item.noOfInstalments == null || item.noOfInstalments <= 0) {

                        errorTempData[item.schemeID].errorNoOfInstalment = true;
                        errorTempData[item.schemeID].errorNoOfInstalmentMsg = "No. of Instalments can not be empty or zero";
                        valid = false;
                    }
                    else if (item.noOfInstalments && (item.noOfInstalments < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS) || item.noOfInstalments > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS))) {
                        errorTempData[item.schemeID].errorNoOfInstalment = true;
                        errorTempData[item.schemeID].errorNoOfInstalmentMsg = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`;
                        valid = false;
                    }
                    else {
                        errorTempData[item.schemeID].errorNoOfInstalment = false;
                    }
                }
                if (item.sipDay == "") {
                    errorTempData[item.schemeID].errorSIPDay = true;
                    errorTempData[item.schemeID].errorSIPDayMsg = "SIP Day can not be empty";
                    valid = false;
                }
                else {
                    errorTempData[item.schemeID].errorSIPDay = false;
                }
                if (radioValue !== 'recommend') {
                    if (item.firstOrderToday === null || item.firstOrderToday === undefined || item.firstOrderToday === "") {
                        errorTempData[item.schemeID].errorFirstOrderToday = true;
                        errorTempData[item.schemeID].errorMessageFirstOrderToday = "Please select Yes or No";
                        valid = false;
                    }
                    else {
                        errorTempData[item.schemeID].errorFirstOrderToday = false;
                    }
                    if (item.mandate === null || item.mandate === undefined || item.mandate === "") {
                        errorTempData[item.schemeID].errorMandate = true;
                        errorTempData[item.schemeID].errorMessageMandate = "Please select Mandate";
                        valid = false;
                    }
                    else {
                        errorTempData[item.schemeID].errorMandate = false;
                    }
                }
            }

        })
        setError(errorTempData);
        return valid;
    }
    async function getBSECode(item: any) {
        let isComponentActive = true;

        try {
            // setIsLoading(true);
            const response = (await dispatch(
                get_bse_code({
                    TrType: item.switchMode ? 'Add' : 'XSIP',
                    Amount: item.switchMode ? item.investmentAmt : item.instalmentAmt,
                    Growthoption: item?.Growthoption,
                    DividendReinvestment: item?.DividendReinvestment,
                    RTACODE: item?.SCh,
                })
            )) as unknown as BSECodeResponse;

            if (!isComponentActive) {
                return;
            }

            if (response && response.bse_code && response.bse_code.length > 0) {
                item.BSESchemeCode = response.bse_code[0][0]?.BSE_ProductCod;

            }

            // setIsLoading(false);
            return item;

        } catch (e) {
            console.log((e as Error).message);
        } finally {
            // setIsLoading(false);
        }

        return () => {
            isComponentActive = false;
        };
    }
    useEffect(() => {
        let isComponentActive = true;
        setMandateId(null);
        async function getClientMandates(data: any) {
            const isComponentActive = true;
            (async function () {
                try {
                    // setIsLoading(true);
                    const response = (await dispatch(
                        get_client_mandateIds({
                            clientId: data
                        })
                    )) as unknown as ClientMandateResponse;
                    if (!isComponentActive) {
                        return;
                    }
                    setMandateId(response)
                    // setIsLoading(false);
                } catch (e) {
                    console.log((e as Error).message);
                    setIsLoading(false);
                }
            })();
            console.log("mandateid in api", mandateId)
            return () => {
                // isComponentActive = false;
            };
        }
        holdingName?.map((item: any) => {
            getClientMandates(item.AccountID);
        });

        return () => {
            isComponentActive = false;
        };
    }, []);
    //Function to submit form
    const handleSubmitData = async (sipLumpsumData: any) => {
        let shouldReturn = false;
        if (!LumpsumValidation() || !SIPValidation()) shouldReturn = true;
        const data: any = [];

        if (sipLumpsumData.length === 0) {
            handleOpenModal()
        }

        else {
            if (radioValue === 'recommend') {
                sipLumpsumData?.map((item: any) => {

                    const obj = item.switchMode ? {
                        "SchemeID": item.schemeID,
                        "InvestmentAmount": item.investmentAmt,
                        "SchemeType": "Add",
                        "rmID": rmId,
                        "ClientCode": investorId,
                        "No_of_Installments": null,
                        "SIP_date": "",
                        "Frequency": "",
                        "AccountID": item.AccountID,
                        "ToSchemeID": item.schemeID,
                        "Folio": item.folio,
                        "Units": 0,
                        "PFFlag": null,
                    } :

                        {
                            "SchemeID": item.schemeID,
                            "InvestmentAmount": item.instalmentAmt,
                            "SchemeType": "XSIP",
                            "rmID": rmId,
                            "ClientCode": investorId,
                            "No_of_Installments": item?.frequencySelected === 'DAILY' ? null : Number(item.noOfInstalments),
                            "SIP_date": item.sipDay,
                            "Frequency": `${item.frequencySelected}`,
                            "AccountID": item.AccountID,
                            "ToSchemeID": item.schemeID,
                            "Folio": item.folio,
                            "Units": 0,
                            "PFFlag": null
                        }
                    data.push(obj);
                })
                const recommendationData = {
                    recommendation_data: data
                }
                if (shouldReturn == true) return;
                await postRecommendedSchemes(recommendationData);
            } else {

                sipLumpsumData?.map(async (item: any) => {
                    await getBSECode(item)
                    if (item.switchMode) {
                        {
                            const obj = {
                                "AccNo": item?.folio,
                                "AmcId": item?.AmcID,
                                "Amount": item?.investmentAmt,
                                "BSE_SchemeCode": item?.BSESchemeCode,
                                "DividendReinvestment": item?.DividendReinvestment,
                                "Growthoption": item?.Growthoption,
                                "Sch": item?.SCh,
                                "TrType": "Add",
                                "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
                                "AccountID": item?.AccountID,
                                "rmID": rmId
                            }
                            data.push(obj);
                        }
                    }
                    else {
                        const obj = {
                            "StartDate": new Date(item.sipDay).toLocaleDateString('en-GB'),
                            "Installments": item?.frequencySelected === 'DAILY' ? "" : item.noOfInstalments,
                            "InstallmentsAmt": item.instalmentAmt,
                            "MandateId": item?.mandate,
                            // "MandateType": item?.mandateType,
                            "FirstOrderToday": item.firstOrderToday,
                            "Frequency": frequencyMapping[item.frequencySelected],
                            "AmcId": item?.AmcID,
                            "Sch": item?.SCh,
                            "TrType": "XSIP",
                            "Growthoption": item?.Growthoption,
                            "DividendReinvestment": item?.DividendReinvestment,
                            "BSE_SchemeCode": item?.BSESchemeCode,
                            "AccNo": item?.folio,
                            "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
                            "AccountID": item?.AccountID,
                            "rmID": rmId
                        }
                        data.push(obj);
                    }
                })
                if (shouldReturn == true) return;
                const confirmationResult = await Swal.fire({
                    html: '<b>Are you sure you want to place this order?</b>',
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, place order",
                    customClass: {
                        container: 'my-swal',
                        htmlContainer: 'custom-html-container-class',
                    },
                });
                if (confirmationResult.isConfirmed) {
                    await create_transaction(data[0])
                }
            }
        }
    }

    async function postRecommendedSchemes(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    post_recommended_schemes(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    async function create_transaction(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    RM_create_transaction(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
                setPlaceOrderResponse(response)
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }

    //Account Type
    useEffect(() => {

        setAccountTypeData(null);
        (async function () {
            try {

                const response = (await dispatch(
                    get_account_type(
                        {
                            rmID: rmId,
                            ClientCode: investorId
                        }
                    )

                )) as unknown as GetAccountType;

                setAccountTypeData(response.account_type[0]);
            } catch (e) {

                console.log((e as Error).message);
            }
        })();
    }, [dispatch]);

    return (
        <div>

            <CommonLayout>
                <>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                        <Button
                            sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick_}
                        >
                            {selectedValue}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                        <Menu
                            sx={{ cursor: "pointer" }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            {['Explore', 'Recommendation', 'Holdings', 'Orders'].map(
                                (i) => {
                                    return <MenuItem onClick={() => handleMenuItemClick(`${i}`)} key={i}>{i}</MenuItem>
                                }
                            )}
                        </Menu>
                    </Box>

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'common.white',
                            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            py: 2,
                        }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ArrowBackIosIcon onClick={() => handleMenuItemClick('Holdings')} sx={{ marginInline: "4rem 1rem", cursor: "pointer" }} />
                            <h3>SIP/Lumpsum</h3>
                        </Box>
                        <Divider />
                        {holdingNameData?.map((ele: any, key: any) => {
                            const errorData = error ? error[ele.schemeID] : errorStates;
                            return (
                                <>
                                    {holdingNameData.length === 1 ?
                                        <FormControl sx={{ px: 8, pt: 2 }}>
                                            <RadioGroup
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={radioValue}
                                                onChange={handleRadioChange}
                                                sx={{
                                                    '& .MuiSvgIcon-root': {
                                                        fontSize: 18,
                                                    },
                                                    '& .MuiFormControlLabel-root': {
                                                        color: '#5a7c82'
                                                    },
                                                    '& .Mui-checked .MuiRadio-root': {
                                                        color: '#337FC9',
                                                    },
                                                    '& .MuiRadio-root:not(.Mui-checked)': {
                                                        color: '#5a7c82'
                                                    },
                                                }}
                                            >

                                                <FormControlLabel value="recommend" control={<Radio />} label="Recommend" />
                                                <FormControlLabel value="onbehalf" control={<Radio />} label="On behalf of client" />
                                                <FormControlLabel value="onrequest" control={<Radio />} label="Requested by client" />

                                            </RadioGroup>
                                        </FormControl> : ''
                                    }
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', ml: 8, mr: 4, mt: 2, height: "3rem" }} key={key}>
                                        {/* SchemeName */}
                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "auto" }}>

                                            <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.4 }} key={key}>
                                                {ele.schemeName}

                                            </Typography>
                                            <Box sx={{ display: 'flex' }}>
                                                {ele.bubbleTag.map((tag: string) => (
                                                    <Bubble text={tag} key={key} />
                                                ))}
                                            </Box>
                                        </Box>

                                        {/* Return  */}
                                        <Box sx={{ width: "10%", display: 'flex', flexDirection: 'column', alignItems: 'center', ml: 3 }}>
                                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Returns</Typography>
                                            <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                                {/* {val.Aum === "0" || !val.Aum
                                                ? "NA"
                                                : `${Nanprocessor(roundToTwo(Number(val.Sch_Return5Yr)))}%`} */}
                                                {Nanprocessor(roundToTwo(Number(ele.xirr)))}%
                                            </Typography>
                                        </Box>

                                        {/* Folio */}
                                        <Box sx={{ width: "25%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                            <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Selected Folio</Typography>
                                            <input type="text" style={{ padding: "0.85rem", width: "13rem" }} className='investmentAmount' readOnly={true}
                                                placeholder={ele.folio}
                                            >
                                            </input>

                                        </Box>


                                        {ele.switchMode ?

                                            // investment Amount
                                            <Box sx={{ width: "25%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Investment Amount <b style={{ color: 'red' }}>*</b></Typography>
                                                <input type="number" style={{ padding: "0.85rem", width: "13rem" }} className='investmentAmount' placeholder={`Min. - ₹${ele.MinimumPurchaseAmount}`}
                                                    value={ele.investmentAmt}
                                                    onChange={(e) => {
                                                        holdingNameData[key].investmentAmt = Number(e.target.value);
                                                        setHoldingNameData([...holdingNameData])
                                                    }}
                                                >
                                                </input>
                                                {errorData !== undefined && errorData.errorInvestmentAmount !== undefined && errorData.errorInvestmentAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorInvestementAmountMsg} </Typography>}
                                            </Box> :
                                            //Frequency
                                            <Box sx={{ width: "25%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Frequency <b style={{ color: 'red' }}>*</b></Typography>
                                                <FormControl fullWidth>
                                                    <Select
                                                        displayEmpty
                                                        value={ele.frequencySelected}

                                                        input={<OutlinedInput />}
                                                        renderValue={(selected: any) => {
                                                            if (selected.length === 0) {
                                                                return <p style={{ fontSize: "13px", color: "grey", opacity: "0.5" }}>Select Frequency</p>;
                                                            }
                                                            return selected;
                                                        }}
                                                        onChange={(e: any) => {
                                                            holdingNameData[key].frequencySelected = e.target.value;
                                                            setHoldingNameData([...holdingNameData])
                                                            setValidationForSelectedFrequency(ele.schemeID, (e.target.value).toUpperCase());
                                                        }}
                                                        sx={{
                                                            border: "1px solid #d9d9d9",
                                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                            width: "13rem",
                                                            height: '2rem',
                                                            borderRadius: '5px',
                                                            padding: '2px 3px',
                                                            fontSize: 15,
                                                        }}
                                                    >
                                                        {validations ? validations[ele.schemeID]?.map((data: any, key: any) => {
                                                            return (
                                                                <MenuItem value={data.SIPFREQUENCY} key={key} >{data.SIPFREQUENCY}</MenuItem>
                                                            )
                                                        }) : <></>}
                                                    </Select>
                                                </FormControl>
                                                {errorData.errorFrequency && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorFrequencyMsg} </Typography>}
                                            </Box>}
                                        {/* } */}
                                        <Box sx={{ width: "15%", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <Typography
                                                sx={{
                                                    color: '#22242C',
                                                    opacity: 0.8,
                                                    fontSize: 14,
                                                }}>
                                                SIP
                                            </Typography>

                                            <Switch
                                                checked={ele.switchMode}

                                                onChange={(e: any) => {
                                                    holdingNameData[key].switchMode = e.target.checked;
                                                    setHoldingNameData([...holdingNameData])
                                                    const mapToDoubleDigit = (number: any): string => {
                                                        return number < 9 ? `0${number + 1}` : `${number + 1}`;
                                                    };
                                                    const mapDayToDoubleDigit = (number: any): string => {
                                                        return number <= 9 ? `0${number}` : `${number}`;
                                                    };
                                                    if (e.target.checked) {
                                                        holdingNameData[key].frequencySelected = "";
                                                    }
                                                    if (e.target.checked == false) {
                                                        const date = new Date();
                                                        holdingNameData[key].sipDay = `${date?.getFullYear()}/${mapToDoubleDigit(date?.getMonth())}/${mapDayToDoubleDigit(date?.getDate())}`
                                                        // holdingNameData[key].datePicker = date;
                                                        getSIPValidationData(ele.SCh)
                                                    }
                                                }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                            <Typography
                                                sx={{
                                                    color: '#22242C',
                                                    opacity: 0.8,
                                                    fontSize: 14
                                                }}>
                                                Lumpsum
                                            </Typography>
                                        </Box>
                                        <Box sx={{ width: "7%", marginLeft: "1rem" }}>
                                            <div
                                                onClick={(e) => {
                                                    console.log(key)
                                                    holdingNameData.splice(key, 1)
                                                    setHoldingNameData([...holdingNameData])

                                                }}
                                            ><DeleteIcon /></div>
                                        </Box>



                                    </Box>

                                    {
                                        ele.frequencySelected !== "" &&
                                        <Box sx={{ mx: 5 }}>
                                            <Box sx={{ display: "flex", alignItems: "center", ml: 2, mt: 2 }}>

                                                {/* Installment Amount */}
                                                <Box sx={{ width: "33%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                    <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Installment Amount <b style={{ color: 'red' }}>*</b></Typography>
                                                    <input type='number' className='investmentAmount2' placeholder={`Min. - ₹${selectedFrequencyValidation && selectedFrequencyValidation[ele.schemeID].SIPMINIMUMINSTALLMENTAMOUNT}`}
                                                        value={ele.installmentAmt}
                                                        onChange={(e) => {
                                                            holdingNameData[key].instalmentAmt = Number(e.target.value);
                                                            setHoldingNameData([...holdingNameData])
                                                        }}
                                                    ></input>
                                                    {errorData.errorInstalmentAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorInstalmentAmountMsg} </Typography>}
                                                </Box>

                                                {/* No. of instalments */}
                                                {holdingNameData[key].frequencySelected === 'DAILY' ? '' :
                                                    <Box sx={{ width: "33%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>No. of Installments <b style={{ color: 'red' }}>*</b></Typography>
                                                        <input type="number" className='investmentAmount2' placeholder={`Enter Number ${selectedFrequencyValidation && selectedFrequencyValidation[ele.schemeID].SIPMINIMUMINSTALLMENTNUMBERS} - ${selectedFrequencyValidation && selectedFrequencyValidation[ele.schemeID].SIPMAXIMUMINSTALLMENTNUMBERS}`}
                                                            value={ele.noOfInstalments}
                                                            onChange={(e) => {
                                                                holdingNameData[key].noOfInstalments = Number(e.target.value);
                                                                setHoldingNameData([...holdingNameData])
                                                            }}

                                                        >
                                                        </input>
                                                        {errorData.errorNoOfInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorNoOfInstalmentMsg} </Typography>}
                                                    </Box>
                                                }

                                                {/* SIP Day */}
                                                <Box sx={{ width: "33%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                    <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>SIP Day <b style={{ color: 'red' }}>*</b></Typography>
                                                    <DatePicker
                                                        className="custom-datepicker"
                                                        // ref={datePickerRef}
                                                        selected={holdingNameData[key].datePicker}
                                                        onChange={(date: Date) => {
                                                            const mapToDoubleDigit = (number: number): string => {
                                                                return number < 9 ? `0${number + 1}` : `${number + 1}`;
                                                            };
                                                            const mapDayToDoubleDigit = (number: any): string => {
                                                                return number <= 9 ? `0${number}` : `${number}`;
                                                            };
                                                            holdingNameData[key].sipDay = `${date?.getFullYear()}/${mapToDoubleDigit(date?.getMonth())}/${mapDayToDoubleDigit(date?.getDate())}`
                                                            holdingNameData[key].datePicker = date;
                                                            setHoldingNameData([...holdingNameData])
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        filterDate={(date: Date) => isDateDisabled(date, ele.schemeID)}
                                                        placeholderText={'dd-mm-yyyy'}
                                                    // customInput={<CustomInput />}
                                                    />
                                                    {errorData.errorSIPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorSIPDayMsg} </Typography>}

                                                </Box>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", ml: 2, mt: 2 }}>
                                                {/* First order today */}
                                                {radioValue != 'recommend' ?
                                                    <>
                                                        <Box sx={{ width: "33%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                            <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Mandate <b style={{ color: 'red' }}>*</b></Typography>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    displayEmpty
                                                                    value={ele.mandate}
                                                                    input={<OutlinedInput />}
                                                                    renderValue={(selected: any) => {
                                                                        if (selected && selected.length === 0) {
                                                                            return <p style={{ fontSize: "13px", color: "grey", opacity: "0.5" }}>Select Mandate</p>;
                                                                        }
                                                                        return selected;
                                                                    }}
                                                                    onChange={(e: any) => {
                                                                        holdingNameData[key].mandate = e.target.value;
                                                                        setHoldingNameData([...holdingNameData])
                                                                    }}
                                                                    sx={{
                                                                        border: "1px solid #d9d9d9",
                                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                        width: "13rem",
                                                                        height: '2rem',
                                                                        borderRadius: '5px',
                                                                        padding: '2px 3px',
                                                                        fontSize: 15,
                                                                    }}
                                                                >
                                                                    {mandateId?.mandate && (
                                                                        mandateId?.mandate[0].map((i: any, index: any) => (
                                                                            <MenuItem key={index} value={i.Mandate_Id}> {i.Mandate_Id}</MenuItem>))
                                                                    )
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            {errorData.errorMandate && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorMessageMandate} </Typography>}
                                                        </Box>

                                                        <Box sx={{ width: '33%' }}><Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>First Order Today <b style={{ color: 'red' }}>*</b></Typography>

                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                sx={{
                                                                    '& .MuiSvgIcon-root': {
                                                                        fontSize: 18,
                                                                    },
                                                                    '& .MuiFormControlLabel-root': {
                                                                        color: '#5a7c82'
                                                                    },
                                                                    '& .Mui-checked .MuiRadio-root': {
                                                                        color: '#337FC9',
                                                                    },
                                                                    '& .MuiRadio-root:not(.Mui-checked)': {
                                                                        color: '#5a7c82'
                                                                    },
                                                                }}
                                                                value={holdingNameData[key].firstOrderToday}

                                                                onChange={(e) => {
                                                                    // if (!isNaN(Number(e.target.value))) {
                                                                    holdingNameData[key].firstOrderToday = e.target.value === 'yes' ? 'Y' : 'N';
                                                                    setHoldingNameData([...holdingNameData])

                                                                    // }
                                                                }}>

                                                                <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                                <FormControlLabel value="no" control={<Radio />} label="No" />
                                                            </RadioGroup>
                                                            {errorData.errorFirstOrderToday && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {errorData.errorMessageFirstOrderToday} </Typography>}
                                                        </Box>
                                                    </>
                                                    : ''}
                                            </Box>
                                        </Box>
                                    }
                                </>)
                        })}

                        {holdingNameData.length < 1 ? <Typography sx={{ display: 'flex', justifyContent: 'center', p: 3, color: '#5a7c82', fontSize: 18, fontWeight: 400 }}>No scheme selected</Typography>
                            :
                            <> <Box sx={{ marginTop: "1rem" }}>
                                <Button variant="contained" style={{ padding: "0.4rem 7rem 0.4rem 7rem", marginLeft: "22rem", cursor: "pointer" }}
                                    onClick={() => { handleSubmitData(holdingNameData); }}
                                >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : <> {radioValue === 'recommend' ? "Recommend" : radioValue === 'onbehalf' ? "Place Order On Behalf of Client" : "Place Order Requested by Client"} </>}</Button>
                            </Box>
                                <Typography sx={{ color: 'red', fontSize: '12px', mx: 7.5 }}> * Mandatory fields</Typography>
                            </>
                        }
                    </Box>
                    {Recommend ?
                        <Modal
                            open={Recommend}
                            onClose={() => setRecommended(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute' as any,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>

                                {radioValue === 'recommend' ?
                                    <> <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                        <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
                                    </>
                                    :
                                    <>

                                        {placeOrderResponse === undefined || placeOrderResponse === null || placeOrderResponse.error ?
                                            < >
                                                <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />
                                                <Typography sx={{ textAlign: "center" }}>Order Failed</Typography>
                                                <Typography sx={{ textAlign: "center" }}>Order ID not generated</Typography>
                                            </>
                                            :
                                            <>
                                                {placeOrderResponse.transaction_created[0].order_success ?
                                                    <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                                    : <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />}
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_success ? 'Order Placed' : 'Order Failed'}</Typography>
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_status}</Typography>
                                            </>
                                        }
                                    </>
                                }
                                <Button onClick={() => { setRecommended(false); handleMenuItemClick('Holdings') }} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                            </Box>
                        </Modal> : <></>}
                    <div>

                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    No Scheme To Recommend
                                </Typography>
                            </Box>
                        </Modal>
                    </div>

                </>

            </CommonLayout>

        </div>
    );
}

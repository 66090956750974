import { Box, Grid, Typography } from '@mui/material';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { Gridstyles } from './index';
import { KeyValue } from './personalDetails';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { LocationType, mdmsLocationList } from '../../redux-store/types/mdms';
import { getLocationList } from '../../redux-store/actions';

export default function DistributorDetails({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;
  const LoginRMorSupport = isSupportRM ? application?.supportRm : application?.rm;
  const [locationMaster, setLocationMaster] = useState<mdmsLocationList[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    (async function () {
      try {
        const locationmaster = (await dispatch(getLocationList())) as unknown as LocationType;
        setLocationMaster(locationmaster.rmLocationList);
      } catch (e) {
        console.log((e as Error).message);
      }
    })();
  }, []);
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Grid container direction="row" justifyContent="space-between" alignItems="flex-start">
              <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                RM Details
              </Typography>
            </Grid>

            <Gridstyles>
              <Grid
                item
                container
                pt={1}
                pb={5}
                rowSpacing={6}
                columnSpacing={13}
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <KeyValue
                  title={isSupportRM ? "Service RM's Name" : "RM's Name"}
                  description={LoginRMorSupport?.user?.firstName}
                />
                <KeyValue
                  title={isSupportRM ? "Service RM's Email Address" : "RM's Email Address"}
                  description={LoginRMorSupport?.user?.email}
                />
                <KeyValue
                  title={isSupportRM ? "Service RM's Mobile Number" : "RM's Mobile Number"}
                  description={
                    LoginRMorSupport?.user?.countryCode
                      ? LoginRMorSupport?.user?.countryCode + '-' + LoginRMorSupport?.user?.phone
                      : LoginRMorSupport?.user?.phone
                  }
                />
                <KeyValue
                  title={'Location'}
                  description={
                    locationMaster.find((loc) => loc.key === LoginRMorSupport?.location)?.value
                  }
                />
                {isSupportRM && (
                  <>
                    <KeyValue title={"RM's Name"} description={application?.rm?.user?.firstName} />
                    <KeyValue
                      title={"RM's Email Address"}
                      description={application?.rm?.user?.email}
                    />
                    <KeyValue
                      title={"RM's Mobile Number"}
                      description={
                        application?.rm?.user?.countryCode
                          ? application?.rm?.user?.countryCode + '-' + application?.rm?.user?.phone
                          : application?.rm?.user?.phone
                      }
                    />
                    <KeyValue
                      title={'Location'}
                      description={
                        locationMaster.find((loc) => loc.key === application?.rm?.location)?.value
                      }
                    />
                  </>
                )}
              </Grid>
            </Gridstyles>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

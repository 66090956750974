import { Box, Typography } from '@mui/material';

export default function GridStyleGraph({
  options,
}: {
  options: {
    Large_Blend: number | string;
    Large_Growth: number | string;
    Large_Value: number | string;
    Mid_Blend: number | string;
    Mid_Growth: number | string;
    Mid_Value: number | string;
    Small_Blend: number | string;
    Small_Growth: number | string;
    Small_Value: number | string;
  };
}): JSX.Element {
  const getBoxColor = (value: number | string): string => {
    value = Number(value);
    switch (true) {
      case value > 50:
        return '#67ADF2';
      case value > 24 && value < 51:
        return '#C899F4';
      case value > 9 && value < 25:
        return '#7CD4DE';
      default:
        return '#21639F';
    }
  };

  return (
    <Box>
      <svg width="350" height="350">
        {
          //! Large Value
        }
        <g>
          <rect
            x="0"
            y="0"
            width="100"
            height="100"
            fill={getBoxColor(options.Large_Value)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="90"
            y="10"
            width="100"
            height="100"
            fontSize="12"
            fill="white"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Large_Value || 0}
          </text>
        </g>
        {
          // ! Large Blend
        }
        <g>
          <rect
            x="100"
            y="0"
            width="100"
            height="100"
            fill={getBoxColor(options.Large_Blend)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="190"
            y="10"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Large_Blend || 0}
          </text>
        </g>
        {
          // ! Large Growth
        }
        <g>
          <rect
            x="200"
            y="0"
            width="100"
            height="100"
            fill={getBoxColor(options.Large_Growth)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="290"
            y="10"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Large_Growth || 0}
          </text>
        </g>

        {
          // ! Mid Value
        }
        <g>
          <rect
            x="0"
            y="100"
            width="100"
            height="100"
            fill={getBoxColor(options.Mid_Value)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="90"
            y="110"
            width="100"
            height="100"
            fontSize="12"
            fill="white"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Mid_Value || 0}
          </text>
        </g>
        {
          // ! Mid Blend
        }
        <g>
          <rect
            x="100"
            y="100"
            width="100"
            height="100"
            fill={getBoxColor(options.Mid_Blend)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="190"
            y="110"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Mid_Blend || 0}
          </text>
        </g>
        {
          // ! Mid Growth
        }
        <g>
          <rect
            x="200"
            y="100"
            width="100"
            height="100"
            fill={getBoxColor(options.Mid_Growth)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="290"
            y="110"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Mid_Growth || 0}
          </text>
        </g>

        {
          // ! Small Value
        }
        <g>
          <rect
            x="0"
            y="200"
            width="100"
            height="100"
            fill={getBoxColor(options.Small_Value)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="90"
            y="210"
            width="100"
            height="100"
            fontSize="12"
            fill="white"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Small_Value || 0}
          </text>
        </g>
        {
          // ! Small Blend
        }
        <g>
          <rect
            x="100"
            y="200"
            width="100"
            height="100"
            fill={getBoxColor(options.Small_Blend)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="190"
            y="210"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Small_Blend || 0}
          </text>
        </g>
        {
          // ! Small Growth
        }
        <g>
          <rect
            x="200"
            y="200"
            width="100"
            height="100"
            fill={getBoxColor(options.Small_Growth)}
            stroke="white"
            strokeWidth="2"></rect>
          <text
            x="290"
            y="210"
            width="100"
            height="100"
            fill="white"
            fontSize="12"
            textAnchor="end"
            alignmentBaseline="hanging">
            {options.Small_Growth || 0}
          </text>
        </g>

        {/* <!-- Horizontal Labels --> */}
        <g>
          <text
            x="50"
            y="310"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="middle"
            alignmentBaseline="hanging">
            Value
          </text>
        </g>
        <g>
          <text
            x="150"
            y="310"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="middle"
            alignmentBaseline="hanging">
            Blend
          </text>
        </g>
        <g>
          <text
            x="250"
            y="310"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="middle"
            alignmentBaseline="hanging">
            Growth
          </text>
        </g>

        {/* <!-- Vertical labels--> */}
        <g>
          <text
            x="310"
            y="50"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="start"
            alignmentBaseline="middle">
            Large
          </text>
        </g>
        <g>
          <text
            x="310"
            y="150"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="start"
            alignmentBaseline="middle">
            Mid
          </text>
        </g>
        <g>
          <text
            x="310"
            y="250"
            width="100"
            height="50"
            fontSize="12"
            textAnchor="start"
            alignmentBaseline="middle">
            Small
          </text>
        </g>
      </svg>
      <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1, justifyContent: 'space-evenly' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '35px', width: '19px', backgroundColor: getBoxColor(1) }}></Box>
          <Typography variant="caption">1-9</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '35px', width: '19px', backgroundColor: getBoxColor(10) }}></Box>
          <Typography variant="caption">10-24</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '35px', width: '19px', backgroundColor: getBoxColor(25) }}></Box>
          <Typography variant="caption">25-50</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 2 }}>
          <Box sx={{ height: '35px', width: '19px', backgroundColor: getBoxColor(99) }}></Box>
          <Typography variant="caption">{'>51'}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

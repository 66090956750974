import { Card, Grid, Input, Slider, Stack, Typography } from '@mui/material';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import { MFTextField } from '../../lib/formik';
import {
  allocationlimitsType,
  assetallocationguidelinesType,
  InvestmentPreferenceMasterType,
  investmentpreferencesType,
  tolerancelimitsType,
  TypeInvestmentPreferenceMdms,
} from '../../redux-store/types/api-types';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { DataTable } from '../DataTable';
import { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  saveForLater,
} from '../../utils/utilityFunctions';
import { Box } from '@mui/system';
import { useHistory } from 'react-router';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { investmentPreferenceMasterData } from '../../redux-store/actions';
import { investmentPreferenceSchema } from '../../utils/schema';

export type Values = {
  investmentpreferences: investmentpreferencesType[];
  assetallocationguidelines: assetallocationguidelinesType[];
  allocationlimits: allocationlimitsType[];
  tolerancelimits: tolerancelimitsType[];
  saveType: string;
};

export const investmentPreferenceObj: investmentpreferencesType = {
  question: '',
  answer: '',
  value: '',
  section: '',
};

export const assetallocationguidelinesObj: assetallocationguidelinesType = {
  assetClass: '',
  minimumAllocation: 0,
  maximumAllocation: 0,
};

export const allocationlimitsObj: allocationlimitsType = {
  maturityBuckets: '',
  indicativeAllocation: 0,
};

export const tolerancelimitsObj: tolerancelimitsType = {
  fixedIncomeCategory: '',
  allocation: 0,
};

const initialValues: Values = {
  investmentpreferences: [],
  assetallocationguidelines: [],
  allocationlimits: [],
  tolerancelimits: [],
  saveType: 'save and proceed',
};

function InvestmentPreferences({
  values,
  setValues,
}: {
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
}): JSX.Element {
  const headers = () => {
    return [
      {
        header: 'Asset Class*',
        label: 'assetClass',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          const AllocationStatus = params.assetClass;
          return (
            <Typography
              sx={{
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: 14,
              }}>
              {AllocationStatus}
            </Typography>
          );
        },
      },
      {
        header: 'Minimum Allocation %',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          return (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  '& .MuiSlider-track': {
                    border: 'none',
                    backgroundColor: '#2057A6',
                    opacity: 0.38,
                  },
                }}>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={params.minimumAllocation}
                  marks={[
                    { value: 0, label: `0%` },
                    { value: 100, label: '100%' },
                  ]}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                    '& .MuiSlider-markLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                  }}
                  onChange={(event: any) => {
                    setValues({
                      ...values,
                      assetallocationguidelines: values.assetallocationguidelines.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            minimumAllocation: event.target.value,
                            maximumAllocation:
                              Number(a.maximumAllocation) >= Number(event.target.value)
                                ? a.maximumAllocation
                                : event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />

                <Input
                  size="small"
                  sx={{
                    width: { md: '40px', xs: '62px' },
                    ml: 2,
                    mb: 4,
                    '& ::placeholder': { textTransform: 'none', fontSize: '0.9rem' },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    'input[type=number]': { MozAppearance: 'textfield' },
                  }}
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  value={params.minimumAllocation}
                  onBlur={(event: any) => {
                    const inputValue = parseInt(event.target.value);
                    if (isNaN(inputValue) || inputValue < 0) {
                      setValues({
                        ...values,
                        assetallocationguidelines: values.assetallocationguidelines.map(
                          (a, idx) => {
                            if (index === idx) {
                              return {
                                ...a,
                                minimumAllocation: 0,
                                maximumAllocation: 0,
                              };
                            }
                            return a;
                          }
                        ),
                      });
                    }
                  }}
                  onChange={(event: any) => {
                    setValues({
                      ...values,
                      assetallocationguidelines: values.assetallocationguidelines.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            minimumAllocation: event.target.value > 100 ? 100 : event.target.value,
                            maximumAllocation:
                              Number(a.maximumAllocation) >= Number(event.target.value)
                                ? a.maximumAllocation
                                : event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    mb: 4,
                    fontSize: '12px',
                  }}>
                  %
                </Typography>

                {/* <Typography
                  sx={{
                    ml: 2,
                    borderRadius: '5px',
                    border: '1px #ccc solid',
                    py: 0.5,
                    px: 0.5,
                    width: { xs: '52%', md: '50%' },
                    fontSize: '12px',
                  }}>
                  {params.minimumAllocation}%
                </Typography> */}
              </Grid>
            </>
          );
        },
      },
      {
        header: 'Maximum Allocation %',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          return (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  '& .MuiSlider-track': {
                    border: 'none',
                    backgroundColor: '#2057A6',
                    opacity: 0.38,
                  },
                }}>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={params.maximumAllocation}
                  min={params.minimumAllocation}
                  marks={[
                    { value: params.minimumAllocation, label: `${params.minimumAllocation}%` },
                    { value: 100, label: '100%' },
                  ]}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                    '& .MuiSlider-markLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                  }}
                  onChange={(event: any) => {
                    setValues({
                      ...values,
                      assetallocationguidelines: values.assetallocationguidelines.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            maximumAllocation: event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Input
                  size="small"
                  sx={{
                    width: { md: '40px', xs: '62px' },
                    ml: 2,
                    mb: 4,
                    '& ::placeholder': { textTransform: 'none', fontSize: '0.9rem' },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    'input[type=number]': { MozAppearance: 'textfield' },
                  }}
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  value={params.maximumAllocation}
                  onBlur={(event: any) => {
                    const inputValue = parseInt(event.target.value);
                    if (
                      inputValue < params.minimumAllocation ||
                      isNaN(inputValue) ||
                      inputValue < 0
                    ) {
                      setValues({
                        ...values,
                        assetallocationguidelines: values.assetallocationguidelines.map(
                          (a, idx) => {
                            if (index === idx) {
                              return {
                                ...a,
                                maximumAllocation: params.minimumAllocation,
                              };
                            }
                            return a;
                          }
                        ),
                      });
                    }
                  }}
                  onChange={(event: any) => {
                    setValues({
                      ...values,
                      assetallocationguidelines: values.assetallocationguidelines.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            maximumAllocation: event.target.value > 100 ? 100 : event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    fontSize: '12px',
                    mb: 4,
                  }}>
                  %
                </Typography>
                {/* 
                <Typography
                  sx={{
                    ml: 2,
                    borderRadius: '5px',
                    border: '1px #ccc solid',
                    py: 0.5,
                    px: 0.5,
                    width: { xs: '52%', md: '50%' },
                    fontSize: '12px',
                  }}>
                  {params.maximumAllocation}%
                </Typography> */}
              </Grid>
            </>
          );
        },
      },
    ];
  };

  const headerstolaranceLimit = () => {
    return [
      {
        header: 'Fixed Income Category*',
        label: 'fixedIncomeCategory',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          const fixedIncomeCategoryStatus = params.fixedIncomeCategory;
          return (
            <Typography
              sx={{
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: 14,
              }}>
              {fixedIncomeCategoryStatus}
            </Typography>
          );
        },
      },
      {
        header: 'Allocation %',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          const [minValue, setMinValue] = useState(0);

          useEffect(() => {
            setMinValue(params.allocation);
          }, [params]);

          return (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  '& .MuiSlider-track': {
                    border: 'none',
                    backgroundColor: '#2057A6',
                    opacity: 0.38,
                  },
                }}>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={params.allocation}
                  marks={[
                    { value: 0, label: `0%` },
                    { value: 100, label: '100%' },
                  ]}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                    '& .MuiSlider-markLabel': {
                      fontSize: '12px',
                      m: 0,
                    },
                  }}
                  onChange={(event: any) => {
                    setMinValue(event.target.value);
                    setValues({
                      ...values,
                      tolerancelimits: values.tolerancelimits.map((a, idx) => {
                        if (index === idx) {
                          return { ...a, allocation: event.target.value };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Input
                  size="small"
                  sx={{
                    width: { md: '40px', xs: '62px' },
                    ml: 2,
                    mb: 4,
                    '& ::placeholder': { textTransform: 'none', fontSize: '0.9rem' },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    'input[type=number]': { MozAppearance: 'textfield' },
                  }}
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  value={params.allocation}
                  onBlur={(event: any) => {
                    const inputValue = parseInt(event.target.value);
                    if (isNaN(inputValue) || inputValue < 0) {
                      setValues({
                        ...values,
                        tolerancelimits: values.tolerancelimits.map((a, idx) => {
                          if (index === idx) {
                            return {
                              ...a,
                              allocation: 0,
                            };
                          }
                          return a;
                        }),
                      });
                    }
                  }}
                  onChange={(event: any) => {
                    setMinValue(event.target.value);
                    setValues({
                      ...values,
                      tolerancelimits: values.tolerancelimits.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            allocation: event.target.value > 100 ? 100 : event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    fontSize: '12px',
                    mb: 4,
                  }}>
                  %
                </Typography>
              </Grid>
            </>
          );
        },
      },
    ];
  };

  const headerAllocationLimit = () => {
    return [
      {
        header: 'Maturity Buckets',
        label: 'fixedIncomeCategory',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          const maturityBucketsStatus = params.maturityBuckets;
          return (
            <Typography
              sx={{
                textDecoration: 'none',
                fontWeight: 500,
                fontSize: 14,
              }}>
              {maturityBucketsStatus}
            </Typography>
          );
        },
      },
      {
        header: 'Indicative Allocation',
        renderEditDeleteCell: (params: any, index: number): JSX.Element => {
          const [minValue, setMinValue] = useState(0);
          useEffect(() => {
            setMinValue(params.indicativeAllocation);
          }, [params]);
          return (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  '& .MuiSlider-track': {
                    border: 'none',
                    backgroundColor: '#2057A6',
                    opacity: 0.38,
                  },
                }}>
                <Slider
                  aria-label="Default"
                  valueLabelDisplay="off"
                  value={params.indicativeAllocation}
                  marks={[
                    { value: 0, label: `0%` },
                    { value: 100, label: '100%' },
                  ]}
                  sx={{
                    '& .MuiSlider-valueLabel': {
                      fontSize: '12px',
                    },
                    '& .MuiSlider-markLabel': {
                      fontSize: '12px',
                    },
                  }}
                  onChange={(event: any) => {
                    setMinValue(event.target.value);
                    setValues({
                      ...values,
                      allocationlimits: values.allocationlimits.map((a, idx) => {
                        if (index === idx) {
                          return { ...a, indicativeAllocation: event.target.value };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Input
                  value={params.indicativeAllocation}
                  size="small"
                  sx={{
                    width: { md: '40px', xs: '62px' },
                    ml: 2,
                    mb: 4,
                    '& ::placeholder': { textTransform: 'none', fontSize: '0.9rem' },
                    'input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
                      WebkitAppearance: 'none',
                      margin: 0,
                    },
                    'input[type=number]': { MozAppearance: 'textfield' },
                  }}
                  type="number"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onBlur={(event: any) => {
                    const inputValue = parseInt(event.target.value);
                    if (isNaN(inputValue) || inputValue < 0) {
                      setValues({
                        ...values,
                        allocationlimits: values.allocationlimits.map((a, idx) => {
                          if (index === idx) {
                            return { ...a, indicativeAllocation: 0 };
                          }
                          return a;
                        }),
                      });
                    }
                  }}
                  onChange={(event: any) => {
                    setMinValue(event.target.value);
                    setValues({
                      ...values,
                      allocationlimits: values.allocationlimits.map((a, idx) => {
                        if (index === idx) {
                          return {
                            ...a,
                            indicativeAllocation:
                              event.target.value > 100 ? 100 : event.target.value,
                          };
                        }
                        return a;
                      }),
                    });
                  }}
                />
                <Typography
                  sx={{
                    ml: 0.5,
                    mb: 4,
                    fontSize: '12px',
                  }}>
                  %
                </Typography>
              </Grid>
            </>
          );
        },
      },
    ];
  };

  return (
    <>
      {values.investmentpreferences.map((preference, ind) => {
        return (
          <>
            <SubHeading
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              {`${preference.section}`}
            </SubHeading>
            <>
              <Grid item xs={12} sm={12}>
                <UseRadioGroupTransparent
                  name={`investmentpreferences.${ind}.answer`}
                  formLabel={`${preference.questionText}`}
                  items={
                    preference.options?.map((profile: any) => ({
                      label: profile.label,
                      value: profile.key,
                    })) || []
                  }
                  onChange={(e) => {
                    setValues({
                      ...values,
                      investmentpreferences: values.investmentpreferences.map((preference, idx) => {
                        if (idx === ind) {
                          if (preference.question === 'liquidateAssetsunder') {
                            return {
                              ...preference,
                              answer: e.target.value,
                              value: '',
                            };
                          }
                          return {
                            ...preference,
                            answer: e.target.value,
                          };
                        }
                        return preference;
                      }),
                    });
                  }}
                  sx={{
                    '& .MuiFormGroup-root': { flexDirection: 'column' },
                    '& .MuiFormControlLabel-label': { fontWeight: 400 },
                  }}
                />
                {preference.question === 'liquidateAssetsunder' && preference.answer === 'yes' && (
                  <Grid item xs={12} sm={6} sx={{ mt: -2 }}>
                    <MFTextField
                      name={`investmentpreferences.${ind}.value`}
                      label={``}
                      placeholder=""
                      startAdornment={
                        <CurrencyRupeeIcon sx={{ color: '#bcbec6', ml: 1, fontSize: '20px' }} />
                      }
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          investmentpreferences: values.investmentpreferences.map(
                            (preference, idx) => {
                              if (idx === ind && preference.question === 'liquidateAssetsunder') {
                                return { ...preference, value: e.target.value };
                              }
                              return preference;
                            }
                          ),
                        });
                      }}
                    />
                    {preference.answer && (
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}>
                        {currencyConversion(Number(preference.value))}
                      </Typography>
                    )}
                  </Grid>
                )}
                {preference.questionType === 'textBox' && (
                  <Grid item xs={12} sm={6} sx={{ mt: -2, ml: { md: -2 } }}>
                    <MFTextField
                      name={`investmentpreferences.${ind}.answer`}
                      label={``}
                      placeholder=""
                      startAdornment={
                        <CurrencyRupeeIcon sx={{ color: '#bcbec6', ml: 1, fontSize: '20px' }} />
                      }
                      type="number"
                      onKeyDown={(e) => {
                        allowOnlyNumbers(e);
                      }}
                      trimOnBlur={false}
                      onChange={(e) => {
                        setValues({
                          ...values,
                          investmentpreferences: values.investmentpreferences.map((preference) => {
                            if (preference.question === 'assetSize') {
                              return { ...preference, answer: e.target.value };
                            }
                            return preference;
                          }),
                        });
                      }}
                    />
                    {preference.answer && (
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}>
                        {currencyConversion(Number(preference.answer))}
                      </Typography>
                    )}
                  </Grid>
                )}
              </Grid>
            </>
          </>
        );
      })}
      <SubHeading
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        Asset Allocation guidelines
      </SubHeading>
      <Grid item xs={12} sm={12} lg={12}>
        <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836' }}>
          {`Please indicate the broad asset allocation guidelines to be implemented for the investment advice.`}
        </Typography>
        <Card
          sx={{
            mt: 2,
          }}>
          <DataTable
            tableData={values.assetallocationguidelines}
            tableHeader={headers()}
            renderAdditionalRow={false}
            tableHeaderCustomStyles={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
                textAlign: 'left',
                backgroundColor: '#F3F8FB',
                // pl: 4,
                // pr: { md: 10 },
                '&:nth-of-type(2)': {
                  pl: 6,
                },
                '&:nth-of-type(3)': {
                  pl: 6,
                },
              },
            }}
            rowCustomStyles={{
              '.MuiTableCell-root': {
                overflowWrap: 'anywhere',
                textAlign: 'left',
                verticalAlign: 'top',
                py: 2,
                '& .MuiFormControl-root': {
                  m: 0,
                },
              },
            }}
            boxStyles={{
              minHeight: 'unset',
              maxHeight: 'unset',
              maxWidth: 'unset',
              minWidth: 'unset',
              mb: 0,
            }}
          />
        </Card>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#c598ad',
            mt: 2,
          }}>{`*A tolerance limit of +/-10% to be followed`}</Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#c598ad',
            // mt: 2,
          }}>{`*Maximum Allocation % should greater than Minimum Allocation % (else value will adjust to Minimum Allocation %)`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836', mt: 3 }}>
          {`Allocation limit for the below Fixed income maturities`}
        </Typography>
        <Card
          sx={{
            mt: 2,
          }}>
          <DataTable
            tableData={values.allocationlimits}
            tableHeader={headerAllocationLimit()}
            renderAdditionalRow={false}
            tableHeaderCustomStyles={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
                textAlign: 'left',
                backgroundColor: '#F3F8FB',
                // pl: 4,
                pr: { md: 10 },
                // pb: '-50px !important',
                // mb: 77,
                '&:nth-of-type(2)': {
                  pl: 6,
                },
                '&:nth-of-type(3)': {
                  pl: 6,
                },
              },
            }}
            rowCustomStyles={{
              '.MuiTableCell-root': {
                overflowWrap: 'anywhere',
                textAlign: 'left',
                verticalAlign: 'top',
                py: 2,
                // pb: 0,
                // mb: 3,
                pr: 7,
                '& .MuiFormControl-root': {
                  m: 0,
                },
              },
            }}
            boxStyles={{
              maxHeight: 'unset',
              minHeight: 'unset',
              minWidth: 250,
              mb: 0,
            }}
          />
        </Card>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#c598ad',
            mt: 2,
          }}>{`*Market capitalisation guidelines as per SEBI & AMFI`}</Typography>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 400,
            color: '#c598ad',
            // mt: 2,
          }}>{`**A tolerance limit of +/-10% to be followed`}</Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836', mt: 3 }}>
          {`Tolerance limits for the below Fixed Income categories`}
        </Typography>
        <Card
          sx={{
            mt: 2,
          }}>
          <DataTable
            tableData={values.tolerancelimits}
            tableHeader={headerstolaranceLimit()}
            renderAdditionalRow={false}
            tableHeaderCustomStyles={{
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 14,
                fontWeight: 500,
                color: 'primary.main',
                textAlign: 'left',
                backgroundColor: '#F3F8FB',
                // pl: 4,
                // pb: '-50px !important',
                // mb: 77,
                pr: { md: 10 },
                '&:nth-of-type(2)': {
                  pl: 6,
                },
                '&:nth-of-type(3)': {
                  pl: 6,
                },
              },
            }}
            rowCustomStyles={{
              '.MuiTableCell-root': {
                overflowWrap: 'anywhere',
                textAlign: 'left',
                verticalAlign: 'top',
                py: 2,
                // pb: 0,
                // mb: 3,
                '& .MuiFormControl-root': {
                  m: 0,
                },
              },
            }}
            boxStyles={{ minHeight: 'unset', minWidth: 250, mb: 0 }}
          />
        </Card>
      </Grid>
    </>
  );
}

export default function InvestmentPreference(): JSX.Element {
  const [investmentPreferencesData, setInvestmentPreferencesData] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [investmentDataMaster, setInvestmentDataMaster] = useState<
    InvestmentPreferenceMasterType[]
  >([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};

      const {
        investmentpreferences,
        assetallocationguidelines,
        tolerancelimits,
        allocationlimits,
        saveType,
      } = values;

      const updatedInvestmentpreferences = investmentpreferences?.map((_riskProfile) => {
        const { questionKey, questionText, questionType, options, ...rest } = _riskProfile;
        return {
          ...rest,
        };
      });

      const updatedPayload = {
        ...application,
        investmentpreferences: updatedInvestmentpreferences,
        assetallocationguidelines: assetallocationguidelines,
        tolerancelimits: tolerancelimits,
        allocationlimits: allocationlimits,
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          investmentpreferences: application?.investmentpreferences,
          assetallocationguidelines: application?.assetallocationguidelines,
          tolerancelimits: application?.tolerancelimits,
          allocationlimits: application?.allocationlimits,
        },
        {
          ...updatedPayload,
          investmentpreferences: updatedPayload.investmentpreferences,
          assetallocationguidelines: updatedPayload.assetallocationguidelines,
          tolerancelimits: updatedPayload.tolerancelimits,
          allocationlimits: updatedPayload.allocationlimits,
          currentStep: !!currentStep && currentStep > 9 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 10,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('service-preference', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('service-preference', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(
          investmentPreferenceMasterData()
        )) as unknown as TypeInvestmentPreferenceMdms;
        if (!isComponentAlive) {
          return;
        }
        setInvestmentDataMaster(response.invesmentQuestions);
        const {
          investmentpreferences = [],
          assetallocationguidelines = [],
          tolerancelimits = [],
          allocationlimits = [],
        } = application || {};

        setInvestmentPreferencesData({
          ...investmentPreferencesData,
          investmentpreferences: response.invesmentQuestions
            .map((preference) => {
              let ans = [] as any;
              preference.questions.map((a) => {
                const existingInvestmentPreference = investmentpreferences?.filter(
                  (data) => data.question === a.questionKey
                );
                ans = existingInvestmentPreference.length
                  ? existingInvestmentPreference.map((preferences) => {
                      if (preferences.question === a.questionKey) {
                        return {
                          ...preferences,
                          ...a,
                          question: a.questionKey,
                          answer: preferences.answer,
                          section: preference.SectionHeading,
                        };
                      }
                    })
                  : [
                      {
                        ...investmentPreferenceObj,
                        question: a.questionKey,
                        answer: '',
                        section: preference.SectionHeading,
                        ...a,
                      },
                    ];
              });
              return ans;
            })
            .flat(),
          assetallocationguidelines: assetallocationguidelines.length
            ? assetallocationguidelines
            : response.allocationGuidelines,
          tolerancelimits: tolerancelimits.length ? tolerancelimits : response.tolaranceLimit,
          allocationlimits: allocationlimits.length ? allocationlimits : response.allocationLimit,
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  return (
    <Formik
      initialValues={investmentPreferencesData}
      validationSchema={investmentPreferenceSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Grid
          container
          rowSpacing={1}
          //columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <InvestmentPreferences values={values} setValues={setValues} />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  Hidden,
  LinearProgress,
  Pagination,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Typography,
} from '@mui/material';
import { SxProps } from '@mui/system';
import React, { ChangeEvent, useState } from 'react';
import { TableSortingIcon } from '../customSVGs';
import { useSelector } from 'react-redux';

export type ColumnType = {
  /**
   * Header of the column
   */
  header: string | (() => React.ReactNode);

  stickyColumn?: boolean;

  /**
   * To get the value from the row
   */
  label?: string;

  /**
   * Value to render in the table
   */
  value?: string | number | boolean | undefined;

  /**
   * Sorting key to sort the values by the key of the object
   */
  sortingKey?: string;

  /**
   * Alignment of the column
   */
  align?: 'left' | 'center' | 'right';

  /**
   * Function to get the value from the row
   */
  valueGetter?: (row: any, amountUnit?: number | null | undefined) => string | number | boolean | undefined | JSX.Element;

  /**
   * Boolean to check if the cell is switch
   */
  isSwitch?: boolean;

  /**
   * Action for switch component
   */
  switchAction?: (id: string) => void;

  /**
   * Render custom cell
   */
  renderCell?: (row: any) => React.ReactNode;
  renderEditDeleteCell?: (row: any, index: number, groupIndex?: number, amountUnit?: number | null | undefined) => React.ReactNode;
};

const DataRow = ({
  row,
  index,
  rowCustomStyles,
  groupIndex,
  currentPage,
  tableHeader,
}: {
  row: any;
  index: number;
  rowCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  currentPage?: number;
  tableHeader: ColumnType[];
}): JSX.Element => {
  const valueConverter = useSelector((state:any)=> state.valueConverter)
  return (
    <TableRow
      key={index}
      sx={{
        background: currentPage ? '#FAFAFB' : '',
        ':hover': {
          bgcolor: currentPage ? '#FAFAFB' : '',
        },
        ...rowCustomStyles,
      }}>
      {tableHeader.map((header, ind) => {
        const {
          valueGetter,
          isSwitch,
          renderCell,
          label = '',
          renderEditDeleteCell,
          stickyColumn,
        } = header;
        return (
          <TableCell
            key={ind}
            align={header?.align ?? 'center'}
            sx={{
              left: 0,
              right: 0,
              backgroundColor: 'white',
              position: stickyColumn ? 'sticky' : 'unset',
              padding: 0,
            }}>
            {/* {isSwitch ? (
              <Stack alignItems={'center'}>
                <Switch
                  sx={{ '.MuiSwitch-switchBase': { color: 'black' } }}
                  checked={row.isActive}
                  // onChange={() => header.switchAction && header.switchAction(row.id)}
                />
              </Stack>
            ) :  */}
            <Box
              sx={{
                padding: '0.8rem',
                width: '90%',
                height: '100%',
                
              }}>
              {valueGetter
                ? valueGetter(row, valueConverter)
                : renderCell
                ? renderCell(row)
                : renderEditDeleteCell
                ? renderEditDeleteCell(row, index, groupIndex, valueConverter)
                : row[label]}
            </Box>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export const MobileViewDataRow = ({
  row,
  index,
  groupIndex,
  tableHeader,
  isLoading = false,
}: {
  row: any;
  index: number;
  groupIndex?: number;
  tableHeader: ColumnType[];
  isLoading?: boolean;
}): JSX.Element => {
  return (
    <Grid
      key={index}
      container
      // columnSpacing={1}
      sx={{
        border: '1px solid #00D9DA',
        p: 1.25,
        // ml: 0,
        borderRadius: 1.25,
        mb: 1.25,
        rowGap: 1.25,
        padding: { xs: '10px', sm: '55px' },
        '.MuiGrid-item': {
          px: 1,
        },
        '.MuiTypography-root': {
          fontSize: { xs: 10, sm: 16 },
          fontWeight: 500,
          maxWidth: '204px',
          overflowWrap: 'anywhere',
        },
        '& .MuiTypography-h6': {
          fontFamily: 'Poppins, sans-serif',
          color: 'primary.main',
        },
        '& .MuiTypography-body1': {
          color: 'rgba(41, 49, 57, 0.7)',
        },
      }}>
      {tableHeader.map((headerList, idx) => {
        const {
          valueGetter,
          isSwitch,
          label = '',
          renderCell,
          header,
          renderEditDeleteCell,
        } = headerList;
        return (
          <Grid item xs={6} sm={4} key={idx}>
            {typeof header !== 'string' ? (
              header()
            ) : (
              <Typography variant="h6">{headerList.header}</Typography>
            )}
            {isLoading ? (
              <Skeleton />
            ) : // isSwitch ? (
            //   <Stack
            //     sx={{
            //       '.MuiSwitch-root': { paddingLeft: '0px', width: '52px' },
            //       '.MuiSwitch-switchBase': { paddingLeft: '0px' },
            //     }}>
            //     <Switch
            //       sx={{
            //         '.MuiSwitch-switchBase': { color: 'black' },
            //       }}
            //       checked={row[label]}
            //       onChange={() => headerList.switchAction && headerList.switchAction(row.id)}
            //     />
            //   </Stack>
            // ) :
            renderCell ? (
              renderCell(row)
            ) : renderEditDeleteCell ? (
              renderEditDeleteCell(row, index, groupIndex)
            ) : (
              <Typography>{valueGetter ? valueGetter(row) : row[label]}</Typography>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

export const WebViewDataRow = ({
  rowCustomStyles,
  renderAdditionalRow = false,
  tableBodyCustomStyles,
  tableHeaderCustomStyles,
  groupIndex,
  currentPage,
  tableHeader,
  tableData,
  maxHeight,
  tableStyles,
  customEmptyMessage = 'No Data found',
}: {
  rowCustomStyles?: SxProps<Theme>;
  currentPage?: number;
  renderAdditionalRow?: boolean;
  tableBodyCustomStyles?: SxProps<Theme>;
  tableHeaderCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  tableHeader: ColumnType[];
  tableData: any;
  maxHeight?: string | number;
  tableStyles?: SxProps<Theme>;
  customEmptyMessage?: string;
}): JSX.Element => {
  const [sortColumn, setSortColumn] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const handleSort = (column: string) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedTableData =
    sortColumn === null
      ? tableData
      : tableData.sort((a: any, b: any) => {
          if (sortDirection === 'asc') {
            if (Number(a[sortColumn] && Number(b[sortColumn]))) {
              return Number(a[sortColumn] > Number(b[sortColumn]) ? 1 : -1);
            }
            return a[sortColumn] > b[sortColumn] ? 1 : -1;
          } else {
            if (Number(a[sortColumn] && Number(b[sortColumn]))) {
              return Number(a[sortColumn] < Number(b[sortColumn]) ? 1 : -1);
            }
            return a[sortColumn] < b[sortColumn] ? 1 : -1;
          }
        });

  return (
    <TableContainer
      sx={{
        bgcolor: 'common.white',
        maxHeight: maxHeight || '100%',
        ...tableStyles,
      }}>
      <Table aria-label="Application table" key={sortColumn}>
        <TableHead
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 100,
          }}>
          <TableRow
            sx={{
              position: 'relative',
              '.MuiTableCell-head': {
                fontFamily: 'Poppins, sans-serif',
                fontSize: 12,
                color: 'primary.main',
              },
              ...tableHeaderCustomStyles,
            }}>
            {tableHeader.map((currHeader: ColumnType, index: number) => {
              const { header, stickyColumn } = currHeader;
              return (
                <TableCell
                  key={'h' + index}
                  align={currHeader?.align ?? 'center'}
                  sx={{
                    whiteSpace: 'nowrap',
                    left: 0,
                    top: 0,
                    right: 0,
                    position: stickyColumn ? 'sticky' : 'unset',
                    zIndex: stickyColumn ? 50 : 'unset',
                    p: 0,
                  }}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: 'white',
                    }}>
                    {currHeader['sortingKey'] ? (
                      <TableSortLabel
                        IconComponent={TableSortingIcon}
                        active={sortColumn === currHeader['sortingKey']}
                        direction={sortColumn === currHeader['sortingKey'] ? sortDirection : 'asc'}
                        onClick={() => {
                          const column = currHeader['sortingKey'];
                          column && handleSort(column);
                        }}
                        sx={{
                          '& .MuiTableSortLabel-icon': {
                            fontSize: '12px',
                          },
                        }}>
                        {typeof header !== 'string' ? header() : currHeader['header']}
                      </TableSortLabel>
                    ) : typeof header !== 'string' ? (
                      header()
                    ) : (
                      currHeader['header']
                    )}
                  </Box>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            '.MuiTableRow-root': {
              cursor: currentPage ? 'pointer' : 'auto',
              ':hover': {
                bgcolor: currentPage ? '#F4FCFC' : '',
              },
            },
            ...tableBodyCustomStyles,
          }}>
          {sortedTableData.length ? (
            sortedTableData.map((row: any, index: number) => (
              <React.Fragment key={index}>
                <DataRow
                  groupIndex={groupIndex}
                  index={index}
                  row={row}
                  key={index}
                  rowCustomStyles={rowCustomStyles}
                  currentPage={currentPage}
                  tableHeader={tableHeader}
                />
                {renderAdditionalRow && (
                  <TableRow
                    sx={{
                      '.MuiTableCell-root': {
                        padding: '4px',
                      },
                    }}>
                    <TableCell colSpan={tableHeader.length}></TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={tableHeader.length}
                sx={{
                  textAlign: 'center',
                  height: 100,
                }}>
                {customEmptyMessage}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export function DataTable({
  isLoading,
  tableData = [],
  tableHeader,
  rowCustomStyles,
  noOfPages,
  currentPage,
  paginationAction,
  renderAdditionalRow = false,
  tableBodyCustomStyles,
  tableHeaderCustomStyles,
  groupIndex,
  boxStyles,
  tableStyles,
  customEmptyMessage,
}: {
  isLoading?: boolean;
  tableData: any;
  tableHeader: ColumnType[];
  rowCustomStyles?: SxProps<Theme>;
  noOfPages?: number;
  currentPage?: number;
  paginationAction?: (event: ChangeEvent<unknown>, value: number) => void;
  renderAdditionalRow?: boolean;
  tableBodyCustomStyles?: SxProps<Theme>;
  tableHeaderCustomStyles?: SxProps<Theme>;
  groupIndex?: number;
  boxStyles?: SxProps<Theme>;
  tableStyles?: SxProps<Theme>;
  customEmptyMessage?: string;
}): JSX.Element {
  tableData = isLoading ? new Array(5).fill({}) : tableData;
  const maxHeight = 'inherit';
  return (
    <>
      <Hidden only="xs">
        <Box
          sx={{
            maxHeight: maxHeight,
            overflow: 'auto',
            minHeight: '480px',
            minWidth: 600,
            ...boxStyles,
          }}>
          {!isLoading ? (
            <WebViewDataRow
              rowCustomStyles={rowCustomStyles}
              currentPage={currentPage}
              renderAdditionalRow={renderAdditionalRow}
              tableBodyCustomStyles={tableBodyCustomStyles}
              tableHeaderCustomStyles={tableHeaderCustomStyles}
              groupIndex={groupIndex}
              tableHeader={tableHeader}
              tableData={tableData}
              maxHeight={maxHeight}
              tableStyles={tableStyles}
              customEmptyMessage={customEmptyMessage}
            />
          ) : (
            <LinearProgress />
          )}
        </Box>
      </Hidden>
      <Hidden smUp={true}>
        {tableData.map((row: any, index: number) => (
          <MobileViewDataRow
            index={index}
            row={row}
            key={index}
            tableHeader={tableHeader}
            isLoading={isLoading}
          />
        ))}
      </Hidden>
      {!!tableData.length && !!currentPage && !isLoading && (
        <Stack direction="row" justifyContent="center" sx={{ mt: 5 }}>
          <Pagination
            count={noOfPages}
            page={currentPage}
            color="primary"
            onChange={paginationAction}
          />
        </Stack>
      )}
    </>
  );
}

import {
  Avatar,
  Box,
  Card,
  Grid,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { distributorWisecommitmentAmount } from '../../redux-store/actions/application';
import { RootStateType } from '../../redux-store/reducers';
import { distributorWisecommitmentType } from '../../redux-store/types/api-types';
import { formatCurrency } from '../../utils/utilityFunctions';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export const distributorWise: distributorWisecommitmentType = {
  column1: '',
  column2: '',
  data: [
    {
      commitmentAmount: 0,
      completed: 0,
      contributionAmount: 0,
      name: '',
    },
  ],
  title: '',
};
export const Distributors = ({ dis }: { dis: distributorWisecommitmentType }): JSX.Element => {
  const distributors = dis.data.filter((dis) => {
    return dis.completed;
  });

  return (
    <>
      <TableContainer
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow sx={{ '&:last-child th': { border: 0 } }}>
              <TableCell>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontSize: 12,
                    fontWeight: 500,
                    color: 'rgb(97, 209, 214)',
                  }}>
                  {dis.column1}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography
                  component="span"
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: 'rgb(97, 209, 214)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}>
                  {dis.column2}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {distributors.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td': { border: 0 }, height: '20px !important' }}>
                {/* <TableCell align="left" sx={{ py: 0 }}></TableCell> */}
                <TableCell
                  align="right"
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '60px !important',
                  }}>
                  <Typography component="span" align="right">
                    {row.name ? row.name : 'N/A'}
                  </Typography>
                  <Typography sx={{ pr: 2 }}>
                    <Avatar
                      sx={{
                        fontWeight: 600,
                        backgroundColor: 'rgb(97, 209, 214)',
                      }}>
                      {row.name.charAt(0)}
                    </Avatar>
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography>{row.completed ? row.completed : 'N/A'}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default function TopDistributorRM(): JSX.Element {
  const dispatch = useDispatch();
  const [topDisData, setTopDisData] = useState(distributorWise);
  const topDistributorData = useSelector((store: RootStateType) => store.topDistributors);

  const filterDis = topDistributorData.data.filter((dis) => {
    return dis.completed;
  });

  useEffect(() => {
    let componentIsAlive = true;
    (async function () {
      try {
        (await dispatch(
          distributorWisecommitmentAmount()
        )) as unknown as distributorWisecommitmentType;
        if (!componentIsAlive) {
          return;
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  useEffect(() => {
    setTopDisData(topDistributorData);
  }, [topDistributorData]);

  return (
    <Grid item xs={12} sm={12} md={12} lg={5}>
      <Card raised={true} sx={{ height: { lg: 440 }, p: 2 }}>
        {!topDistributorData.title ? (
          <>
            <Skeleton
              sx={{
                width: { xs: 500, sm: 400 },
                height: { xs: 20, sm: 30 },
                ml: { xs: 0, sm: 15, lg: 2 },
                mr: { xs: 0, sm: 15, lg: 2 },
              }}
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                // mt: 6,
              }}>
              <Skeleton sx={{ width: { xs: 150, sm: 100 }, ml: { xs: 0, sm: 3 }, mt: 2 }} />
              <Skeleton
                sx={{
                  width: { xs: 150, sm: 155 },
                  mt: 2,
                  ml: { xs: 0, sm: 15, lg: 2 },
                  mr: { xs: 0, sm: 15, lg: 2 },
                }}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mt: 1,
              }}>
              <Skeleton
                variant="rectangular"
                sx={{
                  width: { xs: 500, sm: 600 },
                  height: { xs: 150, sm: 300 },
                  mt: 1,
                  mb: 2,
                  ml: { xs: 0, sm: 15, lg: 2 },
                  mr: { xs: 0, sm: 15, lg: 2 },
                }}
              />
            </Box>
          </>
        ) : (
          <>
            {filterDis.length > 0 ||
            JSON.stringify(topDistributorData) !== JSON.stringify(topDisData) ? (
              <>
                <Typography
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}>
                  <InfoOutlinedIcon color="info" fontSize="small" />
                  <Typography
                    sx={{
                      color: 'rgba(41, 49, 57, 0.7)',
                      fontSize: 12,
                      fontWeight: 500,
                      ml: 1,
                    }}>
                    Data is shown only for completed applications .
                  </Typography>
                </Typography>
                <Typography>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 550,
                      pl: 2,
                      pt: 1,
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    {topDistributorData.title}
                  </Typography>
                  <Distributors dis={topDistributorData} />
                </Typography>
              </>
            ) : (
              <>
                <Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <InfoOutlinedIcon color="info" fontSize="small" />
                    <Typography
                      sx={{
                        color: 'rgba(41, 49, 57, 0.7)',
                        fontSize: 12,
                        fontWeight: 500,
                        ml: 1,
                      }}>
                      Data is shown only for completed applications .
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: 18,
                      fontWeight: 550,
                      pl: 2,
                      pt: 1,
                    }}>
                    {topDistributorData.title || 'Top 5 Distributors'}
                  </Typography>
                  <Typography
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-evenly',
                      p: { lg: 1 },
                    }}>
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 15,
                        fontWeight: 500,
                        color: 'rgb(97, 209, 214)',
                        ml: 3,
                      }}>
                      {topDistributorData.column1 || 'RM Name'}
                    </Typography>
                    <Typography
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontSize: 15,
                        fontWeight: 500,
                        color: 'rgb(97, 209, 214)',
                      }}>
                      {topDistributorData.column2 || 'Completed Applications'}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      color: 'rgb(97, 209, 214)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mt: { lg: 14, xs: 4 },
                      mb: { xs: 4 },
                    }}>
                    No Data Available
                  </Typography>
                </Typography>
              </>
            )}
          </>
        )}
      </Card>
    </Grid>
  );
}

import { Box, Button, CircularProgress, Divider, Skeleton, LinearProgress, InputLabel, Modal, Switch, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./styles.css";
import { formatToIndianCurrency } from "../../../utils/utilityFunctions";
import { CloseIcon, DeleteIcon } from "../../customSVGs";
import { useState, useEffect } from "react";
import { GetAccountType, PostRecommendedSchemes, SchemeList, RecommendationsType, GetRecommendedSchemes } from "../../../redux-store/types/api-types";
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { formatLongDate } from "../../../utils/date";
import { useDispatch } from 'react-redux';
import { edit_recommended_schemes, getRmDetails, get_account_type, get_folioList, get_recommendation_validation, get_recommended_schemes, post_recommended_schemes, redeem_validation } from "../../../redux-store/actions/userManagement";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Key } from "@mui/icons-material";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Swal from 'sweetalert2';
import { HoldingSWPSTPSeletedData } from "./types";
import Redeem from './Redeem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import { RedemptionErrorType } from './types';
import FormControlLabel from '@mui/material/FormControlLabel';



export function Bubble(props: { text: string }) {
    return (
        <div className="bubbleDiv">
            <div className="bubbleText">
                {props.text}
            </div>
        </div>
    );
}
function roundToTwo(num: number) {
    const num2 = num + "e+2";

    return +(Math.round(Number(num2)) + "e-2");
}

const Nanprocessor = function (entry: any) {
    if (entry) {
        return entry;
    } else {
        return 0;
    }
};


interface schemeParams {
    AccountID: string;
    ToSchemeID: string;
    ToschemeName: string;
    RecID: number;
    SchemeID: number;
    SchemeName: string;
    SchemeType: string;
    Aum: string;
    Sch_Return5Yr: number;
    minPurchase: any;
    maxPurchase: any;
    switchValue: string;
    invAmount: number;
    selectedAccount: any;
    instalmentAmount: number;
    error: boolean;
    errorInstalment: boolean;
    errorSIPDay: boolean;
    errorAccountLumpsum: boolean;
    errorAccountSIP: boolean;
    errorMessageAccount: string;
    errorFrequency: boolean;
    errorInstalmentAmount: boolean;
    errorMessage: string;
    errorMessageInstalment: string;
    errorMessageSIPDay: string;
    errorMessageFrequency: string;
    errorMessageInstalmentAmount: string;
    AssetClassName: string;
    Sub_AssetclassName: string;
    Dividendfrequency: string;
    Growthoption: number;
    Dividendreinvestment: number;
    DividendPayout: number;
    instalments: number;
    SIPDay: string;
    Folio: any;
    PFFlag: string;
    // mandate: string;
    frequency: string;
    Sch: any;
    datePicker: any;
}

interface sipSchemeValidation {
    Productcode_RTA: string,
    SIPFREQUENCY: string | null,
    SIPDATES: string,
    SIPMINIMUMINSTALLMENTAMOUNT: string,
    SIPMAXIMUMINSTALLMENTAMOUNT: string,
    SIPMINIMUMINSTALLMENTNUMBERS: string,
    SIPMAXIMUMINSTALLMENTNUMBERS: string,
    SCHEMEISIN: string,
    SIPMINIMUMGAP: string,
    IPMAXIMUMGAP: string,
    SIPMULTIPLIERAMOUNT: string
}

export function EditSchemesModal({ handleModalClose, selectedSchemes = [], OnSchemeDeSelection, investorId, isRecommended, trigger, setTrigger, page }:
    { handleModalClose: () => void; selectedSchemes: RecommendationsType[] | undefined; OnSchemeDeSelection: (schemeId: number) => void; investorId: string; isRecommended: (val: boolean) => void, trigger: boolean, setTrigger: (val: any) => void, page: string }) {

    // const { investorId, investorName, holdingName="" } = { investorId: '', investorName: '' };
    const [schemeParams, setSchemeParams] = useState<schemeParams[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [rmId, setRmId] = useState<number | null>();
    const [accountType, setAccountType] = useState<GetAccountType | null>();
    const [validations, getValidations] = useState<any>();
    const [SIPInstallment, setSIPInstallment] = useState<sipSchemeValidation[]>();
    const [redeemBalance, setRedeemBalance] = useState<any>();
    const [redeemPageData, setRedeemPageData] = useState<any>({ ...selectedSchemes, redeemValue: { value: (schemeParams[0]?.PFFlag == "F" ? redeemBalance?.Amount : (schemeParams[0]?.invAmount != null || schemeParams[0]?.invAmount != undefined) ? schemeParams[0]?.invAmount.toString() : 0), type: "INR" }, redeemType: selectedSchemes[0].PFFlag === "F" ? "Full" : "Partial" });
    const [selectAccount, setSelectAccount] = useState<boolean>(false);
    // const [swp_stpPageData, setSWP_STPPageData] = useState<HoldingSWPSTPSeletedData>({ ...holdingName, transactionType: "SWP", SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", switchValue: { value: "", type: "INR" }, ToSchemeName: "", ToschemeID: "", newSchemeSch: "", STPFrequency: "", SIPDate: "", SWPFrequency: "" });
    const [selectedAccountType, setSelectedAccountType] = useState<string>('');
    const [folio, setFolio] = useState<any>();
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const [recommendationData, setRecommendationData] = useState<GetRecommendedSchemes | null>(null);
    const [isLoadingAsset, setLoadingAsset] = useState(false);
    const [isLoadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);


    const errorStates: RedemptionErrorType = {
        errorRedemptionValue: false,
        errorRedemptionValueMsg: ""
    }

    const [error, setError] = useState<RedemptionErrorType>(errorStates);
    useEffect(() => {
        if (schemeParams[0]?.PFFlag == "F" && redeemBalance != undefined) {
            setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: redeemBalance?.Amount } })
        }
        else if (schemeParams[0]?.PFFlag == "P" && redeemBalance != undefined) {
            setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: schemeParams[0]?.invAmount } })
        }
    }, [redeemBalance])


    const dispatch = useDispatch();
    useEffect(() => {
        const schemeParams: any = [];
        selectedSchemes?.map((item) => {
            const swtVal = {
                AccountID: item.accountID,
                RecID: item.RecID,
                SchemeID: item.SchemeID,
                SchemeName: item.SchemeName,
                Aum: item.Aum,
                Sch_Return5Yr: item.Sch_Return5Yr,
                minPurchase: item.MinimumPurchaseAmount,
                maxPurchase: item.MaximumPurchaseAmount,
                SchemeType: item.SchemeType,
                switchValue: item.SchemeType,
                instalmentAmount: item.InvestmentAmount,
                invAmount: item.InvestmentAmount,
                selectedAccount: "",
                error: false,
                errorInstalment: false,
                errorSIPDay: false,
                errorFrequency: false,
                errorInstalmentAmount: false,
                errorMessage: "",
                errorMessageInstalment: "",
                errorMessageSIPDay: "",
                errorMessageFrequency: "",
                errorMessageInstalmentAmount: "",
                AssetClassName: item.AssetClassName,
                Sub_AssetclassName: item.Sub_AssetclassName,
                Dividendfrequency: item.Dividendfrequency,
                Growthoption: item.Growthoption,
                Dividendreinvestment: item.Dividendreinvestment,
                DividendPayout: item.DividendPayout,
                instalments: item.No_of_Installments,
                Folio: item.Folio,
                ToSchemeID: item.ToschemeID,
                ToschemeName: item.ToschemeName,
                SIPDay: item.SIP_date.split('T')[0].split("-").join('/'),
                PFFlag: item.PFFlag,
                datePicker: new Date(item?.SIP_date),
                mandate: "",
                frequency: item.Frequency,
                Sch: item.Sch
            }
            schemeParams.push(swtVal);
        })
        setSchemeParams(schemeParams);
    }, [])

    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);

            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])
    useEffect(() => {

        let isComponentActive = true;
        setAccountType(null);
        (async function () {
            try {
                setSelectAccount(true);
                const responseAccount = (await dispatch(
                    get_account_type({
                        rmID: rmId,
                        ClientCode: investorId
                    })
                )) as unknown as GetAccountType;
                if (!isComponentActive) {
                    return;
                }
                setAccountType(responseAccount);
                setSelectAccount(false);
                selectedSchemes?.map(async (item: any, index: number) => {
                    try {
                        const response = (await dispatch(
                            get_folioList({
                                "AccountID": responseAccount?.account_type[0]?.AccountId,
                                "AMC": item?.AmcID
                            })
                        )) as unknown as any;
                        setFolio(response?.folio[0]);

                    } catch (e) {
                        console.log((e as Error).message);
                    }
                })
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    useEffect(() => {
        if (schemeParams?.length > 0) {
            schemeParams[0].selectedAccount = accountType?.account_type[0]?.AccountType;
            setSchemeParams([...schemeParams])
            setSelectedAccountType(accountType?.account_type[0].AccountType as string)
        }
    }, [accountType])

    useEffect(() => {
        const temp: any = [];
        const validationList: any = [];
        const promises: any = selectedSchemes?.map(async (item: RecommendationsType) => {
            return recommendation_val(item.Sch).then((obj: any) => {
                temp.push({
                    [item.Sch]: (obj.validations[1] as any),
                });
                validationList.push({
                    Productcode_RTA: item.Sch,
                    SIPFREQUENCY: null,
                    SIPDATES: "",
                    SIPMINIMUMINSTALLMENTAMOUNT: "",
                    SIPMAXIMUMINSTALLMENTAMOUNT: "",
                    SIPMINIMUMINSTALLMENTNUMBERS: "",
                    SIPMAXIMUMINSTALLMENTNUMBERS: "",
                    SCHEMEISIN: "",
                    SIPMINIMUMGAP: "",
                    IPMAXIMUMGAP: "",
                    SIPMULTIPLIERAMOUNT: ""
                });
            });
        })
        Promise.all(promises).then((result) => {
            getValidations(temp);
            setSIPInstallment(validationList);
        });

    }, [selectedSchemes])

    const recommendation_val = async (sch: any): Promise<any> => {
        const isComponentActive = true;
        try {
            const response = (await dispatch(
                get_recommendation_validation({ sch, TrType: selectedSchemes[0]?.SchemeType })
            )) as any;
            if (!isComponentActive) {
                return Promise.resolve();
            }
            return response;
        } catch (e) {
            console.log((e as Error).message);
        }
        return Promise.resolve();
    };

    // const OnSchemeDeSelection_ = (scId: number) => {
    //     const schemeList = schemeParams?.filter((val) => val.SchemeID != scId);
    //     setSchemeParams(schemeList);
    //     OnSchemeDeSelection(scId);
    // }

    const handleSubmit = async () => {
        let shouldReturn = false;
        if (!invAmountValidation() || !SIPDataValidation() || !RedeemValidation()) shouldReturn = true;
        const data: any = [];
        schemeParams?.map((item: any) => {
            const obj = {
                RecID: item.RecID,
                SchemeID: item.SchemeID,
                InvestmentAmount: (item.switchValue === 'ISIP' || item.switchValue === 'XSIP' || item.switchValue === 'STP' || item.switchValue === 'SWP') ? item.instalmentAmount : (item.switchValue === 'Red' || item.switchValue === 'Swt') ? redeemPageData.redeemValue.value : item.invAmount,
                SchemeType: item.SchemeType,
                rmID: rmId,
                ClientCode: investorId,
                No_of_Installments: (item.switchValue === 'ISIP' || item.switchValue === 'XSIP' || item.switchValue === 'STP' || item.switchValue === 'SWP') ? (item.frequency === 'DAILY' ? null : item.instalments) : null,
                SIP_date: (item.switchValue === 'ISIP' || item.switchValue === 'XSIP' || item.switchValue === 'STP' || item.switchValue === 'SWP') ? item.SIPDay : "",
                Frequency: (item.switchValue === 'ISIP' || item.switchValue === 'XSIP' || item.switchValue === 'STP' || item.switchValue === 'SWP') ? item.frequency : "",
                AccountID: item.AccountID,
                ToSchemeID: (item.switchValue === 'ISIP' || item.switchValue === 'New' || item.switchValue === 'SWP') ? null : item.ToSchemeID,
                Folio: item.Folio === 'new' ? '' : item.Folio,
                Units: (item.switchValue === 'Red' || item.switchValue === 'Swt') ? redeemPageData.redeemValue.type == "Units" ? redeemPageData.redeemValue.type : 0 : 0,
                PFFlag: (item.switchValue === 'Red' || item.switchValue === 'Swt') ? redeemPageData.redeemType === 'Partial' ? 'P' : redeemPageData.redeemType === 'Full' ? 'F' : null : null
            }
            data.push(obj);
        })
        const recommendationData = {
            recommendation_data: data
        }
        if (shouldReturn == true) return;
        await handleEditSchemes(recommendationData);
        setIsLoading(false);
    }

    async function handleEditSchemes(value: any) {
        // const obj = {
        //     RecID: 10097,
        //     SchemeID: 10044,
        //     InvestmentAmount: 6540,
        //     SchemeType: 'Rec',
        //     rmID: 5,
        //     ClientCode: "O000906",
        //     No_of_Installments: 6,
        //     SIP_date: "2024-01-29",
        //     Frequency: "Monthly",
        //     AccountID: 'W000906',
        //     ToSchemeID: null,
        //     Folio: "10080986",
        //     Units: 0,
        //     PFFlag: null
        // }
        const isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (dispatch(
                    edit_recommended_schemes(value)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                isRecommended(true);
                response.then(async (response: any) => {
                    setTrigger(!trigger)
                    // Swal.fire({
                    //     html: `<b>${response.recommendations
                    //     [0].Response}</b>`,
                    //     icon: "success",
                    //     showCancelButton: false,
                    //     confirmButtonColor: "#3085d6",
                    //     confirmButtonText: "OK",
                    //     customClass: {
                    //         htmlContainer: 'custom-html-container-class',
                    //     }
                    // })

                })
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }

        })();


    }

    async function getRedeemValidations() {

        // const data: any = [];
        // selectedSchemes?.map((item: any) => {
        //     const obj = {
        //         SchemeID: item.SchemeID,
        //         AccountID: item.accountID,
        //         Folio: item.Folio,
        //     }
        //     data.push(obj)
        // }
        // )
        let isComponentActive = true;
        setRedeemBalance(null);
        (async function () {
            try {
                const response = (await dispatch(
                    redeem_validation({
                        AccountID: redeemPageData[0].accountID,
                        AccNo: redeemPageData[0].Folio,
                        SchId: `${redeemPageData[0].SchemeID}`
                    })
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRedeemBalance(response.validation[0][0]);
            }
            catch (e: any) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    useEffect(() => {
        if (page == "Swt" || page == "Red")
            getRedeemValidations();

    }, [])




    const SIPDataValidation = () => {
        const updatedData = schemeParams ? [...schemeParams] : [];
        let valid = true;
        updatedData?.map((item: any) => {
            let currentSIPValidation: any = {};
            validations.forEach((i: any) => {
                const productCode = Object.keys(i)[0];
                const frequencyMatches = i[productCode].find((entry: any) => entry.SIPFREQUENCY === item.frequency);
                if (productCode === item.Sch && frequencyMatches)
                    currentSIPValidation = frequencyMatches
            });
            // const currentSIPValidation = SIPInstallment?.find((i: any) => i.Productcode_RTA == item.Sch);

            if (item.switchValue === 'ISIP' || item.switchValue === 'XSIP' || item.switchValue === 'STP' || item.switchValue === 'SWP') {

                if (item.selectedAccount == "") {
                    item.errorAccountSIP = true;
                    item.errorMessageAccount = "Account can not be empty";
                    valid = false;
                }
                else {
                    item.errorAccountSIP = false;
                }
                if (item.instalmentAmount === null || item.instalmentAmount === 0) {
                    item.errorInstalmentAmount = true;
                    item.errorMessageInstalmentAmount = "Instalment Amount can not be empty";
                    valid = false;
                }
                else if (item.instalmentAmount && (item.instalmentAmount < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT) || item.instalmentAmount > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT))) {
                    item.errorInstalmentAmount = true;
                    item.errorMessageInstalmentAmount = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT}`;
                    valid = false;
                }
                else {
                    item.errorInstalmentAmount = false;
                }
                if (item.instalments === 0) {

                    item.errorInstalment = true;
                    item.errorMessageInstalment = "No. of Instalments can not be empty";
                    valid = false;
                }
                else if (item.instalments && (item.instalments < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS) || item.instalments > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS))) {
                    item.errorInstalment = true;
                    item.errorMessageInstalment = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`;
                    valid = false;
                }
                else {
                    item.errorInstalment = false;
                }

                if (item.SIPDay == "") {
                    item.errorSIPDay = true;
                    item.errorMessageSIPDay = "SIP Day can not be empty";
                    valid = false;
                }
                else {
                    item.errorSIPDay = false;
                }

                if (item.frequency == null) {
                    item.errorFrequency = true;
                    item.errorMessageFrequency = "Frequency can not be empty";
                    valid = false;
                }
                else {
                    item.errorFrequency = false;
                }
            }

        })
        setSchemeParams(updatedData);
        return valid;
    }
    //   const newSchemeParamsData: any[] = selectedSchemes?.map((ele: any) => {
    //     return { ...ele,selectedAccount:""}
    // })

    const isDateDisabled = (date: Date, Sch: string): boolean => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (date < currentDate) {
            return false;
        }

        const foundArray = validations.filter((value: any) => {
            return Object.keys(value).includes(Sch)
        })

        const foundValue: any = foundArray[0][Sch]?.find((val: any) => val.SIPFREQUENCY == schemeParams?.find((data) => data.Sch === Sch)?.frequency);
        const datesArray: any = foundValue?.SIPDATES.split(',');

        if (datesArray.includes(date.getDate().toString())) {
            return true;
        } else {
            return false;
        }
    };

    const invAmountValidation = () => {
        const updatedData = schemeParams ? [...schemeParams] : [];
        let valid = true;
        updatedData?.map((item) => {
            //Code should be change
            if (item.switchValue === 'Add' || item.switchValue === 'New') {
                if (item.selectedAccount == "") {
                    item.errorAccountLumpsum = true;
                    item.errorMessageAccount = "Account can not be empty";
                    valid = false;
                }
                else {
                    item.errorAccountLumpsum = false;
                }
                if (item.invAmount == null || item.invAmount == 0) {
                    item.error = true;
                    item.errorMessage = "Amount can not be empty";
                    valid = false;
                }
                else if (item.invAmount && (item.invAmount < item.minPurchase || item.invAmount > item.maxPurchase)) {
                    item.error = true;
                    item.errorMessage = `Enter Amount between ${item.minPurchase} - ${item.maxPurchase}`;
                    valid = false;
                    // setError(true);
                    // setErrorMessage("Enter Amount more the 1000");
                }
                else {
                    item.error = false;
                }
            }
        })
        setSchemeParams(updatedData);
        return valid;
    }

    const RedeemValidation = () => {
        const updatedData = schemeParams ? [...schemeParams] : [];
        let valid = true;
        updatedData?.map((item) => {
            if (item.switchValue === 'Red' || item.switchValue === 'Swt') {
                if (redeemPageData.redeemValue.value === "" || redeemPageData.redeemValue.value <= 0 || (redeemPageData.redeemType == "Full" && Number(redeemPageData.redeemValue.value) <= 0)) {
                    setError({ errorRedemptionValue: true, errorRedemptionValueMsg: "Redemption Amount can not be empty or negative" })
                    valid = false;
                }
                else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "INR" && Number(redeemPageData.redeemValue.value) > redeemBalance.Amount)) {
                    setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${redeemBalance.Amount}` })
                    valid = false;
                }
                else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "Units" && Number(redeemPageData.redeemValue.value) > redeemBalance.Units)) {
                    setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${redeemBalance.Units}` })
                    valid = false;
                }
                else {
                    setError({ errorRedemptionValue: false, errorRedemptionValueMsg: "" })
                }
                return valid;
            }
        })
        setSchemeParams(updatedData);
        return valid;
    }
    const changeInsSIPAmt = (schemeId: any, sch: any, FrequencyVal: any) => {
        const currentValidation = validations.find((item: any) => {
            const productCode = Object.keys(item)[0];
            const frequencyMatches = item[productCode].some((entry: any) => entry.SIPFREQUENCY === FrequencyVal);
            return productCode === sch && frequencyMatches;
        });
        const result = currentValidation[sch].filter((item: any) => item.SIPFREQUENCY === FrequencyVal);
        const obj = result[0];
        const updatedData = SIPInstallment?.map((item: any) => {
            if (item.Productcode_RTA === sch) {
                return {
                    ...item,
                    SIPFREQUENCY: obj.SIPFREQUENCY,
                    SIPMINIMUMINSTALLMENTNUMBERS: obj.SIPMINIMUMINSTALLMENTNUMBERS,
                    SIPMAXIMUMINSTALLMENTNUMBERS: obj.SIPMAXIMUMINSTALLMENTNUMBERS,
                    SIPDATES: obj.SIPDATES,
                    SIPMINIMUMINSTALLMENTAMOUNT: obj.SIPMINIMUMINSTALLMENTAMOUNT,
                    SIPMAXIMUMINSTALLMENTAMOUNT: obj.SIPMAXIMUMINSTALLMENTAMOUNT
                };
            }

            return item;
        });
        setSIPInstallment(updatedData)
    }
    const [schemeValidation, setSchemeValidation] = useState<any>();
    const [switchValue, setSwitchValue] = useState<any>({ value: (schemeParams[0]?.PFFlag == "F" ? redeemBalance?.Amount : (schemeParams[0]?.invAmount != null || schemeParams[0]?.invAmount != undefined) ? schemeParams[0]?.invAmount.toString() : "0"), type: "INR" })

    useEffect(() => {
        setSchemeValidation(redeemBalance);
        if (schemeParams[0]?.PFFlag == "F" && redeemBalance != undefined) {
            setSwitchValue({ value: redeemBalance?.Amount, type: "INR" })
        }
    }, [redeemBalance])

    return (
        <Box>
            <Box sx={{ mb: 3, px: 3, display: 'flex', justifyContent: 'space-between' }}>
                <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                    Edit Recommendation
                </Typography>
                <Box onClick={handleModalClose} sx={{ cursor: 'pointer' }}>
                    <CloseIcon />
                </Box>
            </Box>
            <Divider />
            {(schemeParams?.length === 0) ?
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Typography sx={{ fontSize: 18, color: '#5a7c82', width: '50%', margin: '10% 0% 0% 25%' }}>Please select a scheme</Typography>
                </Box> :
                <>
                    {selectAccount ? (
                        <Box sx={{
                            height: '330px',
                        }}>
                            <LinearProgress sx={{ mt: 2.5 }} />
                        </Box>
                    ) : (
                        <>
                            <Box sx={{
                                height: '250px',
                                overflowY: `${schemeParams?.length && schemeParams?.length > 1 ? "scroll" : 'hidden'}`
                            }}>
                                {schemeParams?.map((val: schemeParams, index: any) => {
                                    const plan = val.Growthoption === 1
                                        ? "Growth"
                                        : val.Dividendreinvestment === 1
                                            ? "Div"
                                            : val.DividendPayout === 1
                                                ? "Div"
                                                : "";
                                    const subPlan = val.Dividendreinvestment === 1
                                        ? "Reinvestment"
                                        : val.DividendPayout === 1
                                            ? "Payout"
                                            : "";
                                    const currentSIPValidation = SIPInstallment?.find((i: any) => i.Productcode_RTA == val.Sch);
                                    return (
                                        <Box sx={{ px: 2, mb: 6, }} key={index}>
                                            {/* 1 */}
                                            <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.5 }}>
                                                {/* val.SchemeType === "ISIP" ? "New Purchase SIP" : val.SchemeType */}
                                                Recomendation Type :  {val.SchemeType === "ISIP" ? "New Purchase SIP" : (val.SchemeType === "XSIP" ? "Additional Purchase SIP" : (val.SchemeType === "Add" ? "Additional Purchase Lumpsum" : (val.SchemeType === "New" ? "New Purchase Lumpsum" : val.SchemeType)))}
                                            </Typography>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', cursor: 'pointer', my: 4.5 }}>

                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: "30%" }}>
                                                    <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.5 }}>
                                                        {val.SchemeName}
                                                    </Typography>
                                                    <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                                                        {!!val.AssetClassName && (
                                                            <Bubble text={val.AssetClassName} />
                                                        )}
                                                        {!!val.Sub_AssetclassName && (
                                                            <Bubble text={val.Sub_AssetclassName} />
                                                        )}
                                                        {plan && <Bubble text={plan} />}
                                                        {subPlan ? subPlan.length !== 0 && <Bubble text={subPlan} /> : ""}
                                                        {subPlan && !!val.Dividendfrequency && (
                                                            <Bubble text={val.Dividendfrequency} />
                                                        )}
                                                    </Box>

                                                </Box>
                                                {/* Folio */}
                                                {(val.switchValue === 'ISIP' || val.switchValue === 'New') ?
                                                    <></>
                                                    : <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Selected Folio</Typography>
                                                        <input type="text" style={{ padding: "0.85rem", width: "8rem" }} className='investmentAmount' readOnly={true}
                                                            placeholder={val.Folio}
                                                        >
                                                        </input>
                                                    </Box>}
                                                {(val.switchValue === 'Add' || val.switchValue === 'New' || val.switchValue === 'XSIP' || val.switchValue === 'ISIP') ?
                                                    <Box sx={{ width: "6%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Returns</Typography>
                                                        <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                                            {val.Aum === "0" || !val.Aum
                                                                ? "NA"
                                                                : `${Nanprocessor(roundToTwo(Number(val.Sch_Return5Yr)))}%`}
                                                        </Typography>
                                                    </Box> : <></>
                                                }
                                                {/* {val.switchValue === '' ?
                                        <Box sx={{ width: "20%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Account <b style={{ color: 'red' }}>*</b></Typography>
                                            <FormControl fullWidth sx={{ mt: 0.5 }}>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={val.selectedAccount}
                                                    onChange={(e) => {
                                                        schemeParams[index].selectedAccount = e.target.value;
                                                        setSchemeParams([...schemeParams])
                                                        setSelectedAccountType(e.target.value as string)

                                                    }}
                                                    MenuProps={
                                                        {
                                                            // PaperProps: {
                                                            //     style: {
                                                            //         maxHeight: 48 * 4.5 + 8,
                                                            //         width: 150,
                                                            //         fontSize: ".6rem",
                                                            //         padding: 0
                                                            //     },
                                                            // },
                                                        }}
                                                    sx={{
                                                        border: "none",
                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                        width: "10rem",
                                                        height: '2rem',
                                                        borderRadius: '5px',
                                                        padding: '2px 1px',
                                                        fontSize: 10,
                                                    }}
                                                >
                                                    {
                                                        accountType?.account_type?.map((i: any, index: any) => (
                                                            <MenuItem key={index} value={i.AccountId}> {i.AccountType}</MenuItem>))
                                                    }
                                                </Select>
                                            </FormControl>
                                            {val.errorAccountLumpsum && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                                        </Box> : <></>
                                    } */}

                                                {(val.switchValue === 'Add' || val.switchValue === 'New' || val.switchValue === 'Red' || val.switchValue === 'Swt') ?
                                                    <>
                                                        {(val.switchValue === 'Add' || val.switchValue === 'New') ?
                                                            <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                                                                <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Account <b style={{ color: 'red' }}>*</b></Typography>
                                                                <FormControl fullWidth sx={{ mt: 0.5 }}>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        value={val.selectedAccount}
                                                                        defaultValue={val.selectedAccount}
                                                                        onChange={(e) => {
                                                                            schemeParams[index].selectedAccount = e.target.value;
                                                                            setSchemeParams([...schemeParams])
                                                                            setSelectedAccountType(e.target.value as string)

                                                                        }}

                                                                        sx={{
                                                                            border: "none",
                                                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                            // width: "10rem",
                                                                            height: '2rem',
                                                                            borderRadius: '5px',
                                                                            padding: '2px 3px',
                                                                            fontSize: 15,

                                                                        }}
                                                                    >
                                                                        {
                                                                            accountType?.account_type?.map((i: any, index: any) => (
                                                                                <MenuItem key={index} value={i.AccountType}> {i.AccountType}</MenuItem>))
                                                                        }
                                                                    </Select>
                                                                </FormControl>
                                                                {val.errorAccountSIP && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                                                            </Box>
                                                            : <></>}

                                                        {/* investment amount */}
                                                        {(val.switchValue === 'Red' || val.switchValue === 'Swt') ?
                                                            <>
                                                                {/* TO SchemeName */}
                                                                {val.switchValue === 'Swt' ?
                                                                    <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>

                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>To Scheme</Typography>
                                                                        <Typography sx={{ fontSize: 12 }}>
                                                                            {val.ToschemeName}

                                                                        </Typography>
                                                                    </Box> : <></>}
                                                                <Box sx={{ width: "17%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>

                                                                    <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500", marginBlock: 0.2, mr: 1, fontSize: 14, color: '#5a7c82' }}>{val.switchValue === "Swt" ? "Switch Type" : " Redemption Type"}</FormLabel>
                                                                    <RadioGroup
                                                                        sx={{ flexDirection: 'row', flexWrap: 'nowrap' }}
                                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                                        name="row-radio-buttons-group"
                                                                        // value={redeemPageData.redeemType}
                                                                        // onChange={(e: any) => {

                                                                        //     setRedeemPageData({ ...redeemPageData, redeemType: e.target.value, redeemValue: { value: e.target.value === "Partial" ? "" : redeemPageData.redeemValue.type === "INR" ? redeemBalance?.Amount : redeemBalance?.Units, type: redeemPageData.redeemValue.type } })
                                                                        // }}
                                                                        value={redeemPageData.redeemType}
                                                                        onChange={(e) => {
                                                                            if (e.target.value == "Full" && redeemPageData.redeemValue.type == "INR") {
                                                                                setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: redeemBalance?.Amount }, redeemType: e.target.value })
                                                                            }
                                                                            else if (e.target.value == "Full" && redeemPageData.redeemValue.type == "Units") {
                                                                                setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: redeemBalance?.Units }, redeemType: e.target.value })
                                                                            }
                                                                            else {
                                                                                setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: schemeParams[0]?.invAmount }, redeemType: e.target.value })
                                                                            }
                                                                        }}
                                                                    >
                                                                        <FormControlLabel value="Partial" control={
                                                                            <Radio
                                                                                sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 18,
                                                                                    },
                                                                                }}
                                                                            />}
                                                                            label="Partial" />
                                                                        <FormControlLabel value="Full" control={<Radio
                                                                            sx={{
                                                                                '& .MuiSvgIcon-root': {
                                                                                    fontSize: 18,
                                                                                },
                                                                            }} />} label="Full" />
                                                                    </RadioGroup>
                                                                </Box>
                                                                <Box sx={{ width: "20%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>

                                                                    <p style={{ fontSize: 14, color: '#5a7c82', marginTop: 0.8, marginBottom: 0.1 }}>{val.switchValue === "Swt" ? "Switch value" : " Redemption value"} <b style={{ color: 'red' }}>*</b></p>
                                                                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'flex-start' }}>

                                                                        {redeemBalance ?
                                                                            <input style={{ width: "100%", height: 30, marginTop: ".1rem", marginRight: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='text'
                                                                                value={redeemPageData.redeemValue.value}
                                                                                disabled={redeemPageData?.redeemType === "Full" ? true : false}
                                                                                onChange={(e) => {
                                                                                    let newValue = e.target.value;
                                                                                    newValue = newValue.replace(/[^0-9]/g, '');

                                                                                    // setSwitchValue({ ...switchValue, value: newValue })
                                                                                    setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: newValue } })
                                                                                }}
                                                                            // disabled={!redeemBalance}
                                                                            />
                                                                            : ''}
                                                                        {error.errorRedemptionValue && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorRedemptionValueMsg} </Typography>}
                                                                    </Box>
                                                                    <Box>

                                                                        <RadioGroup
                                                                            sx={{ display: "flex", flexDirection: "row" }}
                                                                            // value={redeemPageData.redeemValue.type}
                                                                            // onChange={(e: any) => {
                                                                            //     setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, type: e.target.value } })
                                                                            //     // FinalRedeemValue()
                                                                            // }}
                                                                            onChange={(e) => {
                                                                                if (e.target.value == "INR" && redeemPageData.redeemType == "Full") {
                                                                                    setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: redeemBalance?.Amount, type: e.target.value } })
                                                                                }
                                                                                else if (e.target.value == "Units" && redeemPageData.redeemType == "Full") {
                                                                                    setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: redeemBalance?.Units, type: e.target.value } })
                                                                                }
                                                                                else {
                                                                                    setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: schemeParams[0]?.invAmount, type: e.target.value } })
                                                                                }
                                                                            }}
                                                                            value={redeemPageData.redeemValue.type}
                                                                        >
                                                                            <FormControlLabel value="INR" control={<Radio
                                                                                sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 18,
                                                                                    },
                                                                                }} />} label="INR" />
                                                                            <FormControlLabel value="Units" control={<Radio
                                                                                sx={{
                                                                                    '& .MuiSvgIcon-root': {
                                                                                        fontSize: 18,
                                                                                    },
                                                                                }} />} label="Units" />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                </Box>



                                                            </> :
                                                            <Box sx={{ width: "16%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end", marginRight: "1rem" }}>
                                                                <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Investment Amount <b style={{ color: 'red' }}>*</b></Typography>
                                                                <input style={{
                                                                    // width: "10rem",
                                                                    border: "1px solid #C4C4C4", height: '2rem',
                                                                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                                                }} type="text" className='investmentAmount' placeholder={`Min. - Rs. ₹${val.minPurchase}`} value={val.invAmount}
                                                                    onChange={(e) => {
                                                                        // if (!isNaN(Number(e.target.value))) {
                                                                        const updatedData = [...schemeParams];
                                                                        const objectToUpdate = updatedData.find(item =>
                                                                            item.SchemeID === val.SchemeID
                                                                        );

                                                                        if (objectToUpdate) {
                                                                            objectToUpdate.invAmount = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                            setSchemeParams(updatedData);
                                                                        }
                                                                        // }
                                                                    }}>
                                                                </input>

                                                                {val.error && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessage} </Typography>}
                                                            </Box>
                                                        }
                                                    </>
                                                    : <>
                                                        {/* TO SchemeName */}
                                                        {val.switchValue === 'STP' ?
                                                            <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                                                                <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>To Scheme</Typography>
                                                                <Typography sx={{ fontSize: 12 }}>
                                                                    {val.ToschemeName}
                                                                </Typography>
                                                            </Box> : <></>}
                                                        {/* Account Type */}
                                                        {/* {(val.switchValue === 'ISIP') ? */}
                                                        <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                                                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Account <b style={{ color: 'red' }}>*</b></Typography>
                                                            <FormControl fullWidth sx={{ mt: 0.5 }}>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={val?.selectedAccount}
                                                                    defaultValue={val?.selectedAccount}
                                                                    onChange={(e) => {
                                                                        schemeParams[index].selectedAccount = e.target.value;
                                                                        setSchemeParams([...schemeParams])
                                                                        setSelectedAccountType(e.target.value as string)

                                                                    }}

                                                                    sx={{
                                                                        border: "none",
                                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                        // width: "10rem",
                                                                        height: '2rem',
                                                                        borderRadius: '5px',
                                                                        padding: '2px 3px',
                                                                        fontSize: 15,

                                                                    }}
                                                                >
                                                                    {
                                                                        accountType?.account_type?.map((i: any, index: any) => (
                                                                            <MenuItem key={index} value={i.AccountType}> {i.AccountType}</MenuItem>))
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                            {val.errorAccountSIP && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                                                        </Box>
                                                        {/* : <></>} */}
                                                        {/* // Frequency */}
                                                        <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end", marginRight: "1rem" }}>
                                                            <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Frequency <b style={{ color: 'red' }}>*</b></Typography>
                                                            <FormControl fullWidth>
                                                                <Select
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={val?.frequency}
                                                                    defaultValue={val?.frequency}

                                                                    onChange={(e) => {
                                                                        const updatedData = [...schemeParams];
                                                                        const objectToUpdate = updatedData.find(item =>
                                                                            item.SchemeID === val.SchemeID
                                                                        );

                                                                        if (objectToUpdate) {
                                                                            objectToUpdate.frequency = e.target.value;
                                                                            setSchemeParams(updatedData);
                                                                        }
                                                                        changeInsSIPAmt(val.SchemeID, val.Sch, e.target.value);
                                                                    }}
                                                                    sx={{
                                                                        border: "none",
                                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                        // width: "10rem",
                                                                        height: '2rem',
                                                                        borderRadius: '5px',
                                                                        padding: '2px 3px',
                                                                        fontSize: 15,
                                                                    }}
                                                                >
                                                                    {validations?.find((entry: any) => entry[val.Sch])?.[val.Sch]?.map((item: any, index: any) => (
                                                                        <MenuItem key={index} value={item.SIPFREQUENCY}>{item.SIPFREQUENCY}</MenuItem>
                                                                    ))}
                                                                </Select>
                                                            </FormControl>
                                                            {val.errorFrequency && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageFrequency} </Typography>}
                                                        </Box></>
                                                }
                                                {/* <Box sx={{ width: "15%", display: 'flex', alignItems: 'flex-end', justifyContent: 'center', p: 3 }}>
                                        <Typography
                                            sx={{
                                                color: '#22242C',
                                                opacity: 0.8,
                                                fontSize: 14,
                                            }}>
                                            SIP
                                        </Typography>
                                        <Switch
                                            checked={val.switchValue === 'Lumpsum'}
                                            onChange={(_, checked) => {
                                                setSelectedAccountType("")
                                                const updatedData = [...schemeParams];
                                                const objectToUpdate = updatedData.find(item => item.SchemeID === val.SchemeID);
                                                const mapToDoubleDigit = (number: any): string => {
                                                    return number < 9 ? `0${number + 1}` : `${number + 1}`;
                                                };
                                                const mapDayToDoubleDigit = (number: any): string => {
                                                    return number <= 9 ? `0${number}` : `${number}`;
                                                };

                                                setSchemeParams([...schemeParams])
                                                if (objectToUpdate) {
                                                    const date = new Date();
                                                    objectToUpdate.switchValue = (checked ? 'Lumpsum' : 'SIP');
                                                    objectToUpdate.SIPDay = checked ? "" : `${mapDayToDoubleDigit(date?.getDate())}/${mapToDoubleDigit(date?.getMonth())}/${date?.getFullYear()}`
                                                    // objectToUpdate.datePicker = checked ? null : date;
                                                    updatedData[index].selectedAccount = "";
                                                    updatedData[index].invAmount = 0;
                                                    setSchemeParams(updatedData);
                                                }
                                            }
                                            }
                                            sx={{
                                                p: 0,
                                                mx: 2,
                                                height: 20,
                                                width: 43,
                                                '& .MuiSwitch-track': {
                                                    background: '#4990F0',
                                                    opacity: 1,
                                                    borderRadius: '12px',
                                                },
                                                '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                                                '& .MuiSwitch-switchBase': {
                                                    p: 0,
                                                    '&.Mui-checked': {
                                                        color: '#fff',
                                                        transform: 'translateX(32px)',
                                                        '& + .MuiSwitch-track': {
                                                            backgroundColor: '#4990F0',
                                                            opacity: 1,
                                                        },
                                                    },
                                                },
                                            }}
                                        />
                                        <Typography
                                            sx={{
                                                color: '#22242C',
                                                opacity: 0.8,
                                                fontSize: 14
                                            }}>
                                            Lumpsum
                                        </Typography>
                                    </Box> */}
                                                {/* <Box sx={{ width: "5%", display: 'flex', alignItems: "center", justifyContent: "flex-end", p: 2, ml: 1 }}>
                                        <div onClick={() => { OnSchemeDeSelection_(val.SchemeID); }}><DeleteIcon /></div>
                                    </Box> */}
                                            </Box>
                                            <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                                {(val.SchemeType == "New" || val.switchValue === 'ISIP' || val.switchValue === 'New') && <Box sx={{ width: "16%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                                                    <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Folio </Typography>
                                                    <FormControl fullWidth sx={{ mt: 0.5 }}>
                                                        <Select
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={val?.Folio}

                                                            onChange={(e) => {
                                                                schemeParams[index].Folio = e.target.value;
                                                                setSchemeParams([...schemeParams])
                                                            }}

                                                            sx={{
                                                                border: "none",
                                                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                                                // width: "10rem",
                                                                height: '2rem',
                                                                borderRadius: '5px',
                                                                padding: '2px 3px',
                                                                fontSize: 15,

                                                            }}
                                                        >

                                                            {folio && folio.length > 0 ? (
                                                                folio.map((i: any, index: any) => {
                                                                    return <MenuItem key={index} value={i.Folio}>{i.Folio}</MenuItem>;
                                                                })
                                                            ) : (
                                                                <MenuItem value='new'>
                                                                    New Folio
                                                                </MenuItem>
                                                            )}

                                                        </Select>
                                                    </FormControl>
                                                    {val.errorAccountSIP && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                                                </Box>}
                                                {/* 2 */}
                                                {
                                                    val.switchValue === 'ISIP' || val.switchValue === 'XSIP' || val.switchValue === 'STP' || val.switchValue === 'SWP' ? <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', my: 2.5, gap: "1rem" }}>

                                                        {schemeParams.find(item =>
                                                            item.SchemeID === val.SchemeID
                                                        )?.frequency != null ?
                                                            <>
                                                                {/* Instalment Amount */}
                                                                <Box sx={{ width: "34%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                                    <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>{val.SchemeType === "STP" ? "Monthly Amount" : (val.SchemeType === "SWP" ? " Monthly SWP Amount  " : "Instalment Amount")}<b style={{ color: 'red' }}>*</b></Typography>

                                                                    <input style={{
                                                                        width: "15rem", height: '2rem', border: "1px solid #C4C4C4",
                                                                        boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                                                    }} type="text" className='investmentAmount' placeholder={`Min. - Rs. ₹${schemeParams[0]?.instalmentAmount}`} value={val.instalmentAmount}
                                                                        onChange={(e) => {
                                                                            const updatedData = [...schemeParams];
                                                                            const objectToUpdate = updatedData.find(item =>
                                                                                item.SchemeID === val.SchemeID
                                                                            );

                                                                            if (objectToUpdate) {
                                                                                objectToUpdate.instalmentAmount = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                                setSchemeParams(updatedData);
                                                                            }
                                                                            // }
                                                                        }}>
                                                                    </input>
                                                                    {val.errorInstalmentAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageInstalmentAmount} </Typography>}
                                                                </Box>
                                                                {/* No. of instalments */}
                                                                {val.frequency === 'DAILY' ? '' :
                                                                    <Box sx={{ width: "34%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>No. of Instalments <b style={{ color: 'red' }}>*</b></Typography>
                                                                        <input style={{
                                                                            width: "15rem", border: "1px solid #C4C4C4",
                                                                            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                                                        }} type="text" className='investmentAmount2' placeholder={`${val.instalments}`} value={val.instalments}
                                                                            // placeholder={`Enter Number ${currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS} - ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`}
                                                                            onChange={(e) => {
                                                                                // if (!isNaN(Number(e.target.value))) {
                                                                                const updatedData = [...schemeParams];
                                                                                const objectToUpdate = updatedData.find(item =>
                                                                                    item.SchemeID === val.SchemeID
                                                                                );

                                                                                if (objectToUpdate) {
                                                                                    objectToUpdate.instalments = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                                                                    setSchemeParams(updatedData);
                                                                                }
                                                                                // }
                                                                            }}>
                                                                        </input>
                                                                        {val.errorInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageInstalment} </Typography>}
                                                                    </Box>
                                                                }
                                                                {/* SIP Day */}

                                                                <Box sx={{ width: "34%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                                                    <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>{val.SchemeType === "STP" ? "STP Date" : (val.SchemeType === "SWP" ? "SWP date" : "SIP Date")}<b style={{ color: 'red' }}>*</b></Typography>
                                                                    <DatePicker
                                                                        className="custom-datepicker"
                                                                        // ref={datePickerRef}
                                                                        selected={val.datePicker}
                                                                        onChange={(date: Date) => {
                                                                            const updatedData = [...schemeParams];
                                                                            const objectToUpdate = updatedData.find(item =>
                                                                                item.SchemeID === val.SchemeID
                                                                            );
                                                                            const mapToDoubleDigit = (number: number): string => {
                                                                                return number < 9 ? `0${number + 1}` : `${number + 1}`;
                                                                            };
                                                                            const mapDayToDoubleDigit = (number: any): string => {
                                                                                return number <= 9 ? `0${number}` : `${number}`;
                                                                            };
                                                                            if (objectToUpdate) {
                                                                                objectToUpdate.SIPDay = `${date?.getFullYear()}/${mapToDoubleDigit(date?.getMonth())}/${mapDayToDoubleDigit(date?.getDate())}`
                                                                                objectToUpdate.datePicker = date
                                                                                setSchemeParams(updatedData);
                                                                            }
                                                                        }}
                                                                        dateFormat="dd-MM-yyyy"
                                                                        filterDate={(date: Date) => isDateDisabled(date, val.Sch)}
                                                                        placeholderText={'dd-mm-yyyy'}
                                                                    // customInput={<CustomInput />}

                                                                    />
                                                                    {val.errorSIPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageSIPDay} </Typography>}
                                                                </Box>
                                                            </> : <></>}

                                                        {/* Mandate */}
                                                        {/* <Box sx={{ width: "24%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                      <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Mandate</Typography>
                      <input type="" className='investmentAmount2' value={val.mandate}
                        onChange={(e) => {
                          const updatedData = [...schemeParams];
                          const objectToUpdate = updatedData.find(item =>
                            item.SchemeID === val.SchemeID
                          );
  
                          if (objectToUpdate) {
                            objectToUpdate.mandate = e.target.value;
                            setSchemeParams(updatedData);
                          }
                        }}>
                      </input>
                      {val.error && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessage} </Typography>}
                      </Box>*/}
                                                    </Box> : <></>
                                                }
                                            </Box>
                                        </Box>
                                    )
                                })
                                }
                            </Box>



                            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 0 }}>
                                {/* {schemeParams?.length !== 0 ?  */}
                                <Button
                                    sx={{
                                        height: '40px',
                                        color: 'white',
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        cursor: 'pointer',
                                        borderRadius: '5px',
                                        bgcolor: '#337FC9',
                                        width: '40%',
                                        '&:hover': { backgroundColor: '#337FC9' },
                                    }}
                                    onClick={handleSubmit}
                                // alert(JSON.stringify(schemeParams))
                                >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : "Update"}</Button>
                                {/* : <></>} */}
                            </Box>

                            <Typography sx={{ color: 'red', fontSize: '12px', m: 2.5, width: "50%" }}> * Mandatory fields</Typography>
                        </>)}
                </>}
        </Box >
    )
}



import { Box, Button, Divider, MenuItem, Select, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetAllocationCard, ProgressBarIntervals, RevenuePercent, scrollbarView } from '.';
import { setFilters } from '../../redux-store/actions/product';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { ProductOverviewType, ProductOverviewAssetAllocationEntity, ProductOverviewAmcPerformanceEntity, ProductOverviewAssetAllocationSebiEntity } from '../../redux-store/types/api-types';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { CommonLayout, CopyGraphDropdown, AmountUnit } from '../commonComponents';
import GroupedBarChart, { colors } from './components/grouped-bar-chart';
import { ProductTopSection } from './product-details';
import { useLocation } from 'react-router';
import ReactCardFlip from 'react-card-flip';
import { ColumnType, DataTable } from '../DataTable';
import { FlipIcon } from '../customSVGs';
import { useReactToPrint } from "react-to-print";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function ProductOverview({
  investorId,
  selectedFilters,
  setAssetTypes,
  selectedPortfolio
}: {
  investorId: string; selectedPortfolio: number;
  selectedFilters: { [key: string]: string[] };
  setAssetTypes?: React.Dispatch<React.SetStateAction<string[]>>;
}): JSX.Element {
  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<ProductOverviewType | null>(null);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const [flipcategory, setFlipcategory] = useState(false);
  const [flipamcPerformance, setFlipamcPerformance] = useState(false);
  const [flipsebiCategory, setFlipsebiCategory] = useState(false);
  const componentRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setOverviewData(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'MUTUAL FUNDS',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              ...selectedFilters,
              customers: investorId ? [investorId] : [],
            },
            required: ['overview'],
          })
        )) as unknown as ProductOverviewType;
        if (!isComponentActive) {
          return;
        }
        setAssetTypes &&
          setAssetTypes(response.assetAllocation.map((each) => each.assetCategory.toLowerCase()));
        dispatch(setFilters(response.dropdownLists));
        setOverviewData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedFilters, location.pathname, selectedPortfolio]);
  // Graph refs
  const amcAllocationRef = useRef(null);
  const amcPerformanceRef = useRef(null);
  const assetAllocationRef = useRef(null);
  const [assetBySebi, setAssetBySebi] = useState(false);
  const [amcPerformanceViewAll, setAmcPerformanceViewAll] = useState<boolean>(false);
  const assetBySebiDataDisplay = assetBySebi
    ? overviewData?.assetAllocationSebiCat
    : overviewData?.assetAllocationSebiCat.slice(0, 5);

  const amcPerformance = amcPerformanceViewAll
    ? overviewData?.amcPerformance
    : overviewData?.amcPerformance.slice(0, 10);

  const totalInvAmount =
    overviewData?.assetAllocation &&
    overviewData?.assetAllocation.reduce(function (tot, arr) {
      return tot + arr.investedValue;
    }, 0);
  const totalMktAmount =
    overviewData?.assetAllocation &&
    overviewData?.assetAllocation.reduce(function (tot, arr) {
      return tot + arr.currentValue;
    }, 0);

  const amcPerformanceGraphData =
    amcPerformance?.sort((a, b) => b.currentValue - a.currentValue).map((each) => {
      return {
        name: each.amc,
        data: {
          'Current Market Value': each.currentValue,
          'Invested Value': each.investedValue,
          'Unrealised Gain/Loss': each.gainLoss,
        },
      };
    }) || [];

  const categoryTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Asset Class',
        sortingKey: 'assetCategory',
        valueGetter: (row: ProductOverviewAssetAllocationEntity) => row?.assetCategory,
      },
      {
        header: 'Invested Value',
        sortingKey: 'investedValue',
        valueGetter: (row: ProductOverviewAssetAllocationEntity, amountUnit?: number | null | undefined) => row?.investedValue || row?.investedValue === 0 ? `${formatToIndianCurrency(row?.investedValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'currentValue',
        valueGetter: (row: ProductOverviewAssetAllocationEntity, amountUnit?: number | null | undefined) => row?.currentValue || row?.currentValue === 0 ? `${formatToIndianCurrency(row?.currentValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'investedValuePercentage',
        valueGetter: (row: ProductOverviewAssetAllocationEntity) => row?.investedValuePercentage,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'currentValPercentage',
        valueGetter: (row: ProductOverviewAssetAllocationEntity) => row?.currentValPercentage,
      },
    ];
  }
  const amcPerformanceAnalysisTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'AMC',
        sortingKey: 'amc',
        valueGetter: (row: ProductOverviewAmcPerformanceEntity) => row?.amc,
      },
      {
        header: 'Invested Value',
        sortingKey: 'investedValue',
        valueGetter: (row: ProductOverviewAmcPerformanceEntity, amountUnit?: number | null | undefined) => row?.investedValue || row?.investedValue === 0 ? `${formatToIndianCurrency(row?.investedValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'currentValue',
        valueGetter: (row: ProductOverviewAmcPerformanceEntity, amountUnit?: number | null | undefined) => row?.currentValue || row?.currentValue === 0 ? `${formatToIndianCurrency(row?.currentValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Unrealised Gain /Loss',
        sortingKey: 'gainLoss',
        valueGetter: (row: ProductOverviewAmcPerformanceEntity, amountUnit?: number | null | undefined) => row?.gainLoss || row?.gainLoss === 0 ? `${formatToIndianCurrency(row?.gainLoss, amountUnit)}` : 'N/A',
      },
    ];
  }
  const sebiCategoryTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Asset Class',
        sortingKey: 'I_AssetClass',
        valueGetter: (row: ProductOverviewAssetAllocationSebiEntity) => row?.I_AssetClass,
      },
      {
        header: 'Market Value',
        sortingKey: 'I_MarKetValue',
        valueGetter: (row: ProductOverviewAssetAllocationSebiEntity, amountUnit?: number | null | undefined) => row?.I_MarKetValue || row?.I_MarKetValue === 0 ? `${formatToIndianCurrency(row?.I_MarKetValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Allocation %',
        sortingKey: 'Hper',
        valueGetter: (row: ProductOverviewAssetAllocationSebiEntity) => row?.Hper,
      },
    ];
  }

  return (
    <div ref={componentRef}>
      {/* <CommonLayout>
      <> */}
      <Box>
        <Box
          sx={{
            mb: 3,
          }}>
          <AmountUnit />
          <Button
            onClick={() => window.print()}

            sx={{
              marginLeft: '20px',
              height: '27px',
              color: 'white',
              fontSize: '13px',
              cursor: 'pointer',
              borderRadius: '5px',
              bgcolor: '#4990F0',
              '&:hover': { backgroundColor: '#639FF0' },
            }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>
          {/* // onClick={handlePrint}

        // className="print_button" 
            // sx={{ */}
          {/* //   // bgcolor: 'white',
            //   color:'black',
            //   // borderRadius: '5px',
            //   // fontWeight: '',
            //   fontSize:'large',
            //   padding: 0,
            //   px: 2,
            //   py: 3,
            //   '&:hover': { bgcolor: '#2868a6' },
            // }}  */}
        </Box>
        {overviewData?.assetAllocation.length == 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem', fontSize: '18px', fontWeight: 500, color: '#666', background: 'white', boxShadow: '10rem', marginTop:'4rem' }}>No portfolio details available.</div> :
        <Box>
        {investorId && (
          <ProductTopSection
            marketValue={overviewData?.topValuesOverview?.currentValue || 0}
            investedValue={overviewData?.topValuesOverview?.investedValue || 0}
            gainLoss={overviewData?.topValuesOverview?.gainLoss}
            xirr={investorId ? overviewData?.topValuesOverview?.xirr : null}
            isLoading={isLoading}
          />
        )}
        <Box
          ref={amcAllocationRef}
          sx={{
            background: ' #FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              Asset Allocation by Category
            </Typography>
            <Box sx={{
              display: 'flex', alignItems: 'center'
            }}>

              {/* <CopyGraphDropdown elementRef={amcAllocationRef} /> */}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon
              onClick={() => (setFlipcategory(!flipcategory))}
              style={{
                height: '20px',
                cursor: 'pointer'
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipcategory}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipcategory ? 'none' : 'block'}`
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                  justifyContent: 'center',
                  width: '100%',
                }}>
                <Box sx={{ display: 'flex', width: '100%' }}>
                  <Box style={{ width: '50%', textAlign: 'right', paddingRight: '20px' }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#22242C',
                        opacity: 0.8,
                        mb: 1,
                        textAlign: 'right',
                      }}>
                      Invested Value
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 20,
                        color: '#11263C',
                        mb: 3.5,
                        fontWeight: 700,
                        textAlign: 'right',
                      }}>
                      {isLoading ? (
                        <Skeleton />
                      ) : totalInvAmount ? (
                        `${formatToIndianCurrency(totalInvAmount, valueConverter)}`
                      ) : (
                        'NA'
                      )}
                    </Typography>
                  </Box>
                  <Box style={{ width: 150, textAlign: 'center' }}></Box>
                  <Box style={{ width: '50%', paddingLeft: '20px' }}>
                    <Typography
                      sx={{
                        fontSize: 14,
                        color: '#22242C',
                        opacity: 0.8,
                        mb: 1,
                      }}>
                      Current Market Value
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 20,
                        color: '#11263C',
                        mb: 3.5,
                        fontWeight: 700,
                      }}>
                      {isLoading ? (
                        <Skeleton />
                      ) : totalMktAmount ? (
                        `${formatToIndianCurrency(totalMktAmount, valueConverter)}`
                      ) : (
                        'NA'
                      )}
                    </Typography>
                  </Box>
                </Box>
                {overviewData?.assetAllocation
                  ? overviewData?.assetAllocation.map((asset, index: number) => (
                    <AssetAllocationCard
                      key={index}
                      label={asset?.assetCategory}
                      investedPercent={asset?.investedValuePercentage}
                      currentPercent={asset?.currentValPercentage}
                      investedValue={asset?.investedValue}
                      currentValue={asset?.currentValue}
                      labelStyles={{ border: 0 }}
                    />
                  ))
                  : [...Array(3)].map((asset, index: number) => (
                    <AssetAllocationCard
                      key={index}
                      label={asset?.assetCategory}
                      investedPercent={asset?.investedValuePercentage}
                      currentPercent={asset?.currentValPercentage}
                      investedValue={asset?.investedValue}
                      currentValue={asset?.currentValue}
                      isLoading={true}
                    />
                  ))}
              </Box>
            </div>
            <div
              style={{
                height: '282px',
                display: `${flipcategory ? 'block' : 'none'}`
              }}>
              <DataTable
                isLoading={isLoading}
                tableHeader={[...categoryTableHeaders()]}
                tableData={overviewData?.assetAllocation && overviewData?.assetAllocation}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />

            </div>
          </ReactCardFlip>
        </Box>

        <Box
          ref={amcPerformanceRef}
          sx={{
            background: ' #FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              AMC Performance Analysis
            </Typography>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}>
              {['Current Market Value', 'Invested Value', 'Unrealised Gain/Loss'].map((k, i) => (
                <div
                  key={k}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: 20,
                    fontWeight: 'normal',
                    fontSize: 12,
                  }}>
                  <div
                    style={{
                      borderRadius: '50%',
                      width: 12,
                      height: 12,
                      backgroundColor: colors[i],
                      marginRight: 5,
                    }}
                  />
                  <span>{k}</span>
                </div>
              ))}
            </div>
            <Box>
              <Button
                onClick={() => setAmcPerformanceViewAll(!amcPerformanceViewAll)}
                sx={{
                  fontSize: 14,
                  lineHeight: '16px',
                  color: '#4E5056',
                  fontWeight: 500,
                  padding: '4px 22px',
                  boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                  border: '1px solid #0BAAE7',
                  borderRadius: '5px',
                }}>
                {amcPerformanceViewAll ? 'View less' : 'View all'}
              </Button>

              {/* <CopyGraphDropdown elementRef={amcPerformanceRef} /> */}
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon
              onClick={() => (setFlipamcPerformance(!flipamcPerformance))}
              style={{
                height: '20px',
                cursor: 'pointer'
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipamcPerformance}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipamcPerformance ? 'none' : 'block'}`
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 2,
                  justifyContent: 'center',
                  width: '100%',
                }}>
                {amcPerformanceGraphData && (
                  <GroupedBarChart
                    data={amcPerformanceGraphData}
                    dimensions={{ WIDTH: 400 }}
                    barDimensions={{ height: 8, spacing: 4, groupSpacing: 10 }}
                  />
                )}
              </Box>
            </div>

            <div
              style={{
                maxHeight: '500px',
                display: `${flipamcPerformance ? 'block' : 'none'}`
              }}>

              <DataTable
                isLoading={isLoading}
                tableHeader={[...amcPerformanceAnalysisTableHeaders()]}
                tableData={overviewData?.amcPerformance && overviewData?.amcPerformance}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />

            </div>
          </ReactCardFlip>
        </Box>
        <Box
          ref={assetAllocationRef}
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              Asset Allocation by SEBI Category {!assetBySebi ? '(Top 5)' : ''}
            </Typography>
            {overviewData?.assetAllocationSebiCat && overviewData?.assetAllocationSebiCat.length > 5 && (
              <Box>
                <Button
                  sx={{
                    fontSize: 14,
                    lineHeight: '16px',
                    color: '#4E5056',
                    fontWeight: 500,
                    padding: '4px 22px',
                    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                    border: '1px solid #0BAAE7',
                    borderRadius: '5px',
                  }}
                  onClick={() => setAssetBySebi(!assetBySebi)}>
                  {assetBySebi ? 'View Less' : ' View all'}
                </Button>

                {/* <CopyGraphDropdown elementRef={assetAllocationRef} /> */}
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon
              onClick={() => (setFlipsebiCategory(!flipsebiCategory))}
              style={{
                height: "20px",
                cursor: 'pointer'
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipsebiCategory}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipsebiCategory ? 'none' : 'block'}`
              }}
            >
              <Box
                sx={{
                  p: 2,
                  maxHeight: '350px',
                  overflow: 'auto',
                  ...scrollbarView,
                }}>
                <Box>
                  <Box
                    sx={{
                      py: 3,
                      px: 1,
                      pb: 0.2,
                      position: 'relative',
                    }}>
                    <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                    {overviewData?.assetAllocationSebiCat
                      ? assetBySebiDataDisplay &&
                      assetBySebiDataDisplay.map((ele, index: number) => {
                        const colors = ['#B8E3FF', '#21639F', '#8ED8B7', '#4990F0', '#BA87ED'];
                        return (
                          <RevenuePercent
                            key={index}
                            label={ele.I_AssetClass}
                            percent={ele.Hper}
                            amount={`${ele?.Hper}%`}
                            color={colors[index % 5]}
                            popOverAmt={ele.I_MarKetValue}
                            isLoading={isLoading}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })
                      : [...Array(3)].map((_, index: number) => {
                        return (
                          <RevenuePercent
                            key={index}
                            label=""
                            percent={0}
                            amount=""
                            color=""
                            isLoading={true}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </div>
            <div
              style={{
                maxHeight: '335px',
                display: `${flipsebiCategory ? 'block' : 'none'}`
              }}>
              <DataTable
                isLoading={isLoading}
                tableHeader={[...sebiCategoryTableHeaders()]}
                tableData={overviewData?.assetAllocationSebiCat && overviewData?.assetAllocationSebiCat}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />

            </div>
          </ReactCardFlip>
        </Box>
      </Box>}
      </Box>
      {/* </>
    </CommonLayout> */}
    </div>
  );
}

import {
  Box,
  Button,
  CardMedia,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import MFSelectField from '../../lib/formik/SelectField';
import { ProceedSaveLater, SubHeading } from './components';
import { MFTextField } from '../../lib/formik';
import {
  Formik,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
  FormikHelpers,
} from 'formik';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useState } from 'react';
import { Applicant, Bank } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  genderMasters,
  maritalMasters,
  occupationDetailsMasters,
  relatedRelations,
  salutationsMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  getApplicantName,
  getApplicantType,
  getFirstHolderName,
  saveForLater,
  getRelation,
  applyRoleBasedStatus,
  applicationComparison,
  sendApplication_Nri,
  removeSingleQuote,
  getOccupationType,
  preventSpecialCharacters,
  futureAge,
  isMinor,
  isOnboardingTypeMinor,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { FetchData, updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { ConfirmationDialog, CountryCodesDropDown } from '../commonComponents';
import { personalDetailsSchema } from '../../utils/schema';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Relations } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import { MinorTextDatePicker } from '../onboarding/DatePickerWithTextFeild';
import { showError } from '../../redux-store/actions/auth';
import application from '../../redux-store/reducers/application';
import { individualPanRegex } from '../../utils/regex';

const personalDetailsObject: Partial<Applicant> = {
  name: '',
  namePrefix: '',
  fatherOrSpouseName: '',
  maritalStatus: '',
  relationShipWithFirstApplicant: '',
  gender: '',
  occupationDetails: '',
  occupationType: '',
  status: 'Individual',
  minorPan: '',
  minorDOB: null,
  minorName: '',
  minorFatherName: '',
  minorGender: '',
  minorMobile: '',
  minorCountryCode: '+91',
  minorCountryNameAndCode: 'India: +91',
  minorEmail: '',
  minorNamePrefix: 'Mr.',
  minorFirstName: '',
  minorLastName: '',
  firstName: '',
  lastName: '',
  guardianRelationship: '',
};
type updateApplicant = Applicant & {
  jointApplicantRelation: string | null;
  occupationType: string | null;
  guardianRelationshipTypeOther: string | null;
};
export type Values = {
  modeOfHolding: string;
  applicants: Partial<updateApplicant>[];
  banks: Bank[];
  // poaHolderName: string;
  saveType: string;
  nationalityDropdown: string[];
};

const initialValues: Values = {
  modeOfHolding: 'single',
  applicants: [personalDetailsObject],
  // poaHolderName: '',
  saveType: 'save and proceed',
  banks: [],
  nationalityDropdown: [],
};

const PersonalDetails = ({
  index,
  values,
  applicationStatus,
  dataFetchedFromKRA,
  setValues,
  onboardingType,
}: {
  index: number;
  values: Values;
  applicationStatus?: string;
  dataFetchedFromKRA: boolean | null;
  setValues: FormikHelpers<Values>['setValues'];
  onboardingType: string;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  const [open, setOpen] = useState<{
    index: number;
    status: string;
  } | null>(null);

  const handleOpen = (index: number, status: string) => {
    setOpen({ index, status });
  };
  const handleClose = () => {
    setOpen(null);
  };
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.name`}
          label="Full Name *"
          placeholder="Enter Full Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                disabled={isFieldDisabled}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.fatherOrSpouseName`}
          label="Father/Spouse Name *"
          placeholder="Enter Father/Spouse Name"
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.firstName`}
          label="First Name *"
          placeholder="Enter First Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.lastName`}
          label="Last Name *"
          placeholder="Enter Last Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.panNo`}
          label="PAN No *"
          placeholder="Enter PAN No"
          disabled={isFieldDisabled || dataFetchedFromKRA || false}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.maritalStatus`}
          label="Marital Status *"
          items={maritalMasters
            .filter((status) => {
              if (values.applicants[index].namePrefix === 'Mr.' && status === 'Widower') return;
              return status;
            })
            .map((status) => ({ key: status, value: status }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.gender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.occupationType`}
          label="Occupation Type *"
          items={occupationDetailsMasters.map((occupation) => ({
            key: occupation,
            value: occupation,
          }))}
          onChange={(e) => {
            setValues({
              ...values,
              applicants: values.applicants.map((_applicant, _index) => {
                if (_index === index) {
                  return {
                    ..._applicant,
                    occupationType: e.target.value,
                    occupationDetails: '',
                  };
                }
                return _applicant;
              }) as Partial<updateApplicant>[],
            });
          }}
        />
      </Grid>
      {values.applicants.map((applicant, idx: number) => {
        if (applicant.occupationType === 'Others' && idx === index) {
          return (
            <Grid item xs={12} sm={6} key={idx}>
              <MFTextField
                name={`applicants.${index}.occupationDetails`}
                label="Please Specify Occupation Type *"
                placeholder={`Enter Occupation Type`}
                onChange={(e) => {
                  const occupationDetails = e.target.value.replace(/\s+/g, ' ');
                  setValues({
                    ...values,
                    applicants: values.applicants.map((_applicant, _index) => {
                      if (_index === index) {
                        return {
                          ..._applicant,
                          occupationDetails: occupationDetails,
                        };
                      }
                      return _applicant;
                    }) as Partial<updateApplicant>[],
                  });
                }}
              />
            </Grid>
          );
        }
      })}
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.status`}
          label="Status *"
          items={Object.keys(applicantStatusMasters).map((status) => ({
            key: applicantStatusMasters[status],
            value: status,
          }))}
          disabled={isFieldDisabled || isOnboardingTypeMinor(onboardingType)}
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            if (applicantStatusMasters[value as string] === applicantStatusMasters.NRI) {
              if (
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                APPLICATION_LISTING_STATUS.sent_to_applicant1
              ) {
                handleOpen(index, value as string);
              } else {
                setFieldValue(`applicants.${index}.status`, value);
                setFieldValue(`applicants.${index}.nationality`, 'INDIAN');
              }
            } else {
              if (
                !values.applicants
                  .map((applicant, ind) => {
                    if (ind === index) {
                      return value;
                    }
                    return applicant.status;
                  })
                  .includes('NRI') &&
                APPLICATION_LISTING_STATUS[applicationStatus || ''] ===
                APPLICATION_LISTING_STATUS.sent_to_amc_approver
              ) {
                return handleOpen(index, value as string);
              }

              setFieldValue(`applicants.${index}.status`, value);
            }
          }}
        />
      </Grid>
      {index !== 0 && (
        <Grid item xs={12} sm={6}>
          <MFSelectField
            name={`applicants.${index}.jointApplicantRelation`}
            label="Relationship With First Applicant *"
            items={Relations.filter((relation) => {
              const { applicants } = values;
              const selectedRelations = applicants
                .filter(
                  (_applicant, ind) =>
                    ind !== index &&
                    _applicant.jointApplicantRelation &&
                    ['Mother', 'Father', 'Spouse'].includes(_applicant.jointApplicantRelation)
                )
                .map((applicant) => applicant.jointApplicantRelation);
              return !selectedRelations.includes(relation);
            }).map((relation) => ({
              key: relation,
              value: relation,
            }))}
          />
          {values.applicants.map((applicant, idx: number) => {
            if (applicant.jointApplicantRelation === 'Others' && idx === index) {
              return (
                <MFTextField key={idx}
                  name={`applicants.${index}.relationShipWithFirstApplicant`}
                  label="Please Specify Relationship *"
                  placeholder={`Enter Relationship`}
                />
              );
            }
          })}
        </Grid>
      )}
      <ConfirmationDialog
        message={`Any change in the investor status will affect the application's status and workflow. Are you sure you want to proceed?`}
        open={open !== null}
        setOpen={handleClose}
        onSave={() => {
          setFieldValue(`applicants.${open?.index}.status`, open?.status);
          setFieldValue(`applicants.${open?.index}.nationality`, 'INDIAN');
          handleClose();
        }}
        onCancel={handleClose}
      />
      {/* <InfoDialog onClose={handleClose} open={open} content={'status changed to draft.'} /> */}
    </>
  );
};
const MinorPersonalDetails = ({
  index,
  values,
  fetchMinorData,
  minorDataFetched,
  setMinorDataFetched,
  setFieldValue,
  previousPan,
  previousDob,
  guardianPan,
}: {
  index: number;
  values: Values;
  fetchMinorData: any;
  minorDataFetched: string;
  setMinorDataFetched: any;
  setFieldValue: any;
  previousPan: string;
  previousDob: any;
  guardianPan: string | undefined;
}): JSX.Element => {
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <>
      <Grid item xs={12} sm={6}>
        <MFTextField
          label="PAN"
          name={`applicants.${index}.minorPan`}
          placeholder="Enter PAN"
          required
          autoComplete="off"
          inputProps={{ style: { textTransform: 'uppercase' } }}
          onChange={(e) => {
            setFieldValue(
              `applicants.${index}.minorPan`,
              removeSingleQuote(e.target.value.toUpperCase())
            );
            if (e.target.value.toUpperCase() !== previousPan?.toUpperCase()) {
              setMinorDataFetched('changed');
            } else {
              setMinorDataFetched('fetched');
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MinorTextDatePicker
          label="Date of Birth *"
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'DD/MM/YYYY'}
          name={`applicants.${index}.minorDOB`}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 18))}
          previousDob={previousDob}
          setMinorDataFetched={setMinorDataFetched}
        />
      </Grid>
      {values.applicants.map((applicant, idx: number) => {
        if (applicant?.minorPan?.length === 10 && idx === index && applicant?.minorDOB) {
          return (
            <Grid item xs={12} key={idx}>
              <Box sx={{ maxWidth: 250, mt: -1 }}>
                <LoadingButton
                  loadingPosition="end"
                  loading={minorDataFetched === 'fetching' ? true : false}
                  disabled={
                    applicant?.minorPan?.length === 10 &&
                      applicant?.minorDOB &&
                      minorDataFetched === 'changed'
                      ? false
                      : true
                  }
                  variant="contained"
                  sx={{ fontWeight: 500, fontSize: 14, width: '100%' }}
                  onClick={() =>
                    fetchMinorData(applicant.minorPan, applicant.minorDOB, values, guardianPan)
                  }>
                  Fetch Data
                </LoadingButton>
              </Box>
            </Grid>
          );
        }
      })}
      {/* <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorName`}
          label="Full Name *"
          placeholder="Enter Full Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
        <MFSelectField
          name={`applicants.${index}.minorNamePrefix`}
          items={salutationsMasters.map((salutation) => ({
            key: salutation,
            value: salutation,
          }))}
          sx={{
            color: 'primary.main',
            '.MuiSvgIcon-root ': { color: 'primary.main' },
            '&.MuiInputBase-root': {
              '.MuiSelect-select': {
                p: 0,
                pr: 2.5,
                border: 0,
              },
            },
            '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
          }}
        />
        </InputAdornment>
          }
        />
      </Grid> */}
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorFirstName`}
          label="First Name *"
          placeholder="Enter First Name"
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.minorNamePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': { '.MuiOutlinedInput-notchedOutline': { border: 0 } },
                }}
              />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorLastName`}
          label="Last Name *"
          placeholder="Enter Last Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorFatherName`}
          label="Father Name *"
          placeholder="Enter Father Name"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.minorGender`}
          label="Gender *"
          items={genderMasters.map((gender) => ({ key: gender, value: gender }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.minorEmail`}
          label="Email"
          placeholder="Enter Email"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          label="Mobile Number"
          name={`applicants.${index}.minorMobile`}
          placeholder="Enter Mobile number"
          onKeyDown={(e) => {
            preventSpecialCharacters(e);
          }}
          startAdornment={
            <CountryCodesDropDown
              ElementWidth={mobileElementWidth && mobileElementWidth}
              name={`applicants.${index}.minorCountryNameAndCode`}
              value={values?.applicants[index]?.minorCountryNameAndCode || 'India: +91'}
            />
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.guardianRelationship`}
          label="Relationship with Guardian *"
          items={relatedRelations.map((relation) => ({
            key: relation,
            value: relation,
          }))}
        />
      </Grid>
      {values?.applicants[index]?.guardianRelationship === 'OTHERS' && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.guardianRelationshipTypeOther`}
            label={`Please Specify Relationship *`}
            placeholder={`Enter Please Specify Relationship`}
            inputProps={{ style: { textTransform: 'uppercase' } }}
          />
        </Grid>
      )}
    </>
  );
};

export default function ContributorDetails(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [personalDetailsValues, setPersonalDetailsValues] = useState(initialValues);
  const [minorDataFetched, setMinorDataFetched] = useState('');
  const [previousPan, setPreviousPan] = useState('');
  const [previousDob, setPreviousDob] = useState<any>(null);
  const [applicantToDelete, setApplicantToDelete] = useState<{
    index: number;
  } | null>(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClose = () => setOpen(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [errorOnFetch, setErrorOnFetch] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [], modeOfHolding = '', banks = [] } = application || {};
        const sortedApplicants = applicants
          .map((applicant) => ({
            ...applicant,
            applicant_type: getApplicantType(applicant.applicant_type),
          }))
          .sort((applicant1, applicant2) => {
            return Number(applicant1.applicant_type) - Number(applicant2.applicant_type);
          });
        setPreviousPan(applicants[0].minorPan || '');
        setPreviousDob(applicants[0].minorDOB || null);
        setPersonalDetailsValues({
          ...personalDetailsValues,
          banks,
          modeOfHolding:
            modeOfHolding === 'joint'
              ? 'joint'
              : sortedApplicants.length > 1
                ? 'either or survivor'
                : modeOfHolding,
          applicants: applicants.length
            ? sortedApplicants.map((applicant) => ({
              ...applicant,
              namePrefix:
                applicant.namePrefix && salutationsMasters.includes(applicant.namePrefix || '')
                  ? applicant.namePrefix
                  : 'Mr.',
              jointApplicantRelation: getRelation(applicant.relationShipWithFirstApplicant)
                ? applicant.relationShipWithFirstApplicant
                : 'Others',
              relationShipWithFirstApplicant: getRelation(
                applicant.relationShipWithFirstApplicant
              )
                ? ''
                : applicant.relationShipWithFirstApplicant,
              occupationType: getOccupationType(applicant.occupationDetails)
                ? applicant.occupationDetails
                : 'Others',
              occupationDetails:
                applicant.occupationDetails === 'Others'
                  ? ''
                  : getOccupationType(applicant.occupationDetails)
                    ? ''
                    : applicant.occupationDetails,
              status: applicant.status || 'Individual',
              maritalStatus: applicant.maritalStatus || '',
              minorPan: applicant.minorPan || '',
              minorDOB: applicant.minorDOB || null,
              minorName: applicant.minorName || '',
              minorFirstName: applicant.minorFirstName || '',
              minorLastName: applicant.minorLastName || '',
              guardianRelationship:
                (relatedRelations.includes(applicant.guardianRelationship as string)
                  ? applicant.guardianRelationship
                  : applicant.guardianRelationship
                    ? 'OTHERS'
                    : applicant.guardianRelationship) || '',
              guardianRelationshipTypeOther:
                (relatedRelations.includes(applicant.guardianRelationship as string)
                  ? ''
                  : applicant.guardianRelationship) || '',
              minorFatherName: applicant.minorFatherName || '',
              minorGender: applicant.minorGender || '',
              minorMobile: applicant.minorMobile || '',
              minorCountryNameAndCode: applicant.minorCountryNameAndCode || 'India: +91',
              minorEmail: applicant.minorEmail || '',
              minorNamePrefix: applicant.minorNamePrefix || 'Mr.',
              minorCountryCode: applicant.minorCountryCode || '+91',
            }))
            : [personalDetailsObject],
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  // const validate = (values: Values): FormikErrors<Values> => {
  //   let errors: FormikErrors<Values> = {};
  //   const { applicants, poaHolderName } = values;
  //   if (applicants.length > 1 && !poaHolderName) {
  //     errors = { ...errors, poaHolderName: 'Please select POA' };
  //   }
  //   return errors;
  // };

  const handleApplicant = async (values: Values) => {
    const { applicants = [], modeOfHolding = '', saveType, banks = [] } = values;
    const {
      id,
      currentStep,
      hasPOA,
      status,
      applicant1ReferenceId = '',
      applicationNumber,
    } = application || {};
    // const isAllBanksPennyChecked = banks.length
    //   ? banks.every((bank) => bank.pennydropCheck)
    //   : false;
    const updatedBanks =
      applicants[0].name?.split("'").join('').trim() ===
        application?.applicants[0].name?.split("'").join('').trim()
        ? banks
        : banks.map((bank) => ({
          ...bank,
          pennydropCheck: false,
          pennydropVerified: false,
        }));
    const updatedApplicants = values.applicants.map((applicant) => {
      const {
        jointApplicantRelation,
        occupationType,
        guardianRelationship,
        guardianRelationshipTypeOther,
        ...rest
      } = applicant;
      return {
        ...rest,
        // status,
        // addresses: addresses.length
        //   ? addresses.map((address) => {
        //       if (
        //         address.address_type === 'overseas' &&
        //         nationality.toLowerCase() === 'indian' &&
        //         applicantStatusMasters[status as string] === applicantStatusMasters.Individual
        //       ) {
        //         return { ...address, isActive: false };
        //       }
        //       return address;
        //     })
        //   : [],
        name: removeSingleQuote(applicant.name),
        fatherOrSpouseName: removeSingleQuote(applicant.fatherOrSpouseName),
        motherName: removeSingleQuote(applicant.motherName),
        relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
          ? jointApplicantRelation
          : removeSingleQuote(applicant.relationShipWithFirstApplicant),
        occupationDetails: getOccupationType(applicant.occupationDetails)
          ? occupationType
          : removeSingleQuote(applicant.occupationDetails),
        minorPan: removeSingleQuote(applicant.minorPan),
        minorName: removeSingleQuote(applicant.minorName),
        minorFatherName: removeSingleQuote(applicant.minorFatherName),
        guardianRelationship:
          applicant.guardianRelationship === 'OTHERS'
            ? applicant.guardianRelationshipTypeOther
            : applicant.guardianRelationship,
      };
    });
    const updatedModeOfHolding =
      applicants.filter((applicant) => applicant.isActive).length === 1 ? 'single' : modeOfHolding;
    const checkApplication = applicationComparison(
      {
        ...application,
        applicants: application?.applicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
      },
      {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        banks: updatedBanks,
        applicants: updatedApplicants?.sort(
          (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
        ),
        modeOfHolding: updatedModeOfHolding,
        currentStep: !!currentStep && currentStep > 1 ? currentStep : Number(currentStep) + 1,
      }
    );
    if (id && !checkApplication) {
      await dispatch(
        updateApplication({
          body: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ...application!,
            banks: updatedBanks,
            applicants: updatedApplicants,
            modeOfHolding: updatedModeOfHolding,
            ...(saveType === 'save and proceed' && {
              status:
                !hasPOA &&
                  AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                  status !== 'draft' &&
                  applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : (sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                    APPLICATION_LISTING_STATUS[status as string] ===
                    APPLICATION_LISTING_STATUS.sent_to_applicant1) ||
                    (!sendApplication_Nri({ ...application!, applicants: updatedApplicants }) &&
                      APPLICATION_LISTING_STATUS[status as string] ===
                      APPLICATION_LISTING_STATUS.sent_to_amc_approver)
                    ? 'draft'
                    : status,
            }),
            currentStep: 2,
            // !!currentStep && currentStep > 1
            //   ? applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //     ? 2
            //     : currentStep
            //   : applicants[0].name?.trim() !== application?.applicants[0].name?.trim() &&
            //     isAllBanksPennyChecked
            //   ? 2
            //   : Number(currentStep) + 1,
          },
          applicationId: id,
          ...(saveType !== 'save and proceed' && {
            toastMessage: '',
          }),
        })
      );
      saveType === 'save and proceed'
        ? history.push('related-person-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } else if (checkApplication) {
      if (saveType !== 'save and proceed') {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      saveType === 'save and proceed'
        ? history.push('related-person-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      await handleApplicant(values);
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  async function fetchMinorData(panNumber: string, dob: any, values: any, guardianPan: string) {
    try {
      if (panNumber && !individualPanRegex.test(panNumber)) {
        throw 'Only individual PANs are allowed';
      } else if (panNumber === guardianPan) {
        throw 'Minor PAN should not be same as Guardian PAN';
      } else if (dob && dob.toString() === 'Invalid Date') {
        throw 'Invalid Date';
      } else if (dob && futureAge(dob || '')) {
        throw 'Future date cannot be entered';
      } else if (dob && !isMinor(dob)) {
        throw 'Age should be less than 18';
      } else {
        setMinorDataFetched('fetching');
        const response = (await dispatch(FetchData({ panNumber, dob }))) as any;
        if (response.amlCheck) {
          if (response.data && Object.keys(response.data).length > 0) {
            setMinorDataFetched('fetched');
            setPreviousPan(panNumber);
            setPreviousDob(dob);
            setPersonalDetailsValues({
              ...personalDetailsValues,
              applicants: personalDetailsValues.applicants.map((applicant: any, index: number) => ({
                ...applicant,
                name: values.applicants[index].name,
                firstName: values.applicants[index].firstName,
                lastName: values.applicants[index].lastName,
                namePrefix: values.applicants[index].namePrefix,
                fatherOrSpouseName: values.applicants[index].fatherOrSpouseName,
                maritalStatus: values.applicants[index].maritalStatus,
                relationShipWithFirstApplicant:
                  values.applicants[index].relationShipWithFirstApplicant,
                gender: values.applicants[index].gender,
                occupationDetails: values.applicants[index].occupationDetails,
                occupationType: values.applicants[index].occupationType,
                status: values.applicants[index].status,
                minorPan: panNumber || '',
                minorDOB: dob || null,
                minorName: response.data.name || '',
                guardianRelationship: values.applicants[index].guardianRelationship || '',
                guardianRelationshipTypeOther:
                  values.applicants[index].guardianRelationshipTypeOther || '',
                minorFirstName: response.data.minorFirstName || '',
                minorLastName: response.data.minorLastName || '',
                minorFatherName: response.data.fatherName || '',
                minorGender: response.data.gender || '',
                minorMobile: response.data.mobile || '',
                minorCountryNameAndCode: response.data.minorCountryNameAndCode || 'India: +91',
                minorCountryCode: response.data.minorCountryCode || '+91',
                minorEmail: response.data.email || '',
                minorNamePrefix: response.data.minorNamePrefix || 'Mr.',
              })),
            });
            enqueueSnackbar('AML Verification Successful, Data Fetched', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          } else {
            setMinorDataFetched('fetched');
            setPreviousPan(panNumber);
            setPreviousDob(dob);
            setPersonalDetailsValues({
              ...personalDetailsValues,
              applicants: personalDetailsValues.applicants.map((applicant: any, index: number) => ({
                ...applicant,
                name: values.applicants[index].name,
                namePrefix: values.applicants[index].namePrefix,
                fatherOrSpouseName: values.applicants[index].fatherOrSpouseName,
                maritalStatus: values.applicants[index].maritalStatus,
                relationShipWithFirstApplicant:
                  values.applicants[index].relationShipWithFirstApplicant,
                gender: values.applicants[index].gender,
                occupationDetails: values.applicants[index].occupationDetails,
                occupationType: values.applicants[index].occupationType,
                status: values.applicants[index].status,
                minorPan: panNumber || '',
                minorDOB: dob || null,
                minorName: '',
                minorFatherName: '',
                minorGender: '',
                minorMobile: '',
                minorCountryNameAndCode: 'India: +91',
                minorCountryCode: '+91',
                minorEmail: '',
                minorNamePrefix: 'Mr.',
                minorFirstName: '',
                minorLastName: '',
                firstName: values.applicants[index].firstName || '',
                lastName: values.applicants[index].lastName || '',
                guardianRelationship: values.applicants[index].guardianRelationship || '',
                guardianRelationshipTypeOther:
                  values.applicants[index].guardianRelationshipTypeOther || '',
              })),
            });
            enqueueSnackbar('AML Verification Successful, Data Not Fetched', {
              variant: 'success',
              autoHideDuration: 3000,
            });
          }
        } else {
          setMinorDataFetched('failed');
          setPreviousPan(panNumber);
          setPreviousDob(dob);
          setPersonalDetailsValues({
            ...personalDetailsValues,
            applicants: personalDetailsValues.applicants.map((applicant: any, index: number) => ({
              ...applicant,
              name: values.applicants[index].name || '',
              namePrefix: values.applicants[index].namePrefix || '',
              fatherOrSpouseName: values.applicants[index].fatherOrSpouseName || '',
              maritalStatus: values.applicants[index].maritalStatus || '',
              relationShipWithFirstApplicant:
                values.applicants[index].relationShipWithFirstApplicant || '',
              gender: values.applicants[index].gender || '',
              occupationDetails: values.applicants[index].occupationDetails || '',
              occupationType: values.applicants[index].occupationType || '',
              status: values.applicants[index].status || '',
              minorPan: panNumber || '',
              minorDOB: dob || null,
              minorName: '',
              minorFirstName: '',
              minorLastName: '',
              firstName: values.applicants[index].firstName || '',
              lastName: values.applicants[index].lastName || '',
              guardianRelationship: '',
              guardianRelationshipTypeOther: '',
              minorFatherName: '',
              minorGender: '',
              minorMobile: '',
              minorCountryNameAndCode: 'India: +91',
              minorCountryCode: '+91',
              minorEmail: '',
              minorNamePrefix: 'Mr.',
            })),
          });
          enqueueSnackbar('AML Verification Failed, Data Not Fetched', {
            variant: 'error',
            autoHideDuration: 3000,
          });
        }
      }
    } catch (e) {
      setMinorDataFetched('failed');
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  }

  return (
    <Formik
      initialValues={personalDetailsValues}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, personalDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setErrors,
        errors,
        setTouched,
        setFieldValue,
      }) => (
        <>
          <Grid
            container
            rowSpacing={1}
            // columnSpacing={5}
            sx={{
              width: '100%',
              ml: 0,
              '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
            }}
            component="form"
            noValidate
            onSubmit={handleSubmit}>
            {values.applicants.map((applicant, index) => (
              <React.Fragment key={index}>
                <SubHeading
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  Personal Details of{' '}
                  {isOnboardingTypeMinor(application?.onboardingType || '')
                    ? 'Guardian'
                    : `${getApplicantName(index + 1)} Applicant`}
                  {index !== 0 && (
                    <Box
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => setApplicantToDelete({ index })}>
                      <Typography
                        sx={{
                          color: 'rgba(196, 42, 51, 0.8)',
                          fontSize: 12,
                          ml: 'auto',
                          letterSpacing: '-0.2px',
                          cursor: 'pointer',
                        }}>
                        Remove Joint Holder{' '}
                      </Typography>
                      <IconButton sx={{ p: '2px' }}>
                        <CardMedia
                          component="img"
                          src="/images/delete-red.svg"
                          alt="Delete Icon"
                          sx={{ width: 'unset' }}
                        />
                      </IconButton>
                    </Box>
                  )}
                </SubHeading>
                <PersonalDetails
                  index={index}
                  values={values}
                  applicationStatus={application?.status}
                  dataFetchedFromKRA={applicant.dataFetchedFromKRA || null}
                  setValues={setValues}
                  onboardingType={application?.onboardingType || ''}
                />
                {isOnboardingTypeMinor(application?.onboardingType || '') && (
                  <>
                    <SubHeading
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      Personal Details of Minor Applicant
                    </SubHeading>
                    <MinorPersonalDetails
                      index={index}
                      values={values}
                      fetchMinorData={fetchMinorData}
                      minorDataFetched={minorDataFetched}
                      setMinorDataFetched={setMinorDataFetched}
                      setFieldValue={setFieldValue}
                      previousPan={previousPan}
                      previousDob={previousDob}
                      guardianPan={applicant.panNo}
                    />
                  </>
                )}
              </React.Fragment>
            ))}
            {!!values.applicants.length &&
              values.applicants.length < 3 &&
              ![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role) &&
              !isOnboardingTypeMinor(application?.onboardingType || '') && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    startIcon={<AddIcon />}
                    sx={{
                      color: 'primary.main',
                      fontWeight: 500,
                      fontSize: 14,
                      mt: 2,
                    }}
                    onClick={async () => {
                      const formErrors = await validateForm();
                      if (!Object.keys(formErrors).length) {
                        await handleApplicant(values);
                        history.push('/onboarding/enter-pan-number', {
                          redirectTo:
                            USER_ROLES.AMCAPPROVER === role
                              ? '/investment-details/edit-application/personal-details'
                              : '/application/personal-details',
                          application,
                          firstHolderName: getFirstHolderName(values.applicants),
                        });
                        return;
                      }
                      setTouched(setNestedObjectValues(formErrors, true));
                      setErrors(formErrors);
                    }}>
                    Add Joint Holder
                  </Button>
                </Grid>
              )}
            {values.applicants.length > 1 && (
              <>
                <SubHeading>Redeem </SubHeading>
                <Grid item xs={12}>
                  <MFCheckbox
                    checked={values.modeOfHolding === 'either or survivor'}
                    name="holding"
                    label="Either or Survivor"
                    onChange={({ target: { checked } }) => {
                      setFieldValue('modeOfHolding', checked ? 'either or survivor' : 'joint');
                      setOpen(checked ? false : true);
                    }}
                    disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
                    sx={{
                      color: values?.modeOfHolding === 'either or survivor' ? '#2057A6' : '#293139',
                    }}
                  />
                </Grid>
              </>
            )}
            <ProceedSaveLater
              saveLater={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save for later',
                });
              }}
              saveAndProceed={() => {
                setValues({
                  ...values,
                  applicants: values.applicants.map((applicant) => ({
                    ...applicant,
                    relationShipWithFirstApplicant:
                      applicant.jointApplicantRelation === 'Others'
                        ? applicant.relationShipWithFirstApplicant
                        : applicant.jointApplicantRelation,
                  })),
                  saveType: 'save and proceed',
                });
              }}
              loader={loading}
              clickedButton={values.saveType}
            />
          </Grid>
          <Dialog
            onClose={handleClose}
            open={open}
            sx={{
              '.MuiPaper-root ': {
                p: 4,
                width: { xs: '70%', sm: '70%' },
              },
            }}>
            <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <Box sx={{ mb: 2 }}>
              <CardMedia
                component="img"
                src="/images/warning-icon-red.svg"
                sx={{ width: 'unset', mx: 'auto' }}
              />
            </Box>
            <Box
              sx={{
                width: { xs: '100%', sm: '80%' },
                mx: 'auto',
                '.MuiTypography-root': {
                  fontSize: 20,
                  fontWeight: 500,
                  textAlign: 'center',
                },
              }}>
              <Typography sx={{ color: '#FD3A69', lineHeight: '35px' }}>
                Disabling “Either or Survivor” will cause issues later.
              </Typography>
              <Typography sx={{ color: 'primary.main', lineHeight: '35px' }}>
                Are you willing to disable?
              </Typography>
            </Box>
            <Grid container sx={{ my: 3, justifyContent: 'center', gap: 1.5 }}>
              <Button
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'joint',
                  });
                  handleClose();
                }}
                variant="outlined"
                sx={{
                  fontSize: 14,
                  color: 'primary.main',
                  minWidth: 200,
                }}>
                Yes
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setValues({
                    ...values,
                    modeOfHolding: 'either or survivor',
                  });
                  handleClose();
                }}
                sx={{
                  fontSize: 14,
                  minWidth: 200,
                }}>
                No
              </Button>
            </Grid>
          </Dialog>
          <ConfirmationDialog
            message={`Are you sure you want to delete applicant ?`}
            open={applicantToDelete !== null}
            setOpen={() => setApplicantToDelete(null)}
            onSave={async () => {
              const remainedApplicants = values.applicants.map((applicant, i) => {
                const noOfApplicants = values.applicants.length;
                if (i === applicantToDelete?.index) {
                  return {
                    ...applicant,
                    isActive: false,
                  };
                }
                if (noOfApplicants === 3 && i === 2) {
                  return {
                    ...applicant,
                    applicant_type: '2',
                  };
                }
                return applicant;
              });
              const defaultPayload = {
                applicants: remainedApplicants.map((applicant) => {
                  const { jointApplicantRelation, occupationDetails, occupationType, ...rest } =
                    applicant;
                  // console.log(applicant);
                  return {
                    ...rest,
                    occupationDetails:
                      occupationType !== 'Others' && occupationType !== null
                        ? occupationType
                        : occupationDetails
                          ? occupationDetails
                          : '',
                    relationShipWithFirstApplicant: getRelation(jointApplicantRelation)
                      ? jointApplicantRelation
                      : applicant.relationShipWithFirstApplicant,
                  };
                }),
                modeOfHolding:
                  remainedApplicants.filter((applicant) => applicant.isActive).length === 1
                    ? 'single'
                    : values.modeOfHolding,
              };
              if (application?.id) {
                setApplicantToDelete(null);
                await dispatch(
                  updateApplication({
                    body: {
                      ...application,
                      ...defaultPayload,
                      status:
                        !application?.hasPOA &&
                          AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                          application?.status !== 'draft' &&
                          applyRoleBasedStatus(role)
                          ? 'sent_to_amc_approver'
                          : application?.status,
                    },
                    applicationId: application.id,
                  })
                );
              }
            }}
            onCancel={() => setApplicantToDelete(null)}
          />
        </>
      )}
    </Formik>
  );
}

import { Box, LinearProgress, Typography } from '@mui/material';
import { CommonLayout } from '../commonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { NetSalesEntity, RmDashboardType } from '../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../DataTable';
import { getRMDashBoardMetrics } from '../../redux-store/actions/userManagement';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';

const headers = (): ColumnType[] => {
  return [
    {
      header: 'Asset Class',
      valueGetter: (row: NetSalesEntity) => row?.Product || 'N/A',
    },
    {
      header: 'No. of Investors',
      valueGetter: (row: NetSalesEntity) => row?.InvCnt || 'N/A',
    },
    {
      header: 'No. of RMs',
      valueGetter: (row: NetSalesEntity) => row?.RMCnt || 'N/A',
    },
    {
      header: 'Gross Sales',
      valueGetter: (row: NetSalesEntity) =>
        (row?.GrossSaleAmt && `${formatToIndianCurrency(row?.GrossSaleAmt)}`) || 'N/A',
    },
  ];
};

export default function GrossSales(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);
  const [search, setSearch] = useState<string>();
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setDashBoardMetricsData(null);
    (async function () {
      try {
        setLoading(true);
        const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
        if (!isComponentActive) {
          return;
        }
        setDashBoardMetricsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <CommonLayout>
      <>
        <Box>
          <Box
            sx={{
              display: 'block',
              pb: 1,
            }}>
            <Typography
              sx={{
                fontSize: 16,
                color: '#2057A6',
                fontWeight: 600,
              }}>
              Gross Sales
            </Typography>
          </Box>
          {isLoading ? (
            <LinearProgress sx={{ mt: 2.5 }} />
          ) : (
            <Box mt={2.5} sx={{ maxWidth: { lg: '100%' } }}>
              {dashBoardMetricsData && (
                <>
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={[...headers()]}
                    tableData={dashBoardMetricsData?.netSales && dashBoardMetricsData?.netSales}
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                      background: 'transparent',
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: '500px', minWidth: 450 }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </>
    </CommonLayout>
  );
}

import { VisibilityOutlined, East, Close } from '@mui/icons-material';
import { IconButton, Popover, Typography, Divider, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { DirectEquitySchemeDetail, PmsStrategyDetail } from '../../../redux-store/types/api-types';
import { formatLongDate } from '../../../utils/date';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';

export interface GainLossPopoverRowType {
  PurchaseDate?: string | Date | null;
  GainLoss?: string | number;
  GainLossPercentage?: string | number | null;
  PurchasePrice?: string | number;
  CurrentPrice?: string | number;
  TotalReturns?: string | number;
  DayReturns?: string | number;
  STCG?: string | number | null;
  LTCG?: string | number | null;
  Inflow?: string | number | null;
  Outflow?: string | number | null;
}

export default function GainLossPopoverButtons({
  row,
}: {
  row: GainLossPopoverRowType;
  type?: 'equity' | 'pms';
}): JSX.Element {
  const [detailPopoverAnchorEl, setDetailPopoverAnchorEl] = useState<HTMLElement | null>(null);
  const detailPopoverOpen = Boolean(detailPopoverAnchorEl);
  return (
    <Box sx={{}}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}>
        <IconButton
          onClick={(e) => setDetailPopoverAnchorEl(e.currentTarget)}
          sx={{ backgroundColor: '#f4fcfc', '&:hover': { backgroundColor: '#f4fcfc' }, p: 1 }}>
          <VisibilityOutlined fontSize="small" />
        </IconButton>
        <IconButton sx={{ backgroundColor: '#f4fcfc', '&:hover': { backgroundColor: '#f4fcfc' } }}>
          <East fontSize="small" />
        </IconButton>
      </Box>
      <Popover
        open={detailPopoverOpen}
        anchorEl={detailPopoverAnchorEl}
        onClose={() => setDetailPopoverAnchorEl(null)}
        // anchorPosition={{ top: 100, left: 100 }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{ sx: { maxWidth: '500px', minWidth: '300px' } }}>
        <Box>
          <Box
            sx={{
              px: 3,
              py: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}>
            <Typography sx={{ color: '#22242C', fontWeight: '700', fontSize: '18px' }}>
              Overall Gain/Loss
            </Typography>
            <IconButton
              onClick={() => setDetailPopoverAnchorEl(null)}
              sx={{ borderRadius: '5px', backgroundColor: '#D9D9D9' }}>
              <Close fontSize={'small'} />
            </IconButton>
          </Box>
          <Divider />
          <Box sx={{ px: 3, py: 2 }}>
            <Grid container gap={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}>
                  <Typography
                    variant="caption"
                    sx={{ p: 1, pt: 0, pl: 0, fontSize: '16px', opacity: 0.6 }}>
                    Purchased Date: {formatLongDate(row?.PurchaseDate)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      p: 2,
                      gap: 1,
                      border: '1px solid #1473CC',
                      boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                      borderRadius: '3px',
                    }}>
                    <Typography variant="caption" sx={{ fontSize: '14px', opacity: 0.6 }}>
                      Unrealised Gain/Loss
                    </Typography>
                    <Typography sx={{ fontSize: '26px', fontWeight: 500 }}>
                      {row?.GainLoss ? formatToIndianCurrency(row?.GainLoss) : '0'}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {!!(row.Inflow || row.Outflow) && (
                <Grid item xs={12} container justifyContent={'space-between'}>
                  {!!row.Inflow && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          Inflow
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.Inflow ? formatToIndianCurrency(row?.Inflow) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {!!row.Outflow && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          Outflow
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.Outflow ? formatToIndianCurrency(row?.Outflow) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {!!(row.PurchasePrice || row.CurrentPrice) && (
                <Grid item xs={12} container justifyContent={'space-between'}>
                  {!!row.PurchasePrice && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          Purchase Price
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.PurchasePrice ? formatToIndianCurrency(row?.PurchasePrice) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {!!row.CurrentPrice && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          Current Price
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.CurrentPrice ? formatToIndianCurrency(row?.CurrentPrice) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {!!(row.TotalReturns || row.DayReturns) && (
                <Grid item xs={12} container justifyContent={'space-between'}>
                  {!!row.TotalReturns && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          Total Returns
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.TotalReturns ? `${row?.TotalReturns}%` : '0%'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {!!row.DayReturns && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          1D Returns
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.DayReturns ? `${row?.DayReturns}%` : '0%'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
              {!!(row?.STCG || row?.LTCG) && (
                <Grid item xs={12} container justifyContent={'space-between'}>
                  {row?.LTCG && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          LTCG
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.LTCG ? formatToIndianCurrency(row?.LTCG) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                  {row?.STCG && (
                    <Grid item xs={5}>
                      <Box>
                        <Typography variant="caption" sx={{ fontSize: '13px', opacity: '0.6' }}>
                          STCG
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: '16px',
                            color: 'black',
                            opacity: '0.8',
                            fontWeight: 500,
                          }}>
                          {row?.STCG ? formatToIndianCurrency(row?.STCG) : 'N/A'}
                        </Typography>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
}

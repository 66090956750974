import { Location } from 'history';
import {
  Box,
  Breadcrumbs,
  Grid,
  Typography, Button, Stack, Tab, Divider, Skeleton, LinearProgress, MenuItem, Menu
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import { useEffect, useState } from 'react';
import { TransactionAssetClassTab } from './transaction-tabs';
import Scheme_List from './scheme-list';
import SchemeDetail from './scheme-detail';
import { AmountUnit, CommonLayout } from '../../commonComponents';
import { GetCategoryAverage, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, GetSubAssetClassesType, SchemeList, SubAssetClasses, SubAssetclassName } from '../../../redux-store/types/api-types';
import { GetAssetClassesType } from '../../../redux-store/types/api-types';
import { get_assest_classes, get_category_average, get_scheme_details, get_scheme_list, get_scheme_nav, get_sub_asset_classes } from '../../../redux-store/actions/userManagement';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

export default function InvestorTransactions({
  location,
}: {
  location: Location<{ investorId: string; investorName: string }>;
}): JSX.Element {

  const [isLoadingschemeDetail, setLoadingSchemeDetail] = useState(false);
  const [isLoadingschemeList, setLoadingSchemeList] = useState(false);
  const [isLoadingSubAsset, setLoadingSubAsset] = useState(false);
  const [isLoadingAsset, setLoadingAsset] = useState(false);
  const [isLoadingCatAvg, setLoadingCatAvg] = useState(false);
  const [isLoadingSchemeNav, setLoadingSchemeNav] = useState(false);
  const [value, setValue] = useState<string>("Debt");
  const [itemSelected, setItemSelected] = useState<SubAssetClasses>();
  const [viewSubList, setViewSubList] = useState(false);
  const [viewSchemeDetails, setViewSchemeDetails] = useState<boolean>(false);
  const [currentSchemeDetail, setCurrentSchemeDetail] = useState<SchemeList>();
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const history = useHistory();
  const currentPathname = location.pathname;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedValue, setSelectedValue] = useState(currentPathname === '/investor-transactions' ? 'Explore' : 'Recommendation');
console.log("investorid",investorId)

  const handleChange = (event: any, value: any) => {
    setValue(value);
  };
  const dispatch = useDispatch();
  const [assetClassesData, setAssetClassesData] = useState<GetAssetClassesType | null>(null);
  //api call for get_asset_classes
  useEffect(() => {

    let isComponentActive = true;
    setAssetClassesData(null);
    (async function () {
      try {
        setLoadingAsset(true);
        const response = (await dispatch(
          get_assest_classes()
        )) as unknown as GetAssetClassesType;
        if (!isComponentActive) {
          return;
        }
        const sortedAssetClasses = [...response.asset_classes].sort((a, b) =>
          a.AssetClassName.localeCompare(b.AssetClassName)
        );
        setAssetClassesData({ asset_classes: sortedAssetClasses });
        setLoadingAsset(false);
      } catch (e) {
        setLoadingAsset(false);
        console.log((e as Error).message);
      }
    })();

    return () => {
      isComponentActive = false;
    };

  }, [dispatch]);

  const [subAssetClassesData, setSubAssetClassesData] = useState<GetSubAssetClassesType | null>(null);
  //api call for get_sub_asset_classes
  useEffect(() => {
    let isComponentActive = true;
    setSubAssetClassesData(null);
    (async function () {
      try {
        setLoadingSubAsset(true);
        const response = (await dispatch(
          get_sub_asset_classes(value)
        )) as unknown as GetSubAssetClassesType;
        if (!isComponentActive) {
          return;
        }
        setSubAssetClassesData(response);
        setLoadingSubAsset(false);
      } catch (e) {
        setLoadingSubAsset(false)
        console.log((e as Error).message);
      }
    })();

    return () => {
      isComponentActive = false;
    };

  }, [dispatch, value]);

  const [schemeList, setSchemeList] = useState<GetSchemeListType | null>(null);
  //api call for get_scheme_lists
  useEffect(() => {
    if (itemSelected) {
      const asset_id = itemSelected.ID;
      let isComponentActive = true;
      setSchemeList(null);
      (async function () {
        try {
          setLoadingSchemeList(true);
          const response = (await dispatch(
            get_scheme_list(asset_id)
          )) as unknown as GetSchemeListType;
          if (!isComponentActive) {
            return;
          }
          setSchemeList(response);
          setLoadingSchemeList(false);
        } catch (e) {
          setLoadingSchemeList(false);
          console.log((e as Error).message);
        }
      })();

      return () => {
        isComponentActive = false;
      };
    }

  }, [dispatch, itemSelected]);

  const [schemeDetail, setSchemeDetail] = useState<GetSchemeDetailsType | null>(null);
  //api call for get_scheme_details
  useEffect(() => {
    if (currentSchemeDetail) {
      const scheme_id = currentSchemeDetail.SchemeID;
      let isComponentActive = true;
      setSchemeDetail(null);
      (async function () {
        try {
          setLoadingSchemeDetail(true);
          const response = (await dispatch(
            get_scheme_details(scheme_id)
          )) as unknown as GetSchemeDetailsType;
          if (!isComponentActive) {
            return;
          }

          console.log("response: ", Response);
          setSchemeDetail(response);
          setLoadingSchemeDetail(false);
        } catch (e) {
          setLoadingSchemeDetail(false);
          console.log((e as Error).message);
        }
      })();

      return () => {
        isComponentActive = false;
      };
    }

  }, [dispatch, currentSchemeDetail]);

  const [categoryAverage, setCategoryAverage] = useState<GetCategoryAverage | null>(null);
  //api call for get_category_avg
  useEffect(() => {
    if (currentSchemeDetail) {
      const payload = {
        marketValue: false,
        schemaId: currentSchemeDetail.SchemeID
      }
      let isComponentActive = true;
      setCategoryAverage(null);
      (async function () {
        try {
          setLoadingCatAvg(true);
          const response = (await dispatch(
            get_category_average(payload)
          )) as unknown as GetCategoryAverage;
          if (!isComponentActive) {
            return;
          }
          setCategoryAverage(response);
          setLoadingCatAvg(false);
        } catch (e) {
          setLoadingCatAvg(false);
          console.log((e as Error).message);
        }
      })();

      return () => {
        isComponentActive = false;
      };
    }

  }, [dispatch, currentSchemeDetail]);

  const [schemeNav, setSchemeNav] = useState<GetSchemeNav | null>(null);
  //api call for get_scheme_details
  useEffect(() => {
    if (currentSchemeDetail) {
      const schemeId = currentSchemeDetail.SchemeID;
      let isComponentActive = true;
      setSchemeNav(null);
      (async function () {
        try {
          setLoadingSchemeNav(true);
          const response = (await dispatch(
            get_scheme_nav(schemeId)
          )) as unknown as GetSchemeNav;
          if (!isComponentActive) {
            return;
          }
          setSchemeNav(response);
          setLoadingSchemeNav(false);
        } catch (e) {
          setLoadingSchemeNav(false);
          console.log((e as Error).message);
        }
      })();

      return () => {
        isComponentActive = false;
      };
    }

  }, [dispatch, currentSchemeDetail]);

  const breadcrumbs = [
    <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
      Total Investors
    </Typography>,
    <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
      {investorName}
    </Typography>,
    <Typography key="3" color="text.primary">
      Transactions
    </Typography>,
  ];

  const handleClick = (item: any, index: any) => () => {
    setViewSubList(true);
    setItemSelected(item);
  };

  const handleSetViewSchemeDetails = (val: boolean, schemeDetail: SchemeList | undefined | null) => {
    setViewSchemeDetails(val);
    schemeDetail ? setCurrentSchemeDetail(schemeDetail) : "";
  }

  const open = Boolean(anchorEl);
  const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: any) => {
    if (value === 'Explore') history.push('/investor-transactions', {
      investorId: investorId,
      investorName: investorName,

    });
    else if (value === 'Recommendation') history.push('/recommended-schemes', {
      investorId: investorId,
      investorName: investorName,

    });
    else if (value === 'Holdings') history.push('/holdings', {
      investorId: investorId,
      investorName: investorName,
    });
    else if (value === 'Orders') history.push('/orders', {
      investorId: investorId,
      investorName: investorName,
    });
    setSelectedValue(value);
    handleClose();
  };

  return (
    <div>

      <CommonLayout>
        <>

          {location.pathname === "/investor-transactions" || location.pathname === "/recommended-schemes" || location.pathname === "/holding" || location.pathname === "/orders" ?
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2 }}>
              <Button
                sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px' }}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick_}
              >
                {selectedValue}
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendations</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
              </Menu>
            </Box> : <></>}
          <Box
            sx={{
              background: '#EEF9F4',
              borderRadius: '16px',
              px: 3,
              py: 1.5,
              mb: 2.5,
            }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 20,
                  lineHeight: '23px',
                  fontWeight: 500,
                  color: '#BBB5B5',
                  '&.MuiTypography-body1': { color: '#22242C' },
                },
                '& .MuiBreadcrumbs-separator': {
                  '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                  '&:last-of-type': {
                    background: 'red',
                    '& .MuiSvgIcon-root': { color: '#22242C' },
                  },
                },
              }}>
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          {isLoadingAsset || isLoadingschemeDetail || isLoadingschemeList || isLoadingCatAvg || isLoadingSchemeNav ? (
            <LinearProgress sx={{ mt: 2.5 }} />
          ) : (<Box
            sx={{
              bgcolor: 'common.white',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              py: 2,
            }}>
            {!viewSubList && !viewSchemeDetails && <Grid container>
              <Box
                sx={{
                  width: '100%',
                  '& .MuiTabPanel-root': { py: 2, px: 0 },
                  '& .MuiTab-root': {
                    color: '#A1A2A2',
                    opacity: 0.8,
                    fontSize: 17,
                    lineHeight: '24px',
                    textTransform: 'capitalize',
                    px: { xs: 2, md: 3, lg: 5 },
                    '&.Mui-selected': {
                      color: '#4B81B1',
                    },
                  },
                  '& .MuiTabs-indicator': {
                    height: 3,
                    background: '#4B81B1',
                  },
                }}>
                {isLoadingAsset ? <Skeleton /> : <TabContext value={value}>
                  <Box sx={{ px: 3 }}>
                    <TabList
                      onChange={handleChange}
                      aria-label="product tabs"
                      variant="scrollable"
                      scrollButtons="auto">
                      {assetClassesData?.asset_classes.map((ele, index) => {
                        return (<Tab label={ele.AssetClassName} value={ele.AssetClassName} key={index} />)
                      })}
                    </TabList>
                  </Box>
                  <Divider />
                  <Box sx={{ px: 3 }}>
                    {assetClassesData?.asset_classes.map((ele, index) => {
                      return (
                        <TabPanel value={ele.AssetClassName} key={index}>
                          {isLoadingSubAsset ? <Skeleton /> : <>
                            {subAssetClassesData?.sub_asset_classes.map((ele: any, index: number) => (
                              <div
                                key={index}
                                style={{ cursor: "pointer" }}
                                onClick={handleClick(ele, index)}
                              >
                                <TransactionAssetClassTab investorId={investorId} subAssetClassNames={ele} />
                              </div>
                            )
                            )}
                          </>}
                        </TabPanel>
                      )
                    })}
                  </Box>
                </TabContext>}
              </Box>

            </Grid>
            }
            {viewSubList && !viewSchemeDetails && <Grid container >
              <Scheme_List
                handleBackClick={() => {
                  setViewSubList(false);
                }
                }
                selectedSubAssetClass={itemSelected}
                setSelectedSubAssetClass={setItemSelected}
                handleSetViewSchemeDetails={handleSetViewSchemeDetails}
                sub_asset_classes={subAssetClassesData?.sub_asset_classes}
                schemeList={schemeList?.sub_asset_classes}
                investorId={investorId}
                
              />
            </Grid>
            }
            {viewSchemeDetails && <Grid container >
              <SchemeDetail
                handleBackClick={() => {
                  setViewSubList(true);
                  setViewSchemeDetails(false);
                }
                }
                handleSetViewSchemeDetails={handleSetViewSchemeDetails}
                schemeDetail={schemeDetail?.scheme_details}
                schemeData={currentSchemeDetail}
                categoryAverage={categoryAverage?.category_averages}
                schemeNav={schemeNav?.scheme_nav}
                investorId={investorId}
              />
            </Grid>}
          </Box>)}
        </>
      </CommonLayout>
    </div>
  );
}

import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';
import { Typography, Breadcrumbs, Link, Box } from '@mui/material';
import { Location } from 'history';
import React, { ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useReactToPrint } from 'react-to-print';
import { Button } from '@mui/material';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
function DashboardLayout({
  children,
  location,
}: {
  children: ReactNode;
  location?: Location<{ investorId: string; investorName: string }>;
}): JSX.Element {
  const history = useHistory();
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const loc = useLocation();
  const componentRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content : () => componentRef.current
  });

  return (
    <>
      {/* <div ref={componentRef}> */}
      {/* <Button
       onClick={() => window.print()}
        className="print__button" 
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 1,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            PDF
          </Button>  */}
      {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity: '0.7',
          fontSize: '1.7rem',
          cursor: 'pointer',
          borderRadius: '13px',
          '&:hover': { backgroundColor: '#E7E6E6' },
        }}
      /> */}
      <Box
        sx={{
          background: '#EEF9F4',
          borderRadius: '16px',
          px: 3,
          py: 1.5,
          mb: 2.5,
          fontSize: 20,
          lineHeight: '23px',
          fontWeight: 500,
          color: '#22242C',
        }}>
        Total Investors
      </Box>

      {children}
      {/* </div>       */}
    </>
  );
}

export default DashboardLayout;

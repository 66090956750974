import { Close, East, VisibilityOutlined } from '@mui/icons-material';
import { Box, Divider, Grid, IconButton, Popover, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import {
  DirectEquityHoldingDetailsType,
  DirectEquitySchemeDetail,
} from '../../../redux-store/types/api-types';
import { formatLongDate } from '../../../utils/date';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../../DataTable';
import EditTableHeader from './components/edit-table-header';
import { AmountUnit } from '../../commonComponents';
import GainLossPopoverButtons, {
  GainLossPopoverRowType,
} from '../components/gain-loss-popover-buttons';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

function HoldingDetails({ investorId, selectedPortfolio }: { investorId: string; selectedPortfolio:number }): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [holdingDetails, setHoldingDetails] = useState<DirectEquityHoldingDetailsType | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setHoldingDetails(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'DIRECT EQUITIES',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              customers: investorId ? [investorId] : [],
            },
            required: ['transactionDetails'],
          })
        )) as unknown as DirectEquityHoldingDetailsType;
        if (!isComponentActive) {
          return;
        }
        setHoldingDetails(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedPortfolio]);

  const customHeaders: ColumnType[] =
    holdingDetails?.schemeColumnMap.flatMap((category) =>
      category.columns.map(({ name, key }) => {
        const value = key as keyof DirectEquitySchemeDetail;
        return {
          header: name,
          sortingKey: key,
          valueGetter: (row: DirectEquitySchemeDetail, amountUnit?: number | undefined | null) => {
            if (
              key === 'AVGCostPrice' ||
              key === 'PurValue' ||
              key === 'Gainloss' ||
              key === 'CurrentPrice' ||
              key === 'MrkVal' ||
              key === '52-weekLow' ||
              key === '52-weekhigh' ||
              key === 'MarketCaptilization'
            ) {
              return row?.[value] || row?.[value] === 0
                ? formatToIndianCurrency(row?.[value], amountUnit)
                : 'N/A';
            }
            return row?.[value] || 'N/A';
          },
        };
      })
    ) || [];

  const defaultHeaders = ['No. of Shares', 'Purchase Value', 'Current Value', 'Gain/Loss %']
    .map((each) => customHeaders.find((eachHeader) => eachHeader.header === each))
    .filter(Boolean) as ColumnType[];

  const [selectedHeaders, setSelectedHeaders] = useState<ColumnType[]>(defaultHeaders);

  useEffect(() => {
    setSelectedHeaders(defaultHeaders);
  }, [holdingDetails]);

  const changeSelectedHeaders = (headers: string[]) => {
    const resultHeaders: ColumnType[] = [];
    headers.forEach((each) => {
      const result = customHeaders.find((eachHeader) => eachHeader.header === each);
      if (result) {
        resultHeaders.push(result);
      }
    });
    setSelectedHeaders(resultHeaders);
  };

  const headers: ColumnType[] = [
    {
      header: 'Security Name',
      sortingKey: 'SecurityName',

      valueGetter: (row: DirectEquitySchemeDetail) => (
        <Typography
          sx={{
            fontSize: 'inherit',
            // whiteSpace: 'nowrap',
            textAlign: 'left',
            lineHeight: '1.5em',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxHeight: '3em',
            width: '10em',
            maxWidth: '20em',
          }}>
          {row?.SecurityName || 'N/A'}
        </Typography>
      ),
      stickyColumn: true,
    },
    ...selectedHeaders,
    {
      header: () => (
        <EditTableHeader
          headers={holdingDetails?.schemeColumnMap}
          selectedHeaders={selectedHeaders.map((each) => each.header as string)}
          setSelectedHeaders={changeSelectedHeaders}
        />
      ),
      stickyColumn: true,
      valueGetter: (row: DirectEquitySchemeDetail) => (
        <GainLossPopoverButtons
          row={{
            PurchaseDate: row.PurchaseDate,
            GainLoss: row.Gainloss,
            GainLossPercentage: row.GainlossPer,
            CurrentPrice: row.CurrentPrice,
            DayReturns: row.dayReturns,
            LTCG: row.LTCG,
            STCG: row.STCG,
            PurchasePrice: row.PurchasePrice,
            TotalReturns: row.TotReturns,
          }}
        />
      ),
    },
  ];

  return (
    <Box sx={{ maxHeight: '60vh' }}>
      <Box
        sx={{
          mb: 2
        }}>
          <AmountUnit />
          {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
        </Box>
      <DataTable
        isLoading={isLoading}
        tableHeader={headers}
        tableData={holdingDetails?.schemeDetails}
        tableHeaderCustomStyles={{
          '& .MuiTableCell-root': {
            textAlign: 'left',
          },
        }}
        tableBodyCustomStyles={{
          '.MuiTableCell-root': {
            textAlign: 'left',
            fontSize: '14px',
          },
        }}
        tableStyles={{
          '::-webkit-scrollbar': {
            width: '8px',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#337FC9',
            borderRadius: '10px',
            width: '8px',
            boxShadow: 'none',
          },
          '::-webkit-scrollbar-track': {
            background: 'rgb(51 127 201 / 40%)',
            borderRadius: '10px',
          },
        }}
        boxStyles={{ minHeight: 'unset', minWidth: 450 }}
      />
    </Box>
  );
}

export default HoldingDetails;

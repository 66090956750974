import { Check } from '@mui/icons-material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  CardMedia,
  Divider,
  Grid,
  Link,
  Skeleton,
  Stack,
  styled,
  Switch,
  Typography, Button
} from '@mui/material';
import { Location } from 'history';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AssetAllocationCard } from '.';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { IndividualInvestorDashboardType, IndividualInvestorProductAllocationEntity, IndividualInvestorAssetAllocationEntity } from '../../redux-store/types/api-types';
import { formatLongDate } from '../../utils/date';
import { capitalize, formatToIndianCurrency } from '../../utils/utilityFunctions';
import { CommonLayout, CopyGraphDropdown, PopoverValue, AmountUnit, PortfolioSelection } from '../commonComponents';
import ReactCardFlip from 'react-card-flip';
import { ColumnType, DataTable } from '../DataTable';
import { FlipIcon } from '../customSVGs';
// import {useReactToPrint} from "react-to-print";
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
import pic from './Reports_Dashboard/3d.png'


const PortfolioValueCard = styled(Box)(({ theme }) => ({
  background: 'common.white',
  boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
  padding: theme.spacing(1),
  borderTop: '9px solid #4990F0',
  borderRadius: 20,
  boxSizing: 'border-box',
  position: 'relative',
  [theme.breakpoints.down('md')]: {
    boxShadow: '0px 4px 14px rgba(12, 28, 77, 0.2)',
  },
}));
const PortfolioValueLeftSectionContainer = styled(Grid)(({ theme }) => ({
  padding: theme.spacing(5, 0, 5, 5),
  [theme.breakpoints.between('md', 'lg')]: {
    padding: theme.spacing(3, 0, 3, 3),
  },
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2.5, 0, 3, 4),
  },
}));
const PortfolioValueRightSectionContainer = styled(Grid)(({ theme }) => ({
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  padding: theme.spacing(5, 5, 5, 0),
  [theme.breakpoints.between('md', 'lg')]: {
    gap: theme.spacing(1),
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));
const LabelHeadText = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  letterSpacing: '0.01em',
  color: '#000000',
  opacity: 0.98,
  [theme.breakpoints.down('lg')]: {
    fontSize: 10,
    whiteSpace: 'nowrap',
  },
}));

const LabelBodyText = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 500,
  color: '#000000',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  '&>div': {
    overflow: 'hidden',
  },
  [theme.breakpoints.between('md', 'lg')]: {
    fontSize: 12,
  },
  [theme.breakpoints.down('md')]: {
    fontSize: 16,
  },
}));
const CardOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '174px',
  bottom: 0,
  right: 0,
  left: 0,
  background: 'rgba(142, 216, 183, 0.15)',
  borderBottomLeftRadius: 18,
  borderBottomRightRadius: 18,
  margin: theme.spacing(0.5),
  [theme.breakpoints.between('md', 'lg')]: {
    top: '144px',
  },
  [theme.breakpoints.down('md')]: {
    top: '110px',
  },
}));
export default function IndividualInvestor({
  location,
}: {
  location: Location<{ investorId: string; investorName: string }>;
}): JSX.Element {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [value, setValue] = useState("Muthoot")
  const [investorData, setInvestorData] = useState<IndividualInvestorDashboardType | null>(null);
  const [selectedAssetsType, setSelectedAssetsType] = useState<'assetClass' | 'product'>('product');
  const valueConverter = useSelector((state: any) => state.valueConverter)
  const [flipAssetAllocationIndividualInvestor, setFlipAssetAllocationIndividualInvestor] = useState(false);
  const dispatch = useDispatch();

  // const componentRef = React.useRef<HTMLDivElement>(null);
  // const handlePrint = useReactToPrint({
  //   content : () => componentRef.current
  // });
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const [showImage, setShowImage] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowImage(true);
    }, 1050);

    return () => clearTimeout(timer);
  }, [value]);

  const portfolioSelect: any = {
    "Muthoot": 1,
    "Held Away": 2,
    "Combined": 3
  }

  useEffect(() => {
    if (!investorId) {
      history.push('/investors');
    }
    let isComponentActive = true;
    setInvestorData(null);
    const investorData = async () => {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            rmID: responseRM.Header_ID,
            HFlag: portfolioSelect[value],
            productLevel: '',
            filters: {
              customers: investorId ? [investorId] : [],
              assetCategories: [],
              sebiCategories: [],
              amcs: [],
              schemes: [],
            },
            required: ['portfolio'],
          })
        )) as unknown as IndividualInvestorDashboardType;
        if (!isComponentActive) {
          return;
        }
        setInvestorData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    }
    investorData()
    return () => {
      isComponentActive = false;
    };
  }, [value]);


  const breadcrumbs = [
    // <Link
    //   key="1"
    //   sx={{ cursor: 'pointer', textDecoration: 'none' }}
    //   onClick={() => history.push('/investors')}>
    //   Total Investors
    // </Link>,
    <Typography key="2" color="text.primary">
      {investorName}
    </Typography>,
  ];
  // Graph refs
  const assetAllocationRef = useRef(null);
  const productIndividualInvestorTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Product Name',
        sortingKey: 'ProductLevel',
        valueGetter: (row: IndividualInvestorProductAllocationEntity) => row?.ProductLevel,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: IndividualInvestorProductAllocationEntity, amountUnit?: number | null | undefined) => row?.PurVal || row?.PurVal === 0 ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: IndividualInvestorProductAllocationEntity, amountUnit?: number | null | undefined) => row?.MktVal || row?.MktVal === 0 ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: IndividualInvestorProductAllocationEntity) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: IndividualInvestorProductAllocationEntity) => row?.MktValPercent,
      },

      // {
      //   header: '',
      //   valueGetter: (row: AssetAllocationEntity) => row. I_Flag,
      // },
    ];
  };

  const assetClassIndividualInvestorTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Asset Class',
        sortingKey: 'Asset_Class',
        valueGetter: (row: IndividualInvestorAssetAllocationEntity) => row?.Asset_Class,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: IndividualInvestorAssetAllocationEntity, amountUnit?: number | null | undefined) => row?.PurVal || row?.PurVal === 0 ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: IndividualInvestorAssetAllocationEntity, amountUnit?: number | null | undefined) => row?.MktVal || row?.MktVal === 0 ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: IndividualInvestorAssetAllocationEntity) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: IndividualInvestorAssetAllocationEntity) => row?.MktValPercent,
      },
    ];
  };

  return (
    <div
    // ref={componentRef}
    >

      <CommonLayout>
        <>
          <Button
            onClick={() => window.print()}

            sx={{
              marginBottom: '10px',
              height: '27px',
              color: 'white',
              fontSize: '13px',
              cursor: 'pointer',
              borderRadius: '5px',
              bgcolor: '#4990F0',
              '&:hover': { backgroundColor: '#639FF0' },
            }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>

          <Box
            sx={{
              background: '#EEF9F4',
              borderRadius: '16px',
              px: 3,
              py: 1.5,
              mb: 2.5,
            }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 20,
                  lineHeight: '23px',
                  fontWeight: 500,
                  color: '#BBB5B5',
                  '&.MuiTypography-body1': { color: '#22242C' },
                },
                '& .MuiBreadcrumbs-separator': {
                  '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                  '&:last-of-type': {
                    background: 'red',
                    '& .MuiSvgIcon-root': { color: '#22242C' },
                  },
                },
              }}>
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              mb: 3, display: "flex", justifyContent: "space-between", width: "100%"
            }}>
            <AmountUnit />
            <PortfolioSelection val={value} setValue={setValue} setShowImage={setShowImage} />
          </Box>
          <Box
            sx={{
              bgcolor: 'common.white',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              p: 3,
            }}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <Stack>
                  <Typography
                    sx={{
                      fontSize: [16, 16, 20, 26],
                      fontWeight: 700,
                      color: 'text.secondary',
                      mb: 2,
                    }}>
                    {investorName}
                  </Typography>
                  {/* <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                  <CardMedia
                    component="img"
                    src="/images/email-grey.svg"
                    alt="email"
                    sx={{ width: 'unset', mr: 1 }}
                  />
                  {isLoading ? (
                    <Skeleton sx={{ width: '60%' }} />
                  ) : (
                    <Typography sx={{ fontSize: 15, color: 'rgba(75, 75, 75, 0.8)' }}>
                      {investorData?.investorDetails[0]?.EmailID}
                    </Typography>
                  )}
                </Stack>
                <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                  <CardMedia
                    component="img"
                    src="/images/contact-grey.svg"
                    alt="email"
                    sx={{ width: 'unset', mr: 1 }}
                  />
                  {isLoading ? (
                    <Skeleton sx={{ width: '60%' }} />
                  ) : (
                    <Typography sx={{ fontSize: 15, color: 'rgba(75, 75, 75, 0.8)' }}>
                      (91) {investorData?.investorDetails[0]?.Mobile}
                    </Typography>
                  )}
                </Stack> */}
                  <Stack direction="row" alignItems="center" sx={{ mb: 1.5 }}>
                    <CardMedia
                      component="img"
                      src="/images/person-grey.svg"
                      alt="email"
                      sx={{ width: 'unset', mr: 1 }}
                    />
                    {isLoading ? (
                      <Skeleton sx={{ width: '60%' }} />
                    ) : (
                      <Typography sx={{ fontSize: 15, color: 'rgba(75, 75, 75, 0.8)' }}>
                        {investorData?.investorDetails[0]?.RMNAME}
                      </Typography>
                    )}
                  </Stack>
                  <Typography sx={{ fontSize: 16, fontWeight: 600, color: '#1E293B', my: 2 }}>
                    PRODUCTS
                  </Typography>
                  {isLoading ? (
                    <>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Skeleton sx={{ width: '30%', height: '50px' }} />
                        <Skeleton sx={{ width: '30%', height: '50px' }} />
                        <Skeleton sx={{ width: '30%', height: '50px' }} />
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1}>
                        <Skeleton sx={{ width: '30%', height: '50px' }} />
                        <Skeleton sx={{ width: '30%', height: '50px' }} />
                      </Stack>
                    </>
                  ) : (
                    <>
                      <Stack direction="row" alignItems="center" flexWrap="wrap" gap={1}>
                        {['Mutual Funds', 'PMS', 'Direct Equities'].map((each) => {
                          return (
                            <Box
                              key={each}
                              onClick={() =>
                                investorData?.products.includes(each) ? (
                                  each === 'Mutual Funds'
                                    ? history.push('/investor/mutual-fund', {
                                      investorId,
                                      investorName,
                                    })
                                    : each === 'Direct Equities'
                                      ? history.push('/investor/direct-equity', {
                                        investorId,
                                        investorName,
                                      })
                                      : each === 'PMS' &&
                                      history.push('/investor/pms', {
                                        investorId,
                                        investorName,
                                      })
                                ) : null
                              }
                              sx={{
                                color: investorData?.products.includes(each) ? '#0BAAE7' : '#808080',
                                fontSize: [10, 10, 10, 14],
                                border: investorData?.products.includes(each)
                                  ? '1px solid #0BAAE7'
                                  : '1px solid #EEEEEE',
                                fontWeight: 400,
                                p: ['4px', '4px', '4px', '4px 8px'],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                borderRadius: '5px',
                                background: investorData?.products?.includes(each) ? '' : '#F6F6F6',
                                '&: hover': {
                                  background: investorData?.products?.includes(each)
                                    ? 'linear-gradient(180deg, #0090FF 0%, #1EB1F3 100%)'
                                    : '#F6F6F6',
                                  color: investorData?.products.includes(each) ? 'white' : '#808080',
                                },
                                boxShadow: investorData?.products.includes(each)
                                  ? '0px 4px 12px 2px rgba(0, 0, 0, 0.08)'
                                  : '',
                                cursor: investorData?.products.includes(each) ? 'pointer' : 'unset',
                              }}>
                              {each}
                              {/* {investorData?.products.includes(each) ? (
                              <Check sx={{ color: '#0BAAE7', width: '15px' }} />
                            ) : null} */}
                            </Box>
                          );
                        })}
                      </Stack>
                      <Stack direction="row" alignItems="center" gap={1} sx={{ my: 2 }}>
                        {['AIF', 'Bonds'].map((each) => {
                          return (
                            <Box
                              key={each}
                              onClick={() =>
                                investorData?.products.includes(each) ? (
                                  each === 'AIF' ?
                                    history.push('/investor/aif', {
                                      investorId,
                                      investorName,
                                    })
                                    : each === 'Bonds' &&
                                    history.push('/investor/bonds', {
                                      investorId,
                                      investorName,
                                    })
                                ) : null
                              }
                              sx={{
                                color: investorData?.products.includes(each) ? '#0BAAE7' : '#808080',
                                fontSize: [10, 10, 10, 14],
                                border: investorData?.products.includes(each)
                                  ? '1px solid #0BAAE7'
                                  : '1px solid #EEEEEE',
                                fontWeight: 400,
                                p: ['4px', '4px', '4px', '4px 8px'],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                borderRadius: '5px',
                                background: investorData?.products?.includes(each) ? '' : '#F6F6F6',
                                '&: hover': {
                                  background: investorData?.products?.includes(each)
                                    ? 'linear-gradient(180deg, #0090FF 0%, #1EB1F3 100%)'
                                    : '#F6F6F6',
                                  color: investorData?.products.includes(each) ? 'white' : '#808080',
                                },
                                boxShadow: investorData?.products.includes(each)
                                  ? '0px 4px 12px 2px rgba(0, 0, 0, 0.08)'
                                  : '',
                                cursor: investorData?.products.includes(each) ? 'pointer' : 'unset',
                              }}>
                              {each}
                              {/* {investorData?.products.includes(each) ? (
                              <Check sx={{ color: '#0BAAE7', width: '15px' }} />
                            ) : null} */}
                            </Box>
                          );
                        })}
                      </Stack>
                    </>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} sm={8}>
                <PortfolioValueCard>
                  <Grid container sx={{ position: "relative" }}>
                    <PortfolioValueLeftSectionContainer item xs={12} md={7}>
                      <Typography
                        sx={{
                          fontSize: [13, 13, 16, 22],
                          letterSpacing: '0.01em',
                          color: '#000000',
                          opacity: 0.9,
                        }}>
                        Portfolio Value
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: [7, 7, 9, 10],
                          color: '#000000',
                          opacity: 0.9,
                        }}>
                        (as on{' '}
                        {isLoading ? (
                          <Skeleton sx={{ width: '15%', display: 'inline-block', m: 1 }} />
                        ) : (
                          formatLongDate(investorData?.portfolioDetails[0]?.AsonDate)
                        )}
                        )
                      </Typography>
                      {isLoading ? (
                        <Skeleton sx={{ width: '70%', height: '50px' }} />
                      ) : (
                        <Typography
                          sx={{
                            fontSize: investorData?.portfolioDetails[0]?.MktVal == null ? 52 : [15, 20, 32],
                            fontWeight: 600,
                            color: '#000000',
                            my: [.5, .5, 2],
                          }}>
                          {investorData?.portfolioDetails[0]?.MktVal ? formatToIndianCurrency(investorData?.portfolioDetails[0]?.MktVal, valueConverter) || 'N/A' : "0"}
                        </Typography>
                      )}
                      {showImage && investorData?.portfolioDetails[0]?.MktVal == null && <img src={pic} style={{ position: "absolute", top: 10, right: 0, width: "50%", aspectRatio: 1, maxWidth: "auto", maxHeight: "95%" }} />}
                      {investorData?.portfolioDetails[0]?.MktVal &&
                        <Stack direction="row" spacing={4} sx={{ pt: [0, 0, 2, 0], mt: [0, 0, 4] }}>
                          <Stack
                            sx={{
                              flexDirection: ['row', 'row', 'column'],
                              alignItems: ['center', 'center', 'unset'],
                              gap: [1.5, 1.5, 0.5, 0],
                            }}>
                            <Typography
                              sx={{
                                fontSize: [10, 10, 13],
                                color: '#000000',
                                opacity: 0.9,
                              }}>
                              Gain/Loss
                            </Typography>
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: [12, 12, 14, 18],
                                  fontWeight: 600,
                                }}>
                                <span
                                  style={{
                                    color:
                                      investorData?.portfolioDetails[0]?.UnRealReturns?.toString()?.includes(
                                        '-'
                                      )
                                        ? 'red'
                                        : '#8ED8B7',
                                    marginRight: 2,
                                  }}>
                                  {investorData?.portfolioDetails[0]?.UnRealReturns?.toString()?.includes(
                                    '-'
                                  )
                                    ? '-'
                                    : '+'}
                                </span>
                                {investorData?.portfolioDetails[0]?.UnRealReturns?.toString()?.includes(
                                  '-'
                                )
                                  ? formatToIndianCurrency(
                                    investorData?.portfolioDetails[0]?.UnRealReturns
                                    , valueConverter)?.split('-')[1]
                                  : formatToIndianCurrency(
                                    investorData?.portfolioDetails[0]?.UnRealReturns
                                    , valueConverter) || 'N/A'}
                              </Typography>
                            )}
                          </Stack>
                          <Stack
                            sx={{
                              flexDirection: ['row', 'row', 'column'],
                              alignItems: ['center', 'center', 'unset'],
                              gap: [2, 2, 0.5, 0],
                            }}>
                            <Typography
                              sx={{
                                fontSize: [10, 10, 13],
                                color: '#000000',
                                opacity: 0.9,
                              }}>
                              XIRR
                            </Typography>
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: [12, 12, 14, 18],
                                  fontWeight: 600,
                                  color: '#4E5056',
                                }}>
                                {investorData?.portfolioDetails[0]?.PortfolioXiRR
                                  ? investorData?.portfolioDetails[0]?.PortfolioXiRR + '%'
                                  : 'N/A'}
                              </Typography>
                            )}
                          </Stack>
                        </Stack>}
                    </PortfolioValueLeftSectionContainer>
                    {investorData?.portfolioDetails[0]?.MktVal &&
                      <Grid
                        item
                        xs={12}
                        md={1}
                        sx={{ height: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <Divider orientation="vertical" flexItem sx={{ borderColor: '#4990F0' }} />
                      </Grid>}
                    {investorData?.portfolioDetails[0]?.MktVal &&
                      <PortfolioValueRightSectionContainer item xs={12} md={4}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <CardMedia
                            component="img"
                            src="/images/invested-value.svg"
                            alt="Invested value"
                            sx={{ width: 'auto' }}
                          />
                          <Stack>
                            <LabelHeadText>Invested Value</LabelHeadText>
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <LabelBodyText>
                                {formatToIndianCurrency(investorData?.portfolioDetails[0]?.PurVal, valueConverter) ||
                                  'N/A'}
                              </LabelBodyText>
                            )}
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <CardMedia
                            component="img"
                            src="/images/inflow-outflow.svg"
                            alt="Inflow outflow"
                            sx={{ width: 'auto' }}
                          />
                          <Stack>
                            <LabelHeadText>Inflow - Outflow</LabelHeadText>
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <LabelBodyText>
                                {formatToIndianCurrency(
                                  investorData?.portfolioDetails[0]?.InflowOutflowAmt
                                  , valueConverter) || 'N/A'}
                              </LabelBodyText>
                            )}
                          </Stack>
                        </Stack>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <CardMedia
                            component="img"
                            src="/images/avg-days.svg"
                            alt="avg days"
                            sx={{ width: 'auto' }}
                          />
                          <Stack>
                            <LabelHeadText>Weighted Avg Days</LabelHeadText>
                            {isLoading ? (
                              <Skeleton />
                            ) : (
                              <LabelBodyText>
                                {investorData?.portfolioDetails[0]?.WeightedAvgDays || 'N/A'}
                              </LabelBodyText>
                            )}
                          </Stack>
                        </Stack>
                      </PortfolioValueRightSectionContainer>}
                  </Grid>
                  <CardOverlay />
                </PortfolioValueCard>
              </Grid>
            </Grid>
          </Box>
          {investorData?.portfolioDetails[0]?.MktVal &&
            <Box
              ref={assetAllocationRef}
              sx={{
                background: ' #FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
                mt: 4,
              }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                  py: 1,
                }}>
                <Typography
                  sx={{
                    color: '#22242C',
                    opacity: 0.8,
                    fontSize: 16,
                    lineHeight: '19px',
                    fontWeight: 600,
                  }}>
                  Asset Allocation
                </Typography>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                      sx={{
                        color: '#22242C',
                        opacity: 0.8,
                        fontSize: 15,
                        fontWeight: 500,
                      }}>
                      By Product
                    </Typography>
                    <Switch
                      checked={selectedAssetsType === 'assetClass'}
                      onChange={(_, checked) =>
                        setSelectedAssetsType(checked ? 'assetClass' : 'product')
                      }
                      sx={{
                        p: 0,
                        mx: 2.5,
                        height: 20,
                        width: 52,
                        '& .MuiSwitch-track': {
                          background: '#4990F0',
                          opacity: 1,
                          borderRadius: '12px',
                        },
                        '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                        '& .MuiSwitch-switchBase': {
                          p: 0,
                          '&.Mui-checked': {
                            color: '#fff',
                            transform: 'translateX(32px)',
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#4990F0',
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#22242C',
                        opacity: 0.8,
                        fontSize: 15,
                        fontWeight: 500,
                      }}>
                      By Asset Class
                    </Typography>
                  </Box>
                  {/* <CopyGraphDropdown elementRef={assetAllocationRef} /> */}
                </Stack>
              </Box>
              <Divider />
              <Box
                sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
              >
                <FlipIcon
                  onClick={() => (setFlipAssetAllocationIndividualInvestor(!flipAssetAllocationIndividualInvestor))}
                  style={{
                    height: "20px",
                    cursor: 'pointer'

                  }}
                />
              </Box>
              <ReactCardFlip isFlipped={flipAssetAllocationIndividualInvestor}
                flipDirection="horizontal">
                <div
                  style={{
                    backgroundColor: 'white',
                    display: `${flipAssetAllocationIndividualInvestor ? 'none' : 'block'}`
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      p: 2,
                      justifyContent: 'center',
                      width: '100%',
                    }}>
                    <Box sx={{ display: 'flex', width: '100%' }}>
                      <Box style={{ width: '50%', textAlign: 'right', paddingRight: '20px' }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: '#22242C',
                            opacity: 0.8,
                            mb: 1,
                            textAlign: 'right',
                          }}>
                          Invested Value
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: [16, 16, 18, 20],
                            color: '#11263C',
                            mb: 3.5,
                            fontWeight: 700,
                            textAlign: 'right',
                          }}>
                          {isLoading ? (
                            <Skeleton />
                          ) : (
                            `${(investorData?.assetAllocation &&
                              formatToIndianCurrency(investorData?.assetAllocation[0]?.TotalInvesVal, valueConverter)) ||
                            'NA'
                            }`
                          )}
                        </Typography>
                      </Box>
                      <Box style={{ width: 150, textAlign: 'center' }}></Box>
                      <Box style={{ width: '50%', paddingLeft: '20px' }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                            color: '#22242C',
                            opacity: 0.8,
                            mb: 1,
                          }}>
                          Current Market Value
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: [16, 16, 18, 20],
                            color: '#11263C',
                            mb: 3.5,
                            fontWeight: 700,
                          }}>
                          {isLoading ? (
                            <Skeleton />
                          ) : (
                            `${(investorData?.assetAllocation &&
                              formatToIndianCurrency(investorData?.assetAllocation[0]?.TotalMktVal, valueConverter)) ||
                            'NA'
                            }`
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    {selectedAssetsType === 'assetClass' && investorData?.assetAllocation
                      ? investorData?.assetAllocation.map((asset, index: number) => (
                        <AssetAllocationCard
                          key={index}
                          label={capitalize(asset?.Asset_Class) || ''}
                          investedPercent={asset?.PurValPercent || ''}
                          currentPercent={asset?.MktValPercent || ''}
                          investedValue={asset?.PurVal || ''}
                          currentValue={asset?.MktVal || ''}
                          xirr={asset?.Xirr}
                          labelStyles={{ border: 0 }}
                          leftBarColor="#4990F0"
                          rightBarColor="#2E5DA3"
                          leftPopoverContent={
                            <Box
                              sx={{
                                p: 1,
                                background: '#FFFFFF',
                                boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.07)',
                                borderRadius: '3px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}>
                              <PopoverValue
                                label={'Current Market Value'}
                                amount={formatToIndianCurrency(asset?.MktVal, valueConverter)}
                                percent={asset?.MktValPercent}
                                color={'#8ED8B7'}
                              />
                              <PopoverValue
                                label={'Invested Value'}
                                amount={formatToIndianCurrency(asset?.PurVal, valueConverter)}
                                percent={asset?.PurValPercent}
                                color={'#67ADF1'}
                              />
                              <PopoverValue
                                label={'XIRR'}
                                amount={
                                  asset?.Xirr
                                    ? asset.Xirr > 0
                                      ? `${asset.Xirr}%`
                                      : `( ${asset.Xirr}%.toString().split('-')[1] )`
                                    : `0%`
                                }
                                color={asset?.Xirr && asset?.Xirr > 0 ? '#8ED8B7' : '#E89292'}
                              />
                            </Box>
                          }
                          rightPopoverContent={
                            <Box
                              sx={{
                                p: 1,
                                background: '#FFFFFF',
                                boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.07)',
                                borderRadius: '3px',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 1,
                              }}>
                              <PopoverValue
                                label={'Current Market Value'}
                                amount={formatToIndianCurrency(asset?.MktVal, valueConverter)}
                                percent={asset?.MktValPercent}
                                color={'#8ED8B7'}
                              />
                              <PopoverValue
                                label={'Invested Value'}
                                amount={formatToIndianCurrency(asset?.PurVal, valueConverter)}
                                percent={asset?.PurValPercent}
                                color={'#67ADF1'}
                              />
                              <PopoverValue
                                label={'XIRR'}
                                amount={
                                  asset?.Xirr
                                    ? asset.Xirr > 0
                                      ? `${asset.Xirr}%`
                                      : `( ${asset?.Xirr.toString().split('-')[1]}% )`
                                    : `0%`
                                }
                                color={asset?.Xirr && asset?.Xirr > 0 ? '#8ED8B7' : '#E89292'}
                              />
                            </Box>
                          }
                        />
                      ))
                      : selectedAssetsType === 'product' && investorData?.productAllocation
                        ? investorData?.productAllocation.map((asset, index: number) => (
                          <AssetAllocationCard
                            key={index}
                            label={asset?.ProductLevel}
                            investedPercent={asset?.PurValPercent}
                            currentPercent={asset?.MktValPercent}
                            investedValue={asset?.PurVal}
                            currentValue={asset?.MktVal}
                            xirr={asset?.Xirr}
                            leftBarColor="#4990F0"
                            rightBarColor="#2E5DA3"
                            leftPopoverContent={
                              <Box
                                sx={{
                                  p: 1,
                                  background: '#FFFFFF',
                                  boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.07)',
                                  borderRadius: '3px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                }}>
                                <PopoverValue
                                  label={'Current Market Value'}
                                  amount={formatToIndianCurrency(asset?.MktVal, valueConverter)}
                                  percent={asset?.MktValPercent}
                                  color={'#2E5DA3'}
                                />
                                <PopoverValue
                                  label={'Invested Value'}
                                  amount={formatToIndianCurrency(asset?.PurVal, valueConverter)}
                                  percent={asset?.PurValPercent}
                                  color={'#4990F0'}
                                />
                                <PopoverValue
                                  label={'XIRR'}
                                  amount={
                                    asset?.Xirr
                                      ? asset.Xirr > 0
                                        ? `${asset.Xirr}%`
                                        : `( ${asset?.Xirr.toString().split('-')[1]}% )`
                                      : `0%`
                                  }
                                  color={asset?.Xirr && asset?.Xirr > 0 ? '#8ED8B7' : '#E89292'}
                                />
                              </Box>
                            }
                            rightPopoverContent={
                              <Box
                                sx={{
                                  p: 1,
                                  background: '#FFFFFF',
                                  boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.07)',
                                  borderRadius: '3px',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: 1,
                                }}>
                                <PopoverValue
                                  label={'Current Market Value'}
                                  amount={formatToIndianCurrency(asset?.MktVal, valueConverter)}
                                  percent={asset?.MktValPercent}
                                  color={'#2E5DA3'}
                                />
                                <PopoverValue
                                  label={'Invested Value'}
                                  amount={formatToIndianCurrency(asset?.PurVal, valueConverter)}
                                  percent={asset?.PurValPercent}
                                  color={'#4990F0'}
                                />
                                <PopoverValue
                                  label={'XIRR'}
                                  amount={
                                    asset?.Xirr
                                      ? asset.Xirr > 0
                                        ? `${asset.Xirr}%`
                                        : `( ${asset?.Xirr.toString().split('-')[1]}% )`
                                      : `0%`
                                  }
                                  color={asset?.Xirr && asset?.Xirr > 0 ? '#8ED8B7' : '#E89292'}
                                />
                              </Box>
                            }
                          />
                        ))
                        : [...Array(3)].map((asset, index: number) => (
                          <AssetAllocationCard
                            key={index}
                            label={asset?.ProductLevel}
                            investedPercent={asset?.PurValPercent}
                            currentPercent={asset?.MktValPercent}
                            investedValue={asset?.PurVal}
                            currentValue={asset?.MktVal}
                            isLoading={true}
                          />
                        ))}
                  </Box>
                </div>
                <div style={{
                  maxHeight: '300px',
                  display: `${flipAssetAllocationIndividualInvestor ? 'block' : 'none'}`
                }}>
                  {selectedAssetsType === 'product' ?
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={[...productIndividualInvestorTableHeaders()]}
                      tableData={investorData?.productAllocation && investorData?.productAllocation}

                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },

                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: '500px', minWidth: 450 }}
                    />
                    :

                    <DataTable
                      isLoading={isLoading}
                      tableHeader={[...assetClassIndividualInvestorTableHeaders()]}
                      tableData={
                        investorData?.assetAllocation

                      }

                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },

                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: '500px', minWidth: 450 }}
                    />}
                </div>
              </ReactCardFlip>
            </Box>}
        </>
      </CommonLayout>
    </div>
  );
}

import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import MFCheckbox from '../../lib/formik/Checkbox';
import { RootStateType } from '../../redux-store/reducers';
import { Applicant, ApplicationProps } from '../../redux-store/types/api-types';
import { USER_ROLES } from '../../utils/constant';
import { Gridstyles } from './index';

export const getApplicant = (applicants: Partial<Applicant>[]) => {
  return applicants?.find((applicant) => Number(applicant.applicant_type) === 1);
};

const TermsAndConditions = (): JSX.Element => {
  const [isDialog, setIsDialog] = useState(false);
  return (
    <>
      <Typography>
        I/We hereby confirm that we gone through and understood the General{' '}
        <Button
          sx={{
            fontSize: 'inherit',
            fontWeight: 'inherit',
            fontFamily: 'inherit',
            color: 'inherit',
            textUnderlinePosition: 'under',
            textDecoration: 'underline',
            p: 0,
            ':hover': {
              bgcolor: '#F4FCFC',
              color: 'primary.main',
              textDecoration: 'underline',
            },
          }}
          onClick={() => {
            setIsDialog(true);
          }}>
          Terms and Conditions
        </Button>
      </Typography>
      <TermsAndConditionsText
        handleDialogClose={() => {
          setIsDialog(false);
        }}
        isDialog={isDialog}
      />
    </>
  );
};

const styles = {
  header: {
    fontSize: 20,
    color: 'primary.main',
    py: 2,
  },
  header2: {
    fontSize: 18,
    color: 'primary.main',
    py: 1,
  },
  content: {
    fontSize: 14,
    py: 1,
  },
  list: {
    fontSize: 14,
    py: 0.5,
  },
};

export const Ol = ({ children, style }: { children: JSX.Element; style?: any }) => {
  return (
    <Typography component={'ol'} style={style}>
      {children}
    </Typography>
  );
};
export const Ul = ({ children, style }: { children: JSX.Element; style?: any }) => {
  return (
    <Typography component={'ul'} style={style}>
      {children}
    </Typography>
  );
};
export const JustifiedText = ({
  children,
  style,
  component = Typography,
}: {
  children: JSX.Element;
  style?: any;
  component?: React.ElementType;
}) => {
  return (
    <Typography
      sx={{ textAlign: 'justify', ...styles.content }}
      style={style}
      component={component}>
      {children}
    </Typography>
  );
};
export const Li = ({
  children,
  style,
}: {
  children: JSX.Element;
  style?: any;
  component?: React.ElementType;
}) => {
  return (
    <Typography
      sx={{
        textAlign: 'justify',
        ...styles.content,
        ...style,
      }}
      component={'li'}>
      {children}
    </Typography>
  );
};

const TermsAndConditionsText = ({
  handleDialogClose,
  isDialog,
}: {
  handleDialogClose: any;
  isDialog: boolean;
}) => {
  return (
    <Dialog
      // onClose={handleDialogClose}
      open={isDialog}
      sx={{
        '.MuiPaper-root ': {
          p: 4,
          width: { xs: '100%', sm: '100%' },
        },
      }}
      fullWidth={true}
      maxWidth={'lg'}>
      <Box
        sx={{
          width: { xs: '100%', sm: '100%' },
          mx: 'auto',
          //   '.MuiTypography-root': {
          //     // fontSize: 20,
          //     fontWeight: 500,
          //   },
        }}>
        <Typography sx={styles.header}>DECLARATION</Typography>
        <JustifiedText>
          <>
            I acknowledge and confirm that the information provided above is true and correct . In
            case any of the above specified information is found to be false or untrue or misleading
            or misrepresenting, I/ am aware that I may stand liable for it. I hereby authorize Muthoot
            Fincorp Limited to disclose, share, rely, remit in any form, mode or manner,
            all / any of the information provided by me, including all changes, updates to such
            information as and when provided by me to / any of the Mutual Fund, its Sponsor, Asset
            Management Company, trustees, their employees / RTAs (&apos;the Authorized
            Parties&apos;) or any Indian or foreign governmental or statutory or judicial
            authorities / agencies including but not limited to the Financial Intelligence
            Unit-India (FIU-IND), the tax / revenue authorities in India or outside India wherever
            it is legally required and other investigation agencies without any obligation of
            advising me of the same. Further, I authorize to share the given information to other
            SEBI Registered Intermediaries/or any regulated intermediaries registered with SEBI /
            RBI / IRDA / PFRDA to facilitate single submission / update & for other relevant
            purposes. I also undertake to keep you informed in writing about any changes /
            modification to the above information in future within 30 days and also undertake to
            provide any other additional information as may be required at your / Fund&apos;s end or
            by domestic or overseas regulators/ tax authorities. I/We authorize Fund/AMC/RTA to
            provide relevant information to upstream payors to enable withholding to occur and pay
            out any sums from my account or close or suspend my account(s) without any obligation of
            advising me of the same. I also confirm that I have read and understood the FATCA & CRS
            Terms and Conditions and hereby accept the same.
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            I hereby authorize Muthoot Fincorp Ltd. to onboard me/us on platforms offered
            by NSE or BSE or any other platform provider for the purpose of transacting in Mutual
            Schemes. I acknowledge and confirm that the ARN holder has disclosed to me/us all the
            commission (In the form of trail commission or any other mode), payable to them/him from
            Mutual Funds offered.
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            I/We hereby authorize above person to give instruction for account operation on our
            behalf to Muthoot Fincorp Ltd. (Muthoot Fincorp Ltd.) for executing investment
            transactions in my/ our name(s) and instruct Muthoot Fincorp Ltd. to act on such instructions.
            In case we wish to revoke this authority, we shall give instructions in writing to Muthoot
            Fincorp of such revocation and the same shall come into effect once received and
            acknowledged by Muthoot Fincorp Ltd.. I/We agree to keep Muthoot Fincorp Ltd. indemnified for the
            decisions taken by the above person acting in our name.
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            I/We declare that we are members of the aforesaid family / Group who have common
            financial and property interests and person designated above is the Head of the
            Family/Group.{' '}
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            I/we have/ hold accounts in various combinations with Muthoot Fincorp Ltd.. I/We authorize and
            give our unequivocal consent to Muthoot Fincorp Ltd. to share information regarding our
            investments, portfolio details, etc. with the head of the family/ Group.{' '}
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            I/We hereby confirm that we gone through and understood the General Terms and Conditions
            mentioned below.{' '}
          </>
        </JustifiedText>
        <Typography sx={styles.header}>GENERAL TERMS & CONDITIONS</Typography>
        <JustifiedText>
          <>
            Muthoot Fincorp Ltd. (&quot;Muthoot Fincorp Ltd.&quot;) is acting as a distributor
            across various financial products and offers a broad range of execution services to its
            Clients. The terms and conditions contained herein are binding on the Client. The Client
            agreed to avail facilities/services from the Muthoot Fincorp Ltd. after fully understanding the
            entire terms and conditions. The terms and conditions shall be subject to amendments, if
            any, from time to time at the sole discretion of Muthoot Fincorp Ltd. and such amendments shall
            be binding on the client
          </>
        </JustifiedText>
        <Typography sx={styles.header2}>Roles and Responsibilities of Muthoot Fincorp Ltd.</Typography>
        <JustifiedText>
          <>
            Muthoot Fincorp Ltd. shall endeavour to provide a range of products and services that it feels
            are suitable for Clients based on their financial needs, risk profile, investment
            objectives and other information provided by them.{' '}
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            Muthoot Fincorp Ltd. shall endeavour to provide Clients with product related documents, forms,
            terms and conditions, agreements, term-sheets and fee structures of products that are
            made available to them. Muthoot Fincorp Ltd. shall be entitled to presume that Clients have
            read and understood the material(s) provided and have made an informed decision prior to
            investing in the products offered.{' '}
          </>
        </JustifiedText>
        <JustifiedText>
          <>
            Subject to such considerations as Muthoot Fincorp Ltd. may consider practical and subject to
            the other provisions of the GT&C and Agreements, Muthoot Fincorp Ltd. shall act in a fiduciary
            capacity and as agent of Clients for the execution of instructions pertaining to the
            purchase or sale of securities and execute all instructions in good faith and without
            negligence and subject to the local laws, rules, regulations, procedures, practices and
            guidelines including Rules, Regulations, Guidelines made under the SEBI Act and any
            other laws, rules, regulations, guidelines, etc., of any competent authority applicable
            from time to time.{' '}
          </>
        </JustifiedText>
        <Ol>
          <>
            Muthoot Fincorp Ltd. shall maintain complete confidentiality on all information about Clients
            and their investments, instructions, confirmations and other communication during and
            for one year after the termination of the client relationship. Provided that Muthoot
            Fincorp may provide such information. as it may consider necessary and to the extent
            permitted by regulations to{' '}
            <JustifiedText>
              <>
                (a) agents and intermediaries to which specific functions have been delegated or
                through whom the instructions Clients are executed,{' '}
              </>
            </JustifiedText>
            <JustifiedText>
              <>(b) the employees, advisers and legal counsel of Muthoot Fincorp Ltd.. </>
            </JustifiedText>
          </>
        </Ol>
        <JustifiedText>
          <>
            Muthoot Fincorp Ltd. shall, without further intimation to Clients, provide when so lawfully
            directed, information about the Clients and their investments, instructions,
            confirmations and other communication to Regulators, Enforcement & Investigative
            Agencies. Tax Authorities and other applicable Authorities.{' '}
          </>
        </JustifiedText>
        <Ul>
          <>
            <Li>
              <>
                The Client has option to authorize Muthoot Fincorp Ltd. by executing a Power of Attorney in
                favour of the Muthoot Fincorp Ltd. to execute instructions of the Client or its authorized
                representative in respect to the transactions of Investments / Dis-Investments in
                securities in client&apos;s name. Muthoot Fincorp Ltd. may furnish, wherever required, a
                certified copy of the Power of Attorney and other documents on behalf of the Client
                to the Company/ Registrar/Mutual Fund or any third party.{' '}
              </>
            </Li>
            <Li>
              <>
                For giving instructions and communication pursuant to services availed by Clients or
                Authorised Person(s) of Clients, and where permitted by Regulations, Muthoot Fincorp Ltd.
                is entitled to presume that the Instructions and communication may be given by any
                one, or some, or all such Clients or Authorised Person(s) of Clients to the accounts
                and, each and every such instruction and communication would be binding on all of
                them.{' '}
              </>
            </Li>
            <Li>
              <>
                Where the client has availed of the trading facility of Exchange or any other such
                facility offered by any Platform for transacting in the units of mutual fund schemes
                on MFSS / BSE STar by NSE / BSE or any other such platform respectively offered
                through Muthoot Fincorp Ltd., the client agrees to abide by the terms and conditions
                specified by NSE / BSE / Platform from time to time.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client authorizes Muthoot Fincorp Ltd. to disclose/share, all such information
                pertaining to the Client with the companies /entities /subsidiaries /affiliates of
                Muthoot Fincorp Ltd. or their agent&apos;s Banks / Financial Institutions /Statutory Bodies
                as may be required from time to time, for the Client to be able to avail of any or
                all of the services provided by Muthoot Fincorp Ltd. under this arrangement or any other
                such services. The Client shall undertake not to hold Muthoot Fincorp Ltd. and /or
                companies /entities / subsidiaries /affiliates of Muthoot Fincorp Ltd. and / or their
                agents liable or responsible for use of the aforesaid information.{' '}
              </>
            </Li>
          </>
        </Ul>
        <JustifiedText>
          <>
            The Client expressly agree and acknowledge that any information contained in the Muthoot
            Fincorp&apos;s Brochures or other materials or otherwise communicated by Muthoot
            Fincorp shall not be constructed as investment advice and that all decisions to
            purchase or sell units/securities made by the client and shall be on the basis of own
            personal judgment arrived at after due consideration. Muthoot Fincorp Ltd. does not assure or
            promise any bonus, interest, dividend. guaranteed returns and profit. The Client
            undertakes to read all the relevant Offer Documents and addendums thereto and terms and
            conditions of all schemes of all mutual funds and other issues of securities before
            entering into any transactions and the Client agrees to abide by the terms, conditions,
            rules and regulations as applicable from time to time.{' '}
          </>
        </JustifiedText>
        <Ul>
          <>
            <Li>
              <>
                All decisions to invest in securities through Muthoot Fincorp Ltd. shall be at the sole and
                exclusive discretion of Clients. All risks and liabilities attendant to the
                transactions shall by borne by Clients and Muthoot Fincorp Ltd. shall not in any manner be
                liable to make good any loss sustained or suffered by Clients based on such
                decisions.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client acknowledges that Muthoot Fincorp Ltd. or its representatives shall not be under
                any obligation to provide him with any tax, legal, accounting, investment advice or
                advice regarding the suitability or profitability of investment of any kind nor does
                Muthoot Fincorp Ltd. or its representatives give any advice or offer any opinion with
                respect to the nature, potential value or suitability of any particular transaction
                or investment strategy.{' '}
              </>
            </Li>
            <Li>
              <>
                Muthoot Fincorp Ltd. may, from time to time and on Client&apos;s express request, refer
                them to independent third-parties, with whom Muthoot Fincorp Ltd. has referral
                /distribution agreements and for which it may receive a distribution / referral fee,
                for making investments in products or avail services offered by such third-parties
                solely and entirely at their own risk and liability. In this regard, Muthoot Fincorp Ltd.
                acts purely as a referral / distribution agent and all risks and liabilities
                incidental to such dealings between the Clients and third-parties shall be borne by
                Clients and Muthoot Fincorp Ltd. shall not in any manner be liable to make good any loss
                sustained or suffered by Clients based on such decisions.{' '}
              </>
            </Li>
            <Li>
              <>The Client(s) understand(s) that </>
            </Li>
            <Ol>
              <>
                <JustifiedText>
                  <>
                    a. All applications received by Muthoot Fincorp Ltd. are subject to the cut-off times
                    and Muthoot Fincorp Ltd. shall not be responsible for any delay or any losses, damages
                    or costs arising due to non-submission/delay in submission of the applications,
                    and{' '}
                  </>
                </JustifiedText>
                <JustifiedText>
                  <>
                    b. For operational reasons, Muthoot Fincorp Ltd. shall forward only those instructions
                    that are received prior to a cut off time, which may be earlier than the actual
                    cut-off time prescribed to Muthoot Fincorp Ltd. by third parties and Muthoot Fincorp Ltd.
                    shall not be liable for any losses, damages, costs or expenses arising out of
                    non-forwarding or delay in forwarding transaction instruction received after
                    such cut-off times.{' '}
                  </>
                </JustifiedText>
              </>
            </Ol>
            <Li>
              <>
                Neither Muthoot Fincorp Ltd., nor any of the Mutual Funds /nor the issuer shall be liable
                for any failure to perform its obligations, to the extent that such performance had
                been delayed, hindered or prevented by systems failures network errors, delay or
                loss of data due to the aforesaid, Acts of God, floods, epidemics, quarantine, riot
                or civil commotion and war.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client understands that direct investments in bonds /debentures /equity /equity
                related instruments /mutual fund /venture capital fund may carry significant
                liquidity, credit and default risk, pricing risk, including the possible loss of
                principal amount invested. Past results are not a guarantee of future performance;
                yield or performance fluctuates and may not be a reflection of past results. The
                Client shall make investments after carefully understanding the Investment products.
                Muthoot Fincorp Ltd. shall not be liable or held liable for any consequences thereof.{' '}
              </>
            </Li>
            <Li>
              <>
                The instructions with regard to the transactions may be given through by sending
                email from registered email ID or by making the call from registered telephone
                number/mobile number or any other reasonable mode as permitted by Muthoot Fincorp Ltd..{' '}
              </>
            </Li>
            <Li>
              <>
                Muthoot Fincorp Ltd. and its authorised officer(s) shall be entitled to rely on the
                communication sent through telephone, fax or electronic media as genuine and act in
                a bonafide manner on the instructions and confirmations contained therein without
                further verification. However, Muthoot Fincorp Ltd. may at its sole discretion refuse to
                act on such communication till the receipt of a confirmation in writing. Further the
                Client hereby undertakes that it is aware of the risks involved in using electronic
                modes of communication and agrees to not hold Muthoot Fincorp Ltd. liable/responsible in
                any way for any issues, consequences , loss, damages , liabilities that may arise on
                account of using such electronic modes of communication including emails, fax,
                telephones, websites etc.{' '}
              </>
            </Li>
            <Li>
              <>
                Muthoot Fincorp Ltd. shall not be liable for any loss or damage caused to Clients by
                reasons of
                <Ol>
                  <>
                    <JustifiedText>
                      <>
                        (a) any delay by Clients in providing Instructions or communication to Muthoot
                        Fincorp, and{' '}
                      </>
                    </JustifiedText>
                    <JustifiedText>
                      <>
                        (b) any error, default and failure by the Client in providing instructions
                        or communication to Muthoot Fincorp Ltd..{' '}
                      </>
                    </JustifiedText>
                  </>
                </Ol>
              </>
            </Li>
            <Li>
              <>
                If any communication is unclear, ambiguous or incomplete, in the sole and exclusive
                determination of Muthoot Fincorp Ltd., it shall not be obliged to act upon the same until
                clear and unambiguous communication to the satisfaction of Muthoot Fincorp Ltd. have been
                provided by Clients. All such actions shall be binding on Clients and Muthoot Fincorp Ltd.
                shall not incur any liability for any such act or omission.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client hereby undertakes to indemnify Muthoot Fincorp Ltd. for any losses , damage, or
                liabilities it may end up suffering on account of any usage of electronic mode of
                communication for or on behalf of the client including relying upon instructions
                received in such manner from the Client(s).{' '}
              </>
            </Li>
            <Li>
              <>
                Muthoot Fincorp Ltd. shall be entitled to record the phone conversations between Clients
                and Muthoot Fincorp Ltd. without further intimation to Clients.{' '}
              </>
            </Li>
            <Li>
              <>
                Deal Instructions for securities transactions, shall be delivered to Muthoot Fincorp Ltd.
                only during business hours of Muthoot Fincorp Ltd., i.e., at present between 9:00 to 3:30
                p.m. on a working day, and only on business days (which term shall mean a day on
                which financial and/or other securities and money markets located at the place where
                Muthoot Fincorp Ltd. branch rendering services is located are open for business and
                trading); all other communication may be delivered up to 5:30 pm on business days.
                For any communication or instructions received after the prescribed hours or on
                non-business days, Muthoot Fincorp Ltd. shall have the discretion to deem it to have been
                received on the next business day.{' '}
              </>
            </Li>
            <Typography sx={styles.header2}>Client Undertakings</Typography>
            <Li>
              <>
                Muthoot Fincorp Ltd. shall not be liable for any instructions executed or any orders placed
                prior to the receipt of any communication cancelling or superseding such earlier
                communication.{' '}
              </>
            </Li>
            <Li>
              <>
                Tax statements provided by Muthoot Fincorp Ltd. pertaining to transactions in Client
                portfolios are for guidance purposes only and shall not be used by Clients to
                compute their tax liabilities. Clients shall compute their tax liability based on
                information provided by Brokers, Mutual Funds, Depository Participants, Custodians,
                Portfolio Managers, etc., in consultation with their independent tax advisors, if
                any.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client understands and agrees that the mutual fund / RTA / issuer company /
                stock exchanges / may cancel, close or reject any contract suo-moto without giving
                any reason thereof. In the event of such cancellation, closure or rejection, Muthoot
                Fincorp shall be entitled to cancel relative contract(s) with the Client and the
                Client shall not raise any objection for it and hold Muthoot Fincorp Ltd. liable for it.{' '}
              </>
            </Li>
            <Li>
              <>
                Further to any change in the details of the client including but not limited to the
                Demat account, Bank Account, Address, registered contact email ID, shall be first
                intimated to Muthoot Fincorp Ltd. who may in turn liaise with the Issuer Company / Mutual
                Fund Asset Management Company or its respective registrar to update such changes.
                The Issuer may reject such requests and, in such event, Muthoot Fincorp Ltd. shall not be
                liable for any such rejection.{' '}
              </>
            </Li>
            <Li>
              <>
                In case of NRI client(s), Client(s) shall ensure that they are aware of and comply
                with the provisions of the exchange control and other applicable regulations that
                pertain to their investment In securities to the Indian capital markets. Muthoot
                Fincorp shall not be liable for loss or damage in any manner caused by or
                resulting from any non-compliance by Clients in this regard.{' '}
              </>
            </Li>
            <Li>
              <>
                In the event of any change in the status of the Clients, the Clients shall forthwith
                inform Muthoot Fincorp Ltd. of the same.{' '}
              </>
            </Li>
            <Li>
              <>
                Clients shall comply with all laws, regulations, procedures, practices and
                guidelines given by the competent authorities in so far as applicable to them,
                including but not limited to compliance with applicable provisions of the Foreign
                Accounts Tax Compliance Act (FATCA). Muthoot Fincorp Ltd. shall not be responsible or held
                liable for any breach by Clients of such laws, regulations, procedures, practices
                and guidelines.{' '}
              </>
            </Li>
            <Li>
              <>
                Communication in the form of application forms or letters of instruction for any
                transaction shall be completely filled and signed by Clients prior to their
                submission to Muthoot Fincorp Ltd.. Neither Muthoot Fincorp Ltd. nor its employees shall be
                responsible in any manner whatsoever, for any Incorrect information being submitted
                by Clients.{' '}
              </>
            </Li>
          </>
        </Ul>

        <JustifiedText>
          <>
            Blank or partially completed documents signed by Clients and handed over to any employee
            of Muthoot Fincorp Ltd. shall be solely at their own risk if they are not processed or misused
            in any manner and Clients shall not hold Muthoot Fincorp Ltd. liable or responsible in any
            manner whatsoever.{' '}
          </>
        </JustifiedText>
        <Ul>
          <>
            <Li>
              <>
                Unless otherwise specified by Clients in writing, Muthoot Fincorp Ltd. shall communicate
                With Clients through letters in physical delivered to their communication address;
                or through phone and fax to their phone and fax numbers; or through SMS to the
                mobile number; or through email to their email address.{' '}
              </>
            </Li>
            <Li>
              <>
                Portfolio statements provided by Muthoot Fincorp Ltd. are merely for reporting performance
                and other portfolio information in an aggregated manner. They do not purport to be
                statement of holdings or entitlement. The actual holdings are maintained by
                respective Mutual Funds, Registrars & Transfer Agents, Depository Participants,
                Custodians, Portfolio Managers, Alternative Investment Funds, etc. as the case may
                be. Holdings as reported by such said entities represent the actual holdings of
                Clients.{' '}
              </>
            </Li>
            <Li>
              <>
                Clients shall undertake to verify the correctness of each statement in relation
                their accounts or transactions and to notify Muthoot Fincorp Ltd., within 7 days of the
                receipt of the same, of any discrepancies, omissions or inaccuracies therein. If no
                objection is made within 7 days from the date of such statement, the same shall be
                conclusive evidence without any further proof that the correspondence is correct,
                and Muthoot Fincorp Ltd. shall be free from all claims in respect thereto.{' '}
              </>
            </Li>
          </>
        </Ul>

        <Typography sx={styles.header2}>Communication </Typography>
        <Ul>
          <>
            <Li>
              <>
                Muthoot Fincorp Ltd. shall provide its services on a best effort&apos;s basis. However,
                Muthoot Fincorp shall not be liable for any failure or for any loss, damage or other
                costs arising in any way out of:{' '}
              </>
            </Li>
            <Ol>
              <>
                <JustifiedText>
                  <>
                    a) System failure including failure of ancillary or associated systems, or
                    fluctuation of power, or other acts of God /force majeure;{' '}
                  </>
                </JustifiedText>
                <JustifiedText>
                  <>
                    b) Accident, neglect, misuse, errors, frauds on the part of the Client or any
                    agent of the Client or agents or any third party, or{' '}
                  </>
                </JustifiedText>
                <JustifiedText>
                  <>
                    c) Any incidental, special or consequential damages including without limitation
                    of loss of profit.{' '}
                  </>
                </JustifiedText>
              </>
            </Ol>
            <Li>
              <>
                Muthoot Fincorp Ltd. shall not be liable to Clients for loss caused to them arising out of
                any act or omission by Muthoot Fincorp Ltd., except when such loss anises due to gross
                negligence, wilful misfeasance in connection with the discharge of duties, or wilful
                default. Muthoot Fincorp Ltd. shall in no event be liable for the acts of other
                intermediaries, bankers, brokers, custodians, etc.{' '}
              </>
            </Li>
            <Li>
              <>
                Muthoot Fincorp Ltd. reserves the right to refuse to provide services or to require the
                Client(s) to close its Account with Muthoot Fincorp Ltd. without assigning any reason for
                such decisions.{' '}
              </>
            </Li>
          </>
        </Ul>
        <Typography sx={styles.header2}>Miscellaneous</Typography>
        <Ul>
          <>
            <Li>
              <>
                All services provided by Muthoot Fincorp Ltd. shall be governed by and constructed in
                accordance with the laws of India. Any dispute, controversy or claim between Muthoot
                Fincorp and the Clients shall be settled by arbitration to be held in Mumbai,
                unless otherwise specified in any Agreements executed with Muthoot Fincorp Ltd. in
                accordance with the provisions of The Arbitration and Conciliation Act, 1996 or any
                subsequent enactment. The language of Arbitration shall be English. The award of the
                arbitrator shall be final and binding.{' '}
              </>
            </Li>
          </>
        </Ul>
        <Typography sx={styles.header2}>Arbitration </Typography>
        <Ul>
          <>
            <Li>
              <>
                These Terms and Conditions, the construction and enforcement of Its terms shall be
                governed by and construed in all respect with the laws of India.{' '}
              </>
            </Li>
            <Li>
              <>
                The Client understand that all disputes and differences arising out of, under or in
                connection with these terms and conditions or anything done hereunder shall be
                within the exclusive jurisdiction only to the courts of Mumbai. Further these terms
                and conditions are subject to and shall be construed in accordance with the laws
                prevalent in India.{' '}
              </>
            </Li>
            <Li>
              <>
                If any term or condition herein is declared or becomes invalid or unenforceable in
                any jurisdiction such term and/or condition shall not become or be unimpaired
                invalid or unenforceable to any other jurisdiction and the remaining terms and
                conditions herein shall remain unimpaired and in full force and effect in all
                jurisdiction.
              </>
            </Li>
          </>
        </Ul>
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            sx={{
              color: 'common.white',
              minWidth: '200px',
              mt: 4,
              fontWeight: 600,
              lineHeight: 1.5,
            }}
            onClick={handleDialogClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export const TermsAndConditionsCard = ({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element => {
  const { params } = useRouteMatch();
  const { referenceId } = params as unknown as { referenceId: string };
  const { auth } = useSelector((store: RootStateType) => store);
  const displayCheckBox =
    auth.role === USER_ROLES.INVESTOR && application?.applicant1ReferenceId === referenceId;
  return displayCheckBox || application?.isDeclareTermsAndConditions ? (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Investor(s) Declaration
            </Typography>
          </Grid>
          <Gridstyles>
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
              {displayCheckBox ? (
                <MFCheckbox
                  name={`isDeclareTermsAndConditions`}
                  label={<TermsAndConditions />}
                  disabled={application?.isDeclareTermsAndConditions}
                />
              ) : (
                application?.isDeclareTermsAndConditions && <TermsAndConditions />
              )}
            </Grid>
          </Gridstyles>
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};
export default TermsAndConditions;

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import {
  DirectEquityHoldingDetailsType,
  DirectEquitySchemeTransaction,
} from '../../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../../DataTable';
import { formatLongDate } from '../../../utils/date';
import { Box } from '@mui/material';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { AmountUnit } from '../../commonComponents';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

const headers: ColumnType[] = [
  {
    header: 'Security Name',
    sortingKey: 'ProductName',
    valueGetter: (row: DirectEquitySchemeTransaction) => row?.ProductName || 'N/A',
  },
  {
    header: 'Transaction Type',
    sortingKey: 'TranType',
    valueGetter: (row: DirectEquitySchemeTransaction) => row?.TranType || 'N/A',
  },
  {
    header: 'Transaction Date',
    sortingKey: 'TranDt',
    valueGetter: (row: DirectEquitySchemeTransaction) => formatLongDate(row?.TranDt) || 'N/A',
  },
  {
    header: 'Price',
    sortingKey: 'NAV',
    valueGetter: (row: DirectEquitySchemeTransaction) => row?.NAV || 'N/A',
  },
  {
    header: 'Units',
    sortingKey: 'Units',
    valueGetter: (row: DirectEquitySchemeTransaction) => row?.Units || 'N/A',
  },
  {
    header: 'Transaction Amount',
    sortingKey: 'Amount',
    valueGetter: (row: DirectEquitySchemeTransaction, amountUnit?: number | undefined | null) =>
      row?.Amount || row.Amount === 0 ? formatToIndianCurrency(row.Amount, amountUnit) : 'N/A',
  },
];

function TransactionDetails({ investorId, selectedPortfolio }: { investorId: string; selectedPortfolio:number }): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] =
    useState<DirectEquityHoldingDetailsType | null>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setTransactionDetails(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'DIRECT EQUITIES',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              customers: investorId ? [investorId] : [],
            },
            required: ['transactionDetails'],
          })
        )) as unknown as DirectEquityHoldingDetailsType;
        if (!isComponentActive) {
          return;
        }
        setTransactionDetails(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedPortfolio]);
  return (
    <Box sx={{ maxHeight: '60vh' }}>
      <Box
        sx={{
          mb: 2
        }}>
          <AmountUnit />
          {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
        </Box>
      <DataTable
        isLoading={isLoading}
        tableHeader={headers}
        tableData={transactionDetails?.schemeTransactions}
        boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
        customEmptyMessage="No transactions undertaken in the last 30 days"
      />
    </Box>
  );
}

export default TransactionDetails;

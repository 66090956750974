import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    LinearProgress,
    Menu,
    MenuItem,
    Modal,
    Skeleton,
    Tab,
    Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { GetAccountType, GetAssetClassesType, GetCategoryAverage, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, OrdersTypes, PortfolioType, RecommendationsType, SchemeList, getOrdersType, getPortfolioType, } from '../../../redux-store/types/api-types';
import { getOrders, getPortfolio, getRmDetails, get_account_type, get_assest_classes, get_category_average, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav } from '../../../redux-store/actions/userManagement';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Filters, { filterDataOrders } from './filterComponent';
import { formatTime, formatToIndianCurrency, formatDate_ } from '../../../utils/utilityFunctions';
import { OrderDetailsModal } from './investor-transaction-helper';

export const trTypeMapping: Record<string, string> = {
    "New": "New Purchase",
    "Add": "Add Purchase",
    "SWP": "SWP",
    "STP": "STP",
    "XSIP": "Add Purchase",
    "ISIP": "New Purchase",
    "Swt": "Switch",
    "Red": "Redeem"
}

export const trTypeToSchemeTypeMapping: Record<string, string> = {
    "New": "Lumpsum",
    "Add": "Lumpsum",
    "SWP": "SWP",
    "STP": "STP",
    "XSIP": "SIP",
    "ISIP": "SIP",
    "Swt": "Switch",
    "Red": "Redeem"
}

export default function Orders({
    location
}: {
    location: Location<{ investorId: string; investorName: string }>
}): JSX.Element {

    const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Orders');
    const [ordersData, setOrdersData] = useState<getOrdersType | null>(null);
    const [isLoadingOrders, setLoadingOrders] = useState(true);
    const [currentOrderDetail, setCurrentOrderDetail] = useState<OrdersTypes>();
    const [value, setValue] = useState<string>("Recommended");
    const [viewOrderDetails, setViewOrderDetails] = useState<boolean>(false);
    const [accountType, setAccountType] = useState<GetAccountType | null>();
    const [rmId, setRmId] = useState<number | null>();
    const [search, setSearch] = useState<string>('');
    const [AMCList, setAMCList] = useState<{ AMC: string, AMCId: number }[]>([]);
    const [filterData, setFilterData] = useState<filterDataOrders>({
        orderStatus: [],
        orderType: [],
        date: { FromDate: "", ToDate: "" },
    });
    const [filteredSchemeList, setFilteredSchemeList] = useState<OrdersTypes[] | undefined>(ordersData?.ordersData[0]);
    const [openModal, setOpenModal] = useState(false);


    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);


    //function to handle selecting Explore/Recommended/Holdings/Orders
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };


    //api call for getting rmid
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);

            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    //api call for getaccount type
    useEffect(() => {

        let isComponentActive = true;
        setAccountType(null);
        (async function () {
            try {
                const response = (await dispatch(
                    get_account_type({
                        rmID: rmId,
                        ClientCode: investorId
                    })
                )) as unknown as GetAccountType;
                if (!isComponentActive) {
                    return;
                }
                setAccountType(response);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    // api call for Orders
    useEffect(() => {

        let isComponentActive = true;

        setOrdersData(null);
        if (!rmId || !investorId) {
            console.log("RM ID or Investor ID is missing");
            return;
        }
        (async function () {
            try {
                setLoadingOrders(true);
                const response = (await dispatch(
                    getOrders({
                        rmID: rmId,
                        ClientCode: investorId
                    })
                )) as unknown as getOrdersType;
                if (!isComponentActive) {
                    return;
                }
                setOrdersData(response);
                setFilteredSchemeList(response?.ordersData[0]);
                setLoadingOrders(false);
            } catch (e) {
                setLoadingOrders(false);
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };

    }, [dispatch, rmId, investorId]);

    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Orders
        </Typography>,
    ];

    //function to handle change of Recommended and direct tab
    const handleChangeTabs = (event: any, value: any) => {
        setValue(value);

        if (value === "Recommended")
            setFilteredSchemeList(ordersData?.ordersData[0])
        else if (value === "Direct")
            setFilteredSchemeList(ordersData?.ordersData[1])
    };

    const open = Boolean(anchorEl);

    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const groupOrdersByDate = (data: OrdersTypes[] | undefined) => {
        const groupedOrders: Record<string, OrdersTypes[]> = {};

        if (data) {
            data.forEach((order) => {
                const entryDate = order.EntryDate.split('T')[0];
                groupedOrders[entryDate] = groupedOrders[entryDate] || [];
                groupedOrders[entryDate].push(order);
            });
        }

        const formattedOrders = Object.entries(groupedOrders).map(([date, orders]) => {
            return {
                date: date, // You can format the date here if needed
                orders: orders.map((order) => ({
                    ...order,
                })).sort((a, b) => new Date(b.EntryDate).getTime() - new Date(a.EntryDate).getTime())
            };
        }).sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        return formattedOrders;
    }

    //Getting Unique AMC names for filter
    useEffect(() => {
        const uniqueAMCIds = new Set<number>();
        const AmcList: { AMC: string, AMCId: number }[] = [];
        ordersData?.ordersData[0]?.forEach((value: OrdersTypes) => {
            if (!uniqueAMCIds.has(value.AmcID)) {
                AmcList.push({ AMC: value.AMC, AMCId: value.AmcID });
                uniqueAMCIds.add(value.AmcID);
            }
        });
        setAMCList(AmcList.sort((a: any, b: any) => a.AMC.toLowerCase().localeCompare(b.AMC.toLowerCase())));
    }, [ordersData])

    //Set Filtered Data
    useEffect(() => {
        const OrdersInitialData = value === "Recommended" ? ordersData?.ordersData[0] : ordersData?.ordersData[1];
        if (filterData.orderStatus.length == 0 && filterData.orderType.length == 0 && filterData.date.FromDate == "" && filterData.date.ToDate == "") {
            setFilteredSchemeList(OrdersInitialData);
        }
        else {
            // orderStatus Filter Logic
            let filteredList: OrdersTypes[] | undefined = OrdersInitialData;
            if (filterData.orderStatus.length !== 0) {
                filteredList = OrdersInitialData?.filter((scheme: OrdersTypes) =>
                    filterData.orderStatus.some((selectedStatus) => selectedStatus === scheme.bseOrderStatus)
                )
                setFilteredSchemeList(filteredList);
            }

            // orderType Filter Logic
            let filteredList2: OrdersTypes[] | undefined = filteredList;
            if (filterData.orderType.length !== 0) {
                filteredList2 = filteredList?.filter((scheme: OrdersTypes) =>
                    filterData.orderType.some((selectedType) => selectedType === scheme.TRTYPE)
                )
                setFilteredSchemeList(filteredList2);
            }

            let filteredList3: OrdersTypes[] | undefined = filteredList2;
         
            if (filterData.date.FromDate !== "" && filterData.date.ToDate !== "") {
                filteredList3 = filteredList2?.filter((scheme: OrdersTypes) => {
                    return (
                        scheme.EntryDate !== undefined && new Date(scheme.EntryDate) >= new Date(new Date(filterData.date.FromDate).getTime() + 60 * 60 * 5.5 * 1000) && new Date(scheme.EntryDate) <= new Date(new Date(filterData.date.ToDate).getTime() + 60 * 60 * 29.5 * 1000))
                });
                setFilteredSchemeList(filteredList3);
            }
        }
    }, [filterData, search, value])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "400",
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: "24",
        p: "4",
        padding: "2rem 6rem",
    }

    return (
        <div>

            <CommonLayout>
                <>
                    {/* Menu to select Explore/Recommendation/Holdings/Order */}
                    {location.pathname === "/investor-transactions" || location.pathname === "/recommended-schemes" || location.pathname === "/holdings" || location.pathname === "/orders" ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                            <Button
                                sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick_}
                            >
                                {selectedValue}
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                            <Menu
                                sx={{ cursor: "pointer" }}
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendations</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                            </Menu>
                        </Box> : <></>}

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>

                    {/* Main Card */}
                    {(isLoadingOrders) ? (
                        <LinearProgress sx={{ mt: 2.5 }} />
                    ) :
                        (<Box
                            sx={{
                                bgcolor: 'common.white',
                                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                                borderRadius: '5px',
                                py: 2,
                            }}>

                            {/* Displays scheme detail */}
                            {viewOrderDetails ?
                                //scheme detail
                                <div>

                                </div>

                                :
                                //Order List
                                <Box >
                                    <TabContext value={value} >
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: '100%',
                                            '& .MuiTabPanel-root': { py: 2, px: 0 },
                                            '& .MuiTab-root': {
                                                color: '#A1A2A2',
                                                opacity: 0.8,
                                                fontSize: 17,
                                                lineHeight: '24px',
                                                textTransform: 'capitalize',
                                                px: { xs: 2, md: 3, lg: 5 },
                                                mx: 2,
                                                '&.Mui-selected': {
                                                    color: '#4B81B1',
                                                },
                                            },
                                            '& .MuiTabs-indicator': {
                                                height: 3,
                                                background: '#4B81B1',
                                            },
                                        }}>
                                            <TabList onChange={handleChangeTabs}>
                                                {["Recommended", "Direct"].map((ele, index) => {
                                                    return (<Tab label={ele} value={ele} key={index} sx={{ cursor: "pointer" }} />)
                                                })}

                                            </TabList>
                                            <Box sx={{ marginRight: '1.5rem' }}>
                                                <Filters
                                                    AMCList={AMCList}
                                                    filterData={filterData}
                                                    setFilterData={setFilterData}
                                                    accountName={accountType?.account_type}
                                                />
                                            </Box>
                                        </Box>

                                        <Divider />

                                        <Box>
                                            {["Recommended", "Direct"].map((item: any, index: any) => {
                                                return (

                                                    <TabPanel value={item} key={index}>
                                                        {/* {isLoadingSubAsset ? <Skeleton /> : <> */}

                                                        {filteredSchemeList?.filter((scheme: OrdersTypes) => {
                                                            if (value === "Recommended")
                                                                return scheme.TYPE === "Recommendations";
                                                            else if (value === "Direct")
                                                                return scheme.TYPE === "Direct";
                                                        }
                                                        ).length === 0 ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                            <Typography sx={{ fontSize: 18, color: '#5a7c82', mt: 2 }}>No Orders available</Typography>
                                                        </Box> : <>
                                                            {
                                                                groupOrdersByDate(filteredSchemeList?.filter((scheme: OrdersTypes) => {
                                                                    if (value === "Recommended")
                                                                        return scheme.TYPE === "Recommendations";
                                                                    else if (value === "Direct")
                                                                        return scheme.TYPE === "Direct";
                                                                })) // Filter based on Direct/Recommended orders
                                                                    .map((ele: { date: string, orders: OrdersTypes[] }, index: number) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                            // style={{ cursor: "pointer" }}
                                                                            // onClick={handleClick(ele, index)}
                                                                            >
                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', my: 2.5 }}>
                                                                                    <Typography sx={{ color: '#4B81B1', fontWeight: 500 }}>{formatDate_(ele.date)}</Typography>

                                                                                    <Box sx={{ width: '100%', mt: '1rem', mb: '0.7rem', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                                                                                        {/* Each Order Box */}
                                                                                        {ele.orders.map((order: OrdersTypes, index: number) => {
                                                                                            return <Box
                                                                                                key={index}
                                                                                                sx={{ display: 'flex', flexDirection: 'column', boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px', width: '25%', borderRadius: '8px', mx: "1.5rem", my: "1rem", cursor: 'pointer' }}
                                                                                                onClick={() => { handleModalOpen(), setCurrentOrderDetail(order) }}>
                                                                                                {/* Head */}
                                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', px: "0.8rem", py: '0.4rem' }}>
                                                                                                    <Typography sx={{ color: '#4B81B1', fontWeight: 500, fontSize: '0.8rem' }}>{trTypeMapping[order.TRTYPE]}
                                                                                                        <span style={{ fontSize: "0.7rem", fontWeight: 600, color: "#293139", marginLeft: "8px" }}>{order.TRTYPE === "New" || order.TRTYPE === "Add" ? "Lumpsum" : order.TRTYPE === "XSIP" || order.TRTYPE === "ISIP" ? "SIP" : ""}</span>
                                                                                                    </Typography>
                                                                                                    <span style={{ width: "8px", height: "8px", borderRadius: '50%', backgroundColor: `${order.bseOrderStatus == "Success" ? "green" : "#cb1919"}` }}></span>
                                                                                                </Box>
                                                                                                <Divider />
                                                                                                {/* Body */}
                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', px: "0.8rem" }}>
                                                                                                    <Typography sx={{ textAlign: 'center', fontWeight: 600, fontSize: '0.8rem', my: "0.5rem" }}>{order.SchemeName}</Typography>
                                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                                                                                        <Typography sx={{ textAlign: 'start', fontSize: '0.77rem', color: '#677885', fontWeight: 500 }}>{order.InvestmentAmount != null ? formatToIndianCurrency(order.InvestmentAmount) : ""}</Typography>
                                                                                                        <Typography sx={{ textAlign: 'end', fontSize: '0.77rem', color: '#677885', fontWeight: 500 }}>{order.Units != null ? `${order.Units} Units` : ""}</Typography>
                                                                                                    </Box>
                                                                                                    {order.RMIFlag === 1 || order.RMIFlag === 2 ? <Typography sx={{ fontSize: '0.75rem', my: "0.5rem", color: '#4B81B1', fontWeight: 500 }}>RM initiated</Typography> : ''}
                                                                                                    <Typography sx={{ fontSize: '0.75rem', my: "0.5rem", color: '#b3b3b3', fontWeight: 500 }}>{formatTime(order.EntryDate)}</Typography>
                                                                                                </Box>
                                                                                            </Box>
                                                                                        })}
                                                                                    </Box>
                                                                                </Box>

                                                                                <Divider />
                                                                            </div>
                                                                        )
                                                                    }

                                                                    )}</>}
                                                    </TabPanel>

                                                )
                                            })}
                                        </Box>

                                    </TabContext>
                                </Box>
                            }

                        </Box>)
                    }

                    <Modal
                        open={openModal}
                    // onClose={handleClose}
                    >
                        {currentOrderDetail != undefined ?
                            <OrderDetailsModal
                                closeModal={handleModalClose}
                                schemeDetail={currentOrderDetail}
                            /> :
                            <></>
                        }
                    </Modal>

                </>

            </CommonLayout>

        </div>
    );
}

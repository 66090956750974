import {
  Box,
  Button,
  CardMedia,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router';
import { CommonLayout } from '../commonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import DashboardAndApplicationsList from './applicationslist';
import { useEffect, useState } from 'react';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { getAllApplications } from '../../redux-store/actions/application';
import { GetAllApplicantionsResponseBody } from '../../redux-store/types/application';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SearchIcon from '@mui/icons-material/Search';

export default function InvestorDetails(): JSX.Element {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [applicationsList, setApplicationsList] = useState<ApplicationProps[]>([]);
  const dispatch = useDispatch();
  useEffect(() => {
    let componentIsAlive = true;
    setLoading(true);
    setApplicationsList([]);
    (async function () {
      try {
        const response = (await dispatch(
          getAllApplications({
            page: 1,
            limit: 4,
          })
        )) as unknown as GetAllApplicantionsResponseBody;
        const { applications } = response || {};
        setLoading(false);
        if (!componentIsAlive) {
          return;
        }
        setApplicationsList(applications);
      } catch (e) {
        setLoading(false);
        console.error((e as Error).message);
      }
    })();
    return () => {
      componentIsAlive = false;
    };
  }, []);

  const [age, setAge] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };

  return (
    <CommonLayout>
      <>
        <Typography
          sx={{
            fontFamily: 'Poppins, sans-serif',
            fontSize: 18,
            fontWeight: 500,
            mb: { xs: 2, md: 4 },
            p: { xs: '5px 15px', md: '' },
            bgcolor: '#e5f1f8',
            borderRadius: '10px',
            display: 'flex',
            alignItems: 'center',
          }}>
          <IconButton aria-label="back" sx={{ p: 0 }} onClick={() => history.goBack()}>
            <ChevronLeftIcon />
          </IconButton>
          Investor Details
        </Typography>
        <Box
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 6px 18px rgba(0, 0, 0, 0.06)',
            borderRadius: '10px',
            p: 2.5,
            display: { xs: 'block', sm: 'flex' },
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            mb: 5,
          }}>
          <FormControl
            sx={{ mr: 3, minWidth: { xs: '100%', sm: '30%', md: 175 }, mb: { xs: 2, md: 0 } }}>
            <Typography sx={{ mb: 1, fontSize: 14, color: '#293139', opacity: 0.7 }}>
              Investment Horizon
            </Typography>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              sx={{ '& .MuiSelect-select': { py: 1.3 } }}>
              <MenuItem value="">Select</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{ mr: 3, minWidth: { xs: '100%', sm: '30%', md: 175 }, mb: { xs: 2, md: 0 } }}>
            <Typography sx={{ mb: 1, fontSize: 14, color: '#293139', opacity: 0.7 }}>
              Asset Size
            </Typography>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              sx={{ '& .MuiSelect-select': { py: 1.3 } }}>
              <MenuItem value="">Select</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{ mr: 3, minWidth: { xs: '100%', sm: '30%', md: 175 }, mb: { xs: 2, md: 0 } }}>
            <Typography sx={{ mb: 1, fontSize: 14, color: '#293139', opacity: 0.7 }}>
              Fixed Income Maturity
            </Typography>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              sx={{ '& .MuiSelect-select': { py: 1.3 } }}>
              <MenuItem value="">Select</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            sx={{ mr: 3, minWidth: { xs: '100%', sm: '30%', md: 175 }, mb: { xs: 2, md: 0 } }}>
            <Typography sx={{ mb: 1, fontSize: 14, color: '#293139', opacity: 0.7 }}>
              Credit Profile
            </Typography>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              sx={{ '& .MuiSelect-select': { py: 1.3 } }}>
              <MenuItem value="">Select</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            sx={{ height: 44, width: 130, fontSize: 14, mb: { xs: 2, md: 0 } }}>
            Apply
          </Button>
        </Box>
        <Grid container sx={{ mb: 5 }} spacing={{ xs: 2, md: 4 }}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: { xs: 'block', sm: 'flex' },
                alignItems: 'center',
                gap: 2,
                background: '#FFFFFF',
                borderLeft: ' 2px solid #D7EAF1',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
                borderRadius: '10px',
                py: 1.5,
                px: 2,
                mb: { xs: 1, md: 0 },
              }}>
              <Box
                sx={{
                  background: 'rgba(247, 250, 253,0.5)',
                  borderRadius: '5px',
                  padding: '14px 18px',
                  width: { xs: '100%', md: '50%' },
                  mb: { xs: 1, md: 0 },
                }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: '22px',
                    color: '#293139',
                    opacity: 0.7,
                    mb: 2.5,
                  }}>
                  Total Investors
                </Typography>
                <Typography
                  sx={{
                    color: '#11263C',
                    fontSize: 26,
                    lineHeight: '22px',
                    fontWeight: 600,
                    mb: 0,
                  }}>
                  153
                </Typography>
              </Box>
              <Box
                sx={{
                  background: '#FFF3EF',
                  width: { xs: '100%', md: '50%' },
                  borderRadius: '5px',
                  '& .MuiBox-root': {
                    borderBottom: '1px solid rgba(131, 146, 153,0.08)',
                    '&:last-child': { borderBottom: 0 },
                  },
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    Individual Investor
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    53
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    NRI’s
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    45
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    Non Individual
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    55
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: 'block', sm: 'flex' },
                alignItems: 'center',
                gap: 2,
                background: '#FFFFFF',
                borderLeft: ' 2px solid #D7EAF1',
                boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.08)',
                borderRadius: '10px',
                py: 1.5,
                px: 2,
              }}>
              <Box
                sx={{
                  background: 'rgba(247, 250, 253,0.5)',
                  borderRadius: '5px',
                  padding: '14px 18px',
                  width: { xs: '100%', md: '50%' },
                  mb: { xs: 1, md: 0 },
                }}>
                <Typography
                  sx={{
                    fontSize: 16,
                    lineHeight: '22px',
                    color: '#293139',
                    opacity: 0.7,
                    mb: 2.5,
                  }}>
                  Total Investors
                </Typography>
                <Typography
                  sx={{
                    color: '#11263C',
                    fontSize: 26,
                    lineHeight: '22px',
                    fontWeight: 600,
                    mb: 0,
                  }}>
                  153
                </Typography>
              </Box>
              <Box
                sx={{
                  background: '#FFF3EF',
                  width: { xs: '100%', md: '50%' },
                  borderRadius: '5px',
                  '& .MuiBox-root': {
                    borderBottom: '1px solid rgba(131, 146, 153,0.08)',
                    '&:last-child': { borderBottom: 0 },
                  },
                }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    Individual Investor
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    53
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    NRI’s
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    45
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 0.5, px: 2 }}>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      width: '70%',
                    }}>
                    Non Individual
                  </Typography>
                  <Typography
                    sx={{
                      color: '#293139',
                      opacity: 0.7,
                      fontSize: 14,
                      lineHeight: '22px',
                      fontWeight: 600,
                      width: '30%',
                    }}>
                    55
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box sx={{ background: '#fff', borderRadius: '10px' }}>
              <CardMedia
                sx={{ height: 265, objectFit: 'contain' }}
                component="img"
                src="/images/risk-meter.svg"
                alt="image"
              />
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: 1.5,
            }}>
            <Typography sx={{ fontSize: 16, fontWeight: 600, color: 'primary.main' }}>
              Clients
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 } }}>
              <FormControl sx={{ width: 250, mr: 2, background: '#fff' }} variant="outlined">
                <OutlinedInput
                  sx={{ '& .MuiOutlinedInput-input': { py: 1, fontSize: 13 } }}
                  id="search-field"
                  placeholder="Search by name"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ maxWidth: { lg: '100%' } }}>
            <DashboardAndApplicationsList
              isLoading={isLoading}
              applicationsList={applicationsList}
              boxStyles={{ minHeight: '350px', minWidth: 450 }}
              DashboardApplicationsList={true}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', pt: 5 }}>
            {/* <IconButton to={{ pathname: '/applications' }} component={RouterLink}> */}
            <Button
              variant="contained"
              sx={{
                maxWidth: 20,
                maxHeight: 30,
                minWidth: 200,
                minHeight: 40,
              }}
              onClick={() => history.push('/applications')}>
              View More
            </Button>
            {/* </IconButton> */}
          </Box>
        </Box>
      </>
    </CommonLayout>
  );
}

import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
  Chip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles, Item, ItemDetails } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/personalDetails';
import { RmsType } from '../../redux-store/types/api-types';
import { getRMById } from '../../redux-store/actions/userManagement';
import { Location } from 'history';
import { RoleTypes } from '../../utils/constant';
import { LocationType, mdmsLocationList } from '../../redux-store/types/mdms';
import { getLocationList, getRMsList } from '../../redux-store/actions';
import { rmRoleTypeService } from '../../utils/utilityFunctions';

export default function RMDetails({
  location,
}: {
  location: Location<{ rmId: number }>;
}): JSX.Element {
  const { rmId: id } = location.state || { rmId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [rmDetails, setRMDetails] = useState<RmsType>();
  const [locationMaster, setLocationMaster] = useState<mdmsLocationList[]>([]);
  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        const locationmaster = (await dispatch(getLocationList())) as unknown as LocationType;
        setLocationMaster(locationmaster.rmLocationList);
        setLoading(true);
        if (id) {
          const getRmDetails = (await dispatch(getRMById(Number(id)))) as unknown as RmsType;
          setRMDetails(getRmDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('relationship-managers')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('relationship-managers')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              RM Details
            </Typography>
          </Box>
          <IconButton>
            <CardMedia
              component="img"
              src="/images/edit-icon-outlined.svg"
              alt="Edit Icon"
              sx={{ width: 'unset' }}
              onClick={() => history.push('edit-rm-details', { rmId: rmDetails?.id })}
            />
          </IconButton>
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          rmDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'RM Name'} description={rmDetails.name} />
                    <KeyValue title={'Email Id'} description={rmDetails.user.email} />
                    <KeyValue title={'Phone Number'} description={rmDetails.user.phone} />
                    <KeyValue
                      title={'Role Type'}
                      description={RoleTypes[rmDetails?.roleType || '']}
                    />
                    <KeyValue
                      title={'Location'}
                      description={
                        locationMaster.find((loc) => loc.key === rmDetails?.location)?.value
                      }
                    />
                    <KeyValue title={'Pan'} description={rmDetails.panNumber} />
                    <KeyValue title={'Emp Code'} description={rmDetails.empCode} />
                    <KeyValue title={'Iwapp Rm Id'} description={rmDetails.iwappRmId} />
                    <KeyValue title={'Pincode'} description={rmDetails.pincode} />
                    <KeyValue title={'Building Number'} description={rmDetails.buildingNo} />
                    <KeyValue title={'Street Name'} description={rmDetails.streetName} />
                    <KeyValue title={'State'} description={rmDetails.state} />
                    <KeyValue title={'City'} description={rmDetails.city} />
                    <KeyValue title={'Country'} description={rmDetails.country} />
                    {rmRoleTypeService(rmDetails?.roleType || '') && (
                      <Grid item xs={12}>
                        <Item>{`Applicable AMC's RM`}</Item>
                        {rmDetails?.amcRmDetails &&
                        rmDetails?.amcRmDetails?.amcRmIds?.length > 0 ? (
                          <Box
                            sx={{
                              border: 0.5,
                              // borderColor: 'rgba(41, 49, 57, 0.7)',
                              borderColor: 'primary.main',
                              p: 3,
                              mb: 3,
                              mt: 2,
                              borderRadius: 0.5,
                            }}>
                            {rmDetails?.amcRmDetails?.amcRms?.map((amcRm) => {
                              return (
                                <React.Fragment key={amcRm.id}>
                                  <Chip
                                    label={amcRm.name}
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 500,
                                      color: 'rgba(41, 49, 57, 0.7)',
                                      // p: 2,
                                      m: 0.8,
                                    }}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Box>
                        ) : (
                          <ItemDetails>-</ItemDetails>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}

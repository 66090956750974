import { Box, Button, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { Formik, useFormikContext } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  Distributor,
  getLocationList,
  getRMsList,
  SubDistributor,
} from '../../redux-store/actions';
import React, { useEffect, useState } from 'react';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL, USER_ROLES } from '../../utils/constant';
import { ProceedSaveLater } from './components';
import {
  applicationComparison,
  applyRoleBasedStatus,
  saveForLater,
} from '../../utils/utilityFunctions';
import { RMType, SupportRmProp } from '../../redux-store/types/api-types';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { distributorsValidationSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import { LocationType, mdmsLocationList } from '../../redux-store/types/mdms';
import { showError } from '../../redux-store/actions/auth';
import { getRMById } from '../../redux-store/actions/userManagement';

type DistributorDetailsProps = {
  firstName: string;
  phone: any;
  email: string;
  saveType: string;
  rm_id: number | null | undefined;
  location: string | null;
};

function GridItem({ children }: { children: React.ReactNode }) {
  return (
    <Grid item xs={12} sm={6}>
      {children}
    </Grid>
  );
}

const RMDetail = ({
  isSupportRM,
  rmsList,
  values,
  locationMaster,
}: {
  isSupportRM: boolean;
  rmsList: any;
  values: any;
  locationMaster: mdmsLocationList[];
}) => {
  const rmsListSorting = [...rmsList].sort((a, b) => (a.name > b.name ? 1 : -1));
  const selectedRm = rmsList.find((item: SupportRmProp) => item.id === values.rm_id);
  return (
    <>
      <GridItem>
        <MFTextField
          name="firstName"
          label={isSupportRM ? "Service RM's Name" : "RM's Name"}
          placeholder="Enter RM name"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="email"
          label={isSupportRM ? "Service RM's Email Address" : "RM's Email Address"}
          placeholder="Enter RM Email ID"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFTextField
          name="phone"
          label={isSupportRM ? "Service RM's Mobile Number" : "RM's Mobile Number"}
          placeholder="Enter Mobile Number"
          disabled={true}
        />
      </GridItem>
      <GridItem>
        <MFSelectField
          name={`location`}
          label={'Location'}
          items={locationMaster.map((location) => ({
            key: location.value,
            value: location.key,
          }))}
          disabled
        />
      </GridItem>
      {!rmsList.length && isSupportRM && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            mt: 10,
          }}>
          <Typography
            sx={{
              fontSize: 18,
              fontWeight: 500,
              color: 'rgb(97, 209, 214)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: '7%',
            }}>
            {`AMC's RM is Not Available`}
          </Typography>
        </Box>
      )}
      {isSupportRM && rmsList?.length > 0 && (
        <>
          <GridItem>
            <SearchableSelect
              name="rm_id"
              label="RM's Name *"
              items={rmsListSorting.sort().map((item: SupportRmProp) => ({
                key: item.name,
                value: item.id,
              }))}
              searchFieldPlaceholder={'Search RM'}
            />
          </GridItem>
          {values.rm_id && (
            <>
              <GridItem>
                <MFTextField
                  name="amcEmail"
                  label="RM's Email ID"
                  placeholder="Enter RM's Email ID"
                  value={selectedRm?.user?.email}
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFTextField
                  name="amcMobile"
                  label="RM's Mobile Number"
                  placeholder="Enter RM's Mobile Number"
                  value={selectedRm?.user?.phone}
                  disabled={true}
                />
              </GridItem>
              <GridItem>
                <MFSelectField
                  name={`amcLocation`}
                  label="Location"
                  items={locationMaster.map((location) => ({
                    key: location.value,
                    value: location.key,
                  }))}
                  value={selectedRm?.location}
                  disabled={true}
                />
              </GridItem>
            </>
          )}
        </>
      )}
    </>
  );
};

export default function DistributorDetails(): JSX.Element {
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role } = useSelector((store: RootStateType) => store.auth);
  const { rm, rm_id, supportRm, support_rm_id } = application || {};
  const { auth } = useSelector((store: RootStateType) => store);
  const isSupportRM = auth.id === application?.supportRm?.userId;

  const {
    user: { email: RMEmail = '', firstName: RMFirstName = '', phone, countryCode },
    location: RMLocation = '',
  } = (isSupportRM ? supportRm : rm) || { empCode: '', user: { email: '' } };

  const initialValues: DistributorDetailsProps = {
    email: RMEmail,
    firstName: RMFirstName,
    phone: countryCode ? countryCode + ' ' + phone : phone,
    saveType: 'save and proceed',
    rm_id,
    location: RMLocation,
  };
  const [rmsList, setRmList] = useState<RMType[]>([]);
  const [locationMaster, setLocationMaster] = useState<mdmsLocationList[]>([]);
  useEffect(() => {
    (async function () {
      try {
        let updateRMList: RMType[] = [];
        const locations = (await dispatch(getLocationList())) as unknown as LocationType;
        setLocationMaster(locations.rmLocationList);
        if (isSupportRM) {
          const listedRM = ((await dispatch(getRMById(Number(support_rm_id)))) as unknown as RMType)
            ?.amcRmDetails;
          updateRMList =
            application?.rm_id &&
            (!application.rm.isActive || !listedRM?.amcRmIds.includes(`${application?.rm_id}`))
              ? [...(listedRM?.amcRms || []), application?.rm]
              : listedRM?.amcRms || [];
        }
        setRmList(updateRMList);
      } catch (e: unknown) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);
  const onSubmit = async (values: DistributorDetailsProps) => {
    try {
      if (!rmsList.length && isSupportRM) {
        throw `AMC's RM is Not Available`;
      }
      const { saveType } = values;
      const { id, applicant1ReferenceId, currentStep = null, status, hasPOA } = application || {};
      const checkApplication = applicationComparison(application, {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...application!,
        currentStep: currentStep === null ? 1 : currentStep,
        rm_id: values.rm_id,
      });
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              ...application!,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 1,
              rm_id: values.rm_id,
              //currentStep === null ? 1 : currentStep
              // filledBy,
            },
            applicationId: id,
          })
        );

        saveType === 'save and proceed'
          ? history.push('personal-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        saveType === 'save and proceed'
          ? history.push('personal-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
      saveType === 'save and proceed'
        ? history.push('personal-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } catch (e) {
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={distributorsValidationSchema(isSupportRM, rmsList)}
      onSubmit={onSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container rowSpacing={2} columnSpacing={5}>
            <RMDetail
              isSupportRM={isSupportRM}
              rmsList={rmsList}
              values={values}
              locationMaster={locationMaster}
            />
          </Grid>
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={false}
            clickedButton={values.saveType}
          />
        </Box>
      )}
    </Formik>
  );
}

/* eslint-disable @typescript-eslint/no-empty-function */
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Box,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  Link,
  Popover,
  Skeleton,
  Stack,
  Switch,
  Typography,
  Button,
} from '@mui/material';
import { SxProps } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { getRMDashBoardMetrics } from '../../redux-store/actions/userManagement';
import { RootStateType } from '../../redux-store/reducers';
import {
  AssetAllocationEntity,
  ProductAllocationEntity,
  RmDashboardType,
} from '../../redux-store/types/api-types';
import { AmountUnit, CommonLayout, CopyGraphDropdown, PopoverValue } from '../commonComponents';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import {
  FlipIcon,
  NetSalesDashboardIcon,
  TotalRMsDashboardIcon,
  TotalRevenueDashboardIcon,
} from '../customSVGs';
import { preventOverflow } from '@popperjs/core';
import { useReactToPrint } from 'react-to-print';
import ReactCardFlip from 'react-card-flip';
import { ColumnType, DataTable } from '../DataTable';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export const scrollbarView = {
  '::-webkit-scrollbar': {
    width: '16px',
    background: 'white',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#4990F0',
    borderRadius: '10px',
    width: '16px',
    boxShadow: 'none',
  },
  '::-webkit-scrollbar-track': {
    background: '#D6E7FE',
    borderRadius: '10px',
    borderRight: '7px solid transparent',
    borderLeft: '7px solid transparent',
    backgroundClip: 'padding-box',
    width: '2px',
  },
};
export function InfoCard({
  label,
  value,
  onClick,
  isLoading,
  sx,
  color = '#1473CC',
  icon,
}: {
  label: string;
  value: string | number;
  color?: string;
  onClick?: () => void;
  isLoading?: boolean;
  sx?: SxProps;
  icon?: JSX.Element | React.ReactNode;
}): JSX.Element {
  return (
    <Box sx={{ width: ['45%', '30%', 'calc(100% / 3 - 10px)', 'calc(100% / 3 - 15px)'] }}>
      <Link
        onClick={onClick}
        sx={{
          pointerEvents: isLoading ? 'none' : 'unset',
          cursor: onClick ? 'pointer' : 'unset',
          color: '#fff',
          display: 'block',
          textDecoration: 'none',
          py: 3,
          px: 2,
          background: '#FFFFFF',
          borderTop: `4px solid`,
          borderColor: color,
          boxShadow: '0px 2px 10px rgb(0 0 0 / 8%)',
          borderRadius: '10px',
          ...sx,
          '&:hover,&:focus': {
            boxShadow: onClick ? '0px 2px 10px rgb(0 0 0 / 20%)' : '0px 2px 10px rgb(0 0 0 / 8%)',
          },
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', height: '100%', ml: { lg: 1 } }}>
          <Box
            sx={{
              minHeight: '100%',
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              width: '65%',
            }}>
            <Typography
              sx={{
                mb: 1,
                fontWeight: 400,
                fontSize: ['0.8rem', '0.8rem', '0.8rem', '1.1rem'],
                lineHeight: '22px',
                color: '#22242C',
                opacity: 0.8,
                whiteSpace: 'nowrap',
                flex: { md: 1, lg: 2 },
                mt: { lg: 2 },
              }}>
              {label}
            </Typography>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: [
                  '1rem',
                  '1rem',
                  value.toString().length < 20 ? '0.9rem' : '0.8rem',
                  value.toString().length < 20 ? '1.1rem' : '1.2rem',
                ],
                lineHeight: '22px',
                color: '#4E5056',
                flex: { md: 2, lg: 3 },
              }}>
              {isLoading ? <Skeleton sx={{ width: '90%' }} /> : value}
            </Typography>
          </Box>
          <Box>{icon}</Box>
        </Box>
      </Link>
    </Box>
  );
}

function ValuePopOver({
  valueType,
  percent: percentProp,
  value,
  assetName,
  popoverContent,
  color = '#67ADF1',
  gainlossPercent = 0,
}: {
  valueType: string;
  percent: number;
  value: number;
  assetName: string;
  popoverContent?: JSX.Element | React.ReactNode;
  color?: string;
  gainlossPercent?: number;
}) {
  const positiveGainLossPercent = Math.abs(gainlossPercent);
  const [percent, setPercent] = useState(0);
  useEffect(() => {
    if (percentProp > 0) {
      setTimeout(() => setPercent(percentProp), 0);
    }
  }, [percentProp]);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <>
      <Box
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          display: 'flex',
          justifyContent: valueType === 'invested' ? 'flex-end' : 'flex-start',
          width: '100%',
          position: 'relative',
          pr: valueType === 'invested' ? 2.5 : 0,
          pl: valueType === 'invested' ? 0 : 2.5,
          '&::after': {
            content: '""',
            width: '7px',
            height: '7px',
            borderRadius: '50%',
            background: color,
            position: 'absolute',
            right: valueType === 'invested' ? 0 : 'unset',
            left: valueType === 'invested' ? 'unset' : 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          },
          '&::before': {
            content: '""',
            width: '20px',
            height: '1px',
            borderBottom: '1px dashed',
            borderColor: color,
            position: 'absolute',
            right: valueType === 'invested' ? 0 : 'unset',
            left: valueType === 'invested' ? 'unset' : 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 1,
          },
        }}>
        <Box
          sx={{
            fontSize: 11,
            fontWeight: 500,
            color: '#fff',
            px: 1.2,
            py: 0.6,
            position: 'relative',
            width: `${percent}%`,
            transition: 'width 0.5s ease',
            borderRadius: valueType === 'invested' ? ' 20px 0px 0px 20px' : '0px 20px 20px 0px',
            cursor: 'pointer',
          }}>
          <Box
            sx={{
              borderRadius: 'inherit',
              position: 'absolute',
              left: 0,
              top: 0,
              bottom: 0,
              right: 0,
              overflow: 'hidden',
            }}>
            <Box
              sx={{
                position: 'absolute',
                width: `${positiveGainLossPercent
                  ? percent < 10
                    ? 100 - positiveGainLossPercent - percent
                    : percent < 50 &&
                      percent / positiveGainLossPercent < 4 &&
                      percent / positiveGainLossPercent > 2
                      ? 100 + positiveGainLossPercent - percent
                      : 100 - positiveGainLossPercent
                  : 100
                  }%`,
                background: color,
                top: 0,
                bottom: 0,
                left: 0,
                zIndex: 1,
              }}
            />
            <Box
              sx={{
                position: 'absolute',
                width: '100%',
                borderRadius: 'inherit',
                backgroundColor: gainlossPercent < 0 ? '#E89292' : '#8ED8B7',
                top: 0,
                left: 0,
                bottom: 0,
                zIndex: 0,
              }}></Box>
          </Box>

          <span
            style={{
              position: 'absolute',
              color: percent < 10 ? '#22242C' : 'inherit',
              right:
                valueType === 'invested'
                  ? percent < 10
                    ? `${100 - (percent - 10)}%`
                    : 0
                  : 'unset',
              left:
                valueType === 'invested' ? 'unset' : percent < 10 ? `${100 - (percent - 10)}%` : 0,
              zIndex: 2,
            }}>
            {percent}%
          </span>
          <span style={{ visibility: 'hidden' }}>0</span>
        </Box>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
          left: valueType === 'invested' ? -20 : 20,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: valueType === 'invested' ? 'right' : 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: valueType === 'invested' ? 'left' : 'right',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus>
        {popoverContent ? (
          popoverContent
        ) : (
          <Box sx={{ p: 1 }}>
            <PopoverValue label={assetName} color={color} amount={value} percent={percent} />
          </Box>
        )}
      </Popover>
    </>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AssetAllocationCard({
  label,
  onClickLabel = () => { },
  investedPercent,
  currentPercent,
  investedValue,
  currentValue,
  xirr,
  isLoading,
  link,
  labelStyles,
  leftPopoverContent,
  rightPopoverContent,
  leftBarColor,
  rightBarColor,
}: {
  label: string;
  onClickLabel?(): void;
  investedPercent: number | string;
  currentPercent: number | string;
  investedValue: number | string;
  currentValue: number | string;
  xirr?: number | null;
  isLoading?: boolean;
  link?: string;
  labelStyles?: SxProps;
  leftPopoverContent?: JSX.Element | React.ReactNode;
  rightPopoverContent?: JSX.Element | React.ReactNode;
  leftBarColor?: string;
  rightBarColor?: string;
}) {
  const history = useHistory();
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', mb: 3.5 }}>
      <Box style={{ width: '50%', textAlign: 'right' }}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ValuePopOver
            popoverContent={leftPopoverContent}
            color={leftBarColor ? leftBarColor : '#67ADF1'}
            valueType="invested"
            percent={Number(investedPercent)}
            value={Number(investedValue)}
            assetName={label}
          />
        )}
      </Box>
      <Box style={{ width: 150, textAlign: 'center' }}>
        {isLoading ? (
          <Skeleton sx={{ width: 80, margin: 'auto' }} />
        ) : (
          <Typography
            sx={{
              fontSize: { md: 12, lg: 14 },
              lineHeight: '16px',
              color: '#22242C',
              opacity: 0.8,
              padding: '5px 12px',
              // border: '1px solid #0BAAE7',
              borderRadius: '5px',
              // width: 150,
              whiteSpace: 'nowrap',
              margin: 'auto',
              ...labelStyles,
            }}
            onClick={onClickLabel}>
            {label}
          </Typography>
        )}
      </Box>
      <Box style={{ width: '50%' }}>
        {isLoading ? (
          <Skeleton />
        ) : (
          <ValuePopOver
            popoverContent={rightPopoverContent}
            gainlossPercent={xirr !== null ? xirr : undefined}
            color={rightBarColor ? rightBarColor : '#8ED8B7'}
            valueType="current"
            percent={Number(currentPercent)}
            value={Number(currentValue)}
            assetName={label}
          />
        )}
      </Box>
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function RevenuePercent({
  label,
  percent: percentProp,
  amount,
  color,
  isLoading,
  popOverAmt,
  sx,
}: {
  label: string;
  percent: number;
  amount: string;
  color: string;
  isLoading: boolean;
  popOverAmt?: number;
  sx?: SxProps;
}) {
  const [percent, setPercent] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTimeout((_) => setPercent(percentProp), 0);
  }, [percentProp]);
  const open = Boolean(anchorEl);
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        pb: 3.75,
        ...sx,
      }}>
      {isLoading ? (
        <Skeleton sx={{ width: 150 }} />
      ) : (
        <Typography
          className="left"
          sx={{
            fontSize: 13,
            lineHeight: '15px',
            fontWeight: 400,
            color: '#1E346B',
            opacity: 0.8,
            textAlign: 'right',
            width: 150,
            mr: 4,
          }}>
          {label}
        </Typography>
      )}
      <Box
        className="progress-bar"
        onMouseEnter={popOverAmt ? handlePopoverOpen : () => { }}
        onMouseLeave={handlePopoverClose}
        sx={{
          flexGrow: 1,
          position: 'relative',
          overflowX: 'hidden',
          zIndex: 9,
          '&::after': {
            content: '" "',
            position: 'absolute',
            left: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            width: '100%',
            height: '1px',
            borderBottom: '2px dashed rgba(0,0,0,0.1)',
            zIndex: 1,
          },
        }}>
        {isLoading ? (
          <Skeleton sx={{ width: '90%', margin: 'auto' }} />
        ) : (
          <Box
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            sx={{
              background: color,
              width: `100%`,
              transform: `translateX(-${100 - percent}%)`,
              transition: 'transform 0.5s ease',
              borderRadius: '0px 20px 20px 0px',
              cursor: 'pointer',
              height: 14,
              position: 'relative',
              zIndex: 2,
            }}></Box>
        )}
        <Popover
          id="progress-bar-popover"
          sx={{
            pointerEvents: 'none',
            left: 0,
          }}
          PaperProps={{
            sx: {
              background: '#FFFFFF',
              boxShadow: '0px 4px 40px 4px rgba(0, 0, 0, 0.07)',
              borderRadius: '3px',
              padding: '10px',
            },
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus>
          <PopoverValue
            label={label}
            amount={popOverAmt ? formatToIndianCurrency(popOverAmt) : 'NA'}
            color={color}
            percent={percent}
          />
        </Popover>
      </Box>
      {isLoading ? (
        <Skeleton sx={{ width: 50 }} />
      ) : (
        <Typography
          className="right"
          sx={{
            fontSize: 13,
            fontWeight: 400,
            color: '#101010',
            opacity: 0.8,
            width: 80,
            ml: 3.5,
          }}>
          {amount || 'NA'}
        </Typography>
      )}
    </Box>
  );
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function ProgressBarIntervals({ sx }: { sx?: SxProps }) {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2, ...sx }}>
      <Typography className="left" sx={{ width: 150, mr: 4 }}></Typography>
      <Box
        className="progress-bar"
        sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
        {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, index) => (
          <Typography
            key={index}
            sx={{
              fontSize: 12,
              fontWeight: 400,
              color: '#22242C',
              opacity: 0.4,
              lineHeight: '14px',
            }}>
            {item}
          </Typography>
        ))}
      </Box>
      <Typography className="right" sx={{ width: 80, ml: 3.75 }}></Typography>
    </Box>
  );
}

export default function Dashboard(): JSX.Element {
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [selectedAssetsType, setSelectedAssetsType] = useState<'product' | 'assetClass'>('product');
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);
  const [flip, setFlip] = useState(false);
  const dispatch = useDispatch();
  const componentRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    let isComponentActive = true;
    setDashBoardMetricsData(null);
    (async function () {
      try {
        setLoading(true);
        const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
        if (!isComponentActive) {
          return;
        }
        setDashBoardMetricsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
      console.log('here is table data', dashBoardMetricsData);
    };
  }, []);

  const productTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Product Name',
        sortingKey: 'ProductLevel',
        valueGetter: (row: ProductAllocationEntity) => row?.ProductLevel,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: ProductAllocationEntity, amountUnit?: number | null | undefined) =>
          row?.PurVal || row?.PurVal === 0
            ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}`
            : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: ProductAllocationEntity, amountUnit?: number | null | undefined) =>
          row?.MktVal || row?.MktVal === 0
            ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}`
            : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: ProductAllocationEntity) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: ProductAllocationEntity) => row?.MktValPercent,
      },
    ];
  };

  const assetClassTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Asset Class',
        sortingKey: 'Asset_Class',
        valueGetter: (row: AssetAllocationEntity) => row?.Asset_Class,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: AssetAllocationEntity, amountUnit?: number | null | undefined) =>
          row?.PurVal || row?.PurVal === 0
            ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}`
            : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: AssetAllocationEntity, amountUnit?: number | null | undefined) =>
          row?.MktVal || row?.MktVal === 0
            ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}`
            : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: AssetAllocationEntity) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: AssetAllocationEntity) => row?.MktValPercent,
      },
    ];
  };

  function numDifferentiation(value: number) {
    const val = Math.abs(value);
    if (val >= 10000000) {
      return (val / 10000000).toFixed(2) + ' Cr';
    } else {
      return (val / 100000).toFixed(2) + ' Lac';
    }
  }
  //Graph refs
  const assetAllocationRef = useRef(null);

  return (
    <div ref={componentRef}>
      <CommonLayout>
        <>
          {/* <Button
        // onClick={() => window.print()}
        onClick={handlePrint}

        className="print_button" 
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 1,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            PDF
          </Button>  */}
          <Button
            onClick={() => window.print()}

            sx={{

              marginBottom: '10px',
              height: '27px',
              color: 'white',
              fontSize: '13px',
              cursor: 'pointer',
              borderRadius: '5px',
              bgcolor: '#4990F0',
              '&:hover': { backgroundColor: '#639FF0' },
            }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>

          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'unset', lg: 'space-between' },
              flexWrap: 'wrap',
              gap: 1.5,
              mb: 6,
            }}>
            {/* <Box sx={{ width: ['45%', '30%', 230] }}>
            <Link
              onClick={() => history.push('/investor-dashboard')}
              sx={{
                cursor: 'pointer',
                color: '#fff',
                display: 'block',
                textDecoration: 'none',
                py: 3,
                px: 2,
                pb: 1,
                background: '#FFFFFF',
                borderTop: `4px solid #1473CC`,
                boxShadow: '0px 2px 10px rgb(0 0 0 / 8%)',
                borderRadius: '10px',
                '&:hover,&:focus': {
                  boxShadow: '0px 2px 10px rgb(0 0 0 / 20%)',
                },
              }}>
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  mb: 1,
                  fontWeight: 400,
                  fontSize: 14,
                  lineHeight: '22px',
                  color: '#293139',
                  opacity: 0.7,
                }}>
                Total Investors
                <ChevronRightIcon />
              </Typography>
              <Typography
                sx={{ fontWeight: 600, fontSize: 14, lineHeight: '22px', color: '#11263C' }}>
                159
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 12, lineHeight: '14px', color: '#11263C' }}>
                  New: 47
                </Typography>
                <Typography
                  sx={{ fontWeight: 600, fontSize: 12, lineHeight: '14px', color: '#11263C' }}>
                  Active: 112
                </Typography>
              </Box>
            </Link>
          </Box> */}
            <InfoCard
              isLoading={isLoading}
              label="Total Investors"
              value={
                (dashBoardMetricsData?.dashBoardTopMetrics &&
                  dashBoardMetricsData?.dashBoardTopMetrics[0]?.InvestorsCount) ||
                'NA'
              }
              color="#C899F4"
              onClick={() => history.push('/investors')}
              icon={<TotalRMsDashboardIcon sx={{ fontSize: { md: '5rem', lg: '7rem' } }} />}
            />
            <InfoCard
              isLoading={isLoading}
              label="Net Sales"
              value={
                dashBoardMetricsData?.dashBoardTopMetrics &&
                  dashBoardMetricsData?.dashBoardTopMetrics[0]?.NetSales
                  ? `${dashBoardMetricsData?.dashBoardTopMetrics &&
                  formatToIndianCurrency(
                    dashBoardMetricsData?.dashBoardTopMetrics[0]?.NetSales,
                    valueConverter
                  )
                  }`
                  : 'NA'
              }
              color="#8ED8B7"
              onClick={() => history.push('/net-sales')}
              icon={<NetSalesDashboardIcon sx={{ fontSize: { md: '5rem', lg: '7rem' } }} />}
            />
            <InfoCard
              isLoading={isLoading}
              label="Total Revenue"
              value={
                dashBoardMetricsData?.dashBoardTopMetrics &&
                  dashBoardMetricsData?.dashBoardTopMetrics[0]?.TotalRevenue
                  ? `${dashBoardMetricsData?.dashBoardTopMetrics &&
                  formatToIndianCurrency(
                    dashBoardMetricsData?.dashBoardTopMetrics[0]?.TotalRevenue,
                    valueConverter
                  )
                  }`
                  : 'NA'
              }
              color="#4990F0"
              onClick={() => history.push('/total-revenue')}
              icon={<TotalRevenueDashboardIcon sx={{ fontSize: { md: '5rem', lg: '7rem' } }} />}
            />
          </Box>
          {/* <div ref={componentRef}> */}

          <Box
            ref={assetAllocationRef}
            sx={{
              background: ' #FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 1,
              }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: 600,
                }}>
                Asset Allocation
              </Typography>
              <Stack direction="row" alignItems="center">
                <Box sx={{ pr: 2 }}>
                  <AmountUnit />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
                  {/* <Button
        onClick={handlePrint}
        className="print__button" 
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 1,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            PDF
          </Button>  */}

                  <Typography
                    sx={{
                      color: '#22242C',
                      opacity: 0.8,
                      fontSize: 15,
                      fontWeight: 500,
                    }}>
                    By Product
                  </Typography>
                  <Switch
                    checked={selectedAssetsType === 'assetClass'}
                    onChange={(_, checked) =>
                      setSelectedAssetsType(checked ? 'assetClass' : 'product')
                    }
                    sx={{
                      p: 0,
                      mx: 2.5,
                      height: 20,
                      width: 52,
                      '& .MuiSwitch-track': {
                        background: '#4990F0',
                        opacity: 1,
                        borderRadius: '12px',
                      },
                      '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                      '& .MuiSwitch-switchBase': {
                        p: 0,
                        '&.Mui-checked': {
                          color: '#fff',
                          transform: 'translateX(32px)',
                          '& + .MuiSwitch-track': {
                            backgroundColor: '#4990F0',
                            opacity: 1,
                          },
                        },
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      color: '#22242C',
                      opacity: 0.8,
                      fontSize: 15,
                      fontWeight: 500,
                    }}>
                    By Asset Class
                  </Typography>
                </Box>


              </Stack>
            </Box>
            <Divider />
            <Box sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}>
              <FlipIcon
                onClick={() => setFlip(!flip)}
                style={{
                  height: '20px',
                  cursor: 'pointer',
                  // background: 'white',
                  // marginLeft:"20px",
                  // marginTop:"8px",
                  // border:'none'
                }}
              />
            </Box>
            <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
              <div
                style={{
                  backgroundColor: 'white',
                  display: `${flip ? 'none' : 'block'}`
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p: 2,
                    justifyContent: 'center',
                    width: '100%',
                  }}>
                  <Box sx={{ display: 'flex', width: '100%' }}>
                    <Box style={{ width: '50%', textAlign: 'right', paddingRight: '20px' }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#22242C',
                          opacity: 0.8,
                          mb: 1,
                          textAlign: 'right',
                        }}>
                        Invested Value
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: '#11263C',
                          mb: 3.5,
                          fontWeight: 700,
                          textAlign: 'right',
                        }}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          ` ${(dashBoardMetricsData?.assetAllocation &&
                            dashBoardMetricsData?.assetAllocation[0]?.TotalInvesVal &&
                            formatToIndianCurrency(
                              dashBoardMetricsData?.assetAllocation[0]?.TotalInvesVal,
                              valueConverter
                            )) ||
                          'NA'
                          }`
                        )}
                      </Typography>
                    </Box>
                    <Box style={{ width: 150, textAlign: 'center' }}></Box>
                    <Box style={{ width: '50%', paddingLeft: '20px' }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                          color: '#22242C',
                          opacity: 0.8,
                          mb: 1,
                        }}>
                        Current Market Value
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 20,
                          color: '#11263C',
                          mb: 3.5,
                          fontWeight: 700,
                        }}>
                        {isLoading ? (
                          <Skeleton />
                        ) : (
                          ` ${(dashBoardMetricsData?.assetAllocation &&
                            dashBoardMetricsData?.assetAllocation[0]?.TotalMktVal &&
                            formatToIndianCurrency(
                              dashBoardMetricsData?.assetAllocation[0]?.TotalMktVal,
                              valueConverter
                            )) ||
                          'NA'
                          }`
                        )}
                      </Typography>
                    </Box>
                  </Box>

                  {selectedAssetsType === 'assetClass' && dashBoardMetricsData?.assetAllocation
                    ? dashBoardMetricsData?.assetAllocation.map((asset, index: number) => (
                      <AssetAllocationCard
                        key={index}
                        label={asset?.Asset_Class || ''}
                        investedPercent={asset?.PurValPercent || ''}
                        currentPercent={asset?.MktValPercent || ''}
                        investedValue={asset?.PurVal || ''}
                        currentValue={asset?.MktVal || ''}
                        labelStyles={{ border: 0 }}
                      />
                    ))
                    : selectedAssetsType === 'product' && dashBoardMetricsData?.productAllocation
                      ? dashBoardMetricsData?.productAllocation.map((asset, index: number) => (
                        <AssetAllocationCard
                          key={index}
                          label={asset?.ProductLevel}
                          investedPercent={asset?.PurValPercent}
                          currentPercent={asset?.MktValPercent}
                          investedValue={asset?.PurVal}
                          currentValue={asset?.MktVal}
                        />
                      ))
                      : [...Array(3)].map((asset: any, index: number) => (
                        <AssetAllocationCard
                          key={index}
                          label={asset?.ProductLevel}
                          investedPercent={asset?.PurValPercent}
                          currentPercent={asset?.MktValPercent}
                          investedValue={asset?.PurVal}
                          currentValue={asset?.MktVal}
                          isLoading={true}
                        />
                      ))}
                </Box>
              </div>
              <div
                style={{
                  width: '100%',
                  backgroundColor: 'white',
                  maxHeight: '390px',
                  display: `${flip ? 'block' : 'none'}`
                }}>
                {selectedAssetsType === 'product' ? (
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={[...productTableHeaders()]}
                    tableData={
                      dashBoardMetricsData?.productAllocation &&
                      dashBoardMetricsData?.productAllocation
                    }
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: '500px', minWidth: 450 }}
                  />
                ) : (
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={[...assetClassTableHeaders()]}
                    tableData={
                      dashBoardMetricsData?.assetAllocation
                      // &&
                      // dashBoardMetricsData?.rmWise.filter((ele) =>
                      //   ele?.RMID?.toLowerCase().includes(search.toLowerCase())
                      // )
                    }
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: '500px', minWidth: 450 }}
                  />
                )}
              </div>
            </ReactCardFlip>
          </Box>

          {/* <Box>
          <CardMedia
            component="img"
            src="/images/sales-graph.svg"
            alt="graph"
            sx={{ width: 'calc(100% + 60px)', ml: -3 }}
          />
        </Box> */}
          {/* <Box
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
          }}>
          <Typography
            sx={{ fontSize: 14, fontWeight: 600, color: '#22242C', opacity: 0.8, py: 2, px: 3 }}>
            Sales Insights
          </Typography>
          <Divider />
          <Box sx={{ p: 3 }}>
            <Stack
              flexDirection="row"
              sx={{
                justifyContent: { xs: 'unset', lg: 'space-between' },
                mb: 4,
              }}>
              <Box sx={{ p: 3, background: '#F3FDFF', borderRadius: '3px', width: 200 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: '16px',
                    fontWeight: 400,
                    color: '#4E5056',
                    mb: 1,
                    textTransform: 'uppercase',
                  }}>
                  New Customer
                </Typography>
                <Typography
                  sx={{ fontSize: 24, lineHeight: '28px', fontWeight: 600, color: '#4E5056' }}>
                  145
                </Typography>
              </Box>
              <Box sx={{ p: 3, background: '#FBF6FF', borderRadius: '3px', width: 200 }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    lineHeight: '16px',
                    fontWeight: 400,
                    color: '#4E5056',
                    mb: 1,
                    textTransform: 'uppercase',
                  }}>
                  Active Customer
                </Typography>
                <Typography
                  sx={{ fontSize: 24, lineHeight: '28px', fontWeight: 600, color: '#4E5056' }}>
                  321
                </Typography>
              </Box>
              <Stack
                flexDirection="row"
                sx={{
                  py: 1,
                  px: 3,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  background: '#FFF6F6',
                  width: 340,
                }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      fontWeight: 400,
                      color: '#4E5056',
                      mb: 1,
                      textTransform: 'uppercase',
                    }}>
                    Revenue
                  </Typography>
                  <Typography
                    sx={{ fontSize: 24, lineHeight: '28px', fontWeight: 600, color: '#4E5056' }}>
                    2,10,91,87,289
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    border: '5px solid #CB6363',
                    borderRadius: '50%',
                    width: 80,
                    height: 80,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    sx={{ fontSize: 14, lineHeight: '16px', color: '#CB6363', fontWeight: 500 }}>
                    90%
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      lineHeight: '14px',
                      color: '#CB6363',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}>
                    Below Target
                  </Typography>
                </Stack>
              </Stack>
              <Stack
                flexDirection="row"
                sx={{
                  py: 1,
                  px: 3,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  background: '#F9FFF6',
                  width: 340,
                }}>
                <Box>
                  <Typography
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      fontWeight: 400,
                      color: '#4E5056',
                      mb: 1,
                      textTransform: 'uppercase',
                    }}>
                    Gross Sales
                  </Typography>
                  <Typography
                    sx={{ fontSize: 24, lineHeight: '28px', fontWeight: 600, color: '#4E5056' }}>
                    2,10,91,87,289
                  </Typography>
                </Box>
                <Stack
                  sx={{
                    border: '5px solid #39A009',
                    borderRadius: '50%',
                    width: 80,
                    height: 80,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Typography
                    sx={{ fontSize: 14, lineHeight: '16px', color: '#39A009', fontWeight: 500 }}>
                    115%
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 11,
                      lineHeight: '14px',
                      color: '#39A009',
                      fontWeight: 400,
                      textAlign: 'center',
                    }}>
                    Target Met
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
            <Box sx={{ borderRadius: '5px', border: '1px solid #F2F2F2' }}>
              <Stack
                flexDirection="row"
                alignItems="center"
                sx={{ py: 1.25, px: 2, background: '#FAFAFA' }}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#22242C',
                    opacity: 0.8,
                    mr: 3,
                  }}>
                  Revenue by Asset Class
                </Typography>
                <FormControl
                  sx={{
                    '& .MuiSelect-select': { px: 1, py: 0.5, fontSize: 13, minHeight: 'unset' },
                  }}>
                  <Select displayEmpty>
                    <MenuItem value="">Sort by</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Box
                sx={{
                  p: 3,
                  pb: 0.2,
                }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                  <Typography sx={{ width: 150, mr: 4 }}></Typography>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}>
                    {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((item, index) => (
                      <Typography
                        key={index}
                        sx={{
                          fontSize: 12,
                          fontWeight: 400,
                          color: '#22242C',
                          opacity: 0.4,
                          lineHeight: '14px',
                        }}>
                        {item}
                      </Typography>
                    ))}
                  </Box>
                  <Typography sx={{ width: 80, ml: 3.75 }}></Typography>
                </Box>
                <RevenuePercent
                  label="Mutual Funds"
                  percent={75}
                  amount="70.53 Lacs"
                  color="#B8E3FF"
                />
                <RevenuePercent
                  label="Direct Equity"
                  percent={69}
                  amount="67.47 Lacs"
                  color="#21639F"
                />
                <RevenuePercent
                  label="Government Securities"
                  percent={61}
                  amount="61.58 Lacs"
                  color="#8ED8B7"
                />
                <RevenuePercent
                  label="Portfolio Management Services"
                  percent={53}
                  amount="53.50 Lacs"
                  color="#4990F0"
                />
                <RevenuePercent
                  label="Alternate Investment Fund"
                  percent={48}
                  amount="48.53 Lacs"
                  color="#BA87ED"
                />
              </Box>
            </Box>
          </Box>
        </Box> */}
          {/* </div> */}
        </>
      </CommonLayout>
    </div>
  );
}

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Box,
  Breadcrumbs,
  CardMedia,
  Divider,
  FormControl,
  Hidden,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { Location } from 'history';
import { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { Redirect, Route, Switch, useHistory, useRouteMatch, Router } from 'react-router-dom';
import { AnimatedBox, ReportLeftLayout, SectionLinkItem } from '../../InvestorReport/index';
// import ClientStatement from './client-statement';
// import TransactionStatement from './transaction-statement';
// import PortfolioSummary from './portfolio-summary';
// import CGStatement from './cg-statement';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { HoldingReport } from './holding-report';
import { MFTransactionWiseHoldingReport } from './MF-Transactionwise-holding-report';
import { CapitalGainStatement } from './capital-gain-statement';
import { TransactionHistoryReport } from './transaction-history-report';
import { TransactionSummaryReport } from './transaction-summary-report'
import { DetailedHoldingReport } from './DetailedHoldingReport';
import { PortfolioSummaryReport } from './PortfolioSummaryReport';
import { FamilyLevelReport } from './FamilyLevelReport';


export default function DashboardReportRoute(): JSX.Element {
  const history = useHistory();
  const pathName = history.location.pathname;
  //   const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const [transitionClassName, setTransitionClassName] = useState('');
  const { path } = useRouteMatch();

  //Dont change the routerLink it will affect functionality
  const leftMenuItem = [
    { title: 'Holding Report', routerLink: `holding-report` },
    { title: 'Transaction Statement', routerLink: `transaction-statement` },
    // { title: 'Portfolio Summary', routerLink: `portfolio-summary` },
    // { title: 'CG Statement', routerLink: `cg-statement` },
  ];

  const links = [
    `${path}/holding-report`,
    `${path}/transaction-statement`,
    // `${path}/portfolio-summary`,
    // `${path}/cg-statement`,
  ];

  const Menulinks = links.includes(pathName) ? links : [];
  const MenuItems = links.includes(pathName) ? leftMenuItem : [];


  const breadcrumbs = [

    <Link
      key="1"
      sx={{ cursor: 'pointer', textDecoration: 'none' }}
      onClick={() => history.push('/investors')}>
      Reports
    </Link>,
    <Typography key="2" color="text.primary">
      Download
    </Typography>,
  ];
  return (
    <CommonLayout>
      <>
        <Box
          sx={{
            background: '#EEF9F4',
            borderRadius: '16px',
            px: 3,
            py: 1.5,
            mb: 2.5,
          }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
            sx={{
              '& .MuiTypography-root': {
                fontSize: 20,
                lineHeight: '23px',
                fontWeight: 500,
                color: '#BBB5B5',
                '&.MuiTypography-body1': { color: '#22242C' },
              },
              '& .MuiBreadcrumbs-separator': {
                '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                '&:last-of-type': {
                  background: 'red',
                  '& .MuiSvgIcon-root': { color: '#22242C' },
                },
              },
            }}>
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
        <>
          <Box
            sx={{
              width: ['100%', '100%', '100%'],
              ml: [0, 1, 1],
              pt: [0, 0, 2],
              pb: [0, 0, '60px'],
              boxSizing: 'border-box',
            }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="flex-end"
              sx={{ mb: 3.5, display: ['none', 'flex', 'flex'] }}>
              <Typography
                sx={{
                  color: 'text.secondary',
                  fontSize: '22px',
                  fontWeight: 500,
                  lineHeight: '26px',
                  letterSpacing: '0.06em',
                }}>
                REPORTS
              </Typography>
            </Stack>
            <Hidden smUp={true}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-end"
                sx={{ display: ['flex', 'flex', 'flex'] }}>
                <Typography
                  sx={{
                    color: 'text.secondary',
                    fontSize: '18px',
                    fontWeight: 500,
                    ml: 1,
                    lineHeight: '26px',
                    letterSpacing: '0.06em',
                  }}>
                  REPORTS
                </Typography>
              </Stack>
              <Box
                sx={{
                  borderRadius: '5px',
                }}>
                <Box sx={{ px: 3, pt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel shrink id="investor-items"></InputLabel>
                    <Select
                      labelId="investor-items"
                      value={MenuItems[Menulinks?.indexOf(pathName)]?.routerLink}
                      onChange={(e) => {
                        // history.push(`${e.target.value}`, {
                        //   investorId: investorId,
                        //   investorName: investorName,
                        // });
                      }}
                      sx={{
                        color: 'primary.main',
                        fontWeight: 500,
                      }}
                      IconComponent={(props) => (
                        <ArrowDropDownIcon
                          {...props}
                          sx={{
                            paddingRight: '13px',
                            color: '#00D9DA !important',
                            cursor: 'pointer',
                          }}
                          fontSize="large"
                        />
                      )}>
                      {leftMenuItem.map((itemlist, ind) => (
                        <MenuItem
                          sx={{ borderBottom: '1px solid #ccc' }}
                          value={itemlist.routerLink}
                          key={ind}>
                          {itemlist.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Hidden>
            <Paper
              sx={{
                p: 3,
                // backgroundColor: 'primary.light',
                boxShadow: [0, 0, 24],
                borderRadius: [0, 0, '10px'],
                height: ['auto', 'auto', 'auto'],
              }}>
              <Stack direction="row" sx={{ width: '100%' }}>
                <Hidden only="xs">
                  <ReportLeftLayout style={{ overflow: 'auto', maxHeight: '400px' }}>

                    <SectionLinkItem
                      to={{
                        pathname: `${path}/Portfolio-Summary-Report`,

                      }}
                      title={'Portfolio Summary Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/client-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />

                    <SectionLinkItem
                      to={{
                        pathname: `${path}/mf-transaction-wise-holding-report`,

                      }}
                      title={'MF Transaction-wise Holding Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/client-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/holding-report`,

                      }}
                      title={'Holding Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/cg-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/capital-gain-statement`,

                      }}
                      title={'Capital Gain Statement'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/portfolio-summary.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/transaction-history-report`,

                      }}
                      title={'Transaction History Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/transaction-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/transaction-Summary-report`,

                      }}
                      title={'Transaction Summary Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/transaction-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/DetailedHoldingReport`,

                      }}
                      title={'Detailed Holding Report'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/client-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/Family-Level-Report`,

                      }}
                      title={'Family Level Report'}
                      icon={

                        <CardMedia
                          component="img"
                          src="/images/portfolio-summary.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    {/* <SectionLinkItem
                      to={{
                        pathname: `${path}/client-statement`,
                        state: {
                          investorId: investorId,
                          investorName: investorName,
                        },
                      }}
                      title={'Client Statement'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/client-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/transaction-statement`,
                        state: {
                          investorId: investorId,
                          investorName: investorName,
                        },
                      }}
                      title={'Transaction Statement'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/transaction-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/portfolio-summary`,
                        state: {
                          investorId: investorId,
                          investorName: investorName,
                        },
                      }}
                      title={'Portfolio Summary'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/portfolio-summary.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    />
                    <SectionLinkItem
                      to={{
                        pathname: `${path}/cg-statement`,
                        state: {
                          investorId: investorId,
                          investorName: investorName,
                        },
                      }}
                      title={'CG Statement'}
                      icon={
                        <CardMedia
                          component="img"
                          src="/images/cg-statement.svg"
                          sx={{ width: 'unset', mx: 'auto' }}
                        />
                      }
                    /> */}
                  </ReportLeftLayout>
                </Hidden>
                <Divider
                  orientation="vertical"
                  sx={{
                    borderWidth: '1px',
                    borderColor: 'text.dividerLight',
                    mr: 1,
                    ml: 2,
                    display: ['none', 'none', 'flex'],
                  }}
                  flexItem
                />
                <AnimatedBox sx={{ width: '100%' }} className={transitionClassName}>
                  <Box sx={{ mt: { xs: -2 } }}>
                    <Router history={history}>
                      <Switch>
                        <Redirect
                          exact
                          from={path}
                          to={{
                            pathname: `${path}/holding-report`,
                            // state: { investorId, investorName },
                          }}
                        />
                        <Route
                          path={`${path}/mf-transaction-wise-holding-report`}
                          render={() => (
                            <MFTransactionWiseHoldingReport
                            />
                          )}
                        />
                        <Route
                          path={`${path}/holding-report`}
                          render={() => (
                            <HoldingReport
                            />
                          )}
                        />
                        <Route
                          path={`${path}/capital-gain-statement`}
                          render={() => (
                            <CapitalGainStatement

                            />
                          )}
                        />
                        <Route
                          path={`${path}/transaction-history-report`}
                          render={() => (
                            <TransactionHistoryReport
                            />

                          )}
                        />
                        <Route
                          path={`${path}/transaction-Summary-report`}
                          render={() => (
                            <TransactionSummaryReport
                            />

                          )}
                        />
                        <Route
                          path={`${path}/DetailedHoldingReport`}
                          render={() => (
                            <DetailedHoldingReport
                            />
                          )}
                        />
                        <Route
                          path={`${path}/Portfolio-Summary-Report`}
                          render={() => (
                            <PortfolioSummaryReport
                            />
                          )}
                        />
                        <Route
                          path={`${path}/Family-Level-Report`}
                          render={() => (
                            <FamilyLevelReport
                            />
                          )}
                        />
                        {/* <Redirect
                          exact
                          from={path}
                          to={{
                            pathname: `${path}/client-statement`,
                            state: { investorId, investorName },
                          }}
                        />
                        <Route
                          path={`${path}/client-statement`}
                          render={(props) => (
                            <ClientStatement
                              {...props}
                              // investorId={investorId}
                              // investorName={investorName}
                              location={location}
                            />
                          )}
                        />
                        <Route
                          path={`${path}/transaction-statement`}
                          render={(props) => (
                            <TransactionStatement
                              {...props}
                              // investorId={investorId}
                              // investorName={investorName}
                              location={location}
                            />
                          )}
                        />
                        <Route
                          path={`${path}/portfolio-summary`}
                          render={(props) => (
                            <PortfolioSummary
                              {...props}
                              // investorId={investorId}
                              // investorName={investorName}
                              location={location}
                            />
                          )}
                        />
                        <Route
                          path={`${path}/cg-statement`}
                          render={(props) => (
                            <CGStatement
                              {...props}
                              // investorId={investorId}
                              // investorName={investorName}
                              location={location}
                            />
                          )}
                        /> */}
                      </Switch>
                    </Router>
                  </Box>
                </AnimatedBox>
              </Stack>
            </Paper>
          </Box>
        </>
      </>
    </CommonLayout>
  );
}


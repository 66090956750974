import { Box, Button, Divider, Grid, LinearProgress, Skeleton, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetAllocationCard, ProgressBarIntervals, RevenuePercent, scrollbarView } from '..';
import { DatePicker } from '../../../lib/formik/DatePicker';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import {
  DirectEquityOverviewType,
  DirectEquityPerformer, DirectEquitySectorAllocation, DirectEquityAssetAllocation
} from '../../../redux-store/types/api-types';
import { formatLongDate } from '../../../utils/date';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../../DataTable';
import { CopyGraphDropdown, AmountUnit } from '../../commonComponents';
import { ProductTopSection } from '../product-details';
import { useLocation } from 'react-router';
import ReactCardFlip from 'react-card-flip';
import { FlipIcon } from '../../customSVGs';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';


export default function DirectEquityOverview({ investorId, selectedPortfolio}: { investorId: string; selectedPortfolio:number }): JSX.Element {
  const headers: ColumnType[] = [
    {
      header: 'Security Name',
      sortingKey: 'Sector',
      valueGetter: (row: DirectEquityPerformer) => row?.Sector || 'N/A',
    },
    investorId
      ? {
        header: 'XIRR (%)',
        sortingKey: 'xirr',
        valueGetter: (row: DirectEquityPerformer) => (
          <Typography
            sx={{
              fontSize: 'inherit',
              color: row.Xirr > 0 ? 'inherit' : '#F26868',
            }}>
            {row?.Xirr || row?.Xirr === 0 ? row?.Xirr : 'N/A'}
          </Typography>
        ),
      }
      : {
        header: 'Return (%)',
        sortingKey: 'Return',
        valueGetter: (row: DirectEquityPerformer) => (
          <Typography
            sx={{
              fontSize: 'inherit',
              color: row.Return > 0 ? 'inherit' : '#F26868',
            }}>
            {row?.Return || 'N/A'}
          </Typography>
        ),
      },
    {
      header: 'Exposure (%)',
      sortingKey: 'Exposure',
      valueGetter: (row: DirectEquityPerformer) =>
        row?.Exposure || row?.Exposure === 0 ? row?.Exposure : 'N/A',
    },
  ];

  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<DirectEquityOverviewType | null>(null);
  const valueConverter = useSelector((state: any) => state.valueConverter);

  const [flipAssetAllocationOverview, setFlipAssetAllocationOverview] = useState(false);
  const [flipSectorAllocationOverview, setFlipSectorAllocationOverview] = useState(false);



  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setOverviewData(null);
    console.log("investorId", investorId);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'DIRECT EQUITIES',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              customers: investorId ? [investorId] : [],
            },
            required: ['overview'],
          })
        )) as unknown as DirectEquityOverviewType;
        if (!isComponentActive) {
          return;
        }
        setOverviewData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [location.pathname, selectedPortfolio]);

  const [sectorAllocationView, setSectorAllocationView] = useState(false);

  const sectorWiseData = overviewData && overviewData?.sectorAllocation;
  const sectorWiseDataDisplay =
    overviewData && (sectorAllocationView ? sectorWiseData : sectorWiseData?.slice(0, 6));

  const totalInvAmount =
    overviewData?.assetAllocation &&
    overviewData?.assetAllocation.reduce(function (tot, arr) {
      return tot + arr.PurVal;
    }, 0);
  const totalMktAmount =
    overviewData?.assetAllocation &&
    overviewData?.assetAllocation.reduce(function (tot, arr) {
      return tot + arr.MktVal;
    }, 0);

  const assetAllocationRef = useRef<HTMLElement | null>(null);
  const sectorAllocationRef = useRef<HTMLElement | null>(null);
  const portfolioPerformanceRef = useRef<HTMLElement | null>(null);

  const portfolioPerformance = overviewData?.portfolioData;
  const portfolioPerformaceGraphOptions = {
    chart: {
      type: 'line',
      // scrollablePlotArea: {
      //   minWidth: 600,
      //   scrollPositionX: 1,
      // },
    },
    title: {
      text: '',
    },
    credits: false,
    xAxis: {
      // type: 'datetime',
      categories: portfolioPerformance?.map(
        (each: { DT: string }) =>
          formatLongDate(each.DT).split(' ')[1].substring(0, 3) +
          ' ' +
          formatLongDate(each.DT).split(' ')[2]
      ),
      labels: {
        autoRotation: false,
        overflow: 'justify',
      },
    },
    yAxis: {
      title: {
        text: 'Return',
      },
      labels: {
        // formatter: function (): string {
        //   return '<span>' + (this as any).value + ' %</span>';
        // },
        format: '{text} %',
      },
    },
    tooltip: {
      valueSuffix: ' %',
      shared: true,
    },
    legend: {
      align: 'center',
      verticalAlign: 'top',
      enabled: false,
    },
    plotOptions: {
      line: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
        },
      },
    },
    series: [
      {
        name: 'Portfolio',
        color: '#0CDDC4',
        data: portfolioPerformance?.map((each) => each.Rtn),
        connectEnds: false,
        connectNulls: true,
        crisp: true,
      },
      // {
      //   name: 'Sensex',
      //   color: '#F4C10E',
      //   data: portfolioPerformance?.map((each) => 0),
      // },
      // {
      //   name: 'Nifty',
      //   color: '#730EF4',
      //   data: portfolioPerformance?.map((each) => 0),
      // },
    ],
    navigation: {
      menuItemStyle: {
        fontSize: '10px',
      },
    },
  };
  const AssetAllocationOverviewTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'AMC Category',
        sortingKey: 'MACP',
        valueGetter: (row: DirectEquityAssetAllocation) => row?.MACP,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: DirectEquityAssetAllocation, amountUnit?: number | null | undefined) => row?.PurVal || row?.PurVal === 0 ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: DirectEquityAssetAllocation, amountUnit?: number | null | undefined) => row?.MktVal || row?.MktVal === 0 ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: DirectEquityAssetAllocation) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: DirectEquityAssetAllocation) => row?.MktValPercent,
      },
    ];
  }
  const SectorAllocationOverviewTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Sector',
        sortingKey: 'Sector',
        valueGetter: (row: DirectEquitySectorAllocation) => row?.Sector,
      },
      {
        header: 'Market Value',
        sortingKey: 'I_MarKetValue',
        valueGetter: (row: DirectEquitySectorAllocation, amountUnit?: number | null | undefined) => row?.I_MarKetValue || row?.I_MarKetValue === 0 ? `${formatToIndianCurrency(row?.I_MarKetValue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Allocation %',
        sortingKey: 'SecPercent',
        valueGetter: (row: DirectEquitySectorAllocation) => row?.SecPercent,
      },
    ];
  }
  return (
    <Box>
      <Box
        sx={{
          mb: 2
        }}>
        <AmountUnit />
        <Button
          onClick={() => window.print()}

          sx={{
            marginLeft: '20px',
            height: '27px',
            color: 'white',
            fontSize: '13px',
            cursor: 'pointer',
            borderRadius: '5px',
            bgcolor: '#4990F0',
            '&:hover': { backgroundColor: '#639FF0' },
          }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>

      </Box>
      {overviewData?.assetAllocation.length===0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem', fontSize: '18px', fontWeight: 500, color: '#666', background: 'white', boxShadow: '10rem', marginTop:'4rem' }}>No portfolio details available.</div> :
      <Box>
      {investorId && (
        <ProductTopSection
          marketValue={overviewData?.topValuesOverview?.currentValue || 0}
          investedValue={overviewData?.topValuesOverview?.investedValue || 0}
          gainLoss={overviewData?.topValuesOverview?.gainLoss}
          xirr={investorId ? overviewData?.topValuesOverview?.xirr : null}
          isLoading={isLoading}
        />
      )}
      <Box
        ref={assetAllocationRef}
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
          mb: 7,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 16,
              lineHeight: '19px',
              fontWeight: 600,
            }}>
            Asset Allocation by Market Capitalization
          </Typography>
          <Box sx={{
            display: 'flex', alignItems: 'center'
          }}>
            {/* <CopyGraphDropdown elementRef={assetAllocationRef} /> */}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
        >
          <FlipIcon
            onClick={() => (setFlipAssetAllocationOverview(!flipAssetAllocationOverview))}
            style={{
              height: '20px',
              cursor: "pointer"
            }}
          />
        </Box>
        <ReactCardFlip isFlipped={flipAssetAllocationOverview}
          flipDirection="horizontal">
          <div
            style={{
              backgroundColor: 'white',
              display: `${flipAssetAllocationOverview ? 'none' : 'block'}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                justifyContent: 'center',
                width: '100%',
              }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box style={{ width: '50%', textAlign: 'right', paddingRight: '20px' }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#22242C',
                      opacity: 0.8,
                      mb: 1,
                      textAlign: 'right',
                    }}>
                    Invested Value
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: '#11263C',
                      mb: 3.5,
                      fontWeight: 700,
                      textAlign: 'right',
                    }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : totalInvAmount ? (
                      `${formatToIndianCurrency(totalInvAmount, valueConverter)}`
                    ) : (
                      'NA'
                    )}
                  </Typography>
                </Box>
                <Box style={{ width: 150, textAlign: 'center' }}></Box>
                <Box style={{ width: '50%', paddingLeft: '20px' }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#22242C',
                      opacity: 0.8,
                      mb: 1,
                    }}>
                    Current Market Value
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: '#11263C',
                      mb: 3.5,
                      fontWeight: 700,
                    }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : totalMktAmount ? (
                      `${formatToIndianCurrency(totalMktAmount, valueConverter)}`
                    ) : (
                      'NA'
                    )}
                  </Typography>
                </Box>
              </Box>
              {overviewData?.assetAllocation
                ? overviewData?.assetAllocation.map((asset, index: number) => (
                  <AssetAllocationCard
                    key={index}
                    label={asset?.MACP}
                    investedPercent={asset?.PurValPercent}
                    currentPercent={asset?.MktValPercent}
                    investedValue={asset?.PurVal}
                    currentValue={asset?.MktVal}
                    labelStyles={{ border: 0 }}
                  />
                ))
                : [...Array(3)].map((asset, index: number) => (
                  <AssetAllocationCard
                    key={index}
                    label={asset?.assetCategory}
                    investedPercent={asset?.investedValuePercentage}
                    currentPercent={asset?.currentValPercentage}
                    investedValue={asset?.investedValue}
                    currentValue={asset?.currentValue}
                    isLoading={true}
                  />
                ))}
            </Box>
          </div>
          <div
            style={{
              maxHeight: '280px',
              display: `${flipAssetAllocationOverview ? 'block' : 'none'}`
            }}>
            <DataTable
              isLoading={isLoading}
              tableHeader={[...AssetAllocationOverviewTableHeaders()]}
              tableData={overviewData?.assetAllocation && overviewData?.assetAllocation}

              rowCustomStyles={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.MuiTableCell-body ': { color: '#686e72' },
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },

              }}
              tableHeaderCustomStyles={{
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },
              }}
              boxStyles={{ minHeight: '500px', minWidth: 450 }}
            />

          </div>
        </ReactCardFlip>
      </Box>
      <Box
        ref={portfolioPerformanceRef}
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
          mb: 7,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 16,
              lineHeight: '19px',
              fontWeight: 600,
              flex: 1,
            }}>
            Portfolio Performance
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              justifyContent: 'flex-start',
              gap: 3,
              alignItems: 'center',
            }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="caption"
                sx={{ color: '#0CDDC4', fontSize: '18px' }}>{`\u25CF`}</Typography>
              <Typography sx={{ fontSize: '12px' }}>Portfolio</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="caption"
                sx={{ color: '#F4C10E', fontSize: '18px' }}>{`\u25CF`}</Typography>
              <Typography sx={{ fontSize: '12px' }}>Sensex</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: 0.5,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography
                variant="caption"
                sx={{ color: '#730EF4', fontSize: '18px' }}>{`\u25CF`}</Typography>
              <Typography sx={{ fontSize: '12px' }}>Nifty</Typography>
            </Box>
          </Box>
          {/* <CopyGraphDropdown elementRef={portfolioPerformanceRef} /> */}
        </Box>
        <Divider />
        <Box>
          {isLoading ? (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
              <Skeleton sx={{ width: '80%', height: '300px' }} />
            </Box>
          ) : (
            <>
              <Formik
                initialValues={{ startDate: '', endDate: '' }}
                onSubmit={() => {
                  //
                }}>
                <Form>
                  <Box sx={{ px: 3, display: 'flex', flexDirection: 'row', gap: 2 }}>
                    <Box sx={{ maxWidth: { md: '120px', lg: '180px' } }}>
                      <DatePicker
                        label={'Start Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 11,
                          fontWeight: 400,
                          color: '#8E8EA1',
                        }}
                        textFieldStyles={{
                          mt: 2,
                        }}
                        placeholder={'dd/mm/yy'}
                        name={`startDate`}
                      />
                    </Box>
                    <Box sx={{ maxWidth: { md: '120px', lg: '180px' } }}>
                      <DatePicker
                        label={'End Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 11,
                          fontWeight: 400,
                          color: '#8E8EA1',
                        }}
                        textFieldStyles={{
                          mt: 2,
                        }}
                        placeholder={'dd/mm/yy'}
                        name={`endDate`}
                      />
                    </Box>
                  </Box>
                </Form>
              </Formik>
              <HighchartsReact highcharts={Highcharts} options={portfolioPerformaceGraphOptions} />
            </>
          )}
        </Box>
      </Box>
      {(isLoading || sectorWiseDataDisplay) && (
        <Box
          ref={sectorAllocationRef}
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              Sector Allocation
            </Typography>
            {sectorWiseData && sectorWiseData.length > 6 && (
              <Box>
                <Button
                  sx={{
                    fontSize: 14,
                    lineHeight: '16px',
                    color: '#4E5056',
                    fontWeight: 500,
                    padding: '4px 22px',
                    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                    border: '1px solid #0BAAE7',
                    borderRadius: '5px',
                  }}
                  onClick={() => setSectorAllocationView(!sectorAllocationView)}>
                  {sectorAllocationView ? 'View Less' : ' View all'}
                </Button>
                {/* <CopyGraphDropdown elementRef={sectorAllocationRef} /> */}
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon
              onClick={() => (setFlipSectorAllocationOverview(!flipSectorAllocationOverview))}
              style={{
                height: '20px',
                cursor: "pointer"
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipSectorAllocationOverview}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipSectorAllocationOverview ? 'none' : 'block'}`
              }}
            >
              <Box sx={{ p: 2, height: '410px', overflow: 'auto', ...scrollbarView }}>
                <Box>
                  <Box
                    sx={{
                      py: 3,
                      px: 0,
                      pb: 0.2,
                      position: 'relative',
                    }}>
                    <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                    {overviewData
                      ? sectorWiseDataDisplay &&
                      sectorWiseDataDisplay.map((ele, index: number) => {
                        const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                        return (
                          <RevenuePercent
                            key={index}
                            label={ele.Sector}
                            percent={ele.SecPercent}
                            amount={`${ele?.SecPercent}%`}
                            color={colors[index % 3]}
                            isLoading={isLoading}
                            popOverAmt={ele.I_MarKetValue}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })
                      : [...Array(5)].map((_, index: number) => {
                        return (
                          <RevenuePercent
                            key={index}
                            label=""
                            percent={0}
                            amount=""
                            color=""
                            isLoading={true}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </div>
            <div
              style={{
                maxHeight: '415px',
                display: `${flipSectorAllocationOverview ? 'block' : 'none'}`
              }}>
              <DataTable
                isLoading={isLoading}
                tableHeader={[...SectorAllocationOverviewTableHeaders()]}
                tableData={overviewData?.sectorAllocation && overviewData?.sectorAllocation}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />

            </div>
          </ReactCardFlip>
        </Box>
      )}
      <Grid container gap={2}>
        <Grid item xs={12} md >
          <Box
            sx={{
              height: '100%',
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              pageBreakBefore: 'always'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
                background: '#FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
              }}>
              <Typography
                sx={{
                  color: '#4E5056',
                  fontSize: 16,
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                Top 5 Performers
              </Typography>
            </Box>
            <Divider />
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                {overviewData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={headers}
                      tableData={overviewData?.topPerformers}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                        background: 'transparent',
                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md>
          <Box
            sx={{
              height: '100%',
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
                background: '#FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
              }}>
              <Typography
                sx={{
                  color: '#4E5056',
                  fontSize: 16,
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                Bottom 5 Performers
              </Typography>
            </Box>
            <Divider />
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                {overviewData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={headers}
                      tableData={overviewData?.bottomPerformers}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                        background: 'transparent',
                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      </Box>}
    </Box>
  );
}

import { Box, Button, Divider, Modal, Tab, Typography, Skeleton } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import "./styles.css";
import { useEffect, useState } from "react";
import { Bubble, PerformaneAnalysisChart, RecommendedSchemesModal, SubFundCategoryCard } from "./investor-transaction-helper";
import { formatToIndianCurrency } from "../../../utils/utilityFunctions";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
    CategoryAverage,
    DirectEquityOverviewType, SchemeDetails, SchemeList, SchemeNav, credit_ratings
} from '../../../redux-store/types/api-types';
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { getInvestorData } from "../../../redux-store/actions/userManagement";
import { TopHoldingButtons, YearSelecter } from "../../commonComponents";
// import FundApiGraph from "./area-chart";



const BorderLinearProgress = withStyles({
    root: {
        height: 10,
        backgroundColor: '#e4e4e4',
        borderRadius: 20,
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#337FC9',
    },
})(LinearProgress);

export default function SchemeDetail({
    handleBackClick,
    handleSetViewSchemeDetails,
    investorId,
    schemeDetail,
    schemeData,
    categoryAverage,
    schemeNav,
}: {
    handleBackClick: () => void;
    handleSetViewSchemeDetails: (val: boolean, schemeDetail: SchemeList | undefined | null) => void;
    investorId: string;
    schemeDetail: SchemeDetails | undefined;
    schemeData: SchemeList | undefined;
    categoryAverage: CategoryAverage[] | undefined;
    schemeNav: SchemeNav[] | undefined;
}) {
    const [isDebtOrLiq, setIsDebtOrLiq] = useState<boolean>(false);
    const [yearTabSelected, setYearTabSelected] = useState<any>("5Y");
    const [categoryReturn, setCategoryReturn] = useState<any>();
    const [schemeReturn, setSchemeReturn] = useState<any>();
    const [holdingType, setHoldingType] = useState<string>("");
    const [holdingValue, setHoldingValue] = useState<string>(schemeDetail?.header.AssetClassName === "Equity"
        ? "Sectors"
        : "Holdings");
    const [holdingRoutes, setHoldingRoutes] = useState<any>([]);
    const [holdingButtons, setHoldingButtons] = useState<any>([]);
    const [topHoldingData, setTopHoldingData] = useState<any>([])
    const [arrSectors, setArrSectors] = useState<any>([]);
    const [arrCompanies, setArrCompanies] = useState<any>([]);
    const [debtHolders, setDebtHolders] = useState<any>([]);
    const [debtRatings, setDebtRatings] = useState<any>([]);
    const [port_aggregates, setPort_aggregates] = useState<any>([]);
    const [headerObj, setHeaderObj] = useState<any>();
    const [exitLoad, setExitLoad] = useState<any>();
    const [fundManagement, setFundManagement] = useState<any>();
    const [arrReturnArray, setArrReturnArray] = useState<any>([]);
    const [selectedSchemes, setSelectedSchemes] = useState<SchemeList[]>(schemeData ? [schemeData] : []);
    const [graphArray, setGraphArray] = useState<any>([]);
    const [categoryAverageArray, setCategoryAverageArray] = useState<any>([]);
    const [openModal, setOpenModal] = useState(false);
    const [Recommend, setRecommended] = useState<boolean>(false)

    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const header = schemeDetail?.header;
        const assetClassName = header?.AssetClassName;
        const resposnedata = schemeData;
        if (resposnedata) {
            const arr = [];
            for (const key in resposnedata) {
                if (
                    (Object.prototype.hasOwnProperty.call(resposnedata, key) &&
                        key.includes("Cat_Return")) ||
                    (Object.prototype.hasOwnProperty.call(resposnedata, key) &&
                        key.includes("Sch_Return"))
                ) {
                    arr.push({ [key]: (resposnedata as any)[key] });
                }
            }
            setArrReturnArray(arr);
            performanceTabSelected(
                assetClassName === "Debt" || assetClassName === "Liquid" ? "6M" : "5Y"
                , arr);
        }
        schemeDetail?.header.AssetClassName == "Debt" || schemeDetail?.header.AssetClassName == "Liquid" ? setIsDebtOrLiq(true) : setIsDebtOrLiq(false);
        setHoldingType(assetClassName === "Equity"
            ? "Equity"
            : "Debt")
        setHoldingValue(assetClassName === "Equity"
            ? "Sectors"
            : "Holdings")
        setHoldingRoutes(assetClassName === "Equity"
            ? [
                { key: "Sectors", title: "Sectors" },
                { key: "Companies", title: "Companies" },
            ]
            : [
                { key: "Holdings", title: "Debt Holdings" },
                { key: "CreditRatings", title: "Debt Credit Ratings" },
            ])
        setHoldingButtons([
            {
                text: "Equity",
                percent: "40",
                holdingType: "equity_per",
                value: schemeDetail?.top_holdings.equity_per,
                defaultTab: "sectors"
            },
            { text: "Debt", percent: "60", holdingType: "debt_per", value: schemeDetail?.top_holdings.debt_per, defaultTab: "Holdings" },
        ])
        updateGraphInfo();
        handleHoldingValueChange(null, assetClassName === "Equity"
            ? "Sectors"
            : "Holdings",
            assetClassName === "Equity"
                ? "Equity"
                : "Debt");
    }, [schemeDetail]);

    useEffect(() => {
        if (!topHoldingData.length) {
            updateGraphInfo();
        }
        if (!graphArray.length && schemeNav?.length) {
            updateFundManagement();
        }
    }, [holdingType, categoryAverage, schemeNav, topHoldingData])

    const years = [
        {
            text: isDebtOrLiq ? "1M" : "1Y",
        },
        {
            text: isDebtOrLiq ? "3M" : "3Y",
        },
        {
            text: isDebtOrLiq ? "6M" : "5Y",
        },
        {
            text: isDebtOrLiq ? "1Y" : "7Y",
        },
        {
            text: isDebtOrLiq ? "3Y" : "10Y",
        },
    ];

    const updateGraphInfo = () => {
        const scheme_details = schemeDetail;
        if (scheme_details) {
            const _headerObj = scheme_details.header;
            const exitLoad = scheme_details.exit_load;
            const fundInfoObj = scheme_details.fund_management.map(
                ({ FUND_MANAGER }) => ({
                    value: FUND_MANAGER,
                })
            );
            const { header } = scheme_details;
            const assetClassName = !!header.AssetClassName && header.AssetClassName;
            setHeaderObj(_headerObj);
            setExitLoad(exitLoad);
            setFundManagement(fundInfoObj);
            setYearTabSelected(assetClassName === "Debt" || assetClassName === "Liquid"
                ? "1Y"
                : "5Y",)

            const nonNullarraySectors = scheme_details.top_holdings.sector_wise.filter(
                (item) => item.sector !== null
            );

            const nonNullarrayCompanies = scheme_details.top_holdings.company_wise.filter(
                (item) => item.name !== null
            );

            const {
                debt_holders,
                credit_ratings,
                portfolio_aggregates,
            } = scheme_details.top_holdings;

            const debtHolders = Object.keys(debt_holders[0]).map((key) => {
                return {
                    [key]: (debt_holders[0] as any)[key],
                };
            });

            const debt_ratings = credit_ratings.map((item: credit_ratings) => ({
                [item.NAME]: item.WEIGHTING,
            }));

            const port_aggregates = portfolio_aggregates.length
                ? Object.keys(portfolio_aggregates[0]).map((key) => ({
                    [key]: (portfolio_aggregates[0] as any)[key],
                }))
                : [];
            setArrSectors(nonNullarraySectors);
            setArrCompanies(nonNullarrayCompanies);
            setDebtHolders(debtHolders);
            setDebtRatings(debt_ratings);
            setPort_aggregates(port_aggregates);
        }
    };

    const performanceTabSelected = (value: any, arr: any) => {
        const assetClassName = schemeDetail?.header.AssetClassName;
        const schReturns =
            assetClassName === "Debt" || assetClassName === "Liquid"
                ? value === "1Y" || value === "5Y" || value === "3Y"
                    ? `Sch_Return${value.replace(/[^0-9]/g, "")}Yr`
                    : `Sch_Return${value.replace(/[^0-9]/g, "")}Mth`
                : `Sch_Return${value.replace(/[^0-9]/g, "")}Yr`;

        const catReturns =
            assetClassName === "Debt" || assetClassName === "Liquid"
                ? value === "1Y" || value === "5Y" || value === "3Y"
                    ? `Cat_Return${value.replace(/[^0-9]/g, "")}yr`
                    : `Cat_Return${value.replace(/[^0-9]/g, "")}Mth`
                : `Cat_Return${value.replace(/[^0-9]/g, "")}yr`;

        const schemReturn =
            arr.filter((value: any) =>
                Object.keys(value).includes(schReturns)
            )[0][schReturns];
        const catReturn =
            assetClassName === "Debt" || assetClassName === "Liquid"
                ? ""
                : arr.filter((value: any) =>
                    Object.keys(value).includes(catReturns)
                )[0][catReturns];
        setYearTabSelected(value);
        setCategoryReturn(catReturn);
        setSchemeReturn(schemReturn);
    };

    const updateFundManagement = () => {
        const cvg = categoryAverage?.map((item) => {
            return (item as any).map((i: any) => (
                {
                    x: new Date(i.SN_NAVDt).getTime(),
                    y: i.MrkValue,
                }
            ))
        })
        const result =
            schemeNav && !!schemeNav.length
                ? schemeNav
                    .filter((item) => !!item.SN_NAV && !!item.SN_NAVDt)
                    .map(({ SN_NAV, SN_NAVDt }) => ({
                        x: new Date(SN_NAVDt).getTime(),
                        y: SN_NAV,
                    }))
                : [];

        setGraphArray(result);
        setCategoryAverageArray(cvg);
    };

    const changeValue = (label: string) => () => {
        const assetClassName = schemeDetail?.header.AssetClassName;
        if (label === "Equity") {
            setHoldingType(label);
            setHoldingValue("Sectors");
            setHoldingRoutes([
                { key: "Sectors", title: "Sectors" },
                { key: "Companies", title: "Companies" },
            ]);

            handleHoldingValueChange(null, "Sectors", label);
        } else if (label === "Debt") {
            let routes = [
                { key: "Holdings", title: "Debt Holdings" },
                { key: "CreditRatings", title: "Debt Credit Ratings" },
            ];
            routes =
                assetClassName === "Liquid" ||
                    assetClassName === "Debt"
                    ? [
                        ...routes,
                        { key: "portfolio_aggregates", title: "Portfolio Aggregates" },
                    ]
                    : routes;
            setHoldingType(label);
            setHoldingValue("Holdings");
            setHoldingRoutes(routes);
            handleHoldingValueChange(null, "Holdings", label);
        }
    };

    const handleHoldingValueChange = (event: any, holdingValue: any, holding_type?: any) => {
        const hldtype = holding_type ? holding_type : holdingType;
        if (hldtype === "Equity") {
            const data =
                holdingValue === "Sectors"
                    ? arrSectors
                    : arrCompanies;
            setTopHoldingData(data);
        } else if (hldtype === "Debt") {
            let data =
                holdingValue === "Holdings"
                    ? debtHolders
                    : holdingValue === "CreditRatings"
                        ? debtRatings
                        : port_aggregates;
            data = (!!data && data.length ? data : [])
                .sort((a: any, b: any) => {
                    const first: any = Object.values(a)[0];
                    const second: any = Object.values(b)[0];
                    return second - first;
                })
                .map((ele: any) => {
                    const name = Object.entries(ele).pop()?.[0];
                    const Weighting = Object.entries(ele).pop()?.[1];
                    return { name, Weighting };
                });
            setTopHoldingData(data);
        }
        setHoldingValue(holdingValue);
    };

    const OnSchemeSelection = (schemeDetail: SchemeList) => {
        const schemeList = [...selectedSchemes, schemeDetail];
        setSelectedSchemes(schemeList);
    }

    const OnSchemeDeSelection = (schemeId: number) => {
        const schemeList = selectedSchemes.filter((val) => val.subid != schemeId);
        setSelectedSchemes(schemeList);
    }

    const plan =
        schemeDetail?.header.Growthoption === 1
            ? "Growth"
            : schemeDetail?.header.DividendReinvestment === 1
                ? "Div"
                : schemeDetail?.header.DividendPayout === 1
                    ? "Div"
                    : "";
    const subPlan =
        schemeDetail?.header.DividendReinvestment === 1
            ? "Reinvestment"
            : schemeDetail?.header.DividendPayout === 1
                ? "Payout"
                : "";

    const categoryValue = schemeData?.Cat_Return5yr;
    const selectedValue = schemeData?.Sch_Return5Yr;

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }

    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };

    const handleOpen = () => setRecommended(true);
    const handleClose = () => { setRecommended(false); setOpenModal(false); }
    console.log("schemedata", schemeDetail)
    return (
        <div style={{ width: "100%" }}>

            <Box
                sx={{
                    width: '100%',
                    '& .MuiTabPanel-root': { py: 2, px: 0 },
                    '& .MuiTab-root': {
                        color: '#A1A2A2',
                        opacity: 0.8,
                        fontSize: 17,
                        lineHeight: '24px',
                        textTransform: 'capitalize',
                        px: { xs: 2, md: 3, lg: 5 },
                        '&.Mui-selected': {
                            color: '#4B81B1',
                        },
                    },
                    '& .MuiTabs-indicator': {
                        height: 3,
                        background: '#4B81B1',
                    },
                }}>
                <Box sx={{ px: 3, display: 'flex', alignItems: 'center', width: '100%', mb: 2 }}>

                    <ArrowBackIosIcon sx={{ cursor: 'pointer' }}
                        onClick={() => { handleSetViewSchemeDetails(false, null) }}
                    />
                    <Box sx={{ display: 'flex', alignItems: 'center', mx: 3, width: '80%' }}>
                        <div style={{ marginLeft: 16, marginRight: 16 }}>
                            <Typography sx={{ fontSize: 18, fontWeight: 500 }}>{schemeData?.SchemeName}</Typography>
                        </div>
                        <Box sx={{ display: 'flex' }}>
                            {schemeData?.AssetClassName && (
                                <Bubble text={schemeData?.AssetClassName} />
                            )}
                            {schemeData?.Sub_AssetclassName && (
                                <Bubble text={schemeData?.Sub_AssetclassName} />
                            )}
                            {!!plan && <Bubble text={plan} />}
                            {subPlan.length !== 0 && <Bubble text={subPlan} />}
                            {!!subPlan && schemeData?.Dividendfrequency && (
                                <Bubble text={schemeData?.Dividendfrequency} />
                            )}
                        </Box>
                    </Box>
                    {location.pathname === "/recommended-schemes" ? '' :
                        <Button
                            sx={{
                                marginLeft: '20px',
                                height: '27px',
                                color: 'white',
                                fontSize: '13px',
                                cursor: 'pointer',
                                borderRadius: '5px',
                                bgcolor: '#337FC9',
                                '&:hover': { backgroundColor: '#337FC9' },
                            }}
                            onClick={handleModalOpen}
                        >Transaction</Button>
                    }
                </Box>

                <Divider />

                <Box sx={{ px: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', my: 2.5 }}>
                        <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>NAV as on {schemeDetail?.header.Nav_Date}</Typography>
                            <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                {schemeDetail?.header.SN_NAV}
                            </Typography>
                        </Box>
                        <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>AUM</Typography>
                            <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                {schemeDetail?.header.MinimumPurchaseAmount
                                    ? formatToIndianCurrency(schemeDetail?.header.Aum, 1)
                                    : ""}
                            </Typography>
                        </Box>

                        <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Category Avg Returns</Typography>
                            <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                {`${Nanprocessor(roundToTwo(Number(categoryValue)))}%`}
                            </Typography>
                        </Box>

                        <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Returns</Typography>
                            <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                {selectedValue === "0" || !selectedValue
                                    ? "NA"
                                    : `${Nanprocessor(roundToTwo(Number(selectedValue)))}%`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                    {/* Performance Analysis Graph */}
                    <Box
                        sx={{
                            display: 'flex',
                            // alignItems: 'center',
                            justifyContent: 'space-between',
                            flexDirection: 'column',
                            border: '1px solid black',
                            borderRadius: '10px',
                            width: '90%'
                        }}>
                        <Box sx={{
                            width: '100%', bgcolor: '#337FC9', px: 4, py: 1,
                            borderRadius: '10px 10px 0px 0px'
                        }}>
                            <Typography
                                sx={{
                                    color: 'white',

                                    fontSize: 16,
                                    lineHeight: '19px',
                                    fontWeight: 600,
                                    flex: 1,
                                }}>
                                Portfolio Performance
                            </Typography>
                        </Box>
                        <Box sx={{
                            borderRadius: '10px', py: 2

                        }}>
                            {graphArray && graphArray.length !== 0 && categoryAverageArray && categoryAverageArray.length !== 0 && <PerformaneAnalysisChart yearSelected={yearTabSelected} graphArray={graphArray} categoryAverageArray={categoryAverageArray} />}

                            <div className="fundPerformanceDataDiv">
                                <div key="Category Return">
                                    <div className="catText">Category Return</div>
                                    <div className="getStarted">
                                        {Number(categoryReturn || 0).toFixed(2)}%
                                    </div>
                                </div>

                                <div className="fundPerFormanceDiv">
                                    {years.map((ele, index) => (
                                        <YearSelecter
                                            text={ele.text}
                                            marginH={2}
                                            paddingV={4}
                                            key={index}
                                            onPress={() => performanceTabSelected(ele.text, arrReturnArray)}
                                            isSelected={yearTabSelected === ele.text}
                                        />
                                    ))}
                                </div>

                                <div key="Category Return">
                                    <div className="catText">Scheme Return</div>
                                    <div className="getStarted">
                                        {Number(schemeReturn || 0).toFixed(2)}%
                                    </div>
                                </div>
                            </div>

                        </Box>
                    </Box>

                    {/* Bottom 2 cards Box */}
                    <Box sx={{ width: '90%', display: 'flex', justifyContent: 'space-between', mt: 2, height: '300px' }}>

                        {/* Fund Manager */}
                        <Box
                            sx={{
                                display: 'flex',
                                // alignItems: 'center',
                                // justifyContent: 'center',
                                flexDirection: 'column',
                                border: '1px solid black',
                                borderRadius: '10px',
                                width: '45%',
                                height: '100%',
                            }}>
                            <Box sx={{
                                width: '100%', bgcolor: '#337FC9', px: 4, py: 1,
                                borderRadius: '10px 10px 0px 0px'
                            }}>
                                <Typography
                                    sx={{
                                        color: 'white',

                                        fontSize: 16,
                                        lineHeight: '19px',
                                        fontWeight: 600,
                                        flex: 1,
                                    }}>
                                    Fund Manager
                                </Typography>
                            </Box>
                            <Box
                                sx={{
                                    overflowY: 'scroll'
                                }}>
                                {fundManagement?.map((item: any, index: any) => {
                                    return <Box sx={{ p: 2 }} key={index}>
                                        <div>
                                            <Typography sx={{ fontSize: "14px", fontWeight: 400 }} >{item.value}</Typography>
                                            {/* <Typography sx={{ fontSize: "11px" }}>oct 2019 - {<span style={{ fontWeight: 500 }}>present</span>}</Typography> */}
                                        </div>
                                    </Box>
                                })}
                            </Box>
                        </Box>

                        {/* Top Holdings */}
                        <Box
                            sx={{
                                display: 'flex',
                                // alignItems: 'center',
                                justifyContent: 'space-between',
                                flexDirection: 'column',
                                border: '1px solid black',
                                borderRadius: '10px',
                                width: '45%',
                                height: '300px'
                            }}>
                            {/* top holding header */}
                            <Box sx={{
                                width: '100%', bgcolor: '#337FC9', px: 4, py: 1,
                                borderRadius: '10px 10px 0px 0px'
                            }}>
                                <Typography
                                    sx={{
                                        color: 'white',

                                        fontSize: 16,
                                        lineHeight: '19px',
                                        fontWeight: 600,
                                        flex: 1,
                                    }}>
                                    Top Holdings
                                </Typography>
                            </Box>
                            {/* top holding body */}
                            <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                                <Box sx={{ overflowY: 'scroll', width: '100%', height: '265px', overflowX: 'hidden' }}>
                                    <div className="spaceBetween top-holding-btn">
                                        {holdingButtons.map((ele: any, ind: any) => (
                                            ele.value && (
                                                <div onClick={changeValue(ele.text)} key={ind} style={{ width: "50%" }}>
                                                    <TopHoldingButtons
                                                        label={ele.text}
                                                        selected={holdingType}
                                                        percentage={ele.value}
                                                        style={{ width: "100%" }}
                                                    />
                                                </div>
                                            )
                                        ))}
                                    </div>

                                    <TabContext value={holdingValue} >
                                        <Box sx={{ px: 1 }}>
                                            <TabList
                                                onChange={handleHoldingValueChange}
                                                aria-label="product tabs"
                                                variant="scrollable"
                                                scrollButtons="auto">
                                                {
                                                    holdingRoutes.map((ele: any, ind: any) => {
                                                        return <Tab label={ele.title} value={ele.key} key={ind} />
                                                    })
                                                }
                                            </TabList>
                                        </Box>
                                        <Divider />

                                        <Box sx={{ px: 1, width: '100%' }}>
                                            {holdingRoutes.map((ele: any, ind: any) => {
                                                return <TabPanel value={ele.key} key={ind} >

                                                    <div>
                                                        {topHoldingData.map((ele: any) => (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    marginTop: 10,
                                                                    width: '100%',
                                                                    padding: "0 10px",
                                                                }}
                                                                key={ele.name}
                                                            >
                                                                <Typography sx={{ fontSize: "14px", fontWeight: 400 }}>{ele.sector || ele.name}</Typography>
                                                                <div className="homeContainer">
                                                                    <BorderLinearProgress
                                                                        // className={classes.margin}
                                                                        style={{ marginTop: 4, width: "85%" }}
                                                                        variant="determinate"
                                                                        color="secondary"
                                                                        value={parseFloat(ele.Weighting || 0)}
                                                                    />
                                                                    <Typography style={{ marginLeft: 15, color: "gray" }}>
                                                                        {parseFloat(ele.Weighting || 0).toFixed(2) + "%"}
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </TabPanel>
                                            })}

                                        </Box>

                                    </TabContext>
                                </Box>
                            </Box>
                        </Box>

                    </Box>


                </Box>


            </Box>

            <Modal
                open={openModal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: 24,
                    border: 'none',
                    py: 3
                }}>
                    <RecommendedSchemesModal handleModalClose={handleModalClose} selectedSchemes={selectedSchemes} OnSchemeDeSelection={OnSchemeDeSelection} investorId={investorId} isRecommended={(val: boolean) => { setRecommended(val) }} />
                    {/* {Recommend ? <Modal
                        open={Recommend}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute' as any,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            borderRadius: '10px',
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                            <Typography>Schemes Recommended</Typography>
                            <Button onClick={handleClose} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                        </Box>
                    </Modal> : <></>} */}
                </Box>
            </Modal>
        </div>
    )
}
import { FormHelperText, InputLabel, Stack, Typography } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useField, useFormikContext } from 'formik';
import { useState } from 'react';

export const DayJsDatePicker = (
    { id, name }:
        { id: string; name: string; }
) => {
    const currentDate = dayjs();
    const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
    const formikContext = useFormikContext();
    const [field, meta, { setValue }] = useField(name);
    const [error, setError] = useState(false);
    // const onChange = (date: any) => {
    //     date == 'Invalid Date' && setError(true);
    //     date != 'Invalid Date' && setError(false);
    //     setValue(date);
    // };
    const errorText = error ? 'Invalid Date' : meta.error && meta.touched ? meta.error : '';

    return (
        <Stack>
            <InputLabel
                shrink
                htmlFor={id}
                sx={{
                    transform: 'unset',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'rgba(0,0,0,0.7)',
                    mb: 1.8
                }}>
                DOB (MM/DD/YYYY)
            </InputLabel>
            <LocalizationProvider dateAdapter={AdapterDayjs}>

                <DatePicker
                    label=""
                    value={dayjs(dates)}
                    name={name}
                    onChange={(date: any) => {
                        setDates(date.format("YYYY-MM-DD"));
                        formikContext.setFieldValue('dob', date.format("YYYY-MM-DD"));
                    }
                    }
                    sx={{
                        fontSize: 14,
                        fontWeight: 500,
                        p: -3
                    }}
                />
            </LocalizationProvider>
            {errorText && (
                <FormHelperText error sx={{ marginLeft: 'unset' }}>
                    {errorText}
                </FormHelperText>
            )}
        </Stack>
    )
}
export const VERIFY_PAN = 'VERIFY_PAN';
export const VERIFY_PAN_SUCCESS = 'VERIFY_PAN_SUCCESS';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIF_OTP_SUCCESS = 'VERIF_OTP_SUCCESS';

export const GET_DETAILS_SUCCESS = 'GET_DETAILS_SUCCESS';
export const GET_DETAILS = 'GET_DETAILS';

export const GET_OTP_SUCCESS = 'GET_OTP_SUCCESS';
export const GET_OTP = 'GET_OTP';

export const VERIFY_OTP_INVESTOR_SUCCESS = 'VERIFY_OTP_INVESTOR_SUCCESS';
export const VERIFY_OTP_INVESTOR = 'VERIFY_OTP_INVESTOR';

export const GET_APPLICATION_WITH_REF_ID_SUCCESS = 'GET_APPLICATION_WITH_REF_ID_SUCCESS';
export const GET_APPLICATION_WITH_REF_ID = 'GET_APPLICATION_WITH_REF_ID';

export const ESIGN_DOCUMENT_SUCCESS = 'ESIGN_DOCUMENT_SUCCESS';
export const ESIGN_DOCUMENT = 'ESIGN_DOCUMENT';

export const GET_Investment_Details_Aplication_Ref_SUCCESS =
  'GET_Investment_Details_Aplication_Ref_SUCCESS';
export const GET_Investment_Details_Aplication_Ref = 'GET_Investment_Details_Aplication_Ref';

export const GET_RM_LIST_SUCCESS = 'GET_RM_LIST_SUCCESS';
export const GET_RM_LIST = 'GET_RM_LIST';

export const GET_FAMILY_NAMES_DATA = 'GET_FAMILY_NAMES_DATA';
export const GET_RM_DASHBOARD_METRICS_SUCCESS = 'GET_RM_DASHBOARD_METRICS_SUCCESS';
export const GET_RM_DASHBOARD_METRICS = 'GET_RM_DASHBOARD_METRICS';

export const GET_RM_REDIRECTION_LINK_SUCCESS = 'GET_RM_REDIRECTION_LINK_SUCCESS';
export const GET_RM_REDIRECTION_LINK = 'GET_RM_REDIRECTION_LINK';

export const GET_ASSET_CLASSES = 'GET_ASSET_CLASSES';
export const GET_ASSET_CLASSES_SUCCESS = 'GET_ASSET_CLASSES_SUCCESS';
export const GET_ASSET_CLASSES_FAILED = 'GET_ASSET_CLASSES_FAILED';
export const GET_SUB_ASSET_CLASSES = 'GET_SUB_ASSET_CLASSES';
export const GET_SUB_ASSET_CLASSES_SUCCESS = 'GET_SUB_ASSET_CLASSES_SUCCESS';
export const GET_SUB_ASSET_CLASSES_FAILED = 'GET_SUB_ASSET_CLASSES_FAILED';
export const GET_SCHEME_LIST = 'GET_SCHEME_LIST';
export const GET_SCHEME_LIST_SUCCESS = 'GET_SCHEME_LIST_SUCCESS';
export const GET_SCHEME_LIST_FAILED = 'GET_SCHEME_LIST_FAILED';
export const GET_SCHEME_DETAILS = 'GET_SCHEME_DETAILS';
export const GET_SCHEME_DETAILS_SUCCESS = 'GET_SCHEME_DETAILS_SUCCESS';
export const GET_SCHEME_DETAILS_FAILED = 'GET_SCHEME_DETAILS_FAILED';
export const GET_CATEGORY_AVERAGE = 'GET_CATEGORY_AVERAGE';
export const GET_CATEGORY_AVERAGE_SUCCESS = 'GET_CATEGORY_AVERAGE_SUCCESS';
export const GET_CATEGORY_AVERAGE_FAILED = 'GET_CATEGORY_AVERAGE_FAILED';
export const GET_SCHEME_NAV = 'GET_SCHEME_NAV';
export const GET_SCHEME_NAV_SUCCESS = 'GET_SCHEME_NAV_SUCCESS';
export const GET_SCHEME_NAV_FAILED = 'GET_SCHEME_NAV_FAILED';
export const POST_RECOMMENDED_SCHEMES = 'POST_RECOMMENDED_SCHEMES';
export const POST_RECOMMENDED_SCHEMES_SUCCESS = 'POST_RECOMMENDED_SCHEMES_SUCCESS';
export const POST_RECOMMENDED_SCHEMES_FAILED = 'POST_RECOMMENDED_SCHEMES_FAILED';
export const DELETE_RECOMMENDED_SCHEMES = 'DLETE_RECOMMENDED_SCHEMES';
export const DELETE_RECOMMENDED_SCHEMES_SUCCESS = 'DELETE_RECOMMENDED_SCHEMES_SUCCESS';
export const DELETE_RECOMMENDED_SCHEMES_FAILED = 'DELETE_RECOMMENDED_SCHEMES_FAILED';
export const EDIT_RECOMMENDED_SCHEMES = 'EDIT_RECOMMENDED_SCHEMES';
export const EDIT_RECOMMENDED_SCHEMES_SUCCESS = 'EDIT_RECOMMENDED_SCHEMES_SUCCESS';
export const EDIT_RECOMMENDED_SCHEMES_FAILED = 'EDIT_RECOMMENDED_SCHEMES_FAILED';
export const GET_RECOMMENDATION_VALIDATION = 'GET_RECOMMENDATION_VALIDATION';
export const GET_FOLIOLIST = "GET_FOLIOLIST";
export const GET_FOLIOLIST_SUCCESS = "GET_FOLIOLIST_SUCCESS";
export const GET_FOLIOLIST_FAILED = "GET_FOLIOLIST_FAILED";
export const GET_RECOMMENDATION_VALIDATION_SUCCESS = 'GET_RECOMMENDATION_VALIDATION_SUCCESS';
export const GET_RECOMMENDATION_VALIDATION_FAILED = 'GET_RECOMMENDATION_VALIDATION_FAILED';
export const GET_RECOMMENDED_SCHEMES = 'GET_RECOMMENDED_SCHEMES';
export const GET_RECOMMENDED_SCHEMES_SUCCESS = 'GET_RECOMMENDED_SCHEMES_SUCCESS';
export const GET_RECOMMENDED_SCHEMES_FAILED = 'GET_RECOMMENDED_SCHEMES_FAILED';
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE';
export const GET_ACCOUNT_TYPE_SUCCESS = 'GET_ACCOUNT_TYPE_SUCCESS';
export const GET_ACCOUNT_TYPE_FAILED = 'GET_ACCOUNT_TYPE_FAILED';
export const GET_PORTFOLIO_SUCCESS = 'GET_PORTFOLIO_SUCCESS'
export const GET_PORTFOLIO_FAILED = 'GET_PORTFOLIO_FAILED'
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS'
export const GET_ORDERS_FAILED = 'GET_ORDERS_FAILED'
export const GET_SWP_SUCCESS = 'GET_SWP_SUCCESS'
export const GET_SWP_FAILED = 'GET_SWP_FAILED'
export const GET_SCHEMES_SUCCESS = 'GET_SCHEMES_SUCCESS'
export const GET_SCHEMES_FAILED = 'GET_SCHEMES_FAILED'

export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS'
export const GET_ACCOUNT_FAILED = 'GET_ACCOUNT_FAILED'

export const GET_RM_CREATE_TRANSACTION = 'GET_RM_CREATE_TRANSACTION'
export const GET_RM_CREATE_TRANSACTION_SUCCESS = 'GET_RM_CREATE_TRANSACTION_SUCCESS'
export const GET_RM_CREATE_TRANSACTION_FAILED = 'GET_RM_CREATE_TRANSACTION_FAILED'

export const GET_BSE_CODE = 'GET_BSE_CODE'
export const GET_BSE_CODE_SUCCESS = 'GET_BSE_CODE_SUCCESS'
export const GET_BSE_CODE_FAILED = 'GET_BSE_CODE_FAILED'

export const GET_CLIENT_MANDATEID = 'GET_CLIENT_MANDATEID'
export const GET_CLIENT_MANDATEID_SUCCESS = 'GET_CLIENT_MANDATEID_SUCCESS'
export const GET_CLIENT_MANDATEID_FAILED = 'GET_CLIENT_MANDATEID_FAILED'
export const BALANCE_VALIDATION = 'BALANCE_VALIDATION'
export const BALANCE_VALIDATION_SUCCESS = 'BALANCE_VALIDATION_SUCCESS'
export const BALANCE_VALIDATION_FAILED = 'BALANCE_VALIDATION_FAILED'
export const GET_DISTRIBUTORS_LIST_SUCCESS = 'GET_DISTRIBUTORS_LIST_SUCCESS';
export const GET_DISTRIBUTORS_LIST = 'GET_DISTRIBUTORS_LIST';

export const ADD_RMS_LIST_SUCCESS = 'ADD_RMS_LIST_SUCCESS';
export const ADD_RMS_LIST = 'ADD_RMS_LIST';

export const ADD_DISTRIBUTOR_SUCCESS = 'ADD_DISTRIBUTOR_SUCCESS';
export const ADD_DISTRIBUTOR = 'ADD_DISTRIBUTOR';

export const GET_SUBDISTRIBUTOR_SUCCESS = 'GET_SUBDISTRIBUTOR_SUCCESS';
export const GET_SUBDISTRIBUTOR = 'GET_SUBDISTRIBUTOR';

export const ENABLE_POA_SUCCESS = 'ENABLE_POA_SUCCESS';
export const ENABLE_POA = 'ENABLE_POA';

export const EDIT_DISTRIBUTOR_SUCCESS = 'EDIT_DISTRIBUTOR_SUCCESS';
export const EDIT_DISTRIBUTOR = 'EDIT_DISTRIBUTOR';

export const EDIT_RM_SUCCESS = 'EDIT_RM_SUCCESS';
export const EDIT_RM = 'EDIT_RM';

export const GET_Countries_SUCCESS = 'GET_Countries_SUCCESS';
export const GET_Countries = 'GET_Countries';

export const APPLICABLE_FOR_POA_SUCCESS = 'APPLICABLE_FOR_POA_SUCCESS';
export const APPLICABLE_FOR_POA = 'APPLICABLE_FOR_POA';

export const GET_TRUSTEE_LIST_SUCCESS = 'GET_TRUSTEE_LIST_SUCCESS';
export const GET_TRUSTEE_LIST = 'GET_TRUSTEE_LIST';

export const ADD_TRUSTEE_SUCCESS = 'ADD_TRUSTEE_SUCCESS';
export const ADD_TRUSTEE = 'ADD_TRUSTEE';

export const UPDATE_TRUSTEE_SUCCESS = 'UPDATE_TRUSTEE_SUCCESS';
export const UPDATE_TRUSTEE = 'UPDATE_TRUSTEE';

export const GET_FUND_MANAGERS_LIST_SUCCESS = 'GET_FUND_MANAGERS_LIST_SUCCESS';
export const GET_FUND_MANAGERS_LIST = 'GET_FUND_MANAGERS_LIST';

export const GET_AIF_APPROVER_LIST_SUCCESS = 'GET_AIF_APPROVER_LIST_SUCCESS';
export const GET_AIF_APPROVER_LIST = 'GET_AIF_APPROVER_LIST';

export const ADD_DISTRIBUTOR_RM_SUCCESS = 'ADD_DISTRIBUTOR_RM_SUCCESS';
export const ADD_DISTRIBUTOR_RM = 'ADD_DISTRIBUTOR_RM';

export const ADD_FUND_MANAGER_SUCCESS = 'ADD_FUND_MANAGER_SUCCESS';
export const ADD_FUND_MANAGER = 'ADD_FUND_MANAGER';

export const GET_AUTHORISEDSIGNATORY_LIST_SUCCESS = 'GET_AUTHORISEDSIGNATORY_LIST_SUCCESS';
export const GET_AUTHORISEDSIGNATORY_LIST = 'GET_AUTHORISEDSIGNATORY_LIST';

export const ADD_AUTHORISEDSIGNATORY_SUCCESS = 'ADD_AUTHORISEDSIGNATORY_SUCCESS';
export const ADD_AUTHORISEDSIGNATORY = 'ADD_AUTHORISEDSIGNATORY';

export const UPDATE_AUTHORISEDSIGNATORY_SUCCESS = 'UPDATE_AUTHORISEDSIGNATORY_SUCCESS';
export const UPDATE_AUTHORISEDSIGNATORY = 'UPDATE_AUTHORISEDSIGNATORY';

export const UPDATE_FUND_MANAGER_SUCCESS = 'UPDATE_FUND_MANAGER_SUCCESS';
export const UPDATE_FUND_MANAGER = 'UPDATE_FUND_MANAGER';

export const UPDATE_DISTRIBUTOR_RM_SUCCESS = 'UPDATE_DISTRIBUTOR_RM_SUCCESS';
export const UPDATE_DISTRIBUTOR_RM = 'UPDATE_DISTRIBUTOR_RM';

export const GET_POAAPPROVER_LIST_SUCCESS = 'GET_POAAPPROVER_LIST_SUCCESS';
export const GET_POAAPPROVER_LIST = 'GET_POAAPPROVER_LIST';

export const ADD_POAAPPROVER_SUCCESS = 'ADD_POAAPPROVER_SUCCESS';
export const ADD_POAAPPROVER = 'ADD_POAAPPROVER';

export const UPDATE_POAAPPROVER_SUCCESS = 'UPDATE_POAAPPROVER_SUCCESS';
export const UPDATE_POAAPPROVER = 'UPDATE_POAAPPROVER';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL = 'SEND_EMAIL';

export const ONBOARDING_SUMMARY_REPORT = 'ONBOARDING_SUMMARY_REPORT';
export const ONBOARDING_SUMMARY_REPORT_SUCCESS = 'ONBOARDING_SUMMARY_REPORT_SUCCESS';

export const ONBOARDING_DETAIL_REPORT = 'ONBOARDING_DETAIL_REPORT';
export const ONBOARDING_DETAIL_REPORT_SUCCESS = 'ONBOARDING_DETAIL_REPORT_SUCCESS';

export const DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_SUCCESS =
  'DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_SUCCESS';
export const DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT = 'DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT';

export const ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS =
  'ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS';
export const ONBOARDING_SUMMARY_REPORT_DOWNLOAD = 'ONBOARDING_SUMMARY_REPORT_DOWNLOAD';

export const ONBOARDING_DETAIL_REPORT_DOWNLOAD_SUCCESS =
  'ONBOARDING_DETAIL_REPORT_DOWNLOAD_SUCCESS';
export const ONBOARDING_DETAIL_REPORT_DOWNLOAD = 'ONBOARDING_DETAIL_REPORT_DOWNLOAD';

export const ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL_SUCCESS =
  'ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL_SUCCESS';
export const ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL = 'ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL';

ONBOARDING_DETAIL_REPORT_DOWNLOAD_ALL_SUCCESS;

export const DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS =
  'DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD_SUCCESS';
export const DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD =
  'DISTRIBUTOR_ONBOARDING_SUMMARY_REPORT_DOWNLOAD';

export const DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_SUCCESS =
  'DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_SUCCESS';
export const DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT = 'DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT';

export const DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD_SUCCESS =
  'DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD_SUCCESS';
export const DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD =
  'DISTRIBUTOR_COMMITMENT_AMOUNT_REPORT_DOWNLOAD';

export const ACKNOWLEDGE_AML_CHECK_SUCCESS = 'ACKNOWLEDGE_AML_CHECK_SUCCESS';
export const ACKNOWLEDGE_AML_CHECK = 'ACKNOWLEDGE_AML_CHECK';

export const GET_AIFAPPROVERACCESS_SUCCESS = 'GET_AIFAPPROVERACCESS_SUCCESS';
export const GET_AIFAPPROVERACCESS = 'GET_AIFAPPROVERACCESS';

export const GET_RMBYID_SUCCESS = 'GET_RMBYID_SUCCESS';
export const GET_RMBYID = 'GET_RMBYID';

export const GET_DISTRIBUTORRMBYID_SUCCESS = 'GET_DISTRIBUTORRMBYID_SUCCESS';
export const GET_DISTRIBUTORRMBYID = 'GET_DISTRIBUTORRMBYID_SUCCESS';

export const GET_AUTHORISEDSIGNATORYBYID_SUCCESS = 'GET_AUTHORISEDSIGNATORYBYID_SUCCESS';
export const GET_AUTHORISEDSIGNATORYBYID = 'GET_AUTHORISEDSIGNATORYBYID';

export const GET_POAAPPROVERBYID_SUCCESS = 'GET_POAAPPROVERBYID_SUCCESS';
export const GET_POAAPPROVERBYID = 'GET_POAAPPROVERBYID';

export const GET_TRUSTEEBYID_SUCCESS = 'GET_TRUSTEEBYID_SUCCESS';
export const GET_TRUSTEEBYID = 'GET_TRUSTEEBYID';

export const GET_AIF_APPROVERBYID_SUCCESS = 'GET_AIF_APPROVERBYID_SUCCESS';
export const GET_AIF_APPROVERBYID = 'GET_AIF_APPROVERBYID';

export const GET_FUND_MANAGERSBYID_SUCCESS = 'GET_FUND_MANAGERSBYID_SUCCESS';
export const GET_FUND_MANAGERSBYID = 'GET_FUND_MANAGERSBYID';

export const GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS = 'GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS';
export const GET_DISTRIBUTORSOFRM_BY_RMID = 'GET_DISTRIBUTORSOFRM_BY_RMID';

export const GET_TRUSTEEACCESS_SUCCESS = 'GET_TRUSTEEACCESS_SUCCESS';
export const GET_TRUSTEEACCESS = 'GET_TRUSTEEACCESS';

export const GET_IMACCESS_SUCCESS = 'GET_IMACCESS_SUCCESS';
export const GET_IMACCESS = 'GET_IMACCESS';

export const GET_AMCADMINACCESS_SUCCESS = 'GET_AMCADMINACCESS_SUCCESS';
export const GET_AMCADMINACCESS = 'GET_AMCADMINACCESS';

export const GET_AMCADMIN_LIST_SUCCESS = 'GET_AMCADMIN_LIST_SUCCESS';
export const GET_AMCADMIN_LIST = 'GET_AMCADMIN_LIST';

export const GET_AMCADMINBYID_SUCCESS = 'GET_AMCADMINBYID_SUCCESS';
export const GET_AMCADMINBYID = 'GET_AMCADMINBYID';

export const ADD_AMCADMIN_SUCCESS = 'ADD_AMCADMIN_SUCCESS';
export const ADD_AMCADMIN = 'ADD_AMCADMIN';

export const UPDATE_AMCADMIN_SUCCESS = 'UPDATE_AMCADMIN_SUCCESS';
export const UPDATE_AMCADMIN = 'UPDATE_AMCADMIN';

export const SEND_CONSENTEMAIL_SUCCESS = 'SEND_CONSENTEMAIL_SUCCESS';
export const SEND_CONSENTEMAIL = 'SEND_CONSENTEMAIL';

export const SEND_CONSENTEMAIL_APPROVE_SUCCESS = 'SEND_CONSENTEMAIL_APPROVE_SUCCESS';
export const SEND_CONSENTEMAIL_APPROVE = 'SEND_CONSENTEMAIL_APPROVE';

export const SEND_CONSENTEMAIL_REJECT_SUCCESS = 'SEND_CONSENTEMAIL_REJECT_SUCCESS';
export const SEND_CONSENTEMAIL_REJECT = 'SEND_CONSENTEMAIL_REJECT';

export const DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT_SUCCESS =
  'DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT_SUCCESS';
export const DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT =
  'DISTRIBUTOR_COMPLETED_APPLICATIONS_REPORT';

export const ONBOARDING_COMPLETED_REPORT_DOWNLOAD_SUCCESS =
  'ONBOARDING_COMPLETED_REPORT_DOWNLOAD_SUCCESS';
export const ONBOARDING_COMPLETED_REPORT_DOWNLOAD = 'ONBOARDING_COMPLETED_REPORT_DOWNLOAD';

export const GET_APPLICATION_DOWNLOAD_SUCCESS = 'GET_APPLICATION_DOWNLOAD_SUCCESS';
export const GET_APPLICATION_DOWNLOAD = 'GET_APPLICATION_DOWNLOAD';

export const SEND_BSE_CONSENT_SUCCESS = 'SEND_BSE_CONSENT_SUCCESS';
export const SEND_BSE_CONSENT = 'SEND_BSE_CONSENT';

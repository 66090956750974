import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Divider,
    Menu,
    MenuItem,
    Select,
    Typography,
    Modal,
    CircularProgress
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { BSECodeResponse, GetAssetClassesType, GetCategoryAverage, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, RecommendationsType, SchemeList } from '../../../redux-store/types/api-types';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { RM_create_transaction, balance_validation, getRmDetails, get_bse_code, get_recommendation_validation, post_recommended_schemes, redeem_validation } from '../../../redux-store/actions/userManagement';
import { RedemptionErrorType } from './types';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name: string, dataName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            dataName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function Redeem({
    location
}: {
    location: Location<{ investorId: string; investorName: string; holdingName: any }>
}): JSX.Element {

    const { investorId, investorName, holdingName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Holdings');
    const [folioName, setFolioName] = React.useState<string[]>([]);
    const [schemeName, setSchemeName] = React.useState<string[]>([]);
    const [amcName, setAmcName] = React.useState<string[]>([]);
    const [accountType, setAccountType] = React.useState<string[]>([]);
    const [submitData, setSubmitData] = useState<{ schemeName: string, folio: string, redemptionType: string, redemptionValue: number }[]>();
    const [rmId, setRmId] = useState<number | null>();
    const [redeemPageData, setRedeemPageData] = useState<any>({ ...holdingName, redeemValue: { value: "", type: "INR" }, redeemType: "Partial", });
    const [Recommend, setRecommended] = useState<boolean>(false);
    const [radioValue, setRadioValue] = useState<string>('recommend');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [placeOrderResponse, setPlaceOrderResponse] = useState<any>();
    const [balanceLeft, setBalanceLeft] = useState<any>();

    const handleRadioChange = (event: any, value: any) => {
        setRadioValue(event.target.value);
    };

    const errorStates: RedemptionErrorType = {
        errorRedemptionValue: false,
        errorRedemptionValueMsg: ""
    }


    const [error, setError] = useState<RedemptionErrorType>(errorStates);
    const [redeemBalance, setRedeemBalance] = useState<any>();


    async function getRedeemValidations() {
        let isComponentActive = true;
        setRedeemBalance(null);
        (async function () {
            try {
                const response = (await dispatch(
                    redeem_validation({
                        AccountID: redeemPageData.AccountID,
                        AccNo: redeemPageData.folio,
                        SchId: `${redeemPageData.schemeID}`
                    })
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRedeemBalance(response.validation[0][0]);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    async function getBalanceValidations() {
        let isComponentActive = true;
        setBalanceLeft(null);
        (async function () {
            try {
                const response = (await dispatch(
                    balance_validation({
                        AccountID: redeemPageData.AccountID,
                        AccNo: redeemPageData.folio,
                        SchId: `${redeemPageData.schemeID}`
                    })
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setBalanceLeft(response.redVal[0][0]);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    useEffect(() => {
        getRedeemValidations();
        getBalanceValidations()

    }, [])
    //function to handle change of menu - Explore/recommendation
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };

    const RedeemValidation = () => {
        let valid = true;
        if (radioValue === 'recommend') {
            if (redeemPageData.redeemValue.value === "" || redeemPageData.redeemValue.value <= 0 || (redeemPageData.redeemType == "Full" && Number(redeemPageData.redeemValue.value) <= 0)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: "Redemption Amount can not be empty or negative" })
                valid = false;
            }
            else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "INR" && Number(redeemPageData.redeemValue.value) > redeemBalance.Amount)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${redeemBalance.Amount}` })
                valid = false;
            }
            else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "Units" && Number(redeemPageData.redeemValue.value) > redeemBalance.Units)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${redeemBalance.Units}` })
                valid = false;
            }
            else {
                setError({ errorRedemptionValue: false, errorRedemptionValueMsg: "" })
            }
            return valid;
        }
        else {
            if (redeemPageData.redeemValue.value === "" || redeemPageData.redeemValue.value <= 0 || (redeemPageData.redeemType == "Full" && Number(redeemPageData.redeemValue.value) <= 0)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: "Redemption Amount can not be empty or negative" })
                valid = false;
            }
            else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "INR" && Number(redeemPageData.redeemValue.value) > balanceLeft.Amount)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${balanceLeft.Amount}` })
                valid = false;
            }
            else if (redeemPageData.redeemValue.value && (redeemPageData.redeemType == "Partial" && redeemPageData.redeemValue.type === "Units" && Number(redeemPageData.redeemValue.value) > balanceLeft.Units)) {
                setError({ errorRedemptionValue: true, errorRedemptionValueMsg: `Redemption Amount cannot exceed ${balanceLeft.Units}` })
                valid = false;
            }
            else {
                setError({ errorRedemptionValue: false, errorRedemptionValueMsg: "" })
            }
            return valid;
        }
    }
    async function getBSECode(item: any) {
        let isComponentActive = true;

        try {
            const response = (await dispatch(
                get_bse_code({
                    TrType: 'Swt',
                    Amount: item.value,
                    Growthoption: item?.Growthoption,
                    DividendReinvestment: item?.DividendReinvestment,
                    RTACODE: item?.SCh,
                })
            )) as unknown as BSECodeResponse;
            if (!isComponentActive) {
                return;
            }
            if (response && response.bse_code && response.bse_code.length > 0) {
                item.BSESchemeCode = response.bse_code[0][0]?.BSE_ProductCod;
            }
            return item;

        } catch (e) {
            console.log((e as Error).message);
        }
        return () => {
            isComponentActive = false;
        };
    }
    const handleSubmitData = async (redeemData: any) => {
        let shouldReturn = false;
        if (!RedeemValidation()) shouldReturn = true;
        const data: any = [];
        if (radioValue === 'recommend') {
            const obj = {
                "SchemeID": String(redeemData.schemeID),
                "InvestmentAmount": redeemData.redeemValue.type === 'INR' ? Number(redeemData.redeemValue.value) : 0,
                "SchemeType": "Red",
                "rmID": rmId,
                "ClientCode": investorId,
                "No_of_Installments": null,
                "SIP_date": "",
                "Frequency": "",
                "AccountID": redeemData.AccountID,
                "ToSchemeID": "",
                "Folio": redeemData.folio,
                "Units": redeemData.redeemValue.type === 'Units' ? Number(redeemData.redeemValue.value) : 0,
                "PFFlag": redeemPageData.redeemType === 'Partial' ? "P" : "F"
            }
            data.push(obj);
            const recommendationData = {
                recommendation_data: data
            }
            if (shouldReturn) return;
            await postRecommendedSchemes(recommendationData);
        } else {
            await getBSECode(redeemData)
            const obj = {
                "AccNo": redeemData?.folio,
                "AmcId": redeemData?.AmcID,
                "BSE_SchemeCode": redeemData?.BSESchemeCode,
                "Amount": redeemData.redeemValue.value,
                "FParFul": redeemData.redeemType === 'Partial' ? "P" : "F",
                "FUnsAmt": redeemData.redeemValue.type == "INR" ? "a" : "u",
                "Sch": redeemData.SCh,
                "TrType": "Red",
                "Units": redeemData.redeemValue.value,
                "AccountID": redeemData.AccountID,
                "rmID": rmId,
                "RMIFlag": radioValue === 'onbehalf' ? '1' : '2'
            }
            data.push(obj);
            if (shouldReturn) return;
            const confirmationResult = await Swal.fire({
                html: '<b>Are you sure you want to place this order?</b>',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, place order",
                customClass: {
                    container: 'my-swal',
                    htmlContainer: 'custom-html-container-class',
                },
            });
            if (confirmationResult.isConfirmed) {
                await create_transaction(data[0])
            }

        }
    }
    async function create_transaction(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    RM_create_transaction(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
                setPlaceOrderResponse(response)
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }

    async function postRecommendedSchemes(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    post_recommended_schemes(data)
                )) as unknown as any;
                // if (!isComponentActive) {
                //   return;
                // }
                setIsLoading(false);
                getRedeemValidations();
                setRecommended(true);
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }

    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Holdings
        </Typography>,
    ];


    // //RMids
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    //function to handle change of asset class tabs
    // const handleChange = (event: any, value: any) => {
    //     setValue(value);
    // };

    const open = Boolean(anchorEl);

    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }
    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };

    const theme = useTheme();
    const [dataName, setDataName] = React.useState<string[]>([]);

    const handleChangeAMC = (event: SelectChangeEvent<typeof amcName>) => {
        const {
            target: { value },
        } = event;
        setAmcName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeFolio = (event: SelectChangeEvent<typeof folioName>) => {
        const {
            target: { value },
        } = event;
        setFolioName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeAccountType = (event: SelectChangeEvent<typeof accountType>) => {
        const {
            target: { value },
        } = event;
        setAccountType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeScheme = (event: SelectChangeEvent<typeof schemeName>) => {
        const {
            target: { value },
        } = event;
        setSchemeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    return (
        <div>

            <CommonLayout>
                <>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                        <Button
                            sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick_}
                        >
                            {selectedValue}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                        <Menu
                            sx={{ cursor: "pointer" }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendation</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                        </Menu>
                    </Box>

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'common.white',
                            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            py: 2,
                        }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ArrowBackIosIcon onClick={() => handleMenuItemClick('Holdings')} sx={{ marginInline: "4rem 1rem", cursor: "pointer" }} />
                            <h3>Redemption</h3>
                        </Box>
                        <Divider />
                        <h2 style={{ textAlign: "center", background: "#eef9f4", width: "100%", paddingBlock: "0.5rem" }}><span style={{ fontWeight: "500" }}>Scheme Name</span> - {holdingName.schemeName}</h2>
                        <FormControl sx={{ px: 8, pt: 2 }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadioChange}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                    '& .MuiFormControlLabel-root': {
                                        color: '#5a7c82'
                                    },
                                    '& .Mui-checked .MuiRadio-root': {
                                        color: '#337FC9',
                                    },
                                    '& .MuiRadio-root:not(.Mui-checked)': {
                                        color: '#5a7c82'
                                    },
                                }}
                            >

                                <FormControlLabel value="recommend" control={<Radio />} label="Recommend" />
                                <FormControlLabel value="onbehalf" control={<Radio />} label="On behalf of client" />
                                <FormControlLabel value="onrequest" control={<Radio />} label="Requested by client" />

                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ width: "60%", marginLeft: "4rem", mt: "1rem" }}>


                            <Box className="form">
                                <p >Selected Folio</p>
                                <FormControl sx={{ m: 1, width: 800, mt: 2 }}>
                                    <Select
                                        disabled
                                        displayEmpty
                                        value={folioName}
                                        onChange={handleChangeFolio}
                                        input={<OutlinedInput />}
                                        renderValue={(selected: any) => {
                                            if (selected.length === 0) {
                                                return <em>{holdingName.folio}</em>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >


                                    </Select>
                                </FormControl>
                            </Box>


                            <Box className="form">
                                <p>Selected Scheme</p>
                                <FormControl sx={{ m: 1, width: 800, mt: 2 }}>
                                    <Select
                                        disabled
                                        displayEmpty
                                        value={redeemPageData.schemeName}
                                        onChange={handleChangeScheme}
                                        input={<OutlinedInput />}
                                        renderValue={(selected: any) => {
                                            if (selected.length === 0) {
                                                return <em>{redeemPageData.schemeName}</em>;
                                            }

                                            return selected;
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >

                                    </Select>
                                </FormControl>
                            </Box>
                            <Box className="form" >

                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500", mt: 3, mb: 3 }}>Redemption Type</FormLabel>
                                <RadioGroup sx={{ paddingRight: "11.5rem" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"

                                    value={redeemPageData.redeemType}
                                    onChange={(e: any) => {
                                        if (radioValue === 'recommend') {
                                            setRedeemPageData({ ...redeemPageData, redeemType: e.target.value, redeemValue: { value: e.target.value === "Partial" ? "" : redeemPageData.redeemValue.type === "INR" ? redeemBalance?.Amount : redeemBalance?.Units, type: redeemPageData.redeemValue.type } })
                                        } else {
                                            setRedeemPageData({ ...redeemPageData, redeemType: e.target.value, redeemValue: { value: e.target.value === "Partial" ? "" : redeemPageData.redeemValue.type === "INR" ? balanceLeft?.Amount : balanceLeft?.Units, type: redeemPageData.redeemValue.type } })
                                        }

                                    }}
                                >
                                    <FormControlLabel value="Partial" control={<Radio />} label="Partial" />
                                    <FormControlLabel value="Full" control={<Radio />} label="Full" />
                                </RadioGroup>

                            </Box>
                            <Box className="form">
                                <p >Redemption Value <b style={{ color: 'red' }}>*</b></p>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                    {radioValue === 'recommend' ?
                                        <>{redeemBalance ? <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Maximum ${redeemPageData.redeemValue.type === "INR" ? `₹${redeemBalance.Amount}` : redeemBalance.Units}`}

                                            value={redeemPageData.redeemType === "Partial" ?
                                                redeemPageData.redeemValue.value : redeemPageData.redeemValue.type === "INR" ? redeemBalance.Amount : redeemBalance.Units}

                                            onChange={(e) => {
                                                setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: e.target.value } })
                                            }}
                                        ></input> : ''}
                                        </>
                                        :
                                        <>
                                            {balanceLeft ? <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Maximum ${redeemPageData.redeemValue.type === "INR" ? `₹${balanceLeft.Amount}` : balanceLeft.Units}`}

                                                value={redeemPageData.redeemType === "Partial" ?
                                                    redeemPageData.redeemValue.value : redeemPageData.redeemValue.type === "INR" ? balanceLeft.Amount : balanceLeft.Units}

                                                onChange={(e) => {
                                                    setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, value: e.target.value } })
                                                }}
                                            ></input> : ''}
                                        </>
                                    }
                                    {error.errorRedemptionValue && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorRedemptionValueMsg} </Typography>}
                                    <RadioGroup sx={{ display: "flex", flexDirection: "row" }}

                                        value={redeemPageData.redeemValue.type}
                                        onChange={(e: any) => {

                                            setRedeemPageData({ ...redeemPageData, redeemValue: { ...redeemPageData.redeemValue, type: e.target.value }, })
                                            // FinalRedeemValue()
                                        }}
                                    >
                                        <FormControlLabel value="INR" control={<Radio />} label="INR" />
                                        <FormControlLabel value="Units" control={<Radio />} label="Units" />
                                    </RadioGroup>
                                </Box>


                            </Box>


                        </Box>

                        <Box sx={{ marginTop: "2rem" }}>
                            <Button variant="contained" style={{ width: '33.5%', marginLeft: "22rem" }}
                                onClick={() => { handleSubmitData(redeemPageData) }}
                            >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : <> {radioValue === 'recommend' ? "Recommend" : radioValue === 'onbehalf' ? "Place Order On Behalf of Client" : "Place Order Requested by Client"} </>}</Button>
                        </Box>
                        <Typography sx={{ color: 'red', fontSize: '12px', mx: 7 }}> * Mandatory fields</Typography>
                    </Box>
                    {Recommend ?
                        <Modal
                            open={Recommend}
                            onClose={() => setRecommended(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute' as any,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {radioValue === 'recommend' ?
                                    <> <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                        <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
                                    </>
                                    :
                                    <>

                                        {placeOrderResponse === undefined || placeOrderResponse === null || placeOrderResponse.error ?
                                            < >
                                                <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />
                                                <Typography sx={{ textAlign: "center" }}>Order Failed</Typography>
                                                <Typography sx={{ textAlign: "center" }}>Order ID not generated</Typography>
                                            </>
                                            :
                                            <>
                                                {placeOrderResponse.transaction_created[0].order_success ?
                                                    <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                                    : <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />}
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_success ? 'Order Placed' : 'Order Failed'}</Typography>
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_status}</Typography>
                                            </>
                                        }
                                    </>
                                }
                                <Button onClick={() => { setRecommended(false); handleMenuItemClick('Holdings') }} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                            </Box>
                        </Modal> : <></>}

                </>

            </CommonLayout>

        </div>
    );
}

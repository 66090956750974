import { max, min } from 'd3-array';
import { axisBottom, axisLeft } from 'd3-axis';
import { format } from 'd3-format';
import { scaleBand, scaleLinear } from 'd3-scale';
import { Selection, select } from 'd3-selection';
import 'd3-transition';
import { useEffect, useRef } from 'react';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';

type Datum = {
  [key: string]: number;
};

type DataItem = {
  name: string;
  data: Datum;
};

type Margins = {
  TOP: number;
  BOTTOM: number;
  LEFT: number;
  RIGHT: number;
};

type Dimensions = {
  WIDTH?: number;
};

type BarDimensions = {
  height?: number;
  spacing?: number;
  groupSpacing?: number;
};

const DEFAULT_MARGINS: Margins = { TOP: 10, BOTTOM: 30, LEFT: 200, RIGHT: 10 };
const DEFAULT_DIMENSIONS: Dimensions = { WIDTH: 600 };
const DEFAULT_BAR_HEIGHT = 8;
const DEFAULT_BAR_SPACING = 0;
const DEFAULT_GROUP_SPACING = 5;
export const colors = ['#B8E3FF', '#276899', '#8ED8B7'];
const KEY_CURRENT_MARKET_VALUE = 'Current Market Value';
const KEY_INVESTED_VALUE = 'Invested Value';
const KEY_UNREALIZED_GAIN_LOSS = 'Unrealised Gain/Loss';

function wrap(text: Selection<SVGTextElement, unknown, null, undefined>, width: number) {
  text.each(function () {
    const text = select(this),
      words = text.text().split(/\s+/).reverse(),
      lineHeight = 0.8, // ems
      y = text.attr('y'),
      x = text.attr('x'),
      dy = parseFloat(text.attr('dy'));
    let tspan = text
      .text(null)
      .append('tspan')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', dy + 'em');
    let line = [],
      word: any;
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));
      const tspanNode = tspan.node();
      if (tspanNode !== null && tspanNode.getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', lineHeight + dy + 'em')
          .text(word);
      }
    }
    // text.attr('dy', (dy - 2 * lineHeight))
  });
}

type GroupedBarChartProps = {
  data: DataItem[];
  margins?: Margins;
  dimensions?: Dimensions;
  barDimensions?: BarDimensions;
};

export default function GroupedBarChart({
  data,
  margins,
  dimensions = {},
  barDimensions = {},
}: GroupedBarChartProps): JSX.Element {
  const svgRef = useRef<SVGSVGElement>(null);
  const margin = { ...DEFAULT_MARGINS, ...margins };
  const barDimension = {
    ...{
      height: DEFAULT_BAR_HEIGHT,
      spacing: DEFAULT_BAR_SPACING,
      groupSpacing: DEFAULT_GROUP_SPACING,
    },
    ...barDimensions,
  };

  function renderChart() {
    const availableWidth =
      dimensions.WIDTH || svgRef.current
        ? svgRef.current?.parentElement
          ? svgRef.current.parentElement.getBoundingClientRect().width - margin.LEFT - 50
          : 0
        : DEFAULT_DIMENSIONS.WIDTH;
    const width = availableWidth || 0 - margin.LEFT - margin.RIGHT;

    const tooltip = select('.grouped-chart-tooltip').style('opacity', 0);

    const zippedData = data
      .map((item) => item.data)
      .flatMap((item) => [
        item[KEY_CURRENT_MARKET_VALUE],
        item[KEY_INVESTED_VALUE],
        item[KEY_UNREALIZED_GAIN_LOSS],
      ]);

    const height =
      margin.TOP +
      margin.BOTTOM +
      data.length *
        (3 * barDimension.height + 2 * barDimension.spacing + barDimension.groupSpacing);

    const svg = select(svgRef.current)
      .attr('width', width + margin.LEFT + margin.RIGHT)
      .attr('height', height);

    const xScale = scaleLinear()
      .domain([
        (min(zippedData) as number) > 0
          ? 0
          : (min(zippedData) as number) - (max(zippedData) as number) * (1 / 100),
        max(zippedData) as number,
      ])
      .range([margin.LEFT, width + margin.LEFT]);

    const y = scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.TOP, height - margin.BOTTOM])
      .padding(0.1);

    svg
      .selectAll('.xaxis')
      .data([''])
      .join('g')
      .attr('class', 'xaxis')
      .attr('transform', `translate(0,${margin.TOP})`)
      .transition()
      .duration(500)
      .call(
        axisBottom(xScale)
          .ticks(6)
          .tickSize(height - margin.BOTTOM - margin.TOP)
          .tickFormat((d:any)=>{
            if(d >= 1e7)
             return format('.2s')(d / 1e7) + 'Cr';
            else
             return format('.2s')(d/ 1e5) + 'Lakhs';
          }) as any
      )
      .selectAll('.tick line')
      .attr('stroke', 'lightgray');

    svg.selectAll('.xaxis path.domain').attr('stroke', 'transparent');

    svg
      .selectAll('.yaxis')
      .data([''])
      .join('g')
      .attr('class', 'yaxis')
      .attr('transform', `translate(${margin.LEFT},0)`)
      .transition()
      .duration(500)
      .call(axisLeft(y).tickSize(0).tickPadding(10) as any)
      .selectAll('.tick text')
      .attr('color', 'rgb(102, 102, 102)');

    svg.selectAll('.yaxis path.domain').attr('stroke', '#e6e6e6');

    const groups = svg
      .selectAll('.bar-group')
      .data(data)
      .join('g')
      .attr('class', 'bar-group')
      .attr('transform', (d) => `translate(0, ${y(d.name)})`)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(200).style('opacity', 1);
        tooltip
          .html(
            `<div style="width: 200px; background-color: white; opacity: 1; box-shadow: 0px 20px 40px 4px rgba(0, 0, 0, 0.07); padding: 10px; border-radius: 5px;"><span style="display: block; font-size: 14px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">${
              d.name
            }</span>
            <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[0]
            }">${'\u25CF'}</span><span style="font-size: 13px; color: rgb(34 36 44 / 60%);">Current Market Value</span></div>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[0]
            }; visibility: hidden">${'\u25CF'}</span><span style="font-weight: 500;
            font-size: 13px;
            color: #22242C;">${formatToIndianCurrency(
              d.data?.['Current Market Value']
            )}</span></div>
            <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[1]
            }">${'\u25CF'}</span><span style="font-size: 13px; color: rgb(34 36 44 / 60%);">Invested Value</span></div>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[1]
            }; visibility: hidden">${'\u25CF'}</span><span style="font-weight: 500;
            font-size: 13px;
            color: #22242C;">${formatToIndianCurrency(d.data?.['Invested Value'])}</span></div>
            <div style="display: flex; flex-direction: column">
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[2]
            }">${'\u25CF'}</span><span style="font-size: 13px; color: rgb(34 36 44 / 60%);">Unrealised Gain/Loss</span></div>
            </div>
            <div style="display: flex; flex-direction: row; align-items: center; gap: 10px"><span style="color: ${
              colors[2]
            }; visibility: hidden">${'\u25CF'}</span><span style="font-weight: 500;
            font-size: 13px;
            color: #22242C;">${formatToIndianCurrency(
              d.data?.['Unrealised Gain/Loss']
            )}</span></div>
            
            </div>`
          )
          .style('opacity', 1)
          .style('left', `${event.layerX + 50}px`)
          .style('top', `${y(d.name) || 0 - 10}px`);
      })
      .on('mouseout', () => tooltip.transition().duration(500).style('opacity', 0));
    groups
      .selectAll('.bar')
      .data((d) => [
        { key: 'currentMarketValue', value: d.data[KEY_CURRENT_MARKET_VALUE] },
        { key: 'investedValue', value: d.data[KEY_INVESTED_VALUE] },
        { key: 'unrealisedGainLoss', value: d.data[KEY_UNREALIZED_GAIN_LOSS] },
      ])
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(0))
      .attr('y', (d, i) => barDimension.height * i + barDimension.spacing * i)
      .attr('height', barDimension.height)
      .attr('fill', (d, i) => colors[i])
      .attr('rx', barDimension.height / 2)
      .attr('ry', barDimension.height / 2)
      .attr('transform', (d, i) =>
        d.value < 0
          ? `rotate(180, ${xScale(0)}, ${barDimension.height * i + barDimension.spacing * i + 2})`
          : 'rotate(0)'
      )
      .transition()
      .duration(1000)
      .delay((x, i) => i * 100)
      .attr(
        'width',
        (d) => xScale(Math.abs(d.value)) - xScale(0) + xScale(max(zippedData) as number) * (1 / 100)
      );

    setTimeout(() => {
      svg
        .selectAll('.yaxis')
        .selectAll('.tick text')
        .call(wrap as any, margin.LEFT - 10);
    }, 50);
  }
  useEffect(() => {
    {
      renderChart();
    }
  }, [svgRef.current, data]);

  return (
    <div style={{ position: 'relative' }}>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
        {[KEY_CURRENT_MARKET_VALUE, KEY_INVESTED_VALUE, KEY_UNREALIZED_GAIN_LOSS].map((k, i) => (
          <div
            key={k}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: 20,
              fontWeight: 'bold',
              fontSize: 12,
            }}>
            <div
              style={{
                borderRadius: '50%',
                width: 12,
                height: 12,
                backgroundColor: colors[i],
                marginRight: 5,
              }}
            />
            <span>{k}</span>
          </div>
        ))}
      </div> */}
      <svg ref={svgRef} style={{ width: '100%' }}></svg>
      <div className="grouped-chart-tooltip" style={{ position: 'absolute' }}></div>
    </div>
  );
}

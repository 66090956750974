import { Box, FormControl, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { getInvestorReports } from '../../redux-store/actions/investorReports';
import {
  downloadMultipleFiles,
  formatDate,
  replaceLetterOWithW,
} from '../../utils/utilityFunctions';
import { ReportSectionContainer, ReportsRightLayout } from './index';
import { TextDatePicker } from '../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../lib/formik';
import { validationSchemaTransactionStatement } from '../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const TransactionStatement = ({
  location
}: {
  location: Location<{ investorId: string; investorName: string }>
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const history = useHistory();
  const currentDate = dayjs();
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
  const [dates1, setDates1] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));

  const onSubmit = async (values: any) => {
    try {
      const { fromDate, toDate } = values;
      const FromDate = `${new Date(fromDate).getDate().toString().padStart(2, '0')}/${(new Date(fromDate)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(fromDate).getFullYear()}`;
      const ToDate = `${new Date(toDate).getDate().toString().padStart(2, '0')}/${(new Date(toDate)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(toDate).getFullYear()}`;
      if (investorId) {
        setLoading(true)
        const res = await dispatch(
          getInvestorReports({
            reportName: 'TransactionStatement',
            // clientId: [replaceLetterOWithW(investorId) || ''],
            clientId: [investorId || ''],
            fromDate: FromDate,
            toDate: ToDate,
          })
        );
        setLoading(false)
        downloadMultipleFiles(res);
        enqueueSnackbar('File(s) downloaded successfully.', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      setDates(dayjs(currentDate).format("YYYY-MM-DD"));
      setDates1(dayjs(currentDate).format("YYYY-MM-DD"));
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <ReportsRightLayout>
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '19px',
          letterSpacing: '0.06em',
          textTransform: 'uppercase',
          display: ['none', 'none', 'block'],
        }}>
        Transaction Statement
      </Typography>
      <Formik
        validationSchema={validationSchemaTransactionStatement}
        initialValues={{
          fromDate: new Date(),
          toDate: new Date(),
        }}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>

            <ReportSectionContainer>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0.01em',
                  pt: 1,
                  pb: 1,
                }}>
                Choose duration of statement -
              </Typography>
              {/* <Box>
                <FormControl sx={{ width: '100%' }}>
                  <>
                    <TextDatePicker
                      label={'From Date'}
                      inputLabelStyles={{
                        fontSize: '14px',
                        fontWeight: 400,
                        lineHeight: '22px',
                        letterSpacing: '0.01em',
                      }}
                      applyLabelStyles={true}
                      placeholder={'Enter Net worth as on Date'}
                      name={`fromDate`}
                    />
                    <TextDatePicker
                      label={'To Date'}
                      inputLabelStyles={{
                        transform: 'unset',
                        fontSize: 14,
                        fontWeight: 500,
                        color: 'rgba(0,0,0,0.7)',
                      }}
                      applyLabelStyles={true}
                      placeholder={'Enter Net worth as on Date'}
                      name={`toDate`}
                    />
                  </>
                </FormControl>
              </Box> */}
              <Stack
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}
                >

                  <DatePicker
                    sx={{
                      marginBottom: "30px"
                    }}
                    label="From Date"
                    format="DD-MM-YYYY"
                    value={dayjs(dates)}
                    maxDate={dayjs(currentDate)}

                    onChange={(date: any) => {
                      setDates(date.format("YYYY-MM-DD"))
                      setFieldValue('fromDate', date)
                    }
                    }
                  />

                </LocalizationProvider>

                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <DatePicker
                    label="TO Date"
                    format="DD-MM-YYYY"
                    value={dayjs(dates1)}
                    maxDate={dayjs(currentDate)}

                    onChange={(date: any) => {
                      setDates1(date.format("YYYY-MM-DD"))
                      setFieldValue('toDate', date)
                    }
                    }
                  />

                </LocalizationProvider>
              </Stack>
            </ReportSectionContainer>
            <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
              <MFSubmitButton
                label="Download"
                loadingEnable={loading}
              // disabled={selectedFamilyMembers[0] === 'overall-family'}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </ReportsRightLayout>
  );
};

export default TransactionStatement;

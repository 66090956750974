import {
  DatePickerProps as MuiDatePickerProps,
  LocalizationProvider,
  DatePicker as MuiDatePicker,
} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
  TextFieldProps,
  Theme,
} from '@mui/material';
import { SxProps } from '@mui/system';
import { useField } from 'formik';
import { DatePickerIcon } from '../../components/customSVGs';

const defaultTextFieldStyles: SxProps<Theme> = {
  // mt: 4,
  '& .MuiInputBase-root': {
    border: '1px solid #DDEAF3',
    fontSize: 16,
  },
  '& .MuiOutlinedInput-root': {
    position: 'relative',
    backgroundColor: 'common.white',
    border: '1px solid #DDEAF3',

    '&:hover': {
      borderColor: 'primary.main',
      '.MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
    '.MuiInputBase-input': {
      p: '10px 12px',
    },
    '&:focus-visible': {
      outline: 'none',
    },
  },
};

interface DatePickerProps
  extends Omit<Omit<Omit<MuiDatePickerProps<Date | null>, 'onChange'>, 'renderInput'>, 'value'> {
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  onChange?: (date: Date | null, keyboardInputValue?: string) => void;
  label?: string;
  placeholder: string;
  value?: MuiDatePickerProps<Date>['value'];
  onClick?: () => void;
  name: string;
  inputLabelStyles?: SxProps<Theme>;
  textFieldStyles?: SxProps<Theme>;
  maxDate?: Date;
  minDate?: Date;
  disabled?: boolean;
}

export const DatePicker = ({
  label = '',
  placeholder = '',
  name,
  inputLabelStyles,
  textFieldStyles = {},
  maxDate = new Date(),
  minDate,
  disabled = false,
  onClick,
  onChange,
  value,
}: DatePickerProps): JSX.Element => {
  const [field, meta, { setValue }] = useField(name);

  const handleOnChange = (date: Date | null) => {
    setValue(date);
    onClick && onClick();
  };
  const errorText = meta.error && meta.touched ? meta.error : '';

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <FormControl variant="outlined" fullWidth sx={{ my: { xs: 1, md: 2 } }}>
        {label && (
          <InputLabel shrink htmlFor="date-picker" sx={{ ...inputLabelStyles }}>
            {label}
          </InputLabel>
        )}
        <MuiDatePicker
          // value={value ? value : maxDate}
          maxDate={maxDate}
          minDate={minDate}
          components={{ OpenPickerIcon: DatePickerIcon }}
          value={value || field.value}
          inputFormat="dd/MM/yyyy"
          onChange={onChange ? onChange : handleOnChange}
          disabled={disabled}
          views={['year', 'month', 'day']}
          renderInput={(params: TextFieldProps) => (
            <TextField
              {...params}
              fullWidth
              id={`${name}-date-picker`}
              placeholder={placeholder}
              sx={{
                ...defaultTextFieldStyles,
                ...textFieldStyles,
                mt: label ? 4 : 0,
                '& .Mui-disabled': {
                  cursor: !disabled ? 'text' : 'not-allowed !important',
                  pointerEvents: 'all',
                },
              }}
            />
          )}
          disableCloseOnSelect={false}
          showToolbar={false}
        />
        {errorText && (
          <FormHelperText error sx={{ marginLeft: 'unset' }}>
            {errorText}
          </FormHelperText>
        )}
      </FormControl>
    </LocalizationProvider>
  );
};

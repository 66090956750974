import {
  Box,
  Grid,
  Typography,
  Hidden,
  IconButton,
  LinearProgress,
  CardMedia,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useHistory } from 'react-router';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Gridstyles } from '../ApplicationDetails';
import { useDispatch } from 'react-redux';
import { KeyValue } from '../ApplicationDetails/personalDetails';
import { FundManager, RmsType, Trustee } from '../../redux-store/types/api-types';
import {
  AifApproverAccess,
  getAIFApproverById,
  getAmcApproverAccess,
  getFundManagersById,
  getIMAccess,
  getRMById,
  gettrusteeAccess,
  getTrustesById,
  IMAccess,
  trusteeAccess,
} from '../../redux-store/actions/userManagement';
import { Location } from 'history';

export default function AIFApproverDetails({
  location,
}: {
  location: Location<{ aifApproverId: number }>;
}): JSX.Element {
  const { aifApproverId: id } = location.state || { aifApproverId: null };
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const [aifApproverDetails, setAifApproverDetails] = useState<FundManager>();
  const [accessForEditAdd, setAccessForEditAdd] = useState(false);

  useEffect(() => {
    let isComponentActive = true;
    (async function () {
      try {
        setLoading(true);
        const { amcApproverAccess } = (await dispatch(
          getAmcApproverAccess()
        )) as unknown as AifApproverAccess;
        if (id) {
          const getAifApproverDetails = (await dispatch(
            getAIFApproverById(id)
          )) as unknown as FundManager;
          setAifApproverDetails(getAifApproverDetails);
        }
        if (!isComponentActive) {
          return;
        }
        setAccessForEditAdd(amcApproverAccess);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
          py: { xs: 2, sm: 5 },
          px: { xs: 0, sm: 5 },
          mt: { xs: 2, sm: 5 },
        }}>
        <Hidden smUp={true}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton
              sx={{ height: 'fit-content', p: 0 }}
              onClick={() => history.push('checker')}>
              <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
            </IconButton>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
              }}>
              Back
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              my: 2,
              py: 2,
              pl: { xs: 3, sm: 0 },
              color: 'primary.main',
              bgcolor: 'rgba(238, 244, 251, 0.5)',
            }}>
            <PersonOutlineOutlinedIcon fontSize="large" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                pl: 1,
              }}>
              User Management
            </Typography>
          </Box>
        </Hidden>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Hidden only="xs">
              <IconButton
                sx={{ height: 'fit-content', p: 0 }}
                onClick={() => history.push('checker')}>
                <NavigateBeforeIcon fontSize="medium" sx={{ color: 'common.black' }} />
              </IconButton>
            </Hidden>
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: '#1C2D47',
                pl: { xs: 2, sm: 0 },
              }}>
              Checker
            </Typography>
          </Box>
          {accessForEditAdd && (
            <IconButton>
              <CardMedia
                component="img"
                src="/images/edit-icon-outlined.svg"
                alt="Edit Icon"
                sx={{ width: 'unset' }}
                onClick={() =>
                  history.push('edit-aifapprover', { aifApproverId: aifApproverDetails?.id })
                }
              />
            </IconButton>
          )}
        </Box>
        {isLoading ? (
          <LinearProgress sx={{ ml: 5, mr: 5 }} />
        ) : (
          aifApproverDetails && (
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 }, pt: 1 }}>
              <React.Fragment>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <KeyValue title={'Name'} description={aifApproverDetails.user.firstName} />
                    <KeyValue title={'Email Id'} description={aifApproverDetails.user.email} />
                    <KeyValue title={'Mobile Number'} description={aifApproverDetails.user.phone} />
                    <KeyValue title={'Pincode'} description={aifApproverDetails.pincode} />
                    <KeyValue
                      title={'Building Number'}
                      description={aifApproverDetails.buildingNo}
                    />
                    <KeyValue title={'Street Name'} description={aifApproverDetails.streetName} />
                    <KeyValue title={'State'} description={aifApproverDetails.state} />
                    <KeyValue title={'City'} description={aifApproverDetails.city} />
                    <KeyValue title={'Country'} description={aifApproverDetails.country} />
                  </Grid>
                </Gridstyles>
              </React.Fragment>
            </Grid>
          )
        )}
      </Box>
    </>
  );
}

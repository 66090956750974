import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Divider,
    Grid,
    InputLabel,
    LinearProgress,
    Menu,
    MenuItem,
    Select,
    Skeleton,
    Tab,
    Typography,
    Modal,
    CircularProgress
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { AssetClasses, BSECodeResponse, GetAssetClassesType, GetCategoryAverage, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, GetSchemeType, RecommendationsType, SchemeList, SchemeType } from '../../../redux-store/types/api-types';
import { RM_create_transaction, getFrequency, getRmDetails, getSchemeName, get_assest_classes, get_bse_code, get_category_average, get_recommendation_validation, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav, post_recommended_schemes } from '../../../redux-store/actions/userManagement';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ErrorType, HoldingSWPSTPSeletedData, HoldingSchemeData, SWP_STPErrorType, selectedFrequencyValidationData } from './types';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};



function getStyles(name: string, dataName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            dataName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


export default function STP_SWP({
    location
}: {
    location: Location<{ investorId: string; investorName: string; holdingName: HoldingSchemeData }>
}): JSX.Element {

    const { investorId, investorName, holdingName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Holdings');
    const [value, setValue] = useState<string>("Debt");
    const [folioName, setFolioName] = React.useState<string[]>([]);
    const [schemeName, setSchemeName] = React.useState<string[]>([]);
    const [accountType, setAccountType] = React.useState<string[]>([]);
    const [transactionType, setTransactionType] = useState("SWP");
    const [schemeData, setSchemeData] = useState<SchemeType[] | null>();
    const [schemeID, setSchemeID] = useState<any>("");
    const [rmId, setRmId] = useState<number | null>();
    const [validations, SetValidations] = useState<selectedFrequencyValidationData[]>();
    const [Recommend, setRecommended] = useState<boolean>(false);
    const [swp_stpPageData, setSWP_STPPageData] = useState<HoldingSWPSTPSeletedData>({ ...holdingName, transactionType: "SWP", SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", switchValue: { value: "", type: "INR" }, ToSchemeName: "", ToschemeID: "", newSchemeSch: "", STPFrequency: "", SIPDate: "", SWPFrequency: "" });
    const [selectedFrequencyValidation, setSelectedFrequencyValidation] = useState<selectedFrequencyValidationData>();
    const [newSchemeSch, setNewSchemeSch] = useState<string>("")
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [placeOrderResponse, setPlaceOrderResponse] = useState<any>()
    const [radioValue, setRadioValue] = useState<string>('recommend');
    const [astId, setAstId] = useState<string>('')
    const handleRadioChange = (event: any, value: any) => {
        setRadioValue(event.target.value);
    };
    const frequencyMapping: any = {
        "DAILY": "1",
        "Weekly": "2",
        "MONTHLY": "3",
        "QUARTERLY": "4",
        "ANNUALLY": "6",
    }
    const AssetclassMapping: any = {
        "Debt": "4",
        "Equity": "1",
        "Hybrid": "3",
        "Liquid": "2"
    }
    //function to handle change of menu - Explore/recommendation
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };

    const isDateDisabled = (date: Date): boolean => {

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        if (date < currentDate) {
            return false;
        }

        const foundValue: any = validations?.find((val: any) => transactionType == 'STP' ? val.SIPFREQUENCY == swp_stpPageData.STPFrequency : val.SIPFREQUENCY == swp_stpPageData.SWPFrequency)
        const datesArray: any = foundValue?.SIPDATES.split(',');

        if (datesArray.includes(date.getDate().toString())) {
            return true;
        } else {
            return false;
        }
    };
    const errorStates: SWP_STPErrorType = {
        errorSWPAmount: false,
        errorSWPAmountMsg: "",
        errorSWPNoOfInstalment: false,
        errorSWPNoOfInstalmentMsg: "",
        errorSWPDay: false,
        errorSWPDayMsg: "",
        errorSTPAmount: false,
        errorSTPAmountMsg: "",
        errorSchemeValue: false,
        errorSchemeValueMsg: "",
        errorSTPNoOfInstalment: false,
        errorSTPNoOfInstalmentMsg: "",
        errorSTPDay: false,
        errorSTPDayMsg: "",
        errorToSchemeID: false,
        errorToSchemeIDMsg: "",
        errorFrequency: false,
        errorFrequencyMsg: "",
        errorFirstOrderToday: false,
        errorMessageFirstOrderToday: "",
        errorMandate: false,
        errorMessageMandate: "",
        errorAstId: false,
        errorMessageAstId: ""
    }

    const [error, setError] = useState<SWP_STPErrorType>(errorStates);

    const STPValidation = () => {
        let valid = true;
        const errorTempData: any = {
            errorSWPAmount: error.errorSWPAmount,
            errorSWPAmountMsg: error.errorSWPAmountMsg,
            errorSWPNoOfInstalment: error.errorSWPNoOfInstalment,
            errorSWPNoOfInstalmentMsg: error.errorSWPNoOfInstalmentMsg,
            errorSWPDay: error.errorSWPDay,
            errorSWPDayMsg: error.errorSWPDayMsg,
            errorSTPAmount: error.errorSTPAmount,
            errorSTPAmountMsg: error.errorSTPAmountMsg,
            errorSchemeValue: error.errorSchemeValue,
            errorSchemeValueMsg: error.errorSchemeValueMsg,
            errorSTPNoOfInstalment: error.errorSTPNoOfInstalment,
            errorSTPNoOfInstalmentMsg: error.errorSTPNoOfInstalmentMsg,
            errorSTPDay: error.errorSTPDay,
            errorSTPDayMsg: error.errorSTPDayMsg,
            errorToSchemeID: error.errorToSchemeID,
            errorToSchemeIDMsg: error.errorToSchemeIDMsg,
            errorFrequency: error.errorFrequency,
            errorFrequencyMsg: error.errorFrequencyMsg,
            errorFirstOrderToday: error.errorFirstOrderToday,
            errorMessageFirstOrderToday: error.errorMessageFirstOrderToday,
            errorAstId: error.errorAstId,
            errorMessageAstId: error.errorMessageAstId
        };
        if (swp_stpPageData.transactionType === 'STP') {
            //To scheme
            if (newSchemeSch === "" || newSchemeSch === undefined) {
                errorTempData.errorToSchemeID = true;
                errorTempData.errorToSchemeIDMsg = "Please select any Scheme";
                valid = false;
            }
            else {
                errorTempData.errorToSchemeID = false;
            }
            //astid
            if (astId === "" || astId === undefined) {
                errorTempData.errorAstId = true;
                errorTempData.errorMessageAstId = "Please select any asset class";
                valid = false;
            }
            else {
                errorTempData.errorAstId = false;
            }
            //STP amount
            if (swp_stpPageData.STPAmount === "" || swp_stpPageData.STPAmount === "0") {
                errorTempData.errorSTPAmount = true;
                errorTempData.errorSTPAmountMsg = "Amount can not be empty";
                valid = false;
            }
            else if (Number(swp_stpPageData.STPAmount) < Number(selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT) ||
                Number(swp_stpPageData.STPAmount) > Number(selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTAMOUNT)) {
                errorTempData.errorSTPAmount = true;
                errorTempData.errorSTPAmountMsg = `Please enter amount greater than ${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT} and less than ${selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTAMOUNT}`;
                valid = false;
            }
            else {
                errorTempData.errorSTPAmount = false;
            }

            //STP frequency
            if (swp_stpPageData.STPFrequency === "" || swp_stpPageData.STPFrequency === null) {
                errorTempData.errorFrequency = true;
                errorTempData.errorFrequencyMsg = "Frequency can not be empty";
                valid = false;
            }
            else {
                errorTempData.errorFrequency = false;
            }

            // STP installment
            if (swp_stpPageData.STPFrequency != 'DAILY') {
                if (swp_stpPageData.STPinstallment === "" || swp_stpPageData.STPinstallment === "0") {
                    errorTempData.errorSTPNoOfInstalment = true;
                    errorTempData.errorSTPNoOfInstalmentMsg = "Number of installments can not be empty";
                    valid = false;
                }
                else if (Number(swp_stpPageData.STPinstallment) < Number(selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTNUMBERS) ||
                    Number(swp_stpPageData.STPinstallment) > Number(selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTNUMBERS)) {
                    errorTempData.errorSTPNoOfInstalment = true;
                    errorTempData.errorSTPNoOfInstalmentMsg = `Please enter number greater than ${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTNUMBERS} and less than ${selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`;
                    valid = false;
                }
                else {
                    errorTempData.errorSTPNoOfInstalment = false;
                }
            }
            //SIP Date
            if (swp_stpPageData.SIPDate === null || swp_stpPageData.SIPDate === "") {
                errorTempData.errorSTPDay = true;
                errorTempData.errorSTPDayMsg = "STP Date can not be empty";
                valid = false;
            }
            else {
                errorTempData.errorSTPDay = false;
            }
            //first Order Today
            if (radioValue !== 'recommend') {
                if (swp_stpPageData.firstOrderToday === null || swp_stpPageData.firstOrderToday === undefined || swp_stpPageData.firstOrderToday === "") {
                    errorTempData.errorFirstOrderToday = true;
                    errorTempData.errorMessageFirstOrderToday = "Please select Yes or No";
                    valid = false;
                }
                else {
                    errorTempData.errorFirstOrderToday = false;
                }
            }
        }
        setError(errorTempData)
        return valid;
    }

    const SWPValidation = () => {
        let valid = true;
        const errorTempData: any = {
            errorSWPAmount: error.errorSWPAmount,
            errorSWPAmountMsg: error.errorSWPAmountMsg,
            errorSWPNoOfInstalment: error.errorSWPNoOfInstalment,
            errorSWPNoOfInstalmentMsg: error.errorSWPNoOfInstalmentMsg,
            errorSWPDay: error.errorSWPDay,
            errorSWPDayMsg: error.errorSWPDayMsg,
            errorSTPAmount: error.errorSTPAmount,
            errorSTPAmountMsg: error.errorSTPAmountMsg,
            errorSchemeValue: error.errorSchemeValue,
            errorSchemeValueMsg: error.errorSchemeValueMsg,
            errorSTPNoOfInstalment: error.errorSTPNoOfInstalment,
            errorSTPNoOfInstalmentMsg: error.errorSTPNoOfInstalmentMsg,
            errorSTPDay: error.errorSTPDay,
            errorSTPDayMsg: error.errorSTPDayMsg,
            errorToSchemeID: error.errorToSchemeID,
            errorToSchemeIDMsg: error.errorToSchemeIDMsg,
            errorFrequency: error.errorFrequency,
            errorFrequencyMsg: error.errorFrequencyMsg,
            errorFirstOrderToday: error.errorFirstOrderToday,
            errorMessageFirstOrderToday: error.errorMessageFirstOrderToday
        };
        if (swp_stpPageData.transactionType === 'SWP') {
            if (swp_stpPageData.SWPAmount === "" || swp_stpPageData.SWPAmount === "0") {
                errorTempData.errorSWPAmount = true;
                errorTempData.errorSWPAmountMsg = "Amount can not be empty";
                valid = false;
            }
            else if (Number(swp_stpPageData.SWPAmount) < Number(selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT) ||
                Number(swp_stpPageData.SWPAmount) > Number(selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTAMOUNT)) {
                errorTempData.errorSWPAmount = true;
                errorTempData.errorSWPAmountMsg = `Please enter amount greater than ${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT} and less than ${selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTAMOUNT}`;
                valid = false;
            }
            else {
                errorTempData.errorSWPAmount = false;
            }
            if (swp_stpPageData.frequencySelected === "" || swp_stpPageData.frequencySelected === null) {
                errorTempData.errorFrequency = true;
                errorTempData.errorFrequencyMsg = "Frequency can not be empty";
                valid = false;
            }
            else {
                errorTempData.errorFrequency = false;
            }
            if (swp_stpPageData.SWPFrequency != 'DAILY') {
                if (swp_stpPageData.SWPinstallment === "" || swp_stpPageData.SWPinstallment === "0") {
                    errorTempData.errorSWPNoOfInstalment = true;
                    errorTempData.errorSWPNoOfInstalmentMsg = "Number of installments can not be empty";
                    valid = false;
                }
                else if (Number(swp_stpPageData.SWPinstallment) < Number(selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTNUMBERS) ||
                    Number(swp_stpPageData.SWPinstallment) > Number(selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTNUMBERS)) {
                    errorTempData.errorSWPNoOfInstalment = true;
                    errorTempData.errorSWPNoOfInstalmentMsg = `Please enter number greater than ${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTNUMBERS} and less than ${selectedFrequencyValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`;
                    valid = false;
                }
                else {
                    errorTempData.errorSWPNoOfInstalment = false;
                }
            }

            if (swp_stpPageData.SIPDate === null || swp_stpPageData.SIPDate === "") {
                errorTempData.errorSWPDay = true;
                errorTempData.errorSWPDayMsg = "SWP Date can not be empty";
                valid = false;
            }
            else {
                errorTempData.errorSWPDay = false;
            }
            if (radioValue !== 'recommend') {
                if (swp_stpPageData.firstOrderToday === null || swp_stpPageData.firstOrderToday === undefined || swp_stpPageData.firstOrderToday === "") {
                    errorTempData.errorFirstOrderToday = true;
                    errorTempData.errorMessageFirstOrderToday = "Please select Yes or No";
                    valid = false;
                }
                else {
                    errorTempData.errorFirstOrderToday = false;
                }
            }
        }
        setError(errorTempData)
        return valid;
    }
    // const setValidationForSelectedFrequency = (schemeId: string, selectedFrequency: string) => {
    //     const currentSchemeValidations = validations ? validations[schemeId] : null;
    //     const currentSchemeFrequencyValidation = currentSchemeValidations.filter((item: any) => item.SIPFREQUENCY == selectedFrequency)[0];
    //     setSelectedFrequencyValidation({ [schemeId]: currentSchemeFrequencyValidation });
    // }
    //To get Validations for all sche,es
    useEffect(() => {
        (async function () {
            const SIPValidationData: any = {};
            let obj: any = {};
            const errorInitialData: any = {};

            if (transactionType === "SWP") { obj = await getSIPValidationData(swp_stpPageData.SCh) }
            else if (transactionType === "STP" && schemeData !== undefined) {
                // const STPSchemeSChVal = schemeData?.filter((ele) => ele.Sch_ID == swp_stpPageData.ToschemeID)[0]?.Productcode_RTA;
                // setSWP_STPPageData({...swp_stpPageData,SCh:JSON.stringify(STPSchemeSChVal)})
                // obj = await getSIPValidationData(JSON.stringify(STPSchemeSChVal))
                obj = await getSIPValidationData(newSchemeSch)

            }
            // SIPValidationData[swp_stpPageData.schemeID] = obj.validations[1]
            // errorInitialData[swp_stpPageData.schemeID] = errorStates;
            SetValidations(obj.validations[1]);
            setSelectedFrequencyValidation({
                Productcode_RTA: swp_stpPageData.SCh,
                SIPFREQUENCY: null,
                SIPDATES: "",
                SIPMINIMUMINSTALLMENTAMOUNT: "",
                SIPMAXIMUMINSTALLMENTAMOUNT: "",
                SIPMINIMUMINSTALLMENTNUMBERS: "",
                SIPMAXIMUMINSTALLMENTNUMBERS: "",
                SCHEMEISIN: "",
                SIPMINIMUMGAP: "",
                IPMAXIMUMGAP: "",
                SIPMULTIPLIERAMOUNT: ""
            });
            // setError(errorInitialData);
        })();
    }, [transactionType, newSchemeSch])

    //To get Validations of one schemes
    const getSIPValidationData = async (sch: string) => {
        const isComponentActive = true;
        try {
            const response = (await dispatch(
                get_recommendation_validation({ sch, TrType: transactionType })
            )) as any;
            if (!isComponentActive) {
                return Promise.resolve();
            }
            return response;
        } catch (e) {
            console.log((e as Error).message);
        }
        return Promise.resolve();
    }


    async function getBSECode(item: any) {
        let isComponentActive = true;

        try {
            // setIsLoading(true);
            const response = (await dispatch(
                get_bse_code({
                    TrType: transactionType === 'SWP' ? 'SWP' : 'STP',
                    Amount: transactionType === 'SWP' ? item.SWPAmount : item.STPAmount,
                    Growthoption: item?.Growthoption,
                    DividendReinvestment: item?.DividendReinvestment,
                    RTACODE: item?.SCh,
                })
            )) as unknown as BSECodeResponse;

            if (!isComponentActive) {
                return;
            }

            if (response && response.bse_code && response.bse_code.length > 0) {
                item.BSESchemeCode = response.bse_code[0][0]?.BSE_ProductCod;

                if (transactionType === 'STP') {
                    const response1 = (await dispatch(
                        get_bse_code({
                            TrType: 'STP',
                            Amount: item.STPAmount,
                            Growthoption: schemeID.split('~')[1] === 'G' ? 1 : 2,
                            DividendReinvestment: schemeID.split('~')[1] !== 'G' ? 1 : 2,
                            RTACODE: newSchemeSch,
                        })
                    )) as unknown as BSECodeResponse;

                    if (response1 && response1.bse_code && response1.bse_code.length > 0) {
                        item.ToBSESchemeCode = response1.bse_code[0][0]?.BSE_ProductCod;
                    }
                }
            }

            // setIsLoading(false);
            return item;
        } catch (e) {
            console.log((e as Error).message);
        } finally {
            // setIsLoading(false);
        }

        return () => {
            isComponentActive = false;
        };
    }

    const handleSubmitData = async (swp_stpData: any) => {
        let shouldReturn = false;
        if (!SWPValidation() || !STPValidation()) shouldReturn = true;
        const data: any = [];
        if (radioValue === 'recommend') {
            const obj = transactionType === "SWP" ? {
                "SchemeID": swp_stpData.schemeID,
                "InvestmentAmount": Number(swp_stpData.SWPAmount),
                "SchemeType": "SWP",
                "rmID": rmId,
                "ClientCode": investorId,
                "No_of_Installments": swp_stpData.SWPFrequency === 'DAILY' ? null : Number(swp_stpData?.SWPinstallment),
                "SIP_date": swp_stpData.SIPDate,
                "Frequency": swp_stpPageData.SWPFrequency,
                "AccountID": swp_stpData.AccountID,
                "ToSchemeID": "",
                "Folio": swp_stpData.folio,
                "Units": 0,
                "PFFlag": null
            }
                :
                {
                    "SchemeID": swp_stpData.schemeID,
                    "InvestmentAmount": Number(swp_stpData.STPAmount),
                    "SchemeType": "STP",
                    "rmID": rmId,
                    "ClientCode": investorId,
                    "No_of_Installments": swp_stpData.STPFrequency === 'DAILY' ? null : Number(swp_stpData?.STPinstallment),
                    "SIP_date": swp_stpData.SIPDate,
                    "Frequency": swp_stpPageData.STPFrequency,
                    "AccountID": swp_stpData.AccountID,
                    "ToSchemeID": schemeID.split('~')[0],
                    "Folio": swp_stpData.folio,
                    "Units": 0,
                    "PFFlag": null
                }
            data.push(obj);
            const recommendationData = {
                recommendation_data: data
            }
            if (shouldReturn == true) return;
            setError(errorStates);
            setSWP_STPPageData({ ...swp_stpPageData, SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", switchValue: { value: "", type: "INR" }, ToSchemeName: "", ToschemeID: "", newSchemeSch: "", SIPDate: "" })
            await postRecommendedSchemes(recommendationData);
        }
        else {
            await getBSECode(swp_stpData);
            const obj = transactionType === "SWP" ? {
                "AccNo": swp_stpData?.folio,
                "AmcId": swp_stpData?.AmcID,
                "BSE_SchemeCode": swp_stpData?.BSESchemeCode,
                "DividendReinvestment": swp_stpData?.DividendReinvestment,
                // "EndDate": "15/08/2026",
                "FParFul": "p",
                "FUnsAmt": "a",
                "FirstOrderToday": swp_stpData?.firstOrderToday,
                "Frequency": frequencyMapping[swp_stpData.SWPFrequency],
                "Growthoption": swp_stpData.Growthoption,
                "Installments": swp_stpData.SWPFrequency === 'DAILY' ? "" : swp_stpData?.SWPinstallment,
                "InstallmentsAmt": swp_stpData?.SWPAmount,
                "Sch": swp_stpData.SCh,
                "StartDate": (swp_stpData?.SIPDate ? new Date(swp_stpData.SIPDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })),
                "TrType": "SWP",
                "TransTypeDate": new Date().toJSON().slice(0, 10).split("-").reverse().join('/'),
                "Units": "0",
                "rmID": rmId,
                "AccountID": swp_stpData?.AccountID,
                "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
            }
                :
                {
                    "AccNo": swp_stpData?.folio,
                    "AmcId": swp_stpData?.AmcID,
                    "BSE_SchemeCode": swp_stpData?.BSESchemeCode,
                    "DividendReinvestment": swp_stpData?.DividendReinvestment,
                    // "EndDate": "15/08/2026",
                    "FirstOrderToday": swp_stpData?.firstOrderToday,
                    "Frequency": frequencyMapping[swp_stpData.STPFrequency],
                    "Growthoption": swp_stpData.Growthoption,
                    "Installments": swp_stpData.STPFrequency === 'DAILY' ? "" : swp_stpData?.STPinstallment,
                    "InstallmentsAmt": swp_stpData?.STPAmount,
                    "Sch": swp_stpData.SCh,
                    "StartDate": (swp_stpData?.SIPDate ? new Date(swp_stpData.SIPDate).toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' }) : new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' })),
                    "ToBSE_SchemeCode": swp_stpData?.ToBSESchemeCode,
                    "ToSch": newSchemeSch,
                    "TrType": "STP",
                    "TransTypeDate": new Date().toJSON().slice(0, 10).split("-").reverse().join('/'),
                    "rmID": rmId,
                    "AccountID": swp_stpData?.AccountID,
                    "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
                }
            data.push(obj);
            if (shouldReturn == true) return;
            setError(errorStates);
            setSWP_STPPageData({ ...swp_stpPageData, SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", switchValue: { value: "", type: "INR" }, ToSchemeName: "", ToschemeID: "", newSchemeSch: "", SIPDate: "", firstOrderToday: "" })
            const confirmationResult = await Swal.fire({
                html: '<b>Are you sure you want to place this order?</b>',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, place order",
                customClass: {
                    container: 'my-swal',
                    htmlContainer: 'custom-html-container-class',
                },
            });
            // API call to create transaction
            if (confirmationResult.isConfirmed) {
                await create_transaction(data[0])
            }
        }
    }
    async function postRecommendedSchemes(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    post_recommended_schemes(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    async function create_transaction(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    RM_create_transaction(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
                setPlaceOrderResponse(response)
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }



    //RMids
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])



    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Holdings
        </Typography>,
    ];

    //function to handle change of asset class tabs
    const handleChange = (event: any, value: any) => {
        setValue(value);
    };

    const open = Boolean(anchorEl);
    // console.log(holdingName["0"]["Dynamic Bond"])
    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }



    const formatDate = (inputDate: any) => {
        const date = new Date(inputDate);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');

        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };


    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };
    //SCHEME NAMES
    useEffect(() => {

        setSchemeData(null);
        swp_stpPageData.ToSchemeName = "";
        swp_stpPageData.ToschemeID = "";
        swp_stpPageData.STPFrequency = "";
        swp_stpPageData.STPAmount = "";
        swp_stpPageData.STPFrequency = "";
        swp_stpPageData.STPinstallment = "";
        swp_stpPageData.SIPDate = ""
        if (astId) (async function () {
            try {
                const response = (await dispatch(
                    getSchemeName(
                        {
                            "AstId": AssetclassMapping[astId],
                            "AmcId": `${swp_stpPageData.AmcID}`
                        }
                    )
                )) as unknown as GetSchemeType;
                // if (!isComponentActive) {
                //     return;
                // }
                // setLoadingAsset(false);
                const filteredSchemes = response.schemes[0].filter((val: SchemeType) => {
                    return val['STPFLAG'] === "Y"
                });
                setSchemeData(filteredSchemes)
                // .filter((ele) => ele.Sch_ID.split("~")[0] !== JSON.stringify(swp_stpPageData.schemeID).split("~")[0]));
            } catch (e) {
                // setLoadingAsset(false);
                console.log((e as Error).message);
            }
        })();
        // console.log(Number(schemeData.Sch_ID.split("~")[0]))
        // console.log(schemeData.Sch_ID.split("~")[0])
        // console.log(schemeData.Sch_ID)
        // console.log(swp_stpPageData.schemeID)
        // return () => {
        //     isComponentActive = false;
        // };

    }, [dispatch, astId]);


    const theme = useTheme();

    const handleChangeFolio = (event: SelectChangeEvent<typeof folioName>) => {
        const {
            target: { value },
        } = event;
        setFolioName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeAccountType = (event: SelectChangeEvent<typeof accountType>) => {
        const {
            target: { value },
        } = event;
        setAccountType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeScheme = (event: SelectChangeEvent<typeof schemeName>) => {
        const {
            target: { value },
        } = event;
        setSchemeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <div>

            <CommonLayout>
                <>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                        <Button
                            sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick_}
                        >
                            {selectedValue}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                        <Menu
                            sx={{ cursor: "pointer" }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendation</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                        </Menu>
                    </Box>

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'common.white',
                            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            py: 2,
                        }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ArrowBackIosIcon onClick={() => handleMenuItemClick('Holdings')} sx={{ marginInline: "4rem 1rem", cursor: "pointer" }} />
                            <h3>STP/SWP Transaction</h3>
                        </Box>
                        <Divider />

                        <h2 style={{ textAlign: "center", background: "#eef9f4", width: "100%", paddingBlock: "0.5rem" }}><span style={{ fontWeight: "500" }}>Scheme Name</span> - {holdingName.schemeName}</h2>
                        <FormControl sx={{ px: 8, pt: 2 }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadioChange}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                    '& .MuiFormControlLabel-root': {
                                        color: '#5a7c82'
                                    },
                                    '& .Mui-checked .MuiRadio-root': {
                                        color: '#337FC9',
                                    },
                                    '& .MuiRadio-root:not(.Mui-checked)': {
                                        color: '#5a7c82'
                                    },
                                }}
                            >

                                <FormControlLabel value="recommend" control={<Radio />} label="Recommend" />
                                <FormControlLabel value="onbehalf" control={<Radio />} label="On behalf of client" />
                                <FormControlLabel value="onrequest" control={<Radio />} label="Requested by client" />

                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ width: "60%", marginLeft: "4rem", mt: "1rem" }}>
                            <Box className="form" >

                                <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: "500", mt: 2, mb: 2 }}>Select Transaction Type</FormLabel>
                                <RadioGroup sx={{ paddingRight: "12.5rem" }}
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"

                                    onChange={(e: any) => {

                                        setSWP_STPPageData({ ...swp_stpPageData, frequencySelected: "", transactionType: e.target.value, SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", newSchemeSch: "", ToSchemeName: "", ToschemeID: "", STPFrequency: "", SIPDate: "", SWPFrequency: "" })
                                    }}
                                    value={swp_stpPageData.transactionType}
                                >
                                    <FormControlLabel
                                        onClick={() => {
                                            setTransactionType("SWP")
                                            setNewSchemeSch("")
                                            setSWP_STPPageData({ ...swp_stpPageData, frequencySelected: "", SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", ToSchemeName: "", newSchemeSch: "", ToschemeID: "", STPFrequency: "", SIPDate: "", SWPFrequency: "", firstOrderToday: "" })
                                        }}
                                        value="SWP" control={<Radio />} label="SWP" />
                                    <FormControlLabel
                                        onClick={() => {
                                            setTransactionType("STP")
                                            setNewSchemeSch("")
                                            setSWP_STPPageData({ ...swp_stpPageData, frequencySelected: "", SWPAmount: "", SWPinstallment: "", STPinstallment: "", STPAmount: "", ToSchemeName: "", newSchemeSch: "", ToschemeID: "", STPFrequency: "", SIPDate: "", SWPFrequency: "", firstOrderToday: "" })
                                        }}

                                        value="STP" control={<Radio />} label="STP" />
                                </RadioGroup>

                            </Box>
                            <Box className="form">
                                <p >Selected Folio</p>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                    <FormControl sx={{ width: "100%", mt: 2 }}>
                                        <Select
                                            disabled
                                            displayEmpty
                                            value={[swp_stpPageData.folio]}
                                            onChange={handleChangeFolio}
                                            input={<OutlinedInput />}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <em>{swp_stpPageData.folio}</em>;
                                                }

                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>

                            {transactionType === "STP" ?
                                <>
                                    <Box className="form">
                                        <p>From Scheme</p>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                            <FormControl sx={{ width: "100%", mt: 2 }}>
                                                <Select
                                                    disabled
                                                    displayEmpty
                                                    value={schemeName}
                                                    onChange={handleChangeScheme}
                                                    input={<OutlinedInput />}
                                                    renderValue={(selected: any) => {
                                                        if (selected.length === 0) {
                                                            return <em>{swp_stpPageData.schemeName}</em>;
                                                        }

                                                        return selected;
                                                    }}
                                                    MenuProps={MenuProps}
                                                    inputProps={{ 'aria-label': 'Without label' }}
                                                >


                                                </Select>
                                            </FormControl>
                                        </Box>
                                    </Box>
                                    <Box className="form">
                                        <p>Asset Class <b style={{ color: 'red' }}>*</b></p>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                            <FormControl sx={{ mt: 2, width: "100%" }}>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    displayEmpty
                                                    value={astId}
                                                    onChange={(e: any) => {
                                                        setAstId(e.target.value)

                                                    }}
                                                    input={<OutlinedInput />}

                                                    renderValue={(selected: any) => {
                                                        if (selected === "" || selected == undefined) {

                                                            return <em>Select Asset Class</em>;
                                                        }

                                                        return selected;
                                                    }}


                                                >
                                                    {Object.keys(AssetclassMapping).map((assetClassName, index) => (
                                                        <MenuItem
                                                            key={index}
                                                            value={assetClassName}
                                                        >
                                                            {assetClassName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            {error.errorAstId && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorMessageAstId} </Typography>}
                                        </Box>
                                    </Box>
                                    {astId &&
                                        <Box className="form">
                                            <p>To Scheme <b style={{ color: 'red' }}>*</b></p>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                <FormControl sx={{ mt: 2, width: "100%" }}>
                                                    <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        displayEmpty
                                                        value={swp_stpPageData.ToSchemeName}
                                                        onChange={(e: any) => {
                                                            setSWP_STPPageData({ ...swp_stpPageData, newSchemeSch: newSchemeSch, ToSchemeName: e.target.value, ToschemeID: schemeID })

                                                        }}
                                                        input={<OutlinedInput />}

                                                        renderValue={(selected: any) => {
                                                            if (selected === "" || selected == undefined) {

                                                                return <em>Select Scheme</em>;
                                                            }

                                                            return selected;
                                                        }}


                                                    >
                                                        {schemeData?.map((val: SchemeType, index: any) =>
                                                        (
                                                            <MenuItem
                                                                onClick={() => { setSchemeID(val.Sch_ID); setNewSchemeSch(val.Productcode_RTA); }}
                                                                key={index}
                                                                value={val.Sch_Desc}
                                                            // style={getStyles(name, schemeName, theme)}
                                                            >
                                                                {val.Sch_Desc}
                                                            </MenuItem>
                                                        ))
                                                        }
                                                    </Select>
                                                </FormControl>
                                                {error.errorToSchemeID && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorToSchemeIDMsg} </Typography>}
                                            </Box>
                                        </Box>
                                    }
                                    {newSchemeSch &&

                                        <Box className="form">
                                            <p>Frequency <b style={{ color: 'red' }}>*</b></p>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                <FormControl sx={{ mt: 2, width: "100%" }}>
                                                    <Select
                                                        labelId="demo-simple-select-autowidth-label"
                                                        id="demo-simple-select-autowidth"
                                                        displayEmpty

                                                        value={swp_stpPageData.STPFrequency}

                                                        input={<OutlinedInput />}
                                                        renderValue={(selected: any) => {
                                                            if (selected === "" || selected == undefined) {

                                                                return <em>Select Frequency</em>;
                                                            }
                                                            return selected;
                                                        }}

                                                        onChange={(e: any) => {
                                                            swp_stpPageData.STPFrequency = e.target.value;
                                                            setSWP_STPPageData(swp_stpPageData)
                                                            setSelectedFrequencyValidation(validations?.filter((ele: any) => ele.SIPFREQUENCY == e.target.value)[0]);
                                                        }}
                                                        fullWidth

                                                    >
                                                        {validations?.map((data: any, key: any) => {
                                                            return (
                                                                <MenuItem value={data.SIPFREQUENCY} key={key} >{data.SIPFREQUENCY}</MenuItem>
                                                            )
                                                        })}
                                                    </Select>
                                                </FormControl>
                                                {error.errorFrequency && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorFrequencyMsg} </Typography>}
                                            </Box>
                                        </Box>}
                                    {swp_stpPageData.STPFrequency !== "" ? <>
                                        <Box className="form">
                                            <p >STP Amount <b style={{ color: 'red' }}>*</b></p>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", paddingLeft: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Min. - ₹${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT}`}
                                                    value={swp_stpPageData.STPAmount as unknown as number}
                                                    onChange={(e) => {
                                                        setSWP_STPPageData({ ...swp_stpPageData, STPAmount: e.target.value })
                                                    }}
                                                ></input>
                                                {error.errorSTPAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSTPAmountMsg} </Typography>}
                                            </Box>
                                        </Box>
                                        {swp_stpPageData.STPFrequency === 'DAILY' ? '' : <Box className="form">
                                            <p>Instalments <b style={{ color: 'red' }}>*</b></p>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", paddingLeft: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number'
                                                    placeholder={`Enter Number ${selectedFrequencyValidation && selectedFrequencyValidation.SIPMINIMUMINSTALLMENTNUMBERS} - ${selectedFrequencyValidation && selectedFrequencyValidation.SIPMAXIMUMINSTALLMENTNUMBERS}`}
                                                    min="0"
                                                    value={swp_stpPageData.STPinstallment as unknown as string}
                                                    onChange={(e) => {
                                                        setSWP_STPPageData({ ...swp_stpPageData, STPinstallment: e.target.value })
                                                    }}
                                                ></input>
                                                {error.errorSTPNoOfInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSTPNoOfInstalmentMsg} </Typography>}
                                            </Box>
                                        </Box>}
                                        <Box className="form" sx={{ marginTop: "1rem" }}>
                                            <p >STP Date <b style={{ color: 'red' }}>*</b></p>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                <DatePicker
                                                    className="custom-datepicker2"
                                                    // ref={datePickerRef}
                                                    selected={selectedDate}
                                                    onChange={(date: Date) => {
                                                        setSelectedDate(date)
                                                        const day = date.getDate()
                                                        const month = date.getMonth() + 1
                                                        const year = date.getFullYear()
                                                        setSWP_STPPageData({ ...swp_stpPageData, SIPDate: `${month}/${day}/${year}` });
                                                    }}
                                                    dateFormat="dd-MM-yyyy"
                                                    filterDate={isDateDisabled}
                                                    placeholderText={'dd-mm-yyyy'}
                                                // customInput={<CustomInput />}
                                                />
                                                {transactionType === "STP" ? (error.errorSTPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSTPDayMsg} </Typography>) : (error.errorSWPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSWPDayMsg} </Typography>)}
                                            </Box>
                                        </Box>
                                        {radioValue != 'recommend' ?
                                            <Box className="form" sx={{ marginTop: "1rem" }}><p>First Order Today <b style={{ color: 'red' }}>*</b></p>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                                        name="row-radio-buttons-group"
                                                        sx={{
                                                            '& .MuiSvgIcon-root': {
                                                                fontSize: 18,
                                                            },
                                                            '& .MuiFormControlLabel-root': {
                                                                color: '#5a7c82'
                                                            },
                                                            '& .Mui-checked .MuiRadio-root': {
                                                                color: '#337FC9',
                                                            },
                                                            '& .MuiRadio-root:not(.Mui-checked)': {
                                                                color: '#5a7c82'
                                                            },
                                                        }}
                                                        value={swp_stpPageData.firstOrderToday}

                                                        onChange={(e) => {
                                                            // if (!isNaN(Number(e.target.value))) {
                                                            swp_stpPageData.firstOrderToday = e.target.value === 'yes' ? 'Y' : 'N';
                                                            setSWP_STPPageData({ ...swp_stpPageData, firstOrderToday: swp_stpPageData.firstOrderToday })

                                                            // }
                                                        }}>

                                                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                    {error.errorFirstOrderToday && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorMessageFirstOrderToday} </Typography>}
                                                </Box>
                                            </Box>
                                            : ''}
                                    </> : <></>}

                                </> :
                                <>

                                    <Box className="form">
                                        <p>Frequency <b style={{ color: 'red' }}>*</b></p>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                            <FormControl sx={{ mt: 2, width: "100%" }}>
                                                <Select
                                                    labelId="demo-simple-select-autowidth-label"
                                                    id="demo-simple-select-autowidth"
                                                    displayEmpty

                                                    value={swp_stpPageData.frequencySelected}

                                                    input={<OutlinedInput />}
                                                    renderValue={(selected: any) => {
                                                        if (selected === "" || selected == undefined) {

                                                            return <em>Select Frequency</em>;
                                                        }
                                                        return selected;
                                                    }}

                                                    onChange={(e: any) => {
                                                        swp_stpPageData.frequencySelected = e.target.value;
                                                        setSWP_STPPageData({ ...swp_stpPageData, SWPFrequency: e.target.value })
                                                        setSelectedFrequencyValidation(validations?.filter((ele: any) => ele.SIPFREQUENCY == e.target.value)[0]);
                                                    }}
                                                    fullWidth

                                                >
                                                    {validations?.map((data: any, key: any) => {
                                                        return (
                                                            <MenuItem value={data.SIPFREQUENCY} key={key} >{data.SIPFREQUENCY}</MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            {error.errorFrequency && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorFrequencyMsg} </Typography>}
                                        </Box>
                                    </Box>
                                    {swp_stpPageData.SWPFrequency !== "" ?
                                        <>
                                            <Box className="form">
                                                <p >SWP Amount <b style={{ color: 'red' }}>*</b></p>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                    <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", paddingLeft: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Min. - ₹${selectedFrequencyValidation?.SIPMINIMUMINSTALLMENTAMOUNT}`}
                                                        onChange={(e) => {
                                                            setSWP_STPPageData({ ...swp_stpPageData, SWPAmount: e.target.value })
                                                        }}
                                                    ></input>
                                                    {error.errorSWPAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSWPAmountMsg} </Typography>}
                                                </Box>
                                            </Box>
                                            {swp_stpPageData.SWPFrequency === 'DAILY' ? '' : <Box className="form">
                                                <p>Instalments <b style={{ color: 'red' }}>*</b></p>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                    <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", paddingLeft: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number'
                                                        placeholder={`Enter Number ${selectedFrequencyValidation && selectedFrequencyValidation.SIPMINIMUMINSTALLMENTNUMBERS} - ${selectedFrequencyValidation && selectedFrequencyValidation.SIPMAXIMUMINSTALLMENTNUMBERS}`} min="0"
                                                        value={swp_stpPageData.SWPinstallment as unknown as string}
                                                        onChange={(e) => {
                                                            setSWP_STPPageData({ ...swp_stpPageData, SWPinstallment: e.target.value })
                                                        }}
                                                    ></input>
                                                    {transactionType === "STP" ? (error.errorSTPNoOfInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSTPNoOfInstalmentMsg} </Typography>) :
                                                        (error.errorSWPNoOfInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSWPNoOfInstalmentMsg} </Typography>)}

                                                </Box>
                                            </Box>}
                                            <Box className="form" sx={{ marginTop: "1rem" }}>
                                                <p >SWP Date <b style={{ color: 'red' }}>*</b></p>
                                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                    <DatePicker
                                                        className="custom-datepicker2"
                                                        // ref={datePickerRef}
                                                        selected={selectedDate}
                                                        onChange={(date: Date) => {
                                                            setSelectedDate(date)
                                                            const day = date.getDate()
                                                            const month = date.getMonth() + 1
                                                            const year = date.getFullYear()
                                                            setSWP_STPPageData({ ...swp_stpPageData, SIPDate: `${month}/${day}/${year}` });
                                                        }}
                                                        dateFormat="dd-MM-yyyy"
                                                        filterDate={isDateDisabled}
                                                        placeholderText={'dd-mm-yyyy'}
                                                    // customInput={<CustomInput />}
                                                    />
                                                    {transactionType === "STP" ? (error.errorSTPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSTPDayMsg} </Typography>) : (error.errorSWPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSWPDayMsg} </Typography>)}
                                                </Box>
                                            </Box>
                                            {/* First order today */}
                                            {radioValue != 'recommend' ?
                                                <Box className="form" sx={{ marginTop: "1rem" }}><p>First Order Today <b style={{ color: 'red' }}>*</b></p>
                                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                                            name="row-radio-buttons-group"
                                                            sx={{
                                                                '& .MuiSvgIcon-root': {
                                                                    fontSize: 18,
                                                                },
                                                                '& .MuiFormControlLabel-root': {
                                                                    color: '#5a7c82'
                                                                },
                                                                '& .Mui-checked .MuiRadio-root': {
                                                                    color: '#337FC9',
                                                                },
                                                                '& .MuiRadio-root:not(.Mui-checked)': {
                                                                    color: '#5a7c82'
                                                                },
                                                            }}
                                                            value={swp_stpPageData.firstOrderToday}

                                                            onChange={(e) => {
                                                                // if (!isNaN(Number(e.target.value))) {
                                                                swp_stpPageData.firstOrderToday = e.target.value === 'yes' ? 'Y' : 'N';
                                                                setSWP_STPPageData({ ...swp_stpPageData, firstOrderToday: swp_stpPageData.firstOrderToday })

                                                                // }
                                                            }}>

                                                            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                        {error.errorFirstOrderToday && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorMessageFirstOrderToday} </Typography>}
                                                    </Box>
                                                </Box>
                                                : ''}
                                        </> : <></>}

                                </>}

                            <Box sx={{ marginTop: "1rem" }}>
                                <Button variant="contained" style={{ padding: "0.5rem", marginLeft: "18rem", width: '55%' }}
                                    onClick={() => { handleSubmitData(swp_stpPageData); }}
                                >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : <> {radioValue === 'recommend' ? "Recommend" : radioValue === 'onbehalf' ? "Place Order On Behalf of Client" : "Place Order Requested by Client"} </>}</Button>
                            </Box>
                            <Typography sx={{ color: 'red', fontSize: '12px' }}> * Mandatory fields</Typography>
                        </Box>


                    </Box>
                    {Recommend ?
                        <Modal
                            open={Recommend}
                            onClose={() => setRecommended(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute' as any,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {radioValue === 'recommend' ?
                                    <> <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                        <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
                                    </>
                                    :
                                    <>

                                        {placeOrderResponse === undefined || placeOrderResponse === null || placeOrderResponse.error ?
                                            < >
                                                <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />
                                                <Typography sx={{ textAlign: "center" }}>Order Failed</Typography>
                                                <Typography sx={{ textAlign: "center" }}>Order ID not generated</Typography>
                                            </>
                                            :
                                            <>
                                                {placeOrderResponse.transaction_created[0].order_success ?
                                                    <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                                    : <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />}
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_success ? 'Order Placed' : 'Order Failed'}</Typography>
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_status}</Typography>
                                            </>
                                        }
                                    </>
                                }
                                <Button onClick={() => { setRecommended(false); handleMenuItemClick('Holdings') }} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                            </Box>
                        </Modal> : <></>}

                </>

            </CommonLayout>

        </div >
    );
}

import { Box, Button, CardMedia, Dialog, Grid, IconButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MFSubmitButton, MFTextField } from '../../lib/formik';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';
import { Formik, setNestedObjectValues } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendOtp,
  verifyPan,
  EnablePOA,
  IsApplicableForPOA,
  SendEmail,
  AcknowledgeAmlCheck,
  sendConsentEmail,
} from '../../redux-store/actions/onBoarding';
import {
  AcknowledgeAmlCheckResponseBody,
  AmlSystemMatch,
  ApplicationProps,
  EnablePOAResponseBody,
  IsApplicableForPOAResponseBody,
  SendConsentEmailResponseBody,
  SendOtpResponseBody,
  VerifyOtpResponseBody,
  VerifyPanResponseBody,
} from '../../redux-store/types/api-types';
import CloseIcon from '@mui/icons-material/Close';
import { addJointHolder, createApplication } from '../../redux-store/actions/application';
import {
  emailRegex,
  individualPanRegex,
  nonIndividualPanRegex,
  phoneRegExp,
} from '../../utils/regex';
import { Location } from 'history';
import { Notes } from '../investors/components';
import MFCheckbox from '../../lib/formik/Checkbox';

// import { DatePicker } from '../../lib/formik/DatePicker';
import { TextDatePicker } from './DatePickerWithTextFeild';

import {
  futureAge,
  getCountryCodes,
  isMinor,
  isOnboardingTypeMinor,
  maxAge,
  preventSpecialCharacters,
} from '../../utils/utilityFunctions';
import {
  APPLICATION_TYPE,
  ENABLE_Email_Consent,
  ENABLE_EMAIL_OTP,
  IS_DOB_APPLICABLE_FOR_AMC,
  onboardingTypeMasters,
  ONBOARDING_OPTIONS,
  USER_ROLES,
} from '../../utils/constant';
import { CountryCode, isValidPhoneNumber } from 'libphonenumber-js';
import { AmlPopup, AmlPopupValues, CountryCodesDropDown } from '../commonComponents';
import { Link as RouterLink } from 'react-router-dom';
import UseRadioGroup from '../../lib/formik/Radio';
import { useSnackbar } from 'notistack';
import { RootStateType } from '../../redux-store/reducers';
import 'react-datepicker/dist/react-datepicker.css';
import { DayJsDatePicker } from './newDatePicker';
import dayjs from 'dayjs';

interface Values {
  pan: string;
  dob: string;
  onboardingType: string;
}

interface MValues {
  pan: string;
  mobile?: string;
  bypassOtp: boolean;
  skipOtpModal: boolean;
  emailId: string;
  hasPOA: boolean;
  dob: string;
  countryCode: string;
  countryNameAndCode: string;
  onboardingOtpEmailConsent?: string;
}
interface EValues {
  pan: string;
  emailId: string;
  dob: string;
}

export default function EnterPAN({
  location,
}: {
  location: Location<{
    firstHolderName: string;
    application: ApplicationProps;
    redirectTo: string;
  }>;
}): JSX.Element {
  const { redirectTo = '', firstHolderName = '', application } = location.state || {};
  const { id, applicants = [], hasPOA } = application || {};
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const [amlPopup, setAmlPopup] = React.useState<{
    panVerificationToken: string;
    pan: string;
    dob: string;
  } | null>(null);
  const [amlFailedresponse, setAmlFailedresponse] = React.useState<AmlSystemMatch>();
  const [amlAcknowledgementLoading, setAmlAcknowledgementLoading] = React.useState(false);
  const currentDate = dayjs();

  const applicantPanNumbers = applicants.map((applicant) => applicant.panNo);
  const validationSchema1 = yup.object().shape({
    pan: yup
      .string()
      .uppercase()
      .length(10, 'PAN must be exactly 10 characters')
      .test('pan', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (!fieldValue) {
          return createError({
            message: 'PAN is required',
            path,
          });
        }
        if (!individualPanRegex.test(fieldValue)) {
          return createError({
            message: 'Only individual PANs are allowed',
            path,
          });
        }
        // if (fieldValue.split('')[3] === 'P' && !individualPanRegex.test(fieldValue)) {
        //   return createError({
        //     message: 'invalid PAN',
        //     path,
        //   });
        // }
        // if (!nonIndividualPanRegex.test(fieldValue)) {
        //   return createError({
        //     message: 'invalid PAN',
        //     path,
        //   });
        // }
        if (fieldValue.split('')[3] === 'P' && applicantPanNumbers.includes(fieldValue)) {
          return createError({
            message: 'There is already same pan for an applicant associated with this application',
            path,
          });
        }
        return true;
      }),
    dob: yup
      .string()
      .nullable()
      .test('dob', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError, parent } = context;
        if (IS_DOB_APPLICABLE_FOR_AMC) {
          if (!fieldValue) {
            return createError({
              message: 'Date of Birth is required',
              path,
            });
          }
          if (futureAge(fieldValue || '')) {
            return createError({
              message: 'Invalid Date',
              path,
            });
          }
          if (isMinor(fieldValue || '') && parent.pan?.split('')[3] === 'P') {
            return createError({
              message: 'Age should be greater than 18',
              path,
            });
          }
          if (maxAge(fieldValue || '') && parent.pan?.split('')[3] === 'P') {
            return createError({
              message: 'Age should be less than 125',
              path,
            });
          }
        }

        return true;
      }),
  });
  const initialValues1: Values = { pan: '', dob: dayjs(currentDate).format("YYYY-MM-DD"), onboardingType: 'normal' };
  const routingAfterPanVerification = async (
    response: VerifyPanResponseBody | AcknowledgeAmlCheckResponseBody,
    pan: string,
    dob: string
  ) => {
    if (hasPOA) {
      const { panVerificationToken } = response || {};
      const poaResponse = (await dispatch(
        EnablePOA({ panVerificationToken, powerOfAttorney: true })
      )) as unknown as EnablePOAResponseBody;
      const { applicantVerificationToken } = poaResponse;
      await dispatch(addJointHolder({ applicantVerificationToken }, id));
      history.push(redirectTo, { id });
      return;
    }
    if (response.applicationType === APPLICATION_TYPE.NON_INDIVIDUAL) {
      return history.push('enter-email-id', {
        pan: pan.toUpperCase(),
        dob: dob,
        ...(response || {}),
      });
    }
    history.push('enter-mobile-number', {
      pan: pan.toUpperCase(),
      dob: dob,
      ...(response || {}),
      redirectTo,
      firstHolderName,
      application,
    });
  };

  const handleSubmit1 = async (values: Values) => {
    setAmlAcknowledgementLoading(true);
    try {
      const { pan, dob, onboardingType } = values;
      const response = (await dispatch(
        verifyPan({ pan, dob, onboardingType })
      )) as unknown as VerifyPanResponseBody;
      if (!response.amlCheck) {
        setAmlFailedresponse(response.amlData.system_match[0]);
        return setAmlPopup({
          panVerificationToken: response.panVerificationToken,
          pan: pan,
          dob: dob,
        });
      }
      routingAfterPanVerification(response, pan, dob);
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  const handleClose = () => {
    setAmlPopup(null);
  };

  const onSubmit = async (values: AmlPopupValues) => {
    try {
      setAmlAcknowledgementLoading(true);
      const { amlComments, amlAcknowledgement } = values;
      const { panVerificationToken = '', pan = '', dob = '' } = amlPopup || {};
      setAmlPopup(null);
      const response = (await dispatch(
        AcknowledgeAmlCheck({ panVerificationToken, amlComments, amlAcknowledgement })
      )) as unknown as AcknowledgeAmlCheckResponseBody;
      routingAfterPanVerification(response, pan, dob);
    } catch (e) {
      console.error((e as Error).message);
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({ handleSubmit, resetForm, values }) => (
          <>
            <Box component="form" noValidate onSubmit={handleSubmit}>
              <UseRadioGroup
                disabled={id ? true : false}
                formLabel="Investor Type*"
                name="onboardingType"
                items={Object.keys(onboardingTypeMasters).map((type) => ({
                  value: type,
                  label: onboardingTypeMasters[type],
                }))}
              />
              {isOnboardingTypeMinor(values.onboardingType) && (
                <Box
                  sx={{
                    '& .MuiSvgIcon-root': { display: 'none' },
                    '& .MuiTypography-root': { ml: 0 },
                  }}>
                  <Notes
                    displayContent={
                      'You have selected Minor Investor Onboarding. Please provide Guardian PAN and Date of Birth'
                    }
                  />
                </Box>
              )}
              <MFTextField
                label={isOnboardingTypeMinor(values.onboardingType) ? 'Guardian PAN' : 'PAN'}
                name="pan"
                placeholder="Enter PAN"
                required
                autoComplete="off"
                inputProps={{ style: { textTransform: 'uppercase' } }}
              />
              {IS_DOB_APPLICABLE_FOR_AMC && (
                <DayJsDatePicker
                  id={id}
                  name='dob'
                />
              )}

              <MFSubmitButton label="Verify PAN" loadingEnable={amlAcknowledgementLoading} />
              <Notes
                displayContent={
                  'Add the applicant(s) PAN and if the PAN is KRA verified, the contact details will be populated automatically. PAN will be checked against World-Check database for AML compliance.'
                }
              />
            </Box>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <Box
                sx={{
                  p: { xs: 2, sm: 8 },
                  borderRadius: '10px',
                  // width: { xs: '70%', sm: '70%' },
                  maxWidth: 800,
                  height: { xs: '90%', md: 'unset' },
                  overflowY: 'auto',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiButton-root': {
                      minWidth: 200,
                      fontSize: 16,
                    },
                  }}>
                  <Box sx={{ mb: 8 }}>
                    <CardMedia
                      component="img"
                      src="/images/warning-icon-red.svg"
                      sx={{ width: 'unset' }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: '#337FC9',
                      textAlign: 'center',
                      width: '70%',
                      mb: 5,
                    }}>
                    Unable to verify the details please contact admin
                  </Typography>
                  <Button
                    // component={RouterLink}
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      setOpen(false);
                    }}
                    sx={{ color: 'common.white' }}>
                    Enter another PAN
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <AmlPopup
              handleClose={handleClose}
              amlPopupOpen={amlPopup !== null}
              onSubmit={onSubmit}
              FURTHER_INFORMATION={amlFailedresponse?.FURTHER_INFORMATION}
              EXTERNAL_SOURCES={amlFailedresponse?.EXTERNAL_SOURCES}
              displayDocInfo={true}
            />
          </>
        )}
      </Formik>
    </>
  );
}
export function EnterMobile({
  location,
}: {
  location: Location<{
    redirectTo: string;
    panVerificationToken: string;
    pan: string;
    firstHolderName: string;
    application: ApplicationProps;
    dob: string;
    amlCheck: boolean;
  }>;
}): JSX.Element {
  const {
    redirectTo = '',
    panVerificationToken,
    pan,
    firstHolderName = '',
    application,
    dob,
    amlCheck = false,
  } = location.state || {};
  const { id, applicants = [] } = application || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const [disablePOA, setDisablePOA] = useState(false);
  const applicantMobileNumbers = applicants.map((applicant) => applicant.mobile);
  const { enqueueSnackbar } = useSnackbar();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);

  const validationSchema1 = yup.object().shape({
    pan: yup
      .string()
      .uppercase()
      .length(10, 'PAN must be exactly 10 characters')
      .test('pan', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (!fieldValue) {
          return createError({
            message: 'PAN is required',
            path,
          });
        }
        if (fieldValue.split('')[3] === 'P' && !individualPanRegex.test(fieldValue)) {
          return createError({
            message: 'invalid PAN',
            path,
          });
        }
        if (!nonIndividualPanRegex.test(fieldValue)) {
          return createError({
            message: 'invalid PAN',
            path,
          });
        }
        return true;
      }),
    emailId: yup.string().when('hasPOA', {
      is: (value: boolean) => (disablePOA ? true : !value) && ENABLE_EMAIL_OTP,
      then: yup.string().test('emailId', (value, context) => {
        const { createError, path, parent } = context;
        if (!value) {
          return createError({
            message: 'Email Id is required',
            path,
          });
        }
        if (!emailRegex.test(value)) {
          return createError({
            message: 'Invalid Email Id',
            path,
          });
        }
        return true;
      }),
    }),

    mobile: yup.string().when('hasPOA', {
      is: (value: boolean) => (disablePOA ? true : !value),
      then: yup.string().test('mobile', (value, context) => {
        const { createError, path, parent } = context;
        const codesList = getCountryCodes()
          .map((codes) => {
            if (codes.label === parent.countryNameAndCode) {
              return codes.countryCode;
            }
          })
          .filter((ele) => ele)
          .toString();
        if (!value) {
          return createError({
            message: 'Mobile number is required',
            path,
          });
        }
        if (parent.countryNameAndCode === 'India: +91' && !phoneRegExp.test(value)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        if (!isValidPhoneNumber(value, codesList as CountryCode)) {
          return createError({
            message: 'Invalid Mobile number',
            path,
          });
        }
        // if (applicantMobileNumbers.includes(value)) {
        //   return createError({
        //     message:
        //       'There is already same mobile number for an applicant associated with this application',
        //     path,
        //   });
        // }
        return true;
      }),
    }),
    countryNameAndCode: yup
      .string()
      .nullable()
      .when('hasPOA', {
        is: (value: boolean) => (disablePOA ? true : !value),
        then: yup.string().test('countryNameAndCode', (value, context) => {
          const { createError, path, parent } = context;
          const codesList = getCountryCodes()
            .map((codes) => {
              if (codes.label === value) {
                return codes.countryCode;
              }
            })
            .filter((ele) => ele)
            .toString();
          if (!value) {
            return createError({
              message: 'Country Code is required',
              path,
            });
          }
          if (parent.mobile && !isValidPhoneNumber(parent.mobile, codesList as CountryCode)) {
            return createError({
              message: 'Invalid Mobile number',
              path,
            });
          }

          return true;
        }),
      }),
  });
  const initialValues1: MValues = {
    pan: pan,
    mobile: '',
    emailId: '',
    bypassOtp: false,
    skipOtpModal: false,
    hasPOA: false,
    dob: dob,
    countryCode: '+91',
    countryNameAndCode: 'India: +91',
    onboardingOtpEmailConsent: 'otp',
  };

  const handleCreateApplication = async (applicantVerificationToken: string) => {
    const res = (await dispatch(
      createApplication({ applicantVerificationToken })
    )) as unknown as ApplicationProps;
    history.push('/application/rm-details', { ...(res || {}) });
  };

  const handleSubmit1 = async (values: MValues) => {
    const {
      pan,
      mobile = '',
      bypassOtp = false,
      hasPOA,
      emailId,
      countryCode = '+91',
      countryNameAndCode = 'India: +91',
      onboardingOtpEmailConsent = 'otp',
    } = values;
    if (hasPOA) {
      const poaResponse = (await dispatch(
        EnablePOA({ panVerificationToken, powerOfAttorney: true })
      )) as unknown as EnablePOAResponseBody;
      const { applicantVerificationToken } = poaResponse;
      await handleCreateApplication(applicantVerificationToken);
      return;
    }
    if (ONBOARDING_OPTIONS[onboardingOtpEmailConsent] === ONBOARDING_OPTIONS.email_consent) {
      (await dispatch(
        sendConsentEmail({
          phoneNumber: mobile.trim(),
          emailId,
          panVerificationToken,
          countryCode: countryNameAndCode?.split(':')[1].trim(),
          countryNameAndCode,
          applicationId: id || '',
        })
      )) as unknown as SendConsentEmailResponseBody;
      enqueueSnackbar('Email sent successfully', {
        variant: 'success',
        autoHideDuration: 3000,
      });
      return history.push(
        [USER_ROLES.AMCAPPROVER, USER_ROLES.POAAPPROVER].includes(role)
          ? `/applications-list`
          : '/applications'
      );
    }

    const response = (await dispatch(
      sendOtp({
        phoneNumber: mobile.trim(),
        emailId,
        panVerificationToken,
        countryCode: countryNameAndCode?.split(':')[1].trim(),
        countryNameAndCode,
        bypassOtp,
      })
    )) as unknown as SendOtpResponseBody;

    const { applicantVerificationToken, phoneNumber } = response || {};
    if (bypassOtp) {
      if (id && redirectTo) {
        (await dispatch(
          addJointHolder({ applicantVerificationToken }, id)
        )) as unknown as ApplicationProps;
        history.push(redirectTo, { id });
        return;
      }
      handleCreateApplication(applicantVerificationToken);
      return;
    }
    history.push('enter-otp', {
      pan,
      unmaskedphone: mobile.trim(),
      umaskedemailId: emailId,
      mobile: phoneNumber,
      panVerificationToken,
      redirectTo,
      application,
      email: (response || {}).emailId,
      firstHolderName,
      countryCode: countryNameAndCode?.split(':')[1].trim(),
      countryNameAndCode,
      ...(response || {}),
    });
  };

  // useEffect(() => {
  //   (async function () {
  //     const res = (await dispatch(
  //       IsApplicableForPOA()
  //     )) as unknown as IsApplicableForPOAResponseBody;
  //     const { powerOfAttorney } = res;
  //     setDisablePOA(
  //       application
  //         ? application.hasPOA
  //           ? application.hasPOA
  //           : !application.hasPOA
  //         : !powerOfAttorney
  //     );
  //   })();
  // }, []);
  const [mobileElementWidth, setMobileElementWidth] = useState(null);
  useEffect(() => {
    const mobileElement = document.getElementsByName('mobile');
    setMobileElementWidth(mobileElement[0]?.parentElement?.clientWidth as any);
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({
          handleSubmit,
          values,
          validateForm,
          isSubmitting,
          setTouched,
          setErrors,
          setFieldValue,
        }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', right: 0, top: 15 }}>
                <CardMedia
                  component="img"
                  src="/images/tick.svg"
                  sx={{ width: '18px', float: 'right' }}
                />
              </Box>
              <MFTextField
                label="PAN"
                name="pan"
                placeholder="Enter PAN"
                required
                inputProps={{ style: { textTransform: 'uppercase' } }}
                disabled={true}
                applyMarginBottom={false}
              />
              <Typography
                variant="body2"
                sx={{ fontWeight: 600, color: 'rgba(35, 133, 63, 0.7)', mb: 0.5 }}>
                KYC Verified,{' '}
                <Typography
                  variant="body2"
                  component={'span'}
                  sx={{
                    fontWeight: 600,
                    color: amlCheck ? 'rgba(35, 133, 63, 0.7)' : '#BD2932',
                    mb: 0.5,
                  }}>
                  AML Check {amlCheck ? 'Passed' : 'Failed'}
                </Typography>
              </Typography>
              {IS_DOB_APPLICABLE_FOR_AMC && (
                <TextDatePicker
                  label={'Date of Birth'}
                  inputLabelStyles={{
                    transform: 'unset',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'rgba(0,0,0,0.7)',
                  }}
                  placeholder={'Enter Date of Birth'}
                  name="dob"
                  disabled={true}
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
                />
              )}

              {/* <MFCheckbox
                name="hasPOA"
                label="Power of Attorney"
                color="primary"
                sx={{
                  '.MuiTypography-root': {
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    letterSpacing: '0.9625px',
                  },
                }}
                disabled={disablePOA}
              /> */}
            </Box>
            {!values.hasPOA && (
              <>
                <MFTextField
                  label="Mobile Number"
                  name="mobile"
                  placeholder="Enter Mobile number"
                  onKeyDown={(e) => {
                    preventSpecialCharacters(e);
                  }}
                  startAdornment={
                    <CountryCodesDropDown
                      ElementWidth={mobileElementWidth && mobileElementWidth}
                      name={'countryNameAndCode'}
                      value={values.countryNameAndCode}
                    />
                  }
                />
                {ENABLE_EMAIL_OTP && (
                  <MFTextField
                    autoComplete="off"
                    // autoFocus
                    label="Email ID"
                    name="emailId"
                    placeholder="Enter Email ID"
                    required
                  />
                )}
                {ENABLE_Email_Consent && (
                  <UseRadioGroup
                    formLabel=""
                    name={`onboardingOtpEmailConsent`}
                    defaultValue="otp"
                    items={Object.keys(ONBOARDING_OPTIONS).map((option) => ({
                      label: ONBOARDING_OPTIONS[option],
                      value: option,
                    }))}
                  />
                )}
                <Notes
                  displayContent={
                    // 'Provide the mobile number which is registered in KRA database. Applicants Identity details will be fetched from the KRA database.'
                    'This mobile number and email id will be used for all future communication related to this investment.'
                  }
                />
              </>
            )}
            <MFSubmitButton
              label={
                values.hasPOA
                  ? 'Continue'
                  : ONBOARDING_OPTIONS[values.onboardingOtpEmailConsent || ''] ===
                    ONBOARDING_OPTIONS.email_consent
                    ? 'Send Email'
                    : 'Get OTP'
              }
            />
            {!values.hasPOA &&
              ONBOARDING_OPTIONS[values.onboardingOtpEmailConsent || ''] ===
              ONBOARDING_OPTIONS.otp && (
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: 'primary.main',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 0.5,
                    lineHeight: 1.5,
                  }}
                  disabled={isSubmitting}
                  onClick={async () => {
                    const error = await validateForm();
                    if (Object.keys(error).length) {
                      setTouched(setNestedObjectValues(error, true));
                      setErrors(error);
                      return;
                    }
                    setFieldValue('skipOtpModal', true);
                  }}>
                  Skip OTP Verification
                </Button>
              )}
            <Dialog open={values.skipOtpModal} onClose={() => setFieldValue('skipOtpModal', false)}>
              <Box
                sx={{
                  borderRadius: '10px',
                  // width: { xs: '70%', sm: '70%' },
                  maxWidth: 800,
                  height: { xs: '90%', md: 'unset' },
                  overflowY: 'auto',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                    '& .MuiButton-root': {
                      minWidth: 200,
                      fontSize: 16,
                    },
                  }}>
                  <IconButton
                    onClick={() => setFieldValue('skipOtpModal', false)}
                    sx={{ alignSelf: 'flex-end' }}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                  {/* <Box sx={{ mb: 4 }}>
                    <CardMedia
                      component="img"
                      src="/images/warning-icon-red.svg"
                      sx={{ width: 'unset' }}
                    />
                  </Box> */}
                  <Typography
                    sx={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: '#337FC9',
                      textAlign: 'center',
                      mb: 5,
                      width: '90%',
                    }}>
                    Are you sure you want to Skip OTP Verification?
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                      width: '100%',
                    }}>
                    <Button
                      variant="outlined"
                      disabled={isSubmitting}
                      onClick={() => setFieldValue('skipOtpModal', false)}
                      sx={{ color: 'primary.main', mb: 2 }}>
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ mb: 2 }}
                      onClick={() => {
                        setFieldValue('bypassOtp', true);
                        handleSubmit();
                        setFieldValue('skipOtpModal', false);
                      }}>
                      Continue
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Dialog>
          </Box>
        )}
      </Formik>
    </>
  );
}

export function EnterEmailId({
  location,
}: {
  location: Location<{
    panVerificationToken: string;
    pan: string;
    dob: string;
  }>;
}): JSX.Element {
  const { panVerificationToken, pan, dob } = location.state || {};
  const dispatch = useDispatch();
  const history = useHistory();
  const validationSchema1 = yup.object().shape({
    pan: yup
      .string()
      .uppercase()
      .length(10, 'PAN must be exactly 10 characters')
      .test('pan', (value, context) => {
        const fieldValue = value === undefined ? '' : value;
        const { path, createError } = context;
        if (!fieldValue) {
          return createError({
            message: 'PAN is required',
            path,
          });
        }
        if (fieldValue.split('')[3] === 'P' && !individualPanRegex.test(fieldValue)) {
          return createError({
            message: 'invalid PAN',
            path,
          });
        }
        if (!nonIndividualPanRegex.test(fieldValue)) {
          return createError({
            message: 'invalid PAN',
            path,
          });
        }
        return true;
      }),
    emailId: yup
      .string()
      .nullable()
      .matches(emailRegex, 'invalid Email ID')
      .required('Email is required'),
  });
  const initialValues1: EValues = {
    pan: pan,
    emailId: '',
    dob: dob,
  };

  const handleSubmit1 = async (values: EValues) => {
    const { emailId } = values;
    const response = (await dispatch(
      SendEmail({ panVerificationToken, emailId })
    )) as unknown as VerifyOtpResponseBody;
    const { applicantVerificationToken } = response || {};
    const res = (await dispatch(
      createApplication({ applicantVerificationToken })
    )) as unknown as ApplicationProps;
    history.push('/non-individual-application', { ...(res || {}) });
    // history.push('verify-documents', {
    //   applicantVerificationToken,
    // });
  };

  return (
    <>
      <Formik
        initialValues={initialValues1}
        validationSchema={validationSchema1}
        onSubmit={handleSubmit1}>
        {({ handleSubmit }) => (
          <Box component="form" noValidate onSubmit={handleSubmit}>
            <Box sx={{ position: 'relative' }}>
              <Box sx={{ position: 'absolute', right: 0, top: 15 }}>
                <CardMedia
                  component="img"
                  src="/images/tick.svg"
                  sx={{ width: '18px', float: 'right' }}
                />
              </Box>
              <MFTextField
                label="PAN"
                name="pan"
                placeholder="Enter PAN"
                required
                inputProps={{ style: { textTransform: 'uppercase' } }}
                disabled={true}
              />
              {IS_DOB_APPLICABLE_FOR_AMC && (
                <TextDatePicker
                  label={'Date of Birth/Incorporation'}
                  inputLabelStyles={{
                    transform: 'unset',
                    fontSize: 14,
                    fontWeight: 500,
                    color: 'rgba(0,0,0,0.7)',
                  }}
                  placeholder={'Enter Date of Birth'}
                  name="dob"
                  minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
                  disabled={true}
                />
              )}
            </Box>
            <MFTextField
              autoComplete="off"
              autoFocus
              label="Contact Person Email ID"
              name="emailId"
              placeholder="Enter Contact Person Email ID"
              required
            />

            <Notes
              displayContent={
                'Based on registered email id, we will fetch the documents available with our MF holding that are serviced by kfintech and the data will be prefilled in the application.'
              }
            />
            <MFSubmitButton label={'Proceed'} />
          </Box>
        )}
      </Formik>
    </>
  );
}

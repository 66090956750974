import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Divider,
    LinearProgress,
    Menu,
    MenuItem,
    Modal,
    Skeleton,
    Tab,
    Typography
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { GetAccountType, GetAssetClassesType, GetCategoryAverage, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, PortfolioType, RecommendationsType, SchemeList, getPortfolioType, } from '../../../redux-store/types/api-types';
import { getPortfolio, getRmDetails, get_account_type, get_assest_classes, get_category_average, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav } from '../../../redux-store/actions/userManagement';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Bubble } from './investor-transaction-helper';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SchemeDetail from './scheme-detail';
import { styled, alpha } from '@mui/material/styles';
import { HoldingSchemeData } from './types';
import { formatDate_DDMMYYYY } from '../../../utils/date';
import Collapse from '@mui/material/Collapse';
import Filters, { filterDataHolding } from './filterComponent';

export default function holdings({
    location
}: {
    location: Location<{ investorId: string; investorName: string }>
}): JSX.Element {

    const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [TanchorEl, setTAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Holdings');
    const [recommendationData, setRecommendationData] = useState<any | null>(null);
    const [portfolioData, setPortfolioData] = useState<getPortfolioType | null>(null);
    const [isLoadingAsset, setLoadingAsset] = useState(false);
    const [isLoadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
    const [isLoadingschemeDetail, setLoadingSchemeDetail] = useState<boolean>(false);
    const [isLoadingschemeList, setLoadingSchemeList] = useState<boolean>(false);
    const [isLoadingCatAvg, setLoadingCatAvg] = useState<boolean>(false);
    const [isLoadingSchemeNav, setLoadingSchemeNav] = useState<boolean>(false);
    const [currentSchemeDetail, setCurrentSchemeDetail] = useState<PortfolioType>();
    const [value, setValue] = useState<string>("Debt");
    const [assetClassesData, setAssetClassesData] = useState<GetAssetClassesType | null>(null);
    const [count, setCount] = useState(0);
    const [viewSchemeDetails, setViewSchemeDetails] = useState<boolean>(false);
    const [holding, setHolding] = useState<string>();
    const [moreDetails, setMoreDetails] = useState<{ [key: number]: boolean }>({});
    const [accountType, setAccountType] = useState<GetAccountType | null>();
    const [rmId, setRmId] = useState<number | null>();
    const [search, setSearch] = useState<string>('');
    const [AMCList, setAMCList] = useState<{ AMC: string, AMCId: number }[]>([]);
    const [filterData, setFilterData] = useState<filterDataHolding>({
        AMCList: [],
        schemeType: [],
        accountName: []
    });
    const [filteredSchemeList, setFilteredSchemeList] = useState<PortfolioType[] | undefined>(portfolioData?.portfolio_details[0]);
    const dateObj = new Date();
    const month = dateObj.getUTCMonth() + 1;
    const day = dateObj.getUTCDate();
    const year = dateObj.getUTCFullYear();

    const latestDate = month + "/" + day + "/" + year;
    //function for more details
    const handleDetailsClick = (index: number) => {
        setMoreDetails((lessDetails) => {
            const updatedDetails = { ...lessDetails, [index]: !lessDetails[index] };
            return updatedDetails;
        });
    };
    // const handleHover = (index: number) => {
    //     setMoreDetails((lessDetails) => {
    //         const updatedDetails = { ...lessDetails, [index]: true };
    //         return updatedDetails;
    //     });
    // };

    // const handleMouseOut = (index: number) => {
    //     setMoreDetails((lessDetails) => {
    //         const updatedDetails = { ...lessDetails, [index]: false };
    //         return updatedDetails;
    //     });
    // };
    //function to handle change of menu - Explore/recommendation
    const handleTransactionItemClick = (value: any) => {
        if (checkedHoldingName.length === 0) {
            handleOpenModal()
        }
        else {
            if (value === 'SIP_Lumpsum') {

                history.push('/SIP_Lumpsum', {
                    investorId: investorId,
                    investorName: investorName,
                    holdingName: checkedHoldingName,
                });
            }
            else if (value === 'STP_SWP') history.push('/STP_SWP', {
                investorId: investorId,
                investorName: investorName,
                holdingName: checkedHoldingName[0],
            });
            else if (value === 'Switch') history.push('/Switch', {
                investorId: investorId,
                investorName: investorName,
                holdingName: checkedHoldingName[0],
            });
            else if (value === 'Redeem') history.push('/Redeem', {
                investorId: investorId,
                investorName: investorName,
                holdingName: checkedHoldingName[0],
            });
        }
    }

    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };
    useEffect(() => {

        let isComponentActive = true;
        setAssetClassesData(null);
        (async function () {
            try {
                setLoadingAsset(true);
                const response = (await dispatch(
                    get_assest_classes()
                )) as unknown as GetAssetClassesType;
                if (!isComponentActive) {
                    return;
                }
                const sortedAssetClasses = [...response.asset_classes].sort((a, b) =>
                    a.AssetClassName.localeCompare(b.AssetClassName)
                );
                setAssetClassesData({ asset_classes: sortedAssetClasses });
                setLoadingAsset(false);
            } catch (e) {
                setLoadingAsset(false);
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };

    }, [dispatch]);
    //api call for getting rmid
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);

            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    //api call for getaccount type
    useEffect(() => {

        let isComponentActive = true;
        setAccountType(null);
        (async function () {
            try {
                const response = (await dispatch(
                    get_account_type({
                        rmID: rmId,
                        ClientCode: investorId
                    })
                )) as unknown as GetAccountType;
                if (!isComponentActive) {
                    return;
                }
                setAccountType(response);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    // api call for PORTFOLIO
    const body = {
        clientId: investorId,
        // date: JSON.stringify(new Date())
        // date: "12/15/2023"
        date: latestDate
    }
    useEffect(() => {

        let isComponentActive = true;

        setPortfolioData(null);
        (async function () {
            try {
                setLoadingAsset(true);
                const response = (await dispatch(
                    getPortfolio(body)
                )) as unknown as getPortfolioType;
                if (!isComponentActive) {
                    return;
                }
                setLoadingAsset(false);
                setPortfolioData(response);
                setFilteredSchemeList(response?.portfolio_details[0])
            } catch (e) {
                setLoadingAsset(false);
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };

    }, [dispatch]);
    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Holdings
        </Typography>,
    ];

    //function to handle change of asset class tabs
    const handleChange = (event: any, value: any) => {
        setValue(value);
    };

    const open = Boolean(anchorEl);

    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }
    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };

    //function to enable showing scheme detail screen
    const handleSetViewSchemeDetails = async (val: boolean, schemeDetail: SchemeList | RecommendationsType | PortfolioType | undefined | null) => {
        setCurrentSchemeDetail(schemeDetail as PortfolioType);
        // const response = await getSchemeDetails(schemeDetail as RecommendationsType);
        // const requiredScheme = response?.sub_asset_classes.filter((item: SchemeList) => item.SchemeID == schemeDetail?.SchemeID);
        // requiredScheme ? setSelectedScheme(requiredScheme[0]) : null;
        setViewSchemeDetails(val);
    };

    const handleCloseSchemeDetails = () => {
        setViewSchemeDetails(false);
    };

    const schemeName = [{}];
    const [schemeDetail, setSchemeDetail] = useState<GetSchemeDetailsType | null>(null);
    //api call for get_scheme_details
    useEffect(() => {
        if (currentSchemeDetail) {
            const scheme_id = currentSchemeDetail.productid;
            let isComponentActive = true;
            setSchemeDetail(null);
            (async function () {
                try {
                    setLoadingSchemeDetail(true);
                    const response = (await dispatch(
                        get_scheme_details(scheme_id)
                    )) as unknown as GetSchemeDetailsType;
                    if (!isComponentActive) {
                        return;
                    }
                    setSchemeDetail(response);
                    setLoadingSchemeDetail(false);
                } catch (e) {
                    setLoadingSchemeDetail(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);

    const [categoryAverage, setCategoryAverage] = useState<GetCategoryAverage | null>(null);
    //api call for get_category_avg
    useEffect(() => {
        if (currentSchemeDetail) {
            const payload = {
                marketValue: false,
                schemeId: currentSchemeDetail.productid
            }
            let isComponentActive = true;
            setCategoryAverage(null);
            (async function () {
                try {
                    setLoadingCatAvg(true);
                    const response = (await dispatch(
                        get_category_average(payload)
                    )) as unknown as GetCategoryAverage;
                    if (!isComponentActive) {
                        return;
                    }
                    setCategoryAverage(response);
                    setLoadingCatAvg(false);
                } catch (e) {
                    setLoadingCatAvg(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);

    const [schemeNav, setSchemeNav] = useState<GetSchemeNav | null>(null);
    //api call for get_scheme_nav
    useEffect(() => {
        if (currentSchemeDetail) {
            const schemeId = currentSchemeDetail.productid;
            let isComponentActive = true;
            setSchemeNav(null);
            (async function () {
                try {
                    setLoadingSchemeNav(true);
                    const response = (await dispatch(
                        get_scheme_nav(schemeId)
                    )) as unknown as GetSchemeNav;
                    if (!isComponentActive) {
                        return;
                    }
                    setSchemeNav(response);
                    setLoadingSchemeNav(false);
                } catch (e) {
                    setLoadingSchemeNav(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const open_transaction = Boolean(TanchorEl);
    const handleClickTransaction = (event: React.MouseEvent<HTMLElement>) => {
        setTAnchorEl(event.currentTarget);
    }
    const handleCloseTransaction = () => {
        setTAnchorEl(null);
    };
    const [checkedHoldingName, setCheckedHoldingName] = useState<HoldingSchemeData[]>([]);

    const handleCheckBox = (e: any, schemeCategory: string, xirr: number, bubbleTag: string[], folio: string, schemeID: number, AmcID: number, AccountID: string, SCh: string, MinimumPurchaseAmount: number,
        MaximumPurchaseAmount: number, currentValue: number, Units: number, Growthoption: number, DividendReinvestment: number, firstOrderToday: string, mandate: string) => {
        // Destructuring
        const { value, checked } = e.target;
        setCount(e.target.checked ? count + 1 : count - 1);
        // Case 1 : The user checks the box
        if (checked) {
            setCheckedHoldingName([...checkedHoldingName, { schemeCategory, schemeName: value, schemeID, bubbleTag, xirr, switchMode: true, accountSelected: "", frequencySelected: "", folio: folio, AmcID, AccountID, SCh, MinimumPurchaseAmount, MaximumPurchaseAmount, currentValue, Units, Growthoption, DividendReinvestment, firstOrderToday, mandate }]);
        }
        // Case 2  : The user unchecks the box
        else {
            setCheckedHoldingName(checkedHoldingName.filter((ele: any) => (ele.schemeCategory != schemeCategory && ele.schemeName != value)));
        }
    };

    //Getting Unique AMC names for filter
    useEffect(() => {
        const uniqueAMCIds = new Set<number>();
        const AmcList: { AMC: string, AMCId: number }[] = [];
        portfolioData?.portfolio_details[0]?.forEach((value: PortfolioType) => {
            if (!uniqueAMCIds.has(value.AmcID)) {
                AmcList.push({ AMC: value.AMCName, AMCId: value.AmcID });
                uniqueAMCIds.add(value.AmcID);
            }
        });
        setAMCList(AmcList.sort((a: any, b: any) => a.AMC.toLowerCase().localeCompare(b.AMC.toLowerCase())));
    }, [portfolioData])

    //Set Filtered Data
    useEffect(() => {
        if (filterData.AMCList.length == 0 && filterData.schemeType.length == 0 && filterData.accountName.length == 0) {
            setFilteredSchemeList(portfolioData?.portfolio_details[0]);
        }
        else {
            // AMC Filter Logic
            let filteredList: PortfolioType[] | undefined = portfolioData?.portfolio_details[0];
            if (filterData.AMCList.length !== 0) {
                filteredList = portfolioData?.portfolio_details[0]?.filter((scheme: PortfolioType) =>
                    filterData.AMCList.some((selectedAMC) => selectedAMC.AMCId === scheme.AmcID)
                )
                setFilteredSchemeList(filteredList);
            }

            // Search AMC Name Filter Logic
            let filteredList2: PortfolioType[] | undefined = filteredList;
            if (search !== "") {
                filteredList2 = filteredList?.filter((item: any) =>
                    Object.values(item).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))
                );
                setFilteredSchemeList(filteredList2);
            }

            // Scheme Type Filter Logic
            let filteredList3: PortfolioType[] | undefined = filteredList2;
            if (filterData.schemeType.length !== 0) {
                filteredList3 = filteredList2?.filter((scheme: PortfolioType) => {
                    const isGrowth = filterData.schemeType.includes('Growth');
                    const isDiv = filterData.schemeType.includes('Dividend');

                    if (isGrowth && isDiv) {
                        return true;
                    } else if (isGrowth) {
                        return scheme.Growthoption === 1;
                    } else if (isDiv) {
                        return scheme.DividendReinvestment === 1 || scheme.DividendPayout === 1;
                    } else {
                        return true;
                    }
                });

                setFilteredSchemeList(filteredList3);
            }

            // AccountName Filter Logic
            let filteredList4: PortfolioType[] | undefined = filteredList3;
            if (filterData.accountName.length !== 0) {
                filteredList4 = filteredList3?.filter((scheme: PortfolioType) => {
                    return filterData.accountName.some((selectedAccount) => selectedAccount.AccountId === scheme.AccountID);
                });

                setFilteredSchemeList(filteredList4);
            }

        }
    }, [filterData, search])

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "400",
        borderRadius: "10px",
        bgcolor: 'background.paper',
        boxShadow: "24",
        p: "4",
        padding: "2rem 6rem",
    }

    const [openModal, setOpenModal] = React.useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);


    return (
        <div>

            <CommonLayout>
                <>
                    {location.pathname === "/investor-transactions" || location.pathname === "/recommended-schemes" || location.pathname === "/holdings" || location.pathname === "/orders" ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                            <Button
                                sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick_}
                            >
                                {selectedValue}
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                            <Menu
                                sx={{ cursor: "pointer" }}
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendations</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                            </Menu>
                        </Box> : <></>}
                    <div>

                        <Modal
                            open={openModal}
                            onClose={handleCloseModal}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <Typography id="modal-modal-title" variant="h6" component="h2">
                                    No Scheme Selected
                                </Typography>
                            </Box>
                        </Modal>
                    </div>
                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    {(isLoadingAsset || isLoadingschemeDetail || isLoadingCatAvg || isLoadingSchemeNav || isLoadingRecommendation || isLoadingschemeList) ? (
                        <LinearProgress sx={{ mt: 2.5 }} />
                    ) :
                        (<Box
                            sx={{
                                bgcolor: 'common.white',
                                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                                borderRadius: '5px',
                                py: 2,
                            }}>

                            {/* Displays recommendation list / scheme detail */}
                            {viewSchemeDetails ?
                                //scheme detail
                                <div>
                                    <SchemeDetail
                                        handleBackClick={() => setViewSchemeDetails(false)}
                                        handleSetViewSchemeDetails={handleSetViewSchemeDetails}
                                        investorId={investorId}
                                        schemeDetail={schemeDetail?.scheme_details}
                                        schemeData={currentSchemeDetail as unknown as SchemeList}
                                        categoryAverage={categoryAverage?.category_averages}
                                        schemeNav={schemeNav?.scheme_nav}
                                    />

                                </div>

                                :
                                //Recommendation List
                                <Box >
                                    {isLoadingAsset ? <Skeleton /> :
                                        <TabContext value={value} >
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: '100%',
                                                '& .MuiTabPanel-root': { py: 2, px: 0 },
                                                '& .MuiTab-root': {
                                                    color: '#A1A2A2',
                                                    opacity: 0.8,
                                                    fontSize: 17,
                                                    lineHeight: '24px',
                                                    textTransform: 'capitalize',
                                                    px: { xs: 2, md: 3, lg: 5 },
                                                    mx: 2,
                                                    '&.Mui-selected': {
                                                        color: '#4B81B1',
                                                    },
                                                },
                                                '& .MuiTabs-indicator': {
                                                    height: 3,
                                                    background: '#4B81B1',
                                                },
                                            }}>
                                                <TabList onChange={handleChange}>
                                                    {assetClassesData?.asset_classes.map((ele, index) => {
                                                        return (<Tab label={ele.AssetClassName} value={ele.AssetClassName} key={index} sx={{ cursor: "pointer" }} />)
                                                    })}

                                                </TabList>
                                                <Box sx={{ marginRight: '1.5rem' }}>
                                                    <Button
                                                        sx={{ border: "2px solid #4B81B1", height: '30px', color: '#4B81B1', fontWeight: 600, fontSize: '10px', cursor: "pointer" }}
                                                        aria-controls={open_transaction ? 'basic-menu' : undefined}
                                                        aria-haspopup="true"
                                                        aria-expanded={open_transaction ? 'true' : undefined}
                                                        onClick={handleClickTransaction}
                                                    >
                                                        New Transaction
                                                        {open_transaction ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                                    </Button>

                                                    <Menu
                                                        sx={{ cursor: "pointer" }}
                                                        id="basic-menu"
                                                        anchorEl={TanchorEl}
                                                        open={open_transaction}
                                                        onClose={handleCloseTransaction}
                                                        MenuListProps={{
                                                            'aria-labelledby': 'basic-button',
                                                        }}
                                                    >
                                                        <MenuItem onClick={() => { handleTransactionItemClick('SIP_Lumpsum') }}>SIP/Lumpsum</MenuItem>
                                                        {count < 2 && <>
                                                            <MenuItem onClick={() => { handleTransactionItemClick('STP_SWP') }}>SWP/STP</MenuItem>
                                                            <MenuItem onClick={() => { handleTransactionItemClick('Switch') }} >Switch</MenuItem>
                                                            <MenuItem onClick={() => { handleTransactionItemClick('Redeem') }}>Redeem</MenuItem>
                                                        </>}
                                                    </Menu>

                                                    <Filters
                                                        AMCList={AMCList}
                                                        filterData={filterData}
                                                        setFilterData={setFilterData}
                                                        accountName={accountType?.account_type}
                                                    />
                                                </Box>
                                            </Box>


                                            <Divider />
                                            <Box>
                                                {assetClassesData?.asset_classes.map((item: any, index: any) => {
                                                    return (

                                                        <TabPanel value={item.AssetClassName} key={index}>
                                                            {/* {isLoadingSubAsset ? <Skeleton /> : <> */}

                                                            {filteredSchemeList?.filter((scheme: any) => scheme.I_Asset_Class === value).length === 0 ? <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                <Typography sx={{ fontSize: 18, color: '#5a7c82', mt: 2 }}>No Portfolio available</Typography>
                                                            </Box> : <>
                                                                {filteredSchemeList?.sort((a: any, b: any) => a.Product.toLowerCase().localeCompare(b.Product.toLowerCase())).filter((scheme: any) => scheme.I_Asset_Class === value) // Filter based on AssetClassName
                                                                    .map((ele: any, index: number) => {
                                                                        return (
                                                                            <div
                                                                                key={index}
                                                                            // style={{ cursor: "pointer" }}
                                                                            // onClick={handleClick(ele, index)}
                                                                            >
                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', my: 2.5, cursor: 'pointer' }} onClick={() => handleDetailsClick(index)}>
                                                                                    <Box sx={{ width: '10%', }}>
                                                                                        <Checkbox sx={{ color: '#337FC9', mx: 0 }}
                                                                                            value={ele.Product}
                                                                                            onChange={(e) => {
                                                                                                const bubbleTag: string[] = [ele.I_Asset_Class, ele.I_AssetClass, ele.PlanShortName];
                                                                                                handleCheckBox(e, ele.I_AssetClass, Nanprocessor(roundToTwo(Number(ele.FolioXirr))), bubbleTag, ele.I_Accno, ele.productid, ele.AmcID, ele.AccountID, ele.SCh, ele.MinimumPurchaseAmount, ele.MaximumPurchaseAmount, ele.AUM, ele.Units, ele.Growthoption, ele.DividendReinvestment, ele.firstOrderToday, ele.mandate)
                                                                                            }}
                                                                                        />
                                                                                        {/* <input
                                                                                        style={{cursor:"pointer",marginLeft:"1rem"}}
                                                                                            type="checkbox"
                                                                                            name="languages"
                                                                                            value={ele.AMCName}
                                                                                            id="flexCheckDefault"
                                                                                            onChange={(e) =>
                                                                                                handleCheckBox(e, ele.AmcID)
                                                                                            }
                                                                                        /> */}
                                                                                    </Box>

                                                                                    <Box sx={{ marginRight: 8, display: 'flex', flexDirection: 'column', width: "30%" }}>
                                                                                        <Typography sx={{ fontSize: 14, fontWeight: 600, mb: 0.5 }}>
                                                                                            {ele.Product}
                                                                                        </Typography>
                                                                                        <Box sx={{ display: 'flex', width: "16rem" }}>
                                                                                            {!!ele.I_Asset_Class && (
                                                                                                <Bubble text={ele.I_Asset_Class} />
                                                                                            )}
                                                                                            {!!ele.I_AssetClass && (
                                                                                                <Bubble text={ele.I_AssetClass} />
                                                                                            )}
                                                                                            {!!ele.PlanShortName && (
                                                                                                <Bubble text={ele.PlanShortName} />
                                                                                            )}


                                                                                        </Box>
                                                                                    </Box>

                                                                                    <Box sx={{ width: "30%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>

                                                                                            Invested Amount
                                                                                        </Typography>
                                                                                        <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>

                                                                                            {`Rs. ${ele.COST}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "30%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>

                                                                                            Current Value
                                                                                        </Typography>
                                                                                        <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>

                                                                                            {`Rs. ${ele.AUM}`}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "20%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Units</Typography>
                                                                                        <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                                                                            {ele.Units}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>XIRR</Typography>
                                                                                        <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                                                                                            {Nanprocessor(roundToTwo(Number(ele.FolioXirr)))} %
                                                                                        </Typography>
                                                                                    </Box>

                                                                                    {/* <Box sx={{ width: '8%' }}>
                                                                                        <ArrowForwardIosIcon
                                                                                            onClick={() => handleSetViewSchemeDetails(true, ele)}
                                                                                            sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5, cursor: 'pointer' }} />
                                                                                    </Box> */}


                                                                                </Box>
                                                                                {/* {moreDetails[index] ? */}
                                                                                <Collapse in={moreDetails[index]} timeout={300}>
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'row', my: 3, justifyContent: 'space-between' }}>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.I_Accno}</Typography>
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Gain Amount</Typography>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>Rs. {ele.UnrealReturns} ({ele.AUM_PERCENTAGE} %) </Typography>
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Holding Name</Typography>
                                                                                            {accountType?.account_type.filter((i: any) => i.AccountId === ele.AccountID)
                                                                                                .map((item: any) => (
                                                                                                    <Typography key={item.AccountId} sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>
                                                                                                        {item.AccountType}
                                                                                                    </Typography>
                                                                                                ))}
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Current NAV</Typography>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>Rs. {ele.NAVAson}</Typography>
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>NAV Difference(in %) </Typography>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.NAVDiff}</Typography>
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Collapse>
                                                                                {/* : ''} */}

                                                                                <Divider />
                                                                            </div>
                                                                        )
                                                                    }

                                                                    )}</>}
                                                        </TabPanel>

                                                    )
                                                })}
                                            </Box>

                                        </TabContext>}
                                </Box>
                            }

                        </Box>)
                    }

                </>

            </CommonLayout>

        </div>
    );
}

import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Divider,
    FormControl,
    IconButton,
    InputAdornment,
    LinearProgress,
    Menu,
    MenuItem,
    OutlinedInput,
    Skeleton,
    Tab,
    Tooltip,
    Modal,
    Typography,
    Collapse
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { GetAccountType, GetAssetClassesType, GetCategoryAverage, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, GetSchemeType, RecommendationsType, SchemeList } from '../../../redux-store/types/api-types';
import { delete_recommended_schemes, edit_recommended_schemes, getRmDetails, getSchemeName, get_account_type, get_assest_classes, get_category_average, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav } from '../../../redux-store/actions/userManagement';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { RecommendedSchemesModal, SubFundCategoryCard } from "./investor-transaction-helper";
import { EditSchemesModal } from './editRecommendation_Modal';
import { Bubble } from './investor-transaction-helper';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { CloseIcon, DeleteIcon, DisDeleteIcon, DisEditIcon, EditIcon } from "../../customSVGs";
import SchemeDetail from './scheme-detail';
import SearchIcon from '@mui/icons-material/Search';
import { formatDate_DDMMYYYY } from '../../../utils/date';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import { Icon } from '@material-ui/core';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Swal from 'sweetalert2';
import Filters, { filterDataRecommended } from './filterComponent';
import Search from '@mui/icons-material/Search';

interface Styles {
    backgroundColor?: string;
    color?: string;
    borderRadius?: string;
    height?: string
}
export default function RecommendedSchemes({
    location
}: {
    location: Location<{ investorId: string; investorName: string }>
}): JSX.Element {

    const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState(currentPathname === '/investor-transactions' ? 'Explore' : 'Recommendations');
    const [recommendationData, setRecommendationData] = useState<GetRecommendedSchemes | null>(null);
    const [isLoadingAsset, setLoadingAsset] = useState(false);
    const [isLoadingRecommendation, setLoadingRecommendation] = useState<boolean>(false);
    const [isLoadingschemeDetail, setLoadingSchemeDetail] = useState<boolean>(false);
    const [isLoadingschemeList, setLoadingSchemeList] = useState<boolean>(false);
    const [isLoadingCatAvg, setLoadingCatAvg] = useState<boolean>(false);
    const [isLoadingSchemeNav, setLoadingSchemeNav] = useState<boolean>(false);
    const [currentSchemeDetail, setCurrentSchemeDetail] = useState<RecommendationsType>();
    const [value, setValue] = useState<string>("New");
    const [checked, setChecked] = useState(false);
    const [viewSchemeDetails, setViewSchemeDetails] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [moreDetails, setMoreDetails] = useState<{ [key: number]: boolean }>({});
    const [accountType, setAccountType] = useState<GetAccountType | null>();
    const [rmId, setRmId] = useState<number | null>();
    const [recommendedSchemes, setRecommendedSchemes] = useState([]);
    const [trigger, setTrigger] = useState<any>(false)
    const [openModal, setOpenModal] = useState(false);
    const [Recommend, setRecommended] = useState<boolean>(false);
    const [groupedSchemes, setGroupedSchemes] = useState([])
    const [modalMoreDetails, setModalMoreDetails] = useState<{ [key: number]: boolean }>({});
    const [schemeModalOpen, setSchemeModalOpen] = useState<boolean>(false);
    const [recommendedBucketSchemes, setRecommendedBucketSchemes] = useState<any>({})
    const [AMCList, setAMCList] = useState<{ AMC: string, AMCId: number }[]>([]);
    const [filterData, setFilterData] = useState<filterDataRecommended>({
        AMCList: [],
        schemeType: [],
        status: [],
        accountName: [],
        date: { FromDate: "", ToDate: "" },
    });
    const [filteredSchemeList, setFilteredSchemeList] = useState<RecommendationsType[] | undefined>(recommendationData?.recommendedSchemes[0]);
    const [selectedSchemes, setSelectedSchemes] = useState<any>([]);
    const handleModalOpen = (value: any) => {
        setOpenModal(true);
        setSelectedSchemes([...selectedSchemes, value])
    }
    const handleModalClose = () => {
        setSelectedSchemes([])
        setOpenModal(false);
    }

    const handleOpenSchemeModal = (schemeDetails: []) => {
        setGroupedSchemes(schemeDetails)
        if (schemeDetails.length > 1)
            setSchemeModalOpen(true)
    };
    const handleCloseSchemeModal = () => setSchemeModalOpen(false);
    //function to handle see more details
    const handleDetailsClick = (index: number) => {
        setMoreDetails((lessDetails) => {
            const updatedDetails = { ...lessDetails, [index]: !lessDetails[index] };
            return updatedDetails;
        });
    };

    const handleMoreDetailsClickModal = (index: number) => {
        setModalMoreDetails((lessDetails) => {
            const updatedDetails = { ...lessDetails, [index]: !lessDetails[index] };
            return updatedDetails;
        });
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 850,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: "10px",
        overflowX: "hidden",
        "&:focus": {
            outline: "none"
        }
    };
    // const handleHover = (index: number) => {
    //     setMoreDetails((lessDetails) => {
    //         const updatedDetails = { ...lessDetails, [index]: true };
    //         return updatedDetails;
    //     });
    // };

    // const handleMouseOut = (index: number) => {
    //     setMoreDetails((lessDetails) => {
    //         const updatedDetails = { ...lessDetails, [index]: false };
    //         return updatedDetails;
    //     });
    // };

    //function to handle change of menu - Explore/recommendation
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendations') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };

    //api call for get_recommended_schemes
    useEffect(() => {

        let isComponentActive = true;
        setRecommendationData(null);
        (async function () {
            try {
                setLoadingRecommendation(true);
                const response = (await dispatch(
                    get_recommended_schemes({

                        ClientCode: investorId
                    })
                )) as unknown as GetRecommendedSchemes;
                if (!isComponentActive) {
                    return;
                }

                setRecommendationData(response);
                setFilteredSchemeList(response?.recommendedSchemes[0])

                setLoadingRecommendation(false);
            } catch (e) {
                setLoadingRecommendation(false);
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };

    }, [dispatch, trigger]);

    //api call for getting rmid
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);

            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])
    //api call for account type and name matching
    useEffect(() => {

        let isComponentActive = true;
        setAccountType(null);
        (async function () {
            try {
                const response = (await dispatch(
                    get_account_type({
                        rmID: rmId,
                        ClientCode: investorId
                    })
                )) as unknown as GetAccountType;
                if (!isComponentActive) {
                    return;
                }
                setAccountType(response);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])

    //Getting Unique AMC names for filter
    useEffect(() => {
        const uniqueAMCIds = new Set<number>();
        const AmcList: { AMC: string, AMCId: number }[] = [];
        recommendationData?.recommendedSchemes[0]?.forEach((value: RecommendationsType) => {
            if (!uniqueAMCIds.has(value.AmcID)) {
                AmcList.push({ AMC: value.AMC, AMCId: value.AmcID });
                uniqueAMCIds.add(value.AmcID);
            }
        });
        setAMCList(AmcList.sort((a: any, b: any) => a.AMC.toLowerCase().localeCompare(b.AMC.toLowerCase())));
    }, [recommendationData])

    //Set Filtered Data
    useEffect(() => {
        if (filterData.AMCList.length == 0 && filterData.schemeType.length == 0 && filterData.status.length == 0 && filterData.accountName.length == 0 && search == '' && filterData.date.FromDate == "" && filterData.date.ToDate == "") {
            setFilteredSchemeList(recommendationData?.recommendedSchemes[0]);
        }
        else {
            // AMC Filter Logic
            let filteredList: RecommendationsType[] | undefined = recommendationData?.recommendedSchemes[0];
            if (filterData.AMCList.length !== 0) {
                filteredList = recommendationData?.recommendedSchemes[0]?.filter((scheme: RecommendationsType) =>
                    filterData.AMCList.some((selectedAMC) => selectedAMC.AMCId === scheme.AmcID)
                )
                setFilteredSchemeList(filteredList);
            }

            // Search AMC Name Filter Logic
            let filteredList2: RecommendationsType[] | undefined = filteredList;
            if (search !== "") {
                filteredList2 = filteredList?.filter((item: any) =>
                    Object.values(item).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))
                );
                setFilteredSchemeList(filteredList2);
            }

            // Scheme Type Filter Logic
            let filteredList3: RecommendationsType[] | undefined = filteredList2;
            if (filterData.schemeType.length !== 0) {
                filteredList3 = filteredList2?.filter((scheme: RecommendationsType) => {
                    const isGrowth = filterData.schemeType.includes('Growth');
                    const isDiv = filterData.schemeType.includes('Dividend');

                    if (isGrowth && isDiv) {
                        return true;
                    } else if (isGrowth) {
                        return scheme.Growthoption === 1;
                    } else if (isDiv) {
                        return scheme.Dividendreinvestment === 1 || scheme.DividendPayout === 1;
                    } else {
                        return true;
                    }
                });

                setFilteredSchemeList(filteredList3);
            }

            // Status Filter Logic
            let filteredList4: RecommendationsType[] | undefined = filteredList3;
            if (filterData.status.length !== 0) {
                filteredList4 = filteredList3?.filter((scheme: RecommendationsType) => {
                    return filterData.status.some((selectedStatus) => selectedStatus.value === scheme.Expirystatus);
                });

                setFilteredSchemeList(filteredList4);
            }

            // AccountName Filter Logic
            let filteredList5: RecommendationsType[] | undefined = filteredList4;
            if (filterData.accountName.length !== 0) {
                filteredList5 = filteredList4?.filter((scheme: RecommendationsType) => {
                    return filterData.accountName.some((selectedAccount) => selectedAccount.AccountId === scheme.accountID);
                });

                setFilteredSchemeList(filteredList5);
            }

            let filteredList6: RecommendationsType[] | undefined = filteredList5;
            if (filterData.date.FromDate !== "" && filterData.date.ToDate !== "") {
                filteredList6 = filteredList5?.filter((scheme: RecommendationsType) => {
                    return (
                        scheme.EntryDate !== undefined && new Date(scheme.EntryDate) >= new Date(filterData.date.FromDate) && new Date(scheme.EntryDate) <= new Date(new Date(filterData.date.ToDate).getTime() + 60 * 60 * 24 * 1000))
                });
                setFilteredSchemeList(filteredList6);
            }

        }
    }, [filterData, search])

    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Recommendations
        </Typography>,
    ];

    //function to handle change of asset class tabs
    const handleChange = (event: any, value: any) => {
        setValue(value);
    };

    const open = Boolean(anchorEl);
    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setRecommended(false);
        setOpenModal(false);
        setSelectedSchemes([])
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }
    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };

    const handleCheckboxChange = () => {
        setChecked(!checked);
    };

    //function to enable showing scheme detail screen
    const handleSetViewSchemeDetails = async (val: boolean, schemeDetail: SchemeList | RecommendationsType | undefined | null) => {
        setCurrentSchemeDetail(schemeDetail as RecommendationsType);
        // const response = await getSchemeDetails(schemeDetail as RecommendationsType);
        // const requiredScheme = response?.sub_asset_classes.filter((item: SchemeList) => item.SchemeID == schemeDetail?.SchemeID);
        // requiredScheme ? setSelectedScheme(requiredScheme[0]) : null;
        setViewSchemeDetails(val); trigger
    };

    const handleDeleteSchemes = async (value: RecommendationsType) => {
        Swal.fire({
            html: '<b>Are you sure you want to delete this Recommendation ?</b>',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            customClass: {
                htmlContainer: 'custom-html-container-class',
            }
        }).then((result: any) => {
            if (result.isConfirmed) {
                const obj = {
                    rmID: value.rmID,
                    ClientCode: value.ClientCode,
                    RecID: value.RecID
                }

                const response = (dispatch(
                    delete_recommended_schemes(obj)
                )) as unknown as any;
                response.then((response: any) => {
                    setTrigger(!trigger)
                    Swal.fire({
                        html: `<b>${response.del_recommendation[0][0].Response}</b>`,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK",
                        customClass: {
                            htmlContainer: 'custom-html-container-class',
                        }
                    })
                })
            }

        })
    }

    const handleDeleteMultipleSchemes = async (value: RecommendationsType[]) => {
        Swal.fire({
            html: '<b>Are you sure you want to delete this Recommendation ?</b>',
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
            customClass: {
                htmlContainer: 'custom-html-container-class',
            }
        }).then((result: any) => {
            let flag = true
            if (result.isConfirmed) {
                value.map((ele: RecommendationsType) => {

                    const obj = {
                        rmID: ele.rmID,
                        ClientCode: ele.ClientCode,
                        RecID: ele.RecID
                    }

                    const response = (dispatch(
                        delete_recommended_schemes(obj)
                    )) as unknown as any;

                    response.then((response: any) => {
                        if (response.del_recommendation[0][0].Status != "True") {
                            flag = false
                        }
                    })
                })
                if (flag == true) {
                    setTrigger(!trigger)
                    Swal.fire({
                        html: `<b>Deleted Successfully</b>`,
                        icon: "success",
                        showCancelButton: false,
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: "OK",
                        customClass: {
                            htmlContainer: 'custom-html-container-class',
                        }
                    })
                }
            }

        })
    }

    // const handleEditSchemes = async (value: RecommendationsType) => {
    //     console.log("Edit Button Got click")

    //     const obj = {
    //         RecID: 10097,
    //         SchemeID: 10044,
    //         InvestmentAmount: 6540,
    //         SchemeType: 'Rec',
    //         rmID: 5,
    //         ClientCode: "O000906",
    //         No_of_Installments: 6,
    //         SIP_date: "2024-01-29",
    //         Frequency: "Monthly",
    //         AccountID: 'W000906',
    //         ToSchemeID: null,
    //         Folio: "10080986",
    //         Units: 0,
    //         PFFlag: null

    //     }
    //     const recommendationData = {
    //         recommendation_data: [obj]
    //     }

    //     const response = (dispatch(
    //         edit_recommended_schemes(recommendationData)
    //     )) as unknown as any;

    //     console.log("response from Edit :>>", response)
    //     response.then((response: any) => {
    //         setTrigger(!trigger)
    //         console.log("value check", response)

    //     })
    // }


    const handleCloseSchemeDetails = () => {
        setViewSchemeDetails(false);
    };

    const [schemeDetail, setSchemeDetail] = useState<GetSchemeDetailsType | null>(null);
    //api call for get_scheme_details
    useEffect(() => {
        if (currentSchemeDetail) {
            const scheme_id = currentSchemeDetail.SchemeID;
            let isComponentActive = true;
            setSchemeDetail(null);
            (async function () {
                try {
                    setLoadingSchemeDetail(true);
                    const response = (await dispatch(
                        get_scheme_details(scheme_id)
                    )) as unknown as GetSchemeDetailsType;
                    if (!isComponentActive) {
                        return;
                    }
                    setSchemeDetail(response);
                    setLoadingSchemeDetail(false);
                } catch (e) {
                    setLoadingSchemeDetail(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);

    const [categoryAverage, setCategoryAverage] = useState<GetCategoryAverage | null>(null);
    //api call for get_category_avg
    useEffect(() => {
        if (currentSchemeDetail) {
            const payload = {
                marketValue: false,
                schemaId: currentSchemeDetail.SchemeID
            }
            let isComponentActive = true;
            setCategoryAverage(null);
            (async function () {
                try {
                    setLoadingCatAvg(true);
                    const response = (await dispatch(
                        get_category_average(payload)
                    )) as unknown as GetCategoryAverage;
                    if (!isComponentActive) {
                        return;
                    }
                    setCategoryAverage(response);
                    setLoadingCatAvg(false);
                } catch (e) {
                    setLoadingCatAvg(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);

    const [schemeNav, setSchemeNav] = useState<GetSchemeNav | null>(null);
    //api call for get_scheme_nav
    useEffect(() => {
        if (currentSchemeDetail) {
            const schemeId = currentSchemeDetail.SchemeID;
            let isComponentActive = true;
            setSchemeNav(null);
            (async function () {
                try {
                    setLoadingSchemeNav(true);
                    const response = (await dispatch(
                        get_scheme_nav(schemeId)
                    )) as unknown as GetSchemeNav;
                    if (!isComponentActive) {
                        return;
                    }
                    setSchemeNav(response);
                    setLoadingSchemeNav(false);
                } catch (e) {
                    setLoadingSchemeNav(false);
                    console.log((e as Error).message);
                }
            })();

            return () => {
                isComponentActive = false;
            };
        }

    }, [dispatch, currentSchemeDetail]);


    useEffect(() => {
        setRecommendedBucketSchemes(groupOrdersByBucket(filteredSchemeList?.filter((ele: any) => (ele?.SchemeType == 'ISIP' || ele?.SchemeType == "New" || ele?.SchemeType == 'XSIP' || ele?.SchemeType == 'Add'))))
    }, [filteredSchemeList])

    const groupOrdersByBucket = (data: RecommendationsType[] | undefined) => {
        const groupedRecommendedSchemes: Record<number, RecommendationsType[]> = {};

        if (data) {
            data.forEach((ele) => {
                const BucketID = ele?.BucketID
                groupedRecommendedSchemes[BucketID] = groupedRecommendedSchemes[BucketID] || [];
                if (ele?.BucketID != null) {
                    groupedRecommendedSchemes[BucketID].push(ele);
                }
                else {
                    groupedRecommendedSchemes[0]?.push(ele);
                }
            });
        }
        return groupedRecommendedSchemes;
    }

    // const filteredRecommendations = recommendationData?.recommendedSchemes[0]?.filter((item) =>
    //     Object.values(item).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))
    // );
    const tabMap = [
        {
            label: "New Purchase",
            value: "New",
            trType: "New",
            trType2: "ISIP"
        },
        {
            label: "Additional Purchase",
            value: "Add",
            trType: "Add",
            trType2: "XSIP",
        },
        {
            label: "STP",
            value: "STP",
            trType: "STP",

        },
        {
            label: "SWP",
            value: "SWP",
            trType: "SWP",

        },
        {
            label: "Switch",
            value: "Swt",
            trType: "Swt",

        },
        {
            label: "Redemption",
            value: "Red",
            trType: "Red",

        }
    ]

    const [subVal, setSubVal] = useState<string>('lumpsum');
    const [selectedValuesSIP, setselectedValuesSIP] = useState<any>([]);
    const [selectedValuesLump, setselectedValuesLump] = useState<any>([]);
    const handleSubValChange = (event: any, val: any) => {
        setselectedValuesSIP([]);
        setselectedValuesLump([]);
        setSubVal(val);
    }
    const getStyles = (ele: any): Styles => {
        const styles: Styles = {};
        const daysLeft = 7 - ele.NoOfDays;
        if (daysLeft >= 5) {
            styles.backgroundColor = '#52AC8C';
        } else if (daysLeft >= 3) {
            styles.backgroundColor = '#EA8B24';
        } else {
            styles.backgroundColor = '#EB4646';
        }
        styles.color = 'white';
        styles.borderRadius = '15px';
        styles.height = '20px'
        return styles;
    };
    const getStatusStyles = (ele: any): Styles => {
        // const Element = ele.ToschemeName;
        const styles: Styles = {};
        if (ele.Expirystatus === "Active") {
            styles.color = '#52AC8C'
        } else if (ele.Expirystatus === "Expired") {
            styles.color = 'grey'
        } else if (ele.Expirystatus === "In Cart") {
            styles.color = '#EA8B24'
        } else if (ele.Expirystatus === "Order confirmed_Success") {
            styles.color = '#337FC9'
        } else if (ele.Expirystatus === "Order confirmed_Failed") {
            styles.color = '#EB4646'
        } else { styles.color = '#337FC9' }
        return styles;
    };
    function OnSchemeDeSelection(schemeId: number): void {
        throw new Error('Function not implemented.');
    }

    return (
        <div>

            <CommonLayout>
                <>
                    {location.pathname === "/investor-transactions" || location.pathname === "/recommended-schemes" || location.pathname === "/holdings" || location.pathname === "/orders" ?
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2 }}>
                            <Button
                                sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px' }}
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick_}
                            >
                                {selectedValue}
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Recommendations')}>Recommendations</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                                <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                            </Menu>
                        </Box> : <></>}

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    {(isLoadingAsset || isLoadingschemeDetail || isLoadingCatAvg || isLoadingSchemeNav || isLoadingRecommendation || isLoadingschemeList) ? (
                        <LinearProgress sx={{ mt: 2.5 }} />
                    ) :
                        (<Box
                            sx={{
                                bgcolor: 'common.white',
                                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                                borderRadius: '5px',
                                py: 2,
                            }}>

                            {/* Displays recommendation list / scheme detail */}
                            {viewSchemeDetails ?
                                //scheme detail
                                <div>
                                    <SchemeDetail
                                        handleBackClick={() => setViewSchemeDetails(false)}
                                        handleSetViewSchemeDetails={handleSetViewSchemeDetails}
                                        investorId={investorId}
                                        schemeDetail={schemeDetail?.scheme_details}
                                        schemeData={currentSchemeDetail as unknown as SchemeList}
                                        categoryAverage={categoryAverage?.category_averages}
                                        schemeNav={schemeNav?.scheme_nav}
                                    />
                                </div>
                                :
                                //Recommendation List
                                <Box>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, alignItems: 'flex-end' }}>
                                        <FormControl sx={{ width: 225 }} variant="outlined">
                                            <OutlinedInput
                                                value={search}
                                                onChange={({ target: { value } }) => {
                                                    setSearch(value);
                                                }}
                                                sx={{ '& .MuiOutlinedInput-input': { py: 1, fontSize: 13 } }}
                                                id="search-field"
                                                placeholder="Search by Scheme Name"
                                                startAdornment={
                                                    <InputAdornment position="start">
                                                        <SearchIcon fontSize="small" />
                                                    </InputAdornment>
                                                }
                                            />
                                        </FormControl>
                                        <Filters
                                            AMCList={AMCList}
                                            filterData={filterData}
                                            setFilterData={setFilterData}
                                            accountName={accountType?.account_type}
                                        />
                                    </Box>
                                    {isLoadingAsset ? <Skeleton /> :
                                        <TabContext value={value} >
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: '100%',
                                                '& .MuiTabPanel-root': { px: 0 },
                                                '& .MuiTab-root': {
                                                    color: '#A1A2A2',
                                                    opacity: 0.8,
                                                    fontSize: 17,
                                                    lineHeight: '24px',
                                                    textTransform: 'capitalize',
                                                    px: { xs: 1, md: 2, lg: 3 },
                                                    mx: 2,
                                                    '&.Mui-selected': {
                                                        color: '#4B81B1',
                                                    },
                                                },
                                                '& .MuiTabs-indicator': {
                                                    height: 3,
                                                    background: '#4B81B1',
                                                },
                                            }}>
                                                <TabList onChange={handleChange}>

                                                    {tabMap.map((val: any, index: number) => {
                                                        return (
                                                            <Tab label={val.label} value={val.value} key={index} />
                                                        )
                                                    })}
                                                </TabList>
                                                {/* <Button variant="contained">Edit</Button> */}
                                            </Box>
                                            <Divider />
                                            <Box sx={{ px: 3 }}>
                                                {tabMap.map((val) =>

                                                    <TabPanel value={val.value} key={val.value}>
                                                        {val.value == "New" || val.value == "Add" ?
                                                            //Tab for New and Additional purchase
                                                            <>
                                                                <TabContext value={subVal}>
                                                                    <Box
                                                                        sx={{

                                                                            width: '100%',
                                                                            '& .MuiTabPanel-root': { px: 0 },
                                                                            '& .MuiTab-root': {
                                                                                color: '#A1A2A2',
                                                                                opacity: 0.8,
                                                                                fontSize: 13,

                                                                                textTransform: 'capitalize',
                                                                                px: { xs: 1, md: 2, lg: 3 },
                                                                                my: 0,
                                                                                py: 0,
                                                                                '&.Mui-selected': {
                                                                                    color: '#4B81B1',
                                                                                },
                                                                            },
                                                                            '& .MuiTabs-indicator': {
                                                                                height: 2,
                                                                                background: '#4B81B1',
                                                                            },
                                                                        }}>
                                                                        <TabList
                                                                            onChange={handleSubValChange}
                                                                            aria-label="product tabs"
                                                                            variant="scrollable"
                                                                            scrollButtons="auto"
                                                                        >
                                                                            {[{ label: "SIP", value: "sip" }, { label: "Lumpsum", value: "lumpsum" }].map((val_: any, index: number) => {
                                                                                return (
                                                                                    <Tab label={val_.label} value={val_.value} key={index} />
                                                                                )
                                                                            })}
                                                                        </TabList>
                                                                    </Box>
                                                                    <Divider />
                                                                    <Box>
                                                                        {[{ label: "SIP", value: "sip" }, { label: "Lumpsum", value: "lumpsum" }].map((val_) => {
                                                                            return (
                                                                                <TabPanel value={val_.value} key={val_.value}>

                                                                                    {filteredSchemeList?.filter((i: any) => {
                                                                                        return (i.SchemeType == val.trType || i.SchemeType == val.trType2)
                                                                                    }).length === 0 ?
                                                                                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                                                            <Typography sx={{ color: "lightgrey", fontSize: 18, fontWeight: 500 }}>No schemes Recommended</Typography>
                                                                                        </Box>
                                                                                        :
                                                                                        <>
                                                                                            {Object.values(recommendedBucketSchemes)?.sort((a: any, b: any) => new Date(b[0]?.EntryDate).getTime() - new Date(a[0]?.EntryDate).getTime()).filter((i: any) => {
                                                                                                return (val_.value == 'sip' ? i[0].SchemeType === val.trType2 : i[0].SchemeType == val.trType)
                                                                                            }).map((itemArr: any, index: number) => {
                                                                                                const ele = itemArr[0]  //to get first element from bucket arrays
                                                                                                return <div
                                                                                                    key={index}
                                                                                                >

                                                                                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2.5, cursor: 'pointer' }} onClick={() => { recommendedBucketSchemes[ele?.BucketID]?.length == 1 ? handleDetailsClick(index) : handleOpenSchemeModal(itemArr) }}
                                                                                                    >
                                                                                                        {/* <Box sx={{ width: '10%' }}>
                                                            <Checkbox sx={{ color: '#337FC9', mx: 1.5 }}
                                                                onChange={handleCheckboxChange} checked={checked}
                                                            />
                                                        </Box> */}
                                                                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: "30%" }}>
                                                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                                                                                <Box sx={{ width: '70%' }}><Typography sx={{ fontSize: 12, fontWeight: 500, mb: 0.5 }}>
                                                                                                                    {ele.SchemeName}<span style={{ color: "gray", marginLeft: "0.5rem", fontSize: "0.7rem", width: "4rem" }}>{recommendedBucketSchemes[ele?.BucketID]?.length != 1 ? ` +${recommendedBucketSchemes[ele?.BucketID]?.length - 1} more` : ""}</span>
                                                                                                                </Typography>
                                                                                                                </Box>
                                                                                                                {ele.Expirystatus === "Active" ?
                                                                                                                    <Box sx={getStyles(ele)}>
                                                                                                                        <Typography sx={{ color: '#ffffff', fontSize: '9.5px', fontWeight: '0', p: 0.5 }} >{`${7 - Number(ele?.NoOfDays)}`}{`${7 - Number(ele?.NoOfDays)}` === '1' ? ' day left' : ' days left'}</Typography>
                                                                                                                    </Box> : ''
                                                                                                                }

                                                                                                            </Box>
                                                                                                            <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                                                                                                                {!!ele.AssetClassName && (
                                                                                                                    <Bubble text={ele.AssetClassName} />
                                                                                                                )}
                                                                                                                {!!ele.Sub_AssetclassName && (
                                                                                                                    <Bubble text={ele.Sub_AssetclassName} />
                                                                                                                )}

                                                                                                                {ele.PlanName.length !== 0 && <Bubble text={ele.PlanName} />}
                                                                                                                {ele.DividendPayout === 1 ?
                                                                                                                    <Bubble text={"Payout"} />
                                                                                                                    : ele.Dividendreinvestment === 1 ? <Bubble text={"Reinvestment"} /> : ''
                                                                                                                }
                                                                                                                {ele.PlanShortName === 'DP' && !!ele.Dividendfrequency ? <Bubble text={ele.Dividendfrequency} /> : ''}

                                                                                                            </Box>
                                                                                                        </Box>

                                                                                                        <Box sx={{ width: "10%" }}>
                                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'flex-start' }}>Status</Typography>
                                                                                                            <Typography sx={{ fontSize: 12, fontWeight: 500, color: ele?.Expirystatus == "Active" ? "#52AC8C" : ele?.Expirystatus == "In Cart" ? "#EA8B24" : ele?.Expirystatus == "Expired" ? 'grey' : ele?.Expirystatus == "Order confirmed_Failed" ? "#EB4646" : "#337FC9" }}>
                                                                                                                {ele?.Expirystatus == "Order confirmed_Success" ? "Order Placed" : ele?.Expirystatus == "Order confirmed_Failed" ? "Order Confirmation Failed" : ele?.Expirystatus == "Order confirmed_Payment Successfully Completed" ? "Payment Successful" : ele?.Expirystatus}
                                                                                                            </Typography>
                                                                                                        </Box>
                                                                                                        <Box sx={{ width: "14%" }}>
                                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'flex-start' }}>Amount</Typography>
                                                                                                            <Typography sx={{ fontSize: 12 }}>
                                                                                                                {ele.InvestmentAmount === 0 || !ele.InvestmentAmount
                                                                                                                    ? "NA"
                                                                                                                    : ele.InvestmentAmount.toLocaleString('en-IN', {
                                                                                                                        style: 'currency',
                                                                                                                        currency: 'INR'
                                                                                                                    })}
                                                                                                            </Typography>
                                                                                                        </Box>

                                                                                                        <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Recommendation Date </Typography>
                                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.EntryDate?.split('T')[0])}</Typography>
                                                                                                        </Box>
                                                                                                        <Box style={{ display: 'flex' }}>
                                                                                                            {ele.Expirystatus === 'Active' && recommendedBucketSchemes[ele?.BucketID]?.length == 1 ? (
                                                                                                                <div
                                                                                                                    onClick={() => handleModalOpen(ele)} >
                                                                                                                    <EditIcon />
                                                                                                                </div>
                                                                                                            )
                                                                                                                : <div style={{}}>
                                                                                                                    <DisEditIcon />
                                                                                                                </div>}

                                                                                                            {ele.Expirystatus === 'Active' ? (
                                                                                                                <div onClick={(e) => { recommendedBucketSchemes[ele?.BucketID]?.length == 1 ? handleDeleteSchemes(ele) : handleDeleteMultipleSchemes(itemArr), e.stopPropagation(); }} style={{ marginLeft: "10px" }}>
                                                                                                                    <DeleteIcon />
                                                                                                                </div>
                                                                                                            ) : <div style={{ marginLeft: '10px' }} >
                                                                                                                <DisDeleteIcon />
                                                                                                            </div>}
                                                                                                        </Box>

                                                                                                        <Box sx={{ width: '6%' }}>
                                                                                                            {recommendedBucketSchemes[ele?.BucketID]?.length == 1 ?
                                                                                                                <ArrowForwardIosIcon
                                                                                                                    onClick={() => handleSetViewSchemeDetails(true, ele)}
                                                                                                                    sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5, mt: .5, cursor: 'pointer' }} /> :
                                                                                                                <ArrowForwardIosIcon
                                                                                                                    // onClick={() => handleSetViewSchemeDetails(true, ele)}
                                                                                                                    sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5, mt: .5, cursor: 'pointer', opacity: 0.2 }} />}
                                                                                                        </Box>

                                                                                                    </Box>

                                                                                                    {/* {moreDetails[index] ?
                                                                                                        <> */}
                                                                                                    <Collapse in={moreDetails[index]} timeout={300}>
                                                                                                        {ele.SchemeType === "ISIP" || ele.SchemeType === "XSIP" ?

                                                                                                            <Box sx={{ display: 'flex', flexDirection: 'row', my: 3, justifyContent: 'space-between' }}>
                                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                                                                                    <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                                                                                        {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Frequency </Typography>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600', alignContent: 'flex-start' }}> {ele.Frequency}</Typography>
                                                                                                                </Box>
                                                                                                                {ele.Frequency === 'DAILY' ? '' : <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>No. Of Instalments </Typography>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.No_of_Installments}</Typography>
                                                                                                                </Box>}

                                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>SIP Date </Typography>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.SIP_date?.split('T')[0])}</Typography>
                                                                                                                </Box>
                                                                                                                {ele.Folio === '' ?
                                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Account Name </Typography>
                                                                                                                        {accountType?.account_type.filter((i: any) => i.AccountId === ele.accountID)
                                                                                                                            .map((item: any) => (
                                                                                                                                <Typography key={item.AccountId} sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>
                                                                                                                                    {item.AccountType}
                                                                                                                                </Typography>
                                                                                                                            ))}
                                                                                                                    </Box> :
                                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                                                                                    </Box>
                                                                                                                }

                                                                                                            </Box>
                                                                                                            : <Box sx={{ display: 'flex', flexDirection: 'row', my: 3 }}>
                                                                                                                <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                                                                                    <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                                                                                        {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                                                                                    </Typography>
                                                                                                                </Box>
                                                                                                                {ele.Folio === '' ?
                                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Account Name </Typography>
                                                                                                                        {accountType?.account_type.filter((i: any) => i.AccountId === ele.accountID)
                                                                                                                            .map((item: any) => (
                                                                                                                                <Typography key={item.AccountId} sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>
                                                                                                                                    {item.AccountType}
                                                                                                                                </Typography>
                                                                                                                            ))}
                                                                                                                    </Box> :
                                                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                                                                                    </Box>
                                                                                                                }
                                                                                                            </Box>


                                                                                                        }
                                                                                                    </Collapse>
                                                                                                    {/* </>
                                                                                                        : ''} */}
                                                                                                    <Divider />
                                                                                                    {/* <Box sx={{ backgroundColor: '#C7E1FF', height: '12px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '10px' }} >
                                                                                                        <IconButton onClick={() => handleDetailsClick(index)}>
                                                                                                            {
                                                                                                                moreDetails[index] ? <KeyboardDoubleArrowUpIcon sx={{ fontSize: '15px', color: '#337FC9' }} /> : <KeyboardDoubleArrowDownIcon sx={{ fontSize: '15px', color: '#337FC9' }} />
                                                                                                            }
                                                                                                        </IconButton>
                                                                                                    </Box> */}
                                                                                                </div>
                                                                                            })}

                                                                                        </>
                                                                                    }
                                                                                </TabPanel>
                                                                            )
                                                                        })}
                                                                    </Box>
                                                                </TabContext>
                                                            </> :
                                                            //Tab for STP, SWP, Switch and redemption
                                                            <Box>
                                                                {filteredSchemeList?.filter((i: any) => {
                                                                    return (i.SchemeType === val.trType || i.SchemeType == val.trType2)
                                                                }).length === 0 ?
                                                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                                                        <Typography sx={{ color: "lightgrey", fontSize: 18, fontWeight: 500 }}>No schemes Recommended</Typography>
                                                                    </Box>
                                                                    :
                                                                    <>
                                                                        {filteredSchemeList?.sort((a: any, b: any) => new Date(b.EntryDate).getTime() - new Date(a.EntryDate).getTime()).filter((i: any) => {
                                                                            return (i.SchemeType === val.trType)
                                                                        }).map((ele: RecommendationsType, index: number) => {
                                                                            return <div
                                                                                key={index}

                                                                            >

                                                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2.5, cursor: 'pointer' }} onClick={() => handleDetailsClick(index)}>
                                                                                    {/* <Box sx={{ width: '10%' }}>
                                                    <Checkbox sx={{ color: '#337FC9', mx: 1.5 }}
                                                        onChange={handleCheckboxChange} checked={checked}
                                                    />
                                                </Box> */}
                                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: "30%" }}>
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                                                                            <Box sx={{ width: '70%' }}><Typography sx={{ fontSize: 12, fontWeight: 500, mb: 0.5 }}>
                                                                                                {ele.SchemeName}
                                                                                            </Typography>
                                                                                            </Box>
                                                                                            {ele.Expirystatus === "Active" ?
                                                                                                <Box sx={getStyles(ele)}>
                                                                                                    <Typography sx={{ color: '#ffffff', fontSize: '9.5px', fontWeight: '0', p: 0.5 }} >{`${7 - Number(ele?.NoOfDays)}`}{`${7 - Number(ele?.NoOfDays)}` === '1' ? ' day left' : ' days left'}</Typography>
                                                                                                </Box> : ''
                                                                                            }
                                                                                        </Box>
                                                                                        <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                                                                                            {!!ele.AssetClassName && (
                                                                                                <Bubble text={ele.AssetClassName} />
                                                                                            )}
                                                                                            {!!ele.Sub_AssetclassName && (
                                                                                                <Bubble text={ele.Sub_AssetclassName} />
                                                                                            )}

                                                                                            {ele.PlanName.length !== 0 && <Bubble text={ele.PlanName} />}
                                                                                            {ele.DividendPayout === 1 ?
                                                                                                <Bubble text={"Payout"} />
                                                                                                : ele.Dividendreinvestment === 1 ? <Bubble text={"Reinvestment"} /> : ''
                                                                                            }
                                                                                            {ele.PlanShortName === 'DP' && !!ele.Dividendfrequency ? <Bubble text={ele.Dividendfrequency} /> : ''}

                                                                                        </Box>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "12%" }}>
                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'center' }}>Status</Typography>
                                                                                        <Typography sx={{ fontSize: 12, fontWeight: 500, color: ele?.Expirystatus == "Active" ? "#52AC8C" : ele?.Expirystatus == "In Cart" ? "#EA8B24" : ele?.Expirystatus == "Expired" ? 'grey' : ele?.Expirystatus == "Order confirmed_Failed" ? "#EB4646" : "#337FC9" }}>
                                                                                            {ele?.Expirystatus == "Order confirmed_Success" ? "Order Placed" : ele?.Expirystatus == "Order confirmed_Failed" ? "Order Confirmation Failed" : ele?.Expirystatus == "Order confirmed_Payment Successfully Completed" ? "Payment Successful" : ele?.Expirystatus}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    <Box sx={{ width: "14%" }}>
                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'flex-start' }}> Amount</Typography>
                                                                                        <Typography sx={{ fontSize: 12 }}>
                                                                                            {ele.InvestmentAmount === 0 || !ele.InvestmentAmount
                                                                                                ? "NA"
                                                                                                : ele.InvestmentAmount.toLocaleString('en-IN', {
                                                                                                    style: 'currency',
                                                                                                    currency: 'INR'
                                                                                                })}
                                                                                        </Typography>
                                                                                    </Box>
                                                                                    {ele.SchemeType == "Swt" || ele.SchemeType == "STP" ?
                                                                                        <Box sx={{ width: "24%" }}>
                                                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>To Scheme</Typography>
                                                                                            <Typography sx={{ fontSize: 12 }}>
                                                                                                {ele.ToschemeName}
                                                                                            </Typography>
                                                                                        </Box>
                                                                                        : ""}


                                                                                    <Box sx={{ width: "18%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Recommendation Date </Typography>
                                                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.EntryDate?.split('T')[0])}</Typography>
                                                                                    </Box>

                                                                                    <Box style={{ display: "flex" }}>
                                                                                        {ele.Expirystatus === 'Active' ? (
                                                                                            <div onClick={() => handleModalOpen(ele)}>
                                                                                                <EditIcon />
                                                                                            </div>
                                                                                        ) : <div style={{}} >
                                                                                            <DisEditIcon />
                                                                                        </div>}

                                                                                        {ele.Expirystatus === 'Active' ? (
                                                                                            <div onClick={() => handleDeleteSchemes(ele)} style={{ marginLeft: "10px" }}>
                                                                                                <DeleteIcon />
                                                                                            </div>
                                                                                        ) : <div style={{ marginLeft: '10px' }}>
                                                                                            <DisDeleteIcon />
                                                                                        </div>}
                                                                                    </Box>

                                                                                    <Box sx={{ width: '6%' }}>
                                                                                        <ArrowForwardIosIcon
                                                                                            onClick={() => handleSetViewSchemeDetails(true, ele)}
                                                                                            sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5, mt: 0.5, cursor: 'pointer' }} />
                                                                                    </Box>

                                                                                </Box>
                                                                                {/* 
                                                                                {moreDetails[index] ?
                                                                                    <> */}
                                                                                <Collapse in={moreDetails[index]} timeout={300}>
                                                                                    {ele.SchemeType === "Red" || ele.SchemeType === 'Swt' ?
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', my: 3 }}>
                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                                                                    {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '33%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>{ele.SchemeType === "Red" ? 'Redemption Type' : 'Switch Type'} </Typography>
                                                                                                {ele.PFFlag === "F" ?
                                                                                                    <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600', }}>Full</Typography>
                                                                                                    : <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600', }}>Partial</Typography>}
                                                                                            </Box>

                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                                                            </Box>
                                                                                        </Box> :
                                                                                        <Box sx={{ display: 'flex', flexDirection: 'row', mb: 3, justifyContent: 'space-between' }}>
                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                                                                    {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                                                                </Typography>
                                                                                            </Box>
                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Frequency </Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600', alignContent: 'flex-start' }}> {ele.Frequency}</Typography>
                                                                                            </Box>
                                                                                            {ele.Frequency === 'DAILY' ? '' : <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>No. Of Instalments </Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.No_of_Installments}</Typography>
                                                                                            </Box>}

                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>{value} Date </Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.SIP_date?.split('T')[0])}</Typography>
                                                                                            </Box>
                                                                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                                                            </Box>
                                                                                        </Box>



                                                                                    }
                                                                                </Collapse>
                                                                                {/* </>
                                                                                    : ''} */}
                                                                                <Divider />
                                                                                {/* <Box sx={{ backgroundColor: '#C7E1FF', height: '12px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '10px' }} >
                                                                                    <IconButton onClick={() => handleDetailsClick(index)}>
                                                                                        {
                                                                                            moreDetails[index] ? <KeyboardDoubleArrowUpIcon sx={{ fontSize: '15px', color: '#337FC9' }} /> : <KeyboardDoubleArrowDownIcon sx={{ fontSize: '15px', color: '#337FC9' }} />
                                                                                        }
                                                                                    </IconButton>
                                                                                </Box> */}

                                                                            </div>
                                                                        })}
                                                                    </>
                                                                }

                                                            </Box>}

                                                    </TabPanel>

                                                )}

                                            </Box>

                                        </TabContext>}
                                </Box>
                            }

                        </Box>)
                    }
                    <Modal
                        open={schemeModalOpen}
                        onClose={handleCloseSchemeModal}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >

                        <Box sx={style}>
                            <Typography sx={{ fontWeight: 500, ml: 1, mb: 1, }}>Recommendation Details</Typography>
                            <Divider />
                            <div style={{ height: "20rem" }}>
                                {groupedSchemes.map((ele: any, index: number) => {

                                    return <div
                                        key={index}
                                    >

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2.5, cursor: 'pointer' }} onClick={() => { handleMoreDetailsClickModal(index) }}
                                        >
                                            <Box sx={{ display: 'flex', flexDirection: 'column', width: "45%" }}>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                                    <Box sx={{ width: '70%' }}><Typography sx={{ fontSize: 12, fontWeight: 500, mb: 0.5 }}>
                                                        {ele.SchemeName}
                                                    </Typography>
                                                    </Box>
                                                    {ele.Expirystatus === "Active" ?
                                                        <Box sx={getStyles(ele)}>
                                                            <Typography sx={{ color: '#ffffff', fontSize: '9.5px', fontWeight: '0', p: 0.5 }} >{`${7 - Number(ele?.NoOfDays)}`}{`${7 - Number(ele?.NoOfDays)}` === '1' ? ' day left' : ' days left'}</Typography>
                                                        </Box> : ''
                                                    }

                                                </Box>
                                                <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                                                    {!!ele.AssetClassName && (
                                                        <Bubble text={ele.AssetClassName} />
                                                    )}
                                                    {!!ele.Sub_AssetclassName && (
                                                        <Bubble text={ele.Sub_AssetclassName} />
                                                    )}

                                                    {ele.PlanName.length !== 0 && <Bubble text={ele.PlanName} />}
                                                    {ele.DividendPayout === 1 ?
                                                        <Bubble text={"Payout"} />
                                                        : ele.Dividendreinvestment === 1 ? <Bubble text={"Reinvestment"} /> : ''
                                                    }
                                                    {ele.PlanShortName === 'DP' && !!ele.Dividendfrequency ? <Bubble text={ele.Dividendfrequency} /> : ''}

                                                </Box>
                                            </Box>

                                            <Box sx={{ width: "10%" }}>
                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'flex-start' }}>Status</Typography>
                                                <Typography sx={{ fontSize: 12, fontWeight: 500, color: ele?.Expirystatus == "Active" ? "#52AC8C" : ele?.Expirystatus == "In Cart" ? "#EA8B24" : ele?.Expirystatus == "Expired" ? 'grey' : ele?.Expirystatus == "Order confirmed_Failed" ? "#EB4646" : "#337FC9" }}>
                                                    {ele?.Expirystatus == "Order confirmed_Success" ? "Order Placed" : ele?.Expirystatus == "Order confirmed_Failed" ? "Order Confirmation Failed" : ele?.Expirystatus == "Order confirmed_Payment Successfully Completed" ? "Payment Successful" : ele?.Expirystatus}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: "14%" }}>
                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', display: 'flex', alignItems: 'flex-start' }}>Amount</Typography>
                                                <Typography sx={{ fontSize: 12 }}>
                                                    {ele.InvestmentAmount === 0 || !ele.InvestmentAmount
                                                        ? "NA"
                                                        : ele.InvestmentAmount.toLocaleString('en-IN', {
                                                            style: 'currency',
                                                            currency: 'INR'
                                                        })}
                                                </Typography>
                                            </Box>

                                            <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Recommendation Date </Typography>
                                                <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.EntryDate?.split('T')[0])}</Typography>
                                            </Box>
                                            {/* <Box style={{ display: 'flex' }}>
                                {ele.Expirystatus === 'Active' ? (
                                    <div
                                        onClick={() => handleModalOpen(ele)} >
                                        <EditIcon />
                                    </div>
                                )
                                    : <div style={{}}>
                                        <DisEditIcon />
                                    </div>}

                                {ele.Expirystatus === 'Active' ? (
                                    <div onClick={() => handleDeleteSchemes(ele)} style={{ marginLeft: "10px" }}>
                                        <DeleteIcon />
                                    </div>
                                ) : <div style={{ marginLeft: '10px' }} >
                                    <DisDeleteIcon />
                                </div>}
                            </Box>

                            <Box sx={{ width: '6%' }}>
                                <ArrowForwardIosIcon
                                    onClick={() => {handleCloseSchemeModal(),handleSetViewSchemeDetails(true, ele)}}
                                    sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5, mt: .5, cursor: 'pointer' }} />
                            </Box> */}

                                        </Box>

                                        {/* {moreDetails[index] ?
                                                                                                        <> */}
                                        <Collapse in={modalMoreDetails[index]} timeout={300}>
                                            {ele.SchemeType === "ISIP" || ele.SchemeType === "XSIP" ?

                                                <Box sx={{ display: 'flex', flexDirection: 'row', my: 3, justifyContent: 'space-between' }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                        <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                            {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Frequency </Typography>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600', alignContent: 'flex-start' }}> {ele.Frequency}</Typography>
                                                    </Box>
                                                    {ele.Frequency === 'DAILY' ? '' : <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>No. Of Instalments </Typography>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.No_of_Installments}</Typography>
                                                    </Box>}

                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>SIP Date </Typography>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {formatDate_DDMMYYYY(ele.SIP_date?.split('T')[0])}</Typography>
                                                    </Box>
                                                    {ele.Folio === '' ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Account Name </Typography>
                                                            {accountType?.account_type.filter((i: any) => i.AccountId === ele.accountID)
                                                                .map((item: any) => (
                                                                    <Typography key={item.AccountId} sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>
                                                                        {item.AccountType}
                                                                    </Typography>
                                                                ))}
                                                        </Box> :
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                        </Box>
                                                    }

                                                </Box>
                                                : <Box sx={{ display: 'flex', flexDirection: 'row', my: 3 }}>
                                                    <Box sx={{ display: 'flex', flexDirection: 'column', width: '20%' }}>
                                                        <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Returns</Typography>
                                                        <Typography sx={{ fontSize: 12, color: "rgb(30, 163, 98)" }}>
                                                            {Nanprocessor(roundToTwo(Number(ele.Sch_Return5Yr)))} %
                                                        </Typography>
                                                    </Box>
                                                    {ele.Folio === '' ?
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '40%' }}>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Account Name </Typography>
                                                            {accountType?.account_type.filter((i: any) => i.AccountId === ele.accountID)
                                                                .map((item: any) => (
                                                                    <Typography key={item.AccountId} sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}>
                                                                        {item.AccountType}
                                                                    </Typography>
                                                                ))}
                                                        </Box> :
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '30%' }}>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82' }}>Folio </Typography>
                                                            <Typography sx={{ fontSize: 12, color: '#5a7c82', fontWeight: '600' }}> {ele.Folio}</Typography>
                                                        </Box>
                                                    }
                                                </Box>


                                            }
                                        </Collapse>
                                        {/* </>
                                                                                                        : ''} */}
                                        <Divider />
                                        {/* <Box sx={{ backgroundColor: '#C7E1FF', height: '12px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', fontSize: '10px' }} >
                                                                                                        <IconButton onClick={() => handleDetailsClick(index)}>
                                                                                                            {
                                                                                                                moreDetails[index] ? <KeyboardDoubleArrowUpIcon sx={{ fontSize: '15px', color: '#337FC9' }} /> : <KeyboardDoubleArrowDownIcon sx={{ fontSize: '15px', color: '#337FC9' }} />
                                                                                                            }
                                                                                                        </IconButton>
                                                                                                    </Box> */}
                                    </div>
                                })}
                            </div>
                        </Box>
                    </Modal>
                </>

            </CommonLayout >
            <Modal
                open={openModal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 1000,
                    bgcolor: 'background.paper',
                    borderRadius: '16px',
                    boxShadow: 24,
                    border: 'none',
                    py: 3
                }}>
                    <EditSchemesModal handleModalClose={handleModalClose} selectedSchemes={selectedSchemes} OnSchemeDeSelection={OnSchemeDeSelection} investorId={investorId} isRecommended={(val: boolean) => { setRecommended(val) }} trigger={trigger} setTrigger={setTrigger} page={value} />
                    {Recommend ? <Modal
                        open={Recommend}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={{
                            position: 'absolute' as any,
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            borderRadius: '10px',
                            boxShadow: 24,
                            p: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>

                            <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                            <Typography>Data Updated Successfully</Typography>
                            <Button onClick={handleClose} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                            {/* {Recommend && (
                                Swal.fire({
                                    html: `<b>Data Updated Sucessfully</b>`,
                                    icon: "success",
                                    showCancelButton: false,
                                    confirmButtonColor: "#3085d6",
                                    confirmButtonText: "OK",
                                    customClass: {
                                        htmlContainer: 'custom-html-container-class',
                                    }
                                }).then(() => handleClose())
                            )} */}

                        </Box>
                    </Modal> : <></>}
                </Box>
            </Modal>
        </div >
    );
}

import { Box, Button, CardMedia, Grid, IconButton, Typography } from '@mui/material';
import { Notes, ProceedSaveLater, SubHeading } from './components';
import {
  Formik,
  setNestedObjectValues,
  useField,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import React, { useEffect, useState } from 'react';
import {
  AddressProofsMasters,
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  applicantStatusMasters,
  APPLICATION_TYPE_FOR_DOCUMENTS,
  BankAccountProofsMasters,
  ENABLE_KRA_DOCUMENT_FETCH,
  IdentityProofsMasters,
  IMAGE_UPLOAD_FORMATS,
  INVESTOR_IMAGE_UPLOAD_FORMATS,
  onboardingTypeMasters,
  USER_ROLES,
} from '../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import {
  Applicant,
  Bank,
  Document,
  DocumentType,
  FileType,
  individuals_Poa_nonIndividuals_Documents,
  KraDocumentType,
  NomineeType,
  RiskProfileMaster,
} from '../../redux-store/types/api-types';
import {
  getDocuments,
  isFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import { documentDetailsSchema } from '../../utils/schema';
import { showError } from '../../redux-store/actions/auth';
import {
  getApplicantName,
  isMinor,
  isOnboardingTypeMinor,
  saveForLater,
  sendApplication_Nri,
} from '../../utils/utilityFunctions';
import { getFieldTitle } from '../ApplicationDetails/DocumentDetails';
import { setLabels } from 'react-chartjs-2/dist/utils';
import { applicationComparison, applyRoleBasedStatus } from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import {
  getMaskedAadhaar,
  getNationalityList,
  MaskedAadhaarResponse,
  riskProfileMasterData,
} from '../../redux-store/actions';
import { nationaliyType } from '../../redux-store/types/mdms';
import { KraDocument } from './kraDocument';
import { LoadingButton } from '@mui/lab';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileValidateType
);

const FileUpload = ({
  onFileChange,
  name,
  documentData,
  disabled,
  onFileUpload,
  onFileUploaded,
  fileUploadFormats,
  maxUploadFiles,
  maxFileSize,
  minFileSize,
  multipleFilesCheck,
}: {
  onFileChange: (value: string, documentId: string, removeFile?: boolean) => void;
  name: string;
  documentData: documentDetails;
  disabled: boolean;
  onFileUpload: () => void;
  onFileUploaded: () => void;
  fileUploadFormats: string[];
  maxUploadFiles: number;
  maxFileSize: string;
  minFileSize: string | null;
  multipleFilesCheck: string;
}): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name);
  const errorText = meta.error && meta.touched ? meta.error : '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [files, setFiles] = useState<any>([]);
  const authToken = useSelector((store: RootStateType) => store.auth.token);
  const handleFileChange = (name: string, documentId: string) => {
    try {
      onFileChange(name, documentId);
    } catch (e) {
      onFileUploaded();
      console.error((e as Error).message);
    }
  };
  return (
    <>
      {!documentData.file ? (
        <Box
          sx={{
            '.filepond--credits': { display: 'none' },
            '.filepond--label-action': {
              textDecoration: 'none',
              fontSize: '14px',
            },
            '.filepond--drop-label': {
              cursor: disabled ? 'not-allowed' : 'pointer',
              pointerEvents: 'all',
            },
            '.filepond--file': {
              color: '#000',
              flexWrap: 'wrap',
              fontWeight: 500,
            },
            pt: 4,
          }}>
          <FilePond
            files={files}
            server={{
              process: {
                url: '/api/files/files',
                method: 'POST',
                headers: {
                  Authorization: `Bearer ${authToken}`,
                },
                onload: (response): string => {
                  const result = JSON.parse(response);
                  result?.name && result?.id && handleFileChange(result.name, result.id);
                  onFileUploaded();
                  return response;
                },
              },
            }}
            onaddfilestart={() => {
              onFileUpload();
            }}
            onaddfile={(error, file) => {
              if (error) {
                onFileUploaded();
              }
            }}
            onprocessfile={(error, file) => {
              if (error) {
                onFileUploaded();
              }
            }}
            onupdatefiles={(fileItems) => {
              setFiles(fileItems);
            }}
            onprocessfilerevert={() => {
              onFileChange('', '', true);
            }}
            name="file"
            minFileSize={minFileSize}
            maxFileSize={maxFileSize}
            allowMultiple={multipleFilesCheck === 'false'}
            maxFiles={maxUploadFiles}
            acceptedFileTypes={fileUploadFormats}
            labelIdle={`<span class="filepond--label-action">Choose File <div>${
              minFileSize ? `Min Size:${minFileSize}, ` : ''
            }Max Size:${maxFileSize}</div>Supports: ${fileUploadFormats
              ?.map((format) => format?.split('/')[1])
              ?.toString()}</span>`}
            labelFileTypeNotAllowed={'Invalid file type'}
            labelMaxFileSize={`Max file size is ${maxFileSize}`}
            labelMinFileSize={`Min file size is ${minFileSize}`}
            fileValidateTypeLabelExpectedTypesMap={{
              'image/jpeg': '.jpeg',
              'image/jpg': '.jpg',
              'image/png': '.png',
              'application/pdf': '.pdf',
            }}
            imagePreviewHeight={100}
            disabled={disabled}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: '1px dashed #AAB2BA',
            borderRadius: '6px',
            mt: '30px',
            minHeight: '65px',
          }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <CardMedia
              component="img"
              src={documentData.file.thumbnailUrl || '/images/photo-placeholder.jpg'}
              alt="aadhar"
              width="50"
              height="50"
              sx={{ display: 'flex', width: 'unset', mx: 1 }}
            />
          </Box>
          <Typography
            component="span"
            sx={{ whiteSpace: 'pre-wrap', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
            {documentData.file.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              '.MuiButton-root': { fontWeight: 500, fontSize: 14, p: 0, mr: 1 },
              flexWrap: 'wrap',
            }}>
            <Button
              sx={{
                color: 'primary.main',
                border: '1px dashed #2057A6',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.viewUrl)}>
              View
            </Button>
            {!disabled && (
              <Button
                variant="contained"
                onClick={() => {
                  onFileChange('', '', true);
                }}
                sx={{ mb: 1 }}>
                Replace
              </Button>
            )}
            <IconButton
              sx={{
                p: 0,
                pr: 0.5,
                ':hover': {
                  bgcolor: '#F4FCFC',
                },
                cursor: 'pointer',
                pointerEvents: 'all',
                mb: 1,
              }}
              onClick={() => window.open(documentData.file?.url, '_blank')}>
              <CardMedia
                component="img"
                src={'/images/download-icon.png'}
                alt="Download"
                sx={{ width: '25px' }}
              />
            </IconButton>
          </Box>
        </Box>
      )}
      {!!errorText && (
        <Typography
          sx={{
            color: '#d32f2f',
            fontSize: '0.75rem',
          }}>
          {errorText}
        </Typography>
      )}
    </>
  );
};

type documentDetails = {
  documentType: string;
  documentName: string;
  documentId: string;
  id?: string | number;
  isActive: boolean;
  file?: FileType;
  loading?: boolean;
  required: string;
  uniqueKey: string;
  options?: string[];

  [key: string]: string | number | boolean | FileType | undefined | string[] | null | boolean;
};

type documentProps = {
  documents: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
    uploadFormats: string[];
    maxFiles: number;
    maxFileSize: string;
    minFileSize: string;
  }[];
  kraDocument?: KraDocumentType[];
  onboardingType?: any;
};

type nomineeDocumentProps = {
  nomineedocuments: {
    documentType: string;
    documentName: string;
    documentsList: documentDetails[];
    multipleFiles: string;
    required: string;
    options?: string[];
    uploadFormats: string[];
    maxFiles: number;
    maxFileSize: string;
    minFileSize: string;
  }[];
};
export interface Values {
  applicants: documentProps[];
  nominees: nomineeDocumentProps[];
  saveType: string;
  banks: Bank[];
  hasPOA: boolean;
  [key: string]:
    | documentProps[]
    | string
    | undefined
    | string[]
    | Bank[]
    | boolean
    | nomineeDocumentProps[];
}
export const document_object = {
  documentId: '',
  isActive: true,
};
export default function DocumentDetails(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  const history = useHistory();
  const [documentUpload, setDocumentUpload] = useState(false);

  const handleUpload = () => {
    setDocumentUpload(true);
  };
  const handleUploaded = () => {
    setDocumentUpload(false);
  };
  const [documentsData, setDocumentsData] = useState<Document>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(getDocuments())) as unknown as Document;
        setDocumentsData(response as Document);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const { applicants = [], banks = [], hasPOA = true, nominees = [] } = application || {};

  const initialValues: Values = {
    applicants: applicants.map((applicant, applicant_index) => {
      let docData = '';
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        if (
          onboardingTypeMasters[applicants[0].onboardingType as string] ===
          onboardingTypeMasters.minor
        ) {
          docData = APPLICATION_TYPE_FOR_DOCUMENTS.MINOR;
        } else {
          docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL;
        }
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.Individual
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.INDIVIDUAL_POA;
      }
      if (
        !hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI;
      }
      if (
        hasPOA &&
        applicantStatusMasters[applicant.status as string] === applicantStatusMasters.NRI
      ) {
        docData = APPLICATION_TYPE_FOR_DOCUMENTS.NRI_POA;
      }
      return {
        documents:
          typeof documentsData !== 'undefined'
            ? ((documentsData as Document)[docData] || [])
                .filter((doc) => {
                  if (
                    hasPOA &&
                    applicant.applicant_type !== '1' &&
                    doc.documentType === 'poaNotarized'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .filter((document) => {
                  if (applicant.amlCheck && document.documentType === 'compliance_document') {
                    return;
                  }
                  return document;
                })
                .filter((ele) => ele)
                .filter((doc) => {
                  if (
                    !applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return;
                  }
                  return doc;
                })
                .filter((ele) => ele)
                .map((doc) => {
                  if (
                    applicant.correspondenceAddressEdited &&
                    doc.documentType === 'correspondenceAddress'
                  ) {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                .map((doc) => {
                  if (applicant.permanentAddressEdited && doc.documentType === 'addressProof') {
                    return { ...doc, required: 'true' };
                  }
                  return doc;
                })
                .map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                  const {
                    documentType,
                    documentName,
                    multipleFiles,
                    required,
                    options,
                    uploadFormats,
                    maxFiles,
                    maxFileSize,
                    minFileSize,
                  } = doc;
                  const { documents: existingDocuments = [] } = applicant || {};
                  const docsOfCurrentDocType = existingDocuments
                    .filter((doc) => doc.documentType === documentType)
                    .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                  return {
                    documentType: documentType,
                    documentName: documentName,
                    documentsList: docsOfCurrentDocType.length
                      ? docsOfCurrentDocType.map((doc, ind) => {
                          const {
                            id,
                            documentName = '',
                            documentType = '',
                            documentId = '',
                            isActive = true,
                            file = undefined,
                            loading = false,
                          } = doc;
                          return {
                            id,
                            applicantId: applicant?.id,
                            documentType,
                            documentName,
                            loading,
                            documentId,
                            isActive,
                            file,
                            options,
                            required,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              ind.toString()) as string,
                          };
                        })
                      : [
                          {
                            documentType,
                            documentName,
                            required,
                            options,
                            uniqueKey: (applicant_index.toString() +
                              '-' +
                              index.toString() +
                              '-' +
                              '0') as string,
                            ...document_object,
                          },
                        ],
                    required,
                    multipleFiles,
                    options,
                    uploadFormats,
                    maxFiles,
                    maxFileSize,
                    minFileSize,
                  };
                })
            : [],
        kraDocument: applicant.kraDocument,
        onboardingType: applicant.onboardingType,
      };
    }),
    nominees: nominees.map((nominee, nominee_index) => {
      return {
        nomineedocuments:
          typeof documentsData !== 'undefined'
            ? (isMinor(nominee.dateOfBirth || '')
                ? [
                    ...((documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] ||
                      []),
                    ...((documentsData as Document)[
                      APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_GUARDIAN
                    ] || []),
                  ].map((doc) => {
                    if (doc.documentType === 'nomineeIdProof') {
                      return { ...doc, required: 'false' };
                    }
                    return doc;
                  })
                : (documentsData as Document)[APPLICATION_TYPE_FOR_DOCUMENTS.NOMINEE_DOC] || []
              ).map((doc: individuals_Poa_nonIndividuals_Documents, index: number) => {
                const {
                  documentType,
                  documentName,
                  multipleFiles,
                  required,
                  options,
                  uploadFormats,
                  maxFiles,
                  maxFileSize,
                  minFileSize,
                } = doc;
                const { nomineedocuments: existingDocuments = [] } = nominee || {};
                const docsOfCurrentDocType = existingDocuments
                  .filter((doc) => doc.documentType === documentType)
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId));
                return {
                  documentType: documentType,
                  documentName: documentName,
                  documentsList: docsOfCurrentDocType.length
                    ? docsOfCurrentDocType.map((doc, ind) => {
                        const {
                          id,
                          documentName = '',
                          documentType = '',
                          documentId = '',
                          isActive = true,
                          file = undefined,
                        } = doc;
                        return {
                          id,
                          nomineeId: nominee?.id,
                          documentType,
                          documentName,
                          documentId,
                          isActive,
                          file,
                          options,
                          required,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            ind.toString()) as string,
                        };
                      })
                    : [
                        {
                          documentType,
                          documentName,
                          required,
                          options,
                          uniqueKey: (nominee_index.toString() +
                            '-' +
                            index.toString() +
                            '-' +
                            '0') as string,
                          ...document_object,
                        },
                      ],
                  required,
                  multipleFiles,
                  options,
                  uploadFormats,
                  maxFiles,
                  maxFileSize,
                  minFileSize,
                };
              })
            : [],
      };
    }),
    saveType: 'save and proceed',
    banks: banks,
    hasPOA: hasPOA,
  };
  const updatedApplicants = (
    exisitingApplicants: Partial<Applicant>[],
    applicants: documentProps[]
  ): Partial<Applicant>[] => {
    return exisitingApplicants.map((applicant, index) => {
      const { documents = [] } = applicants[index] || {};
      const finalDocs = documents
        .map((doc) => {
          return doc.documentsList.filter((d) => d.documentId);
        })
        .flat()
        .map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, ...rest } = doc;
          return {
            ...rest,
            // id: applicant?.documents?.find((doc) => doc.documentType === rest.documentType)?.id,
            // applicantId: applicant?.id,
          };
        });
      return {
        ...applicant,
        documents: finalDocs,
      };
    }) as Partial<Applicant>[];
  };

  const updatedNominees = (
    existingNominees: NomineeType[],
    nominees: nomineeDocumentProps[]
  ): NomineeType[] => {
    return existingNominees.map((nominee, index) => {
      const { nomineedocuments = [] } = nominees[index] || {};
      const finalDocs = nomineedocuments
        .map((doc) => {
          return doc.documentsList.filter((d) => d.documentId);
        })
        .flat()
        .map((doc) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { uniqueKey, required, ...rest } = doc;
          return {
            ...rest,
            // nomineeId: nominee?.id,
            // id: nominee?.nomineedocuments?.find((doc) => doc.documentType === rest.documentType)
            //   ?.id,
          };
        });
      return {
        ...nominee,
        nomineedocuments: finalDocs,
      };
    }) as NomineeType[];
  };

  const handleSubmit = async (values: Values) => {
    try {
      setLoading(true);
      const nationalitiesMdmsMasters = (await dispatch(
        getNationalityList()
      )) as unknown as nationaliyType;
      const responseRiskProfile = (await dispatch(
        riskProfileMasterData()
      )) as unknown as RiskProfileMaster;
      await isFormValidForSubmission(
        application,
        false,
        true,
        nationalitiesMdmsMasters,
        documentsData,
        role,
        responseRiskProfile.riskProfile
      );
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId,
        currentStep,
        status,
        applicationNumber,
        nominees: existingNominees = [],
      } = application || {};
      const { applicants, saveType, hasPOA, nominees } = values;

      // const updatedApplicants = exisitingApplicants.map((applicant, index) => {
      //   const applicantsWithNoDocsAttached = Object.keys(applicants[index].documents).filter(
      //     (doc) => applicants[index].documents[doc].documentId
      //   );
      //   return {
      //     ...applicant,
      //     countryCode: '+91', //For now hard coding country code. Need to remove after UI is done
      //     documents: applicantsWithNoDocsAttached.map((documentType) => ({
      //       ...getExistingDocuments(exisitingApplicants[index].documents || [], documentType),
      //       ...applicants[index].documents[documentType],
      //     })),
      //   };
      // });

      const updatedApplicantsWithDoc = updatedApplicants(exisitingApplicants, applicants);
      updatedApplicantsWithDoc.map((applicant) => {
        if (applicant.documents?.length) {
          applicant.documents.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${getFieldTitle[document.documentType]}`;
            }
          });
        }
      });

      const updatedNomineesWithDoc = updatedNominees(existingNominees, nominees);
      updatedNomineesWithDoc.map((_nominee) => {
        if (_nominee.nomineedocuments?.length) {
          _nominee.nomineedocuments.map((document) => {
            if (document.options && !document.options.includes(document.documentName)) {
              throw `Please Select ${getFieldTitle[document.documentType]}`;
            }
          });
        }
      });
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants
            ?.map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  ?.map((document: any) => {
                    const {
                      documentData,
                      // id,
                      // applicantId,
                      filename,
                      updatedAt,
                      createdAt,
                      updatedBy,
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: application?.nominees
            ?.map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  ?.map((document: any) => {
                    const {
                      documentData,
                      // id,
                      // nomineeId,
                      filename,
                      updatedAt,
                      createdAt,
                      updatedBy,
                      createdBy,
                      ...rest
                    } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
        },
        {
          ...application,
          applicants: updatedApplicantsWithDoc
            .map((applicant) => {
              return {
                ...applicant,
                documents: applicant.documents
                  ?.map((document: any) => {
                    const { documentData, options, loading, ...rest } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          nominees: updatedNomineesWithDoc
            .map((nominee) => {
              return {
                ...nominee,
                nomineedocuments: nominee.nomineedocuments
                  ?.map((document: any) => {
                    const { documentData, options, ...rest } = document;
                    return { ...rest };
                  })
                  .sort((doc1, doc2) => Number(doc1.documentId) - Number(doc2.documentId)),
              };
            })
            .sort((nominee1, nominee2) => Number(nominee1.id) - Number(nominee2.id)),
          currentStep: !!currentStep && currentStep > 11 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicantsWithDoc,
              nominees: updatedNomineesWithDoc,
              // : (status === 'draft' || status === 'sent_to_amc_approver') &&
              status:
                !application?.hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                applyRoleBasedStatus(role) &&
                status !== 'draft'
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 12,
              //!!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && {
              toastMessage: '',
            }),
          })
        );
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/investment-details/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : isSaveLater
            ? `/applications`
            : `/application-preview/${id}`
        );
      } else if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/investment-details/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : `/applications`
        );
      } else {
        history.push(
          role === USER_ROLES.INVESTOR
            ? `/investment-details/${applicant1ReferenceId}/application-details`
            : role === USER_ROLES.POAAPPROVER || USER_ROLES.AMCAPPROVER === role
            ? `/application-details/${id}`
            : `/application-preview/${id}`
        );
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  const getLabel = (
    documentName: string,
    documentType: string,
    required: string,
    values: Values,
    ind: number
  ): string => {
    return `${documentName}  ${
      documentType === 'bankAccountProof'
        ? ((!isOnboardingTypeMinor((applicants[0]?.onboardingType as string) || '') &&
            values.banks.filter((bank) => !bank.pennydropVerified && bank.defaultBankAccount)
              .length !== 0) ||
            isOnboardingTypeMinor((applicants[0].onboardingType as string) || '')) &&
          ind === 0
          ? '*'
          : ''
        : required === 'true'
        ? '*'
        : ''
    } `;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      //validationSchema={documentDetailsSchema}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, documentDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({
        handleSubmit,
        values,
        setValues,
        validateForm,
        setTouched,
        setErrors,
        setFieldValue,
      }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={10}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid sx={{ width: '100%' }}>
            <Notes
              displayContent={'Please upload latest documents not more than 2 months old'}
              displayContent1={"Please don't upload any password protected files"}
            />
          </Grid>
          {values.applicants.map((applicant, ind) => {
            const { documents } = applicant;
            return (
              <>
                {' '}
                <SubHeading>Applicant {ind + 1} Document Details</SubHeading>
                {documents.map((document, doc_index) => {
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                    uploadFormats,
                    maxFiles,
                    maxFileSize,
                    minFileSize,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList.map((doc, index) => (
                            <React.Fragment key={index}>
                              <Grid xs={12} sm={12}>
                                <MFSelectField
                                  name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentName`}
                                  label={getLabel(
                                    documentName,
                                    documentType,
                                    required,
                                    values,
                                    ind
                                  )}
                                  items={options.map((item: string) => ({
                                    key: item,
                                    value: item,
                                  }))}
                                  applyLabelStyles={true}
                                  disabled={isFieldDisabled}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                            {documentType === 'investorSignature' && (
                              <Typography
                                sx={{
                                  color: 'primary.main',
                                  fontWeight: 400,
                                  fontSize: 14,
                                }}>
                                (This signature copy is used as a verification process for future
                                redemptions and transfer requests)
                              </Typography>
                            )}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {documentsList.map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, removeFile) => {
                                const { file, ...rest } =
                                  values.applicants[ind].documents[doc_index].documentsList[index];
                                setFieldValue(
                                  `applicants.${ind}.documents.${doc_index}.documentsList.${index}`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                  }
                                );
                              }}
                              name={`applicants.${ind}.documents.${doc_index}.documentsList.${index}.documentId`}
                              documentData={doc}
                              disabled={isFieldDisabled}
                              onFileUpload={handleUpload}
                              onFileUploaded={handleUploaded}
                              fileUploadFormats={uploadFormats}
                              maxUploadFiles={maxFiles}
                              maxFileSize={maxFileSize}
                              minFileSize={minFileSize}
                              multipleFilesCheck={multipleFiles}
                            />
                            {[
                              'identityProof',
                              'aadhaar_card',
                              'addressProof',
                              'correspondenceAddress',
                            ].includes(doc.documentType) && (
                              <LoadingButton
                                loadingPosition="start"
                                type="submit"
                                loading={
                                  values.applicants[ind].documents[doc_index].documentsList[index]
                                    .loading
                                }
                                variant="contained"
                                sx={{
                                  mt: 1,
                                  float: 'right',
                                  fontSize: 15,
                                  pl: values.applicants[ind].documents[doc_index].documentsList[
                                    index
                                  ].loading
                                    ? 5
                                    : 1,
                                  py: 0.5,
                                  cursor:
                                    !doc.documentName.includes('Aadhaar Card') ||
                                    doc.documentId === ''
                                      ? 'none'
                                      : 'pointer',
                                }}
                                onClick={async () => {
                                  try {
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList.${index}.loading`,
                                      true
                                    );
                                    const maskedAadhaarResponse = (await dispatch(
                                      getMaskedAadhaar({
                                        fileId: doc.documentId,
                                        applicationId: application?.id || '',
                                        applicantId: application?.applicants[ind].id || '',
                                      })
                                    )) as unknown as MaskedAadhaarResponse;
                                    const { loading, file, ...rest } =
                                      values.applicants[ind].documents[doc_index].documentsList[
                                        index
                                      ];
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList.${index}`,
                                      {
                                        ...rest,
                                        loading: false,
                                        documentId: maskedAadhaarResponse.id,
                                        ...(rest.id && { file }),
                                      }
                                    );
                                    if (maskedAadhaarResponse.id) {
                                      enqueueSnackbar('Aadhaar masked Successfully', {
                                        variant: 'success',
                                        autoHideDuration: 3000,
                                      });
                                    }
                                  } catch (e) {
                                    setFieldValue(
                                      `applicants.${ind}.documents.${doc_index}.documentsList.${index}.loading`,
                                      false
                                    );
                                    console.error((e as Error).message);
                                  }
                                }}
                                disabled={
                                  !doc.documentName.includes('Aadhaar Card') ||
                                  doc.documentId === '' ||
                                  isFieldDisabled
                                }>
                                Mask Aadhaar
                              </LoadingButton>
                            )}
                          </React.Fragment>
                        ))}
                      </Grid>
                    </React.Fragment>
                  );
                })}
                {ENABLE_KRA_DOCUMENT_FETCH && USER_ROLES.INVESTOR !== role && (
                  <KraDocument KraDocument={applicant.kraDocument} />
                )}
              </>
            );
          })}
          {values.nominees.map((nominee, ind) => {
            const { nomineedocuments } = nominee;
            return (
              <>
                {' '}
                <SubHeading> {getApplicantName(ind + 1, true)} Nominee Document Details</SubHeading>
                {nomineedocuments.map((document, doc_index) => {
                  const {
                    documentType,
                    documentName,
                    documentsList = [],
                    multipleFiles,
                    options,
                    required,
                    uploadFormats,
                    maxFiles,
                    maxFileSize,
                    minFileSize,
                  } = document;
                  return (
                    <React.Fragment key={ind}>
                      <Grid item xs={12} sm={6}>
                        {options &&
                          documentsList.map((doc, index) => (
                            <React.Fragment key={index}>
                              <Grid xs={12} sm={12}>
                                <MFSelectField
                                  name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentName`}
                                  label={getLabel(
                                    documentName,
                                    documentType,
                                    required,
                                    values,
                                    ind
                                  )}
                                  items={options.map((item: string) => ({
                                    key: item,
                                    value: item,
                                  }))}
                                  disabled={isFieldDisabled}
                                  applyLabelStyles={true}
                                />
                              </Grid>
                            </React.Fragment>
                          ))}
                        {!options && (
                          <Typography
                            sx={{
                              color: 'primary.main',
                              fontWeight: 500,
                              mt: documentType === 'investorSignature' ? 4 : 6,
                            }}>
                            {documentName} {required === 'true' ? '*' : ''}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {documentsList.map((doc, index) => (
                          <React.Fragment key={doc.uniqueKey}>
                            <FileUpload
                              onFileChange={(value, documentId, removeFile) => {
                                const { file, ...rest } =
                                  values.nominees[ind].nomineedocuments[doc_index].documentsList[
                                    index
                                  ];
                                setFieldValue(
                                  `nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}`,
                                  {
                                    ...rest,
                                    documentId,
                                    ...(!removeFile && { file }),
                                  }
                                );
                              }}
                              name={`nominees.${ind}.nomineedocuments.${doc_index}.documentsList.${index}.documentId`}
                              documentData={doc}
                              disabled={isFieldDisabled}
                              onFileUpload={handleUpload}
                              onFileUploaded={handleUploaded}
                              fileUploadFormats={uploadFormats}
                              maxUploadFiles={maxFiles}
                              maxFileSize={maxFileSize}
                              minFileSize={minFileSize}
                              multipleFilesCheck={multipleFiles}
                            />
                          </React.Fragment>
                        ))}
                      </Grid>
                    </React.Fragment>
                  );
                })}
              </>
            );
          })}
          <ProceedSaveLater
            saveButtonText={
              isFieldDisabled || USER_ROLES.AMCAPPROVER === role ? 'Save Application' : 'Preview'
            }
            // saveButtonText={
            //   isFieldDisabled || USER_ROLES.AMCAPPROVER === role
            //     ? 'Save Application'
            //     : values.hasPOA
            //     ? 'Send to POA Approver'
            //     : // : (application?.status === 'draft' ||
            //     //     application?.status === 'sent_to_amc_approver') &&
            //     AMC_APPROVER_CHECK_FOR_INDIVIDUAL && applyRoleBasedStatus(role)
            //     ? 'Send to Checker'
            //     : 'Send to Investor'
            // }
            showEndIcon={false}
            saveLater={() => {
              setFieldValue('saveType', 'save for later');
            }}
            // saveAndProceed={async () => {
            //   try {
            //     if (![USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)) {
            //       await isFormValidForSubmission(application, false);
            //       const error = await validateForm();
            //       if (!Object.keys(error).length) {
            //         history.push(`/application-details/${application?.id}`);
            //         //setDialog(true);
            //       }
            //       setTouched(setNestedObjectValues(error, true));
            //       setErrors(error);
            //     }
            //   } catch (e) {
            //     dispatch(showError(e as string));
            //   }
            // }}
            //saveAndProceedType={isFieldDisabled ? 'submit' : 'button'}
            loader={loading}
            clickedButton={values.saveType}
            showSaveForLater={
              isFieldDisabled ? false : USER_ROLES.AMCAPPROVER === role ? false : true
            }
            disabled={documentUpload}
            saveAndProceed={() => setFieldValue('saveType', 'save and proceed')}
          />
        </Grid>
      )}
    </Formik>
  );
}

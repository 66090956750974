import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { investmentPreferenceMasterData } from '../../redux-store/actions';
import {
  ApplicationProps,
  investmentpreferencesType,
  InvestmentPreferenceMasterType,
  assetallocationguidelinesType,
  TypeInvestmentPreferenceMdms,
  allocationlimitsType,
  tolerancelimitsType,
} from '../../redux-store/types/api-types';
import { currencyConversion } from '../../utils/utilityFunctions';
import { DataTable, WebViewDataRow } from '../DataTable';
import { SubHeading } from '../investors/components';
import { updatedServicePreference } from '../investors/servicePreferenceDetails';
import { Gridstyles, Item, ItemDetails } from './index';
import { KeyValue } from './personalDetails';

export default function InvestmentPreference({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const {
    investmentpreferences = [],
    tolerancelimits = [],
    allocationlimits = [],
    assetallocationguidelines = [],
  } = application || {};
  const [investmentPreferenceData, setInvestmentPreferenceData] = useState<any>([]);
  const dispatch = useDispatch();

  const assetAllocationHeaders = [
    {
      header: 'Asset Class',
      label: 'assetClass',
      valueGetter: (row: assetallocationguidelinesType) => row.assetClass || 'N/A',
    },
    {
      header: 'Minimum Allocation %',
      label: 'minimumAllocation',
      valueGetter: (row: assetallocationguidelinesType) => row.minimumAllocation + ' %' || 'N/A',
    },
    {
      header: 'Maximum Allocation %',
      label: 'maximumAllocation',
      valueGetter: (row: assetallocationguidelinesType) => row.maximumAllocation + ' %' || 'N/A',
    },
  ];

  const allocationlimitHeaders = [
    {
      header: 'Maturity Buckets',
      label: 'maturityBuckets',
      valueGetter: (row: allocationlimitsType) => row.maturityBuckets || 'N/A',
    },
    {
      header: 'Indicative Allocation',
      label: 'indicativeAllocation',
      valueGetter: (row: allocationlimitsType) => row.indicativeAllocation + ' %' || 'N/A',
    },
  ];
  const tolerancelimitsHeaders = [
    {
      header: 'Fixed Income Category*',
      label: 'fixedIncomeCategory',
      valueGetter: (row: tolerancelimitsType) => row.fixedIncomeCategory || 'N/A',
    },
    {
      header: 'Allocation %',
      label: 'allocation',
      valueGetter: (row: tolerancelimitsType) => row.allocation + ' %' || 'N/A',
    },
  ];
  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(
          investmentPreferenceMasterData()
        )) as unknown as TypeInvestmentPreferenceMdms;
        setInvestmentPreferenceData({
          ...investmentPreferenceData,
          investmentpreferences: response.invesmentQuestions
            .map((preference) => {
              let ans = [] as any;
              preference.questions.map((a) => {
                const existingInvestmentPreference = investmentpreferences?.filter(
                  (data) => data.question === a.questionKey
                );
                ans = existingInvestmentPreference.length
                  ? existingInvestmentPreference.map((preferences) => {
                      if (preferences.question === a.questionKey) {
                        return {
                          ...preferences,
                          ...a,
                          question: a.questionKey,
                          answer: preferences.answer,
                          section: preference.SectionHeading,
                        };
                      }
                    })
                  : [
                      {
                        ...investmentpreferences,
                        question: a.questionKey,
                        answer: '',
                        section: preference.SectionHeading,
                        ...a,
                      },
                    ];
              });
              return ans;
            })
            .flat(),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2, pb: 6 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Investment Preference
            </Typography>
            {investmentPreferenceData?.investmentpreferences?.map((preferenceData: any) => {
              const ans = preferenceData?.options?.find(
                (option: any) => option.key === preferenceData.answer
              );
              return (
                <React.Fragment key={preferenceData?.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>
                    {preferenceData.section}
                  </SubHeading>
                  <Gridstyles>
                    <Grid
                      item
                      container
                      pb={5}
                      rowSpacing={6}
                      columnSpacing={13}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="flex-start">
                      {/* {preferenceData.questions?.map((question: any) => {
                        return (
                          <React.Fragment key={question.questionKey}> */}
                      {preferenceData.questionText &&
                        preferenceData.question !== 'liquidateAssetsunder' && (
                          <KeyValue title={preferenceData.questionText} description={ans?.label} />
                        )}

                      {preferenceData.question === 'liquidateAssetsunder' &&
                        preferenceData.questionText && (
                          <Grid item xs={12} sm={12}>
                            <Item>{preferenceData.questionText}</Item>
                            <ItemDetails>
                              {' '}
                              {ans?.label
                                ? ans?.key === 'yes'
                                  ? 'Yes' + ' (anticipated amount in Rupees)'
                                  : ans?.label
                                : '-'}
                            </ItemDetails>
                            {preferenceData.value && <Item>{preferenceData.value}</Item>}
                            {preferenceData.value && Number(preferenceData.value) > 0 && (
                              <Typography
                                sx={{
                                  fontWeight: 500,
                                  fontSize: 14,
                                  mb: -4,
                                  mt: 1,
                                }}>
                                {currencyConversion(Number(preferenceData.value))}
                              </Typography>
                            )}
                          </Grid>
                        )}
                      {!preferenceData.questionText && (
                        <Grid item xs={12} sm={6}>
                          <ItemDetails>
                            {' '}
                            {preferenceData.answer || preferenceData.value || '-'}
                          </ItemDetails>
                          {preferenceData.answer && Number(preferenceData.answer) > 0 && (
                            <Typography
                              sx={{
                                fontWeight: 500,
                                fontSize: 14,
                                mb: -4,
                                mt: 1,
                              }}>
                              {currencyConversion(Number(preferenceData.answer))}
                            </Typography>
                          )}
                        </Grid>
                      )}
                      {/* </React.Fragment>
                        );
                      })} */}
                    </Grid>
                  </Gridstyles>
                </React.Fragment>
              );
            })}

            <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>Asset Allocation guidelines</SubHeading>

            <Gridstyles>
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836', mb: 2 }}>
                  {`Please indicate the broad asset allocation guidelines to be implemented for the investment advice.`}
                </Typography>
                <DataTable
                  tableData={assetallocationguidelines}
                  tableHeader={assetAllocationHeaders}
                  renderAdditionalRow={false}
                  tableHeaderCustomStyles={{
                    '.MuiTableCell-head': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'primary.main',
                      textAlign: 'left',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      bgcolor: '#F3F8FB',
                    },
                  }}
                  rowCustomStyles={{
                    '.MuiTableCell-root': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(19, 24, 54, 0.7)',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      overflowWrap: 'anywhere',
                      textAlign: 'left',
                      verticalAlign: 'top',
                    },
                    '&:nth-child(odd)': {
                      bgcolor: 'rgba(19, 24, 54, 0.02)',
                    },
                  }}
                  boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                />
              </Box>
            </Gridstyles>
            <Gridstyles>
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836', mb: 2 }}>
                  {`Allocation limit for the below Fixed income maturities.`}
                </Typography>
                <DataTable
                  tableData={allocationlimits}
                  tableHeader={allocationlimitHeaders}
                  renderAdditionalRow={false}
                  tableHeaderCustomStyles={{
                    '.MuiTableCell-head': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'primary.main',
                      textAlign: 'left',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      bgcolor: '#F3F8FB',
                    },
                  }}
                  rowCustomStyles={{
                    '.MuiTableCell-root': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(19, 24, 54, 0.7)',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      overflowWrap: 'anywhere',
                      textAlign: 'left',
                      verticalAlign: 'top',
                    },
                    '&:nth-child(odd)': {
                      bgcolor: 'rgba(19, 24, 54, 0.02)',
                    },
                  }}
                  boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                />
              </Box>
            </Gridstyles>
            <Gridstyles>
              <Box sx={{ mt: 4 }}>
                <Typography sx={{ fontWeight: 600, fontSize: 14, color: '#131836', mb: 2 }}>
                  {`Tolerance limits for the below Fixed income category.`}
                </Typography>
                <DataTable
                  tableData={tolerancelimits}
                  tableHeader={tolerancelimitsHeaders}
                  renderAdditionalRow={false}
                  tableHeaderCustomStyles={{
                    '.MuiTableCell-head': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'primary.main',
                      textAlign: 'left',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      bgcolor: '#F3F8FB',
                    },
                  }}
                  rowCustomStyles={{
                    '.MuiTableCell-root': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(19, 24, 54, 0.7)',
                      px: 2,
                      py: 1.5,
                      width: '30%',
                      overflowWrap: 'anywhere',
                      textAlign: 'left',
                      verticalAlign: 'top',
                    },
                    '&:nth-child(odd)': {
                      bgcolor: 'rgba(19, 24, 54, 0.02)',
                    },
                  }}
                  boxStyles={{ minHeight: '0px', minWidth: 450, maxHeight: 'unset' }}
                />
              </Box>
            </Gridstyles>

            {/* <Grid container pt={3}>
              <Grid item xs={12} alignSelf="center"> */}

            {/* <WebViewDataRow
                  tableData={assetallocationguidelines}
                  tableHeader={assetAllocationHeaders}
                  renderAdditionalRow={false}
                  tableHeaderCustomStyles={{
                    '.MuiTableCell-head': {
                      fontFamily: 'Poppins, sans-serif',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'primary.main',
                    },
                  }}
                  rowCustomStyles={{
                    '.MuiTableCell-root': {
                      py: '8px',
                      overflowWrap: 'anywhere',
                      padding: { xs: '10px', sm: '30px' },
                    },
                  }}
                  tableBodyCustomStyles={{
                    '.MuiTableRow-root': {
                      '&:last-child': {
                        borderBottom: 'none',
                      },
                    },
                  }}
                /> */}
            {/* </Grid>
            </Grid> */}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

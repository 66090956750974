import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { RootStateType } from '../../redux-store/reducers';
import { ProgressBarIntervals, RevenuePercent, scrollbarView } from '.';
import { ColumnType, DataTable } from '../DataTable';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { ProductDebtTop10Entity, ProductDebtType, ProductDebtAmcWiseAllocationEntity, ProductDebtIssuerCategoryAllocationEntity } from '../../redux-store/types/api-types';
import { CopyGraphDropdown, AmountUnit } from '../commonComponents';
import { ProductTopSection } from './product-details';
import ReactCardFlip from 'react-card-flip';
import { FlipIcon } from '../customSVGs';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function ProductDebt({
  investorId,
  selectedFilters,
  selectedPortfolio
}: {
  investorId: string; selectedPortfolio: number;
  selectedFilters: { [key: string]: string[] };
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [debtData, setDebtData] = useState<ProductDebtType | null>(null);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const [flipIssuerCategoryAllocation, setFlipIssuerCategoryAllocation] = useState(false);
  const [flipAmcAllocationDebt, setFlipAmcAllocationDebt] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setDebtData(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'MUTUAL FUNDS',
            rmID: responseRM.Header_ID,
            HFlag: selectedPortfolio,
            filters: {
              ...selectedFilters,
              customers: investorId ? [investorId] : [],
            },
            required: ['debt'],
          })
        )) as unknown as ProductDebtType;
        if (!isComponentActive) {
          return;
        }
        setDebtData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedFilters, selectedPortfolio]);
  const ratingAllocation = debtData ? debtData?.['Rating Allocation'] : [];
  const instrumentAllocation = debtData ? debtData?.['Instrument Allocation'] : [];
  const maturityAllocation = debtData ? debtData?.['Average Maturity Allocation'] : [];
  const debtQuants = debtData?.['Debt Quants'] ? debtData['Debt Quants'][0] : null;
  const legendOptions = {
    enabled: true,
    layout: 'vertical',
    itemDistance: 20,
    itemMarginTop: 5,
    itemPadding: 5,
    useHTML: true,
    // symbolHeight: 0.1,
    // symbolPadding: 0,
    // symbolRadius: 0,
    // symbolWidth: 0.1,
    labelFormatter: function () {
      const _this = this as unknown as { name: string; y: string; color: string };

      return (
        "<div style='display: flex; flex-direction: row; gap: 2px; width: 300px; opacity: 0.5;'>" +
        "<div style=' width: 70%; overflow: hidden; flex: 1; display: flex; flex-direction: row; justify-content: flex-start; gap: 10px; font-weight: normal; font-family: Work Sans, sans-serif;'>" +
        // '<span style="font-size: 24px; color:' +
        // _this.color +
        // '">\u25CF</span>' +
        _this.name +
        '</div>' +
        "<div style=''>" +
        _this.y +
        '%' +
        '</div>' +
        '</div>'
      );
    },
  };
  const options1 = {
    title: '',
    credits: false,
    chart: {
      type: 'pie',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        dataLabels: { enabled: false },
        showInLegend: true,
      },
    },
    legend: legendOptions,
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        '<b>{point.y}</b><br/>',
    },
    series: [
      {
        size: '100%',
        innerSize: '40%',
        data: ratingAllocation?.length
          ? ratingAllocation?.map((each: any) => {
            return {
              name: each?.Rating,
              y: each?.HoldPercent,
            };
          })
          : [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
            chartOptions: {
              series: [
                {},
                {
                  dataLabels: {
                    enabled: false,
                  },
                },
              ],
            },
          },
        },
      ],
    },
  };
  const options2 = {
    title: '',
    credits: false,
    chart: {
      type: 'pie',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        dataLabels: { enabled: false },
        showInLegend: true,
      },
    },
    legend: legendOptions,
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        '<b>{point.y}</b><br/>',
    },
    series: [
      {
        size: '100%',
        innerSize: '40%',
        data: instrumentAllocation
          ? instrumentAllocation.map((each: any) => {
            return {
              name: each?.Description1,
              y: each?.HoldPercent,
            };
          })
          : [],
      },
    ],
    responsive: {
      rules: [
        {
          chartOptions: {
            series: [
              {},
              {
                dataLabels: {
                  enabled: false,
                },
              },
            ],
          },
          condition: {
            maxWidth: 400,
          },
        },
      ],
    },
  };
  const options3 = {
    title: '',
    credits: false,
    chart: {
      type: 'pie',
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ['50%', '50%'],
        dataLabels: { enabled: false },
        showInLegend: true,
      },
    },
    legend: legendOptions,
    tooltip: {
      headerFormat: '',
      pointFormat:
        '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        '<b>{point.y}</b><br/>',
    },
    series: [
      {
        size: '100%',
        innerSize: '40%',
        data: maturityAllocation
          ? maturityAllocation.map((each: any) => {
            return {
              name: each.MaturityType,
              y: each.HoldPercentage,
            };
          })
          : [],
      },
    ],
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 400,
            chartOptions: {
              series: [
                {},
                {
                  dataLabels: {
                    enabled: false,
                  },
                },
              ],
            },
          },
        },
      ],
    },
  };
  // Graph refs
  const issueCategoryRef = useRef(null);
  const creditRatingRef = useRef(null);
  const instrumentAllocationRef = useRef(null);
  const avgMaturityAllocationRef = useRef(null);
  const amcAllocationRef = useRef(null);
  const topData = debtData?.['TOP Allocation'] && debtData['TOP Allocation'][0];
  const [issuerCategoryView, setIssuerCategoryView] = useState(false);
  const [amcAllocationView, setAMCAllocationView] = useState(false);
  const issuerCategoryData = debtData?.['Issuer Category Allocation'];
  const issuerCategoryDataDisplay =
    issuerCategoryData &&
    (issuerCategoryView ? issuerCategoryData : issuerCategoryData.slice(0, 6));
  const amcAllocationData = debtData?.['AMC wise Allocation'];
  const amcAllocationDataDisplay =
    amcAllocationData && (amcAllocationView ? amcAllocationData : amcAllocationData.slice(0, 5));

  const headers = (): ColumnType[] => {
    return [
      {
        header: 'Scheme',
        sortingKey: 'schemename',
        valueGetter: (row: ProductDebtTop10Entity) => row?.schemename || 'N/A',
      },
      investorId
        ? {
          header: 'XIRR (%)',
          sortingKey: 'Xirr',
          valueGetter: (row: ProductDebtTop10Entity) =>
            row?.Xirr || row?.Xirr === 0 ? row?.Xirr : 'N/A',
        }
        : {
          header: 'Sector',
          sortingKey: 'sector',
          valueGetter: (row: ProductDebtTop10Entity) => row?.sector || 'N/A',
        },
      {
        header: 'Amount',
        sortingKey: 'AUM',
        valueGetter: (row: ProductDebtTop10Entity, amountUnit?: number | null | undefined) =>
          (row?.AUM && formatToIndianCurrency(row?.AUM, amountUnit)) || 'N/A',
      },
      {
        header: 'Exposure (%)',
        sortingKey: 'Hper',
        valueGetter: (row: ProductDebtTop10Entity) => row.Hper || 'N/A',
      },
    ];
  };


  const IssuerCategoryTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Sector',
        sortingKey: 'industryname',
        valueGetter: (row: ProductDebtIssuerCategoryAllocationEntity) => row?.industryname,
      },
      {
        header: 'Market Value',
        sortingKey: 'AUMper',
        valueGetter: (row: ProductDebtIssuerCategoryAllocationEntity, amountUnit?: number | null | undefined) => row?.AUMper || row?.AUMper === 0 ? `${formatToIndianCurrency(row?.AUMper, amountUnit)}` : 'N/A',
      },
      {
        header: 'Allocation %',
        sortingKey: 'Hper',
        valueGetter: (row: ProductDebtIssuerCategoryAllocationEntity) => row?.Hper,
      },
    ];
  }
  const AmcAllocationDebtTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'AMC',
        sortingKey: 'AMCName',
        valueGetter: (row: ProductDebtAmcWiseAllocationEntity) => row?.AMCName,
      },
      {
        header: 'AUM',
        sortingKey: 'AUM',
        valueGetter: (row: ProductDebtAmcWiseAllocationEntity, amountUnit?: number | null | undefined) => row?.AUM || row?.AUM === 0 ? `${formatToIndianCurrency(row?.AUM, amountUnit)}` : 'N/A',
      },
      {
        header: 'AUM Allocation %',
        sortingKey: 'HoldPercent',
        valueGetter: (row: ProductDebtAmcWiseAllocationEntity) => row?.HoldPercent,
      },
    ];
  }
  return (
    <Box>
      <Box
        sx={{
          mb: 3
        }}>
        <AmountUnit />
        <Button
          onClick={() => window.print()}

          sx={{
            marginLeft: '20px',
            height: '27px',
            color: 'white',
            fontSize: '13px',
            cursor: 'pointer',
            borderRadius: '5px',
            bgcolor: '#4990F0',
            '&:hover': { backgroundColor: '#639FF0' },
          }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>
      </Box>
      { !debtData?.['AMC wise Allocation'] ||debtData?.['AMC wise Allocation'].length === 0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem', fontSize: '18px', fontWeight: 500, color: '#666', background: 'white', boxShadow: '10rem', marginTop: '4rem' }}>No portfolio details available.</div> :
        <Box>
          {investorId && (
            <ProductTopSection
              isLoading={isLoading}
              investedValue={topData?.PurVal}
              marketValue={topData?.MktVal}
              gainLoss={topData?.GainOrloss}
              xirr={investorId ? topData?.Xirr : null}
            />
          )}
          <Box
            ref={issueCategoryRef}
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              mb: 7,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
              }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: 600,
                }}>
                Issuer Category Allocation
              </Typography>
              {issuerCategoryData && issuerCategoryData?.length > 6 && (
                <Box>
                  <Button
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      color: '#4E5056',
                      fontWeight: 500,
                      padding: '4px 22px',
                      boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      border: '1px solid #0BAAE7',
                      borderRadius: '5px',
                    }}
                    onClick={() => setIssuerCategoryView(!issuerCategoryView)}>
                    {issuerCategoryView ? 'View Less' : ' View all'}
                  </Button>
                  {/* <CopyGraphDropdown elementRef={issueCategoryRef} /> */}
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <FlipIcon
                onClick={() => (setFlipIssuerCategoryAllocation(!flipIssuerCategoryAllocation))}
                style={{
                  height: '20px',
                  cursor: "pointer"
                }}
              />
            </Box>
            <ReactCardFlip isFlipped={flipIssuerCategoryAllocation}
              flipDirection="horizontal">
              <div
                style={{
                  backgroundColor: 'white',
                  display: `${flipIssuerCategoryAllocation ? 'none' : 'block'}`
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    height: '430px',
                    overflow: 'auto',
                    ...scrollbarView,
                  }}>
                  <Box>
                    <Box
                      sx={{
                        py: 3,
                        px: 0,
                        pb: 0.2,
                        position: 'relative',
                      }}>
                      <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                      {issuerCategoryDataDisplay
                        ? issuerCategoryDataDisplay.map((ele: any, index: number) => {
                          const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                          return (
                            <RevenuePercent
                              key={index}
                              label={ele.industryname}
                              percent={ele.Hper}
                              amount={`${ele?.Hper}%`}
                              color={colors[index % 3]}
                              isLoading={isLoading}
                              popOverAmt={ele.AUMper}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })
                        : [...Array(5)].map((ele: any, index: number) => {
                          return (
                            <RevenuePercent
                              key={index}
                              label=""
                              percent={0}
                              amount=""
                              color=""
                              isLoading={true}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  maxHeight: '430px',
                  display: `${flipIssuerCategoryAllocation ? 'block' : 'none'}`
                }}>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={[...IssuerCategoryTableHeaders()]}
                  tableData={debtData?.['Issuer Category Allocation'] && debtData?.['Issuer Category Allocation']}

                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },

                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: '500px', minWidth: 450 }}
                />
              </div>
            </ReactCardFlip>
          </Box>
          <Grid container spacing={2} mb={7}>
            <Grid item xs={12} md={6}>
              <Box
                ref={creditRatingRef}
                sx={{
                  background: ' #FFFFFF',
                  boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                  borderRadius: '5px',
                  mb: 7,
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 1,
                  }}>
                  <Typography
                    sx={{
                      color: '#22242C',
                      opacity: 0.8,
                      fontSize: 16,
                      lineHeight: '19px',
                      fontWeight: 600,
                    }}>
                    Credit Rating Allocation
                  </Typography>
                  {/* <CopyGraphDropdown elementRef={creditRatingRef} /> */}
                </Box>
                <Divider />
                <Box>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Skeleton
                        sx={{
                          width: ['50%', '50%', '50%', '40%', '30%'],
                          height: '300px',
                          borderRadius: '50%',
                        }}
                      />
                    </Box>
                  ) : (
                    <HighchartsReact highcharts={Highcharts} options={options1} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                ref={instrumentAllocationRef}
                sx={{
                  background: ' #FFFFFF',
                  boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                  borderRadius: '5px',
                  mb: 7,
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 1,
                  }}>
                  <Typography
                    sx={{
                      color: '#22242C',
                      opacity: 0.8,
                      fontSize: 16,
                      lineHeight: '19px',
                      fontWeight: 600,
                    }}>
                    Instrument Allocation
                  </Typography>
                  {/* <CopyGraphDropdown elementRef={instrumentAllocationRef} /> */}
                </Box>
                <Divider />
                <Box>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Skeleton
                        sx={{
                          width: ['50%', '50%', '50%', '40%', '30%'],
                          height: '300px',
                          borderRadius: '50%',
                        }}
                      />
                    </Box>
                  ) : (
                    <HighchartsReact highcharts={Highcharts} options={options2} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} >
              <Box
                ref={avgMaturityAllocationRef}
                sx={{
                  background: ' #FFFFFF',
                  boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                  borderRadius: '5px',
                  pageBreakBefore: 'always'
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 1,
                  }}>
                  <Typography
                    sx={{
                      color: '#22242C',
                      opacity: 0.8,
                      fontSize: 16,
                      lineHeight: '19px',
                      fontWeight: 600,
                    }}>
                    Average Maturity Allocation
                  </Typography>
                  {/* <CopyGraphDropdown elementRef={avgMaturityAllocationRef} /> */}
                </Box>
                <Divider />
                <Box>
                  {isLoading ? (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Skeleton
                        sx={{
                          width: ['50%', '50%', '50%', '40%', '30%'],
                          height: '300px',
                          borderRadius: '50%',
                        }}
                      />
                    </Box>
                  ) : (
                    <HighchartsReact highcharts={Highcharts} options={options3} />
                  )}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  background: '#FFFFFF',
                  boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                  borderRadius: '5px 5px 0 0',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 2,
                  }}>
                  <Typography
                    sx={{
                      color: '#4E5056',
                      fontSize: 16,
                      lineHeight: '24px',
                      fontWeight: 600,
                    }}>
                    Quantitative Analysis
                  </Typography>
                </Box>
                <Divider />
                {isLoading ? (
                  <Skeleton sx={{ width: '80%', height: '300px', margin: 'auto' }} />
                ) : (
                  <Box
                    sx={{
                      px: 4,
                      flexGrow: 1,
                      '& .MuiTableCell-root': {
                        py: 3,
                      },
                    }}>
                    {debtData && (
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Metric</TableCell>
                              <TableCell>Value</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell>Average Maturity</TableCell>
                              <TableCell>{debtQuants?.AvgMaturity}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Duration</TableCell>
                              <TableCell>{debtQuants?.Duration}</TableCell>
                            </TableRow>
                            {/* <TableRow>
                          <TableCell>Standard Deviation</TableCell>
                          <TableCell>{debtQuants?.StandardDeviation}</TableCell>
                        </TableRow> */}
                            <TableRow>
                              <TableCell>YTM</TableCell>
                              <TableCell>{debtQuants?.YTM}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
          <Box
            ref={amcAllocationRef}
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              mb: 7,
              pageBreakBefore: 'always'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
              }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: 600,
                }}>
                AMC Allocation
              </Typography>
              {amcAllocationData && amcAllocationData?.length > 5 && (
                <Box>
                  <Button
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      color: '#4E5056',
                      fontWeight: 500,
                      padding: '4px 22px',
                      boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      border: '1px solid #0BAAE7',
                      borderRadius: '5px',
                    }}
                    onClick={() => setAMCAllocationView(!amcAllocationView)}>
                    {amcAllocationView ? 'View Less' : ' View all'}
                  </Button>
                  {/* <CopyGraphDropdown elementRef={amcAllocationRef} /> */}
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <FlipIcon
                onClick={() => (setFlipAmcAllocationDebt(!flipAmcAllocationDebt))}
                style={{
                  height: '20px',
                  cursor: "pointer"
                }}
              />
            </Box>
            <ReactCardFlip isFlipped={flipAmcAllocationDebt}
              flipDirection="horizontal">
              <div
                style={{
                  backgroundColor: 'white',
                  display: `${flipAmcAllocationDebt ? 'none' : 'block'}`
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    height: '470px',
                    overflow: 'auto',
                    ...scrollbarView,
                  }}>
                  <Box>
                    <Box
                      sx={{
                        py: 3,
                        px: 0,
                        pb: 0.2,
                        position: 'relative',
                      }}>
                      <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                      {amcAllocationDataDisplay
                        ? amcAllocationDataDisplay.map((ele: any, index: number) => {
                          const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                          return (
                            <RevenuePercent
                              key={index}
                              label={ele.AMCName}
                              percent={ele.HoldPercent}
                              amount={`${ele?.HoldPercent}%`}
                              color={colors[index % 3]}
                              isLoading={isLoading}
                              popOverAmt={ele.AUM}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })
                        : [...Array(5)].map((ele: any, index: number) => {
                          return (
                            <RevenuePercent
                              key={index}
                              label=""
                              percent={0}
                              amount=""
                              color=""
                              isLoading={true}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  maxHeight: '480px',
                  display: `${flipAmcAllocationDebt ? 'block' : 'none'}`
                }}>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={[...AmcAllocationDebtTableHeaders()]}
                  tableData={debtData?.['AMC wise Allocation'] && debtData?.['AMC wise Allocation']}

                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },

                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: '500px', minWidth: 450 }}
                />
              </div>
            </ReactCardFlip>
          </Box>
          <Box
            sx={{
              mb: 7,
              pageBreakBefore: 'always'
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
                background: '#FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
              }}>
              <Typography
                sx={{
                  color: '#4E5056',
                  fontSize: 16,
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                Top 10 Schemes
              </Typography>
            </Box>
            <Divider />
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                {debtData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={[...headers()]}
                      tableData={debtData?.top10}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                        background: 'transparent',
                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: 'unset', maxHeight: 'unset', minWidth: 450 }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Box>}
    </Box>

  );
}

import { ArrowDropDown, Edit, InfoOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grow,
  Menu,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import { Fragment, useState } from 'react';
import {
  DirectEquityColumn,
  DirectEquitySchemeColumnMap,
} from '../../../../redux-store/types/api-types';

const EditTableHeader = ({
  headers = [],
  selectedHeaders = [],
  setSelectedHeaders,
}: {
  headers?: DirectEquitySchemeColumnMap[];
  selectedHeaders?: string[];
  setSelectedHeaders: (headers: string[]) => void;
}): JSX.Element => {
  const [selectedHeaderCategory, setSelectedHeaderCategory] = useState<number | string>('dummy1');
  const [selectedLocalHeaders, setSelectedLocalHeaders] = useState<string[]>(selectedHeaders);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const menuOpen = Boolean(menuAnchorEl);
  const [tooltipPopperAnchorEl, setTooltipPopperAnchorEl] = useState<
    SVGSVGElement | HTMLElement | null
  >(null);
  const tooltipPopperOpen = Boolean(tooltipPopperAnchorEl);
  // const headerCategories = [{ title: 'Columns', key: 'dummy1', options: headers ? headers : [] }];

  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}>
        <InfoOutlined
          sx={{ color: '#A59C9C' }}
          onMouseEnter={(e) => setTooltipPopperAnchorEl(e.currentTarget)}
          onMouseLeave={() => setTooltipPopperAnchorEl(null)}
        />
        <Button
          startIcon={<Edit sx={{ width: '12px' }} />}
          onClick={(e) => setMenuAnchorEl(e.currentTarget)}
          sx={{
            color: '#4E5056',
            padding: '0px 8px',
            fontSize: '14px',
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
            border: '1px solid #0BAAE7',
            borderRadius: '5px',
            fontWeight: 500,
          }}>
          Edit
        </Button>
      </Box>
      <Popper
        open={tooltipPopperOpen}
        anchorEl={tooltipPopperAnchorEl}
        placement="top-start"
        transition
        style={{
          zIndex: 100,
        }}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={400}>
            <Paper
              sx={{
                boxShadow:
                  'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',
              }}>
              <Typography
                sx={{
                  p: 1,
                  color: '#6b6b6b',
                  fontSize: '10px',
                  fontStyle: 'italic',
                }}>
                Stock data is as per NSE
              </Typography>
            </Paper>
          </Grow>
        )}
      </Popper>
      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpen}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2, minWidth: '400px' }}>
          {headers.map(({ name, columns }: DirectEquitySchemeColumnMap, index: number) => (
            <Box key={`${name}_${index}`} sx={{ width: '100%' }}>
              <Box>
                <Box
                  onClick={() => {
                    setSelectedHeaderCategory(selectedHeaderCategory === name ? -1 : name);
                  }}
                  sx={{
                    width: '100%',
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}>
                  <Typography>{name}</Typography>
                  <ArrowDropDown sx={{ color: '#337FC9' }} />
                </Box>
                <Divider />
              </Box>
              {selectedHeaderCategory === name && (
                <Box
                  sx={{
                    pt: 1,
                    px: 2,
                    maxHeight: '250px',
                    transition: '0.3s all',
                    overflow: 'auto',
                  }}>
                  <FormGroup>
                    {columns.map((column: DirectEquityColumn) => {
                      const label = column.name,
                        value = column.name;

                      return label && value ? (
                        <Fragment key={`${value}_${index}`}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedLocalHeaders.includes(value)}
                                onChange={(_, checked) => {
                                  //
                                  let updatedFilters = [...selectedLocalHeaders];
                                  if (checked) {
                                    if (!updatedFilters.includes(value)) {
                                      updatedFilters.push(value);
                                    }
                                  } else {
                                    updatedFilters = updatedFilters.filter((val) => val !== value);
                                  }
                                  setSelectedLocalHeaders(updatedFilters);
                                }}
                                checkedIcon={
                                  <Box
                                    sx={{
                                      border: '2px solid #22242C',
                                      margin: '2px',
                                      borderRadius: '2px',
                                      backgroundColor: '#337FC9',
                                      '&:before': {
                                        display: 'block',
                                        width: 16,
                                        height: 16,
                                        content: '""',
                                      },
                                    }}></Box>
                                }
                              />
                            }
                            label={label}
                          />
                          <Divider />
                        </Fragment>
                      ) : null;
                    })}
                  </FormGroup>
                </Box>
              )}
            </Box>
          ))}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
            <Button
              onClick={() => {
                setSelectedHeaders(selectedLocalHeaders);
                handleClose();
              }}
              sx={{
                bgcolor: '#337FC9',
                borderRadius: '5px',
                fontSize: 17,
                fontWeight: 500,
                p: '0px 22px',
                '&:hover': { bgcolor: '#337FC9' },
              }}>
              Apply
            </Button>
            <Button
              onClick={() => {
                setSelectedHeaders([]);
                handleClose();
              }}
              sx={{
                border: '1px solid #337FC9',
                borderRadius: '5px',
                padding: 0,
                px: 1,
                color: '#696F74',
                fontSize: 17,
                fontWeight: 500,
                '&:hover': {
                  bgcolor: '#fff',
                },
              }}>
              Clear All
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
};

export default EditTableHeader;

import { Box, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Stack, Typography, RadioGroup } from '@mui/material';
import { Form, Formik, useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { BpRadio, ReportSectionContainer, ReportsRightLayout } from '../../InvestorReport/index';
import { getDashboardReports, getInvestorReports } from '../../../redux-store/actions/investorReports';
import { downloadMultipleFiles, formatDate, replaceLetterOWithW, } from '../../../utils/utilityFunctions';
import { TextDatePicker } from '../../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../../lib/formik';
import { validationSchemaPortfolioSummary } from '../../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import FormMultiSelect from '../../../lib/formik/FormMultiSelect';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { margin } from '@mui/system';
import { RmDashboardType } from '../../../redux-store/types/api-types';
import { getRMDashBoardMetrics } from '../../../redux-store/actions/userManagement';
import Swal from 'sweetalert2';

export function TransactionSummaryReport() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    //   const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
    const history = useHistory();
    const currentDate = dayjs();
    const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
    const [dates1, setDates1] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
    const [loading, setLoading] = useState<boolean>(false);
    const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);


    useEffect(() => {
        let isComponentActive = true;
        setDashBoardMetricsData(null);
        (async function () {
            try {
                // setLoading(true);
                const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
                if (!isComponentActive) {
                    return;
                }
                setDashBoardMetricsData(response);
                // setLoading(false);
            } catch (e) {
                // setLoading(false);
                console.log((e as Error).message);
            }
        })();
        return () => {
            isComponentActive = false;
        };

    }, []);

    const onSubmit = async (date: any, date1: any, ClientCode: any, setSubmitting: (isSubmitting: boolean) => void) => {
        setDates(date);
        setDates1(date1)
        try {
            const formattedDate1 = `${new Date(date1).getDate().toString().padStart(2, '0')}/${(new Date(date1)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(date1).getFullYear()}`;
            const formattedDate = `${new Date(date).getDate().toString().padStart(2, '0')}/${(new Date(date)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getFullYear()}`;

            if (!ClientCode) {
                Swal.fire({
                    confirmButtonText: 'OK',

                    icon: 'info',
                    confirmButtonColor: 'rgb(32, 87, 166)',
                    html: '<p>Please select Investor!   </p>',
                    backdrop: false,
                    focusCancel: true,
                    allowEscapeKey: true,
                });
            }
            else {
                setSubmitting(true);
                setLoading(true);
                const res = await dispatch(
                    getDashboardReports({
                        reportName: 'TransactionSummaryReport',
                        clientCode: [ClientCode],
                        fromDate: formattedDate,
                        toDate: formattedDate1,
                    })
                );
                setLoading(false)
                downloadMultipleFiles(res);

                formik.setFieldValue('selectedInvestor', { ClientId: "", clientName: "" });
                enqueueSnackbar('File(s) downloaded successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000,
                });
                setDates(dayjs(currentDate).format("YYYY-MM-DD"));
                setDates1(dayjs(currentDate).format("YYYY-MM-DD"));
            }
        }
        catch (e) {
            setLoading(false);
            console.error((e as Error).message);
        } finally {
            setSubmitting(false);
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const initialValues = {
        selectedInvestor: { ClientId: "", clientName: "" },
        dates: new Date(),
    };

    const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        onSubmit(values.dates, values.dates1, formik.values.selectedInvestor.ClientId, setSubmitting);
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    return (
        <ReportsRightLayout>
            <Typography
                sx={{
                    color: 'text.secondary',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '12px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    display: ['none', 'none', 'block'],
                }}>
                Transaction Summary Report
            </Typography>
            <Formik
                validationSchema={validationSchemaPortfolioSummary}
                initialValues={{
                    date: new Date(),
                    date1: new Date(),
                }}
                onSubmit={() => { onSubmit(dates, dates1, formik.values.selectedInvestor.ClientId, () => { (true) }) }}>
                {({ values, setFieldValue, errors }) => (
                    <Form>
                        <ReportSectionContainer>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        letterSpacing: '0.01em',
                                    }}>
                                    Select Investor(s)
                                </Typography>
                                <Select
                                    name={'reportOptions'}
                                    label="Select Investor"
                                    MenuProps={MenuProps}
                                    value={formik.values.selectedInvestor.ClientId || ''}
                                    onChange={(e) => {
                                        const selectedInvestor = dashBoardMetricsData?.investorDetail?.find(
                                            (investor: any) => investor.OClientID === e.target.value
                                        );
                                        const data = {
                                            ClientId: selectedInvestor?.OClientID,
                                            clientName: selectedInvestor?.I_InvName
                                        };
                                        formik.setFieldValue('selectedInvestor', data);
                                    }}
                                >
                                    {dashBoardMetricsData?.investorDetail?.map((i: any, index: any) => (
                                        <MenuItem value={i.OClientID} key={index}>
                                            {i.I_InvName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>

                            <Stack sx={{ marginTop: "20px" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        label="From Date"
                                        format="DD-MM-YYYY"
                                        value={dayjs(dates)}
                                        maxDate={dayjs(currentDate)}
                                        onChange={(date: any) =>
                                            setDates(date.format("YYYY-MM-DD"))
                                        }
                                    />
                                </LocalizationProvider>
                            </Stack>
                            <Stack
                                sx={{
                                    marginTop: "20px"
                                }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        label="To Date"
                                        format="DD-MM-YYYY"
                                        value={dayjs(dates1)}
                                        maxDate={dayjs(currentDate)}
                                        onChange={(date: any) =>
                                            setDates1(date.format("YYYY-MM-DD"))
                                        }
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </ReportSectionContainer>

                        <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
                            <MFSubmitButton
                                label="Download"
                                loadingEnable={loading}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </ReportsRightLayout>
    );
};



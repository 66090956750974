import { Box, Grid, Typography } from '@mui/material';
import { KeyValue } from './personalDetails';
import React from 'react';
import { Gridstyles } from './index';
import { Applicant, NomineeType } from '../../redux-store/types/api-types';
import { formatShortDate } from '../../utils/date';
import { SubHeading } from '../investors/components';

export default function RelatedPersonDetails({
  applicants = [],
}: {
  applicants: Partial<Applicant>[];
}): JSX.Element {
  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
              Related Person Details
            </Typography>
            {applicants.map((applicant, index: number) => {
              const title = [
                'First Applicant Details',
                'Second Applicant Details',
                'Third Applicant Details',
              ][index];
              return (
                <React.Fragment key={applicant.id}>
                  <SubHeading sx={{ letterSpacing: 0.5 + 'px' }}>{title}</SubHeading>
                  {applicant.relatedpersons?.length === 0 ? (
                    <Grid
                      xs={12}
                      sm={12}
                      sx={{
                        fontSize: 18,
                        fontWeight: 600,
                        color: 'common.black',
                        textAlign: 'center',
                        // background: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        my: 3,
                      }}>
                      No Related Persons
                    </Grid>
                  ) : (
                    applicant.relatedpersons?.map((related, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Grid item xs={12} sm={12} sx={{ ml: 5 }}>
                            <Typography
                              sx={{
                                color: '#2057a6',
                                fontSize: '14px',
                                fontWeight: 550,
                                mt: 1,
                              }}>
                              {`Related Person ${index + 1}`}
                            </Typography>
                          </Grid>
                          <Gridstyles>
                            <Grid
                              item
                              container
                              pt={2}
                              pb={5}
                              rowSpacing={6}
                              columnSpacing={13}
                              direction="row"
                              justifyContent="space-between"
                              alignItems="flex-start">
                              <KeyValue
                                title={`Full Name`}
                                description={related.name}
                                sx={{ mt: 0 }}
                              />
                              <KeyValue
                                title={'Relationship Type'}
                                description={
                                  related.relationshipType !== 'OTHERS'
                                    ? related.relationshipType
                                    : related.relationshipTypeOther
                                }
                              />
                              <KeyValue
                                title={'Date of Birth'}
                                description={formatShortDate(related.dateOfBirth)}
                              />
                              <KeyValue title={'PAN'} description={related.panNo} />
                            </Grid>
                          </Gridstyles>
                        </React.Fragment>
                      );
                    })
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </>
  );
}

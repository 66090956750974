import {
  Applicant,
  ApplicantAddressType,
  ApplicationProps,
  JointholderRelationMaster,
} from '../redux-store/types/api-types';
import {
  applicantStatusMasters,
  APPLICATION_LISTING_STATUS,
  APPLICATION_TYPE,
  BloodRelations,
  BseClientActivationStatusMaster,
  onboardingTypeMasters,
  RoleTypes,
  SALT,
  SALT2,
  USER_ROLES,
} from './constant';
import { ToWords } from 'to-words';
import { numberRegex } from './regex';
import _, { each } from 'lodash';
import { RelatedPersonError } from '../redux-store/middleware';
import { updatedRiskProfile } from '../components/investors/riskProfileDetails';
import * as htmlToImage from 'html-to-image';
import { MutableRefObject } from 'react';

export const maskMobileNumber = (mobileNumber: string): string => {
  if (!mobileNumber) {
    return '';
  }
  return (
    mobileNumber.slice(0, 2) +
    mobileNumber.slice(2, mobileNumber.length - 2).replace(/\d/g, '*') +
    mobileNumber.slice(mobileNumber.length - 2)
  );
};

export const getApplicantName = (index: number, isNomineeOrBank?: boolean): string => {
  if (index === 1) {
    return isNomineeOrBank ? 'First' : 'First/Sole';
  } else if (index === 2) {
    return 'Second';
  } else {
    return 'Third';
  }
};

export const minDateForContributor = (): Date => {
  const date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const getFirstHolderName = (applicants: Partial<Applicant>[]): string => {
  return (
    applicants.find((applicant) => ['firstapplicant', '1'].includes(applicant.applicant_type || ''))
      ?.name || 'N/A'
  );
};

export const getApplicantType = (applicantType: string | undefined): string => {
  if (applicantType) {
    if (['firstapplicant', '1'].includes(applicantType)) {
      return '1';
    } else if (['secondapplicant', '2'].includes(applicantType)) {
      return '2';
    } else {
      return '3';
    }
  }
  return '';
};

export function getEmailPhonePersonRelationType(relation?: string): boolean {
  return [
    'self',
    'spouse',
    'dependent_children',
    'dependent_parents',
    'depending_siblings',
    '',
  ].includes(relation as string);
}

export const formatToIndianCurrency = (value?: number | string | null, amountUnit?: number | null): string => {
  value = Number(value);
  amountUnit = amountUnit ? amountUnit : 0;
  const options = { style: 'currency', currency: 'INR' };
  if (value !== (undefined || null) && !isNaN(value)) {
    if (amountUnit == 0) {
      return new Intl.NumberFormat('en-IN', options).format(value);
    }
    else if (amountUnit == 1 && value >= 10000000) {
      value = value / 10000000;
      return new Intl.NumberFormat('en-IN', options).format(value) + ' Cr';
    }
    else {
      value = value / 100000;
      return new Intl.NumberFormat('en-IN', options).format(value) + ' Lakhs';
    }
  }
  return '';
};

export const formatDate_ = (inputDate: string): string => {
  const date = new Date(inputDate);
  const day = date.getDate();
  const monthNames = [
    'January', 'February', 'March',
    'April', 'May', 'June', 'July',
    'August', 'September', 'October',
    'November', 'December'
  ];
  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  const ordinalSuffix = (num: number): string => {
    const j = num % 10;
    const k = num % 100;
    if (j === 1 && k !== 11) {
      return num + 'st';
    }
    if (j === 2 && k !== 12) {
      return num + 'nd';
    }
    if (j === 3 && k !== 13) {
      return num + 'rd';
    }
    return num + 'th';
  };

  return `${ordinalSuffix(day)} ${monthNames[monthIndex]} ${year}`;
};

export const formatTime = (inputDateTime: string | Date): string => {
  const date = new Date(inputDateTime);
  date.setHours(date.getHours()-5);
  date.setMinutes(date.getMinutes()-30) ;
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const period = hours >= 12 ? 'pm' : 'am';
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${formattedHours}:${formattedMinutes} ${period}`;
};

export function toExactSubstr(str: string): string {
  const a = str.split('.');
  if (a.length == 2) {
    return a[0].concat('.', a[1].substr(0, 2));
  }
  return str;
}

export const downloadFile = async (response: any, userId: any, filename = '', fileType = 'pdf') => {
  console.log("response>>>   ", response, "filename>>", filename)

  const day = new Date().getDate().toString().padStart(2, '0');
  const month = (new Date().getMonth() + 1).toString().padStart(2, '0');
  const year = new Date().getFullYear().toString();
  const formattedDate = [day, month, year].join('');
  let blob;
  const link = document.createElement('a');
  if (fileType === 'pdf') {
    const byteArray = new Uint8Array(response);
    blob = new Blob([byteArray], { type: 'application/pdf' });
    link.href = window.URL.createObjectURL(blob);
    link.download =
      'Report_' +
      (filename ? filename + '_' : '') +
      new Date().toLocaleDateString('en-US') +
      '.pdf';
  } else if (fileType === 'xlsx') {
    const byteArray = new Uint8Array(response);
    blob = new Blob([byteArray], {
      type: 'application/vnd.ms-excel; charset=utf-8',
    });
    link.href = window.URL.createObjectURL(blob);
    link.download = userId + '_' + (filename ? filename + '_' : '') + formattedDate + '.xlsx';
  } else if (fileType === 'xls') {
    const byteArray = new Uint8Array(response);
    blob = new Blob([byteArray], {
      type: 'application/vnd.ms-excel; charset=utf-8',
    });
    link.href = window.URL.createObjectURL(blob);
    link.download = userId + '_' + (filename ? filename + '_' : '') + formattedDate + '.xls';
  }
  link.click();
  return response;
};

export const downloadMultipleFiles = (response: any) => {
  console.log("response>>", response)
  if (typeof response === 'string') response = JSON.parse(response);
  if (typeof response === 'object') {
    response.map((each: any) => {
      const userId = Object.keys(each)[0];
      return downloadFile(each[userId].data, userId, each.filename, each.fileType);
    });
  } else {
    const userId = Object.keys(each)[0]
    downloadFile(response, userId);
  }
};

export const formatDate = (date: any) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear());

  return `${day}/${month}/${year}`;
};

export const replaceLetterOWithW = (text: string) => {
  return text.split('o').join('W');
};

export const getFromToDates = (value: string): { fromDate: string; toDate: string } => {
  const today = new Date();
  switch (value) {
    case 'previous-fy':
      if (today.getMonth() + 1 <= 3) {
        return {
          fromDate: `01/04/${today.getFullYear() - 2}`,
          toDate: `31/03/${today.getFullYear() - 1}`,
        };
      } else {
        return {
          fromDate: `01/04/${today.getFullYear() - 1}`,
          toDate: `31/03/${today.getFullYear()}`,
        };
      }
    case 'current-fy':
      if (today.getMonth() + 1 <= 3) {
        return {
          fromDate: `01/04/${today.getFullYear() - 1}`,
          toDate: `31/03/${today.getFullYear()}`,
        };
      } else {
        return {
          fromDate: `01/04/${today.getFullYear()}`,
          toDate: `31/03/${today.getFullYear() + 1}`,
        };
      }
    default:
      return {
        fromDate: '',
        toDate: '',
      };
  }
};

export const formatCurrency = (value?: number | null): string => {
  const formats = ['K', 'L', 'Cr'];
  let amount: any = Number(value);
  formats.some((key, i) => {
    const size = Math.pow(10, i === 0 ? 3 : 3 - 1);
    if (size <= amount) {
      amount = toExactSubstr(`${amount / size}`);
      if (amount >= 100 && i < formats.length - 1) {
        i++;
      } else {
        // amount = (amount * decPlaces) / decPlaces;
        amount += ' ' + formats[i];
        return true;
      }
    } else {
      return true;
    }
  });
  return amount;
};

export const EIGHTEEN_YEARS_IN_MILLISECONDS = 18 * 31556926 * 1000; //  18 * no of seconds in a Year * 1000

export const isMinor = (dateOfBirth = ''): boolean => {
  return new Date().getTime() - new Date(dateOfBirth).getTime() < EIGHTEEN_YEARS_IN_MILLISECONDS;
};

// eslint-disable-next-line @typescript-eslint/no-var-requires
const countryCodes = require('country-codes-list');
export const getCountryCodes = (): { label: string; key: string; countryCode: string }[] => {
  const countryCodesObject: { [key: string]: string } = countryCodes.customList(
    'countryCode',
    '{countryNameEn}: +{countryCallingCode}'
  );

  const modifiedCodes = Object.keys(countryCodesObject).map((cc) => ({
    label: countryCodesObject[cc],
    key: countryCodesObject[cc].split(':')[1].trim(),
    countryCode: cc,
  }));
  return modifiedCodes;
};

export const getAddressFields = (addressType: string): ApplicantAddressType => {
  return {
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    country: addressType === 'overseas' ? '' : 'INDIA',
    pincode: '',
    permanentAddressSameAsCorresponding: false,
    address_type: addressType,
    isActive: true,
    fetchedFromKRA: null,
  };
};

export const getAddressData = (
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  return (
    addresses?.find((address) => address.address_type === addressType) ||
    getAddressFields(addressType)
  );
};

export function getRelation(relationValue?: string | null): boolean {
  return ['Mother', 'Father', 'Daughter', 'Son', 'Spouse', 'Brother', 'Sister', ''].includes(
    relationValue || ''
  );
}

export function getOccupationType(occupation?: string | null): boolean {
  return [
    'Agriculturist',
    'Business',
    'Doctor',
    'Housewife',
    'Professional',
    'Retired',
    'Service',
    'Student',
    '',
  ].includes(occupation || '');
}

export const saveForLater = (
  role: string,
  id?: string,
  applicant1ReferenceId?: string | null
): string => {
  return role === USER_ROLES.INVESTOR
    ? `/investment-details/${applicant1ReferenceId}/application-details`
    : role === USER_ROLES.POAAPPROVER || role === USER_ROLES.AMCAPPROVER
      ? `/application-details/${id}`
      : '/applications';
};

export const maxAge = (dateOfBirth = ''): boolean => {
  const thisYear = new Date().getFullYear();
  return thisYear - new Date(dateOfBirth).getFullYear() > 125;
};
export const futureAge = (dateOfBirth = ''): boolean => {
  return new Date(dateOfBirth) > new Date();
};

export function currencyConversion(num?: string | number | null): string | null {
  if (!num) return '';
  const toWords = new ToWords();
  const words = toWords.convert(Number(num), { currency: true });
  return words;
}

export function checkIfApplicationIsNonIndividual({ applicationType }: ApplicationProps): boolean {
  return applicationType === APPLICATION_TYPE.NON_INDIVIDUAL;
}

// eslint-disable-next-line
export const preventSpecialCharacters = (e: any) => {
  if (
    !((e.ctrlKey || e.metaKey) && e.keyCode == 67) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 86) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 88) &&
    !((e.ctrlKey || e.metaKey) && e.keyCode == 90) &&
    !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(e.key) &&
    !numberRegex.test(e.key)
  ) {
    e.preventDefault();
  }
};
export function allowOnlyNumbers(e: any): void {
  if (['-', '+', 'e', 'E'].includes(e.key)) {
    e.preventDefault();
  }
}
export const permanentAddressSameAsCorresponding = (
  updatedCorrespondingAddress: Partial<ApplicantAddressType>,
  addressType: string,
  addresses: Partial<ApplicantAddressType>[] | undefined
): Partial<ApplicantAddressType> => {
  // eslint-disable-next-line
  const { address1, address2, address3, city, pincode, state, country, ...rest } =
    updatedCorrespondingAddress;
  return {
    ...getAddressData(addressType, addresses),
    address1: address1,
    address2: address2,
    address3: address3,
    city: city,
    pincode: pincode,
    state: state,
    country: country,
    address_type: addressType,
  };
};
export const _updatedAddresses = (
  updatedAddresses: {
    nationality?: string | null | undefined;
    status?: string | null | undefined;
    permanent?: Partial<ApplicantAddressType> | undefined;
    correspondence?: Partial<ApplicantAddressType> | undefined;
    overseas?: Partial<ApplicantAddressType> | undefined;
    [key: string]: string | Partial<ApplicantAddressType> | null | undefined;
  },
  applicant: Partial<Applicant>
): Partial<ApplicantAddressType>[] => {
  const { nationality, status, ...addressProps } = updatedAddresses;
  return Object.keys(addressProps)
    .map((addressType) => {
      // if (applicant.nationality?.toLowerCase() === 'indian' && addressType === 'overseas') {
      //   return {};
      // }
      if (
        addressType === 'permanent' &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['overseas'],
          addressType,
          applicant.addresses
        );
      }
      if (
        addressType === 'permanent' &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding &&
        !addressProps['overseas']
      ) {
        return permanentAddressSameAsCorresponding(
          addressProps['correspondence'],
          addressType,
          applicant.addresses
        );
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['overseas']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['overseas'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      if (
        Object.keys(addressProps).length === 1 &&
        addressProps['correspondence']?.permanentAddressSameAsCorresponding
      ) {
        return [
          {
            ...permanentAddressSameAsCorresponding(
              addressProps['correspondence'],
              'permanent',
              applicant.addresses
            ),
          },
          {
            ...getAddressData(addressType, applicant.addresses),
            ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
            address_type: addressType,
          },
        ];
      }
      return {
        ...getAddressData(addressType, applicant.addresses),
        ...(addressProps[addressType] as unknown as Partial<ApplicantAddressType>),
        address_type: addressType,
      };
    })
    .flat()
    .filter((address) => Object.keys(address).length);
};
export const applyRoleBasedStatus = (role: any): boolean => {
  return [
    USER_ROLES.RM,
    USER_ROLES.SUBDISTRIBUTOR,
    USER_ROLES.DISTRIBUTOR,
    USER_ROLES.AMCAPPROVER,
  ].includes(role);
};

export const checkKraBasedOnStatus = (status: any): boolean => {
  return ![
    APPLICATION_LISTING_STATUS.invitationexpired,
    APPLICATION_LISTING_STATUS.signed,
    APPLICATION_LISTING_STATUS.completed,
    APPLICATION_LISTING_STATUS.rejected,
  ].includes(APPLICATION_LISTING_STATUS[status]);
};

export const applicationComparison = (
  existingApplication: Partial<ApplicationProps> | null,
  updatedApplication: Partial<ApplicationProps>
): boolean => {
  return _.isEqual(existingApplication, updatedApplication);
};

export const sendApplication_Nri = (application?: ApplicationProps | null): boolean | undefined => {
  return application?.applicants.map((applicant) => applicant.status).includes('NRI');
};

export const checkForCorrespondenceAddress = (
  nationality: string | null | undefined,
  status: string | null | undefined
): boolean => {
  return (
    nationality?.toLowerCase() === 'indian' &&
    applicantStatusMasters[status as string] === applicantStatusMasters.Individual
  );
};

export const setUpFeeCalculations = (
  commitmentAmount: number,
  setupFeePercentage: number
): { feeAmount: number; feeGst: number; totalSetupFee: number } => {
  const setupFeeAmount = (Number(commitmentAmount) * Number(setupFeePercentage)) / 100;
  const GstForSetupFee = (setupFeeAmount * 18) / 100;
  return {
    feeAmount: Number(setupFeeAmount),
    feeGst: Number(GstForSetupFee),
    totalSetupFee: Number(setupFeeAmount + GstForSetupFee),
  };
};

export const checkAddressField = (data?: string) => {
  if (data) {
    if (data.includes(' ,')) {
      return data.split(' ,').join(',') + ' ';
    }
    return data.includes(',') ? data + ' ' : data + ', ';
  }
  return '';
};

export const getBankAddress = (
  address1: string | undefined,
  address2: string | undefined,
  address3: string | undefined
): string => {
  return `${address2 ? checkAddressField(address1) || '' : address1 || '' || ''}${address3 ? checkAddressField(address2) || '' : address2 || ''
    }${address3 || ''}`;
};

export const numToAlpMonth = (numMonth: any, month: any) => {
  numMonth?.map((monthNum: any) => {
    if (monthNum === 1) {
      month.push('Jan');
    } else if (monthNum === 2) {
      month.push('Feb');
    } else if (monthNum === 3) {
      month.push('Mar');
    } else if (monthNum === 4) {
      month.push('Apr');
    } else if (monthNum === 5) {
      month.push('May');
    } else if (monthNum === 6) {
      month.push('Jun');
    } else if (monthNum === 7) {
      month.push('Jul');
    } else if (monthNum === 8) {
      month.push('Aug');
    } else if (monthNum === 9) {
      month.push('Sep');
    } else if (monthNum === 10) {
      month.push('Oct');
    } else if (monthNum === 11) {
      month.push('Nov');
    } else if (monthNum === 12) {
      month.push('Dec');
    }
  });
};

export const removeSingleQuote = (value: string | null | undefined): any => {
  if (value && value.includes("'")) return value.split("'").join('');
  else return value;
};

export function removeEqualOperator(value = ''): string {
  const lastTwoIndex = value.length - 2;
  let encodeString = value.slice(0, lastTwoIndex);
  const slicedStr = value.slice(lastTwoIndex, value.length);
  slicedStr.split('').forEach((char) => {
    if (char !== '=') {
      encodeString += char;
    }
  });
  return encodeString;
}

export function encodeBase64(value: string): string {
  const encodeValue = btoa(value);
  const encodeSubString = removeEqualOperator(encodeValue);
  const encodePayload = btoa(`${SALT}${encodeSubString}${SALT2}`);
  return encodePayload;
}

export function getNomineeRelation(relationValue?: string | null): boolean {
  return ['MOTHER', 'FATHER', 'DAUGHTER', 'SON', 'SPOUSE', 'BROTHER', 'SISTER', ''].includes(
    relationValue || ''
  );
}

export function updatedCommitmentAmount(
  minCommitmentAmount: number,
  applicants: Partial<Applicant>[],
  data: JointholderRelationMaster
): number | void {
  if (applicants.length === 2) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele)
      ?.toString();
    const relation = BloodRelations.includes(applicantRelation) ? applicantRelation : 'Others';
    const amount = data.singleJointHolder
      .map((jointHolder) => {
        if (jointHolder.jointHolder1RelationShip === relation) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
  if (applicants.length === 3) {
    const applicantRelation = applicants
      .map((applicant) => applicant.relationShipWithFirstApplicant)
      .filter((ele) => ele);
    const relation = applicantRelation.map((_relation) =>
      BloodRelations.includes(_relation as string) ? _relation : 'Others'
    );
    const amount = data.twoJointHolders
      .map((jointHolder) => {
        if (
          relation[applicants.length - 3] === jointHolder.jointHolder1RelationShip &&
          relation[applicants.length - 2] === jointHolder.jointHolder2RelationShip
        ) {
          return (minCommitmentAmount * jointHolder.minimumAmountPercentage) / 100;
        }
      })
      .filter((ele) => ele)
      .toString();
    return Number(amount);
  }
}

export const relatedPersonPanValidation = (applicants: Partial<Applicant>[]) => {
  applicants.map((applicant, ind) => {
    const { relatedpersons = [] } = applicant;
    const activeRelatedPersons = relatedpersons.filter((_item) => _item.isActive);
    if (activeRelatedPersons.length) {
      activeRelatedPersons.map((person, index) => {
        if (
          person.panNo &&
          activeRelatedPersons.filter((relatedPerson) => relatedPerson.panNo === person.panNo)
            .length !== 1
        ) {
          throw new RelatedPersonError(
            `Duplicate PAN Number found in Details of Related Person of ${getApplicantName(
              ind + 1
            )} Applicant`
          );
        }
        if (person.panNo === applicant.panNo) {
          throw new RelatedPersonError(
            `Applicant PAN not allowed to enter as Related Person PAN in Details of Related Person of ${getApplicantName(
              ind + 1
            )} Applicant`
          );
        }
      });
    }
  });
};

export const changeGoalVal = (
  ans: string,
  riskProfileData: updatedRiskProfile[],
  question: string
) => {
  const ansArray = ans.split('*').map((val) => {
    const index = val.indexOf('_');
    const result = val.substring(0, index);
    return result;
  });
  const valueArray = riskProfileData.find((profile) => profile.question === question)?.values;
  const labelsArray = ansArray.map((key) => {
    const item = valueArray?.find((val) => val.key === key);
    return item ? `${item.label}` : null;
  });
  return labelsArray;
};

export const getAge = (Dob: string | null | undefined) => {
  let age;
  if (Dob) {
    const birthDate = new Date(Dob);
    const currentDate = new Date();
    const difference = currentDate.getTime() - birthDate.getTime();
    age = difference / (1000 * 60 * 60 * 24 * 365.25);
  }
  return age;
};

export const getRiskAgeValue = (age: number | undefined) => {
  if (age) {
    age = Math.round(age);
    if (age >= 0 && age <= 35) {
      return 'between0to35years';
    } else if (age >= 36 && age <= 45) {
      return 'between36to45years';
    } else if (age >= 46 && age <= 60) {
      return 'between46to60years';
    } else if (age >= 61 && age <= 70) {
      return 'between61to70years';
    } else {
      return 'morethan70years';
    }
  }
  return '';
};

export const getScore = (key: string) => {
  if (key === 'between0to35years') {
    return '50';
  } else if (key === 'between36to45years') {
    return '40';
  } else if (key === 'between46to60years') {
    return '30';
  } else if (key === 'between61to70years') {
    return '20';
  } else if (key === 'morethan70years') {
    return '10';
  }
  return '';
};

export function applicationDownloadStatusCheck(status: string): boolean {
  return APPLICATION_LISTING_STATUS[status] !== APPLICATION_LISTING_STATUS.completed;
}

export const isOnboardingTypeMinor = (onBoardingType: string): boolean => {
  if (onboardingTypeMasters[onBoardingType] === onboardingTypeMasters.minor) {
    return true;
  } else {
    return false;
  }
};

export const getBseClientActivationStatus = (bseClientActivationStatus: string): boolean => {
  return (
    BseClientActivationStatusMaster[bseClientActivationStatus || ''] ===
    BseClientActivationStatusMaster.pending
  );
};

export const copyGraphToClipboard = async (
  graphRef: MutableRefObject<HTMLElement>
): Promise<void> => {
  const divElement = graphRef.current;
  const _window = window as any;
  try {
    const dataUrl = await htmlToImage.toPng(divElement, {
      height: divElement.scrollHeight,
      style: {
        backgroundColor: 'white',
        transform: `translateY(-${divElement.scrollTop}px)`,
      },
    });

    const blob = await fetch(dataUrl).then((res) => res.blob());
    if (navigator.clipboard && navigator.clipboard.write) {
      const item = new ClipboardItem({ [blob.type]: Promise.resolve(blob) });
      const clipboardItems = [item];
      const writePromises = clipboardItems.map((clipboardItem) =>
        navigator.clipboard.write([clipboardItem])
      );
      await Promise.all(writePromises);
      console.log('Image copied to clipboard');
      return Promise.resolve();
    } else {
      // console.error('Clipboard write API not available');
      const imgData = dataUrl.replace(/^data:image\/(png|jpg);base64,/, '');
      const clipboardData = _window.clipboardData || new DataTransfer();
      clipboardData.setData('text/plain', '');
      clipboardData.setData('text/html', divElement.outerHTML);
      clipboardData.setData('image/png', imgData);
      _window.clipboardData ? _window.clipboardData.setData('Text', divElement.outerHTML) : null;
      document.execCommand('copy');
      console.log('Image copied to clipboard');
      return Promise.resolve();
    }
  } catch (error) {
    console.error('Failed to copy image: ', error);
    return Promise.reject();
  }
};

export function capitalize(str: string): string {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const rmRoleTypeService = (roleType: string): boolean => {
  return RoleTypes[roleType] === RoleTypes.support_rm;
};

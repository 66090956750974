import { Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReportSectionContainer, ReportsRightLayout } from './index';
import { getInvestorReports } from '../../redux-store/actions/investorReports';
import { useEffect, useState } from 'react';
import {
  downloadMultipleFiles,
  formatDate,
  replaceLetterOWithW,
} from '../../utils/utilityFunctions';
import { MFSubmitButton } from '../../lib/formik';
import { validationSchemaPortfolioSummary } from '../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

const ComprehensivePortfolio = ({
  location
}: {
  location: Location<{ investorId: string; investorName: string }>
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const currentDate = dayjs();
  const [loading, setLoading] = useState<boolean>(false);
  const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  console.log("investorId",investorId)
  const history = useHistory();

  const onSubmit = async (values: any) => {
    const { date } = values;
    const formattedDate = `${new Date(date).getDate().toString().padStart(2, '0')}/${(new Date(date)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getFullYear()}`;
    try {
      if (investorId) {
        setLoading(true)
        const res = await dispatch(
          getInvestorReports({
            reportName: 'ComprehensivePortfolio',
            ClientCode: investorId,
            ToDate: formattedDate,
          })
        );
        setLoading(false)
        downloadMultipleFiles([res]);
        enqueueSnackbar('File(s) downloaded successfully.', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      setDates(dayjs(currentDate).format("YYYY-MM-DD"));
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <ReportsRightLayout>
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '19px',
          letterSpacing: '0.06em',
          textTransform: 'uppercase',
          display: ['none', 'none', 'block'],
        }}>
        Comprehensive Portfolio
      </Typography>
      <Formik
        validationSchema={validationSchemaPortfolioSummary}
        initialValues={{
          date: new Date(),
          fileType: 'pdf',
        }}
        onSubmit={onSubmit}>
        {({ values, setFieldValue, errors }) => (
          <Form>

            <ReportSectionContainer>
              <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>

                  <DatePicker
                    label="To Date"
                    format="DD-MM-YYYY"
                    value={dayjs(dates)}
                    maxDate={dayjs(currentDate)}

                    onChange={(date: any) => {
                      setDates(date.format("YYYY-MM-DD"))
                      setFieldValue('date', date)
                    }
                    }
                  />

                </LocalizationProvider>
              </Stack>
            </ReportSectionContainer>
            <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
              <MFSubmitButton
                label="Download"
                loadingEnable={loading}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </ReportsRightLayout>
  );
};

export default ComprehensivePortfolio;

import { AnyAction } from 'redux';
import { SCHEME_FOR_BASKET_TYPE, SET_SCHEME_FOR_BASKET } from '../types/globalVariables';

export type ProductsType = {
    filters: SCHEME_FOR_BASKET_TYPE;
};

const initState: Partial<SCHEME_FOR_BASKET_TYPE> = [];

function globalVariableReducer(state = initState, action: AnyAction): Partial<SCHEME_FOR_BASKET_TYPE> {
    switch (action.type) {
        case SET_SCHEME_FOR_BASKET: {
            return action.value;
        }
        default:
            return state;
    }
}

export default globalVariableReducer;
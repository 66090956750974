import { SubAssetclassName } from "../../../redux-store/types/api-types";
import "./styles.css";

export function TransactionAssetClassTab({ investorId, subAssetClassNames }: { investorId: string, subAssetClassNames: SubAssetclassName }) {
  return (
    <div
      className="fundCardContainer  homeContainer"
      style={{ padding: 15, alignItems: "center" }}
    >
      <div style={{ height: "100%" }}>
        <img
          src={subAssetClassNames.ImageURL}
          style={{ height: 60, width: 60 }}
          alt=""
        />
      </div>
      <div style={{ marginLeft: 20, paddingTop: 5 }}>
        <div>{subAssetClassNames.Sub_AssetclassName}</div>
        <div className="fundCatdesc">{subAssetClassNames.Descripti}</div>
      </div>
    </div>
  )
}
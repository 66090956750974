import { AnyAction } from 'redux';
import {
  AuthState,
  GET_RM_DETAIL_SUCCESS,
  GET_USER_INFO_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_LOGIN_OTP_SUCCESS,
} from '../types/auth';

const initState: Partial<AuthState> = {
  token: null,
};
function authReducer(state = initState, action: AnyAction): Partial<AuthState> {
  switch (action.type) {
    case VERIFY_LOGIN_OTP_SUCCESS:
    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        ...action.body,
      };
    }
    case GET_RM_DETAIL_SUCCESS: {
      return {
        ...state,
        userDetails: { ...action.body },
      };
    }
    case LOGOUT_SUCCESS: {
      return { ...initState };
    }
    default:
      return state;
  }
}

export default authReducer;

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { CALL_API } from '../middleware';
import {
  DistributorListType,
  RMType,
  addRmDetails,
  AddDistributorRequestBody,
  Trustee,
  AddTrusteeRequestBody,
  AddFundManagerRequestBody,
  AddAifApproverRequestBody,
  AdddistributorRM,
  DistributorRM,
  FundManager,
  AuthorisedSignatories,
  AddAuthorisedSignatoryRequestBody,
  poaApprover,
  AddPoaApproverRequestBody,
  nationaliyType,
  AddDistributorRMRequestBody,
  SubDistributorListType,
  RmWithDistributors,
  AmcAdmin,
  AddAmcAdminRequestBody,
  investmentDetailsAplicationRef,
  FamilyLevelNamesList,
  RmDashboardType,
  RmRedirectionLinkType,
  getInvestorDataType,
  ProductDebtType,
  ProductEquityType,
  ProductOverviewType,
  ProductSchemeDetailsType,
  IndividualInvestorDashboardType,
  NetSalesType,
  RevenueDataType,
  DirectEquityOverviewType,
  PmsOverviewType,
  DirectEquityHoldingDetailsType,
  DirectEquityFODetailsType,
  AifOverviewType,
  GetSubAssetClassesType,
  GetAssetClassesType,
  GetSchemeListType,
  GetSchemeDetailsType,
  GetCategoryAverage,
  GetSchemeNav,
  GetRecommendedSchemes, GetRecommendationValidation, GetAccountType, PortfolioType, PortfolioBodyType, SchemeBodyType, SchemeType, AccountBodyType, AccountType, getPortfolioType, OrdersBodyType, getOrdersType,
  BSECodeResponse,
  ClientMandateResponse
} from '../types/api-types';
import { GET_RM_DETAIL_SUCCESS, GET_RM_DETAIL } from '../types/auth';
import {
  GET_DISTRIBUTORS_LIST,
  GET_DISTRIBUTORS_LIST_SUCCESS,
  GET_RM_LIST,
  GET_RM_LIST_SUCCESS,
  ADD_RMS_LIST_SUCCESS,
  ADD_RMS_LIST,
  ADD_DISTRIBUTOR,
  ADD_DISTRIBUTOR_SUCCESS,
  EDIT_DISTRIBUTOR_SUCCESS,
  GET_SUBDISTRIBUTOR_SUCCESS,
  GET_SUBDISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  UPDATE_FUND_MANAGER_SUCCESS,
  UPDATE_FUND_MANAGER,
  UPDATE_TRUSTEE_SUCCESS,
  GET_Countries_SUCCESS,
  GET_Countries,
  UPDATE_TRUSTEE,
  EDIT_RM_SUCCESS,
  EDIT_RM,
  GET_AIF_APPROVER_LIST_SUCCESS,
  GET_AIF_APPROVER_LIST,
  ADD_DISTRIBUTOR_RM_SUCCESS,
  ADD_DISTRIBUTOR_RM,
  UPDATE_DISTRIBUTOR_RM_SUCCESS,
  UPDATE_DISTRIBUTOR_RM,
  GET_TRUSTEE_LIST_SUCCESS,
  GET_TRUSTEE_LIST,
  ADD_TRUSTEE_SUCCESS,
  ADD_TRUSTEE,
  GET_FUND_MANAGERS_LIST_SUCCESS,
  GET_FUND_MANAGERS_LIST,
  ADD_FUND_MANAGER_SUCCESS,
  ADD_FUND_MANAGER,
  GET_AUTHORISEDSIGNATORY_LIST_SUCCESS,
  GET_AUTHORISEDSIGNATORY_LIST,
  ADD_AUTHORISEDSIGNATORY_SUCCESS,
  ADD_AUTHORISEDSIGNATORY,
  UPDATE_AUTHORISEDSIGNATORY_SUCCESS,
  UPDATE_AUTHORISEDSIGNATORY,
  GET_POAAPPROVER_LIST_SUCCESS,
  GET_POAAPPROVER_LIST,
  ADD_POAAPPROVER_SUCCESS,
  ADD_POAAPPROVER,
  UPDATE_POAAPPROVER_SUCCESS,
  UPDATE_POAAPPROVER,
  GET_AIFAPPROVERACCESS_SUCCESS,
  GET_AIFAPPROVERACCESS,
  GET_RMBYID,
  GET_RMBYID_SUCCESS,
  GET_DISTRIBUTORRMBYID_SUCCESS,
  GET_DISTRIBUTORRMBYID,
  GET_AUTHORISEDSIGNATORYBYID_SUCCESS,
  GET_AUTHORISEDSIGNATORYBYID,
  GET_POAAPPROVERBYID_SUCCESS,
  GET_POAAPPROVERBYID,
  GET_TRUSTEEBYID_SUCCESS,
  GET_TRUSTEEBYID,
  GET_AIF_APPROVERBYID_SUCCESS,
  GET_AIF_APPROVERBYID,
  GET_FUND_MANAGERSBYID_SUCCESS,
  GET_FUND_MANAGERSBYID,
  GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS,
  GET_DISTRIBUTORSOFRM_BY_RMID,
  GET_TRUSTEEACCESS,
  GET_TRUSTEEACCESS_SUCCESS,
  GET_IMACCESS_SUCCESS,
  GET_IMACCESS,
  GET_AMCADMINACCESS,
  GET_AMCADMINACCESS_SUCCESS,
  GET_AMCADMIN_LIST_SUCCESS,
  GET_AMCADMIN_LIST,
  GET_AMCADMINBYID,
  GET_AMCADMINBYID_SUCCESS,
  ADD_AMCADMIN_SUCCESS,
  ADD_AMCADMIN,
  UPDATE_AMCADMIN_SUCCESS,
  UPDATE_AMCADMIN,
  GET_RM_DASHBOARD_METRICS_SUCCESS,
  GET_RM_DASHBOARD_METRICS,
  GET_RM_REDIRECTION_LINK,
  GET_RM_REDIRECTION_LINK_SUCCESS,
  GET_ASSET_CLASSES,

  GET_ASSET_CLASSES_SUCCESS,

  GET_ASSET_CLASSES_FAILED,

  GET_SUB_ASSET_CLASSES,

  GET_SUB_ASSET_CLASSES_SUCCESS,

  GET_SUB_ASSET_CLASSES_FAILED,
  GET_SCHEME_LIST_SUCCESS,
  GET_SCHEME_LIST,
  GET_SCHEME_LIST_FAILED,
  GET_SCHEME_DETAILS,
  GET_SCHEME_DETAILS_SUCCESS,
  GET_SCHEME_DETAILS_FAILED,
  GET_CATEGORY_AVERAGE,
  GET_CATEGORY_AVERAGE_SUCCESS,
  GET_CATEGORY_AVERAGE_FAILED,
  GET_SCHEME_NAV,
  GET_SCHEME_NAV_SUCCESS,
  GET_SCHEME_NAV_FAILED,
  POST_RECOMMENDED_SCHEMES,
  POST_RECOMMENDED_SCHEMES_SUCCESS,
  POST_RECOMMENDED_SCHEMES_FAILED,
  DELETE_RECOMMENDED_SCHEMES,
  DELETE_RECOMMENDED_SCHEMES_SUCCESS,
  DELETE_RECOMMENDED_SCHEMES_FAILED,
  EDIT_RECOMMENDED_SCHEMES,
  EDIT_RECOMMENDED_SCHEMES_SUCCESS,
  EDIT_RECOMMENDED_SCHEMES_FAILED,
  GET_RECOMMENDED_SCHEMES,
  GET_RECOMMENDED_SCHEMES_SUCCESS,
  GET_RECOMMENDED_SCHEMES_FAILED,
  GET_RECOMMENDATION_VALIDATION,
  GET_RECOMMENDATION_VALIDATION_SUCCESS,
  GET_RECOMMENDATION_VALIDATION_FAILED,
  GET_ACCOUNT_TYPE,
  GET_ACCOUNT_TYPE_SUCCESS,
  GET_ACCOUNT_TYPE_FAILED,
  GET_PORTFOLIO_SUCCESS,
  GET_PORTFOLIO_FAILED,
  GET_SWP_SUCCESS,
  GET_SWP_FAILED,
  GET_SCHEMES_SUCCESS,
  GET_SCHEMES_FAILED,
  GET_ACCOUNT_SUCCESS,
  GET_ACCOUNT_FAILED,
  GET_ORDERS_SUCCESS,
  GET_ORDERS_FAILED,
  GET_RM_CREATE_TRANSACTION,
  GET_RM_CREATE_TRANSACTION_SUCCESS,
  GET_RM_CREATE_TRANSACTION_FAILED,
  GET_BSE_CODE,
  GET_BSE_CODE_SUCCESS,
  GET_BSE_CODE_FAILED,
  GET_CLIENT_MANDATEID,
  GET_CLIENT_MANDATEID_SUCCESS,
  GET_CLIENT_MANDATEID_FAILED,
  BALANCE_VALIDATION,
  BALANCE_VALIDATION_SUCCESS,
  BALANCE_VALIDATION_FAILED,
  GET_FOLIOLIST_FAILED,
  GET_FOLIOLIST_SUCCESS,
  GET_FOLIOLIST,
  GET_FAMILY_NAMES_DATA
} from '../types/onBoarding';

export const getRMsList =
  (params?: { isActive: boolean }) =>
    async (dispatch: any): Promise<RMType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/rm-list`,
          method: 'GET',
          types: [GET_RM_LIST_SUCCESS, GET_RM_LIST],
          params,
        },
      });
    };

export const getRMDashBoardMetrics =
  () =>
    async (dispatch: any): Promise<RmDashboardType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/dashboard/iwapp-rm-dashboard-metrics`,
          method: 'GET',
          types: [GET_RM_DASHBOARD_METRICS_SUCCESS, GET_RM_DASHBOARD_METRICS],
        },
      });
    };

export const getFamilyNamesData = () =>
  async (dispatch: any): Promise<FamilyLevelNamesList> => {

    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/getFamilyNamesForReports`,
        method: 'GET',
        types: [GET_FAMILY_NAMES_DATA]
      }
    })
  }

export const getRmDetails =
  () =>
    async (dispatch: any): Promise<any> => {
      return await dispatch({
        [CALL_API]: {
          url: '/onboarding/dashboard/getRmDetails',
          method: 'GET',
          types: [GET_RM_DETAIL_SUCCESS, GET_RM_DETAIL],
        },
      });
    };

export const getNetSales =
  (body: { rmID?: number | string; fromDate?: string; toDate?: string }) =>
    async (dispatch: any): Promise<NetSalesType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/dashboard/getNetSales`,
          method: 'POST',
          types: [GET_RM_DASHBOARD_METRICS_SUCCESS, GET_RM_DASHBOARD_METRICS],
          body,
        },
      });
    };

export const getRevenueData =
  (body: { rmID?: number | string; fromDate?: string; toDate?: string }) =>
    async (dispatch: any): Promise<RevenueDataType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/dashboard/getRevenueData`,
          method: 'POST',
          types: [GET_RM_DASHBOARD_METRICS_SUCCESS, GET_RM_DASHBOARD_METRICS],
          body,
        },
      });
    };

export const getRmRedirectionLink =
  (body: { clientId: string | null }) =>
    async (dispatch: any): Promise<RmRedirectionLinkType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/dashboard/iwapp-get-redirection-link`,
          method: 'POST',
          types: [GET_RM_REDIRECTION_LINK_SUCCESS, GET_RM_REDIRECTION_LINK],
          body,
        },
      });
    };

export const getInvestorData =
  (body: getInvestorDataType) =>
    async (
      dispatch: any
    ): Promise<
      | ProductOverviewType
      | ProductEquityType
      | ProductDebtType
      | ProductSchemeDetailsType
      | IndividualInvestorDashboardType
      | DirectEquityOverviewType
      | DirectEquityHoldingDetailsType
      | PmsOverviewType
      | AifOverviewType
    > => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/dashboard/getInvestorData`,
          method: 'POST',
          types: [GET_RM_REDIRECTION_LINK_SUCCESS, GET_RM_REDIRECTION_LINK],
          body,
        },
      });
    };

export const get_assest_classes =

  (

    // params: { type: string }

  ) =>

    async (dispatch: any): Promise<GetAssetClassesType[]> => {

      return await dispatch({

        [CALL_API]: {

          url: `/onboarding/transaction/get_asset_classes`,

          method: 'GET',

          types: [

            GET_ASSET_CLASSES,

            GET_ASSET_CLASSES_SUCCESS,

            GET_ASSET_CLASSES_FAILED,

          ],





        },

      });

    };



export const get_sub_asset_classes =

  (

    asset: any



  ) =>

    async (dispatch: any): Promise<GetSubAssetClassesType[]> => {

      return await dispatch({

        [CALL_API]: {

          url: `/onboarding/transaction/get_sub_asset_classes/${asset}`,

          method: 'GET',

          types: [

            GET_SUB_ASSET_CLASSES,

            GET_SUB_ASSET_CLASSES_SUCCESS,

            GET_SUB_ASSET_CLASSES_FAILED,

          ],

          // params,

        },

      });

    };


export const get_scheme_list = (asset_id: any) =>

  async (dispatch: any): Promise<GetSchemeListType[]> => {

    return await dispatch({

      [CALL_API]: {

        url: `/onboarding/transaction/get_scheme_list/${asset_id}`,

        method: 'GET',

        types: [

          GET_SCHEME_LIST,

          GET_SCHEME_LIST_SUCCESS,

          GET_SCHEME_LIST_FAILED,

        ],

        // params,

      },

    });

  };

export const get_scheme_details = (schemeId: any) =>

  async (dispatch: any): Promise<GetSchemeDetailsType[]> => {

    return await dispatch({

      [CALL_API]: {

        url: `/onboarding/transaction/get_scheme_details/${schemeId}`,

        method: 'GET',

        types: [

          GET_SCHEME_DETAILS,

          GET_SCHEME_DETAILS_SUCCESS,

          GET_SCHEME_DETAILS_FAILED,

        ],

        // params,

      },

    });

  };

export const get_category_average = (payload: any) =>

  async (dispatch: any): Promise<GetCategoryAverage[]> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/category_average`,
        method: 'POST',
        body: payload,
        types: [
          GET_CATEGORY_AVERAGE,
          GET_CATEGORY_AVERAGE_SUCCESS,
          GET_CATEGORY_AVERAGE_FAILED,
        ],
      },

    });

  };

export const get_scheme_nav = (schemeId: any) =>

  async (dispatch: any): Promise<GetSchemeNav[]> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/get_scheme_nav/${schemeId}`,
        method: 'GET',
        types: [
          GET_SCHEME_NAV,
          GET_SCHEME_NAV_SUCCESS,
          GET_SCHEME_NAV_FAILED,
        ],
      },

    });

  };

export const post_recommended_schemes = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/push_recommendations`,
        method: 'POST',
        body: payload,
        types: [
          POST_RECOMMENDED_SCHEMES,
          POST_RECOMMENDED_SCHEMES_SUCCESS,
          POST_RECOMMENDED_SCHEMES_FAILED,
        ],
      },

    });

  };

export const delete_recommended_schemes = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/delete_recommended_schemes`,
        method: 'POST',
        body: payload,
        types: [
          DELETE_RECOMMENDED_SCHEMES,
          DELETE_RECOMMENDED_SCHEMES_SUCCESS,
          DELETE_RECOMMENDED_SCHEMES_FAILED,
        ],
      },

    });

  };

export const edit_recommended_schemes = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/edit_recommendations`,
        method: 'POST',
        body: payload,
        types: [
          EDIT_RECOMMENDED_SCHEMES,
          EDIT_RECOMMENDED_SCHEMES_SUCCESS,
          EDIT_RECOMMENDED_SCHEMES_FAILED,
        ],
      },

    });

  };

export const redeem_validation = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/rm_redemption_validation`,
        method: 'POST',
        body: payload,
        types: [
          POST_RECOMMENDED_SCHEMES,
          POST_RECOMMENDED_SCHEMES_SUCCESS,
          POST_RECOMMENDED_SCHEMES_FAILED,
        ],
      },

    });

  };
export const balance_validation = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/balance_validation`,
        method: 'POST',
        body: payload,
        types: [
          BALANCE_VALIDATION,
          BALANCE_VALIDATION_SUCCESS,
          BALANCE_VALIDATION_FAILED,
        ],
      },

    });

  };

export const get_recommended_schemes = (payload: any) =>

  async (dispatch: any): Promise<GetRecommendedSchemes[]> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/get_recommended_schemes`,
        method: 'POST',
        body: payload,
        types: [
          GET_RECOMMENDED_SCHEMES,
          GET_RECOMMENDED_SCHEMES_SUCCESS,
          GET_RECOMMENDED_SCHEMES_FAILED,
        ],
      },

    });

  };
export const get_recommendation_validation = (payload: any) =>
  async (dispatch: any): Promise<GetRecommendationValidation> => {
    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/get_recommendation_validations`,
        method: 'POST',
        body: payload,
        types: [
          GET_RECOMMENDATION_VALIDATION,
          GET_RECOMMENDATION_VALIDATION_SUCCESS,
          GET_RECOMMENDATION_VALIDATION_FAILED,
        ],
      },

    });

  };
export const get_folioList = (payload: any) =>
  async (dispatch: any): Promise<any> => {
    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/folioList`,
        method: 'POST',
        body: payload,
        types: [
          GET_FOLIOLIST,
          GET_FOLIOLIST_SUCCESS,
          GET_FOLIOLIST_FAILED,
        ],
      },

    });

  };

export const get_account_type = (payload: any) =>
  async (dispatch: any): Promise<GetAccountType[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/transaction/get_account_type`,
        method: 'POST',
        body: payload,
        types: [
          GET_ACCOUNT_TYPE,
          GET_ACCOUNT_TYPE_SUCCESS,
          GET_ACCOUNT_TYPE_FAILED,
        ],
      },

    });

  };
export const RM_create_transaction = (payload: any) =>

  async (dispatch: any): Promise<any> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/RM_create_transaction`,
        method: 'POST',
        body: payload,
        types: [
          GET_RM_CREATE_TRANSACTION,
          GET_RM_CREATE_TRANSACTION_SUCCESS,
          GET_RM_CREATE_TRANSACTION_FAILED,
        ],
      },

    });

  };
export const get_bse_code = (payload: any) =>

  async (dispatch: any): Promise<BSECodeResponse[]> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/get_bse_code`,
        method: 'POST',
        body: payload,
        types: [
          GET_BSE_CODE,
          GET_BSE_CODE_SUCCESS,
          GET_BSE_CODE_FAILED,
        ],
      },

    });

  };
export const get_client_mandateIds = (payload: any) =>

  async (dispatch: any): Promise<ClientMandateResponse[]> => {

    return await dispatch({

      [CALL_API]: {
        url: `/onboarding/transaction/get_client_mandateIds`,
        method: 'POST',
        body: payload,
        types: [
          GET_CLIENT_MANDATEID,
          GET_CLIENT_MANDATEID_SUCCESS,
          GET_CLIENT_MANDATEID_FAILED,
        ],
      },

    });

  };
export const getDistributorsList =
  (params: { type: string }) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/distributors`,
          method: 'GET',
          types: [GET_DISTRIBUTORS_LIST_SUCCESS, GET_DISTRIBUTORS_LIST],
          params,
        },
      });
    };

export const editRM =
  (Id: number, body: addRmDetails) =>
    async (dispatch: any): Promise<RMType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-rm/${Id}`,
          method: 'POST',
          types: [EDIT_RM_SUCCESS, EDIT_RM],
          body,
        },
      });
    };

export const addRM =
  (body: addRmDetails) =>
    async (dispatch: any): Promise<RMType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-rm`,
          method: 'POST',
          types: [ADD_RMS_LIST_SUCCESS, ADD_RMS_LIST],
          body,
        },
      });
    };

export const addDistributor =
  (body: AddDistributorRequestBody) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-distributor`,
          method: 'POST',
          types: [ADD_DISTRIBUTOR_SUCCESS, ADD_DISTRIBUTOR],
          body,
        },
      });
    };

export const editDistributor =
  (Id: number, body: AddDistributorRequestBody | any) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-distributor/${Id}`,
          method: 'POST',
          types: [EDIT_DISTRIBUTOR_SUCCESS, EDIT_DISTRIBUTOR],
          body,
        },
      });
    };

export const getTrustessList =
  (params?: { isActive: boolean }) =>
    async (dispatch: any): Promise<Trustee[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/trustee-list`,
          method: 'GET',
          types: [GET_TRUSTEE_LIST_SUCCESS, GET_TRUSTEE_LIST],
          params,
        },
      });
    };

export const addTrustee =
  (body: AddTrusteeRequestBody) =>
    async (dispatch: any): Promise<Trustee[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-trustee`,
          method: 'POST',
          types: [ADD_TRUSTEE_SUCCESS, ADD_TRUSTEE],
          body,
        },
      });
    };

export const UpdateTrustee =
  (Id: number, body: AddTrusteeRequestBody) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-trustee/${Id}`,
          method: 'POST',
          types: [UPDATE_TRUSTEE_SUCCESS, UPDATE_TRUSTEE],
          body,
        },
      });
    };

export const getFundManagersList =
  (params?: { isActive: boolean }) =>
    async (dispatch: any): Promise<FundManager[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/fundmanager-list`,
          method: 'GET',
          types: [GET_FUND_MANAGERS_LIST_SUCCESS, GET_FUND_MANAGERS_LIST],
          params,
        },
      });
    };

export const getAIFApproverList =
  () =>
    async (dispatch: any): Promise<FundManager[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcapprover-list`,
          method: 'GET',
          types: [GET_AIF_APPROVER_LIST_SUCCESS, GET_AIF_APPROVER_LIST],
        },
      });
    };

export const addFundManager =
  (body: AddFundManagerRequestBody) =>
    async (dispatch: any): Promise<FundManager[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-fundmanager`,
          method: 'POST',
          types: [ADD_FUND_MANAGER_SUCCESS, ADD_FUND_MANAGER],
          body,
        },
      });
    };

export const UpdateFundManager =
  (Id: number, body: AddFundManagerRequestBody) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-fundmanager/${Id}`,
          method: 'POST',
          types: [UPDATE_FUND_MANAGER_SUCCESS, UPDATE_FUND_MANAGER],
          body,
        },
      });
    };

export const addAifApprover =
  (body: AddAifApproverRequestBody) =>
    async (dispatch: any): Promise<FundManager[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-amcapprover`,
          method: 'POST',
          types: [ADD_FUND_MANAGER_SUCCESS, ADD_FUND_MANAGER],
          body,
        },
      });
    };

export const UpdateAifApprover =
  (Id: number, body: AddAifApproverRequestBody) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-amcapprover/${Id}`,
          method: 'POST',
          types: [EDIT_DISTRIBUTOR_SUCCESS, EDIT_DISTRIBUTOR],
          body,
        },
      });
    };

export const getAuthorisedSignatoryList =
  (params?: { distributorId: number }) =>
    async (dispatch: any): Promise<AuthorisedSignatories[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/authorisedsignatory-list`,
          method: 'GET',
          types: [GET_AUTHORISEDSIGNATORY_LIST_SUCCESS, GET_AUTHORISEDSIGNATORY_LIST],
          params,
        },
      });
    };

export const addAuthorisedSignatory =
  (body: AddAuthorisedSignatoryRequestBody) =>
    async (dispatch: any): Promise<AuthorisedSignatories[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-authorisedsignatory`,
          method: 'POST',
          types: [ADD_AUTHORISEDSIGNATORY_SUCCESS, ADD_AUTHORISEDSIGNATORY],
          body,
        },
      });
    };

export const updateAuthorisedSignatory =
  (authorisedSignatoryId: number, body: AddAuthorisedSignatoryRequestBody) =>
    async (dispatch: any): Promise<AuthorisedSignatories[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-authorisedsignatory/${authorisedSignatoryId}`,
          method: 'POST',
          types: [UPDATE_AUTHORISEDSIGNATORY_SUCCESS, UPDATE_AUTHORISEDSIGNATORY],
          body,
        },
      });
    };

export const getPoaApproverList =
  (params?: { distributorId: number }) =>
    async (dispatch: any): Promise<poaApprover[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/poaapprover-list`,
          method: 'GET',
          types: [GET_POAAPPROVER_LIST_SUCCESS, GET_POAAPPROVER_LIST],
          params,
        },
      });
    };

export const addDistributorRM =
  (body: any) =>
    async (dispatch: any): Promise<DistributorListType[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-subdistributor`,
          method: 'POST',
          types: [ADD_DISTRIBUTOR_RM_SUCCESS, ADD_DISTRIBUTOR_RM],
          body,
        },
      });
    };

export const updateDistributorRM =
  (Id: number, body: any) =>
    async (dispatch: any): Promise<SubDistributorListType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-subdistributor/${Id}`,
          method: 'POST',
          types: [UPDATE_DISTRIBUTOR_RM_SUCCESS, UPDATE_POAAPPROVER],
          body,
        },
      });
    };

export const addPOAAppprover =
  (body: AddPoaApproverRequestBody) =>
    async (dispatch: any): Promise<poaApprover[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-poaapprover`,
          method: 'POST',
          types: [ADD_POAAPPROVER_SUCCESS, ADD_POAAPPROVER],
          body,
        },
      });
    };

export const updatePOAApprover =
  (POAapproverId: number, body: AddPoaApproverRequestBody) =>
    async (dispatch: any): Promise<poaApprover[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-poaapprover/${POAapproverId}`,
          method: 'POST',
          types: [UPDATE_POAAPPROVER_SUCCESS, UPDATE_POAAPPROVER],
          body,
        },
      });
    };

export const getDistributorById =
  (DistributorId: string | number) =>
    async (dispatch: any): Promise<poaApprover[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/distributors/${DistributorId}`,
          method: 'GET',
          types: [GET_SUBDISTRIBUTOR_SUCCESS, GET_SUBDISTRIBUTOR],
        },
      });
    };

export const getNationalityList =
  () =>
    async (dispatch: any): Promise<nationaliyType> => {
      return await dispatch({
        [CALL_API]: {
          url: '/mdms/default-countries.json',
          method: 'GET',
          types: [GET_Countries_SUCCESS, GET_Countries],
        },
      });
    };

export type AifApproverAccess = {
  amcApproverAccess: boolean;
};
export const getAmcApproverAccess =
  () =>
    async (dispatch: any): Promise<AifApproverAccess> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcApproverAccess`,
          method: 'GET',
          types: [GET_AIFAPPROVERACCESS_SUCCESS, GET_AIFAPPROVERACCESS],
        },
      });
    };

export const getRMById =
  (rmId: number) =>
    async (dispatch: any): Promise<RMType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/rms/${rmId}`,
          method: 'GET',
          types: [GET_RMBYID_SUCCESS, GET_RMBYID],
        },
      });
    };

export const getDistributorRMbyId =
  (Id: number) =>
    async (dispatch: any): Promise<SubDistributorListType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/subdistributors/${Id}`,
          method: 'GET',
          types: [GET_DISTRIBUTORRMBYID_SUCCESS, GET_DISTRIBUTORRMBYID],
        },
      });
    };

export const getAuthorisedSignatoryById =
  (id: number) =>
    async (dispatch: any): Promise<AuthorisedSignatories> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/authorisedsignatories/${id}`,
          method: 'GET',
          types: [GET_AUTHORISEDSIGNATORYBYID_SUCCESS, GET_AUTHORISEDSIGNATORYBYID],
        },
      });
    };
export const getPoaApproverById =
  (Id: number) =>
    async (dispatch: any): Promise<poaApprover> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/poaapprovers/${Id}`,
          method: 'GET',
          types: [GET_POAAPPROVERBYID_SUCCESS, GET_POAAPPROVERBYID],
        },
      });
    };

export const getTrustesById =
  (id: number) =>
    async (dispatch: any): Promise<Trustee> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/trustees/${id}`,
          method: 'GET',
          types: [GET_TRUSTEEBYID_SUCCESS, GET_TRUSTEEBYID],
        },
      });
    };
export const getFundManagersById =
  (id: number) =>
    async (dispatch: any): Promise<FundManager> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/fundmanagers/${id}`,
          method: 'GET',
          types: [GET_FUND_MANAGERSBYID_SUCCESS, GET_FUND_MANAGERSBYID],
        },
      });
    };

export const getAIFApproverById =
  (Id: number) =>
    async (dispatch: any): Promise<FundManager> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcapprovers/${Id}`,
          method: 'GET',
          types: [GET_AIF_APPROVERBYID_SUCCESS, GET_AIF_APPROVERBYID],
        },
      });
    };

export const getDistributorAndSRMByRmid =
  (rmId: number) =>
    async (dispatch: any): Promise<RmWithDistributors> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/distributors-of-rm-by-id/${rmId}`,
          method: 'GET',
          types: [GET_DISTRIBUTORSOFRM_BY_RMID_SUCCESS, GET_DISTRIBUTORSOFRM_BY_RMID],
        },
      });
    };

export type trusteeAccess = {
  trusteeAccess: boolean;
};
export const gettrusteeAccess =
  () =>
    async (dispatch: any): Promise<trusteeAccess> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/trusteeAccess`,
          method: 'GET',
          types: [GET_TRUSTEEACCESS_SUCCESS, GET_TRUSTEEACCESS],
        },
      });
    };
//IM-->Investment Manager or fundManager
export type IMAccess = {
  fundManagerAccess: boolean;
};
export const getIMAccess =
  () =>
    async (dispatch: any): Promise<IMAccess> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/fundManagerAccess`,
          method: 'GET',
          types: [GET_IMACCESS_SUCCESS, GET_IMACCESS],
        },
      });
    };

export type AmcAdminAccess = {
  amcAdminAccess: boolean;
};
export const getAmcAdminAccess =
  () =>
    async (dispatch: any): Promise<AmcAdminAccess> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcAdminAccess`,
          method: 'GET',
          types: [GET_AMCADMINACCESS_SUCCESS, GET_AMCADMINACCESS],
        },
      });
    };

export const getAmcAdminList =
  () =>
    async (dispatch: any): Promise<AmcAdmin[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcadmin-list`,
          method: 'GET',
          types: [GET_AMCADMIN_LIST_SUCCESS, GET_AMCADMIN_LIST],
        },
      });
    };

export const getAMCAdminById =
  (Id: number) =>
    async (dispatch: any): Promise<AmcAdmin> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/amcadmins/${Id}`,
          method: 'GET',
          types: [GET_AMCADMINBYID_SUCCESS, GET_AMCADMINBYID],
        },
      });
    };

export const addAmcAdmin =
  (body: AddAmcAdminRequestBody) =>
    async (dispatch: any): Promise<AmcAdmin[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/add-amcadmin`,
          method: 'POST',
          types: [ADD_AMCADMIN_SUCCESS, ADD_AMCADMIN],
          body,
        },
      });
    };

export const updateAmcAdmin =
  (amcAdminId: number, body: AddAmcAdminRequestBody) =>
    async (dispatch: any): Promise<AmcAdmin[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/amc/update-amcadmin/${amcAdminId}`,
          method: 'POST',
          types: [UPDATE_AMCADMIN_SUCCESS, UPDATE_AMCADMIN],
          body,
        },
      });
    };
export const getPortfolio =
  (body: PortfolioBodyType) =>
    async (dispatch: any): Promise<getPortfolioType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/transaction/portfolio`,
          method: 'POST',
          types: [GET_PORTFOLIO_SUCCESS, GET_PORTFOLIO_FAILED],
          body,
        },
      });
    };

export const getOrders =
  (body: any) =>
    async (dispatch: any): Promise<getOrdersType> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/transaction/orders`,
          method: 'POST',
          types: [GET_ORDERS_SUCCESS, GET_ORDERS_FAILED],
          body,
        },
      });
    };

export const getFrequency =
  () =>
    async (dispatch: any): Promise<string[]> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/transaction/get_swp_stp`,
          method: 'GET',
          types: [GET_SWP_SUCCESS, GET_SWP_FAILED],
        },
      });
    };

export const getSchemeName =
  (body: SchemeBodyType) =>
    async (dispatch: any): Promise<any> => {
      return await dispatch({
        [CALL_API]: {
          url: `/onboarding/transaction/get_switch_schemes`,
          method: 'POST',
          types: [GET_SCHEMES_SUCCESS, GET_SCHEMES_FAILED],
          body,
        },
      });
    };


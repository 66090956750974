import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProgressBarIntervals, RevenuePercent, scrollbarView } from '.';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { ProductEquityMarketCapEntity, ProductEquityTop10Entity, ProductEquityType, ProductEquityAMCWiseExposureEntity, ProductEquitySectorWiseExposureEntity, ProductEquityCompaniesEntity } from '../../redux-store/types/api-types';
import { copyGraphToClipboard, formatToIndianCurrency } from '../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../DataTable';
import GridStyleGraph from './gridstyle-graph';
import { ProductTopSection } from './product-details';
import { CopyGraphDropdown, AmountUnit } from '../commonComponents';
import ReactCardFlip from 'react-card-flip';
import { FlipIcon } from '../customSVGs';
import { useReactToPrint } from 'react-to-print';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';
// import  generatePDF ,{Margin, usePDF} from 'react-to-pdf';


export default function ProductEquity({
  investorId,
  selectedFilters,
  forHybrid = false,
  selectedPortfolio
}: {
  investorId: string;
  selectedPortfolio: number;
  selectedFilters: { [key: string]: string[] };
  forHybrid?: boolean;
}): JSX.Element {
  const headers: ColumnType[] = [
    {
      header: 'Scheme',
      sortingKey: 'schemename',
      valueGetter: (row: ProductEquityTop10Entity) => row?.schemename || 'N/A',
    },
    investorId
      ? {
        header: 'XIRR (%)',
        sortingKey: 'Xirr',
        valueGetter: (row: ProductEquityTop10Entity) =>
          row?.Xirr || row?.Xirr === 0 ? row?.Xirr : 'N/A',
      }
      : {
        header: 'Sector',
        sortingKey: 'sector',
        valueGetter: (row: ProductEquityTop10Entity) => row?.sector || 'N/A',
      },
    {
      header: 'Amount',
      sortingKey: 'AUM',
      valueGetter: (row: ProductEquityTop10Entity, amountUnit?: number | null | undefined) =>
        (row?.AUM && formatToIndianCurrency(row?.AUM, amountUnit)) || 'N/A',
    },
    {
      header: 'Exposure (%)',
      sortingKey: 'Hper',
      valueGetter: (row: ProductEquityTop10Entity) => row.Hper || 'N/A',
    },
  ];
  const [isLoading, setLoading] = useState(false);
  const [equityData, setEquityData] = useState<ProductEquityType>();
  const valueConverter = useSelector((state: any) => state.valueConverter)
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    // setEquityData();
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'MUTUAL FUNDS',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              ...selectedFilters,
              customers: investorId ? [investorId] : [],
            },
            required: [forHybrid ? 'hybrid' : 'equity'],
          })
        )) as unknown as ProductEquityType;
        if (!isComponentActive) {
          return;
        }
        setEquityData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedFilters, selectedPortfolio]);

  const [amcAllocationView, setAMCAllocationView] = useState(false);
  const [sectorAllocationView, setSectorAllocationView] = useState(false);
  const [flipMarketCapAllocation, setFlipMarketCapAllocation] = useState(false);
  const [flipAmcAllocation, setFlipAmcAllocation] = useState(false);
  const [flipSectorAllocation, setFlipSectorAllocation] = useState(false);
  const [flipCompanyExposure, setFlipCompanyExposure] = useState(false);
  //! Graph Refs
  const marketCapRef = useRef<HTMLElement | null>(null);
  const amcAllocationRef = useRef(null);
  const sectorAllocationRef = useRef(null);
  const companyExposureRef = useRef(null);
  const [companyExposureView, setCompanyExposureView] = useState(false);
  const topData = equityData && equityData['TOP Allocation']?.[0];
  const marketCapData = equityData ? equityData['Market cap'] : [];
  const amcAllocationData = equityData && equityData['AMC wise exposure'];
  const sectorWiseData = equityData && equityData['Sector Wise Exposure'];
  const companyExposureData = equityData && equityData.Companies;
  const amcAllocationDataDisplay =
    equityData && (amcAllocationView ? amcAllocationData : amcAllocationData?.slice(0, 5));
  const sectorWiseDataDisplay =
    equityData && (sectorAllocationView ? sectorWiseData : sectorWiseData?.slice(0, 6));
  const companyExposureDataDisplay =
    equityData && (companyExposureView ? companyExposureData : companyExposureData?.slice(0, 6));
  const quantAnalysis = equityData?.Quants;
  const componentRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });
  // const getTargetElement = () => document.getElementById('content-id');
  // const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
  //Flip table headers
  const marketCapAllocationTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'AMC Category',
        sortingKey: 'RANK',
        valueGetter: (row: ProductEquityMarketCapEntity) => row?.RANK,
      },
      {
        header: 'Allocation %',
        sortingKey: 'HoldPercent',
        valueGetter: (row: ProductEquityMarketCapEntity) => row?.HoldPercent,
      },
    ];
  }
  const AmcAllocationTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'AMC',
        sortingKey: 'AMCName',
        valueGetter: (row: ProductEquityAMCWiseExposureEntity) => row?.AMCName,
      },
      {
        header: 'AUM',
        sortingKey: 'AUM',
        valueGetter: (row: ProductEquityAMCWiseExposureEntity, amountUnit?: number | null | undefined) => row?.AUM || row?.AUM === 0 ? `${formatToIndianCurrency(row?.AUM, amountUnit)}` : 'N/A',
      },
      {
        header: 'AUM Allocation %',
        sortingKey: 'HoldPercent',
        valueGetter: (row: ProductEquityAMCWiseExposureEntity) => row?.HoldPercent,
      },
    ];
  }
  const SectorAllocationTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Sector',
        sortingKey: 'sector',
        valueGetter: (row: ProductEquitySectorWiseExposureEntity) => row?.sector,
      },
      {
        header: 'Sector value',
        sortingKey: 'i_marketvalue',
        valueGetter: (row: ProductEquitySectorWiseExposureEntity, amountUnit?: number | null | undefined) => row?.i_marketvalue || row?.i_marketvalue === 0 ? `${formatToIndianCurrency(row?.i_marketvalue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Sector Allocation %',
        sortingKey: 'Hper',
        valueGetter: (row: ProductEquitySectorWiseExposureEntity) => row?.Hper,
      },
    ];
  }
  const CompanyExposureTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Company Name',
        sortingKey: 'Company_Name',
        valueGetter: (row: ProductEquityCompaniesEntity) => row?.Company_Name,
      },
      {
        header: 'AUM',
        sortingKey: 'AUM',
        valueGetter: (row: ProductEquityCompaniesEntity, amountUnit?: number | null | undefined) => row?.AUM || row?.AUM === 0 ? `${formatToIndianCurrency(row?.AUM, amountUnit)}` : 'N/A',
      },
      {
        header: 'Company exposure %',
        sortingKey: 'Hper',
        valueGetter: (row: ProductEquityCompaniesEntity) => row?.Hper,
      },
    ];
  }
  //   const options = {
  //     page: {
  //       // margin is in MM, default is Margin.NONE = 0
  //       margin: Margin.SMALL,
  //       // default is 'A4'
  //       format: 'A2',
  //       // default is 'portrait'
  //       orientation: 'landscape',
  //   },
  //   overrides: {
  //     // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
  //     pdf: {
  //        compress: true
  //     }},

  // }
  return (<>

    <div ref={componentRef}>
      {/* <Button
        onClick={() => window.print()}
        // onClick={handlePrint}

        className="print_button" 
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 1,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            PDF
          </Button>  */}
      <Box>
        <Box
          sx={{
            mb: 3
          }}>
          <AmountUnit />
          <Button
            onClick={() => window.print()}

            sx={{
              marginLeft: '20px',
              height: '27px',
              color: 'white',
              fontSize: '13px',
              cursor: 'pointer',
              borderRadius: '5px',
              bgcolor: '#4990F0',
              '&:hover': { backgroundColor: '#639FF0' },
            }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>
        </Box>
        {equityData?.['AMC wise exposure'].length===0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem', fontSize: '18px', fontWeight: 500, color: '#666', background: 'white', boxShadow: '10rem', marginTop:'4rem' }}>No portfolio details available.</div> :
        <Box>
        {investorId && (
          <ProductTopSection
            marketValue={topData?.MktVal}
            investedValue={topData?.PurVal}
            gainLoss={topData?.GainOrloss}
            xirr={investorId ? topData?.Xirr : null}
            isLoading={isLoading}
          />
        )}
        <Box
          ref={marketCapRef}
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              Market Cap Allocation
            </Typography>
            {/* <CopyGraphDropdown elementRef={marketCapRef} /> */}
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon

              onClick={() => (setFlipMarketCapAllocation(!flipMarketCapAllocation))}
              style={{
                height: '20px',
                cursor: "pointer"
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipMarketCapAllocation}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipMarketCapAllocation ? 'none' : 'block'}`
              }}
            >
              <Box sx={{ p: 2 }}>
                <Box>
                  <Box
                    sx={{
                      py: 3,
                      px: 0,
                      pb: 0.2,
                      position: 'relative',
                    }}>
                    <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                    {equityData
                      ? marketCapData?.map((ele, index: number) => {
                        const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                        return (
                          <Box key={index} sx={{ cursor: 'pointer' }}>
                            <RevenuePercent
                              label={ele?.RANK}
                              percent={ele.HoldPercent}
                              amount={`${ele?.HoldPercent}%`}
                              color={colors[index % 3]}
                              isLoading={isLoading}
                              popOverAmt={ele.MAmt}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          </Box>
                        );
                      })
                      : [...Array(3)].map((_, index: number) => {
                        return (
                          <RevenuePercent
                            key={index}
                            label=""
                            percent={0}
                            amount=""
                            color=""
                            isLoading={true}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </div>
            <div
              style={{
                height: '238px',
                display: `${flipMarketCapAllocation ? 'block' : 'none'}`
              }}>
              <DataTable
                isLoading={isLoading}
                tableHeader={[...marketCapAllocationTableHeaders()]}
                tableData={equityData?.['Market cap'] && equityData?.['Market cap']}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />
            </div>
          </ReactCardFlip>
        </Box>
        <Box
          ref={amcAllocationRef}
          sx={{
            background: '#FFFFFF',
            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
            borderRadius: '5px',
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
            }}>
            <Typography
              sx={{
                color: '#22242C',
                opacity: 0.8,
                fontSize: 16,
                lineHeight: '19px',
                fontWeight: 600,
              }}>
              AMC Allocation
            </Typography>
            {amcAllocationData && amcAllocationData.length > 5 && (
              <Box>
                <Button
                  sx={{
                    fontSize: 14,
                    lineHeight: '16px',
                    color: '#4E5056',
                    fontWeight: 500,
                    padding: '4px 22px',
                    boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                    border: '1px solid #0BAAE7',
                    borderRadius: '5px',
                  }}
                  onClick={() => setAMCAllocationView(!amcAllocationView)}>
                  {amcAllocationView ? 'View Less' : ' View all'}
                </Button>
                {/* <CopyGraphDropdown elementRef={amcAllocationRef} /> */}
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
          >
            <FlipIcon
              onClick={() => (setFlipAmcAllocation(!flipAmcAllocation))}
              style={{
                height: '20px',
                cursor: "pointer"
              }}
            />
          </Box>
          <ReactCardFlip isFlipped={flipAmcAllocation}
            flipDirection="horizontal">
            <div
              style={{
                backgroundColor: 'white',
                display: `${flipAmcAllocation ? 'none' : 'block'}`
              }}
            >
              <Box sx={{ p: 2, maxHeight: '460px', overflow: 'auto', ...scrollbarView }}>
                <Box>
                  <Box
                    sx={{
                      py: 3,
                      px: 0,
                      pb: 0.2,
                      position: 'relative',
                    }}>
                    <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                    {equityData
                      ? amcAllocationDataDisplay &&
                      amcAllocationDataDisplay.map((ele, index: number) => {
                        const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                        return (
                          <RevenuePercent
                            key={index}
                            label={ele.AMCName}
                            percent={ele.HoldPercent}
                            amount={`${ele?.HoldPercent}%`}
                            color={colors[index % 3]}
                            isLoading={isLoading}
                            popOverAmt={ele.AUM}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })
                      : [...Array(5)].map((_, index: number) => {
                        return (
                          <RevenuePercent
                            key={index}
                            label=""
                            percent={0}
                            amount=""
                            color=""
                            isLoading={true}
                            sx={{ '& .left': { width: 150 } }}
                          />
                        );
                      })}
                  </Box>
                </Box>
              </Box>
            </div>
            <div
              style={{
                maxHeight: '360px',
                display: `${flipAmcAllocation ? 'block' : 'none'}`
              }}>
              <DataTable
                isLoading={isLoading}
                tableHeader={[...AmcAllocationTableHeaders()]}
                tableData={equityData?.['AMC wise exposure'] && equityData?.['AMC wise exposure']}

                rowCustomStyles={{
                  '&:last-child td, &:last-child th': { border: 0 },
                  '.MuiTableCell-body ': { color: '#686e72' },
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },

                }}
                tableHeaderCustomStyles={{
                  '& .MuiTableCell-root': {
                    textAlign: 'left',
                  },
                }}
                boxStyles={{ minHeight: '500px', minWidth: 450 }}
              />
            </div>
          </ReactCardFlip>
        </Box>
        {(isLoading || sectorWiseDataDisplay) && (
          <Box
            ref={sectorAllocationRef}
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              mb: 7,
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
              }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: 600,
                }}>
                Sector Allocation
              </Typography>
              {sectorWiseData && sectorWiseData.length > 6 && (
                <Box>
                  <Button
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      color: '#4E5056',
                      fontWeight: 500,
                      padding: '4px 22px',
                      boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      border: '1px solid #0BAAE7',
                      borderRadius: '5px',
                    }}
                    onClick={() => setSectorAllocationView(!sectorAllocationView)}>
                    {sectorAllocationView ? 'View Less' : ' View all'}
                  </Button>
                  {/* <CopyGraphDropdown elementRef={sectorAllocationRef} /> */}
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <FlipIcon
                onClick={() => (setFlipSectorAllocation(!flipSectorAllocation))}
                style={{
                  height: '20px',
                  cursor: "pointer"
                }}
              />
            </Box>
            <ReactCardFlip isFlipped={flipSectorAllocation}
              flipDirection="horizontal">
              <div
                style={{
                  backgroundColor: 'white',
                  display: `${flipSectorAllocation ? 'none' : 'block'}`
                }}
              >
                <Box sx={{ p: 2, height: '410px', overflow: 'auto', ...scrollbarView }}>
                  <Box>
                    <Box
                      sx={{
                        py: 3,
                        px: 0,
                        pb: 0.2,
                        position: 'relative',
                      }}>
                      <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                      {equityData
                        ? sectorWiseDataDisplay &&
                        sectorWiseDataDisplay.map((ele, index: number) => {
                          const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                          return (
                            <RevenuePercent
                              key={index}
                              label={ele.sector}
                              percent={ele.Hper}
                              amount={`${ele?.Hper}%`}
                              color={colors[index % 3]}
                              isLoading={isLoading}
                              popOverAmt={ele.i_marketvalue}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })
                        : [...Array(5)].map((_, index: number) => {
                          return (
                            <RevenuePercent
                              key={index}
                              label=""
                              percent={0}
                              amount=""
                              color=""
                              isLoading={true}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  maxHeight: '400px',
                  display: `${flipSectorAllocation ? 'block' : 'none'}`
                }}>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={[...SectorAllocationTableHeaders()]}
                  tableData={equityData?.['Sector Wise Exposure'] && equityData?.['Sector Wise Exposure']}

                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },

                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: '500px', minWidth: 450 }}
                />
              </div>
            </ReactCardFlip>
          </Box>
        )}
        {
          <Box
            sx={{
              mb: 7,
            }}>
            <Grid container spacing={2}>
              {(quantAnalysis || isLoading) && (
                <Grid item xs={12} md={6} style={{ pageBreakBefore: 'always' }}>
                  <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        px: 3,
                        py: 2,
                        background: '#FFFFFF',
                        boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                        borderRadius: '5px 5px 0 0',
                      }}>
                      <Typography
                        sx={{
                          color: '#4E5056',
                          fontSize: 16,
                          lineHeight: '24px',
                          fontWeight: 600,
                        }}>
                        Quantitative Analysis
                      </Typography>
                    </Box>
                    <Divider />
                    {isLoading ? (
                      <LinearProgress />
                    ) : (
                      <Box
                        sx={{
                          flex: 1,
                          background: '#FFFFFF',
                          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                          borderRadius: '0 0 5px 5px',
                        }}>
                        {quantAnalysis && (
                          <TableContainer>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Metric</TableCell>
                                  <TableCell>Value</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell>P/E</TableCell>
                                  <TableCell>{quantAnalysis[0].PERatio}</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell>P/B</TableCell>
                                  <TableCell>{quantAnalysis[0].PBRatio}</TableCell>
                                </TableRow>
                                {/* <TableRow>
                                <TableCell>Weighted Avg Market Cap (Cr)</TableCell>
                                <TableCell>{quantAnalysis[0].WeightAvgMkt}</TableCell>
                              </TableRow> */}
                                {/* <TableRow>
                                <TableCell>Portfolio Beta</TableCell>
                                <TableCell>{quantAnalysis[0].PortBetaRatio}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Sharpe Ratio</TableCell>
                                <TableCell>{quantAnalysis[0].SharpeRatio}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell>Standard Deviation</TableCell>
                                <TableCell>{quantAnalysis[0].StandardDeviation}</TableCell>
                              </TableRow> */}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grid>
              )}
              {(equityData?.['stock Style'] || isLoading) && (
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      px: 3,
                      py: 2,
                      background: '#FFFFFF',
                      boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                      borderRadius: '5px 5px 0 0',
                    }}>
                    <Typography
                      sx={{
                        color: '#4E5056',
                        fontSize: 16,
                        lineHeight: '24px',
                        fontWeight: 600,
                      }}>
                      Stock Style Allocation Grid
                    </Typography>
                  </Box>
                  <Divider />
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    <Box
                      sx={{
                        background: '#FFFFFF',
                        boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                        borderRadius: '0 0 5px 5px',
                      }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          py: 2,
                        }}>
                        {equityData?.['stock Style']?.[0] && (
                          <GridStyleGraph options={equityData?.['stock Style']?.[0]} />
                        )}
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </Box>
        }
        <Box
          sx={{
            mb: 7,
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 3,
              py: 2,
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Typography
              sx={{
                color: '#4E5056',
                fontSize: 16,
                lineHeight: '24px',
                fontWeight: 600,
              }}>
              Top 10 Schemes
            </Typography>
          </Box>
          <Divider />
          {isLoading ? (
            <LinearProgress />
          ) : (
            <Box>
              {equityData && (
                <>
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={headers}
                    tableData={equityData?.top10}
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                      background: 'transparent',
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
        {(isLoading || companyExposureData) && (
          <Box
            sx={{
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
              }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 16,
                  lineHeight: '19px',
                  fontWeight: 600,
                }}>
                Company Exposure
              </Typography>
              {companyExposureData && companyExposureData.length > 6 && (
                <Box>
                  <Button
                    sx={{
                      fontSize: 14,
                      lineHeight: '16px',
                      color: '#4E5056',
                      fontWeight: 500,
                      padding: '4px 22px',
                      boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      border: '1px solid #0BAAE7',
                      borderRadius: '5px',
                    }}
                    onClick={() => setCompanyExposureView(!companyExposureView)}>
                    {companyExposureView ? 'View Less' : ' View all'}
                  </Button>
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
            >
              <FlipIcon
                onClick={() => (setFlipCompanyExposure(!flipCompanyExposure))}
                style={{
                  height: '20px',
                  cursor: "pointer"
                }}
              />
            </Box>
            <ReactCardFlip isFlipped={flipCompanyExposure}
              flipDirection="horizontal">
              <div
                style={{
                  backgroundColor: 'white',
                  display: `${flipCompanyExposure ? 'none' : 'block'}`
                }}
              >
                <Box
                  ref={companyExposureRef}
                  sx={{ p: 2, height: '430px', overflow: 'auto', ...scrollbarView }}>
                  <Box>
                    <Box
                      sx={{
                        py: 3,
                        px: 0,
                        pb: 0.2,
                        position: 'relative',
                      }}>
                      <ProgressBarIntervals sx={{ '& .left': { width: 150 } }} />
                      {equityData
                        ? companyExposureDataDisplay &&
                        companyExposureDataDisplay.map((ele, index: number) => {
                          const colors = ['#B8E3FF', '#4990F0', '#21639F'];
                          return (
                            <RevenuePercent
                              key={index}
                              label={ele.Company_Name}
                              percent={ele.Hper}
                              amount={`${ele?.Hper}%`}
                              color={colors[index % 3]}
                              isLoading={isLoading}
                              popOverAmt={ele.AUM}
                            // sx={{ '& .left': { width: 200 } }}
                            />
                          );
                        })
                        : [...Array(5)].map((_, index: number) => {
                          return (
                            <RevenuePercent
                              key={index}
                              label=""
                              percent={0}
                              amount=""
                              color=""
                              isLoading={true}
                              sx={{ '& .left': { width: 150 } }}
                            />
                          );
                        })}
                    </Box>
                  </Box>
                </Box>
              </div>
              <div
                style={{
                  maxHeight: '430px',
                  display: `${flipCompanyExposure ? 'block' : 'none'}`
                }}>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={[...CompanyExposureTableHeaders()]}
                  tableData={equityData?.Companies && equityData?.Companies}

                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },

                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: '500px', minWidth: 450 }}
                />
              </div>
            </ReactCardFlip>
          </Box>
        )}
      </Box>}
      </Box>
    </div>
  </>
  );
}

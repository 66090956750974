/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ArrowDropDown, Close } from '@mui/icons-material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Button, Stack, Tab, Typography, Link, Breadcrumbs, Select, MenuItem } from '@mui/material';
import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import DashboardLayout from '../dashboard-layout';
import PMSOverview from './overview';
import SchemeDetails from './scheme-details';
import TransactionDetails from './transaction-details';
import { useHistory } from 'react-router';
import { NavigateNext as NavigateNextIcon } from '@mui/icons-material';

export default function Bonds({
  location,
}: {
  location: Location<{ investorId: string; investorName: string }>;
}): JSX.Element {
  const [value, setValue] = useState('1');
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const history = useHistory();
  const [filterElementLoading, setFilterElementLoading] = useState<boolean>(true);
  const [filterMenuAnchorEl, setFilterMenuAnchorEl] = useState<null | HTMLElement>(null);
  const filterMenuOpen = Boolean(filterMenuAnchorEl);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const [val, setVal] = useState<number>(1);
  const handleSelectChange = (event: any) => {
    const newValue = event.target.value;
    setVal(newValue);
  };

  const [viewAllSelectedFilters, setViewAllSelectedFilters] = useState(false);
  let filtersSelected = ([] as string[]).concat(...Object.values(selectedFilters));
  filtersSelected =
    filtersSelected.length > 3 && !viewAllSelectedFilters
      ? filtersSelected.slice(0, 3)
      : filtersSelected;
  useEffect(() => {
    setTimeout(() => setFilterElementLoading(false), 1);
  }, []);
  useEffect(() => {
    setSelectedFilters({});
  }, [value]);

  const selectedFilterElements = (
    <Box
      sx={{
        bgcolor: 'common.white',
        boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
        borderRadius: '5px',
        mb: 7,
        display: 'flex',
        flex: 1,
        justifyContent: 'space-between',
        p: 2,
        width: '100%',
      }}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ flex: 1, flexWrap: 'wrap', gap: 1 }}
        spacing={1}>
        {filtersSelected.map((each) => (
          <Box
            key={each}
            sx={{
              background: '#D9D9D9',
              p: '6px 8px',
              borderRadius: '9px',
              display: 'flex',
              cursor: 'pointer',
            }}>
            <Typography sx={{ color: '#323232' }}>{each}</Typography>
            <Close
              sx={{ color: '#6D6868', width: 16, ml: 1 }}
              onClick={() => {
                const updatedFilters = { ...selectedFilters };
                Object.keys(updatedFilters).map((eachSection) => {
                  if (selectedFilters?.[eachSection]?.includes(each)) {
                    updatedFilters[eachSection] = selectedFilters?.[eachSection].filter(
                      (val) => val !== each
                    );
                  } else {
                    updatedFilters[eachSection] = selectedFilters?.[eachSection];
                  }
                });
                setSelectedFilters(updatedFilters);
              }}
            />
          </Box>
        ))}
      </Stack>
      {([] as string[]).concat(...Object.values(selectedFilters)).length > 3 && (
        <Button
          endIcon={<ArrowDropDown />}
          sx={{
            fontSize: 14,
            lineHeight: '16px',
            color: '#4E5056',
            fontWeight: 500,
            padding: '4px 22px',
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
            border: '1px solid #0BAAE7',
            borderRadius: '5px',
            height: 'fit-content',
          }}
          onClick={() => setViewAllSelectedFilters(!viewAllSelectedFilters)}>
          {viewAllSelectedFilters ? 'View Less' : ' View all'}
        </Button>
      )}
    </Box>
  );
  const breadcrumbs = location.pathname.includes('/product')
    ? [
      <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
        Products
      </Typography>,
      <Typography key="2" color="text.primary">
        {location.pathname.includes('/mutual-fund')
          ? 'Mutual Fund'
          : location.pathname.includes('/direct-equity')
            ? 'Direct Equity'
            : location.pathname.includes('/pms')
              ? 'PMS'
              : location.pathname.includes('/aif')
                ? 'AIF'
                : location.pathname.includes('/bonds')
                  ? 'BONDS'
                  : null}
      </Typography>,
    ]
    : [
      investorId ? (
        <Link
          key="2"
          sx={{ cursor: 'pointer', textDecoration: 'none' }}
          onClick={() => history.push('/investor', { investorId, investorName })}>
          {investorName}
        </Link>
      ) : null,
      location.pathname.includes('mutual-fund') ||
        location.pathname.includes('direct-equity') ||
        location.pathname.includes('bonds') ||
        location.pathname.includes('aif') ? (
        <Typography key="3" color="text.primary">
          {location.pathname.includes('mutual-fund')
            ? 'Mutual Fund'
            : location.pathname.includes('direct-equity')
              ? 'Direct Equity'
              : location.pathname.includes('/pms')
                ? 'PMS'
                : location.pathname.includes('/aif')
                  ? 'AIF'
                  : location.pathname.includes('/bonds')
                    ? 'BONDS'
                    : null}
        </Typography>
      ) : null,
    ];
  return (
    <>
      {
        // *** Filter Disabled for the time being
        /* {!filterElementLoading && (
        <FilterPortal>
          <Box sx={{ display: 'flex' }}>
            <IconButton onClick={(e) => setFilterMenuAnchorEl(e.currentTarget)}>
              <img src="/images/filter.svg" alt="filter" />
            </IconButton>
          </Box>
          <FilterDrawer
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            open={filterMenuOpen}
            anchorEl={filterMenuAnchorEl}
            onClose={() => setFilterMenuAnchorEl(null)}
          />
        </FilterPortal>
      ) */
      }

      <CommonLayout>
        <Box>
          <Box
            sx={{
              background: '#EEF9F4',
              borderRadius: '16px',
              px: 3,
              py: 1.5,
              mb: 2.5,
            }}>
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
              sx={{
                '& .MuiTypography-root': {
                  fontSize: 20,
                  lineHeight: '23px',
                  fontWeight: 500,
                  color: '#BBB5B5',
                  '&.MuiTypography-body1': { color: '#22242C' },
                },
                '& .MuiBreadcrumbs-separator': {
                  '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                  '&:last-of-type': {
                    background: 'red',
                    '& .MuiSvgIcon-root': { color: '#22242C' },
                  },
                },
              }}>
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
          <Box
            sx={{
              width: '100%',
              '& .MuiTabPanel-root': { py: 4, px: 0 },
              '& .MuiTab-root': {
                color: '#A1A2A2',
                opacity: 0.8,
                fontSize: 17,
                lineHeight: '24px',
                textTransform: 'capitalize',
                px: { xs: 2, md: 3, lg: 5 },
                '&.Mui-selected': {
                  color: '#4B81B1',
                },
              },
              '& .MuiTabs-indicator': {
                height: 3,
                background: '#4B81B1',
              },
            }}>
            <TabContext value={value}>
              <Box>
                <TabList
                  onChange={handleChange}
                  aria-label="product tabs"
                  variant="scrollable"
                  scrollButtons="auto">
                  <Tab label="Overview" value="1" />
                  <Tab label="Scheme Details" value="2" />
                  <Tab label="Transaction Details" value="3" />
                </TabList>
                {location.pathname === '/investor/bonds' ?
                  <Select
                    style={{
                      color: 'var(--title-color)',
                      height: '1.7rem',
                      width: '7rem',
                      position: 'absolute',
                      right: '0',
                      marginRight: "3rem",
                      marginTop: "2rem"
                    }}
                    value={val}
                    onChange={handleSelectChange}
                    displayEmpty
                    inputProps={{ 'aria-label': 'Without label' }}
                    variant="standard"
                    sx={{
                      fontSize: 12,
                      lineHeight: '16px',
                      color: '#4E5056',
                      fontWeight: 500,
                      boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                      borderRadius: '5px',
                      pl: 1,
                      background: ' #FFFFFF',
                    }}
                  >
                    <MenuItem value={1} style={{ color: 'var(--title-color)' }}>
                      Muthoot
                    </MenuItem>
                    <MenuItem value={2} style={{ color: 'var(--title-color)' }}>
                      Held Away
                    </MenuItem>
                    <MenuItem value={3} style={{ color: 'var(--title-color)' }}>
                      Combined
                    </MenuItem>
                  </Select>
                  : ''}
              </Box>
              <TabPanel value="1">
                <>{filtersSelected.length ? selectedFilterElements : null}</>
                <PMSOverview investorId={investorId} selectedPortfolio={val} />
              </TabPanel>
              <TabPanel value="2">
                <>{filtersSelected.length ? selectedFilterElements : null}</>
                <SchemeDetails investorId={investorId} selectedPortfolio={val} />
              </TabPanel>
              <TabPanel value="3">
                <>{filtersSelected.length ? selectedFilterElements : null}</>
                <TransactionDetails investorId={investorId} selectedPortfolio={val} />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </CommonLayout>
    </>
  );
}

import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import { BondsSchemeTransactionsType, BondsSchemeDetail } from '../../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../../DataTable';
import GainLossPopoverButtons from '../components/gain-loss-popover-buttons';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { AmountUnit } from '../../commonComponents';
import { Search } from '@mui/icons-material';
import { Formik, Form } from 'formik';
import { MFTextField } from '../../../lib/formik';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

function Bond_SchemeDetails({ investorId, selectedPortfolio }: { investorId: string; selectedPortfolio: number }): JSX.Element {
    const [isLoading, setLoading] = useState(false);
    const [holdingDetails, setHoldingDetails] = useState<BondsSchemeTransactionsType | null>(null);
    const [search, setSearch] = useState<string>('');
    const valueConverter = useSelector((state: any) => state.valueConverter)
    const dispatch = useDispatch();
    useEffect(() => {
        let isComponentActive = true;
        setHoldingDetails(null);
        (async function () {
            try {
                const responseRM = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                setLoading(true);
                const response = (await dispatch(
                    getInvestorData({
                        productLevel: 'Bonds',
                        rmID: responseRM.Header_ID,
                        HFlag: selectedPortfolio,
                        filters: {
                            customers: investorId ? [investorId] : [],
                        },
                        required: ['transactionDetails'],
                    })
                )) as unknown as BondsSchemeTransactionsType;
                if (!isComponentActive) {
                    return;
                }
                setHoldingDetails(response);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log((e as Error).message);
            }
        })();
        return () => {
            isComponentActive = false;
        };
    }, [selectedPortfolio]);

    const headers: ColumnType[] = [
        {
            header: 'Strategy',
            sortingKey: 'Product',

            valueGetter: (row: BondsSchemeDetail) => (
                <Typography
                    sx={{
                        fontSize: 'inherit',
                        // whiteSpace: 'nowrap',
                        textAlign: 'left',
                        lineHeight: '1.5em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxHeight: '3em',
                        width: '10em',
                        maxWidth: '20em',
                    }}>
                    {row?.Product || 'N/A'}
                </Typography>
            ),
            stickyColumn: true,
        },
        {
            header: 'Investment Amount',
            sortingKey: 'I_PurValue',
            valueGetter: (row: BondsSchemeDetail) =>
                row.I_PurValue || row.I_PurValue === 0 ? formatToIndianCurrency(row.I_PurValue, valueConverter) : 'N/A',
        },
        {
            header: 'Current Value',
            sortingKey: 'I_MarKetValue',
            valueGetter: (row: BondsSchemeDetail) =>
                row.I_MarKetValue || row.I_MarKetValue === 0
                    ? formatToIndianCurrency(row.I_MarKetValue, valueConverter)
                    : 'N/A',
        },
        {
            header: 'Exposure %',
            sortingKey: 'HPer',
            valueGetter: (row: BondsSchemeDetail) =>
                row.HPer || row.HPer === 0 ? formatToIndianCurrency(row.HPer, valueConverter) : 'N/A',
        },
        {
            header: 'Gain/Loss',
            sortingKey: 'GainlossPer',
            valueGetter: (row: BondsSchemeDetail) => (
                <Typography
                    sx={{
                        fontSize: 'inherit',
                        color: row?.GainlossPer?.toString()?.includes('-') ? '#DC3636' : 'inherit',
                    }}>
                    {row.GainlossPer || row.GainlossPer === 0 ? row?.GainlossPer : 'N/A'}
                </Typography>
            ),
        },
        {
            header: '',
            stickyColumn: true,
            valueGetter: (row: BondsSchemeDetail) => (
                <GainLossPopoverButtons
                    row={{
                        PurchaseDate: row.PurDate,
                        GainLoss: row.GainorLoss,
                        GainLossPercentage: row.GainlossPer,
                        Inflow: row.Inflow,
                        Outflow: row.Outflow,
                    }}
                />
            ),
        },
    ];

    return (
        <Box sx={{ maxHeight: '60vh' }}>
            <Box
                sx={{
                    mb: 2
                }}>
                <AmountUnit />
                {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
            </Box>
            <Formik
                initialValues={{}}
                onSubmit={() => {
                    //
                }}>
                <Form>
                    <MFTextField
                        placeholder={'Search Strategy'}
                        name={'strategy'}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        endAdornment={<Search sx={{ pr: 0.5, color: '#000', opacity: 0.4 }} />}
                        sx={{
                            width: { md: '12rem', lg: '20rem' },
                            '& .MuiInputBase-root': {
                                fontSize: { md: 12, lg: 14 },
                                border: '0 !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                ':hover': {
                                    border: 0,
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                },
                            },
                        }}
                    />
                </Form>
            </Formik>
            <DataTable
                isLoading={isLoading}
                tableHeader={headers}
                tableData={holdingDetails?.schemeDetails.filter((ele) =>
                    ele?.Product?.toLowerCase().includes(search.toLowerCase())
                )}
                tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                        textAlign: 'left',
                    },
                }}
                tableBodyCustomStyles={{
                    '.MuiTableCell-root': {
                        textAlign: 'left',
                        fontSize: '14px',
                    },
                }}
                tableStyles={{
                    '::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '::-webkit-scrollbar-thumb': {
                        background: '#337FC9',
                        borderRadius: '10px',
                        width: '8px',
                        boxShadow: 'none',
                    },
                    '::-webkit-scrollbar-track': {
                        background: 'rgb(51 127 201 / 40%)',
                        borderRadius: '10px',
                    },
                }}
                boxStyles={{ minHeight: 'unset', minWidth: 450 }}
            />
        </Box>
    );
}

export default Bond_SchemeDetails;

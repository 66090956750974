import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  CardMedia,
  Checkbox,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Divider,
  Drawer,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import React, { Fragment, MutableRefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { logout } from '../redux-store/actions/auth';
import {
  AMCAdmins,
  ApplicationsIcon,
  DashboardIcon,
  ProductsIcon,
  ReportsIcon,
  // FundsSetup,
  RiskProfile,
} from './customSVGs';
// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AccountCircle, ArrowDropDown, Close, MoreVert, Search } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Formik, useFormikContext } from 'formik';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import { MFTextField } from '../lib/formik';
import MFCheckbox from '../lib/formik/Checkbox';
import { SearchFieldWrapper, renderFilteredOptions } from '../lib/formik/searchSelectField';
import { getParams } from '../redux-store/actions/application';
import { RootStateType } from '../redux-store/reducers';
import { initialState } from '../redux-store/reducers/queryParams';
import { Applicant } from '../redux-store/types/api-types';
import { APPLICATION_TYPE, ENABLE_Email_Consent, USER_ROLES } from '../utils/constant';
import { copyGraphToClipboard, getCountryCodes } from '../utils/utilityFunctions';
import { Item, ItemDetails } from './ApplicationDetails';
import { KeyValue } from './ApplicationDetails/personalDetails';
import { SubHeading } from './investors/components';
import { useSnackbar } from 'notistack';
import { setValue } from '../redux-store/actions/valueConverter';
import { SET_VALUE } from '../redux-store/types/valueConverter';

export function OtpTimer({ onResend }: { onResend: () => void }): JSX.Element {
  const [counter, setCounter] = useState(60);

  // Third Attempts
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timer: any = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  return (
    <Box>
      <Typography>
        <Button
          sx={{
            color: 'primary.main',
            mt: 2,
            fontSize: 16,
            fontWeight: 500,
            p: 0,
            ':hover': {
              background: 'none',
            },
          }}
          disabled={counter > 0}
          onClick={() => {
            try {
              onResend();
              setCounter(60);
            } catch (e) {
              console.error((e as Error).message);
            }
          }}>
          Resend OTP
        </Button>
        <br />
        {counter > 0 && `in ${counter} seconds`}
      </Typography>
    </Box>
  );
}

// Footer
export const Footer = (): JSX.Element => {
  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'primary.main',
        display: 'flex',
        justifyContent: 'center',
        py: 1,
      }}>
      <Typography sx={{ color: 'common.white' }}>Powered By </Typography>
      <CardMedia
        component="img"
        src="/images/kfin-new-logo.png"
        alt="kFintech Logo"
        sx={{ width: 'unset' }}
      />
    </Box>
  );
};
// Footer End

// Login Layout
export const LoginLayout = ({
  LoginText,
  children,
}: {
  LoginText: string;
  children: JSX.Element;
}): JSX.Element => {
  return (
    <Stack
      sx={{
        minHeight: '100vh',
        background: '#f2f6f8 url("/images/login-bg.svg")',
        backgroundSize: 'cover',
      }}>
      <AppBar elevation={0} position="static" sx={{ bgcolor: 'transparent' }}>
        <Box>
          <CardMedia
            component="img"
            src="/images/muthoot-logo.png"
            alt="Logo"
            sx={{ width: '80px', m: 3 }}
          />
        </Box>
      </AppBar>
      <Box
        component="main"
        sx={{
          // height: 'calc(100vh - 43px)',
          flex: 1,
          display: 'grid',
          placeItems: 'center',
          // mt: '-88px',
        }}>
        <Box
          sx={{
            bgcolor: 'grey.50',
            height: { xs: 'unset', sm: 'unset' },
            width: 'clamp(300px, 70%, 500px)',
            px: { xs: '25px', sm: '80px' },
            py: '50px',
            mb: 2,
            borderRadius: '10px',
            boxShadow: '0px 3.29214px 6.58427px rgba(27, 27, 27, 0.16)',
          }}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: 'Poppins, sans-serif',
              fontSize: 28,
              fontWeight: 600,
              color: 'primary.main',
              mb: '50px',
              textAlign: 'center',
            }}>
            {LoginText}
          </Typography>
          {children}
        </Box>
      </Box>
      {/* <Footer /> */}
    </Stack>
  );
};
// Login Layout End

// Header & Sidenav
const drawerWidth = '169px';

const MenuButton = ({
  isActive = false,
  to = null,
  text,
  children,
  downArrow,
  onClick,
}: {
  isActive?: boolean;
  to?: string | null;
  text: string;
  children: JSX.Element;
  downArrow?: boolean;
  onClick?: () => void;
}) => {
  const disabled = to === null;
  return (
    <ListItemButton
      onClick={onClick}
      component={disabled ? Typography : RouterLink}
      to={to || ''}
      sx={{
        p: { xs: '10px 20px', md: 3 },
        background: isActive
          ? 'linear-gradient(88.93deg, rgba(51, 127, 201, 0.10) 24.43%, rgba(51, 127, 201, 0) 93.37%)'
          : 'transparent',
        color: isActive ? 'primary.main' : 'secondary.main',
        stroke: isActive ? '#2057A6' : '#131836',
        strokeWidth: 0,
        '.MuiSvgIcon-root ': {
          opacity: isActive ? 1 : 0.5,
        },
        ':hover': {
          background: isActive
            ? 'linear-gradient(88.93deg, rgba(51, 127, 201, 0.10) 24.43%, rgba(51, 127, 201, 0) 93.37%)'
            : 'transparent',
        },
        textAlign: 'center',
      }}>
      {children}
      <ListItemText primary={text} secondary={downArrow && <ExpandMoreIcon color={'primary'} />} />
      {/* <ListItemText primary={text} />
      {downArrow && <ExpandMoreIcon />} */}
    </ListItemButton>
  );
};

const dashboardLinks = [
  { label: 'Home', link: '/dashboard' },
  { label: 'Clients', link: '/investors' },
  { label: 'Net Sales', link: '/net-sales' },
  { label: 'Total RMs', link: '/total-rm-detail' },
];

const productsLinks = [
  { label: 'Mutual Funds', link: '/product' },
  { label: 'Direct Equity', link: '/product/direct-equity' },
  { label: 'PMS', link: '/product/pms' },
  { label: 'Bonds', link: '/product/bonds' },
  { label: 'AIF', link: '/product/aif' },
];

const KfinDrawer = ({ onToggleClick }: { onToggleClick: () => void }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<'' | 'products' | 'dashboard' | 'applications' | 'reports'>('');
  const {
    role = '',
    adminSupport,
    userDetails,
  } = useSelector((store: RootStateType) => store.auth);
  const rmName = userDetails?.RM_Name || 'User';
  const { path } = useRouteMatch();
  const dashboardMatch = [
    '/dashboard',
    '/investors',
    '/net-sales',
    '/total-rm-detail',
    '/gross-sales',
    '/book-size-detail',
  ].includes(path);
  const productsMatch = path.includes('/product');
  const reportsSectionMatch = path.includes('/reports-download');
  const applicationMatch = [
    '/onboarding',
    '/applications',
    '/application',
    '/application-details/:id',
    '/non-individual-application-details/:id',
    '/non-individual-application',
    '/application-preview/:id',
    '/non-individual-application-preview/:id',
  ].includes(path);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(menuAnchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setMenuAnchorEl(null);
  };
  return (
    <Box>
      <List
        sx={{
          pt: { xs: 2, md: 1 },
          '& .MuiListItemButton-root': {
            mb: 1,
            flexDirection: { xs: 'row', md: 'column' },
            '& .MuiListItemText-root': { mx: { xs: 1, md: 0 } },
            '& .MuiListItemText-root span': {
              fontFamily: 'Poppins, sans-serif',
              fontSize: 16,
              fontWeight: 500,
            },
          },
        }}>
        <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
          <IconButton onClick={onToggleClick} aria-label="close drawer" sx={{ color: '#00D9DA' }}>
            <CancelIcon />
          </IconButton>
        </Box>
        {[USER_ROLES.RM, USER_ROLES.DISTRIBUTOR, USER_ROLES.SUBDISTRIBUTOR].includes(role) && (
          <>
            <MenuButton
              isActive={dashboardMatch}
              text="Dashboard"
              downArrow={open !== 'dashboard'}
              onClick={() => setOpen(open && open === 'dashboard' ? '' : 'dashboard')}>
              <DashboardIcon />
            </MenuButton>
            {open === 'dashboard' && (
              <Box
                sx={{
                  textAlign: 'center',
                  '.MuiTypography-root': {
                    my: 1,
                    py: 1,
                    fontWeight: 500,
                    fontSize: 14,
                    display: 'block',
                    textDecoration: 'none',
                    color: 'text.primary',
                    background:
                      'linear-gradient(90deg, rgba(15, 212, 216, 0.072) 0%, rgba(97, 209, 214, 0) 100%)',
                  },
                }}>
                {dashboardLinks.map((ele, index) => (
                  <Typography key={index} component={RouterLink} to={ele.link}>
                    {ele.label}
                  </Typography>
                ))}
                <IconButton onClick={() => setOpen('')}>
                  <ExpandLessIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </Box>
            )}
            <MenuButton
              isActive={productsMatch}
              text="Products"
              downArrow={open !== 'products'}
              onClick={() => setOpen(open && open === 'products' ? '' : 'products')}>
              <ProductsIcon />
            </MenuButton>
            {open === 'products' && (
              <Box
                sx={{
                  textAlign: 'center',
                  '.MuiTypography-root': {
                    my: 1,
                    py: 1,
                    fontWeight: 500,
                    fontSize: 14,
                    display: 'block',
                    textDecoration: 'none',
                    color: 'text.primary',
                    background:
                      'linear-gradient(90deg, rgba(15, 212, 216, 0.072) 0%, rgba(97, 209, 214, 0) 100%)',
                  },
                }}>
                {productsLinks.map((ele, index) => (
                  <Typography key={index} component={RouterLink} to={ele.link}>
                    {ele.label}
                  </Typography>
                ))}
                <IconButton onClick={() => setOpen('')}>
                  <ExpandLessIcon sx={{ color: 'primary.main' }} />
                </IconButton>
              </Box>
            )}
          </>
        )}
        <MenuButton
          isActive={applicationMatch}
          text="Applications"
          downArrow={open !== 'applications'}
          onClick={() => setOpen(open && open === 'applications' ? '' : 'applications')}>
          <ApplicationsIcon />
        </MenuButton>
        {open === 'applications' && (
          <Box
            sx={{
              textAlign: 'center',
              '.MuiTypography-root': {
                my: 1,
                py: 1,
                fontWeight: 500,
                fontSize: 14,
                display: 'block',
                textDecoration: 'none',
                color: 'text.primary',
                background:
                  'linear-gradient(90deg, rgba(15, 212, 216, 0.072) 0%, rgba(97, 209, 214, 0) 100%)',
              },
            }}>
            <Typography
              component={RouterLink}
              to="/onboarding"
              onClick={() => {
                dispatch(getParams(initialState));
              }}>
              Add New
            </Typography>
            <Typography
              component={RouterLink}
              to="/applications"
              onClick={() => {
                dispatch(getParams(initialState));
              }}>
              View Existing
            </Typography>
            {ENABLE_Email_Consent && (
              <Typography component={RouterLink} to="/view-consent-email-list">
                View Email Consent
              </Typography>
            )}
            <IconButton onClick={() => setOpen('')}>
              <ExpandLessIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        )}
        <MenuButton
          isActive={reportsSectionMatch}
          text="Reports"
          downArrow={open !== 'reports'}
          onClick={() => setOpen(open && open === 'reports' ? '' : 'reports')}>
          <ReportsIcon />
        </MenuButton>
        {open === 'reports' && (
          <Box
            sx={{
              textAlign: 'center',
              '.MuiTypography-root': {
                my: 1,
                py: 1,
                fontWeight: 500,
                fontSize: 14,
                display: 'block',
                textDecoration: 'none',
                color: 'text.primary',
                background:
                  'linear-gradient(90deg, rgba(15, 212, 216, 0.072) 0%, rgba(97, 209, 214, 0) 100%)',
              },
            }}>

            <Typography
              component={RouterLink}
              to="/reports-download"
              onClick={() => {
                dispatch(getParams(initialState));
              }}>
              Download
            </Typography>
            <IconButton onClick={() => setOpen('')}>
              <ExpandLessIcon sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        )}
        {USER_ROLES.AMC_ADMIN === role && (
          <>
            <MenuButton text="User Management" to="/user-management">
              <PersonOutlineOutlinedIcon />
            </MenuButton>
            {/* <MenuButton text="Funds Setup" to="/funds-setup">
              <FundsSetup />
            </MenuButton> */}
            {/* <MenuButton text="Reports" to="/reports">
              <Reports />
          </MenuButton>*/}
            {adminSupport && (
              <MenuButton text="Admin Support" to="/admin-support">
                <RiskProfile />
              </MenuButton>
            )}
            {/* <MenuButton text="Risk Profile" to="/risk-profile">
              <RiskProfile />
            </MenuButton> */}
          </>
        )}
        {USER_ROLES.SUPER_ADMIN === role && (
          <MenuButton text="AMC Admins" to="/amc-admins">
            <AMCAdmins />
          </MenuButton>
        )}
        {/* <MenuButton to="" text="Settings">
          <SettingsIcon />
        </MenuButton> */}
        <Box
          onClick={handleClick}
          sx={{
            my: 1,
            px: 2,
            py: 3,
            background:
              'linear-gradient(88.93deg, rgba(51, 127, 201, 0.1) 24.43%, rgba(51, 127, 201, 0) 93.37%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            cursor: 'pointer',
          }}>
          <AccountCircle />
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
              textDecoration: 'none',
              color: '#323232',
            }}>
            {rmName?.split(' ')[0]}
          </Typography>
          <MoreVert
            id="demo-positioned-button"
            aria-controls={openMenu ? 'demo-positioned-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={openMenu ? 'true' : undefined}
          />
        </Box>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={menuAnchorEl}
          open={openMenu}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}>
          <MenuItem onClick={handleClose}>
            <Stack direction="row" gap={2}>
              <CardMedia
                component="img"
                src="/images/settings-blue.svg"
                alt="settings"
                sx={{ objectFit: 'contain' }}
              />
              <Typography sx={{ color: '#776D6D' }}>Settings</Typography>
            </Stack>
          </MenuItem>
          <Divider />
          <MenuItem onClick={() => dispatch(logout())}>
            <Stack direction="row" gap={2}>
              <CardMedia
                component="img"
                src="/images/logout-blue.svg"
                alt="settings"
                sx={{ objectFit: 'none' }}
              />
              <Typography sx={{ color: '#776D6D' }}>Logout</Typography>
            </Stack>
          </MenuItem>
        </Menu>
      </List>
    </Box>
  );
};

export const FilterDrawer = ({
  selectedFilters,
  setSelectedFilters,
  ...rest
}: MenuProps & {
  selectedFilters: {
    [key: string]: string[];
  };
  setSelectedFilters: React.Dispatch<React.SetStateAction<{ [key: string]: string[] }>>;
}): JSX.Element => {
  const [selectedFilterSection, setSelectedFilterSection] = useState<number | string>(-1);
  const [selectedLocalFilters, setSelectedLocalFilters] =
    useState<{ [key: string]: string[] }>(selectedFilters);
  const [search, setSearch] = useState<string>('');
  const { filters: totalFilters } = useSelector((state: RootStateType) => state.products);
  const filters = totalFilters
    .filter((each) => {
      const children = each.options.filter((option) => {
        return option && typeof option === 'object'
          ? option.label.toLowerCase().includes(search.toLowerCase())
          : option && option.toLowerCase().includes(search.toLowerCase());
      });
      return children.length > 0;
    })
    .map((each) => {
      return {
        ...each,
        options: each.options.filter((option) =>
          option && typeof option === 'object'
            ? option.label.toLowerCase().includes(search.toLowerCase())
            : option && option.toLowerCase().includes(search.toLowerCase())
        ),
      };
    });

  useEffect(() => {
    setSelectedLocalFilters(selectedFilters);
  }, [selectedFilters]);
  return (
    <Menu
      {...rest}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      PaperProps={{ sx: { maxWidth: '400px' } }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', p: 2, gap: 4 }}>
        <Input
          placeholder="Search"
          fullWidth
          endAdornment={<Search />}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <IconButton onClick={(e) => rest?.onClose && rest?.onClose(e, 'backdropClick')}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, p: 2 }}>
        {filters.map(
          (
            {
              title,
              key,
              options,
            }: {
              title: string;
              key: string;
              options: ({ label: string; value: string } | string)[];
            },
            index: number
          ) => (
            <Box key={`${title}_${index}`} sx={{ width: '100%' }}>
              <Box>
                <Box
                  onClick={() => {
                    setSelectedFilterSection(selectedFilterSection === key ? -1 : key);
                  }}
                  sx={{
                    width: '100%',
                    gap: 3,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}>
                  <Typography>{title}</Typography>
                  <ArrowDropDown sx={{ color: '#337FC9' }} />
                </Box>
                <Divider />
              </Box>
              {selectedFilterSection === key && (
                <Box
                  sx={{
                    pt: 1,
                    px: 2,
                    maxHeight: '150px',
                    transition: '0.3s all',
                    overflow: 'auto',
                  }}>
                  <FormGroup>
                    {options.map(
                      (option: { label: string; value: string } | string, index: number) => {
                        let label = '',
                          value = '';
                        if (option && typeof option === 'object') {
                          label = option.label;
                          value = option.value;
                        } else if (option) {
                          label = option;
                          value = option;
                        }

                        return label && value ? (
                          <Fragment key={`${value}_${index}`}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    selectedLocalFilters?.[key]
                                      ? selectedLocalFilters?.[key].includes(value)
                                      : false
                                  }
                                  onChange={(e) => {
                                    const updatedFilters = { ...selectedLocalFilters };
                                    const { checked } = e.target;
                                    if (updatedFilters[key]) {
                                      if (checked) {
                                        const values = [...updatedFilters[key]];
                                        if (!values.includes(value)) {
                                          values.push(value);
                                        }
                                        updatedFilters[key] = values;
                                      } else {
                                        updatedFilters[key] = updatedFilters[key].filter(
                                          (val) => val !== value
                                        );
                                      }
                                    } else if (checked) {
                                      updatedFilters[key] = [value];
                                    }
                                    setSelectedLocalFilters(updatedFilters);
                                  }}
                                  checkedIcon={
                                    <Box
                                      sx={{
                                        border: '2px solid #22242C',
                                        margin: '2px',
                                        borderRadius: '2px',
                                        backgroundColor: '#337FC9',
                                        '&:before': {
                                          display: 'block',
                                          width: 16,
                                          height: 16,
                                          content: '""',
                                        },
                                      }}></Box>
                                  }
                                />
                              }
                              label={label}
                            />
                            <Divider />
                          </Fragment>
                        ) : null;
                      }
                    )}
                  </FormGroup>
                </Box>
              )}
            </Box>
          )
        )}
        {/* {selectedFilterSection ? (
          <Stack
            direction="row"
            alignItems="center"
            sx={{ width: '80%', flexWrap: 'wrap', gap: 1 }}
            spacing={1}>
            {selectedLocalFilters?.[selectedFilterSection]?.map((each) => (
              <Box
                key={each}
                sx={{
                  background: '#D9D9D9',
                  p: '4px 6px',
                  borderRadius: '9px',
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Typography sx={{ color: '#323232', fontSize: '12px' }}>{each}</Typography>
                <Close
                  sx={{ color: '#6D6868', width: 16, ml: 1 }}
                  onClick={() => {
                    const updatedFilters = { ...selectedLocalFilters };
                    updatedFilters[selectedFilterSection] = updatedFilters?.[
                      selectedFilterSection
                    ].filter((val) => val !== each);
                    setSelectedLocalFilters(updatedFilters);
                  }}
                />
              </Box>
            ))}
          </Stack>
        ) : null} */}
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3 }}>
          <Button
            onClick={(e) => {
              setSelectedFilters(selectedLocalFilters);
              rest?.onClose && rest?.onClose(e, 'backdropClick');
            }}
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 1,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            Apply
          </Button>
          <Button
            onClick={(e) => {
              setSelectedFilters({});
              rest?.onClose && rest?.onClose(e, 'backdropClick');
            }}
            sx={{
              border: '1px solid #337FC9',
              borderRadius: '5px',
              padding: 0,
              px: 1,
              color: '#337FC9',
              fontWeight: 'normal',
              '&:hover': {
                bgcolor: '#eaf0f5',
              },
            }}>
            Clear All
          </Button>
        </Box>
      </Box>
    </Menu>
  );
};

export const HeaderSidenav = (): JSX.Element => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: 'white',
          boxShadow: 'none',
        }}>
        <Toolbar sx={{ px: { xs: 2, lg: 6 } }}>
          <Box sx={{ mr: 'auto' }}>
            <CardMedia
              component="img"
              src="/images/muthoot-logo.png"
              alt="Logo"
              sx={{ width: '80px', mt: 1 }}
            />
          </Box>
          {/* <Box sx={{ display: 'flex' }}>
            <IconButton onClick={() => history.push('/profile')}>
              <AccountCircleIcon fontSize="large" sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box> */}
          <Box id="filter-container"></Box>
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: 'none' }, color: 'secondary.main' }}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="side nav">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="right"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}>
          <KfinDrawer onToggleClick={() => setMobileOpen(!mobileOpen)} />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              mt: '100px',
              border: 0,
              maxHeight: 'calc(100vh - 100px)',
              overflow: 'scroll',
              // pb: 'calc(65px + 10%)',
              // justifyContent: 'center',
            },
          }}
          open>
          <KfinDrawer onToggleClick={() => setMobileOpen(!mobileOpen)} />
        </Drawer>
      </Box>
    </>
  );
};
// Header & Sidenav END

export const CommonLayout = ({ children }: { children: JSX.Element }): JSX.Element => {
  return (
    <>
      <Box>
        <HeaderSidenav />
        <Box
          component="main"
          sx={{
            mt: '65px',
            ml: { xs: 0, md: drawerWidth },
            p: { xs: 2, md: '50px' },
            pt: { md: '20px' },
            borderRadius: '50px 0 0 0',
            bgcolor: { xs: '', md: 'rgba(238, 244, 251, 0.5)' },
            minHeight: 'calc(100vh - 108px)',
            // overflow: 'auto',
            // overflowY:"hidden"
          }}>
          <Container maxWidth="lg" disableGutters={true}>
            {children}
          </Container>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};

export const UserManagementCommonLayout = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          // minHeight: 'calc(100vh - 43px)',
          // overflow: 'auto',
          flexDirection: 'column',
        }}>
        <HeaderSidenav />
        <Box
          component="main"
          sx={{
            mt: '65px',
            ml: { xs: 0, md: drawerWidth },
            p: { xs: 0, md: '50px' },
            pt: { md: '20px' },
            borderRadius: { xs: '', sm: '50px 0 0 0' },
            bgcolor: 'rgba(238, 244, 251, 0.5)',
            minHeight: 'calc(100vh - 108px)',
            //overflow: 'auto',
          }}>
          <Container maxWidth="lg" disableGutters={true}>
            {children}
          </Container>
        </Box>
      </Box>
      {/* <Footer /> */}
    </>
  );
};
export const ConfirmationDialog = ({
  open,
  setOpen,
  message = 'Are you sure you want to delete?',
  onCancel,
  onSave,
}: {
  message: string;
  open: boolean;
  setOpen: () => void;
  onCancel: () => void;
  onSave: () => void;
}): JSX.Element => {
  return (
    <Dialog open={open} onClose={setOpen}>
      <Box
        sx={{
          p: { xs: 2, sm: '45px 40px' },
          borderRadius: '10px',
          // width: { xs: '70%', sm: '70%' },
          maxWidth: 400,
          height: { xs: '90%', md: 'unset' },
          overflowY: 'auto',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& .MuiButton-root': {
              minWidth: 100,
              fontSize: 16,
            },
          }}>
          <Typography
            sx={{
              fontSize: 20,
              fontWeight: 500,
              color: '#337FC9',
              textAlign: 'center',
              mb: 5,
            }}>
            {message}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: 1.5,
              '.MuiButton-root': {
                minWidth: '100px',
              },
            }}>
            <Button variant="contained" onClick={onSave} sx={{ color: 'common.white' }}>
              Yes
            </Button>
            <Button variant="outlined" onClick={onCancel} sx={{ color: 'primary.main' }}>
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

type Props = DialogProps & {
  title?: string;
  content: string;
  actions: {
    label: string;
    onClick: () => void;
  }[];
};

export default function SimpleDialog({ title, ...props }: Props): JSX.Element {
  return (
    <Dialog
      {...props}
      sx={{
        '& .MuiPaper-root': {
          minWidth: { sx: '300px', md: '400px' },
          maxWidth: { sx: '500px', md: '500px' },
          borderRadius: '8px',
          padding: '30px 30px 20px',
          textAlign: 'center',
          color: '#4f4f4f',
        },
        '& .MuiDialogContent-root': {
          textAlign: 'center',
        },
        '& .MuiDialogActions-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiButton-root': {
            width: '160px',
            height: '58px',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '18px',
            margin: 'auto',
            marginBottom: '20px',
          },
        },
      }}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {props.actions.map((action, index) => (
          <Button variant="contained" color="primary" key={index} onClick={action.onClick}>
            {action.label}
          </Button>
        ))}
      </DialogActions>
    </Dialog>
  );
}

export function CommonLayoutForPOA(): JSX.Element {
  const { role } = useSelector((store: RootStateType) => store.auth);
  const dispatch = useDispatch();
  return (
    <>
      <AppBar position="fixed" elevation={0} sx={{ bgcolor: 'common.white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: { xs: 0, sm: '48px' },
            paddingLeft: { xs: 0, sm: '48px' },
          }}>
          <CardMedia
            component="img"
            src="/images/muthoot-logo.png"
            alt="Logo"
            sx={{ width: '80px', m: 3 }}
          />
          {role !== USER_ROLES.INVESTOR && (
            <Box sx={{ display: 'flex' }}>
              <IconButton onClick={() => dispatch(logout())}>
                <PowerSettingsNewIcon fontSize="large" sx={{ color: 'primary.main' }} />
              </IconButton>
            </Box>
          )}
        </Box>
      </AppBar>
      <Divider sx={{ display: { xs: 'block', sm: 'none' } }} />
    </>
  );
}

export function CountryCodesDropDown({
  name,
  value,
  readOnly = false,
  disabled = false,
  ElementWidth,
}: {
  name: string;
  value: string;
  readOnly?: boolean;
  disabled?: boolean;
  ElementWidth?: number | null;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [query, setQuery] = useState('');

  return (
    <InputAdornment
      position="start"
      sx={{
        pr: '0 !important',
        mx: '0 !important',
      }}>
      <Box
        sx={{
          '.MuiOutlinedInput-root': {
            marginTop: '0',
            '&.MuiInputBase-root': {
              '& .MuiSelect-select': {
                padding: '12px 15px 12px 0',
                border: 'none',
              },
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
          },

          '&.Mui-focused,&:hover': {
            '.MuiOutlinedInput-notchedOutline': {
              borderWidth: 'none',
            },
          },
          svg: {
            color: '#337FC9',
          },
        }}>
        <Select
          labelId="user-items"
          onChange={(e) => {
            setFieldValue(name, `${e.target.value}`);
          }}
          value={value}
          renderValue={(value) => {
            return (
              <Typography>{value.split(':')[1] ? value.split(':')[1].trim() : value}</Typography>
            );
          }}
          MenuProps={{
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            sx: {
              '.MuiPaper-root ': {
                width: ElementWidth,
                ml: '-10px',
                maxHeight: '20%',
                overflowX: 'auto',
              },
            },
            disableAutoFocusItem: true,
            MenuListProps: {
              disableListWrap: true,
            },
          }}
          onClose={() => {
            setQuery('');
          }}
          readOnly={readOnly}
          disabled={disabled}>
          <SearchFieldWrapper searchFieldPlaceholder={'Search...'} setQuery={setQuery} />
          {renderFilteredOptions(
            getCountryCodes().map((codes) => ({
              key: codes.label,
              value: codes.label,
            })),
            query
          )}
          {/* {getCountryCodes().map((codes, ind) => (
            <MenuItem value={codes.label} key={ind}>
              {codes.label}
            </MenuItem>
          ))} */}
        </Select>
      </Box>
    </InputAdornment>
  );
}
export interface AmlPopupValues {
  amlComments: string;
  amlAcknowledgement: boolean;
}

const amlPopupInitialValues: AmlPopupValues = {
  amlComments: '',
  amlAcknowledgement: false,
};
const amlPopupValidation = yup.object().shape({
  amlComments: yup.string().required('Field is required'),
  amlAcknowledgement: yup.bool().test('amlAcknowledgement', 'Field is required', (value) => {
    return value || false;
  }),
});
export function AmlPopup({
  handleClose,
  amlPopupOpen,
  onSubmit,
  FURTHER_INFORMATION,
  EXTERNAL_SOURCES,
  displayDocInfo = false,
}: {
  handleClose: () => void;
  amlPopupOpen: boolean;
  onSubmit: (values: AmlPopupValues) => void;
  FURTHER_INFORMATION?: string;
  EXTERNAL_SOURCES?: string;
  displayDocInfo?: boolean;
}): JSX.Element {
  return (
    <Dialog
      onClose={handleClose}
      open={amlPopupOpen}
      sx={{
        '.MuiPaper-root ': {
          p: 2,
          width: { xs: '100%', sm: '70%' },
        },
      }}>
      <Box sx={{ mb: 2, mt: 1 }}>
        <CardMedia
          component="img"
          src="/images/warning-icon-red.svg"
          sx={{ width: '50px', mx: 'auto' }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: '100%', sm: '89%' },
          mx: 'auto',
        }}>
        <Typography
          sx={{
            color: '#BD2932',
            textAlign: 'center',
            mb: 1,
            fontSize: 14,
            fontWeight: 500,
          }}>
          Sorry, this PAN has failed the AML check
        </Typography>
        <Typography
          sx={{
            color: 'secondary.main',
            textAlign: 'start',
            mt: 2,
            fontSize: 16,
            fontWeight: 500,
            mb: 0.5,
          }}>
          Further Information:
        </Typography>
        <Box
          sx={{
            width: '100%',
            height: '25vh',
            overflowY: 'auto',
            overflowWrap: 'anywhere',
            border: '1px solid #ccc',
            borderRadius: '5px',
          }}>
          <Typography
            sx={{
              color: 'rgba(0,0,0,0.7)',
              textAlign: 'start',
              m: 2,
              fontSize: 14,
              fontWeight: 500,
            }}>
            {FURTHER_INFORMATION || ''}
          </Typography>
          <Typography
            sx={{
              color: 'primary.main',
              textAlign: 'start',
              ml: 2,
              fontSize: 16,
              fontWeight: 600,
            }}>
            Reference Links
          </Typography>
          <Typography
            sx={{
              color: 'rgb(0, 0, 255)',
              textAlign: 'start',
              fontSize: 14,
              fontWeight: 500,
              m: 2,
              mt: 0.5,
            }}>
            {EXTERNAL_SOURCES || ''}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: 'secondary.main',
            textAlign: 'start',
            fontSize: 14,
            fontWeight: 500,
            mt: 3,
            mb: 0,
          }}>
          If you still wish to proceed with this PAN, please provide reason below.
        </Typography>
        <Formik
          initialValues={amlPopupInitialValues}
          onSubmit={onSubmit}
          validationSchema={amlPopupValidation}>
          {({ handleSubmit, values }) => (
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: 'rgba(0,0,0,0.7)',
                '.MuiTypography-root': {
                  fontSize: 15,
                  fontWeight: 500,
                },
              }}>
              <MFTextField
                name="amlComments"
                multiline
                rows={3}
                inputProps={{
                  maxLength: 1024,
                }}
                placeholder="Type here..."
                displayCharecterCounter={'1024'}
                sx={{
                  color: 'rgba(0,0,0,0.7)',
                  pl: 0.5,
                  pt: 0.5,
                  '&.MuiInputBase-root': {
                    fontSize: 14,
                    fontWeight: 500,
                  },
                }}
              />
              <MFCheckbox
                name={`amlAcknowledgement`}
                label="I understand the risks and acknowledge to proceed further."
                sx={{ mt: 1 }}
              />
              {values.amlAcknowledgement && displayDocInfo && (
                <Typography
                  sx={{
                    color: 'primary.main',
                    textAlign: 'center',
                    fontSize: 14,
                    fontWeight: 500,
                    mt: 1,
                    mb: 0,
                  }}>
                  Please upload compliance document in document details.
                </Typography>
              )}
              <Grid container sx={{ mt: 3, mb: 1, justifyContent: 'center', gap: 1.5 }}>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  variant="outlined"
                  sx={{
                    fontSize: 15,
                    color: 'primary.main',
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 500,
                  }}>
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    fontSize: 15,
                    minWidth: 180,
                    letterSpacing: 2,
                    fontWeight: 400,
                  }}
                  type="submit">
                  Proceed
                </Button>
              </Grid>
            </Box>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
export function AmlDetailsFields({
  amlCheck,
  amlCommentedRole,
  amlComments,
}: {
  amlCheck: boolean | undefined;
  amlCommentedRole: string | undefined;
  amlComments: string | undefined;
}): JSX.Element {
  return (
    <Grid
      item
      container
      rowSpacing={3}
      pb={2}
      columnSpacing={13}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      sx={{ paddingLeft: { xs: 0, sm: '10%' } }}>
      <KeyValue title={'Aml Check'} description={amlCheck?.toString().toUpperCase() || ''} />
      <KeyValue title={'Commented By'} description={amlCommentedRole?.toUpperCase() || ''} />
      <Grid item xs={12}>
        <Item>{'Comments'}</Item>
        <ItemDetails> {amlComments || '-'}</ItemDetails>
      </Grid>
    </Grid>
  );
}
export function AmlDetails({
  applicants = [],
  applicationType,
}: {
  applicants: Partial<Applicant>[];
  applicationType: string;
}): JSX.Element {
  return (
    <>
      {!applicants.every((applicant) => applicant.amlCheck) && (
        <Box
          sx={{
            bgcolor: 'white',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
            borderRadius: '10px',
          }}
          my={5}>
          <Box sx={{ p: 2 }}>
            <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start">
                <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                  Aml Check Details
                </Typography>
              </Grid>
              {applicants
                .filter((applicant) => !applicant.amlCheck)
                .map((applicant, index: number) => {
                  const title = [
                    'First Applicant Details',
                    'Second Applicant Details',
                    'Third Applicant Details',
                  ][Number(applicant.applicant_type) - 1];
                  return (
                    <React.Fragment key={applicant.id}>
                      {applicationType !== APPLICATION_TYPE.NON_INDIVIDUAL && (
                        <SubHeading
                          sx={{
                            color: 'common.black',
                            letterSpacing: 0.5 + 'px',
                            padding: { xs: '10px 10px', sm: '10px 30px' },
                          }}>
                          {title}
                        </SubHeading>
                      )}
                      <AmlDetailsFields
                        amlCheck={applicant.amlCheck}
                        amlCommentedRole={applicant.amlCommentedRole}
                        amlComments={applicant.amlComments}
                      />
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Box>
        </Box>
      )}
    </>
  );
}

export const LoadingDialog = ({
  loadingPopup,
  onLoadingPopupClose,
  loaderText = 'Loading',
}: {
  loadingPopup: boolean;
  onLoadingPopupClose: () => void;
  loaderText?: string;
}): JSX.Element => {
  return (
    <Dialog
      onClose={onLoadingPopupClose}
      open={loadingPopup}
      sx={{
        '.MuiPaper-root ': {
          p: 4,
          width: { xs: '70%', sm: '70%' },
        },
      }}>
      <Box
        sx={{
          width: { xs: '100%', sm: '80%' },
          mx: 'auto',
          '.MuiTypography-root': {
            fontSize: 20,
            fontWeight: 500,
          },
        }}>
        <Typography sx={{ color: 'primary.main', lineHeight: '35px', textAlign: 'center' }}>
          {loaderText}...
        </Typography>
      </Box>
    </Dialog>
  );
};
export const CopyGraphDropdown = ({
  elementRef,
}: {
  elementRef: React.MutableRefObject<HTMLElement | null>;
}): JSX.Element => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const { enqueueSnackbar } = useSnackbar();
  const menuOpen = Boolean(menuAnchorEl);

  const handleClose = () => setMenuAnchorEl(null);
  return (
    <>
      <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
        <MoreVert />
      </IconButton>

      {/* <Backdrop open={menuOpen} onClick={handleClose} sx={{ zIndex: 1201 }}> */}
      <Menu
        anchorEl={menuAnchorEl}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        PaperProps={{ sx: { '.MuiList-root': { padding: 0 } } }}>
        <MenuItem
          onClick={() => {
            handleClose();
            elementRef !== null &&
              copyGraphToClipboard(elementRef as MutableRefObject<HTMLElement>)
                .then(() => {
                  enqueueSnackbar('Graph copied to clipboard successfully.', {
                    variant: 'success',
                    autoHideDuration: 3000,
                  });
                })
                .catch(() => {
                  enqueueSnackbar('Error while copying the graph.', {
                    variant: 'error',
                    autoHideDuration: 3000,
                  });
                });
          }}
          sx={{ padding: '10px 20px' }}>
          Copy
        </MenuItem>
      </Menu>
      {/* </Backdrop> */}
    </>
  );
};

export function PopoverValue({
  label,
  color,
  amount,
  percent,
}: {
  label?: string | null;
  color: string;
  amount?: string | number | null;
  percent?: string | number | null;
}): JSX.Element {
  return (
    <Grid container>
      <Grid item container alignItems={'center'} justifyContent={'center'}>
        <Grid item xs="auto">
          <Typography
            sx={{
              width: '11px',
              height: '11px',
              borderRadius: '50%',
              background: color,
              display: 'inline-block',
              mr: 1,
            }}></Typography>
        </Grid>
        <Grid item xs>
          <Typography sx={{ fontSize: 13, color: '#22242C', opacity: 0.6, mb: 0.5 }}>
            {label}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item xs="auto">
          <Typography
            sx={{
              visibility: 'hidden',
              width: '11px',
              height: '11px',
              borderRadius: '50%',
              background: color,
              display: 'inline-block',
              mr: 1,
            }}></Typography>
        </Grid>
        <Grid item xs>
          <Typography
            sx={{
              fontSize: 13,
              color: '#22242C',
              fontWeight: 500,
            }}>
            {amount}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <Typography component="span" sx={{ fontSize: 12, fontWeight: 400, color: '#22242C' }}>
            {percent ? `${percent}%` : null}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export const AmountUnit = () => {
  const myState = useSelector((state: any) => state.valueConverter);
  const dispatch = useDispatch();

  const handleChange = (event: any) => {
    dispatch({ type: SET_VALUE, value: event.target.value });
  };

  return (
    <>
      <FormControl>
        <Select
          style={{ color: 'var(--title-color)', height: '1.7rem', width: '7rem' }}
          value={myState}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          variant="standard"
          sx={{
            fontSize: 12,
            lineHeight: '16px',
            color: '#4E5056',
            fontWeight: 500,
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
            borderRadius: '5px',
            pl: 1,
            background: ' #FFFFFF',
          }}>
          <MenuItem value={0} style={{ color: 'var(--title-color)' }}>
            Numbers
          </MenuItem>
          <MenuItem value={1} style={{ color: 'var(--title-color)' }}>
            Crores
          </MenuItem>
          <MenuItem value={2} style={{ color: 'var(--title-color)' }}>
            Lakhs
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};


export const PortfolioSelection = ({ val, setValue, setShowImage }: { val: any, setValue: any, setShowImage: any }) => {

  return (
    <>
      <FormControl>
        <Select
          style={{ color: 'var(--title-color)', height: '1.7rem', width: '7rem' }}
          value={val}
          onChange={(e) => {
            setValue(e.target.value)
            setShowImage(false)
          }}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
          variant="standard"
          sx={{
            fontSize: 12,
            lineHeight: '16px',
            color: '#4E5056',
            fontWeight: 500,
            boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
            borderRadius: '5px',
            pl: 1,
            background: ' #FFFFFF',
          }}>
          <MenuItem value={"Muthoot"} style={{ color: 'var(--title-color)' }}>
            Muthoot
          </MenuItem>
          <MenuItem value={"Held Away"} style={{ color: 'var(--title-color)' }}>
            Held Away
          </MenuItem>
          <MenuItem value={"Combined"} style={{ color: 'var(--title-color)' }}>
            Combined
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export function YearSelecter(props: any) {
  return (
    <div
      style={{
        cursor: "pointer",
        flexGrow: 1,
        flexBasis: 0,
        paddingRight: !props.marginH ? props.marginH : 3,
        paddingLeft: !props.marginH ? props.marginH : 3,
        marginLeft: 2,
        marginRight: 2,
        // marginLeft: !!props.marginH ? props.marginH : 3,
        // marginRight: !!props.marginH ? props.marginH : 3,
        borderRadius: 5,
        paddingTop: !props.paddingV ? props.paddingV : 3,
        paddingBottom: !props.paddingV ? props.paddingV : 3,
        backgroundColor: props.isSelected ? "#337FC9" : "transparent",
        border: "1px solid #163869",
      }}
      onClick={props.onPress}
    >
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            color: props.isSelected ? "#FFFFFF" : "#5A7C82",
            alignItems: "center",
          }}
        >
          {props.text}
        </div>
      </div>
    </div>
  );
}

export function TopHoldingButtons(props: any) {
  return (
    <div
      style={{
        display: "flex",
        margin: 10,
        marginBottom: 15,
        borderRadius: 18,
        border:
          // props.selected === props.label
          "1px solid #00A4A4",
        backgroundColor: props.selected === props.label ? '#337FC9' : "#FFFFFF",
        color: props.selected === props.label ? "#FFFFFF" : '#337FC9',
        flexGrow: 1,
        flexBasis: 0,
        padding: 6,
        alignItems: "center",
        justifyContent: "space-between",
        cursor: 'pointer',
      }}
      onClick={props.onPress}
    >
      <div style={{ fontSize: 14, fontWeight: 500 }}>{props.label}</div>
      <div style={{ fontSize: 14, paddingTop: 5 }}>{props.percentage}%</div>
    </div>
  );
}
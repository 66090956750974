import { Location } from 'history';
import {
    Box,
    Breadcrumbs,
    Button,
    Checkbox,
    Divider,
    Grid,
    InputLabel,
    LinearProgress,
    Menu,
    MenuItem,
    Select,
    Skeleton,
    Tab,
    Typography,
    Modal,
    CircularProgress
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import React, { useEffect, useState } from 'react';
import { CommonLayout } from '../../commonComponents';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { BSECodeResponse, GetAssetClassesType, GetCategoryAverage, GetRecommendedSchemes, GetSchemeDetailsType, GetSchemeListType, GetSchemeNav, GetSchemeType, RecommendationsType, SchemeList, SchemeType } from '../../../redux-store/types/api-types';
import { RM_create_transaction, balance_validation, getRmDetails, getSchemeName, get_assest_classes, get_bse_code, get_category_average, get_recommended_schemes, get_scheme_details, get_scheme_list, get_scheme_nav, post_recommended_schemes, redeem_validation } from '../../../redux-store/actions/userManagement';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import "./styles.css"
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import { SwitchErrorType } from './types';
import Swal from 'sweetalert2';
import CancelIcon from '@mui/icons-material/Cancel';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name: string, dataName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            dataName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}
export default function Switch({
    location
}: {
    location: Location<{ investorId: string; investorName: string; holdingName: any }>
}): JSX.Element {

    const { investorId, investorName, holdingName } = location?.state || { investorId: '', investorName: '' };

    const dispatch = useDispatch();
    const history = useHistory();
    const currentPathname = location.pathname;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedValue, setSelectedValue] = useState('Holdings');
    const [value, setValue] = useState<string>("Debt");
    const [schemeData, setSchemeData] = useState<SchemeType[] | null>();
    const [folioName, setFolioName] = React.useState<string[]>([]);
    const [schemeName, setSchemeName] = React.useState<string[]>([]);
    const [amcName, setAmcName] = React.useState<string[]>([]);
    const [accountType, setAccountType] = React.useState<string[]>([]);
    const [switchInSchemeName, setSwitchInSchemeName] = React.useState<string[]>([]);
    const [switchOutSchemeName, setSwitchOutSchemeName] = React.useState<string[]>([]);
    const [rmId, setRmId] = useState<number | null>();
    const [schemeID, setSchemeID] = useState<any>("");
    const [Recommend, setRecommended] = useState<boolean>(false);
    const [switchPageData, setSwitchPageData] = useState<any>({ ...holdingName, switchValue: { value: "", type: "INR" }, switchType: "Partial", InSchemeName: "", InschemeID: "", });
    const [switchBalance, setSwitchBalance] = useState<any>();
    const [radioValue, setRadioValue] = useState<string>('recommend');
    const [newSchemeSch, setNewSchemeSch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [placeOrderResponse, setPlaceOrderResponse] = useState<any>();
    const [balanceLeft, setBalanceLeft] = useState<any>();
    const [astId, setAstId] = useState<string>();

    const handleRadioChange = (event: any, value: any) => {
        setRadioValue(event.target.value);
    };
    // const [submitData, setSubmitData] = useState<{ switchOutSchemeName: string, switchInSchemeName: string, switchType: string, switchValue: number, folio: string }[]>();

    const errorStates: SwitchErrorType = {
        errorSwitchValue: false,
        errorSchemeValue: false,
        errorSwitchValueMsg: "",
        errorSchemeValueMsg: "",
        errorAstId: false,
        errorMessageAstId: ""
    }
    const [error, setError] = useState<SwitchErrorType>(errorStates);
    const AssetclassMapping: any = {
        "Debt": "4",
        "Equity": "1",
        "Hybrid": "3",
        "Liquid": "2"
    }

    //RMids
    useEffect(() => {

        let isComponentActive = true;
        setRmId(null);
        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])


    useEffect(() => {

        // let isComponentActive = true;

        setSchemeData(null);
        switchPageData.InSchemeName = "";
        switchPageData.InschemeID = "";
        switchPageData.switchValue.value = "";
        if (astId) (async function () {
            try {
                // setLoadingAsset(true);
                const response = (await dispatch(
                    getSchemeName(
                        {
                            "AstId": AssetclassMapping[astId],
                            "AmcId": switchPageData.AmcID
                        }
                    )
                )) as unknown as GetSchemeType;
                // if (!isComponentActive) {
                //     return;
                // }
                // setLoadingAsset(false);
                const filteredSchemes = response.schemes[0].filter((val: SchemeType) => {
                    return val['SwitchFLAG'] === "Y"
                });
                setSchemeData(filteredSchemes.filter((ele) => (ele.Sch_ID).split('~')[0] != switchPageData.schemeID))
            } catch (e) {
                // setLoadingAsset(false);
                console.log((e as Error).message);
            }
        })();
    }, [dispatch, astId]);

    async function getRedeemValidations() {
        let isComponentActive = true;
        setSwitchBalance(null);
        (async function () {
            try {
                const response = (await dispatch(
                    redeem_validation({
                        AccountID: switchPageData.AccountID,
                        AccNo: switchPageData.folio,
                        SchId: `${switchPageData.schemeID}`
                    })
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setSwitchBalance(response.validation[0][0]);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    async function getBalanceValidations() {
        let isComponentActive = true;
        setBalanceLeft(null);
        (async function () {
            try {
                const response = (await dispatch(
                    balance_validation({
                        AccountID: switchPageData.AccountID,
                        AccNo: switchPageData.folio,
                        SchId: `${switchPageData.schemeID}`
                    })
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setBalanceLeft(response.redVal[0][0]);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }
    useEffect(() => {
        getRedeemValidations();
        getBalanceValidations();
    }, [])

    //function to handle change of menu - Explore/recommendation
    const handleMenuItemClick = (value: any) => {
        if (value === 'Explore') history.push('/investor-transactions', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Recommendation') history.push('/recommended-schemes', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Holdings') history.push('/holdings', {
            investorId: investorId,
            investorName: investorName,
        });
        else if (value === 'Orders') history.push('/orders', {
            investorId: investorId,
            investorName: investorName,
        });
        setSelectedValue(value);
        handleClose();
    };





    const breadcrumbs = [
        <Typography key="1" sx={{ color: '#BBB5B5 !important' }}>
            Total Investors
        </Typography>,
        <Typography key="2" sx={{ color: '#BBB5B5 !important' }}>
            {investorName}
        </Typography>,
        <Typography key="3" color="text.primary">
            Holdings
        </Typography>,
    ];

    //function to handle change of asset class tabs
    const handleChange = (event: any, value: any) => {
        setValue(value);
    };

    const open = Boolean(anchorEl);

    const handleClick_ = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function roundToTwo(num: number) {
        const num2 = num + "e+2";

        return +(Math.round(Number(num2)) + "e-2");
    }
    const Nanprocessor = function (entry: any) {
        if (entry) {
            return entry;
        } else {
            return 0;
        }
    };



    // const handleAction = (val) => {
    //     setSwtichPageData({ ...switchPageData, InSchemeID: val });
    // }
    const handleChangeAMC = (event: SelectChangeEvent<typeof amcName>) => {
        const {
            target: { value },
        } = event;
        setAmcName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,

        );
    };
    const handleChangeFolio = (event: SelectChangeEvent<typeof folioName>) => {
        const {
            target: { value },
        } = event;
        setFolioName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeAccountType = (event: SelectChangeEvent<typeof accountType>) => {
        const {
            target: { value },
        } = event;
        setAccountType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };
    const handleChangeScheme = (event: SelectChangeEvent<typeof schemeName>) => {
        const {
            target: { value },
        } = event;
        setSchemeName(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleChangeSwitchInScheme = (event: SelectChangeEvent<typeof switchInSchemeName>) => {
        setSwitchPageData({ ...switchPageData, InSwitchName: event.target.value })
    };

    const switchValidation = () => {
        let valid = true;
        const errorTempData: any = {};
        if (radioValue === 'recommend') {
            if (switchPageData.switchValue.value === "" || switchPageData.switchValue.value <= 0) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = "Switch Amount can not be empty"
                // errorTempData({errorSwitchValue: true, errorSwitchValueMsg: "Redemption Amount can not be empty" })
                valid = false;
            }
            else if (switchPageData.switchValue.value && (switchPageData.switchType == "Partial" && switchPageData.switchValue.type === "INR" && Number(switchPageData.switchValue.value) > switchBalance.Amount)) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = `Switch Amount cannot exceed Current Value = ${switchBalance.Amount}`
                valid = false;
            }
            else if (switchPageData.switchValue.value && (switchPageData.switchType == "Partial" && switchPageData.switchValue.type === "Units" && Number(switchPageData.switchValue.value) > switchBalance.Units)) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = `Switch Amount cannot exceed Current Value = ${switchBalance.Units}`
                valid = false;
            }
            else {
                setError({ ...error })
            }
        } else {
            if (switchPageData.switchValue.value === "" || switchPageData.switchValue.value <= 0) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = "Switch Amount can not be empty"
                // errorTempData({errorSwitchValue: true, errorSwitchValueMsg: "Redemption Amount can not be empty" })
                valid = false;
            }
            else if (switchPageData.switchValue.value && (switchPageData.switchType == "Partial" && switchPageData.switchValue.type === "INR" && Number(switchPageData.switchValue.value) > balanceLeft.Amount)) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = `Switch Amount cannot exceed Current Value = ${balanceLeft.Amount}`
                valid = false;
            }
            else if (switchPageData.switchValue.value && (switchPageData.switchType == "Partial" && switchPageData.switchValue.type === "Units" && Number(switchPageData.switchValue.value) > balanceLeft.Units)) {
                errorTempData.errorSwitchValue = true
                errorTempData.errorSwitchValueMsg = `Switch Amount cannot exceed Current Value = ${balanceLeft.Units}`
                valid = false;
            }
            else {
                setError({ ...error })
            }
        }

        if (switchPageData.InSchemeName == "") {
            errorTempData.errorSchemeValue = true,
                errorTempData.errorSchemeValueMsg = "Please select any Scheme"
            valid = false;
        }
        else {
            setError({ ...error })
        }
        if (astId === "" || astId === undefined) {
            errorTempData.errorAstId = true;
            errorTempData.errorMessageAstId = "Please select any asset class";
            valid = false;
        }
        else {
            setError({ ...error })
        }
        setError(errorTempData)
        return valid;
    }
    async function getBSECode(item: any) {
        let isComponentActive = true;

        try {
            const response = (await dispatch(
                get_bse_code({
                    TrType: 'S',
                    Amount: "-1",
                    Growthoption: item?.Growthoption,
                    DividendReinvestment: item?.DividendReinvestment,
                    RTACODE: item?.SCh,
                })
            )) as unknown as BSECodeResponse;
            const response1 = (await dispatch(
                get_bse_code({
                    TrType: 'S',
                    Amount: item.switchValue.value,
                    Growthoption: schemeID.split('~')[1] === 'G' ? 1 : 2,
                    DividendReinvestment: schemeID.split('~')[1] !== 'G' ? 1 : 2,
                    RTACODE: newSchemeSch,
                })
            )) as unknown as BSECodeResponse;
            if (!isComponentActive) {
                return;
            }

            if (response && response.bse_code && response.bse_code.length > 0) {
                item.BSESchemeCode = response.bse_code[0][0]?.BSE_ProductCod;
                item.ToBSESchemeCode = response1.bse_code[0][0]?.BSE_ProductCod
            }
            // setIsLoading(false);
            return item;

        } catch (e) {
            console.log((e as Error).message);
        } finally {
            // setIsLoading(false);
        }

        return () => {
            isComponentActive = false;
        };
    }
    const handleSubmitData = async (switchData: any) => {
        let shouldReturn = false;
        if (!switchValidation()) shouldReturn = true;
        const data: any = [];
        if (radioValue === 'recommend') {
            const obj = {
                "SchemeID": switchData.schemeID,
                "InvestmentAmount": switchData.switchValue.type === 'INR' ? switchData.switchValue.value : 0,
                "SchemeType": "Swt",
                "rmID": rmId,
                "ClientCode": investorId,
                "No_of_Installments": null,
                "SIP_date": "",
                "Frequency": "",
                "AccountID": switchData.AccountID,
                "ToSchemeID": schemeID.split('~')[0],
                "Folio": switchData.folio,
                "Units": switchData.switchValue.type === 'Units' ? Number(switchData.switchValue.value) : 0,
                "PFFlag": switchPageData.switchType === 'Partial' ? "P" : "F"
            }
            data.push(obj);
            const recommendationData = {
                recommendation_data: data
            }
            if (shouldReturn) return;
            await postRecommendedSchemes(recommendationData);
        }
        else {
            await getBSECode(switchData);
            const obj = {
                "AccNo": switchData?.folio,
                "AmcId": switchData?.AmcID,
                "BSE_SchemeCode": switchData?.BSESchemeCode,
                "Amount": switchData.switchValue.value,
                "DividendReinvestment": switchData?.DividendReinvestment,
                "FParFul": switchData.switchType === 'Partial' ? "P" : "F",
                "FUnsAmt": switchData.switchValue.type == "INR" ? "a" : "u",
                "Growthoption": switchData.Growthoption,
                "Sch": switchData.SCh,
                "ToBSE_SchemeCode": switchData?.ToBSESchemeCode,
                "ToSch": newSchemeSch,
                "TrType": "Swt",
                "Units": switchData.switchValue.value,
                "AccountID": switchData.AccountID,
                "rmID": rmId,
                "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
            }
            data.push(obj);
            if (shouldReturn) return;
            const confirmationResult = await Swal.fire({
                html: '<b>Are you sure you want to place this order?</b>',
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, place order",
                customClass: {
                    container: 'my-swal',
                    htmlContainer: 'custom-html-container-class',
                },
            });
            if (confirmationResult.isConfirmed) {
                await create_transaction(data[0])
            }

        }
    }
    async function create_transaction(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    RM_create_transaction(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);
                setPlaceOrderResponse(response)
            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }

    async function postRecommendedSchemes(data: any) {
        let isComponentActive = true;
        (async function () {
            try {
                setIsLoading(true);
                const response = (await dispatch(
                    post_recommended_schemes(data)
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setIsLoading(false);
                setRecommended(true);

            } catch (e) {
                console.log((e as Error).message);
                setIsLoading(false);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }





    // const handleSubmitData=(switchOutSchemeName:string,switchInSchemeName:string,switchType:string,switchValue:number,folio:string)=>{

    // }

    return (
        <div>

            <CommonLayout>
                <>

                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-end', mb: 2, }}>
                        <Button
                            sx={{ border: "1.5px solid #4B81B1", height: '40px', color: '#4B81B1', fontWeight: 600, fontSize: '15px', }}
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick_}
                        >
                            {selectedValue}
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </Button>
                        <Menu
                            sx={{ cursor: "pointer" }}
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={() => handleMenuItemClick('Explore')}>Explore</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Recommendation')}>Recommendation</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Holdings')}>Holdings</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Orders')}>Orders</MenuItem>
                        </Menu>
                    </Box>

                    {/* Section above main card for Breadcrumbs */}
                    <Box
                        sx={{
                            background: '#EEF9F4',
                            borderRadius: '16px',
                            px: 3,
                            py: 1.5,
                            mb: 2.5,
                        }}>
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            sx={{
                                '& .MuiTypography-root': {
                                    fontSize: 20,
                                    lineHeight: '23px',
                                    fontWeight: 500,
                                    color: '#BBB5B5',
                                    '&.MuiTypography-body1': { color: '#22242C' },
                                },
                                '& .MuiBreadcrumbs-separator': {
                                    '& .MuiSvgIcon-root': { color: 'rgba(0,0,0,0.6)' },
                                    '&:last-of-type': {
                                        background: 'red',
                                        '& .MuiSvgIcon-root': { color: '#22242C' },
                                    },
                                },
                            }}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                    <Box
                        sx={{
                            bgcolor: 'common.white',
                            boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                            borderRadius: '5px',
                            py: 2,
                        }}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <ArrowBackIosIcon onClick={() => handleMenuItemClick('Holdings')} sx={{ marginInline: "4rem 1rem", cursor: "pointer" }} />
                            <h3>Switch</h3>
                        </Box>
                        <Divider />
                        <h2 style={{ textAlign: "center", background: "#eef9f4", width: "100%", paddingBlock: "0.5rem" }}><span style={{ fontWeight: "500" }}>Scheme Name</span> - {switchPageData.schemeName}</h2>
                        <FormControl sx={{ px: 8, pt: 2 }}>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleRadioChange}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                    },
                                    '& .MuiFormControlLabel-root': {
                                        color: '#5a7c82'
                                    },
                                    '& .Mui-checked .MuiRadio-root': {
                                        color: '#337FC9',
                                    },
                                    '& .MuiRadio-root:not(.Mui-checked)': {
                                        color: '#5a7c82'
                                    },
                                }}
                            >

                                <FormControlLabel value="recommend" control={<Radio />} label="Recommend" />
                                <FormControlLabel value="onbehalf" control={<Radio />} label="On behalf of client" />
                                <FormControlLabel value="onrequest" control={<Radio />} label="Requested by client" />

                            </RadioGroup>
                        </FormControl>
                        <Box sx={{ width: "60%", marginLeft: "4rem", mt: "1rem" }}>
                            {/* <Box className="form" >
                                <p >Select AMC</p>
                                <FormControl sx={{ m: 1, width: 800, mt: 2 }}>
                                    <Select
                                        displayEmpty
                                        value={amcName}
                                        onChange={handleChangeAMC}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>Select AMC</em>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                
                                        {amcNameArray.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, amcName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box> */}
                            <Box className="form">
                                <p >Selected Folio</p>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                    <FormControl sx={{ width: "100%", mt: 2 }}>
                                        <Select
                                            disabled
                                            displayEmpty
                                            value={folioName}
                                            onChange={handleChangeFolio}
                                            input={<OutlinedInput />}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <em>{switchPageData.folio}</em>;
                                                }

                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >

                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            {/* <Box className="form">
                                <p style={{ fontWeight: "500" }}>Select Account Type</p>
                                <FormControl sx={{ m: 1, width: 800, mt: 2 }}>
                                    <Select
                                        
                                        displayEmpty
                                        value={accountType}
                                        onChange={handleChangeAccountType}
                                        input={<OutlinedInput />}
                                        renderValue={(selected) => {
                                            if (selected.length === 0) {
                                                return <em>{investorName}</em>;
                                            }

                                            return selected.join(', ');
                                        }}
                                        MenuProps={MenuProps}
                                        inputProps={{ 'aria-label': 'Without label' }}
                                    >
                                
                                        {accountTypeArray.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, accountType, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box> */}
                            <Box className="form">
                                <p>Select Switch Out Scheme</p>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                    <FormControl sx={{ width: "100%", mt: 2 }}>
                                        <Select
                                            disabled
                                            displayEmpty
                                            value={switchPageData.schemeName}
                                            // onChange={handleChangeSwitchOutScheme}
                                            input={<OutlinedInput />}
                                            renderValue={(selected: any) => {
                                                if (selected.length === 0) {
                                                    return <em>{switchPageData.schemeName}</em>;
                                                }

                                                return selected;
                                            }}
                                            MenuProps={MenuProps}
                                            inputProps={{ 'aria-label': 'Without label' }}
                                        >

                                            {/* {schemeNameArray.map((name) => (
                                            <MenuItem
                                                key={name}
                                                value={name}
                                                style={getStyles(name, switchOutSchemeName, theme)}
                                            >
                                                {name}
                                            </MenuItem>
                                        ))} */}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                            <Box className="form">
                                <p>Asset Class <b style={{ color: 'red' }}>*</b></p>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                    <FormControl sx={{ mt: 2, width: "100%" }}>
                                        <Select
                                            labelId="demo-simple-select-autowidth-label"
                                            id="demo-simple-select-autowidth"
                                            displayEmpty
                                            value={astId}
                                            onChange={(e: any) => {
                                                setAstId(e.target.value)

                                            }}
                                            input={<OutlinedInput />}

                                            renderValue={(selected: any) => {
                                                if (selected === "" || selected == undefined) {

                                                    return <em>Select Asset Class</em>;
                                                }

                                                return selected;
                                            }}


                                        >
                                            {Object.keys(AssetclassMapping).map((assetClassName, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={assetClassName}
                                                >
                                                    {assetClassName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {error.errorAstId && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorMessageAstId} </Typography>}
                                </Box>
                            </Box>
                            {astId &&
                                <> <Box className="form">
                                    <p>Select Switch In Scheme <b style={{ color: 'red' }}>*</b></p>
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                        <FormControl sx={{ width: "100%", mt: 2 }}>
                                            <Select
                                                labelId="demo-simple-select-autowidth-label"
                                                id="demo-simple-select-autowidth"
                                                displayEmpty
                                                value={switchPageData.InSchemeName}
                                                onChange={(e: any) => {
                                                    setSwitchPageData({ ...switchPageData, InSchemeName: e.target.value })
                                                }}
                                                input={<OutlinedInput />}
                                                renderValue={(selected: any) => {
                                                    if (selected.length === 0) {
                                                        return <em>Select Scheme</em>;
                                                    }

                                                    return selected;
                                                }}
                                                autoWidth
                                            >

                                                {schemeData?.map((val: SchemeType, index: any) =>
                                                (
                                                    <MenuItem
                                                        onClick={() => { setSchemeID(val.Sch_ID), setNewSchemeSch(val.Productcode_RTA) }}
                                                        key={index}

                                                        value={val.Sch_Desc}
                                                    // style={getStyles(name, schemeName, theme)}
                                                    >
                                                        {val.Sch_Desc}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        {error.errorSchemeValue && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {error.errorSchemeValueMsg} </Typography>}
                                    </Box>
                                </Box>
                                    <Box className="form" >
                                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: 'center', justifyContent: "space-between", width: 800 }}>
                                            <FormLabel id="demo-row-radio-buttons-group-label" sx={{ fontWeight: 500, mt: 3, mb: 3 }}>Switch Type</FormLabel>
                                            <RadioGroup sx={{ paddingRight: "11.5rem" }}
                                                row
                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                name="row-radio-buttons-group"
                                                value={switchPageData.switchType}
                                                onChange={(e: any) => {

                                                    if (radioValue === 'recommend') {
                                                        setSwitchPageData({ ...switchPageData, switchType: e.target.value, switchValue: { value: e.target.value === "Partial" ? "" : switchPageData.switchValue.type === "INR" ? switchBalance.Amount : switchBalance.Units, type: switchPageData.switchValue.type } })
                                                    }
                                                    else {
                                                        setSwitchPageData({ ...switchPageData, switchType: e.target.value, switchValue: { value: e.target.value === "Partial" ? "" : switchPageData.switchValue.type === "INR" ? balanceLeft.Amount : balanceLeft.Units, type: switchPageData.switchValue.type } })
                                                    }

                                                }}
                                            >
                                                <FormControlLabel value="Partial" control={<Radio />} label="Partial" />
                                                <FormControlLabel value="Full" control={<Radio />} label="Full" />
                                            </RadioGroup>
                                        </Box>
                                    </Box>

                                    <Box className="form">
                                        <p>Switch value <b style={{ color: 'red' }}>*</b></p>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: 'flex-start', width: 800 }}>
                                            {radioValue === 'recommend' ? <> {switchBalance ? <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Maximum ${switchPageData.switchValue.type === "INR" ? `₹${switchBalance.Amount}` : switchBalance.Units}`}
                                                value={switchPageData.switchType === "Partial" ? switchPageData.switchValue.value : switchPageData.switchValue.type === "INR" ? switchBalance.Amount : switchBalance.Units}

                                                onChange={(e) => {
                                                    setSwitchPageData({ ...switchPageData, switchValue: { ...switchPageData.switchValue, value: e.target.value } })
                                                }}
                                            ></input> : ''}
                                            </> :
                                                <>{balanceLeft ? <input style={{ width: "100%", height: 60, marginTop: "1rem", marginRight: "0.5rem", border: "1px solid #c4c4c4", borderRadius: '5px' }} type='number' placeholder={`Maximum ${switchPageData.switchValue.type === "INR" ? `₹${balanceLeft.Amount}` : balanceLeft.Units}`}
                                                    value={switchPageData.switchType === "Partial" ? switchPageData.switchValue.value : switchPageData.switchValue.type === "INR" ? balanceLeft.Amount : balanceLeft.Units}

                                                    onChange={(e) => {
                                                        setSwitchPageData({ ...switchPageData, switchValue: { ...switchPageData.switchValue, value: e.target.value } })
                                                    }}
                                                ></input> : ''}
                                                </>
                                            }
                                            {error.errorSwitchValue && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {error.errorSwitchValueMsg} </Typography>}
                                            <RadioGroup sx={{ display: "flex", flexDirection: "row" }}

                                                onChange={(e: any) => {
                                                    setSwitchPageData({ ...switchPageData, switchValue: { ...switchPageData.switchValue, type: e.target.value } })
                                                }}
                                                value={switchPageData.switchValue.type}
                                            >
                                                <FormControlLabel value="INR" control={<Radio />} label="INR" />
                                                <FormControlLabel value="Units" control={<Radio />} label="Units" />
                                            </RadioGroup>
                                        </Box>


                                    </Box>
                                </>}


                        </Box>

                        <Box sx={{ marginTop: "2rem" }}>
                            <Button variant="contained" style={{ width: '33.5%', marginLeft: "22rem" }}
                                onClick={() => { handleSubmitData(switchPageData); }}
                            >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : <> {radioValue === 'recommend' ? "Recommend" : radioValue === 'onbehalf' ? "Place Order On Behalf of Client" : "Place Order Requested by Client"} </>}</Button>
                        </Box>
                        <Typography sx={{ color: 'red', fontSize: '12px', mx: 7 }}> * Mandatory fields</Typography>
                    </Box>
                    {Recommend ?
                        <Modal
                            open={Recommend}
                            onClose={() => setRecommended(false)}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={{
                                position: 'absolute' as any,
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 400,
                                bgcolor: 'background.paper',
                                borderRadius: '10px',
                                boxShadow: 24,
                                p: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}>
                                {radioValue === 'recommend' ?
                                    <> <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                        <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
                                    </>
                                    :
                                    <>

                                        {placeOrderResponse === undefined || placeOrderResponse === null || placeOrderResponse.error ?
                                            < >
                                                <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />
                                                <Typography sx={{ textAlign: "center" }}>Order Failed</Typography>
                                                <Typography sx={{ textAlign: "center" }}>Order ID not generated</Typography>
                                            </>
                                            :
                                            <>
                                                {placeOrderResponse.transaction_created[0].order_success ?
                                                    <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                                                    : <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />}
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_success ? 'Order Placed' : 'Order Failed'}</Typography>
                                                <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_status}</Typography>
                                            </>
                                        }
                                    </>
                                }
                                <Button onClick={() => { setRecommended(false); handleMenuItemClick('Holdings') }} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
                            </Box>
                        </Modal> : <></>}

                </>

            </CommonLayout>

        </div>
    );
}


import { CALL_API } from '../middleware';
import {
  GET_DASHBOARD_REPORT,
  GET_DASHBOARD_REPORT_SUCCESS,
  GET_ONBOARDING_REPORT,
  GET_ONBOARDING_REPORT_SUCCESS,
  GET_REPORT_SELECTION_SUCCESS,
} from '../types/investorReports';

export const getInvestorReports =
  (body: any) =>
  async (dispatch: any): Promise<any> => {
    if(body.reportName!="ComprehensivePortfolio"){
      body={...body,level: 'Individual',}
    }
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/generateInvestorReports`,
        method: 'POST',
        types: [GET_ONBOARDING_REPORT, GET_ONBOARDING_REPORT_SUCCESS],
        body: {
          ...body,
        },
      },
    });
  };
  export const getDashboardReports =
  (body: any) =>
  async (dispatch: any): Promise<any> => {
    console.log("hello console")
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/generateInvestorReports`,
        method: 'POST',
        types: [GET_DASHBOARD_REPORT, GET_DASHBOARD_REPORT_SUCCESS],
        body: {
          ...body,
       
        },
      },
    });
  };

export const getReportSelectionList =
  () =>
  async (dispatch: any): Promise<any[]> => {
    return await dispatch({
      [CALL_API]: {
        url: `/onboarding/reports/getReportList`,
        method: 'GET',
        types: [GET_REPORT_SELECTION_SUCCESS, GET_REPORT_SELECTION_SUCCESS],
      },
    });
  };

import { Box, LinearProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { RootStateType } from '../../redux-store/reducers';
import {
  ProductSchemeDetailsType,
  ProductTransactionsEntity,
} from '../../redux-store/types/api-types';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import { formatLongDate } from '../../utils/date';
import { AmountUnit } from '../commonComponents';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function TransactionDetails({
  investorId,
  selectedFilters,
  selectedPortfolio
}: {
  investorId: string;
  selectedPortfolio: number;
  selectedFilters: { [key: string]: string[] };
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [schemeDetailsData, setSchemeDetailsData] = useState<ProductSchemeDetailsType | null>(null);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setSchemeDetailsData(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'MUTUAL FUNDS',
            HFlag:selectedPortfolio,
            rmID: responseRM.Header_ID,
            filters: {
              ...selectedFilters,
              customers: investorId ? [investorId] : [],
            },
            required: ['schemeDetails'],
          })
        )) as unknown as ProductSchemeDetailsType;
        if (!isComponentActive) {
          return;
        }
        setSchemeDetailsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedFilters, selectedPortfolio]);

  const headers = [
    {
      header: 'Scheme',
      sortingKey: 'ProductName',
      valueGetter: (row: ProductTransactionsEntity) => row?.ProductName || 'N/A',
    },
    {
      header: 'Scheme Category',
      sortingKey: 'Sub_AssetclassName',
      valueGetter: (row: ProductTransactionsEntity) => row?.Sub_AssetclassName || 'N/A',
    },
    {
      header: 'Transaction Amount',
      sortingKey: 'Amount',
      valueGetter: (row: ProductTransactionsEntity, amountUnit?: number | null | undefined) =>
        row?.Amount || row?.Amount === 0 ? formatToIndianCurrency(row?.Amount, amountUnit) : 'N/A',
    },
    {
      header: 'Transaction Type',
      sortingKey: 'TranType',
      valueGetter: (row: ProductTransactionsEntity) => row?.TranType || 'N/A',
    },
    {
      header: 'Transaction Date',
      sortingKey: 'TranDt',
      valueGetter: (row: ProductTransactionsEntity) =>
        row.TranDt ? formatLongDate(row.TranDt) : 'N/A',
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          mb: 3
        }}>
        <AmountUnit />
        {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
      </Box>
      <Box
        sx={{
          mb: 7,
        }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box>
            {schemeDetailsData && (
              <>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={headers}
                  tableData={schemeDetailsData?.schemeTransactions}
                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                    background: 'transparent',
                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: 'unset', maxHeight: '500px', minWidth: 450 }}
                  customEmptyMessage="No transactions undertaken in the last 30 days"
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

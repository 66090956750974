import { Box, LinearProgress, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../redux-store/actions/userManagement';
import { RootStateType } from '../../redux-store/reducers';
import {
  ProductSchemeDetailsEntity,
  ProductSchemeDetailsType,
} from '../../redux-store/types/api-types';
import { AmountUnit } from '../commonComponents';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { DataTable } from '../DataTable';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function SchemeDetails({
  investorId,
  selectedFilters,
  selectedPortfolio
}: {
  investorId: string;
  selectedPortfolio: number;
  selectedFilters: { [key: string]: string[] };
}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [schemeDetailsData, setSchemeDetailsData] = useState<ProductSchemeDetailsType | null>(null);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setSchemeDetailsData(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'MUTUAL FUNDS',
            rmID: responseRM.Header_ID,
            HFlag: selectedPortfolio,
            filters: {
              ...selectedFilters,
              customers: investorId ? [investorId] : [],
            },
            required: ['schemeDetails'],
          })
        )) as unknown as ProductSchemeDetailsType;
        if (!isComponentActive) {
          return;
        }
        setSchemeDetailsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedFilters, selectedPortfolio]);

  const headers = [
    {
      header: 'Scheme',
      sortingKey: 'Product',
      valueGetter: (row: ProductSchemeDetailsEntity) => (
        <Typography sx={{ maxWidth: 400, fontSize: 14, lineHeight: '20px' }}>
          {row?.Product || 'N/A'}
        </Typography>
      ),
    },
    {
      header: 'Scheme Category',
      sortingKey: 'I_AssetClass',
      valueGetter: (row: ProductSchemeDetailsEntity) => row?.I_AssetClass || 'N/A',
    },
    {
      header: 'Investment Amount',
      sortingKey: 'I_PurValue',
      valueGetter: (row: ProductSchemeDetailsEntity, amountUnit?: number | null | undefined) =>
        row.I_PurValue || row.I_PurValue === 0 ? formatToIndianCurrency(row?.I_PurValue, amountUnit) : 'N/A',
    },
    {
      header: 'Current Value',
      sortingKey: 'I_MarKetValue',
      valueGetter: (row: ProductSchemeDetailsEntity, amountUnit?: number | null | undefined) =>
        row?.I_MarKetValue || row?.I_MarKetValue === 0
          ? formatToIndianCurrency(row?.I_MarKetValue, amountUnit)
          : 'N/A',
    },
    {
      header: 'Gain/Loss',
      sortingKey: 'GainorLoss',
      valueGetter: (row: ProductSchemeDetailsEntity) => (
        <Typography sx={{ color: row.GainorLoss < 0 ? '#DC3636' : 'inherit', pr: 3 }}>
          {row.GainorLoss || row.GainorLoss === 0 ? `${row.GainorLoss}%` : 'N/A'}
        </Typography>
      ),
    },
  ];
  return (
    <Box>
      <Box
        sx={{
          mb: 3
        }}>
        <AmountUnit />
        {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
      </Box>
      <Box
        sx={{
          mb: 7,
        }}>
        {isLoading ? (
          <LinearProgress />
        ) : (
          <Box>
            {schemeDetailsData && (
              <>
                <DataTable
                  isLoading={isLoading}
                  tableHeader={headers}
                  tableData={schemeDetailsData?.schemeDetails}
                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                    background: 'transparent',
                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: 'unset', maxHeight: '500px', minWidth: 450 }}
                />
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
}

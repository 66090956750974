import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
  downloadMultipleFiles,
  formatDate,
  getFromToDates,
  replaceLetterOWithW,
} from '../../utils/utilityFunctions';
import { getInvestorReports } from '../../redux-store/actions/investorReports';
import { BpRadio, ReportSectionContainer, ReportsRightLayout } from './index';
import { TextDatePicker } from '../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../lib/formik';
import { validationSchemaCgStatement } from '../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';

//! Radio Options
const options = [
  { label: 'Previous FY', value: 'previous-fy' },
  { label: 'Current FY', value: 'current-fy' },
  { label: 'Custom Date', value: 'custom' },
];

const CGStatement = ({
  location
}: {
  location: Location<{ investorId: string; investorName: string }>
}) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { investorId, investorName } = location?.state || { investorId: '', investorName: '' };
  const history = useHistory();
  const onSubmit = async (values: any) => {
    try {
      const { fromDate: from, toDate: to } = getFromToDates(values.duration);
      const { fileType, fromDate, toDate, duration } = values;

      if (investorId) {
        const res = await dispatch(
          getInvestorReports({
            reportName: 'CgStatement',
            // clientId: [replaceLetterOWithW(investorId) || ''],
            clientId: [investorId || ''],
            fileType,
            fromDate: from || formatDate(fromDate),
            toDate: to || formatDate(toDate),
          })
        );
        downloadMultipleFiles(res);
        enqueueSnackbar('File(s) downloaded successfully.', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
    } catch (e) {
      console.error((e as Error).message);
    }
  };

  return (
    <ReportsRightLayout>
      <Typography
        sx={{
          color: 'text.secondary',
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '19px',
          letterSpacing: '0.06em',
          textTransform: 'uppercase',
          display: ['none', 'none', 'block'],
        }}>
        CG Statement
      </Typography>

      <Formik
        validationSchema={validationSchemaCgStatement}
        initialValues={{
          duration: options[0].value,
          fromDate: new Date(),
          toDate: new Date(),
          fileType: 'pdf',
        }}
        onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>

            <ReportSectionContainer>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0.01em',
                  pt: 1,
                  pb: 1,
                }}>
                Choose duration of statement -
              </Typography>
              <Box>
                <FormControl sx={{ width: '100%' }}>
                  <RadioGroup
                    name={'duration'}
                    value={values.duration}
                    onChange={(event) => {
                      setFieldValue('duration', event.currentTarget.value);
                    }}
                    sx={{
                      display: 'grid',
                      gridTemplateColumns: ['1fr 1fr', '1fr 1fr 1fr'],
                      gridColumnGap: 8,
                      gridRowGap: '1rem',
                    }}>
                    {options.map(({ label, value }) => (
                      <FormControlLabel
                        key={value}
                        value={value}
                        control={<BpRadio />}
                        label={label}
                      />
                    ))}
                  </RadioGroup>
                  {values.duration === 'custom' && (
                    <>
                      <TextDatePicker
                        label={'From Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'rgba(0,0,0,0.7)',
                        }}
                        applyLabelStyles={true}
                        placeholder={'Enter Net worth as on Date'}
                        name={`fromDate`}
                      />
                      <TextDatePicker
                        label={'To Date'}
                        inputLabelStyles={{
                          transform: 'unset',
                          fontSize: 14,
                          fontWeight: 500,
                          color: 'rgba(0,0,0,0.7)',
                        }}
                        applyLabelStyles={true}
                        placeholder={'Enter Net worth as on Date'}
                        name={`toDate`}
                      />
                    </>
                  )}
                </FormControl>
              </Box>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '22px',
                  letterSpacing: '0.01em',
                  pt: 2,
                  pb: 1,
                }}>
                Report download options
              </Typography>

              <FormControl>
                <RadioGroup
                  name={'fileType'}
                  value={values.fileType}
                  onChange={(event) => {
                    setFieldValue('fileType', event.currentTarget.value);
                  }}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: 1,
                  }}>
                  <FormControlLabel value="pdf" control={<BpRadio />} label="PDF" />
                  <FormControlLabel value="xlsx" control={<BpRadio />} label="Excel" />
                </RadioGroup>
              </FormControl>
            </ReportSectionContainer>
            <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
              <MFSubmitButton
                label="Download"
              // disabled={selectedFamilyMembers[0] === 'overall-family'}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </ReportsRightLayout>
  );
};

export default CGStatement;

import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import { PmsStrategyDetailsType, PmsTransactionDetail } from '../../../redux-store/types/api-types';
import { formatLongDate } from '../../../utils/date';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../../DataTable';
import { MFTextField } from '../../../lib/formik';
import { Search } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { AmountUnit } from '../../commonComponents';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

const headers: ColumnType[] = [
  {
    header: 'Strategy',
    sortingKey: 'Sub_AssetclassName',
    valueGetter: (row: PmsTransactionDetail) => row?.Sub_AssetclassName || 'N/A',
  },
  {
    header: 'Security Name',
    sortingKey: 'ProductName',
    valueGetter: (row: PmsTransactionDetail) => row?.ProductName || 'N/A',
  },
  {
    header: 'Transaction Type',
    sortingKey: 'TranType',
    valueGetter: (row: PmsTransactionDetail) => row?.TranType || 'N/A',
  },
  {
    header: 'Transaction Date',
    sortingKey: 'TranDt',
    valueGetter: (row: PmsTransactionDetail) => formatLongDate(row?.TranDt) || 'N/A',
  },
  {
    header: 'Transaction Amount',
    sortingKey: 'Amount',
    valueGetter: (row: PmsTransactionDetail, amountUnit?: number | undefined | null) =>
      row?.Amount || row.Amount === 0 ? formatToIndianCurrency(row.Amount, amountUnit) : 'N/A',
  },
];

function TransactionDetails({ investorId, selectedPortfolio }: { investorId: string, selectedPortfolio: number }): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [transactionDetails, setTransactionDetails] = useState<PmsStrategyDetailsType | null>(null);
  const [search, setSearch] = useState<string>('');
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setTransactionDetails(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            productLevel: 'PMS',
            HFlag:selectedPortfolio,
            rmID: responseRM.Header_ID,
            filters: {
              customers: investorId ? [investorId] : [],
            },
            required: ['transactionDetails'],
          })
        )) as unknown as PmsStrategyDetailsType;
        if (!isComponentActive) {
          return;
        }
        setTransactionDetails(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [selectedPortfolio]);
  return (
    <Box sx={{ maxHeight: '60vh' }}>
      <Box
        sx={{
          mb: 2
        }}>
        <AmountUnit />
        {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
      </Box>
      <Formik
        initialValues={{}}
        onSubmit={() => {
          //
        }}>
        <Form>
          <MFTextField
            placeholder={'Search Strategy'}
            name={'strategy'}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            endAdornment={<Search sx={{ pr: 0.5, color: '#000', opacity: 0.4 }} />}
            sx={{
              width: { md: '12rem', lg: '20rem' },
              '& .MuiInputBase-root': {
                fontSize: { md: 12, lg: 14 },
                border: '0 !important',
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                ':hover': {
                  border: 0,
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                },
              },
            }}
          />
        </Form>
      </Formik>
      <DataTable
        isLoading={isLoading}
        tableHeader={headers}
        tableData={transactionDetails?.transactionDetails.filter((ele) =>
          ele?.Sub_AssetclassName?.toLowerCase().includes(search.toLowerCase())
        )}
        boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
        customEmptyMessage="No transactions undertaken in the last 30 days"
      />
    </Box>
  );
}

export default TransactionDetails;

import { Box, Button, Divider, Modal, Tab, Typography, Skeleton, InputAdornment, OutlinedInput, FormControl, Accordion, AccordionSummary, AccordionDetails, Menu, MenuItem, FormGroup, FormControlLabel, Checkbox, Badge } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SearchIcon from '@mui/icons-material/Search';
import "./styles.css";
import { useEffect, useState } from "react";
import { RecommendedSchemesModal, SubFundCategoryCard } from "./investor-transaction-helper";
import { SchemeList, SubAssetClasses, SubAssetclassName } from "../../../redux-store/types/api-types";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Filters, { filterDataExplore } from "./filterComponent";
import { SET_SCHEME_FOR_BASKET } from '../../../redux-store/types/globalVariables';
import { useDispatch, useSelector } from 'react-redux';


export default function Scheme_List({
  handleBackClick,
  selectedSubAssetClass,
  setSelectedSubAssetClass,
  handleSetViewSchemeDetails,
  sub_asset_classes,
  schemeList,
  investorId
}: {
  handleBackClick: () => void;
  selectedSubAssetClass: SubAssetClasses | undefined;
  setSelectedSubAssetClass: any;
  handleSetViewSchemeDetails: (val: boolean, schemeDetail: SchemeList) => void;
  sub_asset_classes: SubAssetClasses[] | undefined;
  schemeList: SchemeList[] | undefined;
  investorId: string;
}) {

  const routes = sub_asset_classes?.map((item) => ({
    key: item.Sub_AssetclassName,
    title: item.Sub_AssetclassName,
    ID: item.ID,
    mainTitle: item.AssetClassName,
    description: item.Descripti,
    imageUrl: item.ImageURL,
  }));
  const dispatch = useDispatch();
  const globalVariables = useSelector((state: any) => state.globalVariable);
  const [value, setValue] = useState<string>(selectedSubAssetClass ? selectedSubAssetClass.Sub_AssetclassName : "");
  const [Recommend, setRecommended] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const [AMCList, setAMCList] = useState<{ AMC: string, AMCId: number }[]>([]);
  const [filterData, setFilterData] = useState<filterDataExplore>({
    AMCList: [],
    schemeType: []
  });
  const [filteredSchemeList, setFilteredSchemeList] = useState<any>(schemeList);

  const handleChange = (event: any, value: any) => {
    setValue(value);
    const temp = sub_asset_classes?.filter((ele) => ele.Sub_AssetclassName == value);
    setSelectedSubAssetClass(temp ? temp[0] : selectedSubAssetClass);
  };

  const [checkedSchemes, setCheckedSchemes] = useState<SchemeList[]>([]);
  const [openModal, setOpenModal] = useState(false);

  const handleModalOpen = () => { setOpenModal(true) };
  const handleModalClose = () => setOpenModal(false);

  // useEffect(() => {
  //   console.log(Recommend);
  //   if (Recommend === false) handleModalClose();
  // }, [Recommend]);

  const OnSchemeSelection = (schemeId: number) => {
    const selectedScheme = schemeList?.filter((val) => val.SchemeID === schemeId);
    selectedScheme ? setCheckedSchemes([...checkedSchemes, selectedScheme[0]]) : "";
    selectedScheme ? dispatch({ type: SET_SCHEME_FOR_BASKET, value: [...globalVariables, selectedScheme[0]] }) : "";
  }

  const OnSchemeDeSelection = (schemeId: number) => {
    const schemeList = checkedSchemes.filter((val) => val.SchemeID != schemeId);
    setCheckedSchemes(schemeList);
    const schemeListGlobal = globalVariables.filter((val: any) => val.SchemeID != schemeId);
    dispatch({ type: SET_SCHEME_FOR_BASKET, value: schemeListGlobal });
  }

  const handleOpen = () => setRecommended(true);
  const handleClose = () => { setRecommended(false); setOpenModal(false); setCheckedSchemes([]); }


  //Getting Unique AMC names for filter
  useEffect(() => {
    const uniqueAMCIds = new Set<number>();
    const AmcList: { AMC: string, AMCId: number }[] = [];

    schemeList?.forEach((value: SchemeList) => {
      if (!uniqueAMCIds.has(value.AmcID)) {
        AmcList.push({ AMC: value.AMC, AMCId: value.AmcID });

        uniqueAMCIds.add(value.AmcID);
      }
    });
    setAMCList(AmcList.sort((a: any, b: any) => a.AMC.toLowerCase().localeCompare(b.AMC.toLowerCase())));
  }, [])

  //Set Filtered Data
  useEffect(() => {
    if (filterData.AMCList.length == 0 && filterData.schemeType.length == 0 && search == '') {
      setFilteredSchemeList(schemeList);
    }
    else {
      // AMC Filter Logic
      let filteredList: any = schemeList;
      if (filterData.AMCList.length !== 0) {
        filteredList = schemeList?.filter((scheme: SchemeList) =>
          filterData.AMCList.some((selectedAMC) => selectedAMC.AMCId === scheme.AmcID)
        )
        setFilteredSchemeList(filteredList);
      }

      // Search AMC Name Filter Logic
      let filteredList2: any = filteredList;
      if (search !== "") {
        filteredList2 = filteredList?.filter((item: any) =>
          Object.values(item).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))
        );
        setFilteredSchemeList(filteredList2);
      }

      // Scheme Type Filter Logic
      let filteredList3: any = filteredList2;
      if (filterData.schemeType.length !== 0) {
        filteredList3 = filteredList2?.filter((scheme: SchemeList) => {
          const isGrowth = filterData.schemeType.includes('Growth');
          const isDiv = filterData.schemeType.includes('Dividend');

          if (isGrowth && isDiv) {
            return true;
          } else if (isGrowth) {
            return scheme.Growthoption === 1;
          } else if (isDiv) {
            return scheme.Dividendreinvestment === 1 || scheme.DividendPayout === 1;
          } else {
            return true;
          }
        });

        setFilteredSchemeList(filteredList3);
      }

    }
  }, [filterData, search])

  return (
    <div style={{ width: "100%" }}>
      <FormControl sx={{ width: 225, mx: 3, mb: 2 }} variant="outlined">
        <OutlinedInput
          value={search}
          onChange={({ target: { value } }) => {
            setSearch(value);
          }}
          sx={{ '& .MuiOutlinedInput-input': { py: 1, fontSize: 13 } }}
          id="search-field"
          placeholder="Search by Scheme Name"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon fontSize="small" />
            </InputAdornment>
          }
        />
      </FormControl>
      <Box
        sx={{
          width: '100%',
          '& .MuiTabPanel-root': { py: 2, px: 0 },
          '& .MuiTab-root': {
            color: '#A1A2A2',
            opacity: 0.8,
            fontSize: 17,
            lineHeight: '24px',
            textTransform: 'capitalize',
            px: { xs: 2, md: 3, lg: 5 },
            '&.Mui-selected': {
              color: '#4B81B1',
            },
          },
          '& .MuiTabs-indicator': {
            height: 3,
            background: '#4B81B1',
          },
        }}>
        {/* Header */}
        <Box sx={{ px: 3, display: 'flex', alignItems: 'center', width: '100%', mb: 3 }}>

          <ArrowBackIosIcon sx={{ cursor: 'pointer' }} onClick={handleBackClick} />
          <Box sx={{ display: 'flex', alignItems: 'center', mx: 3, width: '73%' }}>
            <img
              src={selectedSubAssetClass?.ImageURL}
              style={{ width: 60, height: 60 }}
              alt=""
            />
            <div className="line"></div>
            <div style={{ marginLeft: 20, paddingTop: 5 }}>
              <div>{selectedSubAssetClass?.Sub_AssetclassName}</div>
              <div className="fundCatdesc">{selectedSubAssetClass?.Descripti}</div>
            </div>
          </Box>

          <Badge
            badgeContent={globalVariables.length}
            color="error"
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', paddingRight: '20px' }}
          >
            <Button
              sx={{
                marginLeft: '20px',
                height: '27px',
                color: 'white',
                fontSize: '13px',
                cursor: 'pointer',
                borderRadius: '5px',
                bgcolor: '#337FC9',
                '&:hover': { backgroundColor: '#337FC9' },
              }}
              onClick={handleModalOpen}
            >{globalVariables.length < 2 ? 'Transaction' : 'Recommend'}</Button>
          </Badge>
          <Filters
            AMCList={AMCList}
            filterData={filterData}
            setFilterData={setFilterData}
          />

        </Box>
        <Divider />
        {/* Tabs */}
        <TabContext value={value}>
          <Box sx={{ px: 3 }}>
            <TabList
              onChange={handleChange}
              aria-label="product tabs"
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                '& .MuiTabList-flexContainer': {
                  fontSize: "5px"
                },
                // '& .MuiTabs-indicator':{
                //   height: '2px'
                // }
              }}>
              {routes?.map((item: any) => {
                return (
                  <Tab label={item.title} value={item.key} key={item.key}
                  // style={{fontSize:'15px'}} 
                  />
                )
              })}

            </TabList>
          </Box>
          <Divider />
          <Box sx={{ px: 3 }}>
            {routes?.map((item: any) => {
              return (
                <TabPanel value={item.key} key={item.key}>
                  <>
                    {filteredSchemeList?.sort((a: any, b: any) => a.SchemeName.toLowerCase().localeCompare(b.SchemeName.toLowerCase())).map((val: SchemeList) => {
                      return (
                        <><SubFundCategoryCard
                          onSchemeSelection={OnSchemeSelection}
                          schemeDetail={val}
                          OnSchemeDeSelection={OnSchemeDeSelection}
                          handleSetViewSchemeDetails={handleSetViewSchemeDetails}
                          allocation={false}
                          selectedSchemes={checkedSchemes}
                          openModal={openModal} />
                          <Divider /></>
                      )
                    })}
                  </>
                </TabPanel>
              )
            })}
          </Box>
        </TabContext>
      </Box>

      <Modal
        open={openModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 1000,
          bgcolor: 'background.paper',
          borderRadius: '16px',
          boxShadow: 24,
          border: 'none',
          py: 3,
          height: '500px'
        }}>
          <RecommendedSchemesModal handleModalClose={handleModalClose} selectedSchemes={checkedSchemes} OnSchemeDeSelection={OnSchemeDeSelection} investorId={investorId} isRecommended={(val: boolean) => { setRecommended(val) }} />
          {/* {Recommend ? <Modal
            open={Recommend}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{
              position: 'absolute' as any,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: '10px',
              boxShadow: 24,
              p: 4,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
              <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
              <Button onClick={handleClose} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
            </Box>
          </Modal> : <></>} */}
        </Box>
      </Modal>
    </div>
  )
}
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Grid,
  Typography,
} from '@mui/material';
import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { FormControl } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import MFCheckbox from '../../lib/formik/Checkbox';
import UseRadioGroup, { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { MFTextField } from '../../lib/formik';
import { useHistory, useParams } from 'react-router';
// import { riskProfileScheme } from '../../utils/schema';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  profileType,
  RiskProfile,
  RiskProfileMaster,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getAge,
  getRiskAgeValue,
  getScore,
  saveForLater,
} from '../../utils/utilityFunctions';
import { updateApplication } from '../../redux-store/actions/application';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { riskProfileMasterData } from '../../redux-store/actions';
import { riskProfileDeclaration, riskProfileDonotWish } from '../../utils/declaration';
import { fontSize } from '@mui/system';
import { ConfirmationDialog } from '../commonComponents';

export type updatedRiskProfile = RiskProfile &
  RiskProfileMasterType & {
    otherValue?: string;
    InvestmentGoal?: string[];
    scoreCal?: number;
  };

export type Values = {
  wishToAddRiskProfile: boolean;
  riskProfileDeclaration: boolean;
  totalRiskProfileScore: number;
  riskprofiles: updatedRiskProfile[];
  saveType: string;
};

export const RiskProfileObj: updatedRiskProfile = {
  question: '',
  answer: '',
  scoreText: '',
  otherValue: '',
  key: '',
  displayText: '',
  InvestmentGoal: [],
  scoreCal: 0,
};

const initialValues: Values = {
  wishToAddRiskProfile: false,
  riskProfileDeclaration: true,
  totalRiskProfileScore: 0,
  riskprofiles: [],
  saveType: 'save and proceed',
};

function RiskDeclaration({
  data,
  values,
  setValues,
  setTotalScore,
  totalScore,
  disabled,
}: {
  data: updatedRiskProfile[];
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  setTotalScore: any;
  totalScore: any;
  disabled: boolean;
}): JSX.Element {
  const [expanded, setExpanded] = React.useState<string | false>('');

  useEffect(() => {
    setExpanded(disabled ? '' : expanded);
  }, [disabled]);

  const _handleSelection = async (
    ind: number,
    selectedOptionObj: any,
    InvestmentGoal: string[],
    scoreCal: string | number
  ) => {
    const index = InvestmentGoal?.indexOf(
      `${selectedOptionObj.key}_${selectedOptionObj.score}` as string
    );
    const investmentGoalSelect =
      index === -1
        ? [...InvestmentGoal, `${selectedOptionObj.key}_${selectedOptionObj.score}`]
        : InvestmentGoal?.filter(
            (goal: any) => goal != (`${selectedOptionObj.key}_${selectedOptionObj.score}` as string)
          );

    const total = investmentGoalSelect?.includes(
      `${selectedOptionObj.key}_${selectedOptionObj.score}`
    )
      ? Number(scoreCal) + Number(selectedOptionObj.score)
      : Number(scoreCal) - Number(selectedOptionObj.score);

    setValues({
      ...values,
      riskprofiles: values.riskprofiles.map((risk, index) => {
        if (index === ind) {
          return {
            ...risk,
            answer: investmentGoalSelect?.toString().replace(/,/g, '*'),
            scoreText: total.toFixed(1)?.toString() || '',
            InvestmentGoal: investmentGoalSelect,
            scoreCal: total,
          };
        }
        return risk;
      }),
    });
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      {data?.map((dataValues: any, ind: any) => {
        return (
          <>
            <Accordion
              sx={{ boxShadow: 'none', mb: 3, '&::before': { display: 'none' } }}
              key={ind}
              expanded={expanded === `panel${ind}`}
              onChange={handleChange(`panel${ind}`)}
              disabled={disabled}>
              <AccordionSummary
                sx={{
                  bgcolor: 'rgba(221, 234, 243, 0.2)',
                  borderRadius: '5px',
                  '&.Mui-expanded': { minHeight: 'unset' },
                  '& .MuiAccordionSummary-content': { '&.Mui-expanded': { my: 2 } },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${ind}d-content`}
                id={`panel${ind}d-header`}>
                <Typography
                  sx={{
                    color: values.riskprofiles[ind]?.answer !== '' ? '#57B6BA' : '#131836',
                    fontSize: 14,
                    fontWeight: 600,
                  }}>
                  {`${ind + 1}. ${dataValues.displayText} ${
                    dataValues.required === 'true' ? '*' : ''
                  }`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ pb: 0 }}>
                {['radio', 'multiSelectCheckBox'].includes(dataValues.questionType) && (
                  <>
                    {dataValues.isMultiselect === 'false' && (
                      <UseRadioGroupTransparent
                        name={`riskprofiles.${ind}.answer`}
                        items={dataValues.values.map((profile: any) => ({
                          label: profile.label,
                          value: profile.key,
                        }))}
                        onChange={(e) => {
                          let score;
                          setValues({
                            ...values,
                            riskprofiles: values.riskprofiles.map((risk, index) => {
                              if (index === ind) {
                                score = risk.values?.filter((risk) => {
                                  return risk.key === e.target.value;
                                })?.[0].score;
                                setTotalScore(totalScore + Number(score));
                                return {
                                  ...risk,
                                  answer: e.target.value,
                                  scoreText: score?.toString() || '',
                                };
                              }
                              return risk;
                            }),
                          });
                        }}
                        sx={{
                          '& .MuiFormGroup-root': { flexDirection: 'column' },
                          '& .MuiFormControlLabel-label': { fontWeight: 400 },
                        }}
                      />
                    )}
                    {dataValues.isMultiselect === 'true' &&
                      dataValues.values.map((risk: any) => {
                        return (
                          <>
                            <FormControlLabel
                              key={dataValues.key}
                              value={dataValues.key}
                              sx={{
                                width: '100%',
                                position: 'relative',
                                pb: 0,
                                ml: 0.2,
                                '.MuiFormLabel-root ': {
                                  color: 'primary.main',
                                  fontWeight: 600,
                                  mb: 0.5,
                                },
                                '.MuiFormControlLabel-root': {
                                  width: '100%',
                                  alignItems: 'flex-start',
                                  '.MuiTypography-root': {
                                    color: 'text.primary',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    overflow: { xs: 'unset', md: 'hidden' },
                                    whiteSpace: { xs: 'unset', md: 'nowrap' },
                                    textOverflow: { xs: 'unset', md: 'ellipsis' },
                                    wordBreak: { xs: 'break-word', md: 'unset' },
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ,.Mui-checked': {
                                    color: 'text.primary',
                                  },
                                  '.MuiRadio-root,.MuiCheckbox-root ': { p: 0, mr: 0.5 },
                                },
                                '.MuiAccordionDetails-root': {
                                  paddingTop: 0,
                                },
                                '.MuiAccordionSummary-content.Mui-expanded': {
                                  marginBottom: '10px',
                                },
                              }}
                              onChange={() => {
                                _handleSelection(
                                  ind,
                                  risk,
                                  dataValues.InvestmentGoal,
                                  dataValues.scoreCal
                                );
                              }}
                              control={
                                <Checkbox
                                  size="small"
                                  checked={dataValues.InvestmentGoal?.map((goal: any) =>
                                    goal.toString()
                                  ).includes(`${risk.key}_${risk.score}`)}
                                />
                              }
                              label={risk.label}
                              title={risk.label}
                            />
                          </>
                        );
                      })}
                  </>
                )}
                {(values.riskprofiles[ind]?.answer === 'others' ||
                  (values.riskprofiles[ind].isMultiselect === 'true' &&
                    values.riskprofiles[ind]?.answer.includes('others'))) && (
                  <Grid xs={12} sm={6}>
                    <MFTextField
                      name={`riskprofiles.${ind}.otherValue`}
                      placeholder={`Please specify`}
                      sx={{
                        mb: 0,
                        '&.MuiInputBase-root': {
                          position: 'relative',
                          border: 0,
                          borderBottom: '1px solid #DDEAF3',
                          fontSize: 16,
                        },
                      }}
                      onChange={(e) => {
                        const removeAstricOrUnderscore = e.target.value
                          ?.replace('*', '')
                          ?.replace('_', '');
                        setValues({
                          ...values,
                          riskprofiles: values.riskprofiles.map((risk, index) => {
                            if (index === ind) {
                              return {
                                ...risk,
                                otherValue: removeAstricOrUnderscore,
                              };
                            }
                            return risk;
                          }),
                        });
                      }}
                    />
                  </Grid>
                )}
              </AccordionDetails>
            </Accordion>
          </>
        );
      })}
    </Box>
  );
}

export const profiletext = (total: number, profileData: profileType[]): any => {
  let text;
  profileData?.map((profile) => {
    const min = Math.min(profile.minScore, profile.maxScore),
      max = Math.max(profile.minScore, profile.maxScore);
    if (total >= min && total <= max) {
      text = (
        <>
          <Typography>
            Risk Profile is{' '}
            <Typography component="strong" sx={{ fontSize: 16, fontWeight: 600, color: 'black' }}>
              {'  '}
              {profile.profile}.{' '}
            </Typography>
            {/* {`${profile.Descriptions}. Your risk taking ability is ${profile.riskTakingAbility}`} */}
          </Typography>
        </>
      );
    }
  });
  return text;
};

export const handleIntialSelect = (riskprofiles: RiskProfile[], riskProfileQuestion: string) => {
  let selectedValue: string[] = [];
  const longTermArr = riskprofiles?.find((a) => a.question === riskProfileQuestion);
  selectedValue = longTermArr?.answer.split('*') as unknown as string[];
  return selectedValue;
};

export default function riskProfile(): JSX.Element {
  const [riskProfileData, setRiskProfileData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [riskProfileDataMaster, setRiskProfileDataMaster] = useState<RiskProfileMasterType[]>([]);
  const [profileData, setProfileData] = useState<profileType[]>([]);
  const [totalScore, setTotalScore] = useState(0);
  const [openPopup, setOpenPopup] = useState<{ riskProfileCheck: boolean } | null>(null);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const {
        riskprofiles,
        riskProfileDeclaration,
        wishToAddRiskProfile,
        totalRiskProfileScore = 0,
        saveType,
      } = values;
      const checkAllQuestionsAnswered = riskprofiles
        ?.map((profile) => profile.answer !== '' && profile.required === 'true')
        ?.every((_profile) => _profile);
      if (!wishToAddRiskProfile && !checkAllQuestionsAnswered) {
        throw 'Please fill all the required(*) fields';
      }
      riskprofiles?.map((risk) => {
        if (
          !wishToAddRiskProfile &&
          (risk.answer === 'others' ||
            (risk.isMultiselect === 'true' && risk.answer.includes('others'))) &&
          !risk.otherValue &&
          risk.required === 'true'
        ) {
          const questionForDisplay = riskProfileDataMaster
            ?.map((riskProfile) => {
              if (riskProfile.key === risk.question) {
                return riskProfile.displayText;
              }
              return;
            })
            ?.filter((ele) => ele)
            ?.toString();
          throw 'Please specify others in ' + questionForDisplay;
        }
      });
      if (!wishToAddRiskProfile && !riskProfileDeclaration) {
        throw 'Declaration is required';
      }
      const updatedRiskProfile = riskprofiles?.map((_riskProfile) => {
        const {
          otherValue,
          required,
          values,
          displayText,
          key,
          isMultiselect,
          totalPercentage,
          InvestmentGoal,
          scoreCal,
          questionType,
          ...rest
        } = _riskProfile;
        return {
          ...rest,
          answer:
            otherValue && rest.answer === 'others'
              ? otherValue
              : isMultiselect === 'true' && otherValue && rest.answer?.includes('others')
              ? rest.answer?.replace('others', otherValue)
              : rest.answer,
        };
      });
      const updatedPayload = {
        ...application,
        totalRiskProfileScore: wishToAddRiskProfile ? 0 : Number(manageTotal(values).toFixed(1)),
        wishToAddRiskProfile,
        riskProfileDeclaration,
        riskprofiles: wishToAddRiskProfile
          ? updatedRiskProfile && updatedRiskProfile?.length > 0
            ? (updatedRiskProfile
                ?.map((_profile) => {
                  if (_profile.id) {
                    return {
                      ..._profile,
                      isActive: false,
                    };
                  }
                  return;
                })
                .filter((ele) => ele) as updatedRiskProfile[])
            : updatedRiskProfile?.filter((profile) => profile.answer)
          : updatedRiskProfile?.filter((profile) => profile.answer),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          riskprofiles: application?.riskprofiles?.sort(
            (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
          ),
        },
        {
          ...updatedPayload,
          riskprofiles: updatedPayload?.riskprofiles?.sort(
            (riskprofile1, riskprofile2) => Number(riskprofile1.id) - Number(riskprofile2.id)
          ),
          currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 9,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('investment-preference', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('investment-preference', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;
        if (!isComponentAlive) {
          return;
        }
        setRiskProfileDataMaster(response.riskProfile);
        setProfileData(response.profile);
        const {
          riskprofiles = [],
          riskProfileDeclaration = true,
          wishToAddRiskProfile = true,
        } = application || {};
        setRiskProfileData({
          ...riskProfileData,
          wishToAddRiskProfile: wishToAddRiskProfile || false,
          riskProfileDeclaration: wishToAddRiskProfile ? false : riskProfileDeclaration || true,
          riskprofiles: response.riskProfile
            ?.map((mdmsProfile) => {
              const existingRiskProfiles = riskprofiles?.filter(
                (risk_profiles) => risk_profiles.question === mdmsProfile.key
              );
              return existingRiskProfiles.length
                ? existingRiskProfiles?.map((existingProfiles) => {
                    if (
                      existingProfiles.question === mdmsProfile.key &&
                      mdmsProfile.isMultiselect === 'true'
                    ) {
                      const getInvestment = handleIntialSelect(riskprofiles, mdmsProfile.key);
                      const checkAnswerArray = mdmsProfile.values?.map((_value) => _value.key);
                      let getOtherValue = '';
                      const updateOptions = getInvestment.map((ans) => {
                        if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                          getOtherValue = ans.split('_')[0];
                          return `others_${ans.split('_')[1]}`;
                        }
                        return ans;
                      });
                      return {
                        ...existingProfiles,
                        ...mdmsProfile,
                        values: mdmsProfile.values?.map((value) => {
                          const scoreUpdate = updateOptions
                            .find((investment) => investment?.split('_')[0] === value.key)
                            ?.split('_')[1];

                          return {
                            ...value,
                            score: scoreUpdate ? Number(scoreUpdate) : value.score,
                          };
                        }),
                        InvestmentGoal: updateOptions || [],
                        scoreCal: Number(existingProfiles.scoreText) || 0,
                        otherValue: getOtherValue,
                        answer: getOtherValue
                          ? updateOptions?.toString()?.replace(/,/g, '*')
                          : existingProfiles.answer,
                      };
                    } else {
                      const checkAnswer =
                        existingProfiles.question === mdmsProfile.key &&
                        mdmsProfile.isMultiselect === 'false' &&
                        mdmsProfile.values
                          ?.map((value) => value.key)
                          .includes(existingProfiles.answer);

                      return {
                        ...existingProfiles,
                        ...mdmsProfile,
                        values: mdmsProfile.values?.map((value) => {
                          if (existingProfiles.answer === value.key) {
                            return {
                              ...value,
                              score: Number(existingProfiles.scoreText),
                            };
                          }
                          return value;
                        }),
                        otherValue: checkAnswer ? '' : existingProfiles.answer,
                        answer: checkAnswer ? existingProfiles.answer : 'others',
                      };
                    }
                  })
                : [
                    {
                      ...RiskProfileObj,
                      question: mdmsProfile.key,
                      answer:
                        wishToAddRiskProfile === null && mdmsProfile.key === 'age'
                          ? getRiskAgeValue(
                              getAge(
                                application?.applicants?.find(
                                  (_applicant) => _applicant.applicant_type === '1'
                                )?.dateOfBirth
                              )
                            )
                          : '',
                      scoreText:
                        wishToAddRiskProfile === null && mdmsProfile.key === 'age'
                          ? getScore(
                              getRiskAgeValue(
                                getAge(
                                  application?.applicants?.find(
                                    (_applicant) => _applicant.applicant_type === '1'
                                  )?.dateOfBirth
                                )
                              )
                            )
                          : '',
                      ...mdmsProfile,
                    },
                  ];
            })
            .flat(),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const manageTotal = (values: Values) => {
    const total = values.riskprofiles
      .map((risk) => {
        return Number(risk.scoreText);
      })
      .reduce(function (acc, val) {
        return acc + val;
      }, 0);

    return total;
  };

  return (
    <Formik
      initialValues={riskProfileData}
      // validationSchema={riskProfileScheme}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          //columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <Grid xs={12} sx={{ mb: 2 }}>
            <MFCheckbox
              name="wishToAddRiskProfile"
              label={riskProfileDonotWish}
              sx={{ display: 'flex', alignItems: 'flex-start' }}
              onChange={({ target: { checked } }) => {
                const updatedRiskProfile = riskProfileDataMaster.map((mdmsProfile) => {
                  const riskObj = values.riskprofiles?.find(
                    (_profile) => _profile.key === mdmsProfile.key
                  );
                  return {
                    ...RiskProfileObj,
                    question: mdmsProfile.key,
                    ...riskObj,
                    answer:
                      mdmsProfile.key === 'age'
                        ? getRiskAgeValue(
                            getAge(
                              application?.applicants?.find(
                                (_applicant) => _applicant.applicant_type === '1'
                              )?.dateOfBirth
                            )
                          )
                        : '',
                    scoreText:
                      mdmsProfile.key === 'age'
                        ? getScore(
                            getRiskAgeValue(
                              getAge(
                                application?.applicants?.find(
                                  (_applicant) => _applicant.applicant_type === '1'
                                )?.dateOfBirth
                              )
                            )
                          )
                        : '',
                    ...mdmsProfile,
                  };
                });
                !checked &&
                  setValues({
                    ...values,
                    wishToAddRiskProfile: checked,
                    riskProfileDeclaration: values.riskProfileDeclaration || true,
                    riskprofiles: [...updatedRiskProfile],
                  });
                checked && setOpenPopup({ riskProfileCheck: checked });
              }}
              checked={values.wishToAddRiskProfile}
            />
          </Grid>

          <RiskDeclaration
            data={values.riskprofiles}
            values={values}
            setValues={setValues}
            setTotalScore={setTotalScore}
            totalScore={totalScore}
            disabled={values.wishToAddRiskProfile}
          />
          <Typography
            sx={{
              color: 'black',
              fontWeight: 600,
              fontSize: 14,
              mt: 2,
            }}>{`Your total Score is ${manageTotal(values)}
          `}</Typography>
          <Grid xs={12}>
            <Typography sx={{ mt: 1 }}>
              {profiletext(Number(manageTotal(values)), profileData)}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <MFCheckbox
              name={`riskProfileDeclaration`}
              label={riskProfileDeclaration}
              sx={{ display: 'flex', alignItems: 'flex-start' }}
              disabled={values.wishToAddRiskProfile}
            />
          </Grid>

          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={`Are you sure you do not wish to undergo the RISK Profiling ?`}
            open={openPopup !== null}
            setOpen={() => {
              setOpenPopup(null);
            }}
            onSave={() => {
              setValues({
                ...values,
                wishToAddRiskProfile: openPopup?.riskProfileCheck as boolean,
                riskProfileDeclaration: false,
                riskprofiles: values.riskprofiles?.map((_profile) => {
                  return {
                    ..._profile,
                    answer: '',
                    scoreText: '',
                    InvestmentGoal: [],
                    scoreCal: 0,
                  };
                }),
              });
              setOpenPopup(null);
            }}
            onCancel={() => {
              setOpenPopup(null);
            }}
          />
        </Grid>
      )}
    </Formik>
  );
}

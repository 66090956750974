import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import {
DirectEquityFODetailsHolding, DirectEquityFODetailsTransaction, DirectEquityFODetailsType
} from '../../../redux-store/types/api-types';
import { ColumnType, DataTable } from '../../DataTable';
import { formatLongDate } from '../../../utils/date';
import { Box, Typography, Button, IconButton, Badge, CardMedia } from '@mui/material';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { MFTextField } from '../../../lib/formik';
import { Search } from '@mui/icons-material';
// import { FilterDropdown, initialFilters } from '../filters-fodetails';
import { ApplicationFilterType } from '../../../redux-store/types/api-types';
import { RootStateType } from '../../../redux-store/reducers';
import { useLocation } from 'react-router';

const headers: ColumnType[] = [
  {
    header: 'Security Name',
    stickyColumn: true,
    sortingKey: 'SecurityName',
    valueGetter: (row: DirectEquityFODetailsHolding) => row?.SecurityName ||'N/A',
  },
  {
    header: 'Price date',
    sortingKey: 'PriceDate',
    valueGetter: (row: DirectEquityFODetailsHolding) => formatLongDate(row?.PriceDate) || 'N/A',
  },
  {
    header: 'Quantity',
    sortingKey: 'Quantity',
    valueGetter: (row: DirectEquityFODetailsHolding) => 
    row?.Quantity || 'N/A',
  },
  {
    header: 'Unit Cost',
    sortingKey: 'UnitPrice',
    valueGetter: (row: DirectEquityFODetailsHolding) => 
    row?.UnitPrice || row.UnitPrice === 0 ? formatToIndianCurrency(row.UnitPrice) : 'N/A',
  },
  {
    header: 'Total Cost',
    sortingKey: 'TotalCost',
    valueGetter: (row: DirectEquityFODetailsHolding) => 
    row?.TotalCost || row.TotalCost === 0 ? formatToIndianCurrency(row.TotalCost) : 'N/A',
  },
  {
    header: 'Market Price',
    sortingKey: 'MarketPrice',
    valueGetter: (row: DirectEquityFODetailsHolding) => 
    row?.MarketPrice || row.MarketPrice === 0 ? formatToIndianCurrency(row.MarketPrice) : 'N/A',
  },
  {
    header: 'Market Value',
    sortingKey: 'MarketValue',
    valueGetter: (row: DirectEquityFODetailsHolding) => 
    row?.MarketValue || row.MarketValue === 0 ? formatToIndianCurrency(row.MarketValue) : 'N/A',
  },
  // {
  //   header: 'Income',
  //   sortingKey: 'Income',
  //   valueGetter: (row: DirectEquityFODetailsHolding) => 
  //   row?.Income || row.Income === 0 ? formatToIndianCurrency(row.Income) : 'N/A',
  // },
  {
    header: 'Gain/Loss',
    sortingKey: 'Gainloss',
    valueGetter: (row: DirectEquityFODetailsHolding) =>
      row?.Gainloss || row.Gainloss === 0 ? formatToIndianCurrency(row.Gainloss) : 'N/A',
  },
  {
    header: 'Gain/Loss %',
    sortingKey: 'GainLossPer',
    valueGetter: (row: DirectEquityFODetailsHolding) =>
      row?.GainLossPer || 'N/A',
  },
  {
    header: 'IRR %',
    sortingKey: 'IRR',
    valueGetter: (row: DirectEquityFODetailsHolding) =>
      row?.IRR || 'N/A',
  },
  {
    header: 'Exposure %',
    sortingKey: 'ExposurePer',
    valueGetter: (row:DirectEquityFODetailsHolding) =>
      row?.ExposurePer || 'N/A',
  },
];
const headers2: ColumnType[] = [
    {
      header: 'Security Name',
      stickyColumn: true,
      sortingKey: 'SecurityName',
      valueGetter: (row: DirectEquityFODetailsTransaction) => row?.SecurityName || 'N/A',
    },
    {
      header: 'Transaction Type',
      sortingKey: 'TranactionType',
      valueGetter: (row: DirectEquityFODetailsTransaction) => row?.TranactionType || 'N/A',
    },
    {
      header: 'Transaction Date',
      sortingKey: 'TransactionDate',
      valueGetter: (row: DirectEquityFODetailsTransaction) => formatLongDate(row?.TransactionDate) || 'N/A',
    },
    {
      header: 'Settlement Date',
      sortingKey: 'SettlementDate',
      valueGetter: (row: DirectEquityFODetailsTransaction) => formatLongDate(row?.SettlementDate) || 'N/A',
    },
    {
      header: 'Exchange',
      sortingKey: 'Exchange',
      valueGetter: (row: DirectEquityFODetailsTransaction) => row?.Exchange || 'N/A',
    },
    {
      header: 'Quantity',
      sortingKey: 'Quantity',
      valueGetter: (row: DirectEquityFODetailsTransaction) =>
        row?.Quantity || 'N/A',
    },
    {
      header: 'Unit Price',
      sortingKey: 'UnitPrice',
      valueGetter: (row: DirectEquityFODetailsTransaction) =>
        row?.UnitPrice || row.UnitPrice === 0 ? formatToIndianCurrency(row.UnitPrice) : 'N/A',
    },
    {
        header: 'Settlement Amount',
        sortingKey: 'settlementAmount',
        valueGetter: (row: DirectEquityFODetailsTransaction) =>
          row?.settlementAmount || row.settlementAmount === 0 ? formatToIndianCurrency(row.settlementAmount) : 'N/A',
      },
    {
      header: 'Brokerage',
      sortingKey: 'ServiceTax',
      valueGetter: (row: DirectEquityFODetailsTransaction) =>
        row?.ServiceTax || row.ServiceTax === 0 ? formatToIndianCurrency(row.ServiceTax) : 'N/A',
    },
    {
      header: 'STT',
      sortingKey: 'STT',
      valueGetter: (row: DirectEquityFODetailsTransaction) =>
        row?.STT || 'N/A',
    },
  ];

function FODetails({ investorId, selectedPortfolio }: { investorId: string; selectedPortfolio:number}): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [foDetails, setFoDetails] = useState<DirectEquityFODetailsType | null>(null);
    const [search, setSearch] = useState<string>('');
    const [foDetailsViewAll, setFoDetailsViewAll] = useState<boolean>(false);
    const [distributorLoading, setDistributorLoading] = useState(false);
    const [filtersAnchorEl, setFiltersAnchorEl] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const open = Boolean(anchorEl);
    // const badgeCount = [
    //   // 'applicationType', 'modeOfHolding', 'status', 'distributorId'
    // ].filter(
    //   (filterKey) => (filters[filterKey] as string[]).length
    // ).length;
    // const { role = '' } = useSelector((store: RootStateType) => store.auth);
    // const {
    //   page,
    //   search: storeSearch,
    //   status: storeStatus,
    //   applicationType: storeApplicationType,
    //   modeOfHolding: storeModeOfHolding,
    //   distributorId: storeDistributorId,
    //   applicationSentBack,
    // } = useSelector((store: RootStateType) => store.paramsObj);

    // const [currentPage, setPage] = useState(page);
    // const [filters, setFilters] = useState<ApplicationFilterType>
    // (
    //   initialFilters(
    //     role,
    //     applicationSentBack ? storeStatus.replace('draft', 'draft_send_back') : storeStatus,
    //     storeApplicationType,
    //     storeModeOfHolding,
    //     storeDistributorId
    //   )
    // );
  const dispatch = useDispatch();
  // const handleFiltersAnchorEl = async () => {
  //   try {
  //     setFiltersAnchorEl(true);
  //     setDistributorLoading(false);
  //   } catch (e) {
  //     console.error((e as Error).message);
  //     setDistributorLoading(false);
  //   }
  // };
  // const handleFiltersAnchorElClose = () => {
  //   setFiltersAnchorEl(false);
  // };

  useEffect(() => {
    let isComponentActive = true;
    
    setFoDetails(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = (await dispatch(
          getInvestorData({
            rmID: 2,
            productLevel: 'DIRECT EQUITIES',
            HFlag:selectedPortfolio,
            filters: {
              customers: investorId ? [investorId] : [],
              sebiCategories: [],
              amcs:[],
              schemes:[],
            },
            required: ['foDetails'],
          })
        )) as unknown as DirectEquityFODetailsType;

        if (!isComponentActive) {
          return;
        }
        setFoDetails(response);
      
        setLoading(false);
      } catch (e) {
        setLoading(false);
   
        console.log((e as Error).message);
      }
    })();
  
    return () => {
      isComponentActive = false;
    };
  }, [ ]);

  return (
  <Box>
    {/* <Box
        
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
        //   mb: 3,
        }}> <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}> 
         <Button
              onClick={() => setFoDetailsViewAll(!foDetailsViewAll)}
              sx={{
                fontSize: 14,
                lineHeight: '16px',
                color: '#4E5056',
                fontWeight: 500,
                padding: '4px 22px',
                boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                border: '1px solid #0BAAE7',
                borderRadius: '5px',
              }}>
              {foDetailsViewAll ? 'View less' : 'View all'}
            </Button>
            <IconButton
          sx={{ p: 0.5 }}
          id="filter-icon-button"
          aria-controls="filter-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleFiltersAnchorEl}>
          <Badge badgeContent={badgeCount} color="info">
            <CardMedia component="img" image="/images/filter-icon.svg" alt="Filter Icon" />
          </Badge>
        </IconButton>{' '}
        {filtersAnchorEl && (
          <FilterDropdown
            filters={filters}
            onFiltersChange={(value) => {
              setPage(1);
              setFilters(value);
            }}
            anchorEl={filtersAnchorEl}
            handleClose={handleFiltersAnchorElClose}
            // distributors={distributors}
            loader={distributorLoading}
          />
        )}
        </Box></Box> */}
              <Formik
                initialValues={{}}
                onSubmit={() => {
                  // setFetchData(true);
                }}>
                <Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', gap: 4, alignItems: 'center' }}>
                      
                        <MFTextField
                          placeholder={'Search by Security Name'}
                          name={'SecurityName'}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={<Search sx={{ pr: 0.5, color: '#000000', opacity: 0.4 }} />}
                          sx={{
                            width: { md: '10rem', lg: '15rem' },
                            '& .MuiInputBase-root': {
                              fontSize: { md: 10, lg: 12 },
                              border: '0 !important',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                              ':hover': {
                                border: 0,
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                              },
                            },
                          }}
                        />
                      
                      </Box>
                      </Box>
                      </Form>
                      </Formik>
               
  
    <Box
        
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
        //   mb: 3,
        }}>
        <Box
          sx={{
            // display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            px: 1,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 15,
              lineHeight: '25px',
              fontWeight: 600,
              flex: 1,
            }}>
            Holding Details
          </Typography>
           No data to display 
        {/* <DataTable
      
        isLoading={isLoading}
        tableHeader={headers}
        tableData={foDetails?.foresult1.filter((ele) =>
          ele?.SecurityName?.toLowerCase().includes(search.toLowerCase()))}
        boxStyles={{ maxHeight: '450px', minWidth: 'unset' }}
        customEmptyMessage="No transactions undertaken in the last 30 days"
        
        />  */}
        </Box>
    </Box>
    <Box
        
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
          marginTop:'5vh',
        //   mb: 3,
        }}>
    <Box
          sx={{
            // display: 'flex',
            alignItems: 'center',
            // justifyContent: 'space-between',
            px: 1,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 15,
              lineHeight: '25px',
              fontWeight: 600,
              flex: 1,
            }}>
            Transaction Details
          </Typography>
          No data to display
    {/* <DataTable
        isLoading={isLoading}
        tableHeader={headers2}
        tableData={foDetails?.foresult2.filter((ele) =>
          ele?.SecurityName?.toLowerCase().includes(search.toLowerCase()))}
        boxStyles={{ maxHeight: '450px', minWidth: 'unset' }}
        customEmptyMessage="No transactions undertaken in the last 30 days"
      /> */}
      </Box>
      </Box>
    </Box>
    
  );
}

export default FODetails;

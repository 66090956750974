import { AnyAction } from 'redux';
import { VALUE_TYPE, SET_VALUE} from '../types/valueConverter';

export type ProductsType = {
  filters: VALUE_TYPE;
};

const initState: Partial<number> = 0;

function valueConverterReducer(state = initState, action: AnyAction): Partial<VALUE_TYPE> {
  switch (action.type) {
    case SET_VALUE: {
      state = action.value;
      return  state;
    }
    default:
      return state;
  }
}

export default valueConverterReducer;

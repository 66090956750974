import { Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReportSectionContainer, ReportsRightLayout } from '../../InvestorReport/index';
import { getDashboardReports, getInvestorReports } from '../../../redux-store/actions/investorReports';
import {
    downloadMultipleFiles,
    formatDate,
    replaceLetterOWithW,
} from '../../../utils/utilityFunctions';
import { TextDatePicker } from '../../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../../lib/formik';
import { validationSchemaPortfolioSummary } from '../../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';
import FormMultiSelect from '../../../lib/formik/FormMultiSelect';
import { getRMDashBoardMetrics } from '../../../redux-store/actions/userManagement';
import { useEffect, useState } from 'react';
import { RmDashboardType } from '../../../redux-store/types/api-types';
import { useFormik } from 'formik';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export function DetailedHoldingReport() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const currentDate = dayjs();
    const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
    const [loading, setLoading] = useState<boolean>(false);
    const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);

    useEffect(() => {
        let isComponentActive = true;
        setDashBoardMetricsData(null);
        (async function () {
            try {
                // setLoading(true);
                const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
                if (!isComponentActive) {
                    return;
                }
                setDashBoardMetricsData(response);
                // setLoading(false);
            } catch (e) {
                // setLoading(false);
                console.log((e as Error).message);
            }
        })();
        return () => {
            isComponentActive = false;
        };

    }, []);

    const onSubmit = async (date: any, ClientCode: any, setSubmitting: (isSubmitting: boolean) => void) => {
        console.log("first hi ClientCode : ", ClientCode)
        setDates(date);
        console.log("date here", dates)
        console.log("set loading ", loading)
        try {
            const formattedDate = `${new Date(date).getDate().toString().padStart(2, '0')}/${(new Date(date)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getFullYear()}`;
            console.log("formattedDate ..>>", formattedDate)

            setSubmitting(true);

            // const Fchar = "W"
            // const mClientCode: string = Fchar + ClientCode.slice(1);
            // console.log("modifiedString data: ", mClientCode)

            setLoading(true);
            const res = await dispatch(
                getDashboardReports({
                    reportName: 'DetailedHoldingReport',
                    ClientCode: ClientCode,
                    ToDate: formattedDate,
                })
            );
            setLoading(false)
            console.log(`Generating report for investor with id: ${ClientCode}`);
            console.log("response ", res)
            downloadMultipleFiles(res);

            formik.setFieldValue('selectedInvestor', { ClientId: "", clientName: "" });
            enqueueSnackbar('File(s) downloaded successfully.', {
                variant: 'success',
                autoHideDuration: 3000,
            });
            setDates(dayjs(currentDate).format("YYYY-MM-DD"));
        }
        catch (e) {
            setLoading(false);
            console.error((e as Error).message);
        } finally {
            setSubmitting(false);
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const initialValues = {
        selectedInvestor: { ClientId: "", clientName: "" },
        dates: new Date(),
        // date: new Date()
    };
    // console.log("initialvalue.date", initialValues.date)
    // const handleSubmit = (values: any) => {
    //     // Handle form submission
    //     // console.log(values.selectedInvestor.OClientID);

    //     onSubmit(values.dates, formik.values.selectedInvestor.ClientId);
    // };

    const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        onSubmit(values.dates, formik.values.selectedInvestor.ClientId, setSubmitting);
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    // filteredInvestorDetails?.map((i, index) => {
    // const demo = () => {
    //     console.log("demo called")
    // }

    return (
        <ReportsRightLayout >
            <Typography
                sx={{
                    color: 'text.secondary',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '19px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    display: ['none', 'none', 'block'],
                }}>
                Detailed Holding Report
            </Typography>

            <Formik
                validationSchema={validationSchemaPortfolioSummary}
                initialValues={{
                    date: new Date(),
                    // fileType: 'pdf',
                }}
                onSubmit={() => { onSubmit(dates, formik.values.selectedInvestor.ClientId, () => { (true) }) }} >

                {({ values, setFieldValue, errors }): {


                } => (
                    <Form>
                        <ReportSectionContainer>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0.01em',
                                    }}>
                                    Select Investor(s)
                                </Typography>

                                <Select
                                    name={'reportOptions'}
                                    label="Select Investor"
                                    MenuProps={MenuProps}
                                    value={formik.values.selectedInvestor.ClientId || ''}
                                    onChange={(e) => {
                                        const selectedInvestor = dashBoardMetricsData?.investorDetail?.find(
                                            (investor: any) => investor.OClientID === e.target.value
                                        );
                                        const data = {
                                            ClientId: selectedInvestor?.OClientID,
                                            clientName: selectedInvestor?.I_InvName
                                        };
                                        formik.setFieldValue('selectedInvestor', data);
                                    }}
                                >
                                    {console.log("dashBoardMetricsData", dashBoardMetricsData)}
                                    {dashBoardMetricsData?.investorDetail?.map((i: any, index: any) => (
                                        <MenuItem value={i.OClientID} key={index}>
                                            {i.I_InvName}
                                        </MenuItem>
                                    ))}
                                </Select>


                            </Stack>
                        </ReportSectionContainer>

                        <ReportSectionContainer>
                            <Stack>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        label="Select Date"
                                        format="DD-MM-YYYY"
                                        value={dayjs(dates)}
                                        maxDate={dayjs(currentDate)}
                                        onChange={(date: any) =>
                                            setDates(date.format("YYYY-MM-DD"))
                                        }
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </ReportSectionContainer>
                        <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
                            <MFSubmitButton
                                label="Download"
                                loadingEnable={loading}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </ReportsRightLayout>
    );
    // }
};



export const SET_SCHEME_FOR_BASKET = 'SET_SCHEME_FOR_BASKET';

export type SCHEME_FOR_BASKET_TYPE =
    {
        subid: number;
        Assetclass: number;
        AssetClassName: string;
        Subassetclass: number;
        Sub_AssetclassName: string;
        SchemeID: number;
        SchemeName: string;
        AmcID: number;
        Growthoption: number;
        Dividendreinvestment: number;
        DividendPayout: number;
        Dividendfrequency: null,
        MinimumPurchaseAmount: string;
        MaximumPurchaseAmount: string;
        AMC: string;
        Aum: string;
        TailWindRec: string;
        Cat_Return1yr: string;
        Cat_Return3yr: string;
        Cat_Return5yr: string;
        Cat_Return7yr: string;
        Cat_Return10yr: string;
        Sch_Return1Day: string;
        Sch_Return1Week: string;
        Sch_Return1Mth: string;
        Sch_Return3Mth: string;
        Sch_Return1Yr: string;
        Sch_Return3Yr: string;
        Sch_Return5Yr: string;
        Sch_Return7Yr: string;
        Sch_Return10Yr: string;
        Cat_Return1Mth: string;
        Cat_Return3Mth: string;
        Cat_Return6Mth: string;
        Sch_Return6Mth: string;
        img: string;
        Sch: string;
    }[];
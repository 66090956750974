export function formatShortDate(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
}
export const formatLongDate = (date?: string | Date | null): string => {
  if (!date) {
    return '';
  }
  return new Date(date).toLocaleDateString('en-in', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export function formatShortDateAndTime(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  const d = new Date(date);
  let day = '' + d.getDate();
  const month = d.toLocaleString('en-US', {
    month: 'short',
  });
  const year = d.getFullYear().toString().substr(-2);
  if (day.length < 2) day = '0' + day;
  return (
    [day, month + "'" + year].join(' ') +
    ' ' +
    d.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  );
}

export function formatDate_MMDDYYYY(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  // const d = new Date(date).toLocaleDateString('en-IN', {
  //   month: '2-digit',
  //   day: '2-digit',
  //   year: 'numeric',
  // });
  date = new Date(date);
  const d = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
  return d;
}
export function formatDate_DDMMYYYY(date?: string | Date | null): string {
  if (!date) {
    return '';
  }
  // const d = new Date(date).toLocaleDateString('en-IN', {
  //   month: '2-digit',
  //   day: '2-digit',
  //   year: 'numeric',
  // });
  date = new Date(date);
  const d = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  return d;
}

import { LoadingButton } from '@mui/lab';
import { Box, Radio, RadioProps, Stack, styled } from '@mui/material';
import { Divider } from '@mui/material';
import React, { useContext } from 'react';
import { NavLink as Link, NavLinkProps } from 'react-router-dom';
import { keyframes } from '@emotion/react';

export const NavLink = styled(Link)((props: NavLinkProps & { active?: boolean }) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  textDecoration: 'none',
  gap: '18px',
  color: 'white',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  letterSpacing: '0.02em',
  '&.active': {
    color: props.active === false ? 'white' : '#0293FE !important',
  },
}));

const SectionLink = styled(NavLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  width: '75%',
  fontWeight: 'normal',
  borderRadius: '8px',
  position: 'relative',
  margin: theme.spacing(2, 0),
  '& svg path': {
    fill: 'currentColor',
  },
  '&.active': {
    backgroundColor: theme.palette.primary,
    border: '0.5px solid rgba(67, 91, 111, 0.01)',
    '& svg path': {
      stroke: 'currentColor',
    },
    '& svg rect': {
      fill: 'rgb(227, 230, 248, 0.3)',
      stroke: 'none',
    },
  },
}));

type SectionLinkItemProps = {
  icon: React.ReactNode;
  title: string;
  isLast?: boolean;
};

export const SectionLinkItem = (props: SectionLinkItemProps & NavLinkProps) => {
  const { icon, title, isLast } = props;
  return (
    <>
      <SectionLink {...props}>
        <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 2, ml: 2 }}>
          {icon}
          {title}
        </Stack>
      </SectionLink>
      {!isLast && <Divider sx={{ mt: 1, mb: 1, mr: 4 }} />}
    </>
  );
};

interface NavItemProps {
  activeIcon?: React.ReactElement;
  inactiveIcon?: React.ReactElement;
  title: string;
  active?: boolean;
}

export const ReportsRightLayout = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2, 9),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(0),
  },
}));

export const ReportLeftLayout = styled(Box)(({ theme }) => ({
  width: '50%',
  padding: theme.spacing(0, 3),
  [theme.breakpoints.down('md')]: {
    // display: 'none',
  },
}));

export const slide = keyframes`
0% {
  transform: translateY(20px);
  visibility: visible;
}
100% {
  transform: translateY(0);
}
`;

export const AnimatedBox = styled(Box)({
  '&.slideInUp': {
    animationName: slide,
    animationDuration: '0.1s',
    animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)',
    animationFillMode: 'both',
  },
});

const BpIcon = styled('span')(({ theme }) => ({
  borderRadius: '50%',
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === 'dark'
      ? '0 0 0 1px rgb(16 22 26 / 40%)'
      : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
  backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
  // backgroundImage:
  //   theme.palette.mode === 'dark'
  //     ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
  //     : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
}));

export const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: '#2AC9EE',
  boxShadow: 'none',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#2AC9EE',
  },
});

// Inspired by blueprintjs
export function BpRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

export const ReportSectionContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#eff3fe',
  borderRadius: theme.shape.borderRadius,
  padding: '1rem 2rem',
  marginTop: theme.spacing(2),
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(2),
  },
}));

import {
  Box,
  Button,
  Dialog,
  Grid,
  IconButton,
  SelectChangeEvent,
  Theme,
  Typography,
} from '@mui/material';
import {
  Formik,
  setNestedObjectValues,
  useFormikContext,
  validateYupSchema,
  yupToFormErrors,
} from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MFTextField } from '../../lib/formik';
import UseRadioGroup from '../../lib/formik/Radio';
import { RootStateType } from '../../redux-store/reducers';
import {
  nonIndividualMdmsFatca,
  nonIndividualMdmsQuestionsFatca,
  nonIndividualQuestionsFatca,
  Fatcadetails,
  Applicant,
  FatcaMdms,
  individuals_Poa_nonIndividuals_Documents,
  Document,
} from '../../redux-store/types/api-types';
import { ProceedSaveLater, SubHeading } from '../investors/components';
import { DatePicker } from '../../lib/formik/DatePicker';
import {
  FatcaMdmsData,
  getDocuments,
  nonIndividualFormValidForSubmission,
  updateApplication,
} from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import {
  applicationComparison,
  removeSingleQuote,
  saveForLater,
} from '../../utils/utilityFunctions';
import MFSelectField from '../../lib/formik/SelectField';
import { SxProps } from '@mui/system';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ControllingPerson from './fatca_components';
import { showError } from '../../redux-store/actions/auth';
import { nonIndividualFatcaSchema } from '../../utils/schema';
import updateVisibility from '../../utils/fatcaUtils';
import { ConfirmationDialog } from '../commonComponents';
import { USER_ROLES } from '../../utils/constant';
import { fatcaErrors } from '../../redux-store/middleware';
import { mdmsCountriesList, nationaliyType } from '../../redux-store/types/mdms';
import { getNationalityList } from '../../redux-store/actions';
import { useSnackbar } from 'notistack';
export interface Values {
  applicants: {
    fatcadetail: nonIndividualMdmsQuestionsFatca;
  }[];
  saveType: string;
  _applicants: Partial<Applicant>[];
}
export function SectionHeader({ value }: { value: string }): JSX.Element {
  return (
    <Typography
      sx={{
        color: '#57B6BA',
        fontSize: '20px',
        fontWeight: 500,
        fontFamily: 'Poppins',
        letterSpacing: '0.96px',
        lineHeight: '30px',
        mb: 2,
        py: 1,
        width: '100%',
      }}>
      {value}
    </Typography>
  );
}

export const resetQ = (data: nonIndividualQuestionsFatca, baseQId: string): void => {
  if (
    data?.id
      .replace('Q', '')
      .localeCompare(baseQId.replace('Q', ''), undefined, { numeric: true, sensitivity: 'base' }) ==
    1
  ) {
    // console.log('Question id: ', data.id, ' is more than: ', baseQId);
    //data.answer = '';
    if (data.question_type == 'group') {
      data.isVisible = false;
      data.sub_questions?.forEach((_qns) => resetQ(_qns, baseQId));
    } else {
      data.isVisible = false;
    }
  } else {
    if (data.question_type == 'group') {
      // need to check for sub questions also
      data.sub_questions?.forEach((_qns) => resetQ(_qns, baseQId));
    }
    //console.log('Question id: ', data.id, ' is less than original id: ', baseQId);
  }
};
export const setVisibility = (
  inputQId: string,
  q: nonIndividualQuestionsFatca,
  formValues: nonIndividualMdmsQuestionsFatca
): void => {
  if (q.id === inputQId) {
    q.isVisible = true;
    if (q.answer) {
      updateVisibility(q.answer, q, formValues);
    }
  } else if (q.question_type === 'group') {
    q.sub_questions?.forEach((_qns) => setVisibility(inputQId, _qns, formValues));
  }
};

export const getSubQuestionsIndex = (
  indexArr: string[],
  indexVal = '',
  schema = false
): string | void => {
  const subIndex = indexVal;
  if (indexArr.length === 1) {
    return schema
      ? `fatcadetail[${Number(indexArr[0]) - 1}]${subIndex}`
      : `fatcadetail.${Number(indexArr[0]) - 1}${subIndex}`;
  }
  if (indexArr.length > 1) {
    const subIndexVal = schema
      ? `.sub_questions[${Number(indexArr.pop()) - 1}]${subIndex}`
      : `.sub_questions.${Number(indexArr.pop()) - 1}${subIndex}`;
    return getSubQuestionsIndex(indexArr, subIndexVal, schema);
  }
};

export function SingleChoiceRadio({
  data,
  values,
  formValuesIndex,
  questionId,
  disabled,
}: {
  data: Partial<nonIndividualMdmsFatca>;
  values: nonIndividualMdmsQuestionsFatca;
  formValuesIndex: number;
  questionId: string;
  disabled: boolean;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const singleChoiceRadioFieldName = questionId.split('Q')[1].split('.');
  const singleChoiceRadio_FieldName = getSubQuestionsIndex(singleChoiceRadioFieldName);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          pb: 3,
          pt: 1,
          fontFamily: 'Work Sans,sans-serif',
        }}>
        <Typography sx={{ fontWeight: 600, fontSize: 16 }}>{data.question_order}</Typography>
        <Typography
          sx={{
            pl: 1,
            fontWeight: 400,
            fontSize: 16,
          }}>
          {data.isMandatory === 'true' ? data.question_text + ' *' : data.question_text}
        </Typography>
      </Box>
      {data.options && (
        <Grid xs={12} sx={{ pl: 3, pb: 2 }}>
          <UseRadioGroup
            name={`applicants.${formValuesIndex}.${singleChoiceRadio_FieldName}.answer`}
            items={data.options.map((option) => ({
              label: option.label,
              value: option.label,
            }))}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue(
                `applicants.${formValuesIndex}.${singleChoiceRadio_FieldName}.answer`,
                value
              );
              updateVisibility(value, data, values);
            }}
            disabled={disabled}
          />
        </Grid>
      )}
    </>
  );
}
export function Info({ order, text }: { order: string; text: string }): JSX.Element {
  return (
    <>
      <Typography
        sx={{ fontFamily: 'Work Sans,sans-serif', fontWeight: 400, fontSize: '15px' }}
        component="span">
        {order}
      </Typography>
      <Typography
        sx={{
          pl: 1,
          fontFamily: 'Work Sans,sans-serif',
          fontWeight: 400,
          fontSize: '15px',
        }}
        component="span">
        {text}
      </Typography>
    </>
  );
}

export function BorderedTextField({
  applicantsIndex,
  questionId,
  label,
  placeHolder,
  sx = {},
  isMandatory,
  disabled,
}: {
  applicantsIndex: number;
  questionId: string;
  label?: string;
  placeHolder: string;
  sx?: SxProps<Theme>;
  isMandatory: string;
  disabled: boolean;
}): JSX.Element {
  const fieldName = questionId.split('Q')[1].split('.');
  return (
    <MFTextField
      name={`applicants.${applicantsIndex}.${getSubQuestionsIndex(fieldName)}.answer`}
      label={label && (isMandatory === 'true' ? label + ' *' : label)}
      placeholder={placeHolder}
      sx={sx}
      disabled={disabled}
    />
  );
}

export function OpenTextField({
  text,
  applicantsIndex,
  questionId,
  placeHolder,
  isMandatory,
  disabled,
}: {
  text: string;
  applicantsIndex: number;
  questionId: string;
  placeHolder: string;
  isMandatory: string;
  disabled: boolean;
}): JSX.Element {
  const openTextFieldName = questionId.split('Q')[1].split('.');
  return (
    <>
      <Typography
        sx={{
          pl: { xs: 0, sm: 3 },
          pt: 3,
          fontFamily: 'Work Sans,sans-serif',
          fontWeight: 400,
          fontSize: 16,
        }}>
        {isMandatory === 'true' ? text + ' *' : text}
      </Typography>
      <Grid xs={12} sm={6} sx={{ pb: { xs: 1, sm: 0 }, pl: { xs: 0, sm: 3 }, pt: 2 }}>
        <MFTextField
          name={`applicants.${applicantsIndex}.${getSubQuestionsIndex(openTextFieldName)}.answer`}
          placeholder={placeHolder}
          sx={{
            '&.MuiInputBase-root': {
              position: 'relative',
              border: 0,
              borderBottom: '1px solid #ccc',
              fontSize: 16,
            },
          }}
          disabled={disabled}
        />
      </Grid>
    </>
  );
}

export function FatcaDatePicker({
  applicantsIndex,
  questionId,
  label,
  isMandatory,
  disabled,
}: {
  applicantsIndex: number;
  questionId: string;
  label: string;
  isMandatory: string;
  disabled: boolean;
}): JSX.Element {
  const fatcaDatePickerFieldName = questionId.split('Q')[1].split('.');
  return (
    <DatePicker
      label={isMandatory === 'true' ? label + ' *' : label}
      inputLabelStyles={{
        transform: 'unset',
        fontSize: 14,
        fontWeight: 500,
        color: 'rgba(0,0,0,0.7)',
      }}
      placeholder={'DD/MM/YYYY'}
      name={`applicants.${applicantsIndex}.${getSubQuestionsIndex(
        fatcaDatePickerFieldName
      )}.answer`}
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      disabled={disabled}
    />
  );
}

export function SingleChoiceDropdown({
  applicantsIndex,
  questionId,
  label,
  options,
  isMandatory,
  data,
  values,
  disabled,
}: {
  applicantsIndex: number;
  questionId: string;
  label: string;
  options: {
    id: number;
    label: string;
    showQs?: string[];
    type: string;
  }[];
  isMandatory: string;
  data: Partial<nonIndividualMdmsFatca>;
  values: nonIndividualMdmsQuestionsFatca;
  disabled: boolean;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const SingleChoiceDropdownFieldName = questionId.split('Q')[1].split('.');
  const SingleChoiceDropdown_FieldName = `applicants.${applicantsIndex}.${getSubQuestionsIndex(
    SingleChoiceDropdownFieldName
  )}.answer`;
  return (
    <MFSelectField
      name={SingleChoiceDropdown_FieldName}
      label={isMandatory === 'true' ? label + ' *' : label}
      items={options.map((option) => ({
        key: option.label,
        value: option.label,
      }))}
      onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
        setFieldValue(SingleChoiceDropdown_FieldName, value);
        options.forEach((option) => {
          if (option.showQs) {
            updateVisibility(value as string, data, values);
          }
        });
      }}
      disabled={disabled}
    />
  );
}
export function SingleChoiceDropdownWithInfo({
  applicantsIndex,
  questionId,
  label,
  options,
  isMandatory,
  data,
  values,
  disabled,
}: {
  applicantsIndex: number;
  questionId: string;
  label: string;
  options: {
    id: number;
    label: string;
    showQs?: string[];
    type: string;
  }[];
  isMandatory: string;
  data: Partial<nonIndividualMdmsFatca>;
  values: nonIndividualMdmsQuestionsFatca;
  disabled: boolean;
}): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const SingleChoiceDropdownWithInfo = questionId.split('Q')[1].split('.');
  const SingleChoiceDropdownWithInfo_FieldName = `applicants.${applicantsIndex}.${getSubQuestionsIndex(
    SingleChoiceDropdownWithInfo
  )}.answer`;

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
        <MFSelectField
          name={SingleChoiceDropdownWithInfo_FieldName}
          label={isMandatory === 'true' ? label + ' *' : label}
          items={options.map((option) => ({
            key: option.label,
            value: option.label,
          }))}
          onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
            setFieldValue(SingleChoiceDropdownWithInfo_FieldName, value);
            options.forEach((option) => {
              if (option.showQs) {
                updateVisibility(value as string, data, values);
              }
            });
          }}
          disabled={disabled}
        />
        <InfoOutlinedIcon
          color="info"
          fontSize="small"
          onClick={handleOpen}
          sx={{ position: 'relative', top: 4, left: 5 }}
        />
      </Box>
      <Dialog
        onClose={handleClose}
        open={open}
        sx={{
          '.MuiPaper-root ': {
            maxWidth: 800,
            p: { xs: 2, sm: '15px 5px 35px' },
            borderRadius: '10px',
            height: 'unset',
            overflowY: 'auto',
          },
        }}>
        <IconButton onClick={handleClose} sx={{ alignSelf: 'flex-end' }}>
          <CloseIcon fontSize="medium" />
        </IconButton>
        <Box
          sx={{
            width: { xs: '100%', sm: '90%' },
            mx: 'auto',
            '.MuiTypography-root': {
              textAlign: 'start',
              fontFamily: 'Work Sans,sans-serif',
            },
          }}>
          <Typography sx={{ fontSize: 16, fontWeight: 400 }}>
            *If a TIN is unavailable, please provide the appropriate reason A, B or C as defined
            below:
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason A –{' '}
            </Typography>
            The country where the Account Holder is liable to pay tax does not issue TINs to its
            residents.
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason B –{' '}
            </Typography>
            The Account Holder is unable to obtain a TIN or equivalent number. (If this reason is
            selected, please explain why the Account Holder is unable to obtain a TIN in the box
            beside)
          </Typography>
          <Typography sx={{ fontSize: 16, fontWeight: 400, pt: 1 }}>
            <Typography sx={{ fontSize: 16, fontWeight: 500 }} component="span">
              Reason C –{' '}
            </Typography>
            No TIN is required because the tax residence jurisdiction that issued the TIN does not
            require a Financial Institution to collect and report the TIN
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              sx={{ minWidth: { xs: '170px', sm: '220px' }, mt: 3 }}
              onClick={handleClose}>
              Done
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}

export function Group({
  data,
  formValuesIndex,
  values,
  _applicants,
  disabled,
  nationalityDropdown,
}: {
  data: Partial<nonIndividualQuestionsFatca>;
  formValuesIndex: number;
  values: nonIndividualMdmsQuestionsFatca;
  _applicants: Partial<Applicant>[];
  disabled: boolean;
  nationalityDropdown: mdmsCountriesList[];
}): JSX.Element {
  return (
    <>
      {data.isVisible && data.section_header && <SectionHeader value={data.section_header} />}
      {data.isVisible && data.showHeader && data.header && (
        <SubHeading sx={{ marginTop: '23px', marginBottom: '20px' }}>{data.header}</SubHeading>
      )}
      {data.isVisible && data.showHeader && data.question_order === '' && data.question_text && (
        <Typography
          sx={{
            fontFamily: 'Work Sans,sans-serif',
            fontWeight: 400,
            fontSize: '16px',
            pt: 1,
            pb: 1,
            width: '100%',
          }}>
          {data.question_text}
        </Typography>
      )}
      {data.isVisible && data.question_order && data.question_type === 'group' && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            pt: 1,
            fontFamily: 'Work Sans,sans-serif',
          }}>
          <Typography sx={{ fontWeight: 400, fontSize: '16px' }}>{data.question_order}</Typography>
          <Typography
            sx={{
              pl: 1,
              fontWeight: 400,
              fontSize: '16px',
            }}>
            {data.question_text}
          </Typography>
        </Box>
      )}
      {data.isVisible &&
        data.sub_questions?.map((sub_qns, sub_index) => {
          if (sub_qns.isVisible && sub_qns.question_type === 'group') {
            return (
              <Group key={sub_index}
                data={sub_qns}
                formValuesIndex={formValuesIndex}
                values={values}
                _applicants={_applicants}
                disabled={disabled}
                nationalityDropdown={nationalityDropdown}
              />
            );
          }
          return (
            <React.Fragment key={sub_index}>
              {sub_qns.isVisible && sub_qns.question_type === 'single_choice_radio' && (
                <Grid xs={12} sm={12} sx={{ pt: 2 }}>
                  <SingleChoiceRadio
                    data={sub_qns}
                    values={values}
                    formValuesIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'info' && (
                <Box
                  sx={{
                    pt: 1,
                    pl: ['1.', '2.', '3.', '4.', '5.', '6.', ''].includes(sub_qns.question_order)
                      ? 0
                      : 2.5,
                  }}>
                  <Info order={sub_qns.question_order} text={sub_qns.question_text} />
                </Box>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'bordered_text_field' && (
                <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                  <BorderedTextField
                    applicantsIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    label={sub_qns.question_text}
                    placeHolder={sub_qns.placeholder || 'Please enter text here'}
                    isMandatory={sub_qns.isMandatory}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'open_text_field' && (
                <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                  <BorderedTextField
                    applicantsIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    placeHolder={sub_qns.placeholder || 'Please enter text here'}
                    sx={{
                      '&.MuiInputBase-root': {
                        position: 'relative',
                        border: 0,
                        borderBottom: '1px solid #ccc',
                        fontSize: 16,
                        pt: 4,
                      },
                    }}
                    isMandatory={sub_qns.isMandatory}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'single_choice_dropdown' && (
                <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                  <SingleChoiceDropdown
                    applicantsIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    label={sub_qns.question_text}
                    options={sub_qns.options || []}
                    isMandatory={sub_qns.isMandatory}
                    data={sub_qns}
                    values={values}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'single_choice_dropdown_withInfo' && (
                <Grid item xs={12} sm={6} sx={{ pt: 2 }}>
                  <SingleChoiceDropdownWithInfo
                    applicantsIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    label={sub_qns.question_text}
                    options={sub_qns.options || []}
                    isMandatory={sub_qns.isMandatory}
                    data={sub_qns}
                    values={values}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'date' && (
                <Grid xs={12} sm={6} sx={{ px: { xs: 0, sm: '30px' }, pt: 1 }}>
                  <FatcaDatePicker
                    applicantsIndex={formValuesIndex}
                    questionId={sub_qns.id}
                    label={sub_qns.question_text}
                    isMandatory={sub_qns.isMandatory}
                    disabled={disabled}
                  />
                </Grid>
              )}
              {sub_qns.isVisible && sub_qns.question_type === 'Controlling_person_details' && (
                <ControllingPerson
                  applicantsIndex={formValuesIndex}
                  header={sub_qns.header}
                  _applicants={_applicants}
                  questionText={sub_qns.question_text}
                  disabled={disabled}
                  nationalityDropdown={nationalityDropdown}
                />
              )}
            </React.Fragment>
          );
        })}
    </>
  );
}
const preFilling = (_fatca: Partial<nonIndividualQuestionsFatca>, name: string): any => {
  return {
    ..._fatca,
    sub_questions: _fatca.sub_questions?.map((item: Partial<nonIndividualQuestionsFatca>) => {
      if (item.backend_key && item.backend_key === 'entityName') {
        return {
          ...item,
          answer: name,
        };
      }
      if (item.backend_key && item.backend_key === 'declarationDate') {
        return {
          ...item,
          answer: new Date(),
        };
      }
      if (item.question_type === 'group' && item.sub_questions) {
        return preFilling(item, name);
      }
      return item;
    }),
  };
};
const assignAnsForInitialFatcaQns = (
  _fatca: Partial<nonIndividualQuestionsFatca>,
  existingFatcaDetails: Fatcadetails,
  fatca: nonIndividualMdmsQuestionsFatca
): any => {
  return {
    ..._fatca,
    sub_questions: _fatca.sub_questions?.map((item: Partial<nonIndividualQuestionsFatca>) => {
      if (existingFatcaDetails[item.backend_key as keyof Fatcadetails] as string) {
        return {
          ...item,
          answer: existingFatcaDetails[item.backend_key as keyof Fatcadetails] as string,
        };
      }
      if (item.question_type === 'group' && item.sub_questions) {
        return assignAnsForInitialFatcaQns(item, existingFatcaDetails, fatca);
      }
      return item;
    }),
  };
};

const setVisibilityForInitialFatca = (
  _qns: Partial<nonIndividualQuestionsFatca>,
  fatca: nonIndividualMdmsQuestionsFatca
): any => {
  return {
    ..._qns,
    sub_questions: _qns.sub_questions?.map((item: Partial<nonIndividualQuestionsFatca>) => {
      if (
        (item.question_type === 'single_choice_radio' ||
          item.question_type === 'single_choice_dropdown' ||
          item.question_type === 'single_choice_dropdown_withInfo') &&
        item.answer &&
        item.answer.length > 0 &&
        item.isVisible
      ) {
        updateVisibility(item.answer, item, fatca);
      }
      if (item.question_type === 'group' && item.sub_questions) {
        return setVisibilityForInitialFatca(item, fatca);
      }
      return item;
    }),
  };
};
export const AssignAnsAndVisibilityForInitialFatca = (
  fatca: nonIndividualMdmsQuestionsFatca,
  existingFatcaDetails: Fatcadetails
): any => {
  const assignAns = fatca.map((_fatca) => {
    if (existingFatcaDetails[_fatca.backend_key as keyof Fatcadetails]) {
      return {
        ..._fatca,
        answer: existingFatcaDetails[_fatca.backend_key as keyof Fatcadetails] as string,
      };
    }
    if (_fatca.question_type === 'group' && _fatca.sub_questions) {
      return assignAnsForInitialFatcaQns(_fatca, existingFatcaDetails, fatca);
    }
    return _fatca;
  });
  const finalInitialFatcaQns = assignAns.map((_q) => {
    if (
      (_q.question_type === 'single_choice_radio' ||
        _q.question_type === 'single_choice_dropdown' ||
        _q.question_type === 'single_choice_dropdown_withInfo') &&
      _q.answer &&
      _q.answer.length > 0
    ) {
      updateVisibility(_q.answer, _q, assignAns);
    }
    if (_q.question_type === 'group' && _q.sub_questions) {
      return setVisibilityForInitialFatca(_q, assignAns);
    }
    return _q;
  });
  return finalInitialFatcaQns;
};

export function Fatca(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);

  const [fatca, setFatca] = useState<nonIndividualMdmsQuestionsFatca>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const [documentsData, setDocumentsData] = useState<individuals_Poa_nonIndividuals_Documents[]>(
    []
  );
  const [mdmsCountriesList, setMdmsCountriesList] = useState<mdmsCountriesList[]>([]);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(FatcaMdmsData())) as unknown as FatcaMdms;
        setFatca(response.fatca_questions);
        const nationalitiesMdmsMasters = (await dispatch(
          getNationalityList()
        )) as unknown as nationaliyType;
        setMdmsCountriesList(nationalitiesMdmsMasters.countries);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        const { applicants = [] } = application || {};
        const getInvestorType = applicants[0]?.investorType?.toString();
        if (getInvestorType) {
          const response = (await dispatch(getDocuments())) as unknown as Document;
          if (getInvestorType && Object.keys(response).includes(getInvestorType)) {
            setDocumentsData(
              response[getInvestorType] as individuals_Poa_nonIndividuals_Documents[]
            );
          }
        }
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, [application]);

  const { applicants = [] } = application || {};

  const initialValues: Values = useMemo(() => {
    return {
      applicants: applicants.map((applicant) => {
        const { fatcadetail: existingFatcaDetails = null } = applicant || {};
        return {
          fatcadetail: existingFatcaDetails
            ? AssignAnsAndVisibilityForInitialFatca(fatca, existingFatcaDetails)
            : fatca.map((_fatca) => {
              if (_fatca.backend_key === 'entityName') {
                return {
                  ..._fatca,
                  answer: applicant.name || '',
                };
              }
              if (_fatca.backend_key === 'declarationDate') {
                return {
                  ..._fatca,
                  answer: new Date(),
                };
              }
              if (_fatca.question_type === 'group' && _fatca.sub_questions) {
                return preFilling(_fatca, applicant.name || '');
              }
              return _fatca;
            }),
        };
      }),
      saveType: 'save and proceed',
      _applicants: applicants,
    };
  }, [application, fatca]);

  const assignAnswer = (
    subQns: Partial<nonIndividualQuestionsFatca>,
    _applicants: Partial<Applicant>[]
  ): Fatcadetails => {
    const controllingPerson = _applicants
      .map((applicant) => {
        if (applicant.fatcadetail?.controllingpersons) {
          return applicant.fatcadetail?.controllingpersons;
        }
      })
      .filter((ele) => ele)
      .flat();

    return subQns.sub_questions?.reduce((_prev, _curr: Partial<nonIndividualQuestionsFatca>) => {
      if (_curr.answer && _curr.isVisible && _curr.question_type !== 'Controlling_person_details') {
        return { ..._prev, [_curr.backend_key as string]: _curr.answer };
      }
      if (_curr.isVisible && _curr.question_type === 'Controlling_person_details') {
        return { ..._prev, [_curr.backend_key as string]: controllingPerson };
      }
      if (_curr.question_type === 'group' && _curr.sub_questions?.length) {
        return { ..._prev, ...assignAnswer(_curr, _applicants) };
      }
      return _prev;
    }, {}) as any;
  };
  const resetAnswer = (
    subQns: Partial<nonIndividualQuestionsFatca>,
    _applicants: Partial<Applicant>[]
  ): Fatcadetails => {
    const controllingPerson = _applicants
      .map((applicant) => {
        if (applicant.fatcadetail?.controllingpersons) {
          return applicant.fatcadetail?.controllingpersons;
        }
      })
      .filter((ele) => ele)
      .flat();
    return subQns.sub_questions?.reduce((_prev, _curr: Partial<nonIndividualQuestionsFatca>) => {
      if (_curr.question_type === 'group' && _curr.sub_questions?.length) {
        return { ..._prev, ...resetAnswer(_curr, _applicants) };
      }
      return (
        _curr.backend_key && {
          ..._prev,
          [_curr.backend_key as string]:
            _curr.question_type === 'Controlling_person_details'
              ? controllingPerson.length
                ? controllingPerson.map((person) => ({ ...person, isActive: false }))
                : []
              : _curr.question_type === 'date'
                ? null
                : '',
        }
      );
    }, {} as any) as any;
  };
  const validationPopUp = (
    fatca: nonIndividualQuestionsFatca,
    _applicants: Partial<Applicant>[],
    mdmsCountriesList: mdmsCountriesList[]
  ) => {
    const controllingPerson = _applicants
      .map((applicant) => {
        if (applicant.fatcadetail?.controllingpersons) {
          return applicant.fatcadetail?.controllingpersons;
        }
      })
      .flat()
      .filter((item) => item?.isActive);
    const nationalityDropdown = mdmsCountriesList.map((list) => list.nationality);
    const countryDropdown = mdmsCountriesList.map((list) => list.name);
    if (
      !fatca.answer &&
      fatca.isVisible &&
      fatca.backend_key &&
      fatca.isMandatory === 'true' &&
      fatca.question_type === 'single_choice_radio'
    ) {
      throw new fatcaErrors(`Please fill all the fields`);
    }
    if (
      fatca.isVisible &&
      fatca.isMandatory === 'true' &&
      fatca.question_type === 'Controlling_person_details' &&
      !controllingPerson.length
    ) {
      throw new fatcaErrors(`Please Add Controlling Person`);
    }
    if (
      fatca.isVisible &&
      fatca.isMandatory === 'true' &&
      fatca.question_type === 'Controlling_person_details' &&
      controllingPerson.length
    ) {
      controllingPerson.map((person) => {
        if (!countryDropdown.includes(person?.countryOfResidense || '')) {
          throw `Invalid Country Of Residense for ${person?.name} in Controlling Person`;
        }
        if (!nationalityDropdown.includes(person?.nationality || '')) {
          throw `Invalid Nationality for ${person?.name} in Controlling Person`;
        }
        if (!countryDropdown.includes(person?.issuingCountry || '')) {
          throw `Invalid issuing country for ${person?.name} in Controlling Person`;
        }
        if (!countryDropdown.includes(person?.countryOfBirth || '')) {
          throw `Invalid Country of Birthfor ${person?.name} in Controlling Person`;
        }
      });
    }
    if (fatca.question_type === 'group' && fatca.sub_questions?.length) {
      fatca.sub_questions?.forEach((qun) => validationPopUp(qun, _applicants, mdmsCountriesList));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType, _applicants } = values;
      await nonIndividualFormValidForSubmission(application, documentsData, mdmsCountriesList);
      applicants.forEach((applicant) => {
        applicant.fatcadetail.forEach((fatca) => {
          validationPopUp(fatca, _applicants, mdmsCountriesList);
        });
      });
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        applicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => {
        const { fatcadetail = null } = applicants[index] || {};

        const fatcaObj = fatcadetail?.reduce((prev, curr) => {
          if (curr.question_type === 'group' && curr.sub_questions?.length) {
            return { ...prev, ...resetAnswer(curr, _applicants) };
          }
          return (
            curr.backend_key && {
              ...prev,
              [curr.backend_key as string]: '',
            }
          );
        }, {} as any) as Fatcadetails;

        const finalFatca = fatcadetail?.reduce((prev, curr) => {
          if (
            curr.answer &&
            curr.isVisible &&
            curr.question_type !== 'Controlling_person_details'
          ) {
            return { ...prev, [curr.backend_key as string]: curr.answer };
          }
          if (curr.question_type === 'group' && curr.sub_questions?.length) {
            return { ...prev, ...assignAnswer(curr, _applicants) };
          }
          return prev;
        }, {}) as Fatcadetails;
        return {
          ...applicant,
          fatcadetail: {
            ...fatcaObj,
            ...finalFatca,
            id: applicant?.fatcadetail?.id || null,
            applicantId: applicant?.fatcadetail?.applicantId || null,
            entityName: removeSingleQuote(finalFatca.entityName),
            nameOfStockExchange: removeSingleQuote(finalFatca.nameOfStockExchange),
            eIIssuingCountry: removeSingleQuote(finalFatca.eIIssuingCountry),
            eITypeOthers: removeSingleQuote(finalFatca.eITypeOthers),
            entityConstitutionType: removeSingleQuote(finalFatca.entityConstitutionType),
            entityCountryOfResidense: removeSingleQuote(finalFatca.entityCountryOfResidense),
            entityCustomerId: removeSingleQuote(finalFatca.entityCustomerId),
            nFECountry: removeSingleQuote(finalFatca.nFECountry),
            nFEntitiesExplanation: removeSingleQuote(finalFatca.nFEntitiesExplanation),
            fIFatcaCalssifcationOther: removeSingleQuote(finalFatca.fIFatcaCalssifcationOther),
            fICrsClassificationOther: removeSingleQuote(finalFatca.fICrsClassificationOther),
            controllingpersons:
              finalFatca.controllingpersons && finalFatca.controllingpersons.length > 0
                ? finalFatca.controllingpersons.map((controllingperson) => {
                  return {
                    ...controllingperson,
                    controllingPersonType: removeSingleQuote(
                      controllingperson.controllingPersonType
                    ),
                    correspondenceAddress: removeSingleQuote(
                      controllingperson.correspondenceAddress
                    ),
                    indentificationType: removeSingleQuote(controllingperson.indentificationType),
                    name: removeSingleQuote(controllingperson.name),
                    occupationType: removeSingleQuote(controllingperson.occupationType),
                  };
                })
                : finalFatca.controllingpersons,
          },
        };
      });
      const checkApplication = applicationComparison(
        {
          ...application,
        },
        {
          ...application,
          applicants: updatedApplicants,
          currentStep: !!currentStep && currentStep > 8 ? currentStep : Number(currentStep) + 1,
        }
      );
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              currentStep: 9,
            },
            applicationId: id,
            ...(saveType !== 'save and proceed' && {
              toastMessage: '',
            }),
          })
        );
        saveType === 'save and proceed'
          ? history.push(
            role === USER_ROLES.AMCAPPROVER
              ? `/application-details/${id}`
              : `/non-individual-application-preview/${id}`
          )
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (saveType !== 'save and proceed') {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        saveType === 'save and proceed'
          ? history.push(
            role === USER_ROLES.AMCAPPROVER
              ? `/application-details/${id}`
              : `/non-individual-application-preview/${id}`
          )
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      e instanceof fatcaErrors && dispatch(showError((e as fatcaErrors).message));
      typeof e === 'string' && dispatch(showError(e as string));
      console.error((e as Error).message);
    }
  };
  const isFieldDisabled = [USER_ROLES.AMCAPPROVER].includes(role);
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, nonIndividualFatcaSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((applicant, idx) => (
            <React.Fragment key={idx}>
              {applicant.fatcadetail.map((item) => (
                <React.Fragment key={item.id}>
                  {item.isVisible && item.section_header && item.question_type !== 'group' && (
                    <SectionHeader value={item.section_header} />
                  )}

                  {item.isVisible && item.question_type === 'single_choice_radio' && (
                    <SingleChoiceRadio
                      data={item}
                      values={applicant.fatcadetail}
                      formValuesIndex={idx}
                      questionId={item.id}
                      disabled={false}
                    />
                  )}
                  {item.isVisible && item.question_type === 'group' && (
                    <Group
                      data={item}
                      formValuesIndex={idx}
                      values={applicant.fatcadetail}
                      _applicants={values._applicants}
                      disabled={false}
                      nationalityDropdown={mdmsCountriesList}
                    />
                  )}
                  {item.isVisible && item.question_type === 'open_text_field' && (
                    <OpenTextField
                      text={item.question_text}
                      applicantsIndex={idx}
                      questionId={item.id}
                      placeHolder={item?.placeholder || 'Please enter text here'}
                      isMandatory={item.isMandatory}
                      disabled={false}
                    />
                  )}
                  {item.isVisible && item.question_type === 'bordered_text_field' && (
                    <Grid item xs={12} sm={6} sx={{ pr: { xs: 0, sm: '30px' }, pt: 2 }}>
                      <BorderedTextField
                        applicantsIndex={idx}
                        questionId={item.id}
                        label={item.question_text}
                        placeHolder={item?.placeholder || 'Please enter text here'}
                        isMandatory={item.isMandatory}
                        disabled={false}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))}
          <ProceedSaveLater
            saveButtonText={isFieldDisabled ? 'Save Application' : 'Preview'}
            showEndIcon={false}
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
            showSaveForLater={isFieldDisabled ? false : true}
          />
        </Grid>
      )}
    </Formik>
  );
}

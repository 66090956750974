import {
  Box,
  FormControl,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { CommonLayout, AmountUnit } from '../commonComponents';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { RmDashboardType, RmDetailEntity } from '../../redux-store/types/api-types';
import SearchIcon from '@mui/icons-material/Search';
import { ColumnType, DataTable } from '../DataTable';
import { getRMDashBoardMetrics } from '../../redux-store/actions/userManagement';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';

const headers = (): ColumnType[] => {
  return [
    {
      header: 'RM Name',
      sortingKey: 'RMNAME',

      valueGetter: (row: RmDetailEntity) => row?.RMNAME || 'N/A',
    },
    {
      header: 'Allocation %',
      sortingKey: 'MktValPercent',
      renderEditDeleteCell: (row: RmDetailEntity): JSX.Element => {
        return (
          <>
            {row.MktValPercent ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}>
                <Typography sx={{ width: 65 }}>{row.MktValPercent}%</Typography>
                <Box sx={{ width: '80px' }}>
                  <LinearProgress
                    variant="determinate"
                    value={row.MktValPercent}
                    sx={{
                      background: ' #DAF3E1',
                      borderRadius: '10px',
                      height: 7,
                      '& .MuiLinearProgress-bar': {
                        background: '#50AE98',
                        borderRadius: '10px 0px 0px 10px',
                      },
                    }}
                  />
                </Box>
              </Box>
            ) : (
              'N/A'
            )}
          </>
        );
      },
    },
    {
      header: 'Location',
      sortingKey: 'RMBranch',
      valueGetter: (row: RmDetailEntity) => row?.RMBranch || 'N/A',
    },
    {
      header: 'Book Size',
      sortingKey: 'MktVal',
      valueGetter: (row: RmDetailEntity, amountUnit?: number | null | undefined) =>
        (row?.MktVal && `${formatToIndianCurrency(row?.MktVal, amountUnit)}`) || 'N/A',
    },
    {
      header: 'No. of Investors',
      sortingKey: 'InvCnt',
      valueGetter: (row: RmDetailEntity) => row?.InvCnt || 'N/A',
    },
  ];
};

export default function TotalRmDetail(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);
  const [search, setSearch] = useState<string>();
  const valueConverter = useSelector((state:any)=> state.valueConverter)
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setDashBoardMetricsData(null);
    (async function () {
      try {
        setLoading(true);
        const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
        if (!isComponentActive) {
          return;
        }
        setDashBoardMetricsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  return (
    <CommonLayout>
      <>
        <Box>
          <Box
            sx={{
              display: { xs: 'block', sm: 'flex' },
              alignItems: 'center',
              justifyContent: 'flex-end',
              pb: 1,
            }}>
               <Box sx={{marginRight:3}}>
                      <AmountUnit />
                      </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 } }}>
              <FormControl sx={{ width: 250, mr: 2 }} variant="outlined">
                <OutlinedInput
                  value={search}
                  onChange={({ target: { value } }) => {
                    setSearch(value);
                  }}
                  sx={{ '& .MuiOutlinedInput-input': { py: 1, fontSize: 13 } }}
                  id="search-field"
                  placeholder="Search by RM name"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </Box>
          </Box>
          {isLoading ? (
            <LinearProgress sx={{ mt: 2.5 }} />
          ) : (
            <Box mt={2.5} sx={{ maxWidth: { lg: '100%' } }}>
              {dashBoardMetricsData && (
                <>
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={[...headers()]}
                    tableData={
                      dashBoardMetricsData?.rmDetail &&
                      dashBoardMetricsData?.rmDetail.filter((ele: any) =>
                        search ? ele.RMNAME.toUpperCase().includes(search?.toUpperCase()) : ele
                      )
                    }
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                      background: 'transparent',
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: '500px', minWidth: 450 }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </>
    </CommonLayout>
  );
}

import { East, EastOutlined, Search } from '@mui/icons-material';
import { Box, IconButton, LinearProgress, Switch, Typography, Button } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MFTextField } from '../../lib/formik';
import { DatePicker } from '../../lib/formik/DatePicker';
import { getRevenueData } from '../../redux-store/actions/userManagement';
import { RootStateType } from '../../redux-store/reducers';
import { RevenueDataType, RMProduct } from '../../redux-store/types/api-types';
import { formatDate_MMDDYYYY } from '../../utils/date';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../DataTable';
import { CommonLayout, AmountUnit } from '../commonComponents';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

const commonHeaders = [
  {
    header: 'No. of Investors',
    sortingKey: 'InvCnt',
    valueGetter: (row: RMProduct) => row?.InvCnt || 'N/A',
  },
  {
    header: 'Revenue',
    sortingKey: 'Revenue',
    valueGetter: (row: RMProduct, amountUnit?: number | null | undefined) =>
      row?.Revenue || row?.Revenue === 0
        ? `${formatToIndianCurrency(row?.Revenue, amountUnit)}`
        : 'N/A',
  },
  {
    header: 'Growth Rate M',
    sortingKey: 'GrowthRate',
    valueGetter: (row: RMProduct, amountUnit?: number | null | undefined) =>
      (row?.GrowthRate && `${formatToIndianCurrency(row?.GrowthRate, amountUnit)}`) || 'N/A',
  },
  {
    header: '% of Total',
    sortingKey: 'Hper',
    valueGetter: (row: RMProduct) => (
      <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
        <Typography variant="caption" sx={{ minWidth: '25%', fontSize: 'inherit' }}>
          {row?.Hper || row?.Hper === 0 ? `${row?.Hper}` : 'N/A'}
        </Typography>
        <LinearProgress
          sx={{ flex: 1, height: '0.5rem', borderRadius: '10px' }}
          value={row?.Hper || 0}
          variant="determinate"
        />
      </Box>
    ),
  },
];

const productTableHeaders = (): ColumnType[] => {
  return [
    {
      header: 'Product Name',
      sortingKey: 'ProductType',
      valueGetter: (row: RMProduct) => row?.ProductType || 'N/A',
    },
    ...commonHeaders,
    {
      header: '',
      valueGetter: () => (
        <IconButton sx={{ backgroundColor: '#f4fcfc', '&:hover': { backgroundColor: '#f4fcfc' } }}>
          <East sx={{ fontSize: '14px' }} />
        </IconButton>
      ),
    },
  ];
};

const rmTableHeaders = (): ColumnType[] => {
  return [
    {
      header: 'RM Name',
      sortingKey: 'RMCODE',
      valueGetter: (row: RMProduct) => row.RMCODE,
    },
    ...commonHeaders,
  ];
};

export default function TotalRevenue(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [levelWiseTable, setLevelWiseTable] = useState<'RM' | 'Product'>('RM');
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RevenueDataType | null>(null);
  const [search, setSearch] = useState<string>('');
  const [startDate, setStartDate] = useState<string | Date>(new Date('04/01/2023'));
  const [endDate, setEndDate] = useState<string | Date>(new Date('06/30/2023'));
  const [fetchData, setFetchData] = useState<boolean>(true);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    if (fetchData) {
      setDashBoardMetricsData(null);
      (async function () {
        try {
          setLoading(true);
          const response = (await dispatch(
            getRevenueData({
              // RMID,
              fromDate: formatDate_MMDDYYYY(startDate),
              toDate: formatDate_MMDDYYYY(endDate),
            })
          )) as unknown as RevenueDataType;
          if (!isComponentActive) {
            return;
          }
          setDashBoardMetricsData(response);
          setLoading(false);
          setFetchData(false);
        } catch (e) {
          setLoading(false);
          setFetchData(false);
          console.log((e as Error).message);
        }
      })();
    }
    return () => {
      isComponentActive = false;
    };
  }, [fetchData]);
console.log("data", dashBoardMetricsData );

  return (
    <CommonLayout>
      <>
        <Box>
          {/* <Button
       onClick={() => window.print()}
        className="print__button" 
            sx={{
              bgcolor: '#337FC9',
              borderRadius: '5px',
              fontWeight: 'normal',
              padding: 0,
              px: 0.5,
              '&:hover': { bgcolor: '#2868a6' },
            }}>
            PDF
          </Button>  */}
          {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
          <Box
            sx={{
              display: 'block',
              pb: 1,
            }}>
            <Typography
              sx={{
                fontSize: 22,
                color: '#2057A6',
                fontWeight: 600,
              }}>
              Total Revenue - {levelWiseTable}
            </Typography>
          </Box>
          {isLoading ? (
            <LinearProgress sx={{ mt: 2.5 }} />
          ) : (
            <Box
              mt={2.5}
              sx={{
                maxWidth: { lg: '100%' },
                background: ' #FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '10px',
                p: 2,
                pt: 0,
              }}>
              <Formik
                initialValues={{}}
                onSubmit={() => {
                  setFetchData(true);
                }}>
                <Form>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}>
                    <Box
                      sx={{ display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center' }}>
                      {levelWiseTable === 'RM' && (
                        <MFTextField
                          placeholder={'Search by RM name'}
                          name={'rmName'}
                          value={search}
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          endAdornment={<Search sx={{ pr: 0.5, color: '#000', opacity: 0.4 }} />}
                          sx={{
                            width: { md: '10rem', lg: '16rem' },
                            '& .MuiInputBase-root': {
                              fontSize: { md: 12, lg: 14 },
                              border: '0 !important',
                              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                              ':hover': {
                                border: 0,
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                              },
                            },
                          }}
                        />
                      )}
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 1,
                          alignItems: 'center',
                        }}>
                        <Box sx={{ maxWidth: { md: '120px', lg: '145px' } }}>
                          <DatePicker
                            placeholder="Start Date"
                            name="startDate"
                            value={startDate}
                            disabled
                            // onChange={(newDate) => newDate && setStartDate(newDate)}
                            inputLabelStyles={{
                              transform: 'unset',
                              fontSize: 10,
                              fontWeight: 400,
                              color: '#8E8EA1',
                            }}
                            textFieldStyles={{
                              '& .MuiInputBase-root': {
                                fontSize: { md: 12, lg: 12 },
                                border: '0 !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                ':hover': {
                                  border: 0,
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                },
                              },
                            }}
                        
                          />
                        </Box>
                        <Box sx={{ maxWidth: { md: '120px', lg: '145px' } }}>
                          <DatePicker
                            placeholder="End Date"
                            name="endDate"
                            value={endDate}
                            disabled
                            // onChange={(newDate) => newDate && setEndDate(newDate)}
                            inputLabelStyles={{
                              transform: 'unset',
                              fontSize: 10,
                              fontWeight: 400,
                              color: '#8E8EA1',
                            }}
                            textFieldStyles={{
                              '& .MuiInputBase-root': {
                                fontSize: { md: 12, lg: 12 },
                                border: '0 !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                ':hover': {
                                  border: 0,
                                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                },
                              },
                            }}
                          />
                        </Box>
                        {/* <IconButton
                          type="submit"
                          sx={{
                            width: '30px',
                            height: '30px',
                            boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.25)',
                            border: '1px solid #4990F0',
                            borderRadius: '5px',
                            color: '#4990F0',
                          }}>
                          <EastOutlined sx={{ fontSize: '16px' }} />
                        </IconButton> */}
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Box sx={{ marginRight: 3 }}>
                        <AmountUnit />
                      </Box>
                      <Typography sx={{ fontSize: { md: 12, lg: 14 } }}>RM</Typography>
                      <Switch
                        checked={levelWiseTable === 'Product'}
                        onChange={(_, checked) => setLevelWiseTable(checked ? 'Product' : 'RM')}
                        sx={{
                          p: 0,
                          mx: { md: 1, lg: 1.5 },
                          height: 20,
                          width: { md: 40, lg: 44 },
                          '& .MuiSwitch-track': {
                            background: '#4990F0',
                            opacity: 1,
                            borderRadius: '12px',
                          },
                          '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                          '& .MuiSwitch-switchBase': {
                            p: 0,
                            '&.Mui-checked': {
                              color: '#fff',
                              transform: 'translateX(32px)',
                              '& + .MuiSwitch-track': {
                                backgroundColor: '#4990F0',
                                opacity: 1,
                              },
                            },
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: { md: 12, lg: 14 } }}>Product</Typography>
                    </Box>
                  </Box>
                </Form>
              </Formik>
              {levelWiseTable === 'Product' ? (
                <DataTable
                  isLoading={isLoading}
                  tableHeader={[...productTableHeaders()]}
                  tableData={dashBoardMetricsData?.productWise && dashBoardMetricsData?.productWise}
                  rowCustomStyles={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '.MuiTableCell-body ': { color: '#686e72' },
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                    background: 'transparent',
                  }}
                  tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                      textAlign: 'left',
                    },
                  }}
                  boxStyles={{ minHeight: '500px', minWidth: 450 }}
                />
              ) : (
                <>
                  <DataTable
                    isLoading={isLoading}
                    tableHeader={[...rmTableHeaders()]}
                    tableData={
                      dashBoardMetricsData?.rmWise
                      // &&
                      // dashBoardMetricsData?.rmWise.filter((ele) =>
                      //   ele?.RMID?.toLowerCase().includes(search.toLowerCase())
                      // )
                    }
                    rowCustomStyles={{
                      '&:last-child td, &:last-child th': { border: 0 },
                      '.MuiTableCell-body ': { color: '#686e72' },
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                      background: 'transparent',
                    }}
                    tableHeaderCustomStyles={{
                      '& .MuiTableCell-root': {
                        textAlign: 'left',
                      },
                    }}
                    boxStyles={{ minHeight: '500px', minWidth: 450 }}
                  />
                </>
              )}
            </Box>
          )}
        </Box>
      </>
    </CommonLayout>
  );
}

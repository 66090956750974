import { Box, Button, CircularProgress, Divider, FormControlLabel, FormLabel, InputLabel, Modal, Radio, RadioGroup, Switch, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import "./styles.css";
import { formatDate_, formatToIndianCurrency } from "../../../utils/utilityFunctions";
import { CloseIcon, DeleteIcon } from "../../customSVGs";
import { useState, useEffect } from "react";
import { BSECodeResponse, ClientMandateResponse, GetAccountType, MandateResponse, OrdersTypes, PostRecommendedSchemes, SchemeList } from "../../../redux-store/types/api-types";
import Highcharts from 'highcharts';
import { HighchartsReact } from 'highcharts-react-official';
import { formatLongDate } from "../../../utils/date";
import { useDispatch, useSelector } from 'react-redux';
import { getRmDetails, get_account_type, get_recommendation_validation, post_recommended_schemes, RM_create_transaction, get_bse_code, get_client_mandateIds, get_folioList } from "../../../redux-store/actions/userManagement";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Key } from "@mui/icons-material";
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { trTypeMapping, trTypeToSchemeTypeMapping } from "./orders";
import Swal from "sweetalert2";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SET_SCHEME_FOR_BASKET } from "../../../redux-store/types/globalVariables";

export function SubFundCategoryCard(
  { onSchemeSelection, schemeDetail, OnSchemeDeSelection, handleSetViewSchemeDetails, allocation, selectedSchemes, openModal }:
    {
      onSchemeSelection: (schemeId: number) => void;
      schemeDetail: SchemeList;
      OnSchemeDeSelection: (schemeId: number) => void;
      handleSetViewSchemeDetails: (val: boolean, schemeDetail: SchemeList) => void;
      allocation: boolean;
      selectedSchemes: SchemeList[];
      openModal: boolean;
    }
) {
  const globalVariables = useSelector((state: any) => state.globalVariable);
  const [schemeSelected, setSchemeSelected] = useState<boolean>(false);

  const handleSchemeCheckboxChange = (e: any) => {
    if (e.target.checked == true) {
      onSchemeSelection(schemeDetail.SchemeID);
      setSchemeSelected(true);
    }
    else {
      OnSchemeDeSelection(schemeDetail.SchemeID);
      setSchemeSelected(false);
    }
  }

  useEffect(() => {
    if (!globalVariables.includes(schemeDetail)) {
      setSchemeSelected(false);
    }
    else setSchemeSelected(true);
  }, [openModal])

  const plan =
    schemeDetail.Growthoption === 1
      ? "Growth"
      : schemeDetail.Dividendreinvestment === 1
        ? "Dividend"
        : schemeDetail.DividendPayout === 1
          ? "Dividend"
          : "";
  const subPlan =
    schemeDetail.Dividendreinvestment === 1
      ? "Reinvestment"
      : schemeDetail.DividendPayout === 1
        ? "Payout"
        : "";

  const categoryValue = schemeDetail.Cat_Return5yr;
  const selectedValue = schemeDetail.Sch_Return5Yr;

  function roundToTwo(num: number) {
    const num2 = num + "e+2";

    return +(Math.round(Number(num2)) + "e-2");
  }

  const Nanprocessor = function (entry: any) {
    if (entry) {
      return entry;
    } else {
      return 0;
    }
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer', my: 2.5 }}>
        <Box sx={{ width: '10%' }}>
          <Checkbox sx={{ color: '#337FC9', mx: 1.5 }} onChange={handleSchemeCheckboxChange} checked={schemeSelected} />
        </Box>
        <Box sx={{ marginRight: 10, display: 'flex', flexDirection: 'column', width: "25%" }}>
          <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.5 }}>
            {schemeDetail.SchemeName}
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
            {!!schemeDetail.AssetClassName && (
              <Bubble text={schemeDetail.AssetClassName} />
            )}
            {!!schemeDetail.Sub_AssetclassName && (
              <Bubble text={schemeDetail.Sub_AssetclassName} />
            )}
            {!!plan && <Bubble text={plan} />}
            {subPlan.length !== 0 && <Bubble text={subPlan} />}
            {!!subPlan && !!schemeDetail.Dividendfrequency && (
              <Bubble text={schemeDetail.Dividendfrequency} />
            )}
          </Box>
        </Box>
        <Box sx={{ width: "15%" }}>
          <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>AUM</Typography>
          <Typography sx={{ fontSize: 16 }}>
            {schemeDetail.Aum === "0" || !schemeDetail.Aum
              ? "NA"
              : formatToIndianCurrency(schemeDetail.Aum, 1)}
          </Typography>
        </Box>

        <Box sx={{ width: "17%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>
            {!allocation ? "Category Avg Returns" : "Allocation"}
          </Typography>
          <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
            {" "}
            {`${Nanprocessor(roundToTwo(Number(categoryValue)))}%`}
          </Typography>
        </Box>

        <Box sx={{ width: "15%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Returns</Typography>
          <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
            {selectedValue === "0" || !selectedValue
              ? "NA"
              : `${Nanprocessor(roundToTwo(Number(selectedValue)))}%`}
          </Typography>
        </Box>
        <Box sx={{ width: '8%' }}>
          <ArrowForwardIosIcon onClick={() => { handleSetViewSchemeDetails(true, schemeDetail); }} sx={{ borderRadius: 1, backgroundColor: '#337FC9', color: '#ffffff', p: 0.2, mx: 1.5 }} />
        </Box>
      </Box>

    </div>
  )
}

export function Bubble(props: { text: string }) {
  return (
    <div className="bubbleDiv">
      <div className="bubbleText">
        {props.text}
      </div>
    </div>
  );
}

function roundToTwo(num: number) {
  const num2 = num + "e+2";

  return +(Math.round(Number(num2)) + "e-2");
}

const Nanprocessor = function (entry: any) {
  if (entry) {
    return entry;
  } else {
    return 0;
  }
};

interface schemeParams {
  SchemeID: number;
  SchemeName: string;
  Aum: string;
  Sch_Return5Yr: number;
  minPurchase: any;
  maxPurchase: any;
  switchValue: string;
  invAmount: number;
  selectedAccount: string;
  instalmentAmount: number;
  error: boolean;
  errorInstalment: boolean;
  errorSIPDay: boolean;
  errorAccountLumpsum: boolean;
  errorAccountSIP: boolean;
  errorMessageAccount: string;
  errorFrequency: boolean;
  errorFolioList: boolean;
  errorInstalmentAmount: boolean;
  errorFirstOrderToday: boolean;
  errorMandate: boolean
  errorMessage: string;
  errorMessageFolioList: string;
  errorMessageInstalment: string;
  errorMessageSIPDay: string;
  errorMessageFrequency: string;
  errorMessageInstalmentAmount: string;
  errorMessageFirstOrderToday: string;
  errorMessageMandate: string;
  AssetClassName: string;
  Sub_AssetclassName: string;
  Dividendfrequency: string;
  Growthoption: number;
  Dividendreinvestment: number;
  DividendPayout: number;
  instalments: number;
  SIPDay: string;
  mandate: string;
  frequency: string;
  Sch: any;
  folio: string;
  datePicker: any;
  firstOrderToday: string;
  AmcID: number;
  BSECode: string
}

interface sipSchemeValidation {
  Productcode_RTA: string,
  SIPFREQUENCY: string | null,
  SIPDATES: string,
  SIPMINIMUMINSTALLMENTAMOUNT: string,
  SIPMAXIMUMINSTALLMENTAMOUNT: string,
  SIPMINIMUMINSTALLMENTNUMBERS: string,
  SIPMAXIMUMINSTALLMENTNUMBERS: string,
  SCHEMEISIN: string,
  SIPMINIMUMGAP: string,
  IPMAXIMUMGAP: string,
  SIPMULTIPLIERAMOUNT: string
}
export function RecommendedSchemesModal({ handleModalClose, selectedSchemes, OnSchemeDeSelection, investorId, isRecommended, }:
  { handleModalClose: () => void; selectedSchemes: SchemeList[] | undefined; OnSchemeDeSelection: (schemeId: number) => void; investorId: string; isRecommended: (val: boolean) => void; }) {

  const dispatch = useDispatch();
  const [schemeParams, setSchemeParams] = useState<schemeParams[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rmId, setRmId] = useState<number | null>();
  const [accountType, setAccountType] = useState<GetAccountType | null>();
  const [BSESchemeCode, setBSESchemeCode] = useState<BSECodeResponse | null>()
  const [validations, getValidations] = useState<any>();
  const [SIPInstallment, setSIPInstallment] = useState<sipSchemeValidation[]>();
  const [selectedAccountType, setSelectedAccountType] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [radioValue, setRadioValue] = useState<string>('recommend');
  const [Recommend, setRecommended] = useState<boolean>(false);
  const [folioList, setFolioList] = useState<any>()
  const [switchValue, setSwitchValue] = useState<string>("Lumpsum");
  const [placeOrderResponse, setPlaceOrderResponse] = useState<any>();
  const [mandateId, setMandateId] = useState<ClientMandateResponse | null>();
  const globalVariables = useSelector((state: any) => state.globalVariable);
  const handleRadioChange = (event: any, value: any) => {
    setRadioValue(event.target.value);
  };
  const frequencyMapping: any = {
    "DAILY": "1",
    "Weekly": "2",
    "MONTHLY": "3",
    "QUARTERLY": "4",
    "ANNUALLY": "6",
  }
  console.log("selectedScheme", selectedSchemes, globalVariables, accountType)
  useEffect(() => {
    const schemeParams: any = [];
    globalVariables?.map((item: any) => {
      const swtVal = {
        SchemeID: item.SchemeID,
        SchemeName: item.SchemeName,
        Aum: item.Aum,
        Sch_Return5Yr: item.Sch_Return5Yr,
        minPurchase: item.MinimumPurchaseAmount,
        maxPurchase: item.MaximumPurchaseAmount,
        switchValue: "Lumpsum",
        instalmentAmount: null,
        invAmount: null,
        selectedAccount: "",
        error: false,
        errorInstalment: false,
        errorFolioList: false,
        errorSIPDay: false,
        errorFrequency: false,
        errorInstalmentAmount: false,
        errorMessage: "",
        errorMessageFolioList: "",
        errorMessageInstalment: "",
        errorMessageSIPDay: "",
        errorMessageFrequency: "",
        errorMessageInstalmentAmount: "",
        AssetClassName: item.AssetClassName,
        Sub_AssetclassName: item.Sub_AssetclassName,
        Dividendfrequency: item.Dividendfrequency,
        Growthoption: item.Growthoption,
        Dividendreinvestment: item.Dividendreinvestment,
        DividendPayout: item.DividendPayout,
        instalments: null,
        SIPDay: "",
        mandate: "",
        folio: "",
        frequency: null,
        Sch: item.Sch
      }
      schemeParams.push(swtVal);
    })
    setSchemeParams(schemeParams);
  }, [])

  useEffect(() => {

    let isComponentActive = true;
    setRmId(null);
    (async function () {
      try {
        const response = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        if (!isComponentActive) {
          return;
        }
        setRmId(response.Header_ID);

      } catch (e) {
        console.log((e as Error).message);
      }
    })();

    return () => {
      isComponentActive = false;
    };
  }, [])


  useEffect(() => {

    let isComponentActive = true;
    let responseAccount: any = {}
    setAccountType(null);
    const newAccountID = async function () {
      try {
        responseAccount = (await dispatch(
          get_account_type({
            rmID: rmId,
            ClientCode: investorId
          })
        )) as unknown as GetAccountType;
        if (!isComponentActive) {
          return;
        }
        setAccountType(responseAccount);
        console.log("accountType2", responseAccount, responseAccount, selectedSchemes)
        selectedSchemes?.map(async (item: any, index: number) => {
          // console.log("accountType", accountType, accountType?.account_type[0], responseAccount, responseAccount?.folio[0])
          try {
            const response = (await dispatch(
              get_folioList({
                "AccountID": responseAccount?.account_type[0]?.AccountId,
                "AMC": item?.AmcID
              })
            )) as unknown as any;
            console.log("response", response, response?.folio[0])
            setFolioList(response?.folio[0]);

          } catch (e) {
            console.log((e as Error).message);
          }
        })
      } catch (e) {
        console.log((e as Error).message);
      }
    }
    newAccountID()
    console.log("accountType1", responseAccount, responseAccount, selectedSchemes)



    return () => {
      isComponentActive = false;
    };
  }, [])



  async function getBSECode(item: any) {
    let isComponentActive = true;

    try {
      // setIsLoading(true);
      const response = (await dispatch(
        get_bse_code({
          TrType: switchValue === 'SIP' ? 'ISIP' : 'New',
          Amount: switchValue === 'SIP' ? item.instalmentAmount : item.invAmount,
          Growthoption: item?.Growthoption,
          DividendReinvestment: item?.Dividendreinvestment,
          RTACODE: item?.Sch,
        })
      )) as unknown as BSECodeResponse;

      if (!isComponentActive) {
        return;
      }

      if (response && response.bse_code && response.bse_code.length > 0) {
        item.BSECode = response.bse_code[0][0]?.BSE_ProductCod;

      }

      // setIsLoading(false);
      return item;

    } catch (e) {
      console.log((e as Error).message);
    } finally {
      // setIsLoading(false);
    }

    return () => {
      isComponentActive = false;
    };
  }

  //Function to fetch client mandates
  async function getClientMandates(data: any) {
    const isComponentActive = true;
    (async function () {
      try {
        // setIsLoading(true);
        const response = (await dispatch(
          get_client_mandateIds({
            clientId: data
          })
        )) as unknown as ClientMandateResponse;
        if (!isComponentActive) {
          return;
        }
        setMandateId(response)
        // setIsLoading(false);
      } catch (e) {
        console.log((e as Error).message);
        setIsLoading(false);
      }
    })();
  }

  useEffect(() => {

    const temp: any = [];
    const validationList: any = [];
    globalVariables?.map(async (item: SchemeList) => {
      const obj = await recommendation_val(item.Sch);
      temp.push({
        [item.Sch]: (obj.validations[1] as any),
      });
      validationList.push({
        Productcode_RTA: item.Sch,
        SIPFREQUENCY: null,
        SIPDATES: "",
        SIPMINIMUMINSTALLMENTAMOUNT: "",
        SIPMAXIMUMINSTALLMENTAMOUNT: "",
        SIPMINIMUMINSTALLMENTNUMBERS: "",
        SIPMAXIMUMINSTALLMENTNUMBERS: "",
        SCHEMEISIN: "",
        SIPMINIMUMGAP: "",
        IPMAXIMUMGAP: "",
        SIPMULTIPLIERAMOUNT: ""
      });
    })
    getValidations(temp);
    setSIPInstallment(validationList);
  }, [globalVariables])

  const recommendation_val = async (sch: any): Promise<any> => {
    const isComponentActive = true;
    try {
      const response = (await dispatch(
        get_recommendation_validation({ sch, TrType: "SIP" })
      )) as any;
      if (!isComponentActive) {
        return Promise.resolve();
      }
      return response;
    } catch (e) {
      console.log((e as Error).message);
    }
    return Promise.resolve();
  };

  // const folioList = async (sch: any): Promise<any> => {
  //   const isComponentActive = true;
  //   try {
  //     const response = (await dispatch(
  //       get_folioList({
  //         "AccountID": "W000906",
  //         "AMC": "18"
  //     })
  //   )) as any;
  //   console.log("response",response)
  //     if (!isComponentActive) {
  //       return Promise.resolve();
  //     }
  //     return response;
  //   } catch (e) {
  //     console.log((e as Error).message);
  //   }
  //   return Promise.resolve();
  // };


  const OnSchemeDeSelection_ = (scId: number) => {
    const schemeList = schemeParams?.filter((val) => val.SchemeID != scId);
    setSchemeParams(schemeList);
    OnSchemeDeSelection(scId);
  }

  const handleSubmit = async () => {
    let shouldReturn = false;
    if (!invAmountValidation() || !SIPDataValidation()) shouldReturn = true;
    const data: any = [];
    if (radioValue === 'recommend') {
      schemeParams?.map((item) => {
        const obj = {
          SchemeID: item.SchemeID,
          InvestmentAmount: switchValue === 'SIP' ? item.instalmentAmount : item.invAmount,
          SchemeType: switchValue === 'SIP' ? 'ISIP' : 'New',
          rmID: rmId,
          ClientCode: investorId,
          No_of_Installments: switchValue === 'SIP' ? (item?.frequency === 'DAILY' ? null : item?.instalments) : null,
          SIP_date: switchValue === 'SIP' ? item.SIPDay : "",
          Frequency: switchValue === 'SIP' ? item.frequency : "",
          AccountID: item.selectedAccount,
          ToSchemeID: "",
          Folio: item.folio == "New Folio" ? "" : item.folio,
          Units: 0,
          PFFlag: null
        }
        data.push(obj);
      })
      const recommendationData = {
        recommendation_data: data
      }
      if (shouldReturn == true) return;
      await postRecommendedSchemes(recommendationData);
    }
    else {
      schemeParams?.map(async (item) => {
        await getBSECode(item);
        if (item.switchValue == "SIP") {

          const obj = {
            "AccNo": item.folio == "New Folio" ? "" : item.folio,
            "AmcId": globalVariables?.[0].AmcID,
            "BSE_SchemeCode": item?.BSECode,
            "DividendReinvestment": item?.Dividendreinvestment,
            "FirstOrderToday": item?.firstOrderToday,
            "Frequency": frequencyMapping[item?.frequency],
            "Growthoption": item?.Growthoption,
            "Installments": item?.frequency === 'DAILY' ? "" : item?.instalments,
            "InstallmentsAmt": item?.instalmentAmount.toString(),
            "MandateId": item?.mandate,
            "Sch": item?.Sch,
            "StartDate": new Date(item.SIPDay).toLocaleDateString('en-GB'),
            "TrType": "ISIP",
            "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
            "AccountID": item.selectedAccount,
            "rmID": rmId
          }
          data.push(obj);
        } else {
          const obj = {
            "AccNo": item.folio == "New Folio" ? "" : item.folio,
            "AmcId": globalVariables?.[0].AmcID,
            "Amount": item?.invAmount?.toString(),
            "BSE_SchemeCode": item?.BSECode,
            "DividendReinvestment": item?.Dividendreinvestment,
            "Growthoption": item?.Growthoption,
            "Sch": item?.Sch,
            "TrType": "New",
            "RMIFlag": radioValue === 'onbehalf' ? '1' : '2',
            "AccountID": item?.selectedAccount,
            "rmID": rmId
          }
          data.push(obj);

        }
      })
      if (shouldReturn == true) return;

      const confirmationResult = await Swal.fire({
        html: '<b>Are you sure you want to place this order?</b>',
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, place order",
        customClass: {
          container: 'my-swal',
          htmlContainer: 'custom-html-container-class',
        },
      });
      if (confirmationResult.isConfirmed) {
        await create_transaction(data[0])
      }
    }
    dispatch({ type: SET_SCHEME_FOR_BASKET, value: [] });
  }

  // useEffect(() => {

  //   selectedSchemes?.map(async (item: any, index: number) => {
  //     console.log("accountType",accountType,accountType?.account_type[0])
  //     try {
  //       const response = (await dispatch(
  //         get_folioList({
  //           "AccountID": accountType?.account_type[0]?.AccountId,
  //           "AMC": item?.AmcID
  //         })
  //       )) as unknown as any;
  //       console.log("response", response, response?.folio[0])
  //       setFolioList(response?.folio[0]);

  //     } catch (e) {
  //       console.log((e as Error).message);
  //     }
  //   })
  // }, [])


  async function postRecommendedSchemes(data: any) {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          post_recommended_schemes(data)
        )) as unknown as any;
        if (!isComponentActive) {
          return;
        }
        setIsLoading(false);
        isRecommended(true);
        setRecommended(true);
      } catch (e) {
        console.log((e as Error).message);
        setIsLoading(false);
      }
    })();

    return () => {
      isComponentActive = false;
    };
  }

  async function create_transaction(data: any) {
    let isComponentActive = true;
    (async function () {
      try {
        setIsLoading(true);
        const response = (await dispatch(
          RM_create_transaction(data)
        )) as unknown as any;
        if (!isComponentActive) {
          return;
        }
        setIsLoading(false);
        setRecommended(true);
        setPlaceOrderResponse(response)
      } catch (e) {
        console.log((e as Error).message);
        setIsLoading(false);
      }
    })();

    return () => {
      isComponentActive = false;
    };
  }

  const SIPDataValidation = () => {
    const updatedData = schemeParams ? [...schemeParams] : [];
    let valid = true;
    updatedData?.map((item) => {
      const currentSIPValidation = SIPInstallment?.find((i: any) => i.Productcode_RTA == item.Sch);
      if (switchValue === 'SIP') {
        if (item.selectedAccount == "") {
          item.errorAccountSIP = true;
          item.errorMessageAccount = "Account can not be empty";
          valid = false;
        }
        else {
          item.errorAccountSIP = false;
        }
        // if(item.folio == "")
        //   {
        //     item.errorFolioList=true;
        //     item.errorMessageFolioList = "Folio can not be empty";
        //     valid=false;
        //   }
        //   else{
        //     item.errorFolioList=false;
        //   }
        if (item.instalmentAmount === null || item.instalmentAmount === 0) {
          item.errorInstalmentAmount = true;
          item.errorMessageInstalmentAmount = "Instalment Amount can not be empty";
          valid = false;
        }
        else if (item.instalmentAmount && (item.instalmentAmount < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT) || item.instalmentAmount > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT))) {
          item.errorInstalmentAmount = true;
          item.errorMessageInstalmentAmount = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTAMOUNT}`;
          valid = false;
        }
        else {
          item.errorInstalmentAmount = false;
        }

        if (item.frequency != 'DAILY') {
          if (item.instalments == null || item.instalments <= 0) {
            item.errorInstalment = true;
            item.errorMessageInstalment = "No. of Instalments can not be empty or zero";
            valid = false;
          }
          else if (item.instalments && (item.instalments < Number(currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS) || item.instalments > Number(currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS))) {
            item.errorInstalment = true;
            item.errorMessageInstalment = `Please enter amount greater than ${currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS} and less than ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`;
            valid = false;
          }
          else {
            item.errorInstalment = false;
          }
        }

        if (item.SIPDay == "") {
          item.errorSIPDay = true;
          item.errorMessageSIPDay = "SIP Day can not be empty";
          valid = false;
        }
        else {
          item.errorSIPDay = false;
        }

        if (item.frequency == null) {
          item.errorFrequency = true;
          item.errorMessageFrequency = "Frequency can not be empty";
          valid = false;
        }
        else {
          item.errorFrequency = false;
        }
        if (radioValue !== 'recommend') {
          if (item.firstOrderToday === null || item.firstOrderToday === undefined || item.firstOrderToday === "") {
            item.errorFirstOrderToday = true;
            item.errorMessageFirstOrderToday = "Please select Yes or No";
            valid = false;
          }
          else {
            item.errorFirstOrderToday = false;
          }
          if (item.mandate === null || item.mandate === undefined || item.mandate === "") {
            item.errorMandate = true;
            item.errorMessageMandate = "Please select Mandate";
            valid = false;
          }
          else {
            item.errorMandate = false;
          }
        }
      }

    })
    setSchemeParams(updatedData);
    return valid;
  }
  //   const newSchemeParamsData: any[] = selectedSchemes?.map((ele: any) => {
  //     return { ...ele,selectedAccount:""}
  // })

  const isDateDisabled = (date: Date, Sch: string): boolean => {

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    if (date < currentDate) {
      return false;
    }

    const foundArray = validations.filter((value: any) => {
      return Object.keys(value).includes(Sch)
    })

    const foundValue: any = foundArray[0][Sch]?.find((val: any) => val.SIPFREQUENCY == schemeParams?.find((data) => data.Sch === Sch)?.frequency);
    const datesArray: any = foundValue?.SIPDATES.split(',');

    if (datesArray.includes(date.getDate().toString())) {
      return true;
    } else {
      return false;
    }
  };

  const invAmountValidation = () => {
    const updatedData = schemeParams ? [...schemeParams] : [];
    let valid = true;
    updatedData?.map((item) => {
      if (switchValue !== 'SIP') {
        if (item.selectedAccount == "") {
          item.errorAccountLumpsum = true;
          item.errorMessageAccount = "Account can not be empty";
          valid = false;
        }
        else {
          item.errorAccountLumpsum = false;
        }
        // if(item.folio == "")
        //   {
        //     item.errorFolioList=true;
        //     item.errorMessageFolioList = "Folio can not be empty";
        //     valid=false;
        //   }
        //   else{
        //     item.errorFolioList=false;
        //   }
        if (item.invAmount == null || item.invAmount == 0) {
          item.error = true;
          item.errorMessage = "Amount can not be empty";
          valid = false;
        }
        else if (item.invAmount && (item.invAmount < item.minPurchase || item.invAmount > item.maxPurchase)) {
          item.error = true;
          item.errorMessage = `Enter Amount between ${item.minPurchase} - ${item.maxPurchase}`;
          valid = false;
          // setError(true);
          // setErrorMessage("Enter Amount more the 1000");
        }
        else {
          item.error = false;
        }
      }
    })
    setSchemeParams(updatedData);
    return valid;
  }

  const changeInsSIPAmt = (schemeId: any, sch: any, FrequencyVal: any) => {
    const currentValidation = validations.find((item: any) => {
      const productCode = Object.keys(item)[0];
      const frequencyMatches = item[productCode].some((entry: any) => entry.SIPFREQUENCY === FrequencyVal);
      return productCode === sch && frequencyMatches;
    });
    const result = currentValidation[sch].filter((item: any) => item.SIPFREQUENCY === FrequencyVal);
    const obj = result[0];
    const updatedData = SIPInstallment?.map((item: any) => {
      if (item.Productcode_RTA === sch) {
        return {
          ...item,
          SIPFREQUENCY: obj.SIPFREQUENCY,
          SIPMINIMUMINSTALLMENTNUMBERS: obj.SIPMINIMUMINSTALLMENTNUMBERS,
          SIPMAXIMUMINSTALLMENTNUMBERS: obj.SIPMAXIMUMINSTALLMENTNUMBERS,
          SIPDATES: obj.SIPDATES,
          SIPMINIMUMINSTALLMENTAMOUNT: obj.SIPMINIMUMINSTALLMENTAMOUNT,
          SIPMAXIMUMINSTALLMENTAMOUNT: obj.SIPMAXIMUMINSTALLMENTAMOUNT
        };
      }

      return item;
    });
    setSIPInstallment(updatedData)
  }

  //function to call getClientMandate function when account type changes
  const handleChangeAccountForMandate = () => {
    if (schemeParams != undefined)
      schemeParams?.map((item) => {
        if (item.selectedAccount != undefined || item.selectedAccount != null)
          getClientMandates(item.selectedAccount);
      });
  }
  return (
    <Box>
      <Box sx={{ mb: 1, px: 3, display: 'flex', justifyContent: 'space-between', alignItems: "center" }}>
        <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
          {radioValue === 'recommend' ? 'Recommend Schemes' : 'New Transaction'}
        </Typography>
        <Box sx={{ width: "25%", display: 'flex', alignItems: 'flex-end', justifyContent: 'center', p: 3 }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 14,
            }}>
            SIP
          </Typography>
          <Switch
            checked={switchValue === 'Lumpsum'}
            onChange={(_, checked) => {
              setSelectedAccountType("")
              setSwitchValue(checked ? 'Lumpsum' : 'SIP')
            }
            }
            sx={{
              p: 0,
              mx: 2,
              height: 20,
              width: 43,
              '& .MuiSwitch-track': {
                background: '#4990F0',
                opacity: 1,
                borderRadius: '12px',
              },
              '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
              '& .MuiSwitch-switchBase': {
                p: 0,
                '&.Mui-checked': {
                  color: '#fff',
                  transform: 'translateX(32px)',
                  '& + .MuiSwitch-track': {
                    backgroundColor: '#4990F0',
                    opacity: 1,
                  },
                },
              },
            }}
          />
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 14
            }}>
            Lumpsum
          </Typography>
        </Box>
        <Box onClick={handleModalClose} sx={{ cursor: 'pointer' }}>
          <CloseIcon />
        </Box>
      </Box>
      <Divider />
      {globalVariables && globalVariables?.length === 1 ?

        <FormControl sx={{ px: 2, pt: 2 }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValue}
            onChange={handleRadioChange}
            sx={{
              '& .MuiSvgIcon-root': {
                fontSize: 18,
              },
              '& .MuiFormControlLabel-root': {
                color: '#5a7c82'
              },
              '& .Mui-checked .MuiRadio-root': {
                color: '#337FC9',
              },
              '& .MuiRadio-root:not(.Mui-checked)': {
                color: '#5a7c82'
              },
            }}
          >

            <FormControlLabel value="recommend" control={<Radio />} label="Recommend" />
            <FormControlLabel value="onbehalf" control={<Radio />} label="On behalf of client" />
            <FormControlLabel value="onrequest" control={<Radio />} label="Requested by client" />

          </RadioGroup>
        </FormControl>
        : ''}
      {schemeParams?.length === 0 ?
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <Typography sx={{ fontSize: 18, color: '#5a7c82', width: '50%', margin: '10% 0% 0% 25%' }}>Please select a scheme</Typography>
        </Box> :
        <Box sx={{
          height: '250px',
          overflowY: "scroll"
        }}>
          {schemeParams?.map((val: schemeParams, index: any) => {
            const plan = val.Growthoption === 1
              ? "Growth"
              : val.Dividendreinvestment === 1
                ? "Div"
                : val.DividendPayout === 1
                  ? "Div"
                  : "";
            const subPlan = val.Dividendreinvestment === 1
              ? "Reinvestment"
              : val.DividendPayout === 1
                ? "Payout"
                : "";
            const currentSIPValidation = SIPInstallment?.find((i: any) => i.Productcode_RTA == val.Sch);
            return (
              <Box sx={{ px: 2, mb: 0 }} key={index}>
                {/* 1 */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', cursor: 'pointer', my: 2.5 }}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', width: "32%" }}>
                    <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.5 }}>
                      {val.SchemeName}
                    </Typography>
                    <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
                      {!!val.AssetClassName && (
                        <Bubble text={val.AssetClassName} />
                      )}
                      {!!val.Sub_AssetclassName && (
                        <Bubble text={val.Sub_AssetclassName} />
                      )}
                      {plan && <Bubble text={plan} />}
                      {subPlan ? subPlan.length !== 0 && <Bubble text={subPlan} /> : ""}
                      {subPlan && !!val.Dividendfrequency && (
                        <Bubble text={val.Dividendfrequency} />
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ width: "6%", display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: 14, color: '#5a7c82' }}>Returns</Typography>
                    <Typography sx={{ fontSize: 16, color: "rgb(30, 163, 98)" }}>
                      {val.Aum === "0" || !val.Aum
                        ? "NA"
                        : `${Nanprocessor(roundToTwo(Number(val.Sch_Return5Yr)))}%`}
                    </Typography>
                  </Box>


                  {switchValue !== 'SIP' ?
                    <>
                      <Box sx={{ width: "17%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Account <b style={{ color: 'red' }}>*</b></Typography>
                        <FormControl fullWidth sx={{ mt: 0.5 }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={val.selectedAccount}
                            onChange={(e) => {
                              schemeParams[index].selectedAccount = e.target.value;
                              setSchemeParams([...schemeParams])
                              setSelectedAccountType(e.target.value as string)

                            }}
                            sx={{
                              border: "none",
                              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              // width: "10rem",
                              height: '2rem',
                              borderRadius: '5px',
                              padding: '2px 3px',
                              fontSize: 15,
                            }}
                          >
                            {
                              accountType?.account_type.map((i: any, index: any) => (
                                <MenuItem key={index} value={i.AccountId}> {i.AccountType}</MenuItem>))
                            }
                          </Select>
                        </FormControl>
                        {val.errorAccountLumpsum && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                      </Box>

                      {/* investment amount */}
                      <Box sx={{ width: "16%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end", marginRight: "1rem" }}>
                        <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Investment Amount <b style={{ color: 'red' }}>*</b></Typography>
                        <input style={{
                          width: "10rem",
                          border: "1px solid #C4C4C4", height: '2rem',
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                        }} type="text" className='investmentAmount' placeholder={`Min. - Rs. ₹${val.minPurchase}`} value={val.invAmount}
                          onChange={(e) => {
                            // if (!isNaN(Number(e.target.value))) {
                            const updatedData = [...schemeParams];
                            const objectToUpdate = updatedData.find(item =>
                              item.SchemeID === val.SchemeID
                            );

                            if (objectToUpdate) {
                              objectToUpdate.invAmount = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                              setSchemeParams(updatedData);
                            }
                            // }
                          }}>
                        </input>

                        {val.error && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessage} </Typography>}
                      </Box></>
                    : <>
                      {/* Account Type */}
                      <Box sx={{ width: "17%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end" }}>
                        <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Account <b style={{ color: 'red' }}>*</b></Typography>
                        <FormControl fullWidth sx={{ mt: 0.5 }}>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={val.selectedAccount}
                            onChange={(e) => {
                              schemeParams[index].selectedAccount = e.target.value;
                              setSchemeParams([...schemeParams])
                              handleChangeAccountForMandate();
                              setSelectedAccountType(e.target.value as string)
                            }}

                            sx={{
                              border: "none",
                              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              // width: "10rem",
                              height: '2rem',
                              borderRadius: '5px',
                              padding: '2px 3px',
                              fontSize: 15,

                            }}
                          >
                            {
                              accountType?.account_type?.map((i: any, index: any) => (
                                <MenuItem key={index} value={i.AccountId}> {i.AccountType}</MenuItem>))
                            }
                          </Select>
                        </FormControl>
                        {val.errorAccountSIP && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>}
                      </Box>

                      {/* // Frequency */}
                      <Box sx={{ width: "17%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-end", marginRight: "1rem" }}>
                        <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Frequency <b style={{ color: 'red' }}>*</b></Typography>
                        <FormControl fullWidth>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={val.frequency}
                            onChange={(e) => {
                              const updatedData = [...schemeParams];
                              const objectToUpdate = updatedData.find(item =>
                                item.SchemeID === val.SchemeID
                              );

                              if (objectToUpdate) {
                                objectToUpdate.frequency = e.target.value;
                                setSchemeParams(updatedData);
                              }
                              changeInsSIPAmt(val.SchemeID, val.Sch, e.target.value);
                            }}
                            sx={{
                              border: "none",
                              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                              // width: "10rem",
                              height: '2rem',
                              borderRadius: '5px',
                              padding: '2px 3px',
                              fontSize: 15,
                            }}
                          >
                            {validations.find((entry: any) => entry[val.Sch])?.[val.Sch]?.map((item: any, index: any) => (
                              <MenuItem key={index} value={item.SIPFREQUENCY}>{item.SIPFREQUENCY}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {val.errorFrequency && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageFrequency} </Typography>}
                      </Box></>
                  }
                  {/* <Box sx={{ width: "15%", display: 'flex', alignItems: 'flex-end', justifyContent: 'center', p: 3 }}>
                    <Typography
                      sx={{
                        color: '#22242C',
                        opacity: 0.8,
                        fontSize: 14,
                      }}>
                      SIP
                    </Typography>
                    <Switch
                      checked={val.switchValue === 'Lumpsum'}
                      onChange={(_, checked) => {
                        setSelectedAccountType("")
                        const updatedData = [...schemeParams];
                        const objectToUpdate = updatedData.find(item => item.SchemeID === val.SchemeID);
                        const mapToDoubleDigit = (number: any): string => {
                          return number < 9 ? `0${number + 1}` : `${number + 1}`;
                        };
                        const mapDayToDoubleDigit = (number: any): string => {
                          return number <= 9 ? `0${number}` : `${number}`;
                        };

                        setSchemeParams([...schemeParams])
                        if (objectToUpdate) {
                          const date = new Date();
                          objectToUpdate.switchValue = (checked ? 'Lumpsum' : 'SIP');
                          objectToUpdate.SIPDay = checked ? "" : `${date?.getFullYear()}/${mapToDoubleDigit(date?.getMonth())}/${mapDayToDoubleDigit(date?.getDate())}`
                          // objectToUpdate.datePicker = checked ? null : date;
                          updatedData[index].selectedAccount = "";
                          updatedData[index].invAmount = 0;
                          setSchemeParams(updatedData);
                        }
                      }
                      }
                      sx={{
                        p: 0,
                        mx: 2,
                        height: 20,
                        width: 43,
                        '& .MuiSwitch-track': {
                          background: '#4990F0',
                          opacity: 1,
                          borderRadius: '12px',
                        },
                        '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                        '& .MuiSwitch-switchBase': {
                          p: 0,
                          '&.Mui-checked': {
                            color: '#fff',
                            transform: 'translateX(32px)',
                            '& + .MuiSwitch-track': {
                              backgroundColor: '#4990F0',
                              opacity: 1,
                            },
                          },
                        },
                      }}
                    />
                    <Typography
                      sx={{
                        color: '#22242C',
                        opacity: 0.8,
                        fontSize: 14
                      }}>
                      Lumpsum
                    </Typography>
                  </Box> */}
                  <Box sx={{ width: "5%", display: 'flex', alignItems: "center", justifyContent: "flex-end", p: 2, }}>
                    <div onClick={() => { OnSchemeDeSelection_(val.SchemeID); }}><DeleteIcon /></div>
                  </Box>
                </Box>
                {/* 2 */}

                {
                  switchValue === 'SIP' ?
                    <>
                      <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', my: 2.5, }}>
                        {val.selectedAccount &&
                          <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", mr: 4 }}>
                            <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Folio </Typography>
                            <FormControl fullWidth sx={{ mt: 0.5 }}>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={val.folio}
                                onChange={(e) => {
                                  schemeParams[index].folio = e.target.value;
                                  setSchemeParams([...schemeParams])
                                }}

                                sx={{
                                  border: "none",
                                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  height: '2rem',
                                  borderRadius: '5px',
                                  padding: '2px 3px',
                                  fontSize: 15,
                                }}
                              >
                                {folioList.length == 0 ?
                                  <MenuItem value="New Folio">
                                    New Folio
                                  </MenuItem> :

                                  folioList?.map((i: any, index: any) => (
                                    <MenuItem key={index} value={i.Folio}> {i.Folio}</MenuItem>))
                                }
                              </Select>
                            </FormControl>
                            {/* {val.errorAccountLumpsum && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>} */}
                          </Box>
                        }
                        {schemeParams.find(item =>
                          item.SchemeID === val.SchemeID
                        )?.frequency != null ?
                          <>

                            {/* Instalment Amount */}
                            <Box sx={{ width: "26%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                              <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Instalment Amount <b style={{ color: 'red' }}>*</b></Typography>
                              <input style={{
                                width: "13.5rem", height: '2rem', border: "1px solid #C4C4C4",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                              }} type="text" className='investmentAmount' placeholder={`Min. - Rs. ₹${currentSIPValidation?.SIPMINIMUMINSTALLMENTAMOUNT}`} value={val.instalmentAmount}
                                onChange={(e) => {
                                  // if (!isNaN(Number(e.target.value))) {
                                  const updatedData = [...schemeParams];
                                  const objectToUpdate = updatedData.find(item =>
                                    item.SchemeID === val.SchemeID
                                  );

                                  if (objectToUpdate) {
                                    objectToUpdate.instalmentAmount = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                    setSchemeParams(updatedData);
                                  }
                                  // }
                                }}>
                              </input>
                              {val.errorInstalmentAmount && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageInstalmentAmount} </Typography>}
                            </Box>
                            {/* No. of instalments */}
                            {schemeParams.find(item =>
                              item.SchemeID === val.SchemeID
                            )?.frequency === 'DAILY' ? '' :
                              <Box sx={{ width: "26%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>No. of Instalments <b style={{ color: 'red' }}>*</b></Typography>
                                <input style={{
                                  width: "13.5rem", border: "1px solid #C4C4C4",
                                  boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px"
                                }} type="text" className='investmentAmount2' placeholder={`Enter Number ${currentSIPValidation?.SIPMINIMUMINSTALLMENTNUMBERS} - ${currentSIPValidation?.SIPMAXIMUMINSTALLMENTNUMBERS}`} value={val.instalments}
                                  onChange={(e) => {
                                    // if (!isNaN(Number(e.target.value))) {
                                    const updatedData = [...schemeParams];
                                    const objectToUpdate = updatedData.find(item =>
                                      item.SchemeID === val.SchemeID
                                    );

                                    if (objectToUpdate) {
                                      objectToUpdate.instalments = !isNaN(Number(e.target.value)) ? Number(e.target.value) : 0;
                                      setSchemeParams(updatedData);
                                    }
                                    // }
                                  }}>
                                </input>
                                {val.errorInstalment && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageInstalment} </Typography>}
                              </Box>
                            }
                            {/* SIP Day */}
                            <Box sx={{ width: "28%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                              <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>SIP Day <b style={{ color: 'red' }}>*</b></Typography>
                              <DatePicker
                                className="custom-datepicker"
                                // ref={datePickerRef}
                                selected={val.datePicker}
                                onChange={(date: Date) => {
                                  const updatedData = [...schemeParams];
                                  const objectToUpdate = updatedData.find(item =>
                                    item.SchemeID === val.SchemeID
                                  );
                                  const mapToDoubleDigit = (number: number): string => {
                                    return number < 9 ? `0${number + 1}` : `${number + 1}`;
                                  };
                                  const mapDayToDoubleDigit = (number: any): string => {
                                    return number <= 9 ? `0${number}` : `${number}`;
                                  };
                                  if (objectToUpdate) {
                                    objectToUpdate.SIPDay = `${date?.getFullYear()}/${mapToDoubleDigit(date?.getMonth())}/${mapDayToDoubleDigit(date?.getDate())}`
                                    objectToUpdate.datePicker = date
                                    setSchemeParams(updatedData);
                                  }
                                }}
                                dateFormat="dd-MM-yyyy"
                                filterDate={(date: Date) => isDateDisabled(date, val.Sch)}
                                placeholderText={'dd-mm-yyyy'}
                              // customInput={<CustomInput />}

                              />
                              {val.errorSIPDay && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageSIPDay} </Typography>}
                            </Box>
                          </> : <>
                          </>}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', my: 2.5 }}>

                        {schemeParams.find(item =>
                          item.SchemeID === val.SchemeID
                        )?.frequency != null ?
                          <>
                            {/* first order today and mandate */}
                            {radioValue != 'recommend' ?
                              <>
                                <Box>
                                  <Box sx={{ width: "33%", display: 'flex', flexDirection: 'column', alignItems: "flex-start" }}>
                                    <Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>Mandate <b style={{ color: 'red' }}>*</b></Typography>
                                    <FormControl fullWidth>
                                      <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={val.mandate}
                                        onChange={(e) => {
                                          schemeParams[index].mandate = e.target.value;
                                          setSchemeParams([...schemeParams])
                                          // setSelectedAccountType(e.target.value as string)

                                        }}

                                        sx={{
                                          border: "none",
                                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                          // width: "10rem",
                                          height: '2rem',
                                          borderRadius: '5px',
                                          padding: '2px 3px',
                                          fontSize: 15,
                                          width: '15rem'
                                        }}
                                      >
                                        {mandateId?.mandate && (
                                          mandateId?.mandate[0].map((i: any, index: any) => (
                                            <MenuItem key={index} value={i.Mandate_Id}> {i.Mandate_Id}</MenuItem>))
                                        )
                                        }
                                      </Select>
                                    </FormControl>
                                    {val.errorMandate && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageMandate} </Typography>}
                                  </Box>
                                </Box>
                                <Box sx={{ width: '33%', mx: 10 }}><Typography sx={{ fontSize: 14, color: '#5a7c82', mb: 0.5 }}>First Order Today <b style={{ color: 'red' }}>*</b></Typography>

                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    sx={{
                                      '& .MuiSvgIcon-root': {
                                        fontSize: 18,
                                      },
                                      '& .MuiFormControlLabel-root': {
                                        color: '#5a7c82'
                                      },
                                      '& .Mui-checked .MuiRadio-root': {
                                        color: '#337FC9',
                                      },
                                      '& .MuiRadio-root:not(.Mui-checked)': {
                                        color: '#5a7c82'
                                      },
                                    }}
                                    value={val.firstOrderToday}

                                    onChange={(e) => {
                                      // if (!isNaN(Number(e.target.value))) {
                                      const updatedData = [...schemeParams];
                                      const objectToUpdate = updatedData.find(item =>
                                        item.SchemeID === val.SchemeID
                                      );

                                      if (objectToUpdate) {
                                        objectToUpdate.firstOrderToday = e.target.value === 'yes' ? 'Y' : 'N';
                                        setSchemeParams(updatedData);
                                      }
                                      // }
                                    }}>

                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                  </RadioGroup>
                                  {val.errorFirstOrderToday && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5 }}> {val.errorMessageFirstOrderToday} </Typography>}
                                </Box>
                              </>
                              : ''}
                          </> : <></>}
                      </Box>
                    </> : <>
                      {
                        val.selectedAccount &&
                        <Box sx={{ width: "23%", display: 'flex', flexDirection: 'column', alignItems: "flex-start", mr: 4 }}>
                          <Typography sx={{ fontSize: 14, color: '#5a7c82' }}> Select Folio </Typography>
                          <FormControl fullWidth sx={{ mt: 0.5 }}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={val.folio}
                              onChange={(e) => {
                                schemeParams[index].folio = e.target.value;
                                setSchemeParams([...schemeParams])
                              }}
                              sx={{
                                border: "none",
                                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                height: '2rem',
                                borderRadius: '5px',
                                padding: '2px 3px',
                                fontSize: 15,
                              }}
                            >
                              {folioList.length == 0 ?
                                <MenuItem value="New Folio">
                                  New Folio
                                </MenuItem> :

                                folioList?.map((i: any, index: any) => (
                                  <MenuItem key={index} value={i.Folio}> {i.Folio}</MenuItem>))
                              }
                            </Select>
                          </FormControl>
                          {/* {val.errorAccountLumpsum && <Typography sx={{ fontSize: 10, color: 'red', mb: 0.5, ml: 1 }}> {val.errorMessageAccount} </Typography>} */}
                        </Box>
                      }
                    </>}
              </Box>
            )
          })
          }
        </Box>}

      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', mt: 4 }}>
        {schemeParams?.length !== 0 ? <Button
          sx={{
            height: '40px',
            color: 'white',
            fontSize: '16px',
            fontWeight: 400,
            cursor: 'pointer',
            borderRadius: '5px',
            bgcolor: '#337FC9',
            width: '50%',
            '&:hover': { backgroundColor: '#337FC9' },
          }}
          onClick={handleSubmit}
        // alert(JSON.stringify(schemeParams))
        >{isLoading ? <CircularProgress sx={{ color: 'white' }} /> : <> {radioValue === 'recommend' ? "Recommend" : radioValue === 'onbehalf' ? "Place Order On Behalf of Client" : "Place Order Requested by Client"} </>}</Button>
          : <></>}
      </Box>
      {Recommend ?
        <Modal
          open={Recommend}
          onClose={() => setRecommended(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{
            position: 'absolute' as any,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            borderRadius: '10px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>

            {radioValue === 'recommend' ?
              <> <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                <Typography sx={{ textAlign: "center" }}>Schemes Recommended</Typography>
              </>
              :
              <>

                {placeOrderResponse === undefined || placeOrderResponse === null || placeOrderResponse.error ?
                  < >
                    <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />
                    <Typography sx={{ textAlign: "center" }}>Order Failed</Typography>
                    <Typography sx={{ textAlign: "center" }}>Order ID not generated</Typography>
                  </>
                  :
                  <>
                    {placeOrderResponse.transaction_created[0].order_success ?
                      <CheckCircleIcon sx={{ color: 'green', width: '80px', height: '80px' }} />
                      : <CancelIcon sx={{ color: 'red', width: '80px', height: '80px' }} />}
                    <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_success ? 'Order Placed' : 'Order Failed'}</Typography>
                    <Typography sx={{ textAlign: "center" }}>{placeOrderResponse.transaction_created[0].order_status}</Typography>
                  </>
                }
              </>
            }
            <Button onClick={() => { setRecommended(false); handleModalClose() }} sx={{ height: '30px', color: 'white', backgroundColor: '#337FC9', mt: 2.5, fontSize: '15px', fontWeight: 400, '&:hover': { backgroundColor: '#337FC9' }, }}>OK</Button>
          </Box>
        </Modal> : <></>}
      {schemeParams?.length !== 0 ? <Typography sx={{ color: 'red', fontSize: '12px', m: 2.5 }}> * Mandatory fields</Typography> : ''}
    </Box >

  )
}

export function PerformaneAnalysisChart(
  { yearSelected, graphArray, categoryAverageArray }:
    {
      yearSelected: any;
      graphArray: any;
      categoryAverageArray: any;
    }
) {

  const [portfolioPerformanceGraphOption, setPortfolioPerformanceGraphOption] = useState<any>({
    chart: {
      type: "line",
      zoomType: "x",
      resetZoomButton: {
        theme: {
          display: "none",
        },
      },

      height: 200,
    },
    title: {
      text: "",
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      type: "datetime",
      tickInterval: 24 * 60 * 60 * 1000 * 30 * 6,

      dateTimeLabelFormats: {
        day: "%b '%y",
      },
    },

    visible: false,

    series: [
      {
        type: "area",
        data: [],
      },
    ],
  });

  useEffect(() => {
    getInitialValue(graphArray, categoryAverageArray, yearSelected);
  }, [graphArray, yearSelected, categoryAverageArray])

  function getInitialValue(arrResult: any, cvgArr: any, year: any) {
    if (arrResult && arrResult.length > 0) {
      let maxDate: Date | number = new Date();
      maxDate = maxDate.setFullYear(maxDate.getFullYear());
      let minDate: Date | number = new Date();
      let _lableCount = 3;
      let _valueFormmater = "yyyy";
      let _timeInterval: string | number = "";

      switch (year) {
        case "1Y":
          minDate = minDate.setFullYear(minDate.getFullYear() - 1);
          _lableCount = 6;
          _valueFormmater = "MMM yy";
          _timeInterval = 24 * 60 * 60 * 1000 * 20;
          break;
        case "3Y":
          minDate = minDate.setFullYear(minDate.getFullYear() - 3);
          _lableCount = 6;
          _valueFormmater = "MMM yy";
          _timeInterval = 24 * 60 * 60 * 1000 * 30 * 3;
          break;
        case "7Y":
          minDate = minDate.setFullYear(minDate.getFullYear() - 7);
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 30 * 6;
          break;
        case "5Y":
          minDate = minDate.setFullYear(minDate.getFullYear() - 5);
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 30 * 6;
          break;
        case "10Y":
          minDate = minDate.setFullYear(minDate.getFullYear() - 10);
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 30 * 6;
          break;
        case "1M":
          minDate = new Date(
            minDate.setMonth(minDate.getMonth() - 1)
          ).getTime();
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 5;
          break;
        case "3M":
          minDate = new Date(
            minDate.setMonth(minDate.getMonth() - 3)
          ).getTime();
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 20;
          break;

        case "6M":
          minDate = new Date(
            minDate.setMonth(minDate.getMonth() - 6)
          ).getTime();
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 20;
          break;
        default:
          minDate = minDate.setFullYear(minDate.getFullYear() - 1);
          _lableCount = 3;
          _valueFormmater = "yyyy";
          _timeInterval = 24 * 60 * 60 * 1000 * 30 * 6;
          break;
      }

      const result = arrResult.filter(
        (a: any) => new Date(a.x) > minDate && new Date(a.x) < maxDate
      );

      const cvg_result = cvgArr.filter(
        (a: any) => new Date(a.x) > minDate && new Date(a.x) < maxDate
      );
      // var myDate = !!result.length ? result[0].x : "";

      // const map1 = arrResult.map((ele) => new Date(ele.x));

      setPortfolioPerformanceGraphOption(
        {
          series: [
            {
              type: "area",
              name: "NAV",
              threshold: null,
              data: result.map(Object.values),
              color: "#2b4a76",
              lineColor: "#2b4a76",
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0.21, "#d7e4f4"],
                  [1, "#d7e4f4"],
                ],
              },
            },
            {
              type: "area",
              name: "CVG",
              threshold: null,
              data: cvg_result.map(Object.values),
              color: "#FFA500",
              lineColor: "#FFA500",
              fillColor: {
                linearGradient: {
                  x1: 0,
                  y1: 0,
                  x2: 0,
                  y2: 1,
                },
                stops: [
                  [0.21, "rgba(255, 165, 0,0.5)"],
                  [1, "rgba(255, 165, 0,0.3)"],
                ],
              },
            }
          ],
          credits: {
            enabled: false,
          },

          xAxis: {
            type: "datetime",
            tickInterval: _timeInterval,
            title: {
              text: null,
            },
            dateTimeLabelFormats: {
              day: "%b '%y",
            },
          },
          tooltip: {
            split: false,
            color: "red",

            formatter: function (this: Highcharts.Point): any {
              const formatter = new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              });

              return (
                // "NAV :" +
                formatter.format(this.y ? this.y : 0) +
                "<br>" +
                new Date(this.x).toLocaleDateString("en-UK", {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                }) +
                "</br>"
              );
            },
          },

          caption: {
            text: null,
          },
          title: {
            text: null,
          },
          chart: {
            type: "line",
            zoomType: "x",
            resetZoomButton: {
              theme: {
                display: "none",
              },
            },

            height: 200,
          },

          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
              compare: 'percent',
            },
            area: {
              lineWidth: 1,
            },
          },
          yAxis: {
            min: 0,
            gridLineColor: null,

            title: {
              text: null,
            },
            labels: {
              enabled: false,
            },
          },
        }
      )
    }
  }
  // const portfolioPerformaceGraphOptions = {
  //   chart: {
  //     type: 'area',
  //     // scrollablePlotArea: {
  //     //   minWidth: 600,
  //     //   scrollPositionX: 1,
  //     // },
  //   },
  //   title: {
  //     text: '',
  //   },
  //   credits: false,
  //   xAxis: {
  //     // type: 'datetime',
  //     categories: portfolioPerformance?.map(
  //       (each: { DT: string }) =>
  //         formatLongDate(each.DT).split(' ')[1].substring(0, 3) +
  //         ' ' +
  //         formatLongDate(each.DT).split(' ')[2]
  //     ),
  //     labels: {
  //       autoRotation: false,
  //       overflow: 'justify',
  //     },
  //   },
  //   yAxis: {
  //     title: {
  //       text: 'Return',
  //     },
  //     labels: {
  //       // formatter: function (): string {
  //       //   return '<span>' + (this as any).value + ' %</span>';
  //       // },
  //       format: '{text} %',
  //     },
  //     visible: false
  //   },
  //   tooltip: {
  //     valueSuffix: ' %',
  //     shared: true,
  //   },
  //   legend: {
  //     align: 'center',
  //     verticalAlign: 'top',
  //     enabled: false,
  //   },
  //   plotOptions: {
  //     line: {
  //       lineWidth: 2,
  //       states: {
  //         hover: {
  //           lineWidth: 3,
  //         },
  //       },
  //       marker: {
  //         enabled: false,
  //       },
  //     },
  //   },
  //   series: [
  //     {
  //       name: 'Portfolio',
  //       color: '#337FC9',
  //       opacity: '0.5',
  //       outerHeight: '200px',
  //       data: portfolioPerformance?.map((each: { Rtn: any; }) => each.Rtn),
  //       connectEnds: false,
  //       connectNulls: true,
  //       crisp: true,
  //     },
  //     // {
  //     //   name: 'Sensex',
  //     //   color: '#F4C10E',
  //     //   data: portfolioPerformance?.map((each) => 0),
  //     // },
  //     // {
  //     //   name: 'Nifty',
  //     //   color: '#730EF4',
  //     //   data: portfolioPerformance?.map((each) => 0),
  //     // },
  //   ],
  //   navigation: {
  //     menuItemStyle: {
  //       fontSize: '8px',
  //     },
  //   },
  // };
  return (
    <>
      <HighchartsReact
        highcharts={Highcharts}
        options={portfolioPerformanceGraphOption}
      />
    </>
  )
}

export function OrderDetailsModal(
  {
    schemeDetail,
    closeModal
  }:
    {
      schemeDetail: OrdersTypes,
      closeModal: () => void
    }
) {
  const frequencyMapping: any = {
    "1": "DAILY",
    "2": "Weekly",
    "3": "MONTHLY",
    "4": "QUARTERLY",
    "6": "ANNUALLY",
  }
  const plan =
    schemeDetail.Growthoption === 1
      ? "Growth"
      : Number(schemeDetail.Dividendreinvestment) === 1
        ? "Dividend"
        : Number(schemeDetail.DividendPayout) === 1
          ? "Dividend"
          : "";
  const subPlan =
    Number(schemeDetail.Dividendreinvestment) === 1
      ? "Reinvestment"
      : Number(schemeDetail.DividendPayout) === 1
        ? "Payout"
        : "";

  const orderDetails: Record<string, any> = {
    SIP: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "SIP Start Date",
        value: formatDate_(schemeDetail.StartDate)
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EntryDate)
      },
      {
        label: "SIP End Date",
        value: formatDate_(schemeDetail.EndDate)
      },
      {
        label: "Frequency",
        value: frequencyMapping[schemeDetail.Frequency]
      },
      {
        label: "SIP Day",
        value: formatDate_(schemeDetail.SIP_date)
      },
      {
        label: "Units",
        value: `${schemeDetail.Units != null ? schemeDetail.Units : 0} Units`
      },
      {
        label: "Amount",
        value: `${schemeDetail.InvestmentAmount != null ? formatToIndianCurrency(schemeDetail.InvestmentAmount) : 0}`
      }
    ],
    Lumpsum: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "NAV Date",
        value: formatDate_(schemeDetail.NAVDate)
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EntryDate)
      }
    ],
    SWP: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "SWP Start Date",
        value: formatDate_(schemeDetail.StartDate)
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EntryDate)
      },
      {
        label: "SWP End Date",
        value: formatDate_(schemeDetail.EndDate)
      },
      {
        label: "Frequency",
        value: frequencyMapping[schemeDetail.Frequency]
      },
      {
        label: "SWP Day",
        value: formatDate_(schemeDetail.SIP_date)
      },
      {
        label: "Units",
        value: `${schemeDetail.Units != null ? schemeDetail.Units : 0} Units`
      },
      {
        label: "Amount",
        value: `${schemeDetail.InvestmentAmount != null ? formatToIndianCurrency(schemeDetail.InvestmentAmount) : 0}`
      }
    ],
    STP: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "STP Start Date",
        value: formatDate_(schemeDetail.StartDate)
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EntryDate)
      },
      {
        label: "STP End Date",
        value: formatDate_(schemeDetail.EndDate)
      },
      {
        label: "Frequency",
        value: frequencyMapping[schemeDetail.Frequency]
      },
      {
        label: "STP Day",
        value: formatDate_(schemeDetail.SIP_date)
      },
      {
        label: "Units",
        value: `${schemeDetail.Units != null ? schemeDetail.Units : 0} Units`
      },
      {
        label: "Amount",
        value: `${schemeDetail.InvestmentAmount != null ? formatToIndianCurrency(schemeDetail.InvestmentAmount) : 0}`
      }
    ],
    Redeem: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "Redemption to",
        value: schemeDetail.Folio != null ? schemeDetail.Folio : "NA"
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EntryDate)
      },
      {
        label: "NAV Date",
        value: formatDate_(schemeDetail.NAVDate)
      }
    ],
    Switch: [
      {
        label: "BSE Order Number",
        value: schemeDetail.BseOrderNo != null && schemeDetail.BseOrderNo != "" && schemeDetail.BseOrderNo != '0' ? schemeDetail.BseOrderNo : "NA"
      },
      {
        label: "Target Scheme",
        value: schemeDetail.ToschemeName
      },
      {
        label: "Order Date",
        value: formatDate_(schemeDetail.EndDate)
      }
    ]
  }

  const isPurchaseOrAdd = schemeDetail.TRTYPE === "New" || schemeDetail.TRTYPE === "Add" || schemeDetail.TRTYPE === "XSIP" || schemeDetail.TRTYPE === "ISIP" ? true : false;

  return (
    <>
      <Box sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.paper',
        borderRadius: '16px',
        boxShadow: 24,
        border: 'none',
        p: 3,
        display: 'flex',
        flexDirection: 'column',
      }}>
        {/* Top Box */}
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: "1rem" }}>
          {/* Title */}
          <Box sx={{ marginRight: 10, display: 'flex', flexDirection: 'column' }}>
            <Typography sx={{ fontSize: 14, fontWeight: 500, mb: 0.5 }}>
              {schemeDetail.SchemeName}
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: "wrap" }}>
              {!!schemeDetail.AssetClassName && (
                <Bubble text={schemeDetail.AssetClassName} />
              )}
              {!!schemeDetail.Sub_AssetclassName && (
                <Bubble text={schemeDetail.Sub_AssetclassName} />
              )}
              {!!plan && <Bubble text={plan} />}
              {subPlan.length !== 0 && <Bubble text={subPlan} />}
              {!!subPlan && !!schemeDetail.Dividendfrequency && (
                <Bubble text={schemeDetail.Dividendfrequency} />
              )}
            </Box>
          </Box>
          {/* Close Button */}
          <Box onClick={closeModal} sx={{ cursor: 'pointer' }}>
            <CloseIcon />
          </Box>
        </Box>

        <Divider />

        {/* Middle Box */}
        <Box sx={{ display: 'flex', flexDirection: 'column', my: '1rem' }}>
          {/* Order type */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.1rem', textTransform: 'uppercase', width: `${isPurchaseOrAdd ? "16rem" : "6rem"}` }}>{trTypeMapping[schemeDetail.TRTYPE]}
              <span style={{ fontSize: "0.9rem", fontWeight: 600, color: "#293139", marginLeft: "8px" }}>{schemeDetail.TRTYPE === "New" || schemeDetail.TRTYPE === "Add" ? "- Lumpsum" : schemeDetail.TRTYPE === "XSIP" || schemeDetail.TRTYPE === "ISIP" ? "- SIP" : ""}</span>
            </Typography>
            <span style={{ width: "10px", height: "10px", borderRadius: '50%', backgroundColor: `${schemeDetail.bseOrderStatus == "Success" ? "green" : "#cb1919"}` }}> </span>
            {schemeDetail.RMIFlag === 1 || schemeDetail.RMIFlag === 2 ? <span style={{ fontSize: '0.9rem', color: '#677885', fontWeight: 500, marginLeft: '1rem' }}>RM initiated</span> : ''}
          </Box>

          {/* Order Details */}
          <Box sx={{ mt: "1rem", display: 'flex', flexWrap: 'wrap' }}>
            {/* Single order detail */}
            {orderDetails[trTypeToSchemeTypeMapping[schemeDetail.TRTYPE]].map((val: any) => {
              return <Box sx={{ display: 'flex', width: '40%', justifyContent: 'flex-start', m: "0.5rem" }} key={val.label}>
                <Typography sx={{ fontSize: '0.9rem', color: '#677885', fontWeight: 500 }}>{val.label}</Typography>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: 500 }}>{val.value}</Typography>
              </Box>
            })}
          </Box>
          {/* Order status */}
          <Box sx={{ display: 'flex', width: '20%', justifyContent: 'flex-start', my: "1rem", mx: '0.5rem' }}>
            <Typography sx={{ fontSize: '0.9rem', color: '#677885', fontWeight: 500 }}>Status</Typography>
            <Typography sx={{ fontSize: '1rem', fontWeight: 500, color: `${schemeDetail.bseOrderStatus == "Success" ? "green" : "#cb1919"}` }}>{schemeDetail.bseOrderStatus}</Typography>
          </Box>
        </Box>

        <Divider />

        {/* Footer Box */}
        {schemeDetail.bseOrderStatus !== "Success" ?
          <Box sx={{ my: '1rem' }}>
            <Typography sx={{ color: '#4B81B1', fontWeight: 500, fontSize: '0.8rem' }}>Note</Typography>
            <Typography sx={{ fontSize: '0.8rem', fontWeight: 500 }}>Any amount deducted will be transferred/refunded to the source account in 3-5 working days.</Typography>
          </Box> : <></>
        }

      </Box>
    </>
  )
}


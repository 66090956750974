import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import SearchIcon from '@mui/icons-material/Search';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  LinearProgress,
  Menu,
  MenuItem,
  OutlinedInput,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  getRMDashBoardMetrics,
  getRmRedirectionLink,
} from '../../redux-store/actions/userManagement';
import {
  InvestorDetailEntity,
  RmDashboardType,
  RmRedirectionLinkType,
} from '../../redux-store/types/api-types';
import { formatToIndianCurrency } from '../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../DataTable';
import { CommonLayout, AmountUnit } from '../commonComponents';
import DashboardLayout from './dashboard-layout';
import { TransactionIcon } from '../customSVGs';

const headers = (): ColumnType[] => {
  return [
    {
      header: 'Investor Name',
      sortingKey: 'I_InvName',
      valueGetter: (row: InvestorDetailEntity) => row.I_InvName || 'N/A',
    },
    {
      header: 'Investor Type',
      sortingKey: 'InvType',
      valueGetter: (row: InvestorDetailEntity) => row.InvType || 'N/A',
    },
    {
      header: 'RM Name',
      sortingKey: 'rmname',
      valueGetter: (row: InvestorDetailEntity) => row.rmname || 'N/A',
    },
    {
      header: 'Invested Value',
      sortingKey: 'PurVal',
      valueGetter: (row: InvestorDetailEntity, amountUnit?: number | null | undefined) =>
        row?.PurVal || row?.PurVal === 0 ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}` : 'N/A',
    },
    {
      header: 'Market Value',
      sortingKey: 'MktVal',
      valueGetter: (row: InvestorDetailEntity, amountUnit?: number | null | undefined) =>
        row?.MktVal || row?.MktVal === 0 ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}` : 'N/A',
    },
    {
      header: 'Overall Gain/Loss',
      sortingKey: 'UnRealGainnLoss',
      renderEditDeleteCell: (row: InvestorDetailEntity, index: number, groupIndex?: number, amountUnit?: number | null | undefined): JSX.Element => {
        return (
          <Box sx={{ minWidth: 80 }}>
            {row?.UnRealGainnLoss || row?.UnRealGainnLoss === 0 ? (
              <>
                <Typography
                  sx={{
                    color: row.UnRealGainnLoss < 0 ? '#F26868' : '#07BC01',
                    whiteSpace: 'nowrap',
                    fontSize: '0.875rem',
                  }}>
                  {row.UnRealGainnLoss < 0
                    ? `- ${formatToIndianCurrency(row.UnRealGainnLoss * -1, amountUnit)}`
                    : `+ ${formatToIndianCurrency(row.UnRealGainnLoss, amountUnit)}`}
                </Typography>
              </>
            ) : (
              'N/A'
            )}
          </Box>
        );
      },
    },
    {
      header: '\xa0',
      renderEditDeleteCell: (row: InvestorDetailEntity): JSX.Element => {
        const dispatch = useDispatch();
        const history = useHistory();
        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
        const open = Boolean(anchorEl);
        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
          setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };
        const handleRedirection = async () => {
          try {
            const updatedResponse = (await dispatch(
              getRmRedirectionLink({
                clientId: row.OClientID,
              })
            )) as unknown as RmRedirectionLinkType;
            window.open(updatedResponse?.link);
          } catch (e) {
            console.error((e as Error).message);
          }
          handleClose();
        };
        const handleClickViewDetails = () => {
          history.push('/investor', {
            investorId: row.OClientID,
            investorName: row.I_InvName,
          });
        };

        return (
          <>
            <Button
              sx={{ fontSize: 14, fontWeight: 500, p: 1, color: '#2057A6' }}
              variant="outlined"
              id="actions-btn"
              aria-controls={open ? 'actions-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}>
              Actions
            </Button>
            <Menu
              id="actions-menu"
              anchorEl={anchorEl}
              open={open}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'actions-btn',
              }}>
              <MenuItem onClick={handleClickViewDetails}>
                <>
                  <VisibilityOutlinedIcon sx={{ width: 20, mr: 2 }} />
                  View Details
                </>
              </MenuItem>
              <MenuItem
                //  onClick={handleRedirection}
                onClick={() => {
                  history.push('/investor-reports', {
                    investorId: row.OClientID,
                    investorName: row.I_InvName,
                  });
                }}>
                <>
                  <FileUploadOutlinedIcon sx={{ width: 20, mr: 2 }} />
                  Download
                </>
              </MenuItem>
              <MenuItem onClick={() => {
                history.push('/investor-transactions', {
                  investorId: row.OClientID,
                  investorName: row.I_InvName,
                });
              }}>
                <>
                  <Box sx={{ mr: 2 }}>
                    <TransactionIcon />
                  </Box>
                  Transaction
                </>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];
};

export default function InvestorDashboard(): JSX.Element {
  const [isLoading, setLoading] = useState(false);
  const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);
  const [search, setSearch] = useState<string>('');
  const valueConverter = useSelector((state: any) => state.valueConverter)
  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setDashBoardMetricsData(null);
    (async function () {
      try {
        setLoading(true);
        const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
        if (!isComponentActive) {
          return;
        }
        setDashBoardMetricsData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, []);

  const filteredInvestorDetails = dashBoardMetricsData?.investorDetail?.filter((item) =>
    Object.values(item).some((value) => String(value).toLowerCase().includes(search.toLowerCase()))
  );

  return (
    <CommonLayout>
      <DashboardLayout>
        <>
          {/* <Grid container spacing={4} mb={4}>
          <DoughnutGraph />
          <MonthOnMonthAndOnboardingStatus />
        </Grid> */}
          {/* <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'unset', lg: 'space-between' },
            flexWrap: 'wrap',
            gap: [3, 3, 3, 1],
            mb: 3,
          }}>
          <InfoCard
            label="Very Conservative"
            value="51"
            color="#5BB847"
            onClick={() => history.push('/investor-details')}
          />
          <InfoCard label="Conservative" value="52" color="#B0F600" />
          <InfoCard label="Moderate" value="56" color="#F4EA03" />
          <InfoCard label="Aggressive" value="24" color="#EE820C" />
          <InfoCard label="Very Aggressive" value="68" color="#FA4B03" />
        </Box> */}
          {/* <Box mb={3}>
          <CardMedia component="img" src="/images/investors-graph.png" alt="graph" />
        </Box> */}
          <Box
            sx={{
              bgcolor: 'common.white',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
              // width:"fit-content",
              
            }}>
            <Box
              sx={{
                display: { xs: 'block', sm: 'flex' },
                alignItems: 'center',
                justifyContent: 'space-between',
                pb: 1,
              }}>
              <Box
                sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 }, pt: 3, pl: 3 }}>
                <FormControl sx={{ width: 250, mr: 2 }} variant="outlined">
                  <OutlinedInput
                    value={search}
                    onChange={({ target: { value } }) => {
                      setSearch(value);
                    }}
                    sx={{ '& .MuiOutlinedInput-input': { py: 1, fontSize: 13 } }}
                    id="search-field"
                    placeholder="Search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    }
                  />
                </FormControl>
                {/* <IconButton aria-label="download" sx={{ p: 0 }}>
                <CardMedia
                  component="img"
                  src="/images/download.svg"
                  alt="download icon"
                  sx={{ width: 34 }}
                />
              </IconButton> */}
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: { xs: 2, sm: 0 }, pt: 3, pr: 3 }}>
                <AmountUnit />
              </Box>
            </Box>
            {isLoading ? (
              <LinearProgress sx={{ mt: 2.5 }} />
            ) : (
              <Box
                mt={2.5}
                sx={{ maxWidth: { lg: '100%' }, maxHeight: { md: '55vh', lg: '67vh' } }}>
                {dashBoardMetricsData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={[...headers()]}
                      tableData={filteredInvestorDetails}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0,},
                        '.MuiTableCell-body ': { color: '#686e72' },
                        background: 'transparent',
                      }}
                      boxStyles={{
                        minWidth: 500,
                      }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </>
      </DashboardLayout>
    </CommonLayout>
  );
}

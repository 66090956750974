import { Search } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MFTextField } from '../../../lib/formik';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import { BondsSchemeTransactionsType, BondsTransactionDetail } from '../../../redux-store/types/api-types';
import { formatLongDate } from '../../../utils/date';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { AmountUnit } from '../../commonComponents';
import { ColumnType, DataTable } from '../../DataTable';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

const headers: ColumnType[] = [
    {
        header: 'Scheme',
        sortingKey: 'SchemeName',
        align: 'left',
        valueGetter: (row: BondsTransactionDetail) => row?.SchemeName || 'N/A',
    },
    {
        header: 'Transaction Type',
        sortingKey: 'Transactiontype',
        valueGetter: (row: BondsTransactionDetail) => row?.Transactiontype || 'N/A',
    },
    {
        header: 'Category',
        sortingKey: 'Category',
        valueGetter: (row: BondsTransactionDetail) => row?.Category || 'N/A',
    },

    {
        header: 'Transaction Date',
        sortingKey: 'TransactionDate',
        valueGetter: (row: BondsTransactionDetail) => formatLongDate(row?.TransactionDate) || 'N/A',
    },
    {
        header: 'Transaction Amount',
        sortingKey: 'T_Amount',
        valueGetter: (row: BondsTransactionDetail, amountUnit?: number | undefined | null) =>
            row?.T_Amount || row.T_Amount === 0 ? formatToIndianCurrency(row.T_Amount, amountUnit) : 'N/A',
    },
];

function TransactionDetails({ investorId, selectedPortfolio }: { investorId: string; selectedPortfolio: number }): JSX.Element {
    const [isLoading, setLoading] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState<BondsSchemeTransactionsType | null>(
        null
    );
    const [search, setSearch] = useState<string>('');
    const dispatch = useDispatch();
    useEffect(() => {
        let isComponentActive = true;
        setTransactionDetails(null);
        (async function () {
            try {
                const responseRM = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                setLoading(true);
                const response = (await dispatch(
                    getInvestorData({
                        productLevel: 'Bonds',
                        rmID: responseRM.Header_ID,
                        HFlag: selectedPortfolio,
                        filters: {
                            customers: investorId ? [investorId] : [],
                        },
                        required: ['transactionDetails'],
                    })
                )) as unknown as BondsSchemeTransactionsType;
                if (!isComponentActive) {
                    return;
                }
                setTransactionDetails(response);
                setLoading(false);
            } catch (e) {
                setLoading(false);
                console.log((e as Error).message);
            }
        })();
        return () => {
            isComponentActive = false;
        };
    }, [selectedPortfolio]);
    return (
        <Box sx={{ maxHeight: '60vh' }}>
            <Box
                sx={{
                    mb: 2
                }}>
                <AmountUnit />
                {/* <SaveAltRoundedIcon
        onClick={() => window.print()}
        
        sx={{
          marginLeft: '20px',
          color: '#22242C',
          opacity:'0.7',
          fontSize:'1.7rem',
          cursor:'pointer',
          borderRadius:'13px',
          '&:hover': { backgroundColor:'#E7E6E6' },
        }} /> */}
            </Box>
            <Formik
                initialValues={{}}
                onSubmit={() => {
                    //
                }}>
                <Form>
                    <MFTextField
                        placeholder={'Search Strategy'}
                        name={'strategy'}
                        value={search}
                        onChange={(e) => {
                            setSearch(e.target.value);
                        }}
                        endAdornment={<Search sx={{ pr: 0.5, color: '#000', opacity: 0.4 }} />}
                        sx={{
                            width: { md: '12rem', lg: '20rem' },
                            '& .MuiInputBase-root': {
                                fontSize: { md: 12, lg: 14 },
                                border: '0 !important',
                                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                ':hover': {
                                    border: 0,
                                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15)',
                                },
                            },
                        }}
                    />
                </Form>
            </Formik>
            <DataTable
                isLoading={isLoading}
                tableHeader={headers}
                tableHeaderCustomStyles={{
                    '& .MuiTableCell-root': {
                        textAlign: 'left',
                        maxWidth: '300px',
                    },
                }}
                tableBodyCustomStyles={{
                    '.MuiTableCell-root': {
                        textAlign: 'left',
                        fontSize: '14px',
                        maxWidth: '300px',
                    },
                }}
                tableData={transactionDetails?.transactionDetails.filter((ele) =>
                    ele?.SchemeName?.toLowerCase().includes(search.toLowerCase())
                )}
                boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                customEmptyMessage="No transactions undertaken in the last 30 days"
            />
        </Box>
    );
}

export default TransactionDetails;

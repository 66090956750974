import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { riskProfileMasterData } from '../../redux-store/actions';
import {
  ApplicationProps,
  profileType,
  RiskProfileMaster,
  RiskProfileMasterType,
} from '../../redux-store/types/api-types';
import { riskProfileDeclaration, riskProfileDonotWish } from '../../utils/declaration';
import { changeGoalVal } from '../../utils/utilityFunctions';
import { profiletext, updatedRiskProfile } from '../investors/riskProfileDetails';
import { Item, ItemDetails, Gridstyles } from './index';
import { KeyValue } from './personalDetails';

export default function RiskProfiles({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { riskprofiles = [], wishToAddRiskProfile } = application || {};
  const [riskProfileData, setRiskProfileData] = useState<updatedRiskProfile[]>([]);
  const [profileData, setProfileData] = useState<profileType[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(riskProfileMasterData())) as unknown as RiskProfileMaster;

        setRiskProfileData(
          response.riskProfile
            ?.map((mdmsProfile) => {
              const existingRiskProfiles = riskprofiles?.filter(
                (risk_profiles) => risk_profiles.question === mdmsProfile.key
              );
              setProfileData(response.profile);

              return existingRiskProfiles?.map((existingProfiles) => {
                const checkAnswer = mdmsProfile.values
                  ?.map((value) => {
                    if (value.key === existingProfiles.answer) {
                      return value.label;
                    }
                    return;
                  })
                  ?.filter((ele) => ele);
                return {
                  ...existingProfiles,
                  ...mdmsProfile,
                  answer: checkAnswer?.toString() || existingProfiles.answer,
                };
              });
            })
            .flat() as unknown as updatedRiskProfile[]
        );
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Risk Profile
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    {riskProfileData.length ? (
                      <>
                        {riskProfileData?.map((profileData) => {
                          const profileAns = changeGoalVal(
                            profileData.answer,
                            riskProfileData,
                            profileData.question
                          );
                          return (
                            <React.Fragment key={profileData?.id}>
                              {profileData.isMultiselect === 'false' ? (
                                <KeyValue
                                  title={profileData.displayText}
                                  description={profileData.answer}
                                />
                              ) : (
                                <Grid item xs={12} sm={6}>
                                  <Item>{profileData.displayText}</Item>
                                  {profileData.key === 'realizingMarketBasedInvestments' ? (
                                    profileAns && profileAns.length > 0 ? (
                                      profileAns?.map((ans, ind) => (
                                        <Box
                                          key={ind}
                                          sx={{ display: 'flex', alignItems: 'baseline' }}>
                                          <Typography
                                            sx={{ fontSize: '30px', fontWeight: 900, pr: 1 }}>
                                            .
                                          </Typography>
                                          <ItemDetails> {ans}</ItemDetails>
                                        </Box>
                                      ))
                                    ) : (
                                      <ItemDetails>-</ItemDetails>
                                    )
                                  ) : (
                                    <ItemDetails> {profileAns?.toString() || '-'}</ItemDetails>
                                  )}
                                </Grid>
                              )}
                            </React.Fragment>
                          );
                        })}

                        {application?.totalRiskProfileScore && (
                          <>
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              <Typography
                                sx={{
                                  color: 'black',
                                  fontWeight: 550,
                                  fontSize: 14,
                                }}>{`Your total Score is ${application?.totalRiskProfileScore}`}</Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                              <Typography>
                                {profiletext(
                                  Number(application?.totalRiskProfileScore),
                                  profileData
                                )}
                              </Typography>
                            </Grid>
                          </>
                        )}
                        {application?.riskProfileDeclaration && (
                          <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                            {/* <Checkbox defaultChecked disabled={true} /> */}
                            <Typography
                              sx={{
                                fontSize: 15,
                                fontWeight: 700,
                                color: 'rgba(0, 0, 0, 0.6)',
                                letterSpacing: '0.5px',
                              }}>
                              {riskProfileDeclaration}
                            </Typography>
                          </Grid>
                        )}
                      </>
                    ) : wishToAddRiskProfile ? (
                      <Grid item xs={12} display="flex" alignItems={'flex-start'}>
                        {/* <Checkbox defaultChecked disabled={true} /> */}
                        <Typography
                          sx={{
                            fontSize: 15,
                            fontWeight: 700,
                            color: 'rgba(0, 0, 0, 0.6)',
                            letterSpacing: '0.5px',
                          }}>
                          {riskProfileDonotWish}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Risk Profile
                      </Grid>
                    )}
                    {/* <KeyValue title={'Source Of Income'} description={null} />
                    <KeyValue title={'Time Horizon for investment'} description={null} />
                    <KeyValue title={'Investment Objective'} description={null} />
                    <KeyValue title={'Risk Tolerance'} description={null} />
                    <KeyValue title={'Investment Experience'} description={null} /> */}
                  </Grid>
                </Gridstyles>
              </Box>
            </React.Fragment>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography } from '@mui/material';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useState } from 'react';
import UseRadioGroup from '../../lib/formik/Radio';
import { ApplicationProps } from '../../redux-store/types/api-types';
import { BseClientActivationStatusMaster, BSEConsentMaster } from '../../utils/constant';
import { Gridstyles } from './index';
import { KeyValue } from './personalDetails';
import * as yup from 'yup';
import { BseConsent } from '../../redux-store/actions/onBoarding';
import { useDispatch } from 'react-redux';
import MFCheckbox from '../../lib/formik/Checkbox';
import { getBseClientActivationStatus } from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { showError } from '../../redux-store/actions/auth';

export type Values = {
  clientCodeActivatedInBse: boolean | null;
};

const initialValues: Values = {
  clientCodeActivatedInBse: null,
};

export default function RMConcentForBSE({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const [submitLoader, setSubmitLoader] = useState(false);
  const dispatch = useDispatch();
  const { id } = application;
  const [getApplication, setGetApplication] = useState(application);
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = async (values: Values) => {
    try {
      if (!values.clientCodeActivatedInBse) {
        throw 'BSE consent status required';
      }
      setSubmitLoader(true);
      if (id) {
        const responseApplication = (await dispatch(
          BseConsent({ clientCodeActivatedInBse: values.clientCodeActivatedInBse as boolean }, id)
        )) as unknown as ApplicationProps;
        if (
          BseClientActivationStatusMaster[responseApplication.bseClientActivationStatus || ''] ===
          BseClientActivationStatusMaster.active
        ) {
          enqueueSnackbar('Submitted successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        setGetApplication(responseApplication);
        setSubmitLoader(false);
      }
    } catch (e) {
      setSubmitLoader(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  // const ConsentScheme = yup.object().shape({
  //   clientCodeActivatedInBse: yup.bool().nullable().required('Consent required'),
  // });

  return (
    <>
      {getBseClientActivationStatus(getApplication.bseClientActivationStatus || '') ? (
        <Formik
          initialValues={initialValues}
          // validate={(values: Values) => {
          //   try {
          //     validateYupSchema(values, ConsentScheme, true, values);
          //   } catch (e) {
          //     return yupToFormErrors(e);
          //   }
          // }}
          onSubmit={handleSubmit}
          enableReinitialize={true}>
          {({ handleSubmit, values, setValues }) => (
            <Box
              sx={{
                bgcolor: 'white',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
                borderRadius: '10px',
              }}
              mb={5}
              component="form"
              noValidate
              onSubmit={handleSubmit}>
              <Box sx={{ p: 2 }}>
                <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main' }}>
                      BSE Consent Status
                    </Typography>
                  </Grid>

                  <Gridstyles>
                    <Grid item container pt={3} pb={5}>
                      <MFCheckbox
                        name={`clientCodeActivatedInBse`}
                        label={`Client code activated in BSE star`}
                        sx={{ letterSpacing: '1px' }}
                      />
                    </Grid>
                    <Box sx={{ textAlign: 'center' }}>
                      <LoadingButton
                        loadingPosition="start"
                        type="submit"
                        loading={submitLoader}
                        variant="contained"
                        // sx={{
                        //   fontSize: 15,
                        //   minWidth: 180,
                        //   letterSpacing: 2,
                        //   fontWeight: 400,
                        // }}
                        sx={{
                          color: 'common.white',
                          minWidth: { xs: '130px', sm: '150px', md: '200px' },
                          my: 2,
                          fontWeight: 600,
                          lineHeight: 1.1,
                        }}>
                        Submit
                      </LoadingButton>
                    </Box>
                  </Gridstyles>
                </Grid>
              </Box>
            </Box>
          )}
        </Formik>
      ) : (
        <></>
      )}
    </>
  );
}

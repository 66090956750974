import { Checkbox, FormControl, FormControlLabel, FormGroup, Stack, Typography, Select, MenuItem } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReportSectionContainer, ReportsRightLayout } from '../../InvestorReport/index';
import { getDashboardReports, getInvestorReports } from '../../../redux-store/actions/investorReports';
import {
    downloadMultipleFiles,
    formatDate,
    replaceLetterOWithW,
} from '../../../utils/utilityFunctions';
import { TextDatePicker } from '../../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../../lib/formik';
import { validationSchemaPortfolioSummary } from '../../../utils/schema';
import { Location } from 'history';
import { useHistory } from 'react-router';
import { getRMDashBoardMetrics } from '../../../redux-store/actions/userManagement';
import { useEffect, useState } from 'react';
import { RmDashboardType } from '../../../redux-store/types/api-types';
import { useFormik } from 'formik';
import FormMultiSelect from '../../../lib/formik/FormMultiSelect';

export function TransactionHistoryReport() {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const [loading, setLoading] = useState<boolean>(false);
    const [dashBoardMetricsData, setDashBoardMetricsData] = useState<RmDashboardType | null>(null);

    useEffect(() => {
        let isComponentActive = true;
        setDashBoardMetricsData(null);
        (async function () {
            try {
                const response = (await dispatch(getRMDashBoardMetrics())) as RmDashboardType;
                if (!isComponentActive) {
                    return;
                }
                setDashBoardMetricsData(response);
                console.log("response>>>", response)
            } catch (e) {
                // setLoading(false);
                console.log((e as Error).message);
            }
        })();
        return () => {
            isComponentActive = false;
        };

    }, []);

    const onSubmit = async (ClientCode: any, setSubmitting: (isSubmitting: boolean) => void) => {

        console.log("first hi ClientCode : ", ClientCode)
        console.log("set loading ", loading)
        try {
            setSubmitting(true);

            // const Fchar = "W"
            // const mClientCode: string = Fchar + ClientCode.slice(1);
            // console.log("modifiedString data: ", mClientCode)
            setLoading(true);
            const res = await dispatch(
                getDashboardReports({
                    reportName: 'TransactionHistoryReport',
                    ClientCode: ClientCode,
                })
            );
            setLoading(false);
            console.log(`Generating report for investor with id: ${ClientCode}`);
            console.log("response ", res)
            downloadMultipleFiles(res);

            formik.setFieldValue('selectedInvestor', { ClientId: "", clientName: "" });
            enqueueSnackbar('File(s) downloaded successfully.', {
                variant: 'success',
                autoHideDuration: 3000,
            });
            // console.log("set loading after ..>> ", isLoading)
        }
        catch (e) {
            setLoading(false);
            console.error((e as Error).message);
        } finally {
            setSubmitting(false);
        }
    };
    const investorSelection = [
        "a", "b", "c"
    ]

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };
    const initialValues = {
        selectedInvestor: { ClientId: "", clientName: "" }
    };
    const handleSubmit = (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        onSubmit(formik.values.selectedInvestor.ClientId, setSubmitting);
    };

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
    });

    return (
        <ReportsRightLayout>
            <Typography
                sx={{
                    color: 'text.secondary',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '19px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    display: ['none', 'none', 'block'],
                }}>
                transaction history report
            </Typography>
            <Formik
                validationSchema={validationSchemaPortfolioSummary}
                initialValues={{
                    date: new Date(),
                    fileType: 'pdf',
                }}
                onSubmit={() => { onSubmit(formik.values.selectedInvestor.ClientId, () => { (true) }) }}>
                {({ values, setFieldValue, errors }) => (
                    <Form>
                        <ReportSectionContainer>
                            <Stack>
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0.01em',
                                    }}>
                                    Select Investor(s)
                                </Typography>
                                <Select
                                    name={'reportOptions'}
                                    label="Select Investor"
                                    value={formik.values.selectedInvestor.ClientId || ''}
                                    MenuProps={MenuProps}
                                    onChange={(e) => {
                                        const selectedInvestor = dashBoardMetricsData?.investorDetail?.find(
                                            (investor: any) => investor.OClientID === e.target.value
                                        );
                                        const data = {
                                            ClientId: selectedInvestor?.OClientID,
                                            clientName: selectedInvestor?.I_InvName
                                        };
                                        formik.setFieldValue('selectedInvestor', data);
                                    }}
                                >
                                    {console.log("dashBoardMetricsData", dashBoardMetricsData)}
                                    {dashBoardMetricsData?.investorDetail?.map((i: any, index: any) => (
                                        <MenuItem value={i.OClientID} key={index}>
                                            {i.I_InvName}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Stack>
                        </ReportSectionContainer>
                        <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
                            <MFSubmitButton
                                label="Download"
                                loadingEnable={loading}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </ReportsRightLayout>
    );
};



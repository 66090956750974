import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { servicePreferenceMasterData } from '../../redux-store/actions';
import { ApplicationProps, servicePreferencesMasterType } from '../../redux-store/types/api-types';
import { updatedServicePreference } from '../investors/servicePreferenceDetails';
import { Gridstyles } from './index';
import { KeyValue } from './personalDetails';

export default function ServicePreference({
  application,
}: {
  application: ApplicationProps;
}): JSX.Element {
  const { servicepreferences = [] } = application || {};
  const [servicePreferenceData, setServicePreferenceData] = useState<updatedServicePreference[]>(
    []
  );
  const dispatch = useDispatch();

  useEffect(() => {
    (async function () {
      try {
        const response = (await dispatch(
          servicePreferenceMasterData()
        )) as unknown as servicePreferencesMasterType;
        setServicePreferenceData(
          response.servicePreference
            ?.map((mdmsService) => {
              const existingServicePreference = servicepreferences?.filter(
                (preference) => preference.question === mdmsService.key
              );

              return existingServicePreference?.map((existingPreferences) => {
                const checkAnswer = mdmsService.values
                  ?.map((value) => {
                    if (value.key === existingPreferences.answer) {
                      return value.label;
                    }
                    return;
                  })
                  ?.filter((ele) => ele);
                return {
                  ...existingPreferences,
                  ...mdmsService,
                  answer: checkAnswer?.toString() || existingPreferences.answer,
                };
              });
            })
            .flat() as unknown as updatedServicePreference[]
        );
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.05)',
          borderRadius: '10px',
        }}
        mt={5}>
        <Box sx={{ p: 2 }}>
          <Grid container sx={{ mt: 1, pl: { xs: 2, sm: 10 }, pr: { xs: 2, sm: 10 } }}>
            <Typography sx={{ fontSize: 18, fontWeight: 600, color: 'primary.main', mb: 2 }}>
              Service Preference
            </Typography>
            <React.Fragment>
              <Box sx={{ width: '100%' }}>
                <Gridstyles>
                  <Grid
                    item
                    container
                    pb={5}
                    rowSpacing={6}
                    columnSpacing={13}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="flex-start">
                    {servicePreferenceData.length ? (
                      <>
                        {servicePreferenceData?.map((preferenceData) => {
                          return (
                            <React.Fragment key={preferenceData?.id}>
                              {preferenceData.isMultiselect === 'false' && (
                                <KeyValue
                                  title={preferenceData.sectionHeading}
                                  description={preferenceData.answer}
                                />
                              )}
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <Grid
                        xs={12}
                        sm={12}
                        sx={{
                          fontSize: 18,
                          fontWeight: 600,
                          color: 'common.black',
                          textAlign: 'center',
                          my: 3,
                        }}>
                        No Service Preference
                      </Grid>
                    )}
                  </Grid>
                </Gridstyles>
              </Box>
            </React.Fragment>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

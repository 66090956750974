/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button, Grid, InputAdornment, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import {
  Formik,
  validateYupSchema,
  yupToFormErrors,
  useFormikContext,
  FormikHelpers,
} from 'formik';
import { MFTextField } from '../../lib/formik';
import React, { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import MFCheckbox from '../../lib/formik/Checkbox';
import { Applicant, relatedPersonType } from '../../redux-store/types/api-types';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  applicationComparison,
  applyRoleBasedStatus,
  getApplicantName,
  isMinor,
  relatedPersonPanValidation,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useHistory } from 'react-router';
import { updateApplication } from '../../redux-store/actions/application';
// import { DatePicker } from '../../lib/formik/DatePicker';
import { ConfirmationDialog } from '../commonComponents';
import { relatedPersonSchema } from '../../utils/schema';
import MFSelectField from '../../lib/formik/SelectField';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  relatedRelations,
  salutationsMasters,
} from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { showError } from '../../redux-store/actions/auth';
import { RelatedPersonError } from '../../redux-store/middleware';
import { TextDatePicker } from '../onboarding/DatePickerWithTextFeild';

const newRelated: relatedPersonType = {
  namePrefix: 'Mr.',
  relationshipType: '',
  relationshipTypeOther: '',
  name: '',
  panNo: '',
  dateOfBirth: null,
  sno: 0,
  isActive: true,
};

const relatedPersonObj: Partial<Applicant> = {
  doNotWishToAddRelatedPerson: true,
  relatedpersons: [newRelated],
  saveType: 'save and proceed',
};

type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [relatedPersonObj],
  saveType: 'save and proceed',
};

const RealatedPersons = ({
  related,
  setRelationDelete,
  index,
  relatedIndex,
  setValues,
  values,
  relatedPersonCountForDisplay,
}: {
  related: relatedPersonType;
  setRelationDelete: any;
  index: number;
  relatedIndex: number;
  values: Values;
  setValues: FormikHelpers<Values>['setValues'];
  relatedPersonCountForDisplay: number;
}): JSX.Element => {
  const { setFieldValue } = useFormikContext();
  const { dateOfBirth = null } = related;
  const capitalizeFirstLowercaseRest = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography sx={{ color: '#2057a6', fontSize: '14px', fontWeight: 550, mr: 1 }}>
            {`Related Person ${relatedPersonCountForDisplay}`}
          </Typography>
          <DeleteOutlineIcon
            sx={{ cursor: 'pointer', pointerEvents: 'all', color: '#BD2932' }}
            onClick={() => setRelationDelete({ related, relatedIndex, index })}
          />
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.relatedpersons.${relatedIndex}.name`}
          label="Full Name *"
          placeholder="Enter Full Name"
          // inputProps={{ style: { textTransform: 'capitalize' } }}
          onChange={(e) => {
            setFieldValue(
              `applicants.${index}.relatedpersons.${relatedIndex}.name`,
              e.target.value
                .split(' ')
                .map((str) => capitalizeFirstLowercaseRest(str))
                .join(' ')
            );
          }}
          startAdornment={
            <InputAdornment
              position="start"
              sx={{
                pr: '0 !important',
                mx: '0 !important',
                borderRight: '0 !important',
              }}>
              <MFSelectField
                name={`applicants.${index}.relatedpersons.${relatedIndex}.namePrefix`}
                items={salutationsMasters.map((salutation) => ({
                  key: salutation,
                  value: salutation,
                }))}
                sx={{
                  color: 'primary.main',
                  '.MuiSvgIcon-root ': { color: 'primary.main' },
                  '&.MuiInputBase-root': {
                    '.MuiSelect-select': {
                      p: 0,
                      pr: 2.5,
                      border: 0,
                    },
                  },
                  '&,&:hover,&.Mui-focused': {
                    '.MuiOutlinedInput-notchedOutline': { border: 0 },
                  },
                }}
              />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.relatedpersons.${relatedIndex}.relationshipType`}
          label="Relationship Type *"
          items={relatedRelations
            .filter(
              (relation) =>
                !values.applicants[index]?.relatedpersons
                  ?.filter(
                    (_relation) =>
                      _relation.sno !== relatedIndex &&
                      _relation.isActive &&
                      ['MOTHER', 'FATHER', 'SPOUSE'].includes(_relation.relationshipType)
                  )
                  .map((_relation) => _relation.relationshipType)
                  .includes(relation)
            )
            .map((relation) => ({
              key: relation,
              value: relation,
            }))}
          onChange={(e) => {
            setValues({
              ...values,
              applicants: values.applicants.map((applicant, _index) => {
                if (index === _index) {
                  return {
                    ...applicant,
                    relatedpersons: values.applicants[index].relatedpersons?.map((person, ind) => {
                      if (relatedIndex === ind) {
                        return {
                          ...person,
                          relationshipType: e.target.value,
                          relationshipTypeOther: '',
                        };
                      }
                      return person;
                    }) as relatedPersonType[],
                  };
                }
                return applicant;
              }),
            });
          }}
        />
      </Grid>
      {related.relationshipType === 'OTHERS' && (
        <Grid item xs={12} sm={6}>
          <MFTextField
            name={`applicants.${index}.relatedpersons.${relatedIndex}.relationshipTypeOther`}
            label={`Please Specify Relationship *`}
            placeholder={`Enter Please Specify Relationship`}
            inputProps={{ style: { textTransform: 'uppercase' } }}
          />
        </Grid>
      )}

      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Date of Birth *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Date of Birth'}
          name={`applicants.${index}.relatedpersons.${relatedIndex}.dateOfBirth`}
          minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 125))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.relatedpersons.${relatedIndex}.panNo`}
          label={`PAN ${dateOfBirth ? (isMinor(dateOfBirth ? dateOfBirth : '') ? '' : '*') : ''}`}
          placeholder={`Enter PAN`}
          inputProps={{ style: { textTransform: 'uppercase' } }}
          onChange={(e) => {
            setFieldValue(
              `applicants.${index}.relatedpersons.${relatedIndex}.panNo`,
              e.target.value.toUpperCase()
            );
          }}
        />
      </Grid>
    </>
  );
};

export default function RelatedPerson(): JSX.Element {
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [relativeData, setRelativeData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [relationDelete, setRelationDelete] = useState<{
    related: Partial<relatedPersonType>;
    relatedIndex: number;
    index: number;
  } | null>(null);
  const [relatedPersonBulkDelete, setRelatedPersonBulkDelete] = useState<{
    applicantIndex: number;
  } | null>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { applicants, saveType } = values;
      const updatedPayload = {
        ...application,
        applicants: applicants.map((applicant) => {
          return {
            ...applicant,
            relatedpersons: applicant.relatedpersons?.filter(
              (person) => person.id || person.isActive
            ),
          };
        }),
      };
      relatedPersonPanValidation(updatedPayload.applicants);
      const checkApplication = applicationComparison(
        {
          ...application,
          applicants: application?.applicants?.sort(
            (applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)
          ),
        },
        {
          ...updatedPayload,
          applicants: updatedPayload.applicants
            .map((applicant) => {
              return {
                ...applicant,
                relatedpersons: applicant.relatedpersons?.map((relatedPerson) => {
                  const { sno, ...rest } = relatedPerson;
                  return { ...rest };
                }),
              };
            })
            ?.sort((applicant1, applicant2) => Number(applicant1.id) - Number(applicant2.id)),
          currentStep: !!currentStep && currentStep > 2 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 3,
              //!!currentStep && currentStep > 5 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('contact-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('contact-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      e instanceof RelatedPersonError && dispatch(showError((e as RelatedPersonError).message));
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    const { applicants } = application || {};
    setRelativeData({
      ...relativeData,
      applicants: applicants?.length
        ? applicants?.map((applicant) => ({
            ...applicant,
            doNotWishToAddRelatedPerson:
              applicant.doNotWishToAddRelatedPerson || applicant.relatedpersons?.length === 0
                ? true
                : false,
            relatedpersons:
              applicant.relatedpersons?.map((relatedPerson, index) => {
                return {
                  ...relatedPerson,
                  sno: index,
                  namePrefix: applicant.namePrefix || 'Mr.',
                };
              }) || [],
          }))
        : [relatedPersonObj],
    });
  }, [application]);

  return (
    <Formik
      initialValues={relativeData}
      onSubmit={onSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, relatedPersonSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues, errors }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((applicant, index) => (
            <React.Fragment key={index}>
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                Details of Related Person of {getApplicantName(index + 1)} Applicant
              </SubHeading>
              <Grid item xs={12}>
                <MFCheckbox
                  // disabled={!!applicant.relatedpersons?.filter((item) => item.isActive)?.length}
                  name={`applicants.${index}.doNotWishToAddRelatedPerson`}
                  label="I do not wish to Add"
                  sx={{ letterSpacing: '1px' }}
                  onChange={({ target: { checked } }) => {
                    setValues({
                      ...values,
                      applicants: values.applicants.map((_applicant, _index) => {
                        if (_index === index) {
                          return {
                            ..._applicant,
                            doNotWishToAddRelatedPerson:
                              _applicant.relatedpersons?.filter(
                                (applicantRelatedPerson) => applicantRelatedPerson.isActive
                              ).length === 0
                                ? checked
                                : !checked,
                            relatedpersons: !checked
                              ? [
                                  ...(values.applicants[_index]
                                    .relatedpersons as relatedPersonType[]),
                                  {
                                    ...newRelated,
                                    sno: values.applicants[_index].relatedpersons?.length,
                                  } as relatedPersonType,
                                ]
                              : (values.applicants[_index].relatedpersons as relatedPersonType[]),
                          };
                        }
                        return _applicant;
                      }),
                    });
                    checked && setRelatedPersonBulkDelete({ applicantIndex: index });
                  }}
                  checked={applicant.doNotWishToAddRelatedPerson}
                />
              </Grid>

              {applicant.relatedpersons
                ?.filter((item) => item.isActive)
                .map((related, _index) => {
                  return (
                    <RealatedPersons
                      index={index}
                      key={_index}
                      relatedIndex={related.sno as number}
                      setRelationDelete={setRelationDelete}
                      related={related}
                      setValues={setValues}
                      values={values}
                      relatedPersonCountForDisplay={_index + 1}
                    />
                  );
                })}
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  sx={{
                    color: 'common.white',
                    fontWeight: 500,
                    fontSize: 14,
                    mt: 2,
                    mx: 1,
                  }}
                  onClick={() => {
                    setValues({
                      ...values,
                      applicants: values.applicants.map((_applicant, _index) => {
                        if (_index === index) {
                          return {
                            ..._applicant,
                            doNotWishToAddRelatedPerson: false,
                            relatedpersons: [
                              ...(values.applicants[index].relatedpersons as relatedPersonType[]),
                              {
                                ...newRelated,
                                sno: values.applicants[_index].relatedpersons?.length,
                              } as relatedPersonType,
                            ],
                          };
                        }
                        return _applicant;
                      }),
                    });
                  }}>
                  Add Related Person
                </Button>
              </Grid>
            </React.Fragment>
          ))}

          <ConfirmationDialog
            message={'Are you sure you want to delete Related Person ?'}
            open={relationDelete !== null || relatedPersonBulkDelete !== null}
            setOpen={() => {
              setRelationDelete(null);
              setRelatedPersonBulkDelete(null);
            }}
            onSave={() => {
              const remaningRelation = values.applicants.map((applicant, idx) => {
                if (
                  idx === relationDelete?.index ||
                  idx === relatedPersonBulkDelete?.applicantIndex
                ) {
                  const updatedRelatedPersons = applicant.relatedpersons?.map((related, ind) => {
                    if (
                      related.sno === relationDelete?.relatedIndex ||
                      relatedPersonBulkDelete !== null
                    ) {
                      return {
                        ...related,
                        isActive: false,
                      };
                    }
                    return related;
                  });

                  return {
                    ...applicant,
                    relatedpersons: updatedRelatedPersons,
                    doNotWishToAddRelatedPerson:
                      updatedRelatedPersons?.filter((item) => item.isActive)?.length === 0
                        ? true
                        : false,
                  };
                }
                return applicant;
              });
              setValues({
                ...values,
                applicants: remaningRelation,
              });
              setRelationDelete(null);
              setRelatedPersonBulkDelete(null);
            }}
            onCancel={() => {
              setRelationDelete(null);
              setRelatedPersonBulkDelete(null);
            }}
          />

          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}

import {
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AssetAllocationCard } from '..';
import { getInvestorData, getRmDetails } from '../../../redux-store/actions/userManagement';
import { AifOverviewType, AifPerformers, AifFundPerformance, AifAssetAllocationByCat } from '../../../redux-store/types/api-types';
import { formatToIndianCurrency } from '../../../utils/utilityFunctions';
import { ColumnType, DataTable } from '../../DataTable';
import { CopyGraphDropdown, AmountUnit } from '../../commonComponents';
import GroupedBarChart, { colors } from '../components/grouped-bar-chart';
import { ProductTopSection } from '../product-details';
import { useLocation } from 'react-router';
import ReactCardFlip from 'react-card-flip';
import { FlipIcon } from '../../customSVGs';
import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded';

export default function AifOverview({ investorId, selectedPortfolio}: { investorId: string ; selectedPortfolio: number}): JSX.Element {
  const headers: ColumnType[] = [
    {
      header: 'Scheme',
      sortingKey: 'Scheme',
      valueGetter: (row: AifPerformers) => row?.Scheme || 'N/A',
    },
    {
      header: 'Fund Type',
      sortingKey: 'Fundtype',
      valueGetter: (row: AifPerformers) => row?.Fundtype || 'N/A',
    },
    {
      header: 'Fund House',
      sortingKey: 'FundHouse',
      valueGetter: (row: AifPerformers) => row?.FundHouse || 'N/A',
    },
    // investorId ?
    {
      header: 'XIRR (%)',
      sortingKey: 'Xirr',
      valueGetter: (row: AifPerformers) => (
        <span
          style={{
            fontSize: 'inherit',
            color: row.Xirr >= 0 || row.Xirr === null ? 'inherit' : '#F26868',
          }}>
          {row?.Xirr || row?.Xirr === 0 ? row?.Xirr : 'N/A'}
        </span>
      ),
    },
    // : {
    //     header: 'Return (%)',
    //     sortingKey: 'Return',
    //     valueGetter: (row: AifPerformers) => (
    //       <span
    //         style={{
    //           fontSize: 'inherit',
    //           color: row.Return > 0 ? 'inherit' : '#F26868',
    //         }}>
    //         {row?.Return || row?.Return === 0 ? row?.Return : 'N/A'}
    //       </span>
    //     ),
    //   },
    {
      header: 'Exposure (%)',
      sortingKey: 'Exposure',
      valueGetter: (row: AifPerformers) => (row?.Hper || row?.Hper === 0 ? row?.Hper : 'N/A'),
    },
  ];

  const location = useLocation();
  const [isLoading, setLoading] = useState(false);
  const [overviewData, setOverviewData] = useState<AifOverviewType | null>(null);
  const [selectedAssetsType, setSelectedAssetsType] = useState('category');
  const [fundHousePerformanceViewAll, setFundHousePerformanceViewAll] = useState<boolean>(false);
  const valueConverter = useSelector((state: any) => state.valueConverter);
  const [flipAssetAllocationAif, setFlipAssetAllocationAif] = useState(false);
  const [flipFundHousePerformanceAif, setFlipFundHousePerformanceAif] = useState(false);


  const dispatch = useDispatch();
  useEffect(() => {
    let isComponentActive = true;
    setOverviewData(null);
    (async function () {
      try {
        const responseRM = (await dispatch(
          getRmDetails()
        )) as unknown as any;
        setLoading(true);
        const response = await dispatch(
          getInvestorData({
            productLevel: 'AIF',
            rmID: responseRM.Header_ID,
            HFlag:selectedPortfolio,
            filters: {
              customers: investorId ? [investorId] : [],
            },
            required: ['overview'],
          }) as unknown as AifOverviewType
        );
        if (!isComponentActive) {
          return;
        }
        setOverviewData(response);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        console.log((e as Error).message);
      }
    })();
    return () => {
      isComponentActive = false;
    };
  }, [location.pathname, selectedPortfolio]);

  const assetAllocation =
    selectedAssetsType === 'category'
      ? overviewData?.assetAllocationByCat
      : overviewData?.assetAllocationByFundType;

  const totalInvAmount =
    assetAllocation &&
    assetAllocation.reduce(function (tot, arr) {
      return tot + arr.PurVal;
    }, 0);
  const totalMktAmount =
    assetAllocation &&
    assetAllocation.reduce(function (tot, arr) {
      return tot + arr.MktVal;
    }, 0);

  const assetAllocationRef = useRef<HTMLElement | null>(null);
  const fundHousePerformanceRef = useRef<HTMLElement | null>(null);

  const fundHousePerformance = fundHousePerformanceViewAll
    ? overviewData?.fundPerformance
    : overviewData?.fundPerformance.slice(0, 10);

  const fundHousePerformanceGraphData =
    fundHousePerformance?.sort((a, b) => b.i_Marketvalue - a.i_Marketvalue).map((each) => {
      return {
        name: each.Schemename,
        data: {
          'Current Market Value': each.i_Marketvalue,
          'Invested Value': each.i_purvalue,
          'Unrealised Gain/Loss': each.GainLoss,
        },
      };
    }) || [];

  const AssetAllocationbyCategoryAifTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Asset Class',
        sortingKey: 'Asset_Class',
        valueGetter: (row: AifAssetAllocationByCat) => row?.Asset_Class,
      },
      {
        header: 'Invested Value',
        sortingKey: 'PurVal',
        valueGetter: (row: AifAssetAllocationByCat, amountUnit?: number | null | undefined) => row?.PurVal || row?.PurVal === 0 ? `${formatToIndianCurrency(row?.PurVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'MktVal',
        valueGetter: (row: AifAssetAllocationByCat, amountUnit?: number | null | undefined) => row?.MktVal || row?.MktVal === 0 ? `${formatToIndianCurrency(row?.MktVal, amountUnit)}` : 'N/A',
      },
      {
        header: 'Invested Value %',
        sortingKey: 'PurValPercent',
        valueGetter: (row: AifAssetAllocationByCat) => row?.PurValPercent,
      },
      {
        header: 'Current Market Value %',
        sortingKey: 'MktValPercent',
        valueGetter: (row: AifAssetAllocationByCat) => row?.MktValPercent,
      },
    ];
  }

  const FundHousePerformanceAifTableHeaders = (): ColumnType[] => {
    return [
      {
        header: 'Scheme Name',
        sortingKey: 'Schemename',
        valueGetter: (row: AifFundPerformance) => row?.Schemename,
      },
      {
        header: 'Invested Value',
        sortingKey: 'i_purvalue',
        valueGetter: (row: AifFundPerformance, amountUnit?: number | null | undefined) => row?.i_purvalue || row?.i_purvalue === 0 ? `${formatToIndianCurrency(row?.i_purvalue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Current Market Value',
        sortingKey: 'i_Marketvalue',
        valueGetter: (row: AifFundPerformance, amountUnit?: number | null | undefined) => row?.i_Marketvalue || row?.i_Marketvalue === 0 ? `${formatToIndianCurrency(row?.i_Marketvalue, amountUnit)}` : 'N/A',
      },
      {
        header: 'Unrealised Gain / Loss',
        sortingKey: 'GainLoss',
        valueGetter: (row: AifFundPerformance, amountUnit?: number | null | undefined) => row?.GainLoss || row?.GainLoss === 0 ? `${formatToIndianCurrency(row?.GainLoss, amountUnit)}` : 'N/A',
      },
    ];
  }
  return (
    <Box>
      <Box
        sx={{
          mb: 2
        }}>
        <AmountUnit />
        <Button
          onClick={() => window.print()}

          sx={{
            marginLeft: '20px',
            height: '27px',
            color: 'white',
            fontSize: '13px',
            cursor: 'pointer',
            borderRadius: '5px',
            bgcolor: '#4990F0',
            '&:hover': { backgroundColor: '#639FF0' },
          }}>Download   PDF <SaveAltRoundedIcon sx={{ fontSize: '20px', marginLeft: '8px' }} /></Button>
      </Box>
      {overviewData?.assetAllocationByCat.length===0 ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10rem', fontSize: '18px', fontWeight: 500, color: '#666', background: 'white', boxShadow: '10rem', marginTop:'4rem' }}>No portfolio details available.</div> :
      <Box>
      {investorId && (
        <ProductTopSection
          marketValue={overviewData?.topValuesOverview?.currentValue || 0}
          investedValue={overviewData?.topValuesOverview?.investedValue || 0}
          gainLoss={overviewData?.topValuesOverview?.gainLoss}
          xirr={investorId ? overviewData?.topValuesOverview?.xirr : null}
          isLoading={isLoading}
        />
      )}
      <Box
        ref={assetAllocationRef}
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
          mb: 7,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 16,
              lineHeight: '19px',
              fontWeight: 600,
            }}>
            Asset Allocation by Market Capitalization
          </Typography>
          <Stack direction="row" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', mx: 1 }}>
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 15,
                  fontWeight: 500,
                  marginLeft: 2
                }}>
                By Category
              </Typography>
              <Switch
                checked={selectedAssetsType === 'fundtype'}
                onChange={(_, checked) => setSelectedAssetsType(checked ? 'fundtype' : 'category')}
                sx={{
                  p: 0,
                  mx: 2.5,
                  height: 20,
                  width: 52,
                  '& .MuiSwitch-track': {
                    background: '#4990F0',
                    opacity: 1,
                    borderRadius: '12px',
                  },
                  '& .MuiSwitch-thumb': { border: '1px solid #E1D8D8' },
                  '& .MuiSwitch-switchBase': {
                    p: 0,
                    '&.Mui-checked': {
                      color: '#fff',
                      transform: 'translateX(32px)',
                      '& + .MuiSwitch-track': {
                        backgroundColor: '#4990F0',
                        opacity: 1,
                      },
                    },
                  },
                }}
              />
              <Typography
                sx={{
                  color: '#22242C',
                  opacity: 0.8,
                  fontSize: 15,
                  fontWeight: 500,
                }}>
                By Fund Type
              </Typography>
            </Box>
            {/* <CopyGraphDropdown elementRef={assetAllocationRef} /> */}
          </Stack>
        </Box>
        <Divider />
        <Box
          sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
        >
          <FlipIcon
            onClick={() => (setFlipAssetAllocationAif(!flipAssetAllocationAif))}
            style={{
              height: '20px',
              cursor: "pointer"
            }}
          />
        </Box>
        <ReactCardFlip isFlipped={flipAssetAllocationAif}
          flipDirection="horizontal">
          <div
            style={{
              backgroundColor: 'white',
              display: `${flipAssetAllocationAif ? 'none' : 'block'}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                justifyContent: 'center',
                width: '100%',
              }}>
              <Box sx={{ display: 'flex', width: '100%' }}>
                <Box style={{ width: '50%', textAlign: 'right', paddingRight: '20px' }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#22242C',
                      opacity: 0.8,
                      mb: 1,
                      textAlign: 'right',
                    }}>
                    Invested Value
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: '#11263C',
                      mb: 3.5,
                      fontWeight: 700,
                      textAlign: 'right',
                    }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : totalInvAmount ? (
                      `${formatToIndianCurrency(totalInvAmount, valueConverter)}`
                    ) : (
                      'NA'
                    )}
                  </Typography>
                </Box>
                <Box style={{ width: 150, textAlign: 'center' }}></Box>
                <Box style={{ width: '50%', paddingLeft: '20px' }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                      color: '#22242C',
                      opacity: 0.8,
                      mb: 1,
                    }}>
                    Current Market Value
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 20,
                      color: '#11263C',
                      mb: 3.5,
                      fontWeight: 700,
                    }}>
                    {isLoading ? (
                      <Skeleton />
                    ) : totalMktAmount ? (
                      `${formatToIndianCurrency(totalMktAmount, valueConverter)}`
                    ) : (
                      'NA'
                    )}
                  </Typography>
                </Box>
              </Box>
              {assetAllocation
                ? assetAllocation.map((asset, index: number) => (
                  <AssetAllocationCard
                    key={index}
                    label={asset?.Asset_Class}
                    investedPercent={asset?.PurValPercent}
                    currentPercent={asset?.MktValPercent}
                    investedValue={asset?.PurVal}
                    currentValue={asset?.MktVal}
                    labelStyles={{ border: 0 }}
                  />
                ))
                : [...Array(3)].map((asset, index: number) => (
                  <AssetAllocationCard
                    key={index}
                    label={asset?.assetCategory}
                    investedPercent={asset?.investedValuePercentage}
                    currentPercent={asset?.currentValPercentage}
                    investedValue={asset?.investedValue}
                    currentValue={asset?.currentValue}
                    isLoading={true}
                  />
                ))}
            </Box>
          </div>
          <div style={{
            maxHeight: '300px',
            display: `${flipAssetAllocationAif ? 'block' : 'none'}`
          }}>
            <DataTable
              isLoading={isLoading}
              tableHeader={[...AssetAllocationbyCategoryAifTableHeaders()]}
              tableData={overviewData?.assetAllocationByCat && overviewData?.assetAllocationByCat}

              rowCustomStyles={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.MuiTableCell-body ': { color: '#686e72' },
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },

              }}
              tableHeaderCustomStyles={{
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },
              }}
              boxStyles={{ minHeight: '500px', minWidth: 450 }}
            />
          </div>
        </ReactCardFlip>
      </Box>
      <Box
        ref={fundHousePerformanceRef}
        sx={{
          background: ' #FFFFFF',
          boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
          borderRadius: '5px',
          mb: 7,
        }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}>
          <Typography
            sx={{
              color: '#22242C',
              opacity: 0.8,
              fontSize: 16,
              lineHeight: '19px',
              fontWeight: 600,
            }}>
            Fund House Performance
          </Typography>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}>
            {['Current Market Value', 'Invested Value', 'Unrealised Gain/Loss'].map((k, i) => (
              <div
                key={k}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginRight: 20,
                  fontWeight: 'normal',
                  fontSize: 12,
                }}>
                <div
                  style={{
                    borderRadius: '50%',
                    width: 12,
                    height: 12,
                    backgroundColor: colors[i],
                    marginRight: 5,
                  }}
                />
                <span>{k}</span>
              </div>
            ))}
          </div>
          <Box>
            <Button
              onClick={() => setFundHousePerformanceViewAll(!fundHousePerformanceViewAll)}
              sx={{
                fontSize: 14,
                lineHeight: '16px',
                color: '#4E5056',
                fontWeight: 500,
                padding: '4px 22px',
                boxShadow: '1px 1px 3px rgba(0, 0, 0, 0.15)',
                border: '1px solid #0BAAE7',
                borderRadius: '5px',
              }}>
              {fundHousePerformanceViewAll ? 'View less' : 'View all'}
            </Button>

            {/* <CopyGraphDropdown elementRef={fundHousePerformanceRef} /> */}
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{ padding: '10px 35px', display: 'flex', justifyContent: 'flex-end' }}
        >
          <FlipIcon
            onClick={() => (setFlipFundHousePerformanceAif(!flipFundHousePerformanceAif))}
            style={{
              height: '20px',
              cursor: "pointer"
            }}
          />
        </Box>
        <ReactCardFlip isFlipped={flipFundHousePerformanceAif}
          flipDirection="horizontal">
          <div
            style={{
              backgroundColor: 'white',
              display: `${flipFundHousePerformanceAif ? 'none' : 'block'}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                justifyContent: 'center',
                width: '100%',
              }}>
              {fundHousePerformanceGraphData && (
                <GroupedBarChart
                  data={fundHousePerformanceGraphData}
                  dimensions={{ WIDTH: 400 }}
                  margins={{ TOP: 10, BOTTOM: 30, LEFT: 150, RIGHT: 10 }}
                  barDimensions={{ height: 8, spacing: 4, groupSpacing: 10 }}
                />
              )}
            </Box>
          </div>
          <div style={{
            maxHeight: '400px',
            display: `${flipFundHousePerformanceAif ? 'block' : 'none'}`
          }}>
            <DataTable
              isLoading={isLoading}
              tableHeader={[...FundHousePerformanceAifTableHeaders()]}
              tableData={overviewData?.fundPerformance && overviewData?.fundPerformance}

              rowCustomStyles={{
                '&:last-child td, &:last-child th': { border: 0 },
                '.MuiTableCell-body ': { color: '#686e72' },
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },

              }}
              tableHeaderCustomStyles={{
                '& .MuiTableCell-root': {
                  textAlign: 'left',
                },
              }}
              boxStyles={{ minHeight: '500px', minWidth: 450 }}
            />
          </div>
        </ReactCardFlip>
      </Box>
      <Grid container gap={7}>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '100%',
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
                background: '#FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
              }}>
              <Typography
                sx={{
                  color: '#4E5056',
                  fontSize: 16,
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                Top 5 Performers
              </Typography>
            </Box>
            <Divider />
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                {overviewData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={headers}
                      tableData={overviewData?.top5}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                        background: 'transparent',
                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              height: '100%',
              background: '#FFFFFF',
              boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
              borderRadius: '5px',
            }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
                py: 2,
                background: '#FFFFFF',
                boxShadow: '0px 4px 28px 2px rgba(0, 0, 0, 0.08)',
                borderRadius: '5px',
              }}>
              <Typography
                sx={{
                  color: '#4E5056',
                  fontSize: 16,
                  lineHeight: '24px',
                  fontWeight: 600,
                }}>
                Bottom 5 Performers
              </Typography>
            </Box>
            <Divider />
            {isLoading ? (
              <LinearProgress />
            ) : (
              <Box>
                {overviewData && (
                  <>
                    <DataTable
                      isLoading={isLoading}
                      tableHeader={headers}
                      tableData={overviewData?.bottom5}
                      rowCustomStyles={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        '.MuiTableCell-body ': { color: '#686e72' },
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                        background: 'transparent',
                      }}
                      tableHeaderCustomStyles={{
                        '& .MuiTableCell-root': {
                          textAlign: 'left',
                        },
                      }}
                      boxStyles={{ minHeight: 'unset', minWidth: 'unset' }}
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
      </Box>}
    </Box>
  );
}

import { Stack, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { ReportSectionContainer, ReportsRightLayout } from '../../InvestorReport/index';
import { getDashboardReports, getInvestorReports } from '../../../redux-store/actions/investorReports';
import {
    downloadMultipleFiles,
    formatDate,
    replaceLetterOWithW,
} from '../../../utils/utilityFunctions';
import { getRmDetails } from '../../../redux-store/actions/userManagement';
import { TextDatePicker } from '../../onboarding/DatePickerWithTextFeild';
import { MFSubmitButton } from '../../../lib/formik';
import { validationSchemaPortfolioSummary } from '../../../utils/schema';
import { useHistory } from 'react-router';
import { useEffect, useState } from 'react';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";

export function HoldingReport() {

    const [rmId, setRmId] = useState<number>();
    const currentDate = dayjs();
    const [loading, setLoading] = useState<boolean>(false);
    const [dates, setDates] = useState<string>(dayjs(currentDate).format("YYYY-MM-DD"));
    const dispatch = useDispatch();

    useEffect(() => {

        let isComponentActive = true;

        (async function () {
            try {
                const response = (await dispatch(
                    getRmDetails()
                )) as unknown as any;
                if (!isComponentActive) {
                    return;
                }
                setRmId(response.Header_ID);
                setRmId(response.Header_ID);
            } catch (e) {
                console.log((e as Error).message);
            }
        })();

        return () => {
            isComponentActive = false;
        };
    }, [])
    // console.log("holding data ...", rmId)


    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const onSubmit = async (date: any) => {

        try {
            const formattedDate = `${new Date(date).getDate().toString().padStart(2, '0')}/${(new Date(date)?.getMonth() + 1).toString().padStart(2, '0')}/${new Date(date).getFullYear()}`;
            setLoading(true)
            const res = await dispatch(
                getDashboardReports({
                    reportName: 'HoldingReport',
                    RmId: rmId,
                    ToDate: formattedDate,
                })
            );
            setLoading(false)
            console.log("holding report", res)
            downloadMultipleFiles(res);
            enqueueSnackbar('File(s) downloaded successfully.', {
                variant: 'success',
                autoHideDuration: 3000,
            });
            setDates(dayjs(currentDate).format("YYYY-MM-DD"));
        } catch (e) {
            console.error((e as Error).message);
        }
    };


    return (
        <ReportsRightLayout>
            <Typography
                sx={{
                    color: 'text.secondary',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '19px',
                    letterSpacing: '0.06em',
                    textTransform: 'uppercase',
                    display: ['none', 'none', 'block'],
                }}>
                holding report
            </Typography>
            <Formik
                validationSchema={validationSchemaPortfolioSummary}
                initialValues={{
                    // reportOptions: [],
                    date: new Date(),

                }}
                onSubmit={() => { onSubmit(dates) }}>

                {({ values, setFieldValue }: {
                    values: {
                        // reportOptions: number[];
                        date: any;
                    };

                    setFieldValue: (

                        field: string,
                        value: any,
                        shouldValidate?: boolean | undefined
                    ) => void;

                }) => (
                    <Form>

                        <ReportSectionContainer>
                            <Stack>
                                {/* <TextDatePicker
                                    label={'Till Date'}
                                    maxDate={new Date()}
                                    inputLabelStyles={{
                                        fontSize: '14px',
                                        fontWeight: 400,
                                        lineHeight: '22px',
                                        letterSpacing: '0.01em',
                                    }}
                                    applyLabelStyles={true}
                                    placeholder={''}
                                    name={`date`}
                                /> */}
                                <LocalizationProvider dateAdapter={AdapterDayjs}>

                                    <DatePicker
                                        label="Select Date"
                                        format="DD-MM-YYYY"
                                        value={dayjs(dates)}
                                        maxDate={dayjs(currentDate)}
                                        onChange={(date: any) =>
                                            setDates(date.format("YYYY-MM-DD"))
                                        }
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </ReportSectionContainer>
                        <Stack justifyContent="center" alignItems="center" sx={{ m: 2 }}>
                            <MFSubmitButton
                                label="Download"
                                loadingEnable={loading}
                            />
                        </Stack>
                    </Form>
                )}
            </Formik>
        </ReportsRightLayout>
    );
};


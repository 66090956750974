import { Box, Grid } from '@mui/material';
import { Formik, FormikHelpers } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ProceedSaveLater, SubHeading } from './components';
import { showError } from '../../redux-store/actions/auth';
import { UseRadioGroupTransparent } from '../../lib/formik/Radio';
import { useHistory } from 'react-router';
import {
  servicePreferences,
  servicePreferencesMaster,
  servicePreferencesMasterType,
} from '../../redux-store/types/api-types';
import { RootStateType } from '../../redux-store/reducers';
import {
  applicationComparison,
  applyRoleBasedStatus,
  saveForLater,
} from '../../utils/utilityFunctions';
import { updateApplication } from '../../redux-store/actions/application';
import { AMC_APPROVER_CHECK_FOR_INDIVIDUAL } from '../../utils/constant';
import { useSnackbar } from 'notistack';
import { servicePreferenceMasterData } from '../../redux-store/actions';

export type updatedServicePreference = servicePreferences &
  servicePreferencesMaster & {
    otherValue?: string;
  };

export type Values = {
  servicepreferences: updatedServicePreference[];
  saveType: string;
};

export const servicePreferenceObj: updatedServicePreference = {
  question: '',
  answer: '',
  otherValue: '',
  key: '',
  displayText: '',
  sectionHeading: '',
};

const initialValues: Values = {
  servicepreferences: [],
  saveType: 'save and proceed',
};

function ServicePreferenceQuestion({ data }: { data: updatedServicePreference[] }): JSX.Element {
  return (
    <Box sx={{ width: '100%' }}>
      {data?.map((dataValues: any, ind) => {
        return (
          <>
            {dataValues.sectionHeading && (
              <SubHeading
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                {`${dataValues.sectionHeading}`}
              </SubHeading>
            )}
            <UseRadioGroupTransparent
              name={`servicepreferences.${ind}.answer`}
              items={dataValues.values.map((service: any) => ({
                label: service.label,
                value: service.key,
              }))}
              sx={{
                '& .MuiFormGroup-root': { flexDirection: 'column' },
                '& .MuiFormControlLabel-label': { fontWeight: 400 },
              }}
            />
          </>
        );
      })}
    </Box>
  );
}

export const handleIntialSelect = (
  servicepreferences: servicePreferences[],
  serviceQuestion: string
) => {
  let selectedValue: string[] = [];
  const longTermArr = servicepreferences?.find((a) => a.question === serviceQuestion);
  selectedValue = longTermArr?.answer.split('*') as unknown as string[];
  return selectedValue;
};

export default function ServicePreference(): JSX.Element {
  const [servicePreferenceData, setServicePreferenceData] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const { enqueueSnackbar } = useSnackbar();
  const [servicePreferenceDataMaster, setServicePreferenceDataMaster] = useState<
    servicePreferencesMaster[]
  >([]);

  const handleSubmit = async (values: Values) => {
    try {
      const {
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const { servicepreferences, saveType } = values;
      const checkAllQuestionsAnswered = servicepreferences
        ?.map(
          (service) =>
            service.required === 'false' || (service.answer !== '' && service.required === 'true')
        )
        ?.every((_service) => _service);
      if (!checkAllQuestionsAnswered) {
        throw 'Please fill all the required(*) fields';
      }
      servicepreferences?.map((preference) => {
        if (
          (preference.answer === 'others' ||
            (preference.isMultiselect === 'true' && preference.answer.includes('others'))) &&
          !preference.otherValue &&
          preference.required === 'true'
        ) {
          const questionForDisplay = servicePreferenceDataMaster
            ?.map((servicePreference) => {
              if (servicePreference.key === preference.question) {
                return (
                  servicePreference.displayText ||
                  (servicePreference.sectionHeading && (servicePreference.sectionHeading || ''))
                );
              }
              return;
            })
            ?.filter((ele) => ele)
            ?.toString();
          throw 'Please specify others in ' + questionForDisplay;
        }
      });

      const updatedPayload = {
        ...application,
        servicepreferences: servicepreferences
          ?.map((_servicepreference) => {
            const {
              otherValue,
              required,
              values,
              displayText,
              key,
              isMultiselect,
              sectionHeading,
              ...rest
            } = _servicepreference;
            return {
              ...rest,
              answer:
                otherValue && rest.answer === 'others'
                  ? otherValue
                  : isMultiselect === 'true' && otherValue && rest.answer?.includes('others')
                  ? rest.answer?.replace('others', otherValue)
                  : rest.answer,
            };
          })
          .filter((service) => service.answer),
      };
      const checkApplication = applicationComparison(
        {
          ...application,
          servicepreferences: application?.servicepreferences?.sort(
            (service1, service2) => Number(service1.id) - Number(service2.id)
          ),
        },
        {
          ...updatedPayload,
          servicepreferences: updatedPayload?.servicepreferences?.sort(
            (service1, service2) => Number(service1.id) - Number(service2.id)
          ),
          currentStep: !!currentStep && currentStep > 10 ? currentStep : Number(currentStep) + 1,
        }
      );
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...updatedPayload,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 11,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('document-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      typeof e === 'string' && dispatch(showError(e));
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = (await dispatch(
          servicePreferenceMasterData()
        )) as unknown as servicePreferencesMasterType;
        if (!isComponentAlive) {
          return;
        }
        setServicePreferenceDataMaster(response.servicePreference);
        const { servicepreferences = [] } = application || {};
        setServicePreferenceData({
          ...servicePreferenceData,
          servicepreferences: response.servicePreference
            ?.map((mdmsService) => {
              const existingServicePreference = servicepreferences?.filter(
                (service) => service.question === mdmsService.key
              );
              return existingServicePreference.length
                ? existingServicePreference?.map((existingService) => {
                    if (
                      existingService.question === mdmsService.key &&
                      mdmsService.isMultiselect === 'true'
                    ) {
                      const getServices = handleIntialSelect(servicepreferences, mdmsService.key);
                      const checkAnswerArray = mdmsService.values?.map((_value) => _value.key);
                      let getOtherValue = '';
                      const updateOptions = getServices.map((ans) => {
                        if (!checkAnswerArray?.includes(ans.split('_')[0])) {
                          getOtherValue = ans.split('_')[0];
                          return `others_${ans.split('_')[1]}`;
                        }
                        return ans;
                      });
                      return {
                        ...existingService,
                        ...mdmsService,
                        otherValue: getOtherValue,
                        answer: getOtherValue
                          ? updateOptions?.toString()?.replace(/,/g, '*')
                          : existingService.answer,
                      };
                    } else {
                      const checkAnswer =
                        existingService.question === mdmsService.key &&
                        mdmsService.isMultiselect === 'false' &&
                        mdmsService.values
                          ?.map((value) => value.key)
                          .includes(existingService.answer);

                      return {
                        ...existingService,
                        ...mdmsService,
                        otherValue: checkAnswer ? '' : existingService.answer,
                        answer: checkAnswer ? existingService.answer : 'others',
                      };
                    }
                  })
                : [
                    {
                      ...servicePreferenceObj,
                      question: mdmsService.key,
                      answer: '',
                      ...mdmsService,
                    },
                  ];
            })
            .flat(),
        });
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  return (
    <Formik
      initialValues={servicePreferenceData}
      // validationSchema={riskProfileScheme}
      onSubmit={handleSubmit}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          //columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          <ServicePreferenceQuestion data={values.servicepreferences} />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}

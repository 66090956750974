import { AnyAction } from 'redux';
import { FILTERS_TYPE, SET_FILTERS } from '../types/products';

export type ProductsType = {
  filters: FILTERS_TYPE;
};

const initState: Partial<{
  filters: FILTERS_TYPE;
}> = {
  filters: [],
};
function productsReducer(state = initState, action: AnyAction): Partial<{ filters: FILTERS_TYPE }> {
  switch (action.type) {
    case SET_FILTERS: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    default:
      return state;
  }
}

export default productsReducer;

import { Accordion, AccordionDetails, AccordionSummary, Badge, Box, Button, Checkbox, FormControlLabel, FormGroup, Menu, MenuItem, TextField, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { filter, size } from "lodash";
import { AccountType } from "../../../redux-store/types/api-types";
import VerifiedIcon from '@mui/icons-material/Verified';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export interface filterDataExplore {
    AMCList: { AMC: string, AMCId: number }[];
    schemeType: any[];
}

export interface filterDataRecommended {
    AMCList: { AMC: string, AMCId: number }[];
    schemeType: any[];
    status: { label: string, value: string }[];
    accountName: AccountType[];
    date: { FromDate: string, ToDate: string };
}

export interface filterDataHolding {
    AMCList: { AMC: string, AMCId: number }[];
    schemeType: any[];
    accountName: AccountType[];
}

export interface filterDataOrders {
    orderStatus: any[];
    orderType: string[];
    date: { FromDate: string, ToDate: string };
}

const recommendationStatusMap = [
    {
        label: 'Active',
        value: 'Active'
    },
    {
        label: 'Expired',
        value: 'Expired'
    },
    {
        label: 'Added to cart',
        value: 'In Cart'
    },
    {
        label: 'Order Successful',
        value: 'Order confirmed_Success'
    },
    {
        label: 'Order Failed',
        value: 'Order confirmed_Failed'
    },
    {
        label: 'Payment Successful',
        value: 'Order confirmed_Payment Successfully Completed'
    }
]

const orderTypeMap = [
    {
        label: 'New Purchase',
        subcategories: [
            { label: 'Lumpsum', value: 'New' },
            { label: 'SIP', value: 'ISIP' },
        ]
    },
    {
        label: 'Add Purchase',
        subcategories: [
            { label: 'Lumpsum', value: 'Add' },
            { label: 'SIP', value: 'XSIP' },
        ]
    },
    {
        label: 'SWP',
        subcategories: [],
        value: 'SWP'
    },
    {
        label: 'STP',
        subcategories: [],
        value: 'STP'
    },
    {
        label: 'Switch',
        subcategories: [],
        value: 'Swt'
    },
    {
        label: 'Redeem',
        subcategories: [],
        value: 'Red'
    }
]

export default function Filters(
    {
        AMCList,
        filterData,
        setFilterData,
        accountName
    }:
        {
            AMCList: { AMC: string, AMCId: number }[],
            filterData: filterDataExplore | filterDataRecommended | filterDataOrders,
            setFilterData: any,
            accountName?: AccountType[]
        }
) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [filterInitialData, setFilterInitialData] = useState(filterData);
    const [filterCount, setFilterCount] = useState(0);

    const open = Boolean(anchorEl);

    //function to get the count of filters applied - so that user will be notified about the filters applied by him
    useEffect(() => {
        let count = 0;
        Object.keys(filterData).map((val) => {
            if (val === 'date') {
                if ((filterData as unknown as any)[val].FromDate !== '' && (filterData as unknown as any)[val].ToDate !== '')
                    count = count + 1;
            }
            else {
                count = count + (filterData as unknown as any)[val].length;
            }
        })
        setFilterCount(count);
    }, [filterData])

    const handleClickFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    //Checkbox change function for AMC Name filter
    const handleCheckboxChangeAMCNameFilter = (e: any, AMCId: number, AMC: string) => {
        const { checked } = e.target;
        if ('AMCList' in filterData) {
            if (checked) {
                // Checkbox is checked, add the AMC to the selected list
                const updatedSelectedAMCList = [...filterData.AMCList, { AMCId, AMC }];
                setFilterData({ ...filterData, AMCList: updatedSelectedAMCList });
            } else {
                // Checkbox is unchecked, remove the AMC from the selected list
                const updatedSelectedAMCList = filterData.AMCList.filter((item) => item.AMCId !== AMCId);
                setFilterData({ ...filterData, AMCList: updatedSelectedAMCList });
            }
        }
    }

    //Checkbox changes function for scheme type filter
    const handleCheckboxChangeSchemeTypeFilter = (e: any, schemeType: string) => {
        const { checked } = e.target;
        if ('schemeType' in filterData) {
            if (checked) {
                const updatedSelectedSchemeTypeList = [...filterData.schemeType, schemeType];
                setFilterData({ ...filterData, schemeType: updatedSelectedSchemeTypeList });
            } else {
                const updatedSelectedSchemeTypeList = filterData.schemeType.filter((item) => item !== schemeType);
                setFilterData({ ...filterData, schemeType: updatedSelectedSchemeTypeList });
            }
        }
    }

    //Checkbox changes function for status filter
    const handleCheckboxChangeStatusFilter = (e: any, statusvalue: string, statusLabel: string) => {
        const { checked } = e.target;

        if ('status' in filterData) {
            if (checked) {
                const updatedSelectedStatusList = [...filterData.status, { label: statusLabel, value: statusvalue }];
                setFilterData({ ...filterData, status: updatedSelectedStatusList });
            } else {
                const updatedSelectedStatusList = filterData.status.filter((item) => item.value !== statusvalue);
                setFilterData({ ...filterData, status: updatedSelectedStatusList });
            }
        }
    }

    //Checkbox changes function for status filter
    const handleCheckboxChangeAccountNameFilter = (e: any, account: AccountType) => {
        const { checked } = e.target;

        if ('accountName' in filterData) {
            if (checked) {
                const updatedSelectedAccountNameList = [...filterData.accountName, account];
                setFilterData({ ...filterData, accountName: updatedSelectedAccountNameList });
            } else {
                const updatedSelectedAccountNameList = filterData.accountName.filter((item: AccountType) => item.AccountId !== account.AccountId);
                setFilterData({ ...filterData, accountName: updatedSelectedAccountNameList });
            }
        }
    }

    //Checkbox changes function for order status filter
    const handleCheckboxChangeOrderStatusFilter = (e: any, status: string) => {
        const { checked } = e.target;

        if ('orderStatus' in filterData) {
            if (checked) {
                const updatedSelectedOrderStatusList = [...filterData.orderStatus, status];
                setFilterData({ ...filterData, orderStatus: updatedSelectedOrderStatusList });
            } else {
                const updatedSelectedOrderStatusList = filterData.orderStatus.filter((item) => item !== status);
                setFilterData({ ...filterData, orderStatus: updatedSelectedOrderStatusList });
            }
        }
    }

    //Checkbox changes function for order type filter
    const handleCheckboxChangeOrderTypeFilter = (e: any, category: any) => {
        const { checked } = e.target;

        if ('orderType' in filterData) {
            if (category.subcategories && category.subcategories.length > 0) {
                // It's a category with subcategories
                const updatedSelectedOrderTypeList = checked
                    ? [...filterData.orderType, ...category.subcategories.map((sub: any) => sub.value)]
                    : filterData.orderType.filter(item => !category.subcategories.some((sub: any) => sub.value === item));

                setFilterData({ ...filterData, orderType: updatedSelectedOrderTypeList });
            } else {
                // It's a regular category without subcategories
                const updatedSelectedOrderTypeList = checked
                    ? [...filterData.orderType, category.value]
                    : filterData.orderType.filter(item => item !== category.value);

                setFilterData({ ...filterData, orderType: updatedSelectedOrderTypeList });
            }
        }
    };


    return (
        <>
            <Badge
                badgeContent={filterCount}
                color="error"
                overlap="circular"
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', paddingRight: '20px' }}
            >
                <Button
                    sx={{
                        marginLeft: '20px',
                        height: '27px',
                        color: '#337FC9',
                        fontSize: '13px',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        bgcolor: 'white',
                        border: '1.5px solid #337FC9',
                    }}
                    // aria-controls={open ? 'basic-menu' : undefined}
                    // aria-haspopup="true"
                    // aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickFilter}
                >
                    Filter
                </Button>
            </Badge>

            {/* Filter Menu */}
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleCloseFilter}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                sx={{
                    mt: 0.5,
                    minHeight: "5rem",
                    maxHeight: "24.5rem",
                    maxWidth: "20rem"
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {/* AMC Name */}
                {'AMCList' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>AMC Name
                                    {filterData.AMCList.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.AMCList.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {AMCList.map((value: any) => {
                                        const isChecked = filterData.AMCList.some((selectedAMC) => selectedAMC.AMCId === value.AMCId);
                                        return <FormControlLabel
                                            sx={{
                                                fontSize: '0.7rem',
                                                '& .MuiSvgIcon-root': {
                                                    width: '1rem',
                                                    height: '1rem',
                                                },
                                            }}
                                            key={value.AMCId}
                                            control={<Checkbox
                                                checked={isChecked}
                                                sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                onChange={(e) => handleCheckboxChangeAMCNameFilter(e, value.AMCId, value.AMC)} />} label={value.AMC.split(" ")[0]} />
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}

                {/* Scheme Type */}
                {'schemeType' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Scheme Type
                                    {filterData.schemeType.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.schemeType.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {['Dividend', 'Growth'].map((value: any) => {
                                        const isChecked = filterData.schemeType.some((schemetype) => schemetype === value);
                                        return <FormControlLabel sx={{
                                            fontSize: '0.7rem',
                                            '& .MuiSvgIcon-root': {
                                                width: '1rem',
                                                height: '1rem',
                                            },
                                        }}
                                            key={value}
                                            control={<Checkbox
                                                checked={isChecked}
                                                sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                onChange={(e) => handleCheckboxChangeSchemeTypeFilter(e, value)} />} label={value} />
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}

                {/* Account Name */}
                {'accountName' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Account Name
                                    {filterData.accountName.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.accountName.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails sx={{ width: '10rem' }}>
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {accountName?.map((val: AccountType) => {
                                        const isChecked = filterData.accountName.some((account: AccountType) => account.AccountId === val.AccountId);
                                        return <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', maxWidth: '5rem' }} key={val.AccountId}>
                                            <FormControlLabel
                                                sx={{
                                                    fontSize: '0.7rem',
                                                    mr: '4px',
                                                    '& .MuiSvgIcon-root': {
                                                        width: '1rem',
                                                        height: '1rem'
                                                    },
                                                    '& .MuiTypography-root': {
                                                        fontSize: '0.75rem',
                                                        width: '7rem',
                                                        whiteSpace: 'normal'
                                                    }
                                                }}
                                                control={<Checkbox
                                                    checked={isChecked}
                                                    sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                    onChange={(e) => handleCheckboxChangeAccountNameFilter(e, val)} />}
                                                label={val.AccountType}
                                            />
                                            {val.BseRegStatus === 'Y' ?
                                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                    <VerifiedIcon sx={{ color: '#337FC9', width: 14, mr: 0.6 }} />
                                                    <Typography sx={{ color: '#337FC9', fontSize: 11 }}>BSE Active</Typography>
                                                </Box>
                                                : <></>}
                                        </Box>

                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}

                {/* Status */}
                {'status' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Status
                                    {filterData.status.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.status.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {recommendationStatusMap.map((val: any) => {
                                        const isChecked = filterData.status.some((stat) => stat.value === val.value);
                                        return <FormControlLabel
                                            sx={{
                                                fontSize: '0.7rem',
                                                '& .MuiSvgIcon-root': {
                                                    width: '1rem',
                                                    height: '1rem',
                                                },
                                            }}
                                            key={val.value}
                                            control={<Checkbox
                                                checked={isChecked}
                                                sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                onChange={(e) => handleCheckboxChangeStatusFilter(e, val.value, val.label)} />} label={val.label} />
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}

                {/* Order Status */}
                {'orderStatus' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Order Status
                                    {filterData.orderStatus.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.orderStatus.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails >
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {["Success", "Unsuccessful"].map((val: any) => {
                                        const isChecked = filterData.orderStatus.some((stat) => stat === val);
                                        return <FormControlLabel
                                            sx={{
                                                fontSize: '0.7rem',
                                                '& .MuiSvgIcon-root': {
                                                    width: '1rem',
                                                    height: '1rem',
                                                },
                                            }}
                                            key={val.value}
                                            control={<Checkbox
                                                checked={isChecked}
                                                sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                onChange={(e) => handleCheckboxChangeOrderStatusFilter(e, val)} />} label={val} />
                                    })}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}

                {/* Order Type */}
                {'orderType' in filterData && (
                    <MenuItem>
                        <Accordion sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Order Type
                                    {filterData.orderType.length != 0 && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        {filterData.orderType.length}
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup style={{ width: '100%', display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                    {orderTypeMap.map((category: any) => {
                                        const isCategoryChecked = category.subcategories && category.subcategories.length > 0
                                            ? category.subcategories.every((subCategory: any) =>
                                                filterData.orderType.includes(subCategory.value)
                                            )
                                            : filterData.orderType.includes(category.value);

                                        return <div key={category.label} style={{ marginRight: '16px' }}>
                                            <FormControlLabel
                                                sx={{
                                                    fontSize: '0.7rem',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '& .MuiSvgIcon-root': {
                                                        width: '1rem',
                                                        height: '1rem',
                                                    },
                                                }}
                                                control={
                                                    <Checkbox
                                                        checked={isCategoryChecked}
                                                        sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                        onChange={(e) => handleCheckboxChangeOrderTypeFilter(e, category)}
                                                    />
                                                }
                                                label={category.label}
                                            />
                                            {category.subcategories && category.subcategories.length > 0 && (
                                                <FormGroup style={{ marginLeft: '10px' }}>
                                                    {category.subcategories.map((subCategory: any) => {
                                                        const isSubChecked = isCategoryChecked ? true : filterData.orderType.some((stat) => stat === subCategory.value);
                                                        return (
                                                            <FormControlLabel
                                                                sx={{
                                                                    fontSize: '0.3rem',
                                                                    '& .MuiSvgIcon-root': {
                                                                        width: '0.8rem',
                                                                        height: '0.8rem',
                                                                    },
                                                                }}
                                                                key={subCategory.value}
                                                                control={
                                                                    <Checkbox
                                                                        checked={isSubChecked}
                                                                        sx={{ color: '#337FC9', cursor: 'pointer' }}
                                                                        onChange={(el) => handleCheckboxChangeOrderTypeFilter(el, subCategory)}
                                                                    />
                                                                }
                                                                label={subCategory.label}
                                                            />
                                                        );
                                                    })}
                                                </FormGroup>
                                            )}
                                        </div>
                                    }
                                    )}
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>

                )}

                {/* Date */}
                {'date' in filterData && (
                    <MenuItem>
                        <Accordion
                            sx={{ width: '20rem', margin: 0, border: 'none', '& .MuiAccordionDetails-root': { pt: 0 }, '& .Mui-expanded': { minHeight: 0, maxHeight: "32px" } }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                                sx={{ minHeight: '0.1rem' }}
                            >
                                <Typography sx={{ fontSize: '0.8rem', minHeight: '0.1rem' }}>Date
                                    {filterData?.date.FromDate != '' && filterData?.date.ToDate != '' && <span style={{ display: 'inline-flex', justifyContent: 'center', alignItems: 'center', marginLeft: '10px', width: '15px', height: '15px', color: '#fff', fontSize: '11px', textAlign: 'center', backgroundColor: '#d32f2f', borderRadius: "50%" }}>
                                        1
                                    </span>}
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <FormGroup sx={{ width: '100%', display: "flex", flexDirection: "column" }}>
                                    <Box sx={{ '& .MuiOutlinedInput-root': { height: "2rem", fontSize: 12 }, '& .MuiSvgIcon-root': { fontSize: '24px' }, display: "flex", alignItems: "center", justifyContent: "space-between", mt: 1 }}>
                                        <Typography style={{ fontSize: '1rem', width: "50%" }}>From</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={filterData?.date.FromDate}
                                                disableFuture
                                                maxDate={filterData?.date.ToDate}
                                                format="DD/MM/YYYY"
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        error: false,
                                                    }
                                                }}
                                                onChange={(date: any) => {
                                                    setFilterData({ ...filterData, date: { ...filterData.date, FromDate: date } })

                                                }} />
                                        </LocalizationProvider>
                                    </Box>
                                    <Box sx={{ '& .MuiOutlinedInput-root': { height: "2rem", fontSize: 12 }, '& .MuiSvgIcon-root': { fontSize: '24px' }, display: "flex", alignItems: "center", justifyContent: "space-between", mt: 2 }}>
                                        <Typography style={{ fontSize: '1rem', width: "50%" }}>To</Typography>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                value={filterData?.date.ToDate}
                                                disableFuture
                                                minDate={filterData?.date.FromDate}
                                                format="DD/MM/YYYY"
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        error: false,
                                                    }
                                                }}

                                                onChange={(date: any) => {
                                                    setFilterData({ ...filterData, date: { ...filterData.date, ToDate: date } })
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Box>
                                </FormGroup>
                            </AccordionDetails>
                        </Accordion>
                    </MenuItem>
                )}


                {/* Apply and Clear filter buttons*/}
                <MenuItem sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                        sx={{
                            marginLeft: '20px',
                            height: '27px',
                            color: 'white',
                            fontSize: '13px',
                            cursor: 'pointer',
                            borderRadius: '5px',
                            bgcolor: '#337FC9',
                            fontWeight: 400,
                            '&:hover': { backgroundColor: '#337FC9' },
                        }}
                        onClick={() => {
                            setFilterData(filterInitialData)
                        }}
                    >
                        Clear Filter
                    </Button>
                </MenuItem>

            </Menu>
        </>
    )
}
import { Grid, Typography } from '@mui/material';
import { ProceedSaveLater, SubHeading } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import MFSelectField from '../../lib/formik/SelectField';
import { Applicant } from '../../redux-store/types/api-types';
import {
  AMC_APPROVER_CHECK_FOR_INDIVIDUAL,
  grossAnnualMasters,
  occupationDetailsMasters,
  PEPsMasters,
  USER_ROLES,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  applyRoleBasedStatus,
  currencyConversion,
  getApplicantName,
  saveForLater,
} from '../../utils/utilityFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { useEffect, useState } from 'react';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { KYCDetailsSchema } from '../../utils/schema';
import { MFTextField } from '../../lib/formik';
import { useSnackbar } from 'notistack';
// import { DatePicker } from '../../lib/formik/DatePicker';
import { TextDatePicker } from '../onboarding/DatePickerWithTextFeild';

type Values = {
  applicants: Partial<Applicant>[];
  saveType: string;
};

const initialValues: Values = {
  applicants: [
    {
      occupationDetails: '',
      grossAnnualIncome: '',
      politicallyExposedPersonStatus: 'Not Applicable',
      ckycNo: '',
      grossAnnualIncomeDate: '',
      netWorthDate: '',
      netWorth: '',
    },
  ],
  saveType: 'save and proceed',
};

const KYCDetails = ({
  applicant,
  index,
  value,
}: {
  applicant: string;
  index: number;
  value: any;
}): JSX.Element => {
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  return (
    <>
      <SubHeading>Additional KYC Details of {applicant} Applicant</SubHeading>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.grossAnnualIncome`}
          label="Gross Annual Income (in INR) *"
          items={grossAnnualMasters.map((grossIncome) => ({
            key: grossIncome,
            value: grossIncome,
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Gross Annual Income as on Date *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on Date'}
          name={`applicants.${index}.grossAnnualIncomeDate`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.netWorth`}
          label="Networth (in INR) *"
          placeholder="Enter Net Worth (in INR)"
          type="number"
          trimOnBlur={false}
        />
        {value.netWorth && (
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 14,
            }}>
            {currencyConversion(value.netWorth)}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextDatePicker
          label={'Networth as on Date *'}
          inputLabelStyles={{
            transform: 'unset',
            fontSize: 14,
            fontWeight: 500,
            color: 'rgba(0,0,0,0.7)',
          }}
          placeholder={'Enter Net worth as on'}
          name={`applicants.${index}.netWorthDate`}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFSelectField
          name={`applicants.${index}.politicallyExposedPersonStatus`}
          label="Politically Exposed Person (PEP) Status *"
          items={PEPsMasters.map((pep) => ({ key: pep, value: pep }))}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MFTextField
          name={`applicants.${index}.ckycNo`}
          label="CKYC Number"
          placeholder={'Enter CKYC number'}
          type="number"
          onKeyDown={(e) => {
            allowOnlyNumbers(e);
          }}
          trimOnBlur={false}
          disabled={[USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)}
        />
      </Grid>
    </>
  );
};

export default function AdditionalKYCDetails(): JSX.Element {
  const [kycDetails, setkycDetails] = useState(initialValues);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const { applicants: exisitingApplicants = [] } = application || {};
    setkycDetails({
      ...kycDetails,
      applicants: exisitingApplicants.map((applicant) => ({
        occupationDetails: applicant.occupationDetails || '',
        grossAnnualIncome: applicant.grossAnnualIncome || '',
        politicallyExposedPersonStatus: applicant.politicallyExposedPersonStatus
          ? applicant.politicallyExposedPersonStatus
          : [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role)
          ? ''
          : 'Not Applicable',
        grossAnnualIncomeDate: applicant.grossAnnualIncomeDate || `${new Date().toISOString()}`,
        netWorthDate: applicant.netWorthDate || `${new Date().toISOString()}`,
        netWorth: applicant.netWorth || '',
        ckycNo: applicant.ckycNo || '',
      })),
    });
  }, [application]);

  const handleSubmit = async (values: Values) => {
    try {
      const { applicants, saveType } = values;
      const {
        applicants: exisitingApplicants = [],
        id,
        applicant1ReferenceId = '',
        currentStep,
        status,
        hasPOA,
        applicationNumber,
      } = application || {};
      const updatedApplicants = exisitingApplicants.map((applicant, index) => ({
        ...applicant,
        ...applicants[index],
      }));
      const checkApplication = applicationComparison(application, {
        ...application,
        applicants: updatedApplicants,
        currentStep: !!currentStep && currentStep > 4 ? currentStep : Number(currentStep) + 1,
      });
      const isSaveLater = saveType !== 'save and proceed';
      if (id && !checkApplication) {
        setLoading(true);
        await dispatch(
          updateApplication({
            body: {
              ...application,
              applicants: updatedApplicants,
              status:
                !hasPOA &&
                AMC_APPROVER_CHECK_FOR_INDIVIDUAL &&
                status !== 'draft' &&
                applyRoleBasedStatus(role)
                  ? 'sent_to_amc_approver'
                  : status,
              currentStep: 5,
              //!!currentStep && currentStep > 3 ? currentStep : Number(currentStep) + 1,
            },
            applicationId: id,
            ...(isSaveLater && { toastMessage: '' }),
          })
        );
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      } else if (checkApplication) {
        if (isSaveLater) {
          enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
            variant: 'success',
            autoHideDuration: 3000,
          });
        }
        !isSaveLater
          ? history.push('nominee-details', { id, applicant1ReferenceId })
          : history.push(saveForLater(role, id, applicant1ReferenceId));
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  return (
    <Formik
      initialValues={kycDetails}
      onSubmit={handleSubmit}
      validate={(values: Values) => {
        try {
          validateYupSchema(values, KYCDetailsSchema, true, values);
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}
      enableReinitialize={true}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          // columnSpacing={5}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}
          component="form"
          noValidate
          onSubmit={handleSubmit}>
          {values.applicants.map((_applicant, index) => (
            <KYCDetails
              applicant={getApplicantName(index + 1)}
              value={_applicant}
              key={index}
              index={index}
            />
          ))}
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
        </Grid>
      )}
    </Formik>
  );
}
